import React, { Component } from 'react';
import Color from '../Config/Color';
import Header from '../Header/Header';

import { RemoveDataInStorage, RetriveDataFromStorage, RetriveObjectFromStorage, StoreDataInStorage } from "../Config/Utils";
import Constants from '../Config/Constants';
import Draggable from "react-draggable";

import './TryIt.css'
import { IMAGEFILEEXTENSIONS, IMAGEFILEEXTENSIONSINHTML } from '../Config/constvariable';

const zoomStep = 1;
const maxScale = 5;
const minScale = 1;
const defaultScale = minScale;
const defaultRotate = 0;
const defaultscaleX = 1;
const defaultscaleY = 1;

class TryIt extends Component {
    // static contextType = MainContext;
    constructor(props) {
        super(props);
        this.state = {
            title: "TRY IT",
            selectedFile: null,
            scale: defaultScale,
            rotate: defaultRotate,
            version: 0,
            testImage: "",
            iscaleX: defaultscaleX,
            iscaleY: defaultscaleY
        }
        this.handleFileInput = this.handleFileInput.bind(this)

    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        // var objPlaza = RetriveObjectFromStorage(Constants.Obj_PLAZA, '');
        var objStore = RetriveObjectFromStorage(Constants.Obj_STORE, '');
        // console.log("objPlaza ", objPlaza);
        console.log("objStore ", objStore);
        var objStoreItems = RetriveObjectFromStorage(Constants.Obj_STORE_ITEMS, '');
        console.log("objStoreItems ", objStoreItems);
        if (objStoreItems) {
            this.setState({ testImage: objStoreItems.ItemLogo })
        }
    }

    base64convert() {
        $('#imgupload').trigger('click');
    }

    handleFileInput(event) {
        try {
            var fileobj = event.target.files[0];
            if (fileobj.size > 0) {
                var fileName = event.target.files[0].name;
                var fileSize = event.target.files[0].size;
                var extension = IMAGEFILEEXTENSIONS;
                var fileSplit = fileName.split('.');
                var filelength = fileSplit.length;
                var fileExtension = fileSplit[filelength - 1];
                var extensionFlag = extension.includes(fileExtension);
                if (extensionFlag) {
                    var fileReader = new FileReader();
                    fileReader.readAsDataURL(fileobj);
                    fileReader.onload = (fileLoadedEvent) => {
                        var base64 = fileLoadedEvent.target.result;
                        console.log(base64);
                        this.setState({ selectedFile: base64 });
                    };
                    this.setState({ filename: fileName });
                    this.setState({ fileSize: fileSize });
                }
            }
        }
        catch (err) {
            console.log("File Upload Error", err)
        }
    };

    zoomIn = () => {
        if (this.state.scale <= minScale) {
            this.setState({ scale: this.state.scale + 0.1 });
        }
        else {
            this.setState((state) => {
                const newScale = state.scale + zoomStep;
                return {
                    scale: newScale <= maxScale ? newScale : maxScale
                };
            });
        }
    };

    zoomOut = () => {
        if (this.state.scale <= minScale) {
            this.setState({ scale: this.state.scale > 0.3 ? this.state.scale - 0.1 : 0.2 });
        }
        else {
            this.setState((state) => {
                const newScale = state.scale - zoomStep;
                return {
                    scale: newScale >= minScale ? newScale : minScale
                };
            });
        }
    };

    saveasImagescaleX = () => {
        this.setState({ iscaleX: this.state.iscaleX === 1 ? -1 : 1 });
    }

    saveasImagescaleY = () => {
        this.setState({ iscaleY: this.state.iscaleY === 1 ? -1 : 1 });
    }

    reset = () => {
        this.setState((state) => ({
            scale: defaultScale,
            rotate: 0,
            version: state.version + 1,
            iscaleX: 1,
            iscaleY: 1
        }));
    };

    rotateRight = () => {
        this.setState((state) => ({
            rotate: state.rotate === 360 ? 0 : state.rotate + 10
        }));
    };

    rotateLeft = () => {
        this.setState((state) => ({
            rotate: state.rotate === -360 ? 0 : state.rotate - 10
        }));
    }

    handleEvent = (e, data) => {
        console.log('Event Type', e.type);
        console.log(e, data);
    }

    render() {
        const { } = this.state
        const isDraggable = this.state.scale > 1;
        return (
            <div>
                <Header countItemCart={2} setHeaderTitle={this.state.title} />
                <section className="section">
                    <div className="container" style={{ paddingLeft: "0px", paddingRight: "2px" }}>

                        <div className="reg_form_main col-sm-3">
                            <label
                                for="step5"
                                id="continue-step5"
                                style={{ width: "100%", margin: "0px" }}
                                onClick={() => this.base64convert()}
                                className="btn btn-done">
                                Bowse Image</label>
                            <input type="file" id="imgupload" style={{ display: "none" }} name="myfile" onChange={this.handleFileInput} accept={IMAGEFILEEXTENSIONSINHTML} />
                        </div>

                        {this.state.selectedFile && <div className="reg_form_main col-sm-12">
                            <div class="topnav" style={{ display: "inline", margin: "0px 10px" }}>
                                <a class="" onClick={this.zoomIn}>
                                    <i class="fas fa-search-plus" style={{ color: Color.THEME_COLOR }}></i>
                                </a>
                                <a onClick={this.zoomOut}>
                                    <i class="fas fa-search-minus" style={{ color: Color.THEME_COLOR }}></i>
                                </a>
                                <a onClick={this.rotateRight}>
                                    <i class="fas fa-redo-alt" style={{ color: Color.THEME_COLOR }}></i>
                                </a>
                                <a onClick={this.rotateLeft}>
                                    <i class="fas fa-undo-alt" style={{ color: Color.THEME_COLOR }}></i>
                                </a>
                                <a onClick={this.saveasImagescaleX}>
                                    <i class="fas fa-arrows-alt-h" style={{ color: Color.THEME_COLOR }}></i>
                                </a>
                                <a onClick={this.saveasImagescaleY}>
                                    <i class="fas fa-arrows-alt-v" style={{ color: Color.THEME_COLOR }}></i>
                                </a>
                                <a onClick={this.reset}>
                                    <i class="fas fa-sync-alt" style={{ color: Color.THEME_COLOR }}></i>
                                </a>
                            </div>
                        </div>}

                        {!this.state.selectedFile && <div className="reg_form_main col-sm-12">
                            <center style={{ margin: "40px 0px" }}><h4>Select Background Image</h4></center>
                        </div>}

                        {this.state.selectedFile && <div id="dragviewmainid" className="reg_form_main mb-4" style={{ /* overflow: "auto" */ }}>
                            <div className="">
                                <Draggable
                                // disabled={true}
                                // key={this.state.version}
                                // onDrag={this.handleEvent}
                                // onStart={this.handleEvent}
                                // onStop={this.handleEvent}
                                // onMouseDown={this.handleEvent}
                                // onMouseUp={this.handleEvent}
                                // onTouchStart={this.handleEvent}
                                // onTouchEnd={this.handleEvent}
                                >
                                    <div style={isDraggable ? { cursor: "move" } : null}>
                                        <img id="Image1"
                                            style={{
                                                transform: `scale(${this.state.scale}) rotate(${this.state.rotate}deg) scaleX(${this.state.iscaleX}) scaleY(${this.state.iscaleY})`,
                                                width: "fit-content",
                                                height: "fit-content",
                                                position: "fixed"
                                            }}
                                            draggable="true"
                                            src={this.state.testImage} />
                                    </div>
                                </Draggable>
                                <img src={this.state.selectedFile} style={{ pointerEvents: "none", width: "-webkit-fill-available" }}></img>
                            </div>
                        </div>}
                    </div>
                </section>
            </div>
        );
    }
}

export default TryIt;