import React, { Component } from 'react';
import Constants, { currencyInfo } from '../Config/Constants'
import { RetriveDataFromStorage, RetriveObjectFromStorage } from "../Config/Utils";
import { NavLink, withRouter } from 'react-router-dom';
import { GetData } from "../../services/GetData";
import Color from '../Config/Color';
import Collapsible from 'react-collapsible';
import Header from '../Header/Header';
import Moment from 'moment';
import './OrderDetail.css';
import { POST_CHECKIN_MSSG, POST_CANCEL_MSSG, GET_COUNTRY_API } from '../Config/Config.json';
import { PostData } from "../../services/PostData";
import LoadingOverlay from 'react-loading-overlay';
import CountryList from '../Config/CountryCode'

class OrderDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderDetail: {},
            currencySymbols: "",
            applicationId: "",
            plazzaId: "",
            checkInMssg: {},
            isActive: false,
            countryData: []
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {
        var orderDetails = RetriveObjectFromStorage(Constants.orderDetail)
        if (orderDetails) {
            this.setState({ orderDetail: orderDetails })
        }
        var countryFees = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES)
        if (countryFees) {
            var currencySymbole = (countryFees[currencyInfo.CountryCode] ?
                "(" + countryFees[currencyInfo.CountryCode] + ") " : "") + countryFees.Symbol
            this.setState({ currencySymbols: currencySymbole })

        }
        this.getCountryAPI()
    }

    getCountryAPI() {
        console.log("country list1 ",);
        GetData(GET_COUNTRY_API)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData) {
                        this.setState({ countryData: responseData });
                    }
                    else {
                        this.setState({ countryData: [] })
                    }
                    console.log("countryData ", responseData);
                }
            });
    }

    setAppandPlazzaIds(plazzaId, appId) {
        this.setState({ plazzaId: plazzaId, applicationId: appId })
    }

    handleChange(event) {
        let checkInMssg = this.state.checkInMssg;
        let key = event.target.name
        let value = event.target.value
        checkInMssg[key] = value
        this.setState({ checkInMssg })
    }

    validateForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.checkInMssg;

        if (!fields["sendMssg"]) {
            formIsValid = false;
        }

        if (!this.state.plazzaId) {
            formIsValid = false;
        }

        if (!this.state.applicationId) {
            formIsValid = false;
        }
        if (!this.state.orderDetail.OrderId) {
            formIsValid = false;
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSubmit(e) {
        var postData = {};
        e.preventDefault();
        if (this.validateForm()) {
            postData['ApplicationId'] = this.state.applicationId;
            postData['PlazaId'] = this.state.plazzaId;
            postData['Message'] = this.state.checkInMssg.sendMssg;

            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            console.log("==> postdata==", postData)
            this.addCheckinMssg(postData, authToken);
        }
    }
    addCheckinMssg(postData, authToken) {
        var URL = POST_CHECKIN_MSSG.replace("#id#", this.state.orderDetail.OrderId);
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    $('#myModal').modal('hide')
                    var checkInMssg = this.state.checkInMssg;
                    checkInMssg['sendMssg'] = "";
                    this.setState({ checkInMssg, plazzaId: "", applicationId: "" })
                }
                else {
                    alert(responseJson["Message"])
                }
            })
    }

    handleCancel(e) {
        var postData = {};
        $('#myModal1').modal('hide');
        e.preventDefault();
        if (this.state.orderDetail.OrderId) {
            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            postData["Isconfirm"] = false;
            this.cancelOrderAPI(postData, authToken)
        }
    }
    cancelOrderAPI(postData, authToken) {
        this.setState({ isActive: true });
        var URL = POST_CANCEL_MSSG.replace("#id#", this.state.orderDetail.OrderId);
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {

                    this.setState({ isActive: false });
                    this.props.history.goBack();
                }
                else {
                    alert(responseJson["Message"])
                }
                this.setState({ isActive: false });
            })
    }

    render() {
        var currencySymbols = this.state.currencySymbols;
        let orderDetailsvalue = this.state.orderDetail;
        var lengthofDetail = Object.keys(orderDetailsvalue).length;
        var DetailDOM
        if (lengthofDetail > 0) {
            var specialdeliverychargesTotal = 0.0

            var currency = this.state.countryData.filter(e => e.Currency == orderDetailsvalue.Currency)[0]
            currencySymbols = "(" + orderDetailsvalue.Currency + ")" + (currency ? currency.Symbol + " " : "")

            var totelItem = 0, totalItemTax = 0, subTotal = 0;

            var plazzaList = orderDetailsvalue.Plaza.map(plazzaobj => {

                var shopeList = plazzaobj.Stores.map(shopeObj => {
                    totelItem = totelItem + shopeObj.Items.length;

                    var itemList = shopeObj.Items.map(itemObj => {
                        specialdeliverychargesTotal = specialdeliverychargesTotal + itemObj.specialdeliverycharges
                        totalItemTax = totalItemTax + Number(itemObj.TotalItemTax)
                        subTotal = subTotal + Number(itemObj.TotalItemPrice)
                        return (<div style={{ marginLeft: "0px", marginTop: "10px", borderBottom: "1px solid" + Color.GREY }}>
                            <div className="col-sm-12">
                                <img src={itemObj.ItemImage} style={{ height: "50px", width: "50px" }}></img>
                                <label style={{ marginLeft: "5px", fontSize: "13px" }}>{itemObj.ItemName}</label>
                                <label style={{ fontSize: "13px", float: "right", marginLeft: "auto", color: Color.THEME_COLOR }}>{itemObj.ItemQuantity} Products</label>
                                <div style={{ marginTop: "5px", fontSize: "13px" }}>
                                    <label className="plazzanametitle">{currencySymbols} {(Math.round((itemObj.TotalItemPrice) * 100) / 100).toFixed(2).toString()}</label>
                                    {itemObj.Instruction && <label className="plazzanametitle veriantDetailBox" >
                                        <span className='setshopifydetail'> {itemObj.Instruction}</span></label>}
                                    {itemObj.variantdetail && <label className="plazzanametitle veriantDetailBox" >Variant Detail :
                                        <span className='setshopifydetail'> {itemObj.variantdetail}</span></label>}
                                    <Collapsible
                                        triggerStyle={{ position: "relative" }}
                                        trigger={<label style={{ float: "right", color: Color.BLUE, marginTop: "-35px" }}>More Details</label>}>
                                        ProductDetail:<br />
                                        {itemObj.ItemDescription}
                                    </Collapsible>
                                </div>
                            </div>
                        </div>)
                    })

                    return (<div><div style={{ marginLeft: "0px", borderTop: "1px solid" + Color.GREY, borderBottom: "1px solid" + Color.GREY }}>
                        <div className="col-sm-12" style={{ marginTop: "10px" }}>
                            <label style={{ fontWeight: "bold" }}>{shopeObj.StoreName}</label>
                            <div style={{ fontSize: "13px", lineHeight: "10%" }}>
                                <label>Shop order {shopeObj.Items.length} Products</label>
                                <label className="shopstatus"> status: <span style={{ color: Color.GREEN }}>{shopeObj.OrderStatus}</span></label>
                                <label data-toggle="modal" data-target="#myModal" style={{ marginLeft: "auto", color: Color.THEME_COLOR, float: "right", background: "none" }} onClick={() => this.setAppandPlazzaIds(plazzaobj.PlazaId, shopeObj.ApplicationId)}>Message Business</label>
                            </div>
                            <div style={{ fontSize: "12px" }}>{orderDetailsvalue.IsPickupOrder ? "Pickup" : "Delivery"} Time:{shopeObj.DeliveryTime}</div>
                        </div>
                    </div>
                        {itemList}
                    </div>)
                })

                return (<div className="reg_form_main" >
                    <Collapsible
                        open={true}
                        trigger={<span className="col-sm-12" >
                            <img
                                src={plazzaobj.PlazaImage}
                                style={{ height: "50px", marginTop: "10px", borderRadius: "5px", marginRight: "10px", width: "50px" }}>
                            </img>
                            <label className="plazzanametitle" style={{ fontWeight: "bold" }}>{plazzaobj.PlazaName}</label>
                            {/* <span style={{ fontSize: "13px", float: "right", marginRight: "5px", color: "#e75480" }}>
                                {totelItem} Items
                            </span><br /> */}
                            <label
                                style={{ fontSize: "13px", float: "right", marginRight: "5px", color: Color.THEME_COLOR }}>
                                {plazzaobj.Stores.length} Shop<br />
                                {totelItem} Products
                            </label>
                        </span>
                        }>
                        <div style={{ paddingTop: "5px" }}>
                            {shopeList}
                        </div>
                    </Collapsible>
                </div>)
            })

            DetailDOM = (<div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                <div className="col-sm-12" style={{ backgroundColor: Color.WHITE, borderBottom: "1px solid" + Color.GREY, borderTop: "1px solid" + Color.GREY, marginTop: "15px" }} >
                    <div className="row" style={{ marginLeft: "3px" }}>{orderDetailsvalue._id}</div>
                    <div className="row" style={{ marginLeft: "3px", fontSize: "13px" }}>Order Status: <label style={{ color: Color.GREEN }}>{orderDetailsvalue.OrderStatus}</label></div>
                    <div className="row" style={{ marginLeft: "3px", fontSize: "13px" }}>Order Date: <span style={{ color: Color.BLUE }}>{Moment(orderDetailsvalue.OrderDate).format("DD MMM YYYY h:mm a")}</span></div>
                </div>

                {plazzaList}

                <div className="reg_form_main">
                    <div className="col-sm-12" style={{ marginTop: "15px" }}>
                        <div className="row">
                            <label style={{ marginLeft: "20px", color: Color.BLACK }}>Subtotal</label>
                            <label style={{ marginLeft: "auto", color: Color.BLACK, marginRight: "10px" }}>{currencySymbols}{(Math.round((subTotal) * 100) / 100).toFixed(2).toString()}</label>
                        </div>
                        {totalItemTax != 0 && <div className="row">
                            <label style={{ marginLeft: "20px", color: Color.BLACK }}>Total Product Tax</label>
                            <label style={{ marginLeft: "auto", color: Color.BLACK, marginRight: "10px" }}>{currencySymbols}{(Math.round(totalItemTax * 100) / 100).toFixed(2)}</label>
                        </div>}
                        {specialdeliverychargesTotal != 0 && <div className="row">
                            <label style={{ marginLeft: "20px", color: Color.BLACK }}>Special Delivery Charges</label>
                            <label style={{ marginLeft: "auto", color: Color.BLACK, marginRight: "10px" }}>{currencySymbols}{(Math.round((specialdeliverychargesTotal) * 100) / 100).toFixed(2).toString()}</label>
                        </div>}
                        <div className="row">
                            <label style={{ marginLeft: "20px", color: Color.BLACK }}>Delivery Charges</label>
                            <label style={{ marginLeft: "auto", color: Color.BLACK, marginRight: "10px" }}>{currencySymbols}{(Math.round((orderDetailsvalue.TotalDeliveryAmount) * 100) / 100).toFixed(2).toString()}</label>
                        </div>
                        <div className="row">
                            <label style={{ marginLeft: "20px", color: Color.BLACK }}>Service Fee</label>
                            <label style={{ marginLeft: "auto", color: Color.BLACK, marginRight: "10px" }}>{currencySymbols}{(Math.round((orderDetailsvalue.ServiceFeeAmount) * 100) / 100).toFixed(2).toString()}</label>
                        </div>
                        {orderDetailsvalue.TaxDetails.map(e2 => {

                            return <div className="row">
                                <label style={{ marginLeft: "20px", color: Color.BLACK }}>{e2.TaxType} ({e2.TaxRate}%)</label>
                                <label style={{ marginLeft: "auto", color: Color.BLACK, marginRight: "10px" }}>{currencySymbols}{(Math.round((e2.TotalTax) * 100) / 100).toFixed(2).toString()}</label>
                            </div>
                        })}

                        <div>
                            <div className="row" style={{ borderBottom: "1px solid" + Color.GREY }}>
                                <label style={{ marginLeft: "20px", color: Color.BLACK }}>Delivery Tip</label>
                                <label style={{ marginLeft: "auto", color: Color.BLACK, marginRight: "10px" }}>{currencySymbols}{(Math.round((orderDetailsvalue.TipAmount) * 100) / 100).toFixed(2).toString()}</label>
                            </div>

                        </div>
                    </div>
                    <div className="col-sm-12" style={{ marginTop: "10px", borderBottom: "1px solid" + Color.GREY }}>
                        <div className="row">
                            <label style={{ marginLeft: "20px", color: Color.BLACK }}>Total </label>
                            <label style={{ marginLeft: "auto", color: Color.BLACK, marginRight: "10px" }}>{currencySymbols}{(Math.round((orderDetailsvalue.TotalAmount) * 100) / 100).toFixed(2).toString()}</label>
                        </div>
                        {orderDetailsvalue.CouponDetail &&
                            <div className="row">
                                <label style={{ marginLeft: "20px", color: Color.BLACK }}>Payable Amount  {currencySymbols} {Number(orderDetailsvalue.CouponDetail ? orderDetailsvalue.CouponDetail.discountamount : 0).toFixed(2) + " OFF "}
                                </label>
                                <label style={{ marginLeft: "auto", color: Color.BLACK, marginRight: "10px" }}>{currencySymbols} {Number(orderDetailsvalue.TotalpayAmount).toFixed(2)}</label>
                            </div>
                        }
                        {orderDetailsvalue.CouponDetail && <div className={'row-over row setaTag'}                        >
                            <div className='coupondesc couponblock'>
                                <label className="remove-mg" style={{ marginLeft: "10px", color: Color.BLACK, fontWeight: 'bold', }}>{orderDetailsvalue.CouponDetail.coupontitle}
                                </label>
                                <label className="remove-mg coupondescription" style={{ marginLeft: "10px", color: Color.BLACK }}> {orderDetailsvalue.CouponDetail.coupondescription}</label>
                            </div>
                        </div>}
                        <label className="row" style={{ color: Color.BLACK, fontSize: "12px", marginLeft: "3px" }}>All taxes and charges are included in total price.</label>
                    </div>
                </div>

                {orderDetailsvalue.IsCanCancel ? <div className="reg_form_main">
                    <div className="bottam_sing">
                        <label
                            style={{ backgroundColor: Color.THEME_COLOR, float: "center", borderRadius: "50px", marginBottom: "10px" }}
                            //onClick={this.handlePayment}
                            className="col-sm-5 btn btn-done"
                            data-toggle="modal" data-target="#myModal1"
                        > Cancel Order</label>
                    </div>
                </div> : ""}



                {/* /////////////////////start model///////////////////////////// */}
                <div class="modal" id="myModal">
                    <div class="modal-dialog" style={{ top: "25%" }}>
                        <div class="modal-content">
                            <div class="modal-header">
                                <label>Send message to brewery:</label>
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div class="modal-body">
                                <div>
                                    <textarea className="col-sm-12" style={{ marginTop: "10px" }} name="sendMssg" rows="7" onChange={this.handleChange} value={this.state.checkInMssg.sendMssg}>
                                    </textarea>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <label
                                    style={{ color: Color.THEME_COLOR }}
                                    for="step5"
                                    id="continue-step5"
                                    data-dismiss="modal"
                                > Cancel</label>
                                <label
                                    style={{ float: "center", borderRadius: "50px", marginBottom: "10px" }}
                                    for="step5"
                                    id="continue-step5"
                                    //onClick={this.handlePayment}
                                    onClick={this.handleSubmit}
                                    className="btn btn-done"
                                > Send</label>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /////////////////////end model///////////////////////////// */}

                <div class="modal" id="myModal1">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <label style={{ color: Color.THEME_COLOR, textAlign: "justify" }}>Your order status is pending.if you would like to cancel the entire order click OK or click CANCEL to wait for merchant to confirm. Alternatively you can notify the business by clicking Message Business link and check for status on your order.</label>
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div class="modal-footer">
                                <label
                                    style={{ color: Color.THEME_COLOR }}
                                    for="step5"
                                    id="continue-step5"
                                    data-dismiss="modal"
                                > Cancel</label>
                                <label
                                    style={{ float: "center", borderRadius: "50px", marginBottom: "10px" }}
                                    for="step5"
                                    id="continue-step5"
                                    //onClick={this.handlePayment}
                                    onClick={this.handleCancel}
                                    className="btn btn-done"
                                > Send</label>
                            </div>
                        </div>
                    </div>
                </div>

            </div>)
        }

        return (
            <div>
                {this.state.isActive ? <LoadingOverlay
                    active={this.state.isActive}
                    text={Constants.LoadingMssg}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed",
                            zIndex: "1079"
                        })
                    }}
                    className="lodingspinner"
                /> : ""}
                <Header setHeaderTitle={Constants.Title_MyOrder} />
                <section className="section">
                    {DetailDOM}
                </section>

            </div>
        );
    }
}

export default withRouter(OrderDetail);