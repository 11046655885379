import React, { Component } from 'react';
import DataTable from 'react-data-table-component'
import 'react-data-table-component-extensions/dist/index.css';
import Header from '../Header/Header';
import Color from '../Config/Color';
import Checkbox from 'react-simple-checkbox';
import { RetriveDataFromStorage, RetriveObjectFromStorage, StoreObjectInStorage } from '../Config/Utils';
import Constants, { currencyInfo } from '../Config/Constants';

import { GET_TOPPING_API } from '../Config/Config.json'
import { GetData } from "../../services/GetData";
import ToppingList from './ToppingList';


var toppingsInCart = [];
var totalPrice = 0.0;
class AddToppings extends Component {
    constructor(props) {
        super(props);

        this.state = {

            itemlogo: "",
            itemPrice: "",
            totalPrice: "",
            itemCount: 1,
            toppingCategoryList: [],
            cartItems: [],
            toppingCharge: 0.00,
            selectedToppings: [],
            selectedToppingObj: {},

            storeData: {},
            // plazaData: {},
            itemData: {},

            cartStore: [], // use to make final cart list
            itemsInCart: [], // use to make final cart list

            finalCart: [],

            countCartItem: 0,

            //toppingsInCart:[]
            //objStoreItem:props.data
        }


        this.handleMinus = this.handleMinus.bind(this);
        this.handlePlus = this.handlePlus.bind(this);
        this.countTotal = this.countTotal.bind(this);

        this.handleAddToCart = this.handleAddToCart.bind(this);
        //this.setTopping = this.setTopping.bind(this);
        // this.setCurrentSelectedTopping = this.setCurrentSelectedTopping.bind(this);

        // this.setSelected = this.setSelected.bind(this);

    }

    componentDidMount() {

        // var objPlaza = RetriveObjectFromStorage(Constants.Obj_PLAZA, '');
        var objStore = RetriveObjectFromStorage(Constants.Obj_STORE, '');
        var objStoreItem = RetriveObjectFromStorage(Constants.Obj_STORE_ITEMS, '');

        // console.log("objPlaza ", objPlaza);
        console.log("objStore ", objStore);
        console.log("objStoreItem ", objStoreItem)

        if ( objStore) {
            // this.setState({ plazaData: objPlaza })
            this.setState({ storeData: objStore }, function () {
                // this.setSelected(objPlaza, objStore);
            })
        }

        if (objStoreItem) {
            //var objStoreItem = this.state.objStoreItem
            this.setState({ itemlogo: objStoreItem.ItemLogo });
            this.setState({ itemPrice: objStoreItem.ItemPrice });
            this.setState({ totalPrice: objStoreItem.ItemPrice });

            this.setState({ itemData: objStoreItem }, function () {
                // this.setSelected(objPlaza, objStore)
                // this.setToppingListItem(objStoreItem)
            })

        }

    }

    // setToppingListItem(objStoreItem) {

    //     var setCheck = objStoreItem.ToppingCategory.map(e => {
    //         return {
    //             ...e,
    //             "ToppingItems": e.ToppingItems.map(e1 => {
    //                 return { ...e1, "checked": false }
    //             })
    //         }
    //     })

    //     this.setState({ itemData: { ...objStoreItem, "ToppingCategory": setCheck } }, function () {
    //         console.log("topping List =>", this.state.toppingCategoryList)
    //     });
    // }

    // setSelected(objPlaza, objStore) {
    //     var globalFinalCart = RetriveObjectFromStorage(Constants.Obj_CART, '')
    //     if (globalFinalCart && globalFinalCart.length > 0) {
    //         globalFinalCart.map((item, index) => {
    //             var pObj = item;
    //             if (pObj.PlazaId === objPlaza._id) {
    //                 var storeList = pObj.Stores;
    //                 storeList.map((item1, index1) => {
    //                     var sObj = item1
    //                     if (sObj.ObjectId === objStore.ObjectId) {
    //                         var itemList = sObj.Items;
    //                         console.log("itemList ", itemList)
    //                         var currentItem = this.props.itemData;
    //                         for (var i = 0; i < itemList.length; i++) {
    //                             if (itemList[i].ItemId === currentItem.ItemID) {
    //                                 toppingsInCart = itemList[i].ToppingItems;
    //                                 totalPrice = itemList[i].TotalItemPrice;
    //                                 this.setState({ totalPrice: totalPrice });
    //                                 this.setState({ itemPrice: itemList[i].ItemPrice });
    //                                 this.setState({ itemCount: itemList[i].ItemQuantity });
    //                                 // this.countTotalWithTopping(toppingsInCart)
    //                                 // var toppingCharge = totalPrice - (itemList[i].ItemPrice * itemList[i].ItemQuantity)
    //                                 // toppingCharge = toppingCharge / itemList[i].ItemQuantity
    //                                 // this.setState({ toppingCharge: toppingCharge }, function () {
    //                                 //     //this.countTotal() 
    //                                 // });

    //                                 break;
    //                             }
    //                         }
    //                         // itemList.map((item2, index2) => {
    //                         //     var currentItem = this.props.itemData;
    //                         //     if (item2.ItemId === currentItem.ItemID) {
    //                         //         toppingsInCart = item2.ToppingItems;
    //                         //         totalPrice =  itemList.TotalItemPrice;                                   
    //                         //         return                                  
    //                         //     }                             
    //                         // })
    //                     }
    //                 })
    //             }
    //         })
    //     }
    //     console.log("toppingsInCart  ", toppingsInCart)
    // }


    handleMinus() {
        var count = this.props.itemCount
        if (count !== 1) {
            count = count - 1;
            this.props.setItemCount(count)
            this.setState({
                itemCount: count
            }, () => {
                this.countTotal(this.props.itemCount);
            });
        }
    }

    handlePlus() {
        var count = this.props.itemCount
        count = count + 1;
        if (this.props.ContinueSelling) {
            this.props.setItemCount(count)
            this.setState({
                itemCount: count
            }, () => {
                this.countTotal(count);
            });
        }
        else {
            if (this.props.AvailableQuantity >= count) {
                this.props.setItemCount(count)
                this.setState({
                    itemCount: count
                }, () => {
                    this.countTotal(count);
                });
            }
        }

    }

    countTotal(count) {
        // var price = this.props.itemPrice
        // price = price * count;
        // price = (Math.round(price * 100) / 100).toFixed(2);
        // this.setState({ totalPrice: price })

        var price = this.props.itemPrice
        var toppingCharge = this.props.toppingCharge;
        price = (price + toppingCharge) * count;
        price = (Math.round(price * 100) / 100).toFixed(2);
        this.props.setTotalPrice(price)
        // this.setState({ totalPrice: price })
    }


    // countTotalWithTopping() {
    //     var price = this.props.itemPrice
    //     var noOfItems = this.props.itemCount;
    //     var objTopping = this.state.selectedToppingObj;

    //     var toppingCharge = this.props.toppingCharge;
    //     if (objTopping.checked === true) {
    //         toppingCharge = toppingCharge + objTopping.Price
    //     }
    //     else {
    //         toppingCharge = toppingCharge - objTopping.Price
    //     }
    //     this.setState({ toppingCharge: toppingCharge }, function () {
    //         var amount = (price + toppingCharge) * noOfItems;
    //         amount = (Math.round(amount * 100) / 100).toFixed(2);
    //         this.setState({ totalPrice: amount })
    //     })
    // }

    countTotalWithTopping(selectedItems) {
        console.log("selectedItems  top", selectedItems)
        var toppingPrice = 0.0;
        for (var i = 0; i < selectedItems.length; i++) {
            if (i >= 3) {
                toppingPrice = toppingPrice + selectedItems[i].Price
            }
        }
        this.props.setToppingPrice(toppingPrice)
        this.setState({ toppingCharge: toppingPrice }, function () {
            this.setTotalPrice();
        })
    }

    setTotalPrice() {
        var price = this.props.itemPrice
        var noOfItems = this.props.itemCount;
        var toppingCharge = this.props.toppingCharge;

        var amount = (price + toppingCharge) * noOfItems;
        amount = (Math.round(amount * 100) / 100).toFixed(2);

        this.props.setTotalPrice(amount)
        this.setState({ totalPrice: amount })
    }

    // setTopping = items => {
    //     console.log("toppings ", items)
    //     if (items.length > 0) {
    //         this.setState({ selectedToppings: items }, function () {
    //             //alert(JSON.stringify(this.props.selectedToppingList))
    //             if (this.props.selectedToppingList.length > 3) {
    //                 this.countTotalWithTopping();
    //             }
    //         })
    //     }
    // }

    // setCurrentSelectedTopping = item => {
    //     console.log("current toppings ", item)
    //     if (item.currentObj) {
    //         this.setState({ selectedToppingObj: item.currentObj }, function () {
    //             if (item.list.length > 0) {
    //                 this.setState({ selectedToppings: item.list }, function () {
    //                     var selectedItems = this.props.selectedToppingList.filter(obj => obj.checked === true);
    //                     if (item.selectedList.length > 3) {
    //                         //this.countTotalWithTopping();
    //                         this.countTotalWithTopping(item.selectedList);
    //                     }
    //                     else if (selectedItems.length === 3) {
    //                         var objTopping = this.state.selectedToppingObj;
    //                         var toppingCharge = this.props.toppingCharge;
    //                         var price = this.props.itemPrice
    //                         var noOfItems = this.props.itemCount;
    //                         if (objTopping.checked === false) {
    //                             //toppingCharge = toppingCharge - objTopping.Price
    //                             toppingCharge = 0.0
    //                         }
    //                         this.setState({ toppingCharge: toppingCharge }, function () {
    //                             var amount = (price + toppingCharge) * noOfItems;
    //                             amount = (Math.round(amount * 100) / 100).toFixed(2);
    //                             this.setState({ totalPrice: amount })
    //                         })
    //                     }
    //                 })
    //             }
    //         })
    //     }
    // }

    // setCurrentSelectedTopping1 = list => {
    //     console.log("selected toppings ", list)
    //     var toppingPrice = 0.0
    //     if (list.length > 0) {
    //         for (var i = 0; i < list.length; i++) {
    //             if (i >= 3) {
    //                 toppingPrice = toppingPrice + list[i].Price
    //             }
    //         }
    //     }
    //     alert(toppingPrice)
    // }

    handleAddToCart() {

        this.setState({
            countCartItem: 1,
        });

        var itemData = this.props.itemData;
        itemData["noOfItems"] = this.props.itemCount;
        itemData["totalPrice"] = this.props.totalPrice
        if (this.props.selectedToppingList.length > 0) {
            var selectedItems = this.props.selectedToppingList.filter(obj => obj.checked);
            //itemData.ToppingItems = selectedItems;
            itemData["SelectedToppingItems"] = selectedItems;
        }
        else {
            itemData["SelectedToppingItems"] = [];
        }
        console.log("itemData ===>", itemData);
        this.props.selectedToppings(itemData)
        $('#toppingModal').modal('hide')
        //this.createCartItemsObject(this.state.plazaData, this.state.storeData, itemData)

    }




    renderToppingCategory(toppingCategoryList) {
        return toppingCategoryList
            .map(function (item, index) {
                var toppingCategoryName = item.ToppingCategory;
                var toppingItems = item.ToppingItems;
                return (
                    <React.Fragment key={index} >
                        <div className="col-sm-12" style={{ backgroundColor: Color.THEME_COLOR, color: Color.WHITE }} >
                            {toppingCategoryName}
                        </div>
                        {this.renderToppingItem(item._id, toppingItems)}
                    </React.Fragment>
                )
            }, this);
    }

    handleCheck(toppingCaategoryId, toppingList, toppingItemObj) {
        console.log(JSON.stringify(toppingItemObj))

        var selectedToppings = this.props.selectedToppingList;

        if (!toppingItemObj.checked) {
            let index = selectedToppings.indexOf(e => e._id === toppingItemObj._id)
            if (index < 0) {
                selectedToppings.push({ ...toppingItemObj, "checked": true })
            }
            else {
                selectedToppings[index] = { ...toppingItemObj, "checked": true }
            }
        }
        else {
            var objindex = -1
            for (var i in selectedToppings) {
                if (selectedToppings[i]._id === toppingItemObj._id) {
                    objindex = i
                    break
                }
            }
            console.log('===> else', selectedToppings, toppingItemObj._id, objindex)
            if (objindex != -1) {
                selectedToppings.splice(objindex, 1)
            }

        }
        // this.setState({ selectedToppings: selectedToppings })
        this.props.setSelectedToppings(selectedToppings)

        var storeItemObj = this.props.itemData;
        if (storeItemObj.ToppingCategory) {
            storeItemObj.ToppingCategory = storeItemObj.ToppingCategory.map(e1 => {
                if (e1._id === toppingCaategoryId) {
                    var ToppingItems = e1.ToppingItems.map(e => {
                        if (e._id === toppingItemObj._id) {
                            return { ...e, "checked": !e.checked }
                        }
                        return { ...e }
                    })
                    return { ...e1, "ToppingItems": ToppingItems }
                }
                return { ...e1 }
            })
        }

        // this.setState({ itemData: storeItemObj })
        this.props.setItemData(storeItemObj)

        console.log('====> add toppings', storeItemObj)
        // this.setState({ toppingCategoryList: toppingCategoryList })
        this.setToppingPrice()

    }

    setToppingPrice() {
        var storeItemObj = this.props.itemData;

        var selectedToppingList = this.props.selectedToppingList;

        if (selectedToppingList.length > 3) {
            //this.countTotalWithTopping();
            this.countTotalWithTopping(selectedToppingList);
        }
        else if (selectedToppingList.length <= 3) {
            var toppingCharge = 0.0
            var price = this.props.itemPrice
            var noOfItems = this.props.itemCount;
            this.props.setToppingPrice(toppingCharge)
            this.setState({ toppingCharge: toppingCharge }, function () {
                var amount = (price + toppingCharge) * noOfItems;
                amount = (Math.round(amount * 100) / 100).toFixed(2);
                this.setState({ totalPrice: amount })
                this.props.setTotalPrice(amount)
            })
        }

        // this.setState({ itemData: storeItemObj })

    }

    renderToppingItem(toppingCaategoryId, toppingItems) {
        var currencySymbole = ""
        var countryFees = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES)
        if (countryFees) {
            currencySymbole = (countryFees[currencyInfo.CountryCode] ?
                "(" + countryFees[currencyInfo.CountryCode] + ") " : "") + countryFees.Symbol

        }
        return toppingItems
            .map(function (item, index) {
                return (
                    <React.Fragment key={index} >
                        <div className="col-sm-12" style={{ backgroundColor: Color.WHITE, height: "40px", color: Color.BLACK, borderBottom: "1px solid" + Color.GREY }} >
                            <div className="row" style={{ padding: "5px" }} >
                                <Checkbox
                                    name={item._id}
                                    style={{ marginTop: "0px", paddinTop: "0px" }}
                                    color={Color.THEME_COLOR}
                                    size={2}
                                    tickSize={2}
                                    checked={item.checked}
                                    delay={0.1}
                                    //value={this.state.checked}
                                    //onClick={(value) => this.handleItemClick(item)}
                                    onChange={(value) => this.handleCheck(toppingCaategoryId, toppingItems, item)}>
                                </Checkbox>
                                <label style={{ marginLeft: "10px", fontSize: "15px" }}>{item.ToppingItem}</label>
                                <span style={{ marginLeft: "auto" }}>
                                    <label style={{ fontSize: "15px", float: "right", marginRight: "5px" }}>{currencySymbole}{(Math.round(item.Price * 100) / 100).toFixed(2)}</label>
                                </span>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }, this);
    }


    render() {
        const { toppingList, countCartItem, itemData } = this.state;

        var toppingCategoryList = [];
        if (this.props.itemData.ToppingCategory) {
            toppingCategoryList = this.props.itemData.ToppingCategory
        }
        //console.log("objStoreItem1 ", this.props.data)
        var currencySymbole = ""
        var countryFees = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES)
        if (countryFees) {
            currencySymbole = (countryFees[currencyInfo.CountryCode] ?
                "(" + countryFees[currencyInfo.CountryCode] + ") " : "") + countryFees.Symbol
        }
        return (

            <div>
                {/* <Header countItemCart={countCartItem} /> */}
                <section className="section">
                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                        <div style={{ marginLeft: "0px", marginTop: "10px" }}>
                            <div className="col-sm-12 d-flex justify-content-between align-items-center" style={{ marginBottom: "10px" }}>
                                <img src={this.props.itemData.ItemLogo} style={{ height: "60px", width: "60px" }}></img>

                                <label style={{ color: Color.THEME_COLOR }}>{currencySymbole} {Number(this.props.itemPrice).toFixed(2)}</label>
                                <label style={{ color: Color.THEME_COLOR, float: "right" }}>Toppings : {currencySymbole} {(Math.round(this.props.toppingCharge * 100) / 100).toFixed(2)}</label>
                            </div>
                        </div>
                        {/* {
                            toppingList.length > 0 ?
                            toppingList.map(function (item, index) {
                                var toppingCategoryName = item.ToppingCategory;
                                <div className="col-sm-12" style={{ backgroundColor: Color.THEME_COLOR, color: Color.WHITE }} >
                                    {toppingCategoryName}
                                </div>
                            }):null
                        } */}
                        {this.renderToppingCategory(toppingCategoryList)}
                        {/* <ToppingList
                            setToppingObj={this.setCurrentSelectedTopping}
                            //setToppingList={this.setTopping}
                            data={this.state.toppingCategoryList}>
                        </ToppingList> */}

                        <div className="row" style={{ padding: "5px" }} >
                            <label style={{ fontSize: "25px", marginLeft: "20px", marginRight: "20px" }} onClick={this.handleMinus}>-</label>
                            <label style={{ fontSize: "25px", marginLeft: "10px", marginRight: "10px", width: "30px" }}> {this.props.itemCount} </label>
                            <label style={{ fontSize: "25px" }} onClick={this.handlePlus}>+</label>
                            <span style={{ marginLeft: "auto" }}>
                                <label className="textcolor" style={{ float: "right", marginRight: "15px" }}>{currencySymbole} {Number(this.props.totalPrice).toFixed(2)}</label>
                            </span>
                        </div>

                        <div
                            className="bottam_sing"
                            style={{ marginTop: "20px", bottom: "0px" }}>
                            <div className="">
                                <label
                                    style={{ borderRadius: "50px", marginBottom: "10px" }}
                                    onClick={this.handleAddToCart}
                                    className="col-sm-6 btn btn-done"
                                > Customize Toppings</label>
                            </div>
                        </div>

                        {/* <div className="">
                            <div className="bottam_sing" style={{ color: Color.THEME_COLOR }}>
                                <label
                                    style={{ backgroundColor: Color.THEME_COLOR, float: "center", borderRadius: "50px", marginBottom: "10px" }}
                                    //  onClick={this.handleCancelOrder}
                                    className="col-sm-5 btn btn-done"
                                > Add Cart</label>
                            </div>
                        </div> */}
                    </div>
                </section>
            </div>

        );
    }
}

export default AddToppings;

