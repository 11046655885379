import React, { Component } from 'react';
import 'react-data-table-component-extensions/dist/index.css';
import LoadingOverlay from 'react-loading-overlay';
import Checkbox from 'react-simple-checkbox';
import { GetData } from "../../services/GetData";
import { PostData } from "../../services/PostData";
import Color from '../Config/Color';
import { GET_USERPROFILE_API, PAYPALPAYMENT, STOREID, STOREORDERAPI, STRIPE_PAYMENT_INTENT_API, STRIPE_PLATFORM_PAYMENT_INTENT_API, TENANTID } from '../Config/Config.json';
import Constants, {
    PAYMENT_TYPES, STRIPE_PAYMENT_STATUS, TERMS_OF_SERVICE_IN_VISIBLE_MODE,
    currencyInfo, DEFAULTID, COMPANYCONST
} from '../Config/Constants';
import Messages from '../Config/Messages';
import RouteConstant from '../Config/RouteConstant';
import { RemoveDataInStorage, RetriveDataFromStorage, RetriveObjectFromStorage, formatToDigit } from "../Config/Utils";
import Header from '../Header/Header';
import { PutMandatoryfieldSymbol } from '../UtillCustomComponent/UtillCustomComponent';
import Payple from './PayPal/Payple';
import "./Payment.css";
import StripeStandardPay from './StripeMerchant/StripeStandardPay';
import StripeExpressPay from './StripePlatform/StripeExpressPay';

class Payment extends Component {
    instance;
    constructor(props) {
        super(props);
        this.state = {
            checked: false,
            showDialog: false,
            clientToken: null,
            totalamount: 0.0,
            finelObj: {},
            isActive: false,
            clientid: "",
            merchantid: "",
            currency: "",
            billingAddObj: null,
            phoneNo: "",
            userProfileObj: null,
            isShopifyAvailable: false,
            striperesponse: {},
            errors: {},
            isPaymentEnable: false,
            loadingmessage: Constants.LoadingMssg
        }

        this.handleCheck = this.handleCheck.bind(this);
        this.handleContinue = this.handleContinue.bind(this);
    }

    componentDidMount() {

        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var finelObj = RetriveObjectFromStorage(Constants.finelObj_BeforePayemt);
        if (finelObj) {
            this.setState({ finelObj: finelObj, errors: {} });
            this.setState({
                totalamount: finelObj.TotalAmount,
                billingAddObj: finelObj.dCardAddress ? finelObj.dCardAddress : null
            });

            var plazaId = DEFAULTID, storeId = finelObj.storeid;
            var isShopifyAvailable = finelObj.isshopifyintegration;
            this.setState({ isShopifyAvailable: isShopifyAvailable });

            var countryFees = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES);
            if (countryFees) {
                this.setState({ currency: countryFees.Currency });
            }

            /* Stripe Post Order Data */
            if (Constants.PAYMENTTYPE === PAYMENT_TYPES.STRIPE_STANDARD) {
                this.setState({ isActive: true, errors: {} });
                let search = window.location.search;
                let params = new URLSearchParams(search);
                if (params.get(STRIPE_PAYMENT_STATUS.PAYMENT_INTENT) !== null && (params.get(STRIPE_PAYMENT_STATUS.PAYMENT_INTENT).length > 0)) {
                    var payment_intent = params.get(STRIPE_PAYMENT_STATUS.PAYMENT_INTENT);
                    if (params.get(STRIPE_PAYMENT_STATUS.PAYMENT_INTENT_CLIENT_SECRET) !== null && (params.get(STRIPE_PAYMENT_STATUS.PAYMENT_INTENT_CLIENT_SECRET).length > 0)) {
                        var payment_intent_client_secret = params.get(STRIPE_PAYMENT_STATUS.PAYMENT_INTENT_CLIENT_SECRET);
                        if (params.get(STRIPE_PAYMENT_STATUS.REDIRECT_STATUS) !== null && (params.get(STRIPE_PAYMENT_STATUS.REDIRECT_STATUS).length > 0)) {
                            var redirect_status = params.get(STRIPE_PAYMENT_STATUS.REDIRECT_STATUS);
                            if (redirect_status.toLowerCase() === STRIPE_PAYMENT_STATUS.SUCCEEDED.toLowerCase()) {
                                this.setState({ isActive: true, errors: {}, checked: true, loadingmessage: Constants.LoadingMssgafterpayment });
                                var transaction = payment_intent;
                                var objlength = Object.keys(finelObj).length;
                                if (objlength > 0 && transaction) {
                                    finelObj['transactionid'] = transaction;
                                    console.log("Order Data", JSON.stringify(finelObj));
                                    this.callPostOrderAPI(finelObj);

                                    this.props.history.push({
                                        pathname: RouteConstant.Payment
                                    })
                                }
                            } else if (redirect_status.toLowerCase() === STRIPE_PAYMENT_STATUS.PROCESSING.toLowerCase()) {
                                var errors = {};
                                errors['ErrorC'] = Messages.PAYMENTPROCESSING;
                                this.setState({ errors: errors, isActive: false, checked: true });
                            } else if (redirect_status.toLowerCase() === STRIPE_PAYMENT_STATUS.REQUIRES_PAYMENT_METHOD.toLowerCase()) {
                                var errors = {};
                                errors['ErrorC'] = Messages.REQUIRES_PAYMENT_METHOD;
                                this.setState({ errors: errors, isActive: false, checked: true });
                            } else {
                                var errors = {};
                                errors['ErrorC'] = Messages.ERR_SomethingWentWrong;
                                this.setState({ errors: errors, isActive: false, checked: true });
                            }
                        }
                    }
                } else {
                    var postData = {};
                    postData['storeid'] = storeId;
                    if (countryFees) {
                        postData['currency'] = countryFees.Currency.toLowerCase();
                    }
                    postData['totalorderamount'] = finelObj.TotalAmount;
                    postData['applicationfee'] = finelObj.ApplicationFee;
                    this.poststripepaymentclientidandsecretkey(authToken, postData);
                }
            } else if (Constants.PAYMENTTYPE === PAYMENT_TYPES.STRIPE_EXPRESS) {
                this.setState({ isActive: true, errors: {} });
                let search = window.location.search;
                let params = new URLSearchParams(search);
                if (params.get(STRIPE_PAYMENT_STATUS.PAYMENT_INTENT) !== null && (params.get(STRIPE_PAYMENT_STATUS.PAYMENT_INTENT).length > 0)) {
                    var payment_intent = params.get(STRIPE_PAYMENT_STATUS.PAYMENT_INTENT);
                    if (params.get(STRIPE_PAYMENT_STATUS.PAYMENT_INTENT_CLIENT_SECRET) !== null && (params.get(STRIPE_PAYMENT_STATUS.PAYMENT_INTENT_CLIENT_SECRET).length > 0)) {
                        var payment_intent_client_secret = params.get(STRIPE_PAYMENT_STATUS.PAYMENT_INTENT_CLIENT_SECRET);
                        if (params.get(STRIPE_PAYMENT_STATUS.REDIRECT_STATUS) !== null && (params.get(STRIPE_PAYMENT_STATUS.REDIRECT_STATUS).length > 0)) {
                            var redirect_status = params.get(STRIPE_PAYMENT_STATUS.REDIRECT_STATUS);
                            if (redirect_status.toLowerCase() === STRIPE_PAYMENT_STATUS.SUCCEEDED.toLowerCase()) {
                                this.setState({ isActive: true, errors: {}, checked: true, loadingmessage: Constants.LoadingMssgafterpayment });
                                var transaction = payment_intent;
                                var objlength = Object.keys(finelObj).length;
                                if (objlength > 0 && transaction) {
                                    finelObj['transactionid'] = transaction;
                                    console.log("Order Data", JSON.stringify(finelObj));
                                    this.callPostOrderAPI(finelObj);
                                }
                            } else if (redirect_status.toLowerCase() === STRIPE_PAYMENT_STATUS.PROCESSING.toLowerCase()) {
                                var errors = {};
                                errors['ErrorC'] = Messages.PAYMENTPROCESSING;
                                this.setState({ errors: errors, isActive: false, checked: true });
                            } else if (redirect_status.toLowerCase() === STRIPE_PAYMENT_STATUS.REQUIRES_PAYMENT_METHOD.toLowerCase()) {
                                var errors = {};
                                errors['ErrorC'] = Messages.REQUIRES_PAYMENT_METHOD;
                                this.setState({ errors: errors, isActive: false, checked: true });
                            } else {
                                var errors = {};
                                errors['ErrorC'] = Messages.ERR_SomethingWentWrong;
                                this.setState({ errors: errors, isActive: false, checked: true });
                            }
                        }
                    }
                } else {
                    var postData = {};
                    postData['storeid'] = storeId;
                    if (countryFees) {
                        postData['currency'] = countryFees.Currency.toLowerCase();
                    }
                    postData['totalorderamount'] = finelObj.TotalAmount;
                    postData['applicationfee'] = finelObj.ApplicationFee;
                    this.poststripepaymentclientidandsecretkey(authToken, postData);
                }
            } else {
                if (Constants.PAYMENTTYPE === PAYMENT_TYPES.PAYPAL) {
                    this.getpaymenttoken(authToken, plazaId, storeId);
                }
            }
        }
        else {
            this.props.history.push({
                pathname: RouteConstant.Dashboard
            })
        }
        this.getUserDetail(authToken);
    }

    getpaymenttoken(authToken, plazaId, storeId) {
        this.setState({ isActive: true, errors: {} });
        var API = PAYPALPAYMENT + "?" + TENANTID + plazaId + "&" + STOREID + storeId;
        GetData(API, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData) {
                        this.setState({
                            clientid: responseData.clientid,
                            merchantid: responseData.merchantid,
                            errors: {},
                            isActive: false
                        });
                    }
                    else {
                        var errors = {};
                        errors['ErrorC'] = Messages.ERR_SomethingWentWrong;
                        this.setState({ clientid: "", merchantid: "", errors: errors, isActive: false });
                    }
                }
                else {
                    var errors = {};
                    errors['ErrorC'] = Messages.ERR_SomethingWentWrong;
                    this.setState({ clientid: "", merchantid: "", errors: errors, isActive: false });
                }
            }).catch(error => {
                var errors = {};
                errors['ErrorC'] = Messages.ERR_SomethingWentWrong;
                this.setState({ clientid: "", merchantid: "", errors: errors, isActive: false });
            });
    }

    poststripepaymentclientidandsecretkey(authToken, postData) {
        this.setState({ isActive: true, errors: {} });
        PostData((Constants.PAYMENTTYPE === PAYMENT_TYPES.STRIPE_STANDARD) ? STRIPE_PAYMENT_INTENT_API : STRIPE_PLATFORM_PAYMENT_INTENT_API, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    this.setState({ striperesponse: responseData, errors: {}, isActive: false });
                }
                else {
                    var errors = {};
                    errors['ErrorC'] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors, isActive: false });
                }
            }).catch(error => {
                var errors = {};
                errors['ErrorC'] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    getUserDetail(authToken) {
        this.setState({ errors: {} });
        var API = GET_USERPROFILE_API;
        GetData(API, authToken)
            .then(result => {
                var responseJson = result;
                console.log('User Detail', JSON.stringify(responseJson));
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData) {
                        if (responseData.Addresslist) {
                            if (!this.state.billingAddObj) {
                                this.setState({ billingAddObj: responseData.Addresslist[0] ? responseData.Addresslist[0] : null })
                            }
                        }
                        this.setState({ phoneNo: formatToDigit(responseData.Phone1), userProfileObj: responseData, errors: {}, isPaymentEnable: true })
                    } else {
                        var errors = {};
                        errors['ErrorC'] = Messages.ERR_SomethingWentWrong;
                        this.setState({ errors: errors });
                    }
                }
            }).catch(error => {
                var errors = {};
                errors['ErrorC'] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors });
            });
    }

    async buy() {
        // Send the nonce to your server
        var paymentclick = document.getElementById('paymentclick');
        if (paymentclick) {
            paymentclick.style.pointerEvents = "none";
        }
        const { nonce } = await this.instance.requestPaymentMethod();
        await fetch(`server.test/purchase/${nonce}`);

        var objlength = Object.keys(this.state.finelObj).length;
        if (objlength > 0) {
            // $('#paymentModal').modal('hide');
            this.handleModal(false, 'paymentModal')
            var finelObj = this.state.finelObj;
            finelObj['transactionid'] = nonce;
            this.callPostOrderAPI(finelObj);
        }
    }

    PaymentDone = orderData => {
        var transaction = orderData?.purchase_units[0]?.payments?.captures[0]?.id;
        var objlength = Object.keys(this.state.finelObj).length;
        if (objlength > 0 && transaction) {
            // $('#paymentModal').modal('hide');
            this.handleModal(false, 'paymentModal')
            var finelObj = this.state.finelObj;
            finelObj['transactionid'] = transaction;
            this.callPostOrderAPI(finelObj);
        }
    }

    StripePaymentDone = orderData => {
        // $('#paymentModal').modal('hide');
        this.handleModal(false, 'paymentModal')
    }

    callPostOrderAPI(postData) {
        this.setState({ isActive: true });
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var URL = STOREORDERAPI;
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    RemoveDataInStorage(Constants.Obj_CART);
                    RemoveDataInStorage(Constants.finelObj_BeforePayemt);
                    var errors = {};
                    this.setState({ errors: errors, isActive: false });
                    window.location.href = RouteConstant.Dashboard;
                }
                else {
                    var paymentclick = document.getElementById('paymentclick')
                    if (paymentclick) {
                        paymentclick.style.pointerEvents = "auto";
                    }
                    var errors = {};
                    errors['ErrorC'] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors, isActive: false });
                }
            }).catch(error => {
                var errors = {};
                errors['ErrorC'] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    handleCheck() {
        if (this.state.checked) {
            this.setState({ checked: false })
        } else {
            this.setState({ checked: true })
        }
    }

    // modal method 19-may-2023
    handleModal(falg, id) {
        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById(id);

        if (backgroung && modalId) {
            backgroung.style.display = falg ? "block" : "none";
            modalId.style.display = falg ? "block" : "none";
        }
    }

    closemyModal() {
        this.handleModal(false, 'myModal')
    }

    closepaymentModal() {
        this.handleModal(false, 'paymentModal')
    }

    //

    handleContinue() {
        if (!this.state.checked) {
            // $('#myModal').modal('show');
            this.handleModal(true, 'myModal')
        }
        else {
            //   this.setState({ clientToken: 'sandbox_tv9xp9ky_dys582bq5byt6yh3' })
            // $('#paymentModal').modal('show');
            this.handleModal(true, 'paymentModal')
        }
    }



    handleContinueShopping() {
        this.props.history.push({
            pathname: this.state.isShopifyAvailable ? RouteConstant.PlazaStoreItemsShopify : RouteConstant.PlazaStoreItems,
        });
    }

    render() {

        var currencySymbole = "";
        var countryFees = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES);
        if (countryFees) {
            currencySymbole = (countryFees[currencyInfo.CountryCode] ? "(" + countryFees[currencyInfo.CountryCode] + ") " : "") + countryFees.Symbol;
        }

        return (
            <div>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={this.state.loadingmessage}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="">
                    <div className="modal-background" id="modalbackgroundId"> </div>
                    <Header setHeaderTitle={Constants.Title_Payment} />
                    <section className="section">
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                            <div className="reg_form_main">
                                {/* Comment on policy link use */}
                                {/* <h4 style={{ color: Color.THEME_COLOR }}>Privacy Policy</h4>
                                <div className="contentpanel" >
                                    <p>Pidex Inc. ("Pidex") is a leading provider of software and services.</p>
                                    <br />
                                    <p>
                                        Pidex is headquartered in Missisauga Ontario, Canada. We support our customers with a full range of managed and professional computing services, and collaborate with an array of business partners.
                                    </p>
                                    <br />

                                    <p>
                                        Pidex is committed to protecting any personal information we collect. To this end, we have prepared this Privacy Policy to communicate our ongoing commitment to ensuring personal information remains accurate, confidential and used only for identified purposes, regardless of how it is provided to us
                                    </p>
                                    <br />

                                    <p>
                                        Pidex believes that having a relationship with our customers that is founded on trust is not merely the law but also good business. We believe in giving full disclosure about the kinds of information we gather and what we do with it. We are committed to ensuring that privacy of our customers and visitors is protected. We are responsible for the personal information we collect, use, maintain and disclose. To ensure this accountability, we have developed this Privacy Policy, and have provided training to our employees on how to properly implement our privacy policies and practices.
                                    </p>
                                    <br />

                                    <p>
                                        What is "Personal Information?"
                                    </p>
                                    <br />

                                    <p>
                                        The term "Personal Information" refers to any information that identifies an individual or by which an identity could be derived but does not include corporate or business contact information about an identifiable individual. Personal information also excludes aggregated information that is no longer related to a specific identifiable individual. Pidex reserves the right to use aggregated or anonymous information in any way it considers appropriate. This includes anonymous or aggregated information that we obtain from you as a visitor to our web site or by using our programs and software.
                                    </p>
                                    <br />

                                    <p>
                                        Collected Personal Information
                                    </p>
                                    <br />

                                    <p>
                                        Pidex collects personal information from our customers and visitors for a variety of reasons. We may collect personal information in the furtherance of our contractual obligations.
                                    </p>
                                    <br />

                                    <p>
                                        In serving the needs of our visitors and customers, Pidex believes that it is important to have accurate and timely information. Therefore, we may collect information through our website, personal contact and through the use of our software solutions. The kinds of personal information we will ask for may include your name, e-mail address, phone number, address, type of business, as well as such other personal information that is needed to fulfill the purposes of a particular statement of work or to further its obligations under a particular program or as identified in this Privacy Policy. Sometimes we will ask for other personal information to fulfill the needs of the particular application or service agreement requested and authorized by our visitors and customers.
                                    </p>
                                    <br />

                                    <p>
                                        We do not solicit customers of our clients, unless authorized specifically in writing to do so by the client.
                                    </p>
                                    <br />

                                    <p>
                                        We also collect and store statistics and other information about the online activities of visitors to the Pidex web site or through the use of Pidex's software solutions. These statistics do not contain individual identifying information and constitute aggregated or anonymous data only.
                                    </p>
                                    <br />

                                    <p>
                                        Customers may be invited to receive an email newsletter by providing an email address. Customer email addresses and any personal customer information will not be distributed or shared with third parties. Customers can opt out of being contacted by us, or receiving such information from us, at any time by sending an email to privacyofficer@zuzapp.com
                                    </p>
                                    <br />

                                    <p>
                                        Business customers may be using our products and services in connection with the use of data and information about their customers and prospects ("Data"). Sometimes we may also obtain Data from other sources, a government agency or registry, or other individuals or organizations that our customers have provided informed consent to contact. Pidex will not review, share, distribute, print, or reference any such Data except as provided in the agreement between us and our customer (the "Agreement"), or as may be required by law. The agreement between Pidex and our business customer may provide for the use of non-identifying information to identify and analyze data for business purposes. In no circumstance will the Personal Information of a specific end-user be transferred, sold, or shared with a third party without their express written consent.
                                    </p>
                                    <br />

                                    <p>
                                        Individual records may at times be viewed or accessed only for the purpose of resolving a problem, support issue, or suspected violation of the Agreement, or as may be required by law. Of course, customers are responsible for maintaining the confidentiality and security of their user registrations and passwords.
                                    </p>
                                    <br />

                                    <label>
                                        Consent
                                    </label>
                                    <br />

                                    <p>
                                        Consent for the collection, use and/or disclosure of personal information may be obtained orally, electronically or in writing and may be expressly given or implied. In determining how we obtain consent, we will take into account the sensitivity or the personal information that we are collecting, using and/or disclosing.
                                    </p>
                                    <br />

                                    <p>
                                        We may use personal information to provide products or services, including for billing and credit purposes. Other uses of personal information may include the detection of fraud, security or technical issues, and to protect against imminent harm to the rights, property, or safety of Pidex, or the public as permitted or required by law. We will not send any further material to anyone that informs us that they no longer wish to receive information about our services or about new developments.
                                    </p>
                                    <br />

                                    <p>
                                        Disclosure of Personal Information
                                    </p>
                                    <br />

                                    <p>
                                        Under certain circumstances, we may disclose personal information when we are required by law to do so or when we have consent for the disclosure. We also reserve the right to disclose personal information to third parties to help collect payment for any product purchase or service. Personal information may also be transferred to third-party services providers for administrative purposes (e.g. computer back-up service) and the third party is bound by an Agreement and our Privacy Policy. Public information may also be freely disclosed unless you have requested that it not be disseminated.
                                    </p>
                                    <br />

                                    <p>
                                        Security of Your Personal Information
                                    </p>
                                    <br />

                                    <p>
                                        We take all reasonable precautions to ensure that personal information is kept safe from loss, unauthorized access, modification, or disclosure. We operate on a secure data network protected by an industry standard firewall and password protected systems. Our security and privacy policies are periodically reviewed and enhanced, and only authorized individuals at Pidex have access to personal information unless we have consent for access by a third party through an agreement.
                                    </p>
                                    <br />

                                    <p>
                                        Pidex employs industry standard technological safeguards such as network security software and hardware firewalls to prevent hacking or unauthorized computer access. Personal Information is password protected on all of our internal networks and continuously monitored by full-time network security personnel. All employees and third parties, including licensed users of our software applications that have access to personal information collected by us, are required to sign a confidentiality agreement in relation to this Privacy Policy.
                                    </p>
                                    <br />

                                    <p>
                                        Links to Other Websites or Services
                                    </p>
                                    <br />

                                    <p>
                                        Our website contains links to other websites. Pidex is not responsible for the privacy practices or the content of such websites. Pidex is not responsible for third-party actions and disclosure of personal information by such third parties.
                                    </p>
                                    <br />

                                    <p>
                                        Email Addresses and Cookies
                                    </p>
                                    <br />

                                    <p>
                                        Pidex recognizes that SPAM is a growing problem on networks of all sizes and we are doing our part to prevent its spread. To prevent the growth of SPAM and in accordance with applicable laws, Pidex will not share or sell email addresses to mass marketers or to other third parties.
                                    </p>
                                    <br />

                                    <p>
                                        Cookies are pieces of data assigned by a web server and stored on a computer's hard drive for later retrieval. We do not use cookies.
                                    </p>
                                    <br />

                                    <p>
                                        Retention of Your Personal Information
                                    </p>
                                    <br />

                                    <p>
                                        Pidex keeps personal information for as long as is reasonably necessary for us to complete the objectives set out at the time of collecting the information. When performing services we may retain personal information as long a reasonably necessary to fulfill our contractual obligations. We will retain personal information for as long as our Agreement with our customer requires, or as may be required by law, whichever is longer.
                                    </p>
                                    <br />

                                    <p>
                                        Changes to this Privacy Policy
                                    </p>
                                    <br />

                                    <p>
                                        Since Pidex regularly reviews all of its policies and procedures, Pidex may change its Privacy Policy from time to time. All changes to this policy will be posted promptly.
                                    </p>
                                    <br />

                                    <p>
                                        Request for Access and Inquiries/Complaints
                                    </p>
                                    <br />

                                    <p>
                                        If you have any questions please contact our privacy officer at: privacyofficer@zuzapp.com.
                                    </p>
                                    <br />
                                </div> */}
                                <iframe className="contentpanel" src={RouteConstant.TermsOfService + "?" + TERMS_OF_SERVICE_IN_VISIBLE_MODE.MODE + "=" + TERMS_OF_SERVICE_IN_VISIBLE_MODE.PRIVATE_MODE} title="Privacy Policy"></iframe>
                            </div>

                            <div className="col-sm-12" style={{}} >
                                <label
                                    className="col-sm-12"
                                    style={{ marginRight: "auto", float: "left", paddingTop: "5px" }}>
                                    Total Amount:  <label style={{ color: Color.THEME_COLOR, fontWeight: 'bold' }}>{currencySymbole}{Number(this.state.totalamount).toFixed(2)}</label>
                                </label>
                                <div
                                    className="col-sm-12"
                                    style={{ marginRight: "auto", float: "left" }}>
                                    <Checkbox
                                        color={Color.THEME_COLOR}
                                        size={2}
                                        tickSize={2}
                                        checked={this.state.checked}
                                        delay={0}
                                        backAnimationDuration={0}
                                        tickAnimationDuration={0}
                                        onChange={this.handleCheck}></Checkbox>
                                    &nbsp; &nbsp;<text className="textcolor" style={{ fontWeight: 'bold' }}>I agree to the Privacy Policy. <PutMandatoryfieldSymbol /></text>
                                </div>

                                <div className="col-sm-12">
                                    <div className="errorMsg" style={{ textAlign: 'center' }}>
                                        {this.state.errors.ErrorC}
                                    </div>
                                </div>

                                <div className="bottam_sing" >
                                    <label
                                        style={{ float: "center", borderRadius: "4px", minWidth: "236px" }}
                                        for="step5"
                                        id="continue-step5"
                                        onClick={this.handleContinue}
                                        className="btn btn-done"
                                    > Continue</label>
                                </div>

                                <div className="bottam_sing">
                                    <label
                                        style={{ float: "center", borderRadius: "4px", marginBottom: "10px", marginTop: '0px', minWidth: "236px" }}
                                        for="step5"
                                        id="continue-step5"
                                        onClick={() => this.handleContinueShopping()}
                                        className="btn btn-done"
                                    > Continue Shopping</label>
                                </div>

                            </div>

                            <div class="modal" id="myModal" style={{ verticalAlign: "middle" }}>
                                <div class="modal-dialog dTip-modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-body">
                                            <center>
                                                <label>{COMPANYCONST.BREWERSNEARME}</label><br />
                                                <label>Please agree to the privacy policy.</label>
                                            </center>
                                        </div>
                                        <div class="modal-footer setaTag" onClick={() => this.closemyModal()}>
                                            <label
                                                style={{ color: Color.THEME_COLOR, marginLeft: "auto", marginRight: "auto" }}
                                                data-dismiss="modal"
                                            > OK</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="modal" id="paymentModal" style={{ verticalAlign: "middle" }}>
                                <div class="modal-dialog" style={{ top: "25%" }}>
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <label className="textcolor">Select Payment Method</label>
                                            <button type="button" class="close" onClick={() => this.closepaymentModal()}>&times;</button>
                                        </div>

                                        <div class="modal-body">
                                            <div style={{ margin: "0px" }}>

                                                {/*For Paypal Payment Flow */}
                                                {(Constants.PAYMENTTYPE === PAYMENT_TYPES.PAYPAL
                                                    && this.state.clientid
                                                    && this.state.merchantid)
                                                    &&
                                                    <Payple
                                                        ClientId={this.state.clientid}
                                                        merchantId={this.state.merchantid}
                                                        Amount={this.state.totalamount}
                                                        currency={this.state.currency}
                                                        billingAddress={this.state.billingAddObj}
                                                        phoneNumber={this.state.phoneNo}
                                                        UserProfile={this.state.userProfileObj}
                                                        successPayment={this.PaymentDone} />
                                                }

                                                {/* For Stripe Standard Payment Flow */}
                                                {(Constants.PAYMENTTYPE === PAYMENT_TYPES.STRIPE_STANDARD
                                                    && this.state.isPaymentEnable
                                                    && this.state.striperesponse
                                                    && this.state.striperesponse.publickey
                                                    && this.state.striperesponse.clientsecrate
                                                    && this.state.striperesponse.stripeaccountid)
                                                    &&
                                                    <StripeStandardPay
                                                        publickey={this.state.striperesponse.publickey}
                                                        clientsecrate={this.state.striperesponse.clientsecrate}
                                                        successPayment={this.StripePaymentDone}
                                                        billingAddress={this.state.billingAddObj}
                                                        UserProfile={this.state.userProfileObj}
                                                        phoneNumber={this.state.phoneNo}
                                                        stripeaccountid={this.state.striperesponse.stripeaccountid} />
                                                }

                                                {/* For Stripe Express Payment Flow */}
                                                {(Constants.PAYMENTTYPE === PAYMENT_TYPES.STRIPE_EXPRESS
                                                    && this.state.isPaymentEnable
                                                    && this.state.striperesponse
                                                    && this.state.striperesponse.publickey
                                                    && this.state.striperesponse.clientsecrate)
                                                    &&
                                                    <StripeExpressPay
                                                        publickey={this.state.striperesponse.publickey}
                                                        clientsecrate={this.state.striperesponse.clientsecrate}
                                                        successPayment={this.StripePaymentDone}
                                                        billingAddress={this.state.billingAddObj}
                                                        UserProfile={this.state.userProfileObj}
                                                        phoneNumber={this.state.phoneNo} />
                                                }

                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            {/* <center>
                                                <label
                                                    id="paymentclick"
                                                    style={{ float: "center", borderRadius: "50px", marginBottom: "10px" }}
                                                    onClick={this.buy.bind(this)}
                                                    className="btn btn-done"
                                                > Buy</label>
                                            </center> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </LoadingOverlay>
            </div>
        );
    }
}

export default Payment;