import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from "react";
import StripeExpressCheckoutForm from './StripeExpressCheckoutForm';
import "./StripeExpressPay.css";

function StripeExpressPay({ publickey, clientsecrate, successPayment, billingAddress, UserProfile, phoneNumber, stripepaymentprogressshow, stripepaymentprogresshide }) {

    /* console.log(publickey, "-", clientsecrate); */

    const stripePromise = loadStripe(publickey);

    const appearance = {
        theme: 'stripe',
    };

    const options = {
        clientSecret: clientsecrate,
        appearance: appearance
    };

    return (
        <Elements
            stripe={stripePromise}
            options={options}>
            <StripeExpressCheckoutForm
                successPayment={successPayment}
                billingAddress={billingAddress}
                UserProfile={UserProfile}
                phoneNumber={phoneNumber} />
        </Elements>
    )
}

export default StripeExpressPay;