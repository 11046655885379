import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import "../src/styles/style.css";

//import "../src/styles/font-awesome.min.css";
//import "../src/styles/icons.css";
import "../src/styles/jquery-ui.css";
import "../src/styles/daterangepicker.css";
import "../src/styles/calendar.css";
import './asset/css/style.css';
import './asset/css/customreplace.css'

ReactDOM.render(<App />, document.getElementById("root"));
registerServiceWorker();
