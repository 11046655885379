import React, { Component } from 'react';
import 'react-data-table-component-extensions/dist/index.css';
import Color from '../../Config/Color';
import Constants, { currencyInfo } from '../../Config/Constants';
import { RetriveObjectFromStorage } from '../../Config/Utils';
import NoPriview from '../../../../src/images/nopreviewp.png';

var totalPrice = 0.0;

class SizeColorItemSingleStore extends Component {
    constructor(props) {
        super(props);

        this.state = {

            itemlogo: "",
            itemPrice: "",
            totalPrice: "",
            itemCount: 1,
            toppingCategoryList: [],
            cartItems: [],
            toppingCharge: 0.00,

            storeData: {},
            // plazaData: {},
            itemData: {},

            cartStore: [], // use to make final cart list
            itemsInCart: [], // use to make final cart list

            finalCart: [],

            countCartItem: 0,
        }

        this.handleMinus = this.handleMinus.bind(this);
        this.handlePlus = this.handlePlus.bind(this);
        this.countTotal = this.countTotal.bind(this);
        this.handleAddToCart = this.handleAddToCart.bind(this);
    }

    componentDidMount() {
        // var objPlaza = RetriveObjectFromStorage(Constants.Obj_PLAZA, '');
        var objStore = RetriveObjectFromStorage(Constants.Obj_STORE, '');

        if (objStore) {
            // this.setState({ plazaData: objPlaza });
            this.setState({ storeData: objStore });
        }
    }

    handleMinus() {
        var count = this.props.itemCount;
        if (count !== 1) {
            count = count - 1;
            this.props.setItemCount(count);
            this.setState({
                itemCount: count
            }, () => {
                this.countTotal(this.props.itemCount);
            });
        }
    }

    handlePlus() {
        var count = this.props.itemCount;
        count = count + 1;
        if (this.props.TrackQuantity) {
            if (this.props.AvailableQuantity >= count) {
                this.props.setItemCount(count);
                this.setState({
                    itemCount: count
                }, () => {
                    this.countTotal(count);
                });
            }
        }
        else {
            this.props.setItemCount(count);
            this.setState({
                itemCount: count
            }, () => {
                this.countTotal(count);
            });
        }
    }

    countTotal(count) {
        var price = this.props.itemPrice;
        price = (price + 0) * count;
        price = (Math.round(price * 100) / 100).toFixed(2);
        this.props.setTotalPrice(price);
    }

    setTotalPrice() {
        var price = this.props.itemPrice
        var noOfItems = this.props.itemCount;
        var amount = (price + 0) * noOfItems;
        amount = (Math.round(amount * 100) / 100).toFixed(2);

        this.props.setTotalPrice(amount)
        this.setState({ totalPrice: amount })
    }

    handleAddToCart() {
        this.setState({ countCartItem: 1, });
        var itemData = this.props.itemData;
        this.props.selectedToppings(itemData);
        $('#sizecolorModal').modal('hide');
    }

    render() {
        const { toppingList, countCartItem, itemData } = this.state;

        var toppingCategoryList = [];
        if (this.props.itemData.ToppingCategory) {
            toppingCategoryList = this.props.itemData.ToppingCategory
        }

        var currencySymbole = ""
        var countryFees = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES)
        if (countryFees) {
            currencySymbole = (countryFees[currencyInfo.CountryCode] ? "(" + countryFees[currencyInfo.CountryCode] + ") " : "") + countryFees.Symbol;
        }

        return (
            <div>
                <section className="section">
                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                        <div style={{ marginLeft: "0px", marginTop: "10px" }}>
                            <div className="col-sm-12" style={{ marginBottom: "10px", alignItems: 'center', display: 'flex', gap: '2rem' }}>
                                <img src={this.props.itemData.ItemLogo ? this.props.itemData.ItemLogo : NoPriview}
                                    onError={(e) => (e.target.onerror = null, e.target.src = NoPriview)} loading='lazy'
                                    style={{ height: "100px", width: "100px" }}></img>
                                <div>
                                    <label style={{ display: 'block', color: Color.BLACK, fontWeight: 'bold', fontSize: '15px' }}>{this.props.itemData.ItemName} </label>
                                    <label style={{ display: 'block', color: Color.THEME_COLOR, fontSize: '15px' }}>{currencySymbole} {Number(this.props.itemPrice).toFixed(2)}</label>
                                    <label style={{ color: Color.BLACK, fontSize: '15px', display: 'block' }}><span className="textcolor">Details: </span>{this.props.itemData.ItemDescription} </label>
                                </div>
                            </div>
                        </div>

                        {this.props.itemData && this.props.itemData.ItemSizeColor && this.props.itemData.ItemSizeColor.length > 0 &&
                            <div className="mainattBox mb-2">
                                <span className="textcolor setline"> {this.state.optionObj1?.name}Sizes :- </span>
                                <div>
                                    {this.props.itemData.ItemSizeColor.map(value => (
                                        <div key={value} className={this.props.itemData.size == value.size ? 'sizeBox clicksizeBox' : 'sizeBox'}
                                            onClick={(e) => this.props.setSize(value)}>
                                            <label className="sizeboxitem">{value.size}</label>
                                        </div>
                                    ))}
                                </div>
                            </div>}

                        {this.props.itemData && this.props.itemData.ItemSizeColor && this.props.itemData.ItemSizeColor.length > 0 &&
                            <div className="mainattBox mb-2">
                                <span className="textcolor setline"> {this.state.optionObj1?.name}Colors :- </span>
                                <div>
                                    {this.props.itemData.ItemSizeColor.map(value => {
                                        if ((value.size == this.props.itemData.size) && value.colors && value.colors.length > 0) {
                                            return value.colors.map(colorobj => {
                                                return <div key={colorobj} className={this.props.itemData.color == colorobj.color ? 'sizeBox clicksizeBox' : 'sizeBox'}
                                                    onClick={(e) => this.props.setColor(colorobj)}>
                                                    <label className="sizeboxitem">{colorobj.color}</label>
                                                </div>
                                            })
                                        }
                                        return ""
                                    })}
                                </div>
                            </div>}

                        <div className="row" style={{ padding: "5px" }} >
                            <label style={{ fontSize: "25px", marginLeft: "20px", marginRight: "20px" }} onClick={this.handleMinus}>-</label>
                            <label style={{ fontSize: "25px", marginLeft: "10px", marginRight: "10px", width: "30px" }}> {this.props.itemCount} </label>
                            <label style={{ fontSize: "25px" }} onClick={this.handlePlus}>+</label>
                            <span style={{ marginLeft: "auto" }}>
                                <label className="textcolor" style={{ float: "right", marginRight: "15px" }}>{currencySymbole} {Number(this.props.totalPrice).toFixed(2)}</label>
                            </span>
                        </div>

                        <div
                            className="bottam_sing"
                            style={{ marginTop: "20px", bottom: "0px" }}>
                            <div className="">
                                <label
                                    style={{ borderRadius: "4px", marginBottom: "10px" }}
                                    onClick={this.handleAddToCart}
                                    className="col-sm-6 btn btn-done"
                                > Update Cart</label>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default SizeColorItemSingleStore;

