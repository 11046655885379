import Moment from "moment";
import React, { Component } from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { Radio, RadioGroup } from 'react-radio-group';
import Color from '../../Config/Color';
import './CheckoutSingleStore.css';
import { PickUpAndDeliveryTimeSplit } from "../../Config/Utils";

class DeliveryTimeDay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateArray: [],
            pickUpTimes: props.pickUpTimesList,
            countryFees: "",
            selectedValue: "",
            selected: 0,
            storeid: props.storeid,
            plazaid: props.plazaid,
            Storehourdeliverytimelist: [],
            selectedValueObj: null
        }
        this.handleChangeRadioBtn = this.handleChangeRadioBtn.bind(this);
    }

    componentDidMount() {
        this.setDateArray();
    }

    setDateArray = () => {
        if (this.props.storehourlist && this.props.storehourlist.length > 0) {
            var StoreCurrentDate = this.props.storehourlist[0];
            if (StoreCurrentDate && StoreCurrentDate.storecurrenttime) {
                var strdate = StoreCurrentDate.storecurrenttime.slice(0, -1);
                const current = Moment(strdate);
                let n = 10;
                var resultDates = [];
                var Storehourlist = [];

                while (n > 0) {
                    var StoreTimeObject = this.props.storehourlist.filter(e => e.day == current.format("dddd"))[0];
                    if (StoreTimeObject) {
                        Storehourlist.push(StoreTimeObject);
                    }
                    resultDates.push(current.format("dddd DD MMM YYYY"));
                    current.add(1, "day");
                    n--;
                }

                var TempObj = {};
                if (Storehourlist[0] && Storehourlist[0].ispickupenable
                    && Storehourlist[0].currentdeliverytimes
                    && Storehourlist[0].currentdeliverytimes.length > 0) {
                    TempObj = JSON.parse(JSON.stringify(Storehourlist[0]));
                    TempObj.deliverytimes = TempObj.currentdeliverytimes;
                    Storehourlist[0] = JSON.parse(JSON.stringify(TempObj));
                }

                this.setState({
                    dateArray: resultDates,
                    Storehourdeliverytimelist: Storehourlist,
                    selectedValueObj: (
                        Storehourlist[0]
                        && Storehourlist[0].isdeliveryenable
                        && Storehourlist[0].currentdeliverytimes
                        && Storehourlist[0].currentdeliverytimes.length > 0) ? Storehourlist[0] : null,
                    selected: (
                        Storehourlist[0]
                        && Storehourlist[0].isdeliveryenable
                        && Storehourlist[0].currentdeliverytimes
                        && Storehourlist[0].currentdeliverytimes.length > 0) ? 0 : -1,
                    selectedValue: (
                        Storehourlist[0]
                        && Storehourlist[0].isdeliveryenable
                        && Storehourlist[0].currentdeliverytimes
                        && Storehourlist[0].currentdeliverytimes.length > 0) ? Storehourlist[0].currentdeliverytimes[0] : ""
                });
            }
        }
    }

    onSelect = key => {
        key = Number(key);
        let Storehourdeliverytimelist = this.state.Storehourdeliverytimelist;
        if (key === 0) {
            if (Storehourdeliverytimelist[key]
                && Storehourdeliverytimelist[key].isdeliveryenable
                && Storehourdeliverytimelist[key].currentdeliverytimes
                && Storehourdeliverytimelist[key].currentdeliverytimes.length > 0) {

                var date = Moment(this.state.dateArray[key]).format("DD MMM YYYY");
                let selectedValue = Storehourdeliverytimelist[key].currentdeliverytimes[0];

                var selectedValueObj = Storehourdeliverytimelist[key];
                selectedValueObj.deliverytimes = selectedValueObj.currentdeliverytimes;

                this.setState({
                    selected: key,
                    selectedValueObj: selectedValueObj,
                    selectedValue: selectedValue
                });

                this.props.setPickupDay(date, this.state.storeid, this.state.plazaid, selectedValue);
            }
        } else {
            if (Storehourdeliverytimelist[key]
                && Storehourdeliverytimelist[key].isdeliveryenable
                && Storehourdeliverytimelist[key].deliverytimes
                && Storehourdeliverytimelist[key].deliverytimes.length > 0) {

                var date = Moment(this.state.dateArray[key]).format("DD MMM YYYY");
                let selectedValue = Storehourdeliverytimelist[key].deliverytimes[0];

                this.setState({
                    selected: key,
                    selectedValueObj: Storehourdeliverytimelist[key],
                    selectedValue: selectedValue
                });

                this.props.setPickupDay(date, this.state.storeid, this.state.plazaid, selectedValue);
            }
        }
    }

    handleChangeRadioBtn(value) {
        this.setState({ selectedValue: value });
        this.props.setPickupTime(value, this.state.storeid, this.state.plazaid);
    }

    render() {
        const { countryFees, selectedValueObj } = this.state

        const MenuItem = ({ text, key, selected, storehour, index }) => {
            var colorCode;
            var backgroundcolor;
            var dayfontcolor;
            if (this.state.selected == index) {
                colorCode = Color.THEME_COLOR;
                backgroundcolor = Color.THEME_COLOR;
                dayfontcolor = Color.WHITE;
            }
            else {
                colorCode = Color.GREY;
                backgroundcolor = Color.WHITE;
                dayfontcolor = Color.BLACK;
            }

            return <div className={`menu-item ${selected == index ? 'active' : ''}`} style={{ backgroundColor: backgroundcolor, width: "100px", marginTop: '5px', marginLeft: "10px", height: "80px", marginBottom: "5px", border: "2px solid" + colorCode, borderRadius: "4px", padding: '0px', textAlign: 'center' }}>
                <label style={{
                    width: "48px", whiteSpace: "break-spaces",
                    height: "50px", fontSize: "12px", color: index === 0 ? ((storehour[index] && storehour[index].isdeliveryenable && storehour[index].currentdeliverytimes && storehour[index].currentdeliverytimes.length > 0) ? dayfontcolor : Color.GREY) : ((storehour[index] && storehour[index].isdeliveryenable && storehour[index].deliverytimes && storehour[index].deliverytimes.length > 0) ? dayfontcolor : Color.GREY), fontWeight: 'bold'
                }}>{text}</label>
            </div>;
        };

        const Menu = (list, selected) =>
            list.map((item, index) => {
                return <MenuItem text={item} key={index} index={index} selected={this.state.selected} storehour={this.state.Storehourdeliverytimelist} />;
            });

        this.menuItems = Menu(this.state.dateArray, this.state.selected);
        const menu = this.menuItems;

        let list = []
        if (selectedValueObj && selectedValueObj.deliverytimes) {
            list = selectedValueObj.deliverytimes
                .map(function (item, index) {

                    /* browser current time */
                    /* let [st, et] = item ? item.split("-") : ["24", "1"];
                    let currentTime = PickUpAndDeliveryTimeSplit(Moment().format('hh:mm A')), startTime = PickUpAndDeliveryTimeSplit(st), endTime = PickUpAndDeliveryTimeSplit(et.trim());
                    var isActive = (startTime < endTime && currentTime < endTime); */

                    return (/* isActive ? */
                        <React.Fragment key={index}>
                            <div style={{ borderBottom: "1px solid" + Color.GREY, paddingBottom: '5px', paddingTop: '5px' }}>
                                <Radio value={item} style={{ paddingTop: "5px", color: Color.BLACK, accentColor: Color.THEME_COLOR, width: '15px', height: '15px' }} />&nbsp;{item}<br />
                            </div>
                        </React.Fragment> /* : "" */
                    );
                }, this);
        }

        return (
            <React.Fragment>
                <div className="col-sm-12 p-0" style={{ borderBottom: "1px solid" + Color.GREY, marginBottom: "5px", border: "2px solid" + Color.THEME_COLOR, borderRadius: "4px" }}>
                    <ScrollMenu
                        data={menu}
                        selected={this.state.selected}
                        onSelect={this.onSelect}
                        alignCenter={false}
                        innerWrapperStyle={{ display: "flex" }} />
                </div>
                <div>
                    <RadioGroup id={this.state.storeid} selectedValue={this.state.selectedValue} onChange={this.handleChangeRadioBtn}>
                        {list}
                    </RadioGroup>
                </div>
            </React.Fragment>
        );
    }
}

export default DeliveryTimeDay;