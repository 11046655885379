
import React from 'react';
import cookie from 'react-cookies'
import Constants from './Constants'





const defaultCart = {
    cart: []
} //Insert the default value here.
export const MainContext = React.createContext(defaultCart);

// const defaultPlaza = {plaza: []} //Insert the default value here.
// export const PlazaContext = React.createContext(defaultPlaza);

// const defaultStore = {store: []} //Insert the default value here.
// export const StoreContext = React.createContext(defaultStore);

// const defaultItem = {item: []} //Insert the default value here.
// export const ItemContext = React.createContext(defaultItem);



export function validateEmail(email) {
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return expression.test(String(email).toLowerCase())
}

export function validatePassword(password) {
    const expression = /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).*$/;
    return password.match(expression);
}

export function parseTimetoHour(s) {
    try {
        var part = s.match(/(\d+)(?:)?(am|pm)?/i);
        var hh = parseInt(part[1], 10);
        // var mm = parseInt(part[2], 10);
        var ap = part[2] ? part[2].toUpperCase() : null;
        if (ap === "AM") {
            if (hh == 12) {
                hh = 0;
            }
        }
        if (ap === "PM") {
            if (hh != 12) {
                hh += 12;
            }
        }
        return hh;
    }
    catch (err) {
        console.log(err);
        return 39;
    }
}

export function validateUserName(name) {
    const expression = /^[a-zA-Z ]*$/;
    return name.match(expression);
}

export function validatePhoneNo(phone) {
    const expression = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    //const regex = /^[0-9\b]+$/;
    return phone.match(expression);
}

export function validateOtpNo(otp) {
    const expression = /^[0-9\b]+$/;
    return otp.match(expression);
}

export function validateFloat(value) {
    var value = value.toString()
    const expression = /^\d+(\.\d+)*$/;
    return value.match(expression);
}

export function validateInt(value) {
    var value = value.toString()
    const expression = /^[0-9]+$/;
    return value.match(expression);
}

export const StoreDataInStorage = (KEY, VALUE) => {
    try {
        localStorage.setItem(KEY, VALUE);
        console.log("Utils.StoreDataInStorage -> save ",);
    } catch (err) {
        return err;
    }
}

export const RetriveDataFromStorage = (KEY) => {
    try {
        const value = localStorage.getItem(KEY);
        //console.log("Utils.RetriveDataFromStorage -> value ", value );    
        return value;
    } catch (err) {
        return err;
    }
};

export const RemoveDataInStorage = (KEY) => {
    try {
        localStorage.removeItem(KEY);
        console.log("Utils.RemoveDataInStorage -> remove ",);
    } catch (err) {
        return err;
    }
}


export const StoreObjectInStorage = (KEY, VALUE) => {
    try {
        localStorage.setItem(KEY, JSON.stringify(VALUE));
    } catch (err) {
        return err;
    }
}

export const RetriveObjectFromStorage = (KEY) => {
    try {
        var value = JSON.parse(localStorage.getItem(KEY));
        return value;
    } catch (err) {
        return err;
    }
};


export const setCookie = (KEY, VALUE) => {
    try {
        cookie.save(KEY, VALUE)
    } catch (err) {
        return err;
    }
}

export const getCookie = (KEY) => {
    try {
        var value = cookie.load(KEY)
        return value;
    } catch (err) {
        return err;
    }
};

export const removeCookie = (KEY) => {
    try {
        cookie.remove(KEY)
    } catch (err) {
        return err;
    }
}

export const removeAllSpace = (value) => {
    try {
        return String(value).replace(/\s/g, '');
    }
    catch (err) {
        console.log(err);
        return value;
    }
}

export const checkPostalCode = (addressArray) => {
    try {
        for (var strref in addressArray) {
            if (addressArray[strref] == "postal_code") {
                return true
            }
        }
        return false
    }
    catch (err) {
        console.log(err)
        return false
    }
}

export function formatPhoneNumber(phoneNumberString) {
    try {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '' + match[1] + '-' + match[2] + '-' + match[3];
        }
        return phoneNumberString;
    }
    catch (err) {
        console.log(err)
        return phoneNumberString
    }
}

export function formatToDigit(phoneNumberString) {
    try {
        if (phoneNumberString) {
            return String(phoneNumberString).replace(/[^a-zA-Z0-9]/g, '').replaceAll(/\s/g, '')
        }
        return ""
    }
    catch (err) {
        console.log(err)
        return ""
    }
}


export function validatePhoneNumber(phoneNumber) {
    try {
        var regExp = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}/;
        var phone = String(phoneNumber).match(regExp);
        return phone
    }
    catch (err) {
        console.log(err)
        return false
    }

}

export function filterFunction(o, f) {
    return Object.keys(o).some(function (a) {
        if (Array.isArray(o[a]) || typeof o[a] === 'object' && o[a] !== null) {
            return filterFunction(o[a], f);
        }
        if (o[a]) {
            return String(String(o[a]).toUpperCase()).includes(String(f).toUpperCase())
        }
        return o[a] === f;
    });
}

export function TimeToHours(timeString) {
    try {
        const [time, modifier] = timeString.split(' ');
        let [hours, minutes] = time.split(':');
        if (hours === '12') {
            hours = '00';
        }
        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }
        return `${hours}`;
    }
    catch (err) {
        console.log(err);
        return ""
    }
}

export function PickUpAndDeliveryTimeSplit(s) {
    try {
        const [time, modifier] = s.split(' ');
        let [hours, minutes] = time.split(':');
        if (hours == '12') {
            hours = '00';
        }
        if (String(modifier).toLowerCase() === String('PM').toLowerCase()) {
            hours = parseInt(hours, 10) + 12;
        }
        return `${hours}${minutes ? ":" + minutes : ":00"}`;
    }
    catch (err) {
        console.log(err);
        return ""
    }
}

export function closeNav() {
    if (document.getElementById("mySidenav")) {
        document.getElementById("mySidenav").style.width = "0";
    }
    if (document.getElementById("myCanvasNav")) {
        document.getElementById("myCanvasNav").style.width = "0%";
    }
    if (document.getElementById("myCanvasNav")) {
        document.getElementById("myCanvasNav").style.opacity = "0";
    }
}

export function logoutSession() {
    closeNav()
    RemoveDataInStorage(Constants.AUTH_TOKEN);
    // RemoveDataInStorage(Constants.Obj_COUNTRY);
    RemoveDataInStorage(Constants.Obj_COUNTRY_FEES);
    RemoveDataInStorage(Constants.Obj_PLAZA);
    RemoveDataInStorage(Constants.Obj_STORE);
    RemoveDataInStorage(Constants.Obj_STORE_ITEMS);
    RemoveDataInStorage(Constants.Obj_ITEMS_TOPPINGS);
    RemoveDataInStorage(Constants.Obj_CART_ITEMS);
    var rememberMe = RetriveDataFromStorage(Constants.FLAG_REMEMBER_ME);

    if (rememberMe !== 'true') {
        RemoveDataInStorage(Constants.Obj_USER);
    }
    RemoveDataInStorage(Constants.Obj_CART);
    RemoveDataInStorage(Constants.finelObj_BeforePayemt);
    RemoveDataInStorage(Constants.orderDetail);
    RemoveDataInStorage(Constants.ADDRESS_OBJ);
}

export function userSessionManger() {
    try {
        let ischecktoken = true, currentpath = window.location.pathname;

        let path = window.location.pathname.split('/')[1]
        if ("/" + path == RouteConstant.OrderWebNavigation) {
            ischecktoken = false
        }
        if (ischecktoken) {
            ischecktoken = !publicPage.includes(currentpath)
        }

        return ischecktoken
    }
    catch (err) {
        console.log(err);
        return false
    }
}