import React, { Component } from 'react';
import NoPriviewBanner from '../../../../src/images/nopreviewb.png';
import NoPriview from '../../../../src/images/nopreviewp.png';
import { GetData } from "../../../services/GetData";
import Color from '../../Config/Color';
import { ORDER_WEB_STORE_DETAIL_API, ORDER_WEB_STORE_ITEMS_API, ORDER_WEB_STORE_HOURS_API } from '../../Config/Config.json';
import Constants, { DEFAULTID, DatetimeFormate } from '../../Config/Constants';
import { RetriveDataFromStorage, StoreDataInStorage, StoreObjectInStorage } from "../../Config/Utils";
import HeaderOrderWeb from '../../OrderWeb/Header/HeaderOrderWeb';
import './PlazaOrderWebStoreItems.css';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import LoadingOverlay from 'react-loading-overlay';
import CategoryListOrderWeb from './CategoryListOrderWeb';
import Messages from '../../Config/Messages';
import PlazaStoreForm from '../../Config/HoverMessage';
import truck from '../../../images/extraicon/truck.png';
import truckDis from '../../../images/extraicon/truckDis.png';
import shipping from '../../../images/extraicon/shipping.png';
import shippingDis from '../../../images/extraicon/shippingDis.png';
import OnHoverIconInfo from '../../UtillCustomComponent/UtillCustomComponent';
import Moment from "moment";

/* const STORE_OBJECT = {
    "ObjectId": "6476f878ed099374d4706228",
    "ApplicationId": "b4132b91-b2bb-4110-9473-09553c5b4351",
    "ApplicationName": "The Burger Social 108 N Hale St",
    "AppLogo": "https://plazacart.blob.core.windows.net/plazacartdev/b4132b91-b2bb-4110-9473-09553c5b4351/images/logo_b4132b91-b2bb-4110-9473-09553c5b4351_638217497028923575.png?sp=rwl&st=2023-01-03T12:31:13Z&se=2023-12-31T20:31:13Z&spr=https&sv=2021-06-08&sr=c&sig=klHQq%2BKZoIMES9WY2v6Xw%2BisY6l3M9bHFHddaNpXBdc%3D",
    "AppBannerImage": "https://plazacart.blob.core.windows.net/plazacartdev/b4132b91-b2bb-4110-9473-09553c5b4351/images/HomeBanner/bn_b4132b91-b2bb-4110-9473-09553c5b4351_638217497054882480.png?sp=rwl&st=2023-01-03T12:31:13Z&se=2023-12-31T20:31:13Z&spr=https&sv=2021-06-08&sr=c&sig=klHQq%2BKZoIMES9WY2v6Xw%2BisY6l3M9bHFHddaNpXBdc%3D",
    "AppAddress": "108 N Hale St, Wheaton, IL 60187, United States",
    "WebAppid": 0,
    "DeliveryCharges": "0",
    "ConvenienceFee": 0,
    "ShippingCharges": 0,
    "Taxes": 0,
    "Distance": null,
    "Duration": null,
    "Deliverytimes": null,
    "Pickuptimes": null,
    "StoreOpeningTimes": null,
    "isshopifyintegration": false,
    "iswixintegration": false,
    "shopifystorename": "",
    "shopifyaccesstoken": "",
    "isshopifypostorderenable": false,
    "shopifystoreid": null,
    "IsPickupEnable": true,
    "IsDeliveryEnable": true,
    "IsStoreOpen": true,
    "TaxesDetail": [
        {
            "Sales Tax": 6.25
        },
        {
            "Avg. Local Tax Rate ": 2.57
        }
    ],
    "CountryCode": "CA",
    "Currency": "CAD",
    "Symbol": "$",
    "CurrencyName": "Canadian Dollar",
    "ServiceFee": 0.0
} */

/* const COUNTRY_OBJECT = {
    "CountryCode": "CA",
    "Currency": "CAD",
    "Symbol": "$",
    "CurrencyName": "Canadian Dollar",
    "ServiceFee": 0,
    "Taxes": 0,
    "TaxesDetail": [
        {
            "Sales Tax": 6.25
        },
        {
            "Avg. Local Tax Rate ": 2.57
        }
    ]
} */

class PlazaOrderWebStoreItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            storeItemList: [],
            storeData: {},
            isActive: false,
            masterstorehours: [],
            errors: {},
            StoreHourForPickupObject: null,
            StoreHourForDeliveryObject: null,
            StoreCurrentDateTime: '',
            StoreCurrentTimeZone: '',
            isPickupSelected: true
        }
    }

    componentDidMount() {
        /* Get Params ID */
        const { id } = this.props.match.params;
        let search = window.location.search;
        let params = new URLSearchParams(search);
        if (id && params.get('web')) {
            this.getStoreDetailsAPI(id, params.get('web'));
        }
    }

    /* Get Store Detail API */
    getStoreDetailsAPI(storeID, webID) {
        this.setState({ isActive: true, errors: {} });
        var API = ORDER_WEB_STORE_DETAIL_API.replace("#STOREID#", storeID);
        var URL = API.replace("#WEBID#", webID);
        console.log("API ", URL);
        GetData(URL)
            .then(result => {
                var responseJson = result;
                console.log('Store Object ', JSON.stringify(responseJson));
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData) {
                        responseData.websiteurl = webID;
                        this.setState({ storeData: responseData });
                        StoreObjectInStorage(Constants.Obj_STORE, responseData);
                        if (responseData
                            && responseData.CountryCode
                            && responseData.Currency
                            && responseData.Symbol
                            && responseData.CurrencyName
                            && responseData.TaxesDetail) {
                            var COUNTRY_OBJECT = {
                                "CountryCode": responseData.CountryCode,
                                "Currency": responseData.Currency,
                                "Symbol": responseData.Symbol,
                                "CurrencyName": responseData.CurrencyName,
                                "ServiceFee": (responseData.ServiceFee ? responseData.ServiceFee : 0),
                                "Taxes": 0,
                                "TaxesDetail": responseData.TaxesDetail,
                                "itemLevelTax": responseData.storetaxes ? responseData.storetaxes : [],
                                "ApplicationFee": responseData.ApplicationFee,
                                "ApplicationFeePercentage": responseData.ApplicationFeePercentage
                            }
                            StoreObjectInStorage(Constants.Obj_COUNTRY_FEES, COUNTRY_OBJECT);
                        }
                        this.getStoreItemsAPI(DEFAULTID, storeID);
                        this.getStoreHoursAPI(storeID);

                        /* For Picup and Delivery Set */
                        if (responseData && responseData.IsPickupEnable) {
                            this.setState({ isPickupSelected: true });
                        } else if (responseData && responseData.IsDeliveryEnable) {
                            this.setState({ isPickupSelected: false });
                        }
                    }
                } else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false });
            }).catch(e => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors });
                this.setState({ isActive: false });
            });
    }

    /* Get Store Item API */
    getStoreItemsAPI(plazaID, storeID) {
        this.setState({ isActive: true });
        var API = ORDER_WEB_STORE_ITEMS_API.replace("#STOREID#", storeID);
        var URL = API.replace("#TENANTID#", plazaID);
        console.log("API ", URL);
        GetData(URL)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData && responseData.length > 0) {
                        responseData.map(function (item, index) {
                            var itemlist = item.Items;
                            itemlist.map(function (item1, index1) {
                                item1.CategoryID = item.CategoryId;
                            })
                        });
                        this.setState({ storeItemList: responseData }, function () {
                            console.log("123 ", this.state.storeItemList)
                        });
                    }
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false });
            }).catch(e => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors });
                this.setState({ isActive: false });
            });
    }

    /* Get Store Hours API */
    getStoreHoursAPI(storeID) {
        this.setState({ isActive: true })
        var API = ORDER_WEB_STORE_HOURS_API.replace("#STOREID#", storeID);
        GetData(API)
            .then(result => {
                var responseJson = result;
                console.log('Store Hours ', JSON.stringify(responseJson));
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData && responseData.length > 0) {
                        var MasterStoreHourlist = responseData ? responseData : [];
                        this.setState({ masterstorehours: MasterStoreHourlist }, function () {
                            var StoreCurrentDate = MasterStoreHourlist[0];
                            if (StoreCurrentDate && StoreCurrentDate.storecurrenttime) {
                                var strdate = StoreCurrentDate.storecurrenttime.slice(0, -1);
                                const storecurrenttime = Moment(strdate);
                                const current = Moment(strdate);
                                let n = 7;
                                var Storehourlist = [];

                                while (n > 0) {
                                    var S = MasterStoreHourlist.filter(e => e.day == current.format("dddd"))[0];
                                    if (S) {
                                        S.CurrentDate = current.format("dddd DD MMM YYYY");
                                        Storehourlist.push(S);
                                    }
                                    current.add(1, "day");
                                    n--;
                                }

                                if (Storehourlist && Storehourlist.length > 0) {
                                    var StoreHourObject = Storehourlist.filter(e => e.day == storecurrenttime.format("dddd"))[0];
                                    if (StoreHourObject) {

                                        var StoreHourForPickupObject = null;
                                        var StoreHourForDeliveryObject = null;
                                        var isCurrentPickup = false;
                                        var isCurrentDelivery = false;
                                        var isCurrentPickupSlot = '';
                                        var isCurrentDeliverySlot = '';

                                        StoreHourForPickupObject = StoreHourObject;
                                        StoreHourForDeliveryObject = StoreHourObject;

                                        /* For PickUP Check */
                                        if (StoreHourForPickupObject && StoreHourForPickupObject.ispickupenable && StoreHourForPickupObject.currentpickuptimes && StoreHourForPickupObject.currentpickuptimes.length > 0) {
                                            isCurrentPickupSlot = StoreHourForPickupObject.currentpickuptimes[0];
                                            isCurrentPickup = true;
                                            StoreHourForPickupObject.isCurrentPickup = isCurrentPickup;
                                            StoreHourForPickupObject.isCurrentPickupSlot = isCurrentPickupSlot.split(' - ')[0];
                                            StoreHourForPickupObject.isPickupSchedule = false;
                                        } else {
                                            StoreHourForPickupObject = Storehourlist.filter((e, index) => ((index != 0) && (e.ispickupenable === true) && (e.pickuptimes.length > 0)))[0];
                                            if (StoreHourForPickupObject) {
                                                isCurrentPickupSlot = StoreHourForPickupObject.pickuptimes[0];
                                                isCurrentPickup = false;
                                                StoreHourForPickupObject.isCurrentPickup = isCurrentPickup;
                                                StoreHourForPickupObject.isCurrentPickupSlot = isCurrentPickupSlot.split(' - ')[0];
                                                StoreHourForPickupObject.isPickupSchedule = true;
                                            }
                                        }

                                        /* For Delivery Check */
                                        if (StoreHourForDeliveryObject && StoreHourForDeliveryObject.isdeliveryenable && StoreHourForDeliveryObject.currentdeliverytimes && StoreHourForDeliveryObject.currentdeliverytimes.length > 0) {
                                            isCurrentDeliverySlot = StoreHourForDeliveryObject.currentdeliverytimes[0];
                                            isCurrentDelivery = true;
                                            StoreHourForDeliveryObject.isCurrentDelivery = isCurrentDelivery;
                                            StoreHourForDeliveryObject.isCurrentDeliverySlot = isCurrentDeliverySlot.split(' - ')[0];
                                            StoreHourForDeliveryObject.isDeliverySchedule = false;
                                        } else {
                                            StoreHourForDeliveryObject = Storehourlist.filter((e, index) => ((index != 0) && (e.isdeliveryenable == true) && (e.deliverytimes.length > 0)))[0];
                                            if (StoreHourForDeliveryObject) {
                                                isCurrentDeliverySlot = StoreHourForDeliveryObject.deliverytimes[0];
                                                isCurrentDelivery = false;
                                                StoreHourForDeliveryObject.isCurrentDelivery = isCurrentDelivery;
                                                StoreHourForDeliveryObject.isCurrentDeliverySlot = isCurrentDeliverySlot.split(' - ')[0];
                                                StoreHourForDeliveryObject.isDeliverySchedule = true;
                                            }
                                        }

                                        this.setState({
                                            masterstorehours: Storehourlist,
                                            StoreHourForPickupObject: StoreHourForPickupObject,
                                            StoreHourForDeliveryObject: StoreHourForDeliveryObject,
                                            StoreCurrentDateTime: storecurrenttime,
                                            StoreCurrentTimeZone: StoreHourObject.timezoneshortdescription
                                        });
                                    }
                                }
                            }
                        });
                    }
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })
            }).catch(err => {
                console.log(err);
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    HandleinfoModel(e) {
        e.preventDefault();
        $('#infomationmodel').modal('show');
    }

    render() {
        const { storeItemList, storeData } = this.state

        return (
            <div>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Constants.LoadingMssg}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="">
                    <HeaderOrderWeb setHeaderTitle={this.state.storeData.ApplicationName} />
                    <section className="section">

                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px", zIndex: "-1" }}>
                            <div className="reg_form_main col-sm-6">
                                <div style={{ position: "relative", width: "inherit", zIndex: "-1" }}>
                                    <img src={storeData.AppBannerImage ? storeData.AppBannerImage : NoPriviewBanner} onError={(e) => (e.target.onerror = null, e.target.src = NoPriviewBanner)} style={{ width: "inherit", zIndex: "-1", borderRadius: '10px' }} />
                                </div>
                            </div>

                            <div className="reg_form_main col-sm-6">
                                <div>
                                    <div>
                                        <img src={storeData.AppLogo ? storeData.AppLogo : NoPriview} onError={(e) => (e.target.onerror = null, e.target.src = NoPriview)} style={{ width: "40px", height: "40px", borderRadius: "5px" }} />
                                    </div>
                                    <div>
                                        <div className="row" style={{ marginLeft: "0px", height: "70%", width: '100%', marginTop: "4px" }}>
                                            <text style={{ lineHeight: "1.5", fontSize: "16px", width: "inherit", height: "100%", color: Color.BLACK, maxLines: "2px", textOverflow: "ellipsis", overflow: "hidden", fontWeight: 'bold' }}>
                                                {this.state.storeData.ApplicationName}
                                            </text>
                                        </div>
                                        <div className="row" style={{ marginLeft: "0px", height: "70%", width: '100%', marginTop: "4px" }}>
                                            <text style={{ lineHeight: "1.5", fontSize: "14px", width: "inherit", height: "100%", color: Color.BLACK, maxLines: "2px", textOverflow: "ellipsis", overflow: "hidden", }}>
                                                {storeData.AppAddress}
                                            </text>
                                        </div>
                                        <div className="row" style={{ marginLeft: "0px", height: "70%", width: '100%', marginTop: "4px" }}>
                                            <div className={storeData.IsPickupEnable ? "enableIconbg" : "disableIconbg"}
                                                style={{
                                                    float: "right",
                                                    marginRight: "5px",
                                                    marginTop: "5px", height: "30px", width: "30px",
                                                    textAlign: "center", borderRadius: "50%"
                                                }}>
                                                <OnHoverIconInfo
                                                    title={<img src={storeData.IsPickupEnable ? truck : truckDis} style={{ width: '22px', height: '22px', marginTop: "3px" }} />}
                                                    message={storeData.IsPickupEnable ? PlazaStoreForm.PickupServiceAvailable : PlazaStoreForm.PickupServiceNotAvailable}
                                                />
                                            </div>
                                            <div className={storeData.IsDeliveryEnable ? "enableIconbg" : "disableIconbg"}
                                                style={{
                                                    float: "right",
                                                    marginRight: "5px",
                                                    marginTop: "5px", height: "30px", width: "30px",
                                                    textAlign: "center", borderRadius: "50%"
                                                }}>

                                                <OnHoverIconInfo
                                                    title={<img src={storeData.IsDeliveryEnable ? shipping : shippingDis} style={{ width: '22px', height: '22px', marginTop: "3px" }} />}
                                                    message={storeData.IsDeliveryEnable ? PlazaStoreForm.DeliveryServicesAvailable : PlazaStoreForm.DeliveryServicesNotAvailable}
                                                />
                                            </div>
                                            <div style={{ float: "right", marginRight: "0px", marginTop: "5px", height: "30px", width: "30px", color: Color.WHITE, textAlign: "center", backgroundColor: Color.THEME_COLOR, borderRadius: "50px" }}>
                                                <OnHoverIconInfo
                                                    title={<i class="fa fa-shopping-cart" style={{ fontSize: "15px", marginTop: "7px" }}></i>}
                                                    message={PlazaStoreForm.CartServicesAvailable} />
                                            </div>
                                            {(storeData.IsStoreOpen && (storeData.IsDeliveryEnable || storeData.IsPickupEnable)) ?
                                                <div style={{ borderTopLeftRadius: '10px', marginTop: "5px", marginLeft: '5px', borderBottomRightRadius: '7px', backgroundColor: Color.BLACK, width: 'fit-content', height: '25px' }}>
                                                    <i class="fa fa-circle" style={{ fontSize: "10px", color: Color.LIGHT_GREEN, marginLeft: '2px' }}></i>
                                                    <label style={{ marginLeft: '2px', marginRight: '5px', fontSize: '13px', color: Color.WHITE, fontWeight: 'bold' }}>Open</label>
                                                </div> :
                                                <div style={{ borderTopLeftRadius: '10px', marginTop: "5px", marginLeft: '5px', backgroundColor: Color.BLACK, width: 'fit-content', height: '25px', borderBottomRightRadius: '7px' }}>
                                                    <i class="fa fa-circle" style={{ fontSize: "10px", color: Color.LIGHT_RED, marginLeft: '2px' }}></i>
                                                    <label style={{ marginLeft: '2px', marginRight: '5px', fontSize: '13px', color: Color.WHITE, fontWeight: 'bold' }}>Closed</label>
                                                </div>
                                            }
                                        </div>
                                        <div className="row" style={{ marginLeft: "0px", height: "70%", width: '100%', marginTop: "4px" }}>
                                            {(this.state.StoreHourForPickupObject && this.state.StoreHourForDeliveryObject && (this.state.StoreHourForPickupObject.isPickupSchedule || this.state.StoreHourForDeliveryObject.isDeliverySchedule)) &&
                                                <text style={{ lineHeight: "1.5", fontSize: "14px", width: "inherit", height: "100%", color: Color.BLACK, maxLines: "2px", textOverflow: "ellipsis", overflow: "hidden", }}>
                                                    {(storeData && storeData.IsPickupEnable && storeData.IsDeliveryEnable && this.state.StoreHourForPickupObject.isPickupSchedule && this.state.StoreHourForDeliveryObject.isDeliverySchedule) ?
                                                        <span className='textcolor'>
                                                            <span style={{ color: Color.BLACK }} >Scheduled Orders</span> For Pickup and Delivery
                                                        </span> :
                                                        ((storeData && storeData.IsPickupEnable && this.state.StoreHourForPickupObject.isPickupSchedule) ? <span className='textcolor'>
                                                            <span style={{ color: Color.BLACK }} >Scheduled Orders</span> Only For Pickup
                                                        </span> :
                                                            ((storeData && storeData.IsDeliveryEnable && this.state.StoreHourForDeliveryObject.isDeliverySchedule) ? <span className='textcolor'>
                                                                <span style={{ color: Color.BLACK }} >Scheduled Orders</span> Only For Delivery
                                                            </span> : ""))}
                                                </text>
                                            }

                                            {/* For Pickup */}
                                            {(storeData && storeData.IsPickupEnable && this.state.StoreHourForPickupObject && this.state.StoreHourForPickupObject.isCurrentPickup && this.state.StoreHourForPickupObject.isCurrentPickupSlot && this.state.StoreCurrentTimeZone) &&
                                                <text style={{ lineHeight: "1.5", fontSize: "14px", width: "inherit", height: "100%", color: Color.BLACK, maxLines: "2px", textOverflow: "ellipsis", overflow: "hidden", }}>
                                                    Pickup for Today at <span className='textcolor'>{Moment(this.state.StoreCurrentDateTime).format(DatetimeFormate.DDMMYYYYN) + " " + this.state.StoreHourForPickupObject.isCurrentPickupSlot}{" " + this.state.StoreCurrentTimeZone}</span>
                                                </text>
                                            }
                                            {(storeData && storeData.IsPickupEnable && this.state.StoreHourForPickupObject && !this.state.StoreHourForPickupObject.isCurrentPickup &&
                                                this.state.StoreHourForPickupObject.CurrentDate && this.state.StoreHourForPickupObject.isCurrentPickupSlot && this.state.StoreCurrentTimeZone) &&
                                                <text style={{ lineHeight: "1.5", fontSize: "14px", width: "inherit", height: "100%", color: Color.BLACK, maxLines: "2px", textOverflow: "ellipsis", overflow: "hidden", }}>
                                                    Next Available For Pickup at <span className='textcolor'>{Moment(this.state.StoreHourForPickupObject.CurrentDate).format(DatetimeFormate.DDMMYYYYN) + " " + this.state.StoreHourForPickupObject.isCurrentPickupSlot}{" " + this.state.StoreCurrentTimeZone}</span>
                                                </text>
                                            }

                                            {/* For Delivery */}
                                            {(storeData && storeData.IsDeliveryEnable && this.state.StoreHourForDeliveryObject && this.state.StoreHourForDeliveryObject.isCurrentDelivery && this.state.StoreHourForDeliveryObject.isCurrentDeliverySlot && this.state.StoreCurrentTimeZone) &&
                                                <text style={{ lineHeight: "1.5", fontSize: "14px", width: "inherit", height: "100%", color: Color.BLACK, maxLines: "2px", textOverflow: "ellipsis", overflow: "hidden", }}>
                                                    Delivery for Today at <span className='textcolor'>{Moment(this.state.StoreCurrentDateTime).format(DatetimeFormate.DDMMYYYYN) + " " + this.state.StoreHourForDeliveryObject.isCurrentDeliverySlot}{" " + this.state.StoreCurrentTimeZone}</span>
                                                </text>
                                            }
                                            {(storeData && storeData.IsDeliveryEnable && this.state.StoreHourForDeliveryObject && !this.state.StoreHourForDeliveryObject.isCurrentDelivery && this.state.StoreHourForDeliveryObject.CurrentDate && this.state.StoreHourForDeliveryObject.isCurrentDeliverySlot && this.state.StoreCurrentTimeZone) &&
                                                <text style={{ lineHeight: "1.5", fontSize: "14px", width: "inherit", height: "100%", color: Color.BLACK, maxLines: "2px", textOverflow: "ellipsis", overflow: "hidden", }}>
                                                    Next Available For Delivery at <span className='textcolor'>{Moment(this.state.StoreHourForDeliveryObject.CurrentDate).format(DatetimeFormate.DDMMYYYYN) + " " + this.state.StoreHourForDeliveryObject.isCurrentDeliverySlot}{" " + this.state.StoreCurrentTimeZone}</span>
                                                </text>
                                            }
                                        </div>
                                        {storeData && (storeData.IsPickupEnable || storeData.IsDeliveryEnable) && <div className="row" style={{ marginLeft: "0px", height: "70%", width: '100%' }}>
                                            <label style={{
                                                fontSize: "14px",
                                                width: "inherit",
                                                height: "100%",
                                                color: Color.BLUE,
                                                maxLines: "2px",
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                cursor: "pointer"
                                            }} onClick={(e) => this.HandleinfoModel(e)}>
                                                More Info
                                            </label>
                                        </div>}
                                    </div>
                                </div>
                            </div>


                            <div className="reg_form_main">
                                <CategoryListOrderWeb
                                    storeData={storeData}
                                    data={storeItemList} />
                            </div>

                            <div class="modal" id="deleteModal">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <label>Plazacart</label>
                                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                                        </div>

                                        <div class="modal-body">
                                            <label>Are you sure you want to remove all the items from the cart?</label>
                                        </div>
                                        <div class="modal-footer">
                                            <label
                                                style={{ color: Color.THEME_COLOR }}
                                                for="step5"
                                                id="continue-step5"
                                                data-dismiss="modal"
                                            > No</label>
                                            <label
                                                style={{ float: "center", borderRadius: "50px", marginBottom: "10px" }}
                                                for="step5"
                                                id="continue-step5"
                                                className="btn btn-done"
                                            > Yes</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="modal modalcustom" id="infomationmodel" style={{ marginBottom: "10px" }} centered>
                                <div class="modal-dialog" style={{ margin: "auto", top: "25%" }}>
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <label>{this.state.storeData ? this.state.storeData.ApplicationName : ""}</label>
                                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                                        </div>
                                        <div class="modal-body" style={{ padding: '5px' }}>
                                            {(this.state.StoreHourForPickupObject && this.state.StoreHourForDeliveryObject && (this.state.StoreHourForPickupObject.isPickupSchedule || this.state.StoreHourForDeliveryObject.isDeliverySchedule)) &&
                                                <div style={{ borderBottom: "1px solid #dee2e6" }}>
                                                    <text style={{ margin: '5px', lineHeight: "1.5", fontSize: "14px", width: "inherit", height: "100%", color: Color.BLACK, maxLines: "2px", textOverflow: "ellipsis", overflow: "hidden", }}>
                                                        {(storeData && storeData.IsPickupEnable && storeData.IsDeliveryEnable && this.state.StoreHourForPickupObject.isPickupSchedule && this.state.StoreHourForDeliveryObject.isDeliverySchedule) ?
                                                            <span className='textcolor'>
                                                                <span style={{ color: Color.BLACK }} >Scheduled Orders</span> For Pickup and Delivery
                                                            </span> :
                                                            ((storeData && storeData.IsPickupEnable && this.state.StoreHourForPickupObject.isPickupSchedule) ? <span className='textcolor'>
                                                                <span style={{ color: Color.BLACK }} >Scheduled Orders</span> Only For Pickup
                                                            </span> :
                                                                ((storeData && storeData.IsDeliveryEnable && this.state.StoreHourForDeliveryObject.isDeliverySchedule) ? <span className='textcolor'>
                                                                    <span style={{ color: Color.BLACK }} >Scheduled Orders</span> Only For Delivery
                                                                </span> : ""))}
                                                    </text>
                                                </div>
                                            }
                                            <div style={{ borderBottom: "1px solid #dee2e6" }}>
                                                <div className="row" style={{ margin: '5px' }}>
                                                    {storeData && storeData.IsPickupEnable && <label style={{ fontSize: '14px', color: this.state.isPickupSelected ? Color.THEME_COLOR : Color.BLACK, cursor: 'pointer' }} onClick={() => {
                                                        this.setState({ isPickupSelected: true });
                                                    }}>Pickup hours</label>}
                                                    {storeData && storeData.IsDeliveryEnable && <label style={{ fontSize: '14px', marginLeft: '12px', color: this.state.isPickupSelected ? Color.BLACK : Color.THEME_COLOR, cursor: 'pointer' }} onClick={() => {
                                                        this.setState({ isPickupSelected: false });
                                                    }}>Delivery hours</label>}
                                                </div>
                                            </div>
                                            {this.state.masterstorehours && this.state.masterstorehours.length > 0 && this.state.masterstorehours.map((e, index) => {
                                                return <div className="row" style={{ margin: '5px', fontSize: '12px', justifyContent: 'space-between' }}>
                                                    <div>
                                                        <label style={{ color: (this.state.isPickupSelected ? (e.ispickupenable ? Color.THEME_COLOR : Color.BLACK) : (e.isdeliveryenable ? Color.THEME_COLOR : Color.BLACK)) }}>
                                                            {Moment(e.CurrentDate).format(DatetimeFormate.ddN_DD_MN_Y)}
                                                        </label>
                                                    </div>

                                                    {index == 0 && this.state.isPickupSelected && e.ispickupenable && e.currentpickuptimes && e.currentpickuptimes.length > 0 &&
                                                        <div>
                                                            {e.currentpickuptimes.map(c => {
                                                                return <label className='inlinelabel'>
                                                                    {c}
                                                                </label>
                                                            })}
                                                        </div>
                                                    }

                                                    {index == 0 && !this.state.isPickupSelected && e.isdeliveryenable && e.currentdeliverytimes && e.currentdeliverytimes.length > 0 &&
                                                        <div>
                                                            {e.currentdeliverytimes.map(c => {
                                                                return <label className='inlinelabel'>
                                                                    {c}
                                                                </label>
                                                            })}
                                                        </div>
                                                    }

                                                    {index != 0 && this.state.isPickupSelected && e.ispickupenable && e.pickuptimes && e.pickuptimes.length > 0 &&
                                                        <div>
                                                            {e.pickuptimes.map(c => {
                                                                return <label className='inlinelabel'>
                                                                    {c}
                                                                </label>
                                                            })}
                                                        </div>
                                                    }

                                                    {index != 0 && !this.state.isPickupSelected && e.isdeliveryenable && e.deliverytimes && e.deliverytimes.length > 0 &&
                                                        <div>{e.deliverytimes.map(c => {
                                                            return <label className='inlinelabel'>
                                                                {c}
                                                            </label>
                                                        })}
                                                        </div>
                                                    }
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </LoadingOverlay>
            </div >
        );
    }
}

export default PlazaOrderWebStoreItems;