import React from 'react'
function OnHoverIconInfo(props) {
    return <div className="tooltip" style={{ zIndex: "unset" }}>
        {props.title ? props.title : <i className="far fa-question-circle"></i>}
        <span className={"tooltiptext settooltip settooltipbg " + (props.classString ? props.classString : "")}>{props.message}</span>
    </div>
}

export function PutMandatoryfieldSymbol() {
    return <span style={{ color: 'red' }}> *</span>
}

export function arrayFromHours(start, end) {
    try {
        let timeoptions = [];
        let minutes = [':00', ':30']
        let meridiem = ["AM", "PM"]

        for (var hour = start; hour <= end; hour++) {
            for (var minute = 0; minute < minutes.length; minute++) {
                if (0 < hour && hour <= 9) {
                    timeoptions.push(
                        { value: "0" + String(hour) + minutes[minute] + meridiem[0], label: String(hour) + minutes[minute] + " " + meridiem[0] }
                    )
                }
                else if (9 < hour && hour <= 11) {
                    timeoptions.push(
                        { value: String(hour) + minutes[minute] + meridiem[0], label: String(hour) + minutes[minute] + " " + meridiem[0] }
                    )
                }
                else if (hour == 12) {
                    timeoptions.push(
                        { value: String(hour) + minutes[minute] + meridiem[1], label: String(hour) + minutes[minute] + " " + meridiem[1] }
                    )
                }
                else if (13 <= hour && hour < 22) {
                    timeoptions.push(
                        { value: "0" + String(hour - 12) + minutes[minute] + meridiem[1], label: String(hour - 12) + minutes[minute] + " " + meridiem[1] }
                    )
                }
                else if (22 <= hour && hour <= 23) {
                    timeoptions.push(
                        { value: String(hour - 12) + minutes[minute] + meridiem[1], label: String(hour - 12) + minutes[minute] + " " + meridiem[1] }
                    )
                }
                else if (hour == 0) {
                    timeoptions.push(
                        { value: String(hour + 12) + minutes[minute] + meridiem[0], label: String(hour + 12) + minutes[minute] + " " + meridiem[0] }
                    )
                }
            }
        }

        return timeoptions
    }
    catch (e) {
        console.log(e)
        return []
    }
}

export default OnHoverIconInfo