import React from 'react';
import MasterContextCreater from './MasterContextCreater';
import { StoreObjectInStorage, RemoveDataInStorage, StoreDataInStorage, RetriveDataFromStorage, RetriveObjectFromStorage, userSessionManger, logoutSession } from "../Config/Utils";
import { PostData } from '../../services/PostData.js';
import { GetData } from '../../services/GetData';
import Constants from '../Config/Constants.js';
import Messages from '../Config/Messages'
import { APPOIMENT_APi, STOREORDERAPI, STOREORDER_CURBSIDEAPI, GET_NOTIFICATION_API } from '../Config/Config.json'

const ad = [
    {
        "_id": "637f44586c01c33791c8d545",
        "customerid": "62e3e993482e856900691934",
        "customerfirstname": null,
        "customerlastname": null,
        "customeremail": null,
        "storeid": "62e3e0ebf4502b075c12e10d",
        "storename": "Food Basics",
        "storeaddress": "2200 Jane St, North York, ON M3M 1A3, Canada",
        "date": "2022-11-23T18:30:00Z",
        "starttime": "9am",
        "endtime": "5pm",
        "customernotes": "customernotes",
        "merchantnotes": null,
        "appoimentstatus": "CONFIRMED",
        "Status": null,
        "CreatedDate": "2022-11-24T10:15:52.301Z",
        "ModifiedDate": "2022-11-24T10:15:52.301Z"
    }
]

export default class MasterContextProvider extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            myAppointment: [],
            deliveryOrderList: [],
            curbsideOrderList: [],
            notificaionList: [],
            notificationCount: 0,

            isnavalert: false,
        }
    }

    componentDidMount() {
        this.refreshAppointment()
        this.refforderList()
        this.reffCurbsideorderList()
        this.reffotificationList()
        console.log("for master con")
    }

    refreshAppointment() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        this.getMyAppointmentAPI(authToken)
    }

    getMyAppointmentAPI(authToken) {
        var API = APPOIMENT_APi;
        GetData(API, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData) {
                        this.setState({ myAppointment: responseData })
                    }
                }
                else if (responseJson["StatusCode"] == 401) {
                    if (userSessionManger()) {
                        logoutSession()
                        window.location.href = "/"
                    }
                }
                else {

                }
            });
    }

    refforderList() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        this.getOrderListAPI(authToken);
    }

    getOrderListAPI(authToken) {
        this.setState({ isLoadingActive: true });
        var API = STOREORDERAPI;
        GetData(API, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData) {
                        this.setState({ deliveryOrderList: responseData })
                    }
                }
                else {

                }
            });
    }

    reffCurbsideorderList() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        this.getCurbSideOrderAPI(authToken)
    }

    getCurbSideOrderAPI(authToken) {
        this.setState({ isLoadingActive: true });
        var API = STOREORDER_CURBSIDEAPI;
        GetData(API, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData) {
                        this.setState({ curbsideOrderList: responseData });
                    }
                }
            });
    }

    reffotificationList() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        this.getNotificationAPI(authToken)
    }

    getNotificationAPI(authToken) {
        var API = GET_NOTIFICATION_API;
        GetData(API, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData) {
                        let notificaionListCount = responseData.filter(e => !e.IsRead)
                        this.setState({ notificaionList: responseData, notificationCount: notificaionListCount.length });
                    }
                }
            });
    }

    setStateByChild(ob) {
        this.setState({ ...ob })
    }

    render() {
        return (
            <MasterContextCreater.Provider value={{
                ...this.state,
                reffAppointment: () => this.refreshAppointment(),
                reffotificationList: () => this.reffotificationList(),
                setStateByChild: (ref) => this.setStateByChild(ref)
            }}>
                {this.props.children}
            </MasterContextCreater.Provider>
        )
    }
}