import { RemoveDataInStorage } from "./Utils";
import RouteConstant from "./RouteConstant";

const Constants = {
  DEVICE_ID: "WEB",
  DEVICE_TYPE: "WEB",
  COMPANY: "MY_COMPANY",
  PUSH_TOKEN: "WEB_PUSHTOKEN",
  LATITUDE: "WEB_LATITUDE",
  LONGITUDE: "WEB_LONGITUDE",
  AUTH_TOKEN: "Token", // token for login
  USER_SESSION_TYPES: "User_Session_Types",
  TENANT_ID: "TENANTID",
  TENANT_DATA: "OBJ_TENANT",

  STOREID: "STOREID",
  ORDERID: "ORDERID",
  ORDERFLAG: "ORDERFLAG",

  FLAG_REMEMBER_ME: "FlagRememberMe",
  FACEBOOK_APP_ID: "4672488446105217",
  GOOGLE_CLIENT_ID1:
    "667211124101-83cf2r1rtpheaoa1iobjc8rvq9sf2mph.apps.googleusercontent.com",
  GOOGLE_CLIENT_ID:
    "944405389209-b8et5id7lgnddribii1r9452ba2o62if.apps.googleusercontent.com",

  Title_ForgotPwd: "Reset Password",
  Title_PlazaList: "Plaza List",
  Title_StoreList: "Brewery List",
  Title_MyCart: "My Cart",
  Title_CheckOut: "Checkout",
  Title_Payment: "Payment",
  Title_AddAdress: "Add Address",
  Title_UpdateAddress: "Update Address",
  Title_Notification: "NOTIFICATION",
  Title_MyProfile: "My Profile",
  Title_MyOrder: "My Orders",
  Title_CurbsideOrders: "Curbside Orders",
  Title_SelectedCountry: "Select Country",

  COUNT_CART_ITEM: "0",
  LoadingMssg: "Loading...",
  LoadingMssgafterpayment: "Do not click the back or refersh button",
  Cookie_LoginCredential: {},

  ADDRESS_OBJ: "objAddress",

  Obj_COUNTRY: {}, // Select Country page
  // Obj_PLAZA:{}, // Select Plaza page
  // Obj_STORE:{}, // Store page

  Obj_COUNTRY_FEES: "objCountryFees",
  Obj_PLAZA: "objPlaza", // Select Plaza page
  Obj_STORE: "objStore", // Store page
  Obj_STORE_ITEMS: "objStoreItems", // Store page
  Obj_ITEMS_TOPPINGS: "objItemsTopping", // Store page
  Obj_CART_ITEMS: "objCartItems", // Store page
  GOOGLEEMAIL: "Email",

  Obj_USER: "objUser",

  Obj_CART: "objCart", // Store page

  finelObj_BeforePayemt: "beforepayment", // for payment
  orderDetail: "orderDetail", // for orderDetailpage

  PLATFORM_FLAG: "plattform",
  Obj_STORE_ITEMS_SHOPIFY: "objStoreItemsShopify",
  APPOINTMENT: "APPOINTMENT",
  PAYMENTTYPE: "stripe_express", // For Payment type switch paypal, stripe_standard, stripe_express
  PLAZACARTMAXIMUMORDERAMOUNT: 3000 // Maximum Order Amount Validation for curbside and delivery operations for plazacart
};

export const APPOINTMENTSTATUS = {
  PENDING: "PENDING",
  CONFIRMED: "CONFIRMED",
  CANCEL: "CANCEL",
};

export const STRIPE_PAYMENT_STATUS = {
  SUCCEEDED: "succeeded",
  PROCESSING: "processing",
  REQUIRES_PAYMENT_METHOD: "requires_payment_method",
  PAYMENT_INTENT: "payment_intent",
  PAYMENT_INTENT_CLIENT_SECRET: "payment_intent_client_secret",
  REDIRECT_STATUS: "redirect_status",
};

export const POLICY_IN_VISIBLE_MODE = {
  MODE: "mode",
  PUBLIC_MODE: 1,
  PRIVATE_MODE: 2,
};

export const TERMS_OF_SERVICE_IN_VISIBLE_MODE = {
  MODE: "mode",
  PUBLIC_MODE: 1,
  PRIVATE_MODE: 2,
};

export const PAYMENT_TYPES = {
  PAYPAL: "paypal",
  STRIPE_STANDARD: "stripe_standard",
  STRIPE_EXPRESS: "stripe_express",
};

export const DateRangeT = {
  FUTUREDAYS: "FUTUREDAYS",
  DATERANGE: "DATERANGE",
  INDEFINITELY: "INDEFINITELY",
};

export const DatetimeFormate = {
  DDMMYYYY: "DD/MM/YYYY",
  YYYYMMDDdash: "YYYY-MM-DD",
  DDMMMYYYYhmma: "DD MMM YYYY h:mm a",
  DDMMMYYYYHHMMA: "DD MMM YYYY hh:mm A",
  DDMMYYYYN: "DD MMM YYYY",
  HMMA: "h:mm a",
  ddN_DD_MN_Y: "dddd, D MMMM YYYY",
  DDDD: "dddd",
  T_HHmmA: "HH:mm A",
  T_m: "m",
  T_hhmmA: "hh:mm A",
};

export const PlatFrom = {
  Plazacart: "Plazacart",
  Shopify: "Shopify",
  Wix: "Wix",
};

export function sessionclear() {
  for (var i in Constants) {
    if (Constants[i] != Constants.FLAG_REMEMBER_ME) {
      RemoveDataInStorage(Constants[i]);
    }
  }
}

export const currencyInfo = {
  CountryCode: "Currency",
  StaticCountryCode: "CA",
};
export const PlazaCartAllStatus = {
  ACTIVE: "ACTIVE",
};

export const DateFormate = {
  YYYYMMDD: "YYYY-MM-DD",
};

export const DEFAULTID = "000000000000000000000000";

export const COMPANYCONST = {
  PLAZACART: "brewers near me",
  BREWERSNEARME: "Brewers near me",
  BREWERSNEARMECAP: "BREWERSNEARME",
  PLAZACARTCOM: "brewersnearme.com",
  BREWERSNEARMECOMCAP: "BREWERSNEARME.COM",
  WWWPLAZACARTCOM: "www.brewersnearme.com",
  PIDEXINC: "Pidex Inc",
  PLAZACARTMAIL: "admin@brewersnearme.com",
  TELEPHONE: "982-8934879",
};

export const CONTACT_US_VISIBLE_MODE = {
  MODE: "mode",
  CONTACT_US_MODE: 1,
  REQUEST_A_DEMO_MODE: 2,
};

export const ContactUSConstForm = {
  ContactUS: "Contact us",
  RequestADemo: "Request a Demo",
  Name: "Name",
  Email: "Email",
  Title: "Title",
  PhoneNumber: "Phone Number",
  Comments: "Comments",
  Company: "Company",
  CAPTCHAcode: "Enter the code shown below",
  btn_Submit: "Submit",
};

export const ContactUSConstFormPH = {
  Name: "Name",
  Email: "Email",
  Title: "Title",
  PhoneNumber: "Phone Number",
  Comments: "Comments",
  Company: "Company",
  CAPTCHAcode: "Enter the code shown below",
};

export const UserSessionTypes = {
  PLAZACART_CUSTOMER: "PLAZACART_CUSTOMER",
  GUEST_CUSTOMER: "GUEST_CUSTOMER",
};

export const DELIVERY_OPTIONS = {
  DOORDASH: "DOORDASH",
  PLAZACART: "PLAZACART",
};

export const publicPage = [
  RouteConstant.Default,
  RouteConstant.Login,
  RouteConstant.Register,
  RouteConstant.ForgotPassword,
  RouteConstant.TermsOfService,
  RouteConstant.PrivacyPolicy,
  RouteConstant.AboutUs,
  RouteConstant.HelpAndSupport,
  RouteConstant.PlazaCartPartner,
  RouteConstant.ContactUs,
  RouteConstant.OrderWebNavigation,
  RouteConstant.OrderWebAddtoCart,
  RouteConstant.OrderWebMyCart,
  RouteConstant.OrderWebTryIt,
  RouteConstant.OrderWebCheckout,
  RouteConstant.OrderWebAddress,
  RouteConstant.OrderWebPayment,
];

export default Constants;
