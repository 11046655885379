import React, { Component } from 'react';
import { Menu, MenuItem, ProSidebar, SidebarContent, SidebarFooter, SidebarHeader, SubMenu } from 'react-pro-sidebar';
import defaultUser from "../../images/default-user.png";
import './SideMenu.css';

import Color from '../Config/Color';
import AddressList from './AddressList';
import CurbData from './CurbData';
import MenuData from './MenuData';
import NotificationList from './NotificationList';

import { withRouter } from 'react-router-dom';
import RouteConstant from '../Config/RouteConstant';
import MyAppointmentside from './appointmentside';

import browsing from "../../images/sidemenuicons/browsing.png";
import curbside from "../../images/sidemenuicons/CurbSide1.png";
import location from "../../images/sidemenuicons/Location.png";
import logout from "../../images/sidemenuicons/logout.png";
import myorder from "../../images/sidemenuicons/MyOrder.png";
import notificaion from "../../images/sidemenuicons/Notificaion.png";

import { GetData } from "../../services/GetData";
import { GET_COUNTRY_API, GET_USERPROFILE_API } from '../Config/Config.json';
import Constants, { currencyInfo } from '../Config/Constants';
import { RemoveDataInStorage, RetriveDataFromStorage, RetriveObjectFromStorage, StoreObjectInStorage } from "../Config/Utils";
import MasterContextCreater from '../MasterContextProvider/MasterContextCreater';

class SideMenu extends Component {
    static contextType = MasterContextCreater;
    constructor(props) {
        super(props);
        this.state = {
            opened: false, // (or true)

            currencySymbols: "",
            userDetail: {},
            addressList: [],

            countryData: {},
            countryArr: [],
            isShopifyAvailable: false,
            isCartAvailable: false
        }

        this.closeNav = this.closeNav.bind(this);
        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.handleDashboardClick = this.handleDashboardClick.bind(this);
        this.handleAddAddress = this.handleAddAddress.bind(this);
        this.handleSelectCountry = this.handleSelectCountry.bind(this);
        this.handleEditProfile = this.handleEditProfile.bind(this);
    }


    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        this.getUserDetail(authToken);
        var countryFees = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES);
        if (countryFees) {
            var currencySymbole = (countryFees[currencyInfo.CountryCode] ? "(" + countryFees[currencyInfo.CountryCode] + ") " : "") + countryFees.Symbol;
            this.setState({ currencySymbols: currencySymbole });
        }

        /* Cart Model */
        var objCart = RetriveObjectFromStorage(Constants.Obj_CART, '');
        if (objCart && objCart.length > 0) {
            if (objCart && objCart[0].isshopifyintegration) {
                var isShopifyAvailable = objCart[0].isshopifyintegration;
                this.setState({ isShopifyAvailable: isShopifyAvailable });
            }
            this.setState({ isCartAvailable: true });
        } else {
            this.setState({ isCartAvailable: false });
        }
    }

    getUserDetail(authToken) {
        var API = GET_USERPROFILE_API;
        GetData(API, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData) {
                        this.setState({ userDetail: responseData });
                        if (responseData.Addresslist) {
                            var profilepic = ""
                            var profileUrl = responseData.ProfilePicture;
                            var profileUrlspite = profileUrl.split("/");
                            if (profileUrlspite[profileUrlspite.length - 1] === "BsonNull") {
                                responseData.ProfilePicture = profilepic;
                            }
                            this.setState({ addressList: responseData.Addresslist });
                        }
                        this.getCountryAPI();
                        StoreObjectInStorage(Constants.Obj_USER, responseData);
                    }
                }
            });
    }

    getCountryAPI() {
        GetData(GET_COUNTRY_API)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData) {
                        var countryDetail = responseData.filter(e1 => e1.CountryCode === this.state.userDetail.CountryCode)[0];
                        if (countryDetail) {
                            this.setState({ countryData: countryDetail });
                        }
                        this.setState({ countryArr: responseData });
                    }
                }
            });
    }

    /////////////////////////////////////////// end componentDidMount method /////////////////////////


    ////////////////////////////////////////// child hook methods start ////////////////////////////

    updateUserDetail = () => {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        this.getUserDetail(authToken);
    }

    updateNotifiList = () => {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        // this.getNotificationAPI(authToken);

        const { reffotificationList } = this.context;
        if (typeof reffotificationList == "function") {
            reffotificationList()
        }
    }

    closeNav() {
        this.setState({ opened: false });
        window.closeNav();
    }

    handleToggle() {

    }

    handleDashboardClick = value => {
        const { isnavalert } = this.context
        window.closeNav();
        if (this.state.isCartAvailable || isnavalert) {
            if (this.state.isShopifyAvailable) {
                this.props.history.push({
                    pathname: RouteConstant.PlazaStoreItemsShopify,
                });
            }
            else {
                this.props.history.push({
                    pathname: RouteConstant.PlazaStoreItems,
                });
            }

        } else {
            this.props.history.push({
                pathname: RouteConstant.Dashboard,
            });
        }
    }

    handleMenuClick = value => {
        window.closeNav();
        RemoveDataInStorage(Constants.AUTH_TOKEN);
        RemoveDataInStorage(Constants.Obj_COUNTRY);
        RemoveDataInStorage(Constants.Obj_COUNTRY_FEES);
        RemoveDataInStorage(Constants.Obj_PLAZA);
        RemoveDataInStorage(Constants.Obj_STORE);
        RemoveDataInStorage(Constants.Obj_STORE_ITEMS);
        RemoveDataInStorage(Constants.Obj_ITEMS_TOPPINGS);
        RemoveDataInStorage(Constants.Obj_CART_ITEMS);
        var rememberMe = RetriveDataFromStorage(Constants.FLAG_REMEMBER_ME);

        if (rememberMe !== 'true') {
            RemoveDataInStorage(Constants.Obj_USER);
        }
        RemoveDataInStorage(Constants.Obj_CART);
        RemoveDataInStorage(Constants.finelObj_BeforePayemt);
        RemoveDataInStorage(Constants.orderDetail);
        RemoveDataInStorage(Constants.ADDRESS_OBJ);
        if (value === '6') {
            this.props.history.push({
                pathname: RouteConstant.Default,
            })
        }
    }

    handleSelectCountry() {
        this.closeNav()
        this.props.history.push({
            pathname: RouteConstant.Country
        })
    }

    handleAddAddress() {
        this.closeNav()
        StoreObjectInStorage(Constants.ADDRESS_OBJ, "");
        this.props.history.push({
            pathname: RouteConstant.AddAddress,
        });

        const { onrefresh } = this.props;
        if (typeof onrefresh == "function") {
            onrefresh();
        }
    }

    handleEditProfile() {
        window.closeNav();
        this.props.history.push({
            pathname: RouteConstant.EditProfile,
        })
    }

    closeNav() {
        window.closeNav();
    }

    render() {
        let { myAppointment, deliveryOrderList, curbsideOrderList, notificaionList, notificationCount, isnavalert } = this.context;
        myAppointment = myAppointment ? myAppointment : [];
        deliveryOrderList = deliveryOrderList ? deliveryOrderList : [];
        curbsideOrderList = curbsideOrderList ? curbsideOrderList : [];
        notificaionList = notificaionList ? notificaionList : [];

        return (
            <React.Fragment>
                <div>
                    <ProSidebar>
                        <SidebarHeader style={{ backgroundColor: Color.WHITE }}>
                            <div className="col-sm-12" style={{ marginTop: "10px" }}>
                                <div style={{ backgroundColor: Color.THEME_COLOR, color: Color.WHITE, textAlign: "center", height: "25px", width: "25px", position: "absolute", top: "5px", right: "15px", borderRadius: "20px" }} onClick={this.closeNav}  >
                                    <i class="fa fa-times" style={{ fontSize: "15px", marginTop: "5px" }}></i>
                                </div>
                                <center>
                                    <img src={this.state.userDetail.ProfilePicture ? this.state.userDetail.ProfilePicture : defaultUser} onError={(e) => (e.target.onerror = null, e.target.src = defaultUser)} style={{ borderRadius: "50px" }} width="100" height="100"></img>
                                    <div style={{ backgroundColor: Color.THEME_COLOR, color: Color.WHITE, textAlign: "center", height: "30px", width: "30px", position: "absolute", top: "60px", right: "80px", borderRadius: "20px", cursor: "pointer" }} >
                                        <i class="fas fa-pencil-alt" style={{ fontSize: "17px", marginTop: "6px" }} onClick={this.handleEditProfile}></i>
                                    </div>

                                    <br /><br />
                                    <label style={{ color: Color.BLACK }}>{this.state.userDetail.FirstName} {this.state.userDetail.LastName}</label><br />
                                    <label style={{ color: Color.BLACK }}>{this.state.userDetail.Email}</label>
                                </center>
                            </div>
                        </SidebarHeader>

                        <SidebarContent style={{}}>
                            <Menu style={{ backgroundColor: Color.WHITE }} >
                                <MenuItem
                                    onClick={(value) => this.handleDashboardClick()}
                                    icon={<img src={browsing} style={{ height: "25px", width: "25px" }}></img>}
                                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}>
                                    {(this.state.isCartAvailable || isnavalert) ? "Browse Brewery Items" : "Browse Products"}
                                </MenuItem>

                                <SubMenu
                                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                                    icon={<img src={myorder} style={{ height: "25px", width: "25px" }}></img>}
                                    suffix={deliveryOrderList.length > 0 ? <div style={{ backgroundColor: Color.THEME_COLOR, color: Color.WHITE, textAlign: "center", height: "25px", minWidth: "30px", width: "max-content", borderRadius: "20px" }} >
                                        <span style={{ fontSize: "13px" }}>{deliveryOrderList.length}</span>
                                    </div> : ""}
                                    title="My Orders" >
                                    <MenuData data={deliveryOrderList}
                                        currencySymbols={this.state.currencySymbols}
                                        countryArr={this.state.countryArr}
                                        onrefresh={this.props.onrefresh}
                                    />
                                </SubMenu>

                                <SubMenu
                                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                                    icon={<i class="fas fa-calendar store-icon" style={{ fontSize: "20px", color: Color.THEME_COLOR }}></i>}	
                                    suffix={myAppointment.length > 0 ? <div style={{ backgroundColor: Color.THEME_COLOR, color: Color.WHITE, textAlign: "center", height: "25px", minWidth: "30px", width: "max-content", borderRadius: "20px" }} >
                                        <span style={{ fontSize: "13px" }}>{myAppointment.length}</span>
                                    </div> : ""}
                                    title="My Appointments" >
                                    <MyAppointmentside data={myAppointment} onrefresh={this.props.onrefresh} />
                                </SubMenu>

                                <SubMenu
                                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                                    icon={<img src={curbside} style={{ height: "25px", width: "25px" }}></img>}
                                    suffix={curbsideOrderList.length > 0 ? <div style={{ backgroundColor: Color.THEME_COLOR, color: Color.WHITE, textAlign: "center", height: "25px", minWidth: "30px", width: "max-content", borderRadius: "20px" }} >
                                        <span style={{ fontSize: "13px" }}>{curbsideOrderList.length}</span>
                                    </div> : ""}
                                    title="Curbside Orders" >
                                    <CurbData data={curbsideOrderList}
                                        currencySymbols={this.state.currencySymbols}
                                        countryArr={this.state.countryArr}
                                        onrefresh={this.props.onrefresh}
                                    />
                                </SubMenu>

                                <SubMenu
                                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK, marginLeft: "0px" }}
                                    icon={<img src={location} style={{ height: "25px", width: "25px" }}></img>}
                                    suffix={this.state.addressList.length > 0 ? <div style={{ backgroundColor: Color.THEME_COLOR, color: Color.WHITE, textAlign: "center", height: "25px", minWidth: "30px", width: "max-content", borderRadius: "20px" }} >
                                        <span style={{ fontSize: "13px" }}>{this.state.addressList.length}</span>
                                    </div> : ""}
                                    title={<div>My Addresses &nbsp;
                                        <i class="fa fa-plus" style={{ fontSize: "15px", color: Color.THEME_COLOR }} onClick={this.handleAddAddress} ></i>
                                    </div>} >
                                    <AddressList history={this.props.history} data={this.state.addressList} updateAddressList={this.updateUserDetail} onrefresh={this.props.onrefresh} />
                                </SubMenu>

                                <SubMenu
                                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                                    icon={<img src={notificaion} style={{ height: "25px", width: "25px" }}></img>}
                                    suffix={notificationCount > 0 ? <div style={{ backgroundColor: Color.THEME_COLOR, color: Color.WHITE, textAlign: "center", height: "25px", minWidth: "30px", width: "max-content", borderRadius: "20px" }} >
                                        <span style={{ fontSize: "13px" }}>{notificationCount}</span>
                                    </div> : ""}
                                    title="Notifications" >
                                    <NotificationList data={notificaionList} updateNotificationList={this.updateNotifiList} onrefresh={this.props.onrefresh} />
                                </SubMenu>

                                {/* <SubMenu
									open={false}
									style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
									icon={<i class="fas fa-star" style={{ fontSize: "20px", color: Color.THEME_COLOR }}></i>}
									title="Favorite stores" >

								</SubMenu> */}


                                {/* <MenuItem 
								onClick={(value) => this.handleMenuClick('4')}
								style={{backgroundColor:Color.WHITE, color:Color.BLACK}} 
								icon={<i class="fa fa-bell" style={{ fontSize: "20px", color:Color.THEME_COLOR }}></i>} >
								Notifications
							</MenuItem> */}

                                <MenuItem
                                    //onClick={(value) => this.handleMenuClick('5')} 
                                    onClick={(value) => this.handleSelectCountry()}
                                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}
                                    icon={<img style={{ height: "auto", width: "auto", padding: "5px", borderRadius: "300px" }} src={this.state.countryData.CountryIcon} className="iconDetails" />
                                        // <i class="fas fa-leaf-maple" style={{ fontSize: "20px", color: Color.THEME_COLOR }}></i>
                                    }>
                                    {this.state.countryData.CountryName}
                                </MenuItem>

                                <MenuItem
                                    onClick={(value) => this.handleMenuClick('6')}
                                    icon={<img src={logout} style={{ height: "25px", width: "25px" }}></img>}
                                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </SidebarContent>

                        <SidebarFooter style={{ backgroundColor: Color.WHITE }}>
                        </SidebarFooter>

                    </ProSidebar>
                </div>


            </React.Fragment>
        );
    }
}

export default withRouter(SideMenu);