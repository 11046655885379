import React, { Component } from 'react';
import 'react-data-table-component-extensions/dist/index.css';
import Checkbox from 'react-simple-checkbox';
import Color from '../../Config/Color';
import Constants, { currencyInfo } from '../../Config/Constants';
import { RetriveObjectFromStorage } from '../../Config/Utils';
import NoPriview from '../../../../src/images/nopreviewp.png';

var totalPrice = 0.0;

class UpdateToppingsSingleStore extends Component {
    constructor(props) {
        super(props);

        this.state = {

            itemlogo: "",
            itemPrice: "",
            totalPrice: "",
            itemCount: 1,
            toppingCategoryList: [],
            cartItems: [],
            toppingCharge: 0.00,
            selectedToppings: [],
            selectedToppingObj: {},

            storeData: {},
            // plazaData: {},
            itemData: {},

            cartStore: [], // use to make final cart list
            itemsInCart: [], // use to make final cart list

            finalCart: [],

            countCartItem: 0,
        }

        this.handleMinus = this.handleMinus.bind(this);
        this.handlePlus = this.handlePlus.bind(this);
        this.countTotal = this.countTotal.bind(this);
        this.handleAddToCart = this.handleAddToCart.bind(this);
    }

    componentDidMount() {
        // var objPlaza = RetriveObjectFromStorage(Constants.Obj_PLAZA, '');
        var objStore = RetriveObjectFromStorage(Constants.Obj_STORE, '');

        if (objStore) {
            // this.setState({ plazaData: objPlaza });
            this.setState({ storeData: objStore });
        }
    }

    handleMinus() {
        var count = this.props.itemCount;
        if (count !== 1) {
            count = count - 1;
            this.props.setItemCount(count);
            this.setState({
                itemCount: count
            }, () => {
                this.countTotal(this.props.itemCount);
            });
        }
    }

    handlePlus() {
        var count = this.props.itemCount;
        count = count + 1;
        if (this.props.TrackQuantity) {
            if (this.props.AvailableQuantity >= count) {
                this.props.setItemCount(count);
                this.setState({
                    itemCount: count
                }, () => {
                    this.countTotal(count);
                });
            }
        }
        else {
            this.props.setItemCount(count);
            this.setState({
                itemCount: count
            }, () => {
                this.countTotal(count);
            });
        }
    }

    countTotal(count) {
        var price = this.props.itemPrice;
        var toppingCharge = this.props.toppingCharge;
        price = (price + toppingCharge) * count;
        price = (Math.round(price * 100) / 100).toFixed(2);
        this.props.setTotalPrice(price);
    }

    countTotalWithTopping(selectedItems) {
        var toppingPrice = 0.0;
        for (var i = 0; i < selectedItems.length; i++) {
            if (i >= 0) { /* 3 */
                toppingPrice = toppingPrice + selectedItems[i].Price;
            }
        }
        this.props.setToppingPrice(toppingPrice);
        this.setState({ toppingCharge: toppingPrice }, function () {
            this.setTotalPrice();
        });
    }

    setTotalPrice() {
        var price = this.props.itemPrice
        var noOfItems = this.props.itemCount;
        var toppingCharge = this.props.toppingCharge;

        var amount = (price + toppingCharge) * noOfItems;
        amount = (Math.round(amount * 100) / 100).toFixed(2);

        this.props.setTotalPrice(amount)
        this.setState({ totalPrice: amount })
    }

    handleAddToCart() {
        this.setState({ countCartItem: 1, });
        var itemData = this.props.itemData;
        this.props.selectedToppings(itemData);
        $('#toppingModal').modal('hide');
    }

    renderToppingCategory(toppingCategoryList) {
        return toppingCategoryList
            .map(function (item, index) {
                var toppingCategoryName = item.ToppingCategory;
                var toppingItems = item.ToppingItems;
                return (
                    <React.Fragment key={index} >
                        <div className="col-sm-12" style={{ backgroundColor: Color.THEME_COLOR, color: Color.WHITE }} >
                            {toppingCategoryName}
                        </div>
                        {this.renderToppingItem(item._id, toppingItems)}
                    </React.Fragment>
                )
            }, this);
    }

    handleCheck(toppingCaategoryId, toppingList, toppingItemObj) {
        var selectedToppings = this.props.selectedToppingList;
        // if (!toppingItemObj.checked) {
        //     let index = selectedToppings.indexOf(e => e._id === toppingItemObj._id)
        //     if (index < 0) {
        //         selectedToppings.push({ ...toppingItemObj, "checked": true })
        //     }
        //     else {
        //         selectedToppings[index] = { ...toppingItemObj, "checked": true }
        //     }
        // }
        // else {
        var objindex = -1
        for (var i in selectedToppings) {
            if (selectedToppings[i]._id === toppingItemObj._id) {
                objindex = i
                break
            }
        }

        if (objindex != -1) {
            selectedToppings.splice(objindex, 1)
        }
        else {
            selectedToppings.push(toppingItemObj)
        }
        // }

        this.props.setSelectedToppings(selectedToppings);

        // var storeItemObj = this.props.itemData;
        // if (storeItemObj.ToppingCategory) {
        //     storeItemObj.ToppingCategory = storeItemObj.ToppingCategory.map(e1 => {
        //         if (e1._id === toppingCaategoryId) {
        //             var ToppingItems = e1.ToppingItems.map(e => {
        //                 if (e._id === toppingItemObj._id) {
        //                     return { ...e, "checked": !e.checked }
        //                 }
        //                 return { ...e }
        //             })
        //             return { ...e1, "ToppingItems": ToppingItems }
        //         }
        //         return { ...e1 }
        //     })
        // }

        // this.props.setItemData(storeItemObj);

        this.setToppingPrice();
    }

    setToppingPrice() {
        var storeItemObj = this.props.itemData;
        var selectedToppingList = this.props.selectedToppingList;
        if (selectedToppingList.length >= 0) { /* 3 */
            this.countTotalWithTopping(selectedToppingList);
        }
        /* else if (selectedToppingList.length <= 3) {
            var toppingCharge = 0.0
            var price = this.props.itemPrice
            var noOfItems = this.props.itemCount;
            this.props.setToppingPrice(toppingCharge)
            this.setState({ toppingCharge: toppingCharge }, function () {
                var amount = (price + toppingCharge) * noOfItems;
                amount = (Math.round(amount * 100) / 100).toFixed(2);
                this.setState({ totalPrice: amount })
                this.props.setTotalPrice(amount)
            })
        } */
    }

    renderToppingItem(toppingCaategoryId, toppingItems) {
        var currencySymbole = ""
        var countryFees = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES)
        if (countryFees) {
            currencySymbole = (countryFees[currencyInfo.CountryCode] ? "(" + countryFees[currencyInfo.CountryCode] + ") " : "") + countryFees.Symbol;
        }

        return toppingItems
            .map(function (item, index) {

                var selectedToppings = this.props.selectedToppingList;
                var objindex = -1
                for (var i in selectedToppings) {
                    if (selectedToppings[i]._id === item._id) {
                        objindex = i
                        break
                    }
                }

                return (
                    <React.Fragment key={index} >
                        <div className="col-sm-12" style={{ backgroundColor: Color.WHITE, height: "40px", color: Color.BLACK, borderBottom: "1px solid" + Color.GREY }} >
                            <div className="row" style={{ padding: "5px" }} >
                                <Checkbox
                                    name={item._id}
                                    style={{ marginTop: "0px", paddinTop: "0px" }}
                                    color={Color.THEME_COLOR}
                                    size={2}
                                    tickSize={2}
                                    checked={objindex != -1}
                                    delay={0.1}
                                    onChange={(value) => this.handleCheck(toppingCaategoryId, toppingItems, item)}>
                                </Checkbox>
                                <label style={{ marginLeft: "10px", fontSize: "15px" }}>{item.ToppingItem}</label>
                                <span style={{ marginLeft: "auto" }}>
                                    {(item && item.Price != 0) && <label style={{ fontSize: "15px", float: "right", marginRight: "5px" }}>{currencySymbole}{(Math.round(item.Price * 100) / 100).toFixed(2)}</label>}
                                </span>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }, this);
    }

    render() {
        const { toppingList, countCartItem, itemData } = this.state;

        var toppingCategoryList = [];
        if (this.props.itemData.ToppingCategory) {
            toppingCategoryList = this.props.itemData.ToppingCategory
        }

        var currencySymbole = ""
        var countryFees = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES)
        if (countryFees) {
            currencySymbole = (countryFees[currencyInfo.CountryCode] ? "(" + countryFees[currencyInfo.CountryCode] + ") " : "") + countryFees.Symbol;
        }

        return (
            <div>
                <section className="section">
                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                        <div style={{ marginLeft: "0px", marginTop: "10px" }}>
                            <div className="col-sm-12" style={{ marginBottom: "10px", alignItems: 'center', display: 'flex', gap: '2rem' }}>
                                <img src={this.props.itemData.ItemLogo ? this.props.itemData.ItemLogo : NoPriview}
                                    onError={(e) => (e.target.onerror = null, e.target.src = NoPriview)} loading='lazy'
                                    style={{ height: "100px", width: "100px" }}></img>
                                <div>
                                    <label style={{ display: 'block', color: Color.BLACK, fontWeight: 'bold', fontSize: '15px' }}>{this.props.itemData.ItemName} </label>
                                    <label style={{ display: 'block', color: Color.THEME_COLOR, fontSize: '15px' }}>{currencySymbole} {Number(this.props.itemPrice).toFixed(2)}</label>
                                    <label style={{ color: Color.THEME_COLOR, display: 'block', fontSize: '15px' }}><span style={{ color: Color.BLACK }}>Toppings : </span>{currencySymbole} {(Math.round(this.props.toppingCharge * 100) / 100).toFixed(2)}</label>
                                </div>
                            </div>
                        </div>

                        {this.renderToppingCategory(toppingCategoryList)}

                        <div className="row" style={{ padding: "5px" }} >
                            <label style={{ fontSize: "25px", marginLeft: "20px", marginRight: "20px" }} onClick={this.handleMinus}>-</label>
                            <label style={{ fontSize: "25px", marginLeft: "10px", marginRight: "10px", width: "30px" }}> {this.props.itemCount} </label>
                            <label style={{ fontSize: "25px" }} onClick={this.handlePlus}>+</label>
                            <span style={{ marginLeft: "auto" }}>
                                <label className="textcolor" style={{ float: "right", marginRight: "15px" }}>{currencySymbole} {Number(this.props.totalPrice).toFixed(2)}</label>
                            </span>
                        </div>

                        <div
                            className="bottam_sing"
                            style={{ marginTop: "20px", bottom: "0px" }}>
                            <div className="">
                                <label
                                    style={{ borderRadius: "4px", marginBottom: "10px" }}
                                    onClick={this.handleAddToCart}
                                    className="col-sm-6 btn btn-done"
                                > Update Cart</label>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default UpdateToppingsSingleStore;

