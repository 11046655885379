import React, { Component } from 'react';
import { Menu, MenuItem, ProSidebar, SidebarContent, SidebarFooter, SidebarHeader } from 'react-pro-sidebar';
import { withRouter } from 'react-router-dom';
import defaultUser from "../../../images/default-user.png";
import logout from "../../../images/sidemenuicons/logout.png";
import { GetData } from "../../../services/GetData";
import Color from '../../Config/Color';
import { GET_USERPROFILE_API } from '../../Config/Config.json';
import Constants, { UserSessionTypes, currencyInfo } from '../../Config/Constants';
import RouteConstant from '../../Config/RouteConstant';
import { RemoveDataInStorage, RetriveDataFromStorage, RetriveObjectFromStorage, StoreObjectInStorage } from "../../Config/Utils";
import MasterContextCreater from '../../MasterContextProvider/MasterContextCreater';
import LoginOrderWeb from '../Login/LoginOrderWeb';
import RegisterOrderWeb from '../Register/RegisterOrderWeb';
import './SideMenuOrderWeb.css';

class SideMenuOrderWeb extends Component {
    static contextType = MasterContextCreater;
    constructor(props) {
        super(props);
        this.state = {
            opened: false, // (or true)

            currencySymbols: "",
            userDetail: {},
            addressList: [],

            countryData: {},
            countryArr: [],
            isShopifyAvailable: false,
            isCartAvailable: false,
            StoreMainObject: {},
            onGoogleRenderLogin: false,
            onGoogleRenderRegister: false,
            SessionUserType: "",
        }

        this.closeNav = this.closeNav.bind(this);
        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.handleDashboardClick = this.handleDashboardClick.bind(this);
        this.handleAddAddress = this.handleAddAddress.bind(this);
        this.handleEditProfile = this.handleEditProfile.bind(this);
    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        this.getUserDetail(authToken);

        var SessionUserType = RetriveDataFromStorage(Constants.USER_SESSION_TYPES);
        if (SessionUserType) {
            this.setState({ SessionUserType: SessionUserType });
        }

        var countryFees = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES);
        if (countryFees) {
            var currencySymbole = (countryFees[currencyInfo.CountryCode] ? "(" + countryFees[currencyInfo.CountryCode] + ") " : "") + countryFees.Symbol;
            this.setState({ currencySymbols: currencySymbole });
        }

        /* Cart Model */
        var objCart = RetriveObjectFromStorage(Constants.Obj_CART, '');
        if (objCart && objCart.length > 0) {
            if (objCart && objCart[0].isshopifyintegration) {
                var isShopifyAvailable = objCart[0].isshopifyintegration;
                this.setState({ isShopifyAvailable: isShopifyAvailable });
            }
            this.setState({ isCartAvailable: true });
        } else {
            this.setState({ isCartAvailable: false });
        }

        var objStore = RetriveObjectFromStorage(Constants.Obj_STORE, '');
        if (objStore) {
            this.setState({ StoreMainObject: objStore });
        }
    }

    getUserDetail(authToken) {
        var API = GET_USERPROFILE_API;
        GetData(API, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData) {
                        this.setState({ userDetail: responseData });
                        if (responseData.Addresslist) {
                            var profilepic = ""
                            var profileUrl = responseData.ProfilePicture;
                            var profileUrlspite = profileUrl.split("/");
                            if (profileUrlspite[profileUrlspite.length - 1] === "BsonNull") {
                                responseData.ProfilePicture = profilepic;
                            }
                            this.setState({ addressList: responseData.Addresslist });
                        }
                        StoreObjectInStorage(Constants.Obj_USER, responseData);
                    }
                }
            });
    }

    /////////////////////////////////////////// end componentDidMount method /////////////////////////


    ////////////////////////////////////////// child hook methods start ////////////////////////////

    updateUserDetail = () => {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        this.getUserDetail(authToken);
    }

    updateNotifiList = () => {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        // this.getNotificationAPI(authToken);

        const { reffotificationList } = this.context;
        if (typeof reffotificationList == "function") {
            reffotificationList()
        }
    }

    closeNav() {
        this.setState({ opened: false });
        window.closeNav();
    }

    handleToggle() {

    }

    handleDashboardClick = value => {
        const { isnavalert } = this.context
        window.closeNav();
        if (this.state.isCartAvailable || isnavalert) {
            if (this.state.isShopifyAvailable) {
                this.props.history.push({
                    pathname: RouteConstant.PlazaStoreItemsShopify,
                });
            }
            else {
                this.props.history.push({
                    pathname: RouteConstant.PlazaStoreItems,
                });
            }

        } else {
            this.props.history.push({
                pathname: RouteConstant.Dashboard,
            });
        }
    }

    handleMenuClick = value => {
        window.closeNav();
        var PATH = window.location.pathname;
        RemoveDataInStorage(Constants.AUTH_TOKEN);
        RemoveDataInStorage(Constants.USER_SESSION_TYPES);
        RemoveDataInStorage(Constants.Obj_COUNTRY);
        /* RemoveDataInStorage(Constants.Obj_COUNTRY_FEES); */
        RemoveDataInStorage(Constants.Obj_PLAZA);
        /* RemoveDataInStorage(Constants.Obj_STORE); */
        RemoveDataInStorage(Constants.Obj_STORE_ITEMS);
        RemoveDataInStorage(Constants.Obj_ITEMS_TOPPINGS);
        RemoveDataInStorage(Constants.Obj_CART_ITEMS);
        var rememberMe = RetriveDataFromStorage(Constants.FLAG_REMEMBER_ME);
        if (rememberMe !== 'true') {
            RemoveDataInStorage(Constants.Obj_USER);
        }

        /* OrderWeb Cart Data Clear */
        RemoveDataInStorage(Constants.Obj_CART);

        RemoveDataInStorage(Constants.finelObj_BeforePayemt);
        RemoveDataInStorage(Constants.orderDetail);
        RemoveDataInStorage(Constants.ADDRESS_OBJ);
        if (value === '6') {
            this.setState({ userDetail: {} });
            if (PATH === RouteConstant.OrderWebCheckout || PATH === RouteConstant.OrderWebMyCart) {
                /* window.location.reload(); */
                const { StoreMainObject } = this.state;
                if (StoreMainObject) {
                    window.location.href = RouteConstant.OrderWebNavigation + "/" + StoreMainObject.ObjectId + "?web=" + StoreMainObject.websiteurl;
                }
            } else if (PATH === RouteConstant.OrderWebPayment) {
                const { StoreMainObject } = this.state;
                if (StoreMainObject) {
                    window.location.href = RouteConstant.OrderWebNavigation + "/" + StoreMainObject.ObjectId + "?web=" + StoreMainObject.websiteurl;
                }
            } else {
                this.componentDidMount();
            }
        }
    }

    handleStoreNavigation() {
        this.closeNav()
        const { StoreMainObject } = this.state;
        this.props.history.push(RouteConstant.OrderWebNavigation + "/" + StoreMainObject.ObjectId + "?web=" + StoreMainObject.websiteurl);
    }

    handleAddAddress() {
        this.closeNav()
        StoreObjectInStorage(Constants.ADDRESS_OBJ, "");
        this.props.history.push({
            pathname: RouteConstant.AddAddress,
        });

        const { onrefresh } = this.props;
        if (typeof onrefresh == "function") {
            onrefresh();
        }
    }

    handleEditProfile() {
        window.closeNav();
        this.props.history.push({
            pathname: RouteConstant.EditProfile,
        })
    }

    handleStoreLogin(e) {
        window.closeNav();
        this.setState(
            {
                onGoogleRenderLogin: true
            }, function () {
                this.handleModalviewLogin(true);
            });
    }

    handleStoreRegister(e) {
        window.closeNav();
        this.setState(
            {
                onGoogleRenderRegister: true
            }, function () {
                this.handleModalviewRegister(true);
            });
    }

    handleModalviewLogin(falg) {
        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById('loginpublicmodel');

        if (backgroung && modalId) {
            backgroung.style.display = falg ? "block" : "none";
            modalId.style.display = falg ? "block" : "none";
        }
    }

    handleModalviewRegister(falg) {
        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById('registerpublicmodel');

        if (backgroung && modalId) {
            backgroung.style.display = falg ? "block" : "none";
            modalId.style.display = falg ? "block" : "none";
        }
    }

    closeNav() {
        window.closeNav();
    }

    onRefresh = () => {
        var PATH = window.location.pathname;
        if (PATH === RouteConstant.OrderWebCheckout) {
            window.location.reload();
        } else if (PATH === RouteConstant.OrderWebPayment) {
            const { StoreMainObject } = this.state;
            if (StoreMainObject) {
                window.location.href = RouteConstant.OrderWebNavigation + "/" + StoreMainObject.ObjectId + "?web=" + StoreMainObject.websiteurl;
                this.componentDidMount();
            }
        }
        else {
            this.componentDidMount();
        }
    }

    onGRender = () => {
        this.setState({ onGoogleRenderLogin: false, onGoogleRenderRegister: false });
    }

    render() {
        let { myAppointment, deliveryOrderList, curbsideOrderList, notificaionList, notificationCount, isnavalert } = this.context;
        myAppointment = myAppointment ? myAppointment : [];
        deliveryOrderList = deliveryOrderList ? deliveryOrderList : [];
        curbsideOrderList = curbsideOrderList ? curbsideOrderList : [];
        notificaionList = notificaionList ? notificaionList : [];

        return (
            <React.Fragment>
                <div>
                    <div className="modal-background" id="modalbackgroundId"> </div>
                    <ProSidebar>
                        <SidebarHeader style={{ backgroundColor: Color.WHITE }}>
                            <div className="col-sm-12" style={{ marginTop: "10px" }}>
                                <div style={{ backgroundColor: Color.THEME_COLOR, color: Color.WHITE, textAlign: "center", height: "25px", width: "25px", position: "absolute", top: "5px", right: "15px", borderRadius: "20px" }} onClick={this.closeNav}  >
                                    <i class="fa fa-times" style={{ fontSize: "15px", marginTop: "5px" }}></i>
                                </div>
                                {(this.state.userDetail && this.state.userDetail.Email && this.state.SessionUserType == UserSessionTypes.PLAZACART_CUSTOMER) ?
                                    <center>
                                        <img src={this.state.userDetail.ProfilePicture ? this.state.userDetail.ProfilePicture : defaultUser} onError={(e) => (e.target.onerror = null, e.target.src = defaultUser)} style={{ borderRadius: "50px" }} width="100" height="100"></img>
                                        {/* <div style={{ backgroundColor: Color.THEME_COLOR, color: Color.WHITE, textAlign: "center", height: "30px", width: "30px", position: "absolute", top: "60px", right: "80px", borderRadius: "20px", cursor: "pointer" }} >
                                            <i class="fas fa-pencil-alt" style={{ fontSize: "17px", marginTop: "6px" }} onClick={this.handleEditProfile}></i>
                                        </div> */}
                                        <br /><br />
                                        <label style={{ color: Color.BLACK }}>{this.state.userDetail.FirstName} {this.state.userDetail.LastName}</label><br />
                                        <label style={{ color: Color.BLACK }}>{this.state.userDetail.Email}</label>
                                    </center> :
                                    <div>
                                        <br />
                                        <label style={{ color: Color.BLACK, fontWeight: '500' }}>Welcome!</label>
                                        <label style={{ color: Color.BLACK, fontSize: '15px' }}>
                                            Reorder your favorites and quickly checkout at any restaurant powered
                                            by Brewers near me</label>
                                        <br />
                                    </div>}
                            </div>
                        </SidebarHeader>

                        <SidebarContent style={{}}>
                            <Menu style={{ backgroundColor: Color.WHITE }} >
                                <MenuItem
                                    onClick={(value) => this.handleStoreNavigation()}
                                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}>
                                    {(this.state.StoreMainObject && this.state.StoreMainObject.ApplicationName) ? this.state.StoreMainObject.ApplicationName : ""}
                                </MenuItem>
                                {(!(this.state.userDetail && this.state.userDetail.Email) || (this.state.SessionUserType == UserSessionTypes.GUEST_CUSTOMER)) && <MenuItem
                                    onClick={(value) => this.handleStoreLogin(value)}
                                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}>
                                    Sign In
                                </MenuItem>}
                                {(!(this.state.userDetail && this.state.userDetail.Email) || (this.state.SessionUserType == UserSessionTypes.GUEST_CUSTOMER)) && <MenuItem
                                    onClick={(value) => this.handleStoreRegister(value)}
                                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}>
                                    Sign Up
                                </MenuItem>}
                                <MenuItem
                                    onClick={(value) => {
                                        window.closeNav();
                                        window.open(RouteConstant.OrderWebPrivacyPolicy);
                                    }}
                                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}>
                                    Privacy Policy
                                </MenuItem>
                                <MenuItem
                                    onClick={(value) => {
                                        window.closeNav();
                                        window.open(RouteConstant.OrderWebTermsOfService);
                                    }}
                                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}>
                                    Terms & Conditions
                                </MenuItem>
                                <MenuItem
                                    onClick={(value) => {
                                        window.closeNav();
                                        window.open(RouteConstant.OrderWebAboutUs);
                                    }}
                                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}>
                                    About Us
                                </MenuItem>
                                <MenuItem
                                    onClick={(value) => {
                                        window.closeNav();
                                        window.open(RouteConstant.OrderWebHelpAndSupport);
                                    }}
                                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}>
                                    Help and Support
                                </MenuItem>
                                <MenuItem
                                    onClick={(value) => {
                                        window.closeNav();
                                        window.open(RouteConstant.OrderWebContactUs);
                                    }}
                                    style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}>
                                    Contact Us
                                </MenuItem>
                                {(this.state.userDetail
                                    && this.state.userDetail.Email
                                    && this.state.SessionUserType == UserSessionTypes.PLAZACART_CUSTOMER)
                                    && <MenuItem
                                        onClick={(value) => this.handleMenuClick('6')}
                                        icon={<img src={logout} style={{ height: "25px", width: "25px" }}></img>}
                                        style={{ backgroundColor: Color.WHITE, color: Color.BLACK }}>
                                        Logout
                                    </MenuItem>}
                            </Menu>
                        </SidebarContent>

                        <SidebarFooter style={{ backgroundColor: Color.WHITE, padding: '5px', fontSize: '12px' }}>
                            <center>Copyright © 2023 All Rights Reserved By <span style={{ color: Color.THEME_COLOR }}>plazacart.com</span></center>
                        </SidebarFooter>

                    </ProSidebar>
                </div>

                {/* Login Page  */}
                <LoginOrderWeb
                    onGoogleRender={this.state.onGoogleRenderLogin}
                    onGRender={this.onGRender}
                    onRefresh={this.onRefresh} />

                {/* Register Page */}
                <RegisterOrderWeb
                    onGoogleRender={this.state.onGoogleRenderRegister}
                    onGRender={this.onGRender}
                    onRefresh={this.onRefresh} />

            </React.Fragment>
        );
    }
}

export default withRouter(SideMenuOrderWeb);