import React, { Component, Fragment } from 'react';
import Color from '../../Config/Color';
import { POLICY_IN_VISIBLE_MODE } from '../../Config/Constants';
import RouteConstant from '../../Config/RouteConstant';
import { PrivacyAndPolicy } from "../../Config/constvariable";
import HeaderOrderWeb from '../Header/HeaderOrderWeb';

export default class PrivacyPolicyOrderWeb extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: 0
        }
    }

    componentDidMount() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        if (params.get(POLICY_IN_VISIBLE_MODE.MODE) != null
            && params.get(POLICY_IN_VISIBLE_MODE.MODE).length > 0
            && params.get(POLICY_IN_VISIBLE_MODE.MODE) != "") {
            if (params.get(POLICY_IN_VISIBLE_MODE.MODE) == POLICY_IN_VISIBLE_MODE.PRIVATE_MODE) {
                this.setState({ mode: POLICY_IN_VISIBLE_MODE.PRIVATE_MODE });
            }
        }
    }

    render() {
        const { mode } = this.state;
        return (
            <Fragment>
                {(mode != POLICY_IN_VISIBLE_MODE.PRIVATE_MODE)
                    &&
                    <div class="hero_area">
                        <HeaderOrderWeb setHeaderTitle={PrivacyAndPolicy.screenTitle} />
                    </div>
                }
                <section className="section" style={{ float: "unset" }}>
                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }} >
                        <div className="reg_form_main">
                            {/* <center><img src={plazacartLogo} className="col-sm-4"></img></center> */}
                            {/* For Order Web Changes on Date 22 June 2023 */}
                            {/* <center><p id="instruction" className="textcolor" style={{ marginBottom: '10px' }}>{PrivacyAndPolicy.screenTitle}</p> </center> */}
                        </div>
                        <div class="midsection pl-2 pr-2 pl-sm-0 pr-sm-0 pages" style={{ textAlign: 'justify' }}>
                            <div class="Justify_Font_11">
                                {/*<p><em>Last Updated as on April, 2022</em></p>
                                <p>&nbsp;</p>
                                <p>The {COMPANYCONST.PLAZACART} application and website (&ldquo;Platform&rdquo;) are made available to you by {COMPANYCONST.PIDEXINC} and/or its affiliates (hereinafter may be referred to as&nbsp;the&nbsp;<strong>&lsquo;Company&rsquo;, &lsquo;we&rsquo;, &lsquo;us&rsquo;,</strong>&nbsp;and&nbsp;<strong>&lsquo;our&rsquo;</strong>) respect your privacy and is committed to protecting it through its compliance with its privacy policy. This policy amongst other things describes: (i) the type of information that the Company may collect from you when you access or use its websites, applications and other online services (hereinafter collectively referred to as the&nbsp;<strong>&lsquo;Services&rsquo;</strong>); and, (ii) the Company&rsquo;s practices for collecting, using, maintaining, protecting and disclosing that information.</p>
                                <p>We encourage you to read this policy carefully to understand the Company's policies and practices regarding your information. By accessing or using its Services or its Platform, registering an account with the Company, becoming a supplier, reseller or customer on the Platform, or by attempting to become a supplier, reseller or customer, you expressly agree to be bound by the terms and conditions of this privacy policy and you are consenting to the Company's collection, use, disclosure and&nbsp; retention of your personal information as described here.</p>
                                <p>This policy may change from time to time, your continued use of the Company's Services after it makes any change is deemed to be acceptance of those changes, so please check the policy periodically for updates.</p>
                                <ol>
                                    <li><strong>Applicability of the Policy</strong></li>
                                </ol>
                                <p>1.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This policy applies only to the information the Company collects through its Services, in email, text and other electronic communications sent through or in connection with its Services.</p>
                                <p>1.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This Policy does not apply to the information that you provide to, or that is collected by, any third-party, that you use in connection with its Services. The Company encourages you to consult directly with such third-parties about their privacy practices.</p>
                                <p>&nbsp;</p>
                                <ol start="2">
                                    <li><strong>Collection of the information</strong></li>
                                </ol>
                                <p>2.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Some of our Services may be used without revealing any personal information, and for other Services, personal information is required. We may also collect &lsquo;Non-Personal Information&rsquo; (i.e., information that cannot be used to identify you). Non-Personal Information includes information like the web pages that you have viewed. In order to access certain features and benefits on our Services, you may need to submit &lsquo;Personally Identifiable Information&rsquo; i.e., information that can be used to identify you (<em>as described below</em>). Inaccurate information may affect your ability to use the Services, the information you receive when using the Services, and our ability to contact you. For example, your email address and contact number should be kept valid because these may be&nbsp; the primary channels through which we communicate with you. You are responsible for ensuring the accuracy of the Personally Identifiable Information you submit to the Company.</p>
                                <p>2.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Company&nbsp;collects several types of information from and about users of our Services, including: (i) Your Personal Information- Personal Information is the information that can be associated with a specific person and could be used to identify that specific person whether from that data, or from the data and other information that we have, or is likely to have access to. We do not consider personal information to include information that has been made anonymous or aggregated so that it can no longer be used to identify a specific person, whether in combination with other information or otherwise. Personally Identifiable Information can include, but not be limited to, information such as your name, email address, contact number (cellular and landline), educational qualification(s), occupation, date of birth, marital status, monthly income, city and state of residence, marital status, number of children, employer details, &nbsp;Aadhaar number, PAN, social security and tax identification numbers, and post-qualification or work experience among other things; and/or (ii) Information about your internet connection, the equipment you use to access our Services and your usage details.</p>
                                <p>2.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We may collect this information either (i)&nbsp;directly from you when you provide it to us; (ii) automatically as you navigate through our Services (information collected automatically may include usage details, IP addresses and information collected through cookies, web beacons and other tracking technologies); and/or (iii)&nbsp;from any other source of&nbsp;information including from other third party sources, such as updated delivery and address information from our carriers, which we use to correct our records and deliver your next purchase more easily.</p>
                                <p>2.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Information you provide to us.</p>
                                <p>2.4.1.Your account information: Your full name, email address, postal code, password and other information you may provide with your account, such as your gender, mobile phone number and website. Your profile picture (if any) that will be publicly displayed as part of your account profile. You may optionally provide us with this information through third-party sign-in services such as Facebook and Google Plus. In such cases, we fetch and store whatever information is made available to us by you through these sign-in services.</p>
                                <p>2.4.2.Your preferences: Your preferences and settings such as time zone and language.</p>
                                <p>2.4.3.Your content: Information you provide through our Services, including your reviews, photographs, comments, lists, followers, the users you follow, ordering details and history, favourite categories, special requests, contact information of people you add to, or notify of, your orders through our Services, names, and other information you provide on our Services, and other information in your account profile.</p>
                                <p>2.4.4.Your searches and other activities: The search terms you have looked up and results you selected.</p>
                                <p>2.4.5.Your browsing information: How long you used our Services and which features you used; the ads you clicked on.</p>
                                <p>2.4.6.Your communications: Communications between you and other users or suppliers through our Services; your participation in a survey, poll, sweepstakes, contest or promotion scheme; your request for certain features (e.g., newsletters, updates or other products); your communication with us about employment opportunities posted to the services.</p>
                                <p>2.4.7.Your transactional information: If you make use of our Services, we may collect and store information about you to process your requests and automatically complete forms for future transactions, including (but not limited to) your phone number, address, email, billing information and credit or payment card information. This information may be shared with third-parties which assist in processing and fulfilling your requests, including PCI compliant payment gateway processors. If you write reviews about businesses with which you conduct transactions through our Services, we may publicly display information that you transacted with those businesses.</p>
                                <p>2.4.8.Your Public Posts: You also may provide information (such as ratings, reviews, tips, photos, comments, likes, bookmarks, friends, lists, etc.) to be published or displayed (hereinafter, "posted") on publicly accessible areas of our Services, or transmitted to other users of our Services or third-parties (hereinafter collectively referred to as &lsquo;User Contributions&rsquo;). Your User Contributions are posted on and transmitted to others at your own risk. Please be aware that no security measures are perfect or impenetrable (see "Security" section below). Additionally, we cannot control the actions of other users of our Services with whom you may choose to share your User Contributions. Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by unauthorized persons. We may display this information on the Services, share it with businesses, and further distribute it to a wider audience through third party sites and services. You should be careful about revealing any sensitive details about yourself in such postings.</p>
                                <p>2.4.9.We use the information you provide to us to analyse and enhance the functionality and improve the quality of our Services, and to personalize your experience while using our Services. We also use this information to display relevant advertising, provide support to you, communicate with you, and comply with our legal obligations.</p>
                                <p>2.5.&nbsp;&nbsp;&nbsp;&nbsp;Information we may automatically collect include but may not be limited to the following.</p>
                                <p>2.5.1.We may automatically collect certain information about the computer or devices (including mobile devices) you use to access the Services, and about your use of the Services, even if you use the Services without registering or logging in.</p>
                                <p>2.5.2.Usage information: Details of your use of our Services, including traffic data, location data, logs and other communication data and the resources that you access and use on or through our Services.</p>
                                <p>2.5.3.Computer and device information: Information about your computer, Internet connection and mobile device, including your IP address, operating systems, platforms, browser type, other browsing information (connection, speed, connection type etc.), device type, device's unique device identifier, mobile network information and the device's telephone number.</p>
                                <p>2.5.4.Stored information and files: Our Services also may access metadata and other information associated with other files stored on your mobile device. This may include, for example, photographs, audio and video clips, personal contacts and address book information.</p>
                                <p>2.5.5.Location information: Our applications collect real-time information about the location of your device, as permitted by you.</p>
                                <p>2.5.6.Last URL visited: The URL of the last web page you visited before visiting our websites.</p>
                                <p>2.5.7.Mobile device IDs: Unique mobile device identifier (e.g. IDFA, GAID or other device IDs on Apple devices like the iPhone and iPad), if you're using our Services on a mobile device, we may use mobile device IDs (the unique identifier assigned to a device by the manufacturer), instead of cookies, to recognize you. We may do this to store your preferences and track your use of our applications. Unlike cookies, mobile device IDs cannot be deleted. Advertising companies may use device IDs to track your use of our applications, track the number of advertisements displayed, measure advertising performance and display advertisements that are more relevant to you. Analytics companies may use mobile device IDs to track your usage of our applications.</p>
                                <p>2.5.8.Your preferences: Your preferences and settings such as time zone and language.</p>
                                <p>2.5.9.Your activity on the Services: Information about your activity on the Services, such as your search queries, comments, domain names, search results selected, number of clicks, pages viewed and the order of those pages, how long you visited our Services, the date and time you used the Services, error logs, and other similar information.</p>
                                <p>2.5.10.&nbsp;&nbsp;&nbsp;&nbsp;Mobile status: For mobile application users, the online or offline status of your application.</p>
                                <p>2.5.11.&nbsp;&nbsp;&nbsp;&nbsp;Applications: If you use the Company&rsquo;s application, the Company may collect information about the presence and/ or absence and/ or details pertaining to other applications on your mobile phone. The applications we gather information for, may vary across categories including, without limitation, shopping, fashion, food and travel. This will help us understand you and your preferences better and enable the Company to provide you with a personalized experience. We may collect, process and store your user ID associated with any social media account (such as your Facebook and Google account) that you use to sign into the Services or connect with or use with the Services. Please see your social media provider's privacy policy and help center for more information about how they share information when you choose to connect your account.</p>
                                <p>2.6 You may withdraw the consent provided to us to collect and use your personal information by wiriting to the designated Grievance Officer as provided in Section 14 of this Privacy Policy. However, in case of withdrawal of such consent, we may not undertake the activities for which the information was sought.</p>
                                <ol start="3">
                                    <li><strong>Use of the information</strong></li>
                                </ol>
                                <p>We use the information we collect from and about you for a variety of purposes, including to:</p>
                                <p>3.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Purchase and delivery of products and services. We use your personal information to take, handle and fulfill orders, deliver products and services, process payments, and communicate with you about orders, products and services, and promotional offers.</p>
                                <p>3.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Provide, troubleshoot, and improve the Services. We use your personal information to provide functionality, analyze performance, fix errors, and improve the usability and effectiveness of the Services.</p>
                                <p>3.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Recommendations and personalization. We use your personal information to recommend features, products, and services that might be of interest to you, identify your preferences, and personalize your experience with the Services. We may also share your preferences or the Services availed by you with your network followers on the Company for marketing and other promotional activities of our Services</p>
                                <p>3.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Comply with legal obligations. In certain cases, we collect and use your personal information to comply with laws. For instance, we collect from sellers information regarding place of establishment and bank account information for identity verification and other purposes.</p>
                                <p>3.5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Communicate with you. We use your personal information to communicate with you in relation to the Services via different channels (e.g., by phone, e-mail, chat).</p>
                                <p>3.6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Advertising. We use your personal information to display interest-based ads for features, products, and services that might be of interest to you. We do not use information that personally identifies you to display interest-based ads.</p>
                                <p>3.7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Fraud Prevention and Credit Risks. We use personal information to prevent and detect fraud and abuse in order to protect the security of our users, the Company, and others. We may also use scoring methods to assess and manage credit risks.</p>
                                <p>3.8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To administer contests and sweepstakes.</p>
                                <p>3.9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To carry out Company&rsquo;s obligations and enforcing rights arising from any contracts entered into between you and the Company, including for billing and collection.</p>
                                <p>3.10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Research. Generating and reviewing reports and data, and to conduct research on the Company&rsquo;s user base and service usage patterns. To conduct research following internal review protocols to ensure the balancing of privacy and to use anonymized data for research. Use for internal purposes such as auditing. understand our users (what they do on our Services, what features they like, how they use them, etc.), improve the content and features of our Services (such as by personalizing content to your interests), process and complete your transactions, and make special offers</p>
                                <p>3.11.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To fulfil any other purpose for which you provide us the information and/or for any other purpose with your consent.</p>
                                <p>&nbsp;</p>
                                <ol start="4">
                                    <li><strong>Sharing of the information</strong></li>
                                </ol>
                                <p>4.1.&nbsp;&nbsp;&nbsp;&nbsp;We may disclose personal information that we collect or you provide, as described in this privacy policy, in the following ways:</p>
                                <p>4.1.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;General Information Disclosures</p>
                                <ol>
                                    <li>To our holding companies, subsidiaries and affiliates, which are entities under common ownership or control of the Company.</li>
                                    <li>To contractors, advertisers/service providers and other third-parties whom we use to support our business (e.g. logistics and delivery, to collect payments) and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.</li>
                                    <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution or other sale or transfer of some or all of the Company's assets, whether as a going concern or as part of bankruptcy, liquidation or similar proceeding, in which personal information held by the Company about the users of our Services are among the assets transferred.</li>
                                    <li>To third-parties to market their products or services to you which we feel may be of interest or beneficial to you.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; We contractually require these third-parties to keep personal information confidential and use it only for the purposes for which we disclose it to them.</li>
                                </ol>
                                <p>4.1.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To fulfill the purpose for which you provide it.</p>
                                <p>4.1.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For any other purpose disclosed by us when you provide the information.</p>
                                <p>4.2.&nbsp;&nbsp;&nbsp;&nbsp;Service Providers. We may share your information with outside vendors that we use for a variety of purposes, such as to send you communications via emails, messages or tele-call to inform you about the services and/or products&nbsp; that may be of interest to you, push notifications to your mobile device on our behalf, provide voice recognition services to process your spoken queries and questions, help us analyze use of our Services, and process and collect payments. Some of our products, services and databases are hosted by third party hosting services providers. We also may use vendors for other projects, such as conducting surveys, organizing sweepstakes for us, advertising, marketing and advertisement measuring purposes. We may share information about you with these vendors only to enable them to perform their services.</p>
                                <p>4.3.&nbsp;&nbsp;&nbsp;&nbsp;Legal Purposes. We may share your information when we believe in good faith that such sharing is reasonably necessary in order to investigate, prevent, or take action regarding possible illegal activities or to comply with legal process. We may also share your information to investigate and address threats or potential threats to the physical safety of any person, to investigate and address violations of this Privacy Policy or the Company&rsquo;s Terms of Service, or to investigate and address violations of the rights of third parties and/or to protect the rights, property and safety of the Company, our employees, users, or the public. This may involve the sharing of your information with law enforcement, government agencies, courts, and/or other organizations on account of legal requests such as subpoena, court order or government demand to comply with the law.</p>
                                <p>4.4.&nbsp;&nbsp;&nbsp;&nbsp;Social Networks. If you interact with social media features on our Services, such as the Facebook Like button, or use your social media credentials to log-in or post content, these features may collect information about your use of the Services, as well as post information about your activities on the social media service. Your interactions with social media companies are governed by their privacy policies.</p>
                                <p>4.5.&nbsp;&nbsp;&nbsp;&nbsp;To enforce or apply our Terms of Use available at [https://{COMPANYCONST.WWWPLAZACARTCOM}{RouteConstant.TermsOfService}] and other agreements, including for billing and collection purposes.</p>
                                <p>4.6.&nbsp;&nbsp;&nbsp;&nbsp;If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our users or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</p>
                                <p>4.7.&nbsp;&nbsp;&nbsp;&nbsp;Consent. We may share your information in any other circumstances where we have your consent.</p>
                                <p>&nbsp;</p>
                                <ol start="5">
                                    <li><strong>Third Party links and services</strong></li>
                                </ol>
                                <p>5.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Services may contain links to third-party websites. Your use of these features may result in the collection, processing or sharing of information about you, depending on the feature. Please be aware that we are not responsible for the content or privacy practices of other websites or services which may be linked on the Company&rsquo;s Services. We do not endorse or make any representations about third-party websites or services. Our Privacy Policy does not cover the information you choose to provide to or that is collected by these third parties. We strongly encourage you to read such third parties' privacy policies.</p>
                                <p>&nbsp;</p>
                                <ol start="6">
                                    <li><strong>Anonymous or de-identified data</strong></li>
                                </ol>
                                <p>6.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We may anonymize and/or de-identify information collected from you through the Services or via other means, including via the use of third-party web analytic tools as described below. As a result, our use and disclosure of aggregated and/or de-identified information is not restricted by this Privacy Policy, and it may be used and disclosed to others without limitation.</p>
                                <p>&nbsp;</p>
                                <ol start="7">
                                    <li><strong>Cookies</strong></li>
                                </ol>
                                <p>7.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cookies are alphanumeric identifiers with a small amount of data that is stored on the user&rsquo;s device hard-drive containing information about the user, commonly used as an anonymous unique identifier. We may offer certain features only through &lsquo;cookies&rsquo; and may also collect information about you using these cookies. Please note, a cookie in no way gives the Company access to your device. Other websites may place their own cookies or other files on your device, collect data or solicit personal information from you, for which the Company shall not be held responsible or liable. We encourage you to read the privacy policies of all external sites. We also use cookies from third party partners for marketing and promotional purposes. Please note that most web browsers are set to accept cookies by default.</p>
                                <p>7.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We strive to provide you with choices regarding the personal information you provide to us.</p>
                                <p>&nbsp;</p>
                                <ol start="8">
                                    <li><strong>Security Precautions</strong></li>
                                </ol>
                                <p>8.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We ensure to maintain reasonable physical, electronic, and managerial procedures to safeguard and help prevent unauthorized access to your information and to maintain data security. These safeguards take into account the sensitivity of the information that we collect, process and store and the current state of technology. We follow generally accepted industry standards to protect the personal information submitted to us, both during transmission and once we receive it. The access to the&nbsp;Services is offered through the use of a secure server and adhere to our security guidelines to protect it against unauthorized access. However, by using the Services, the users accept the inherent security implications of data transmission over the internet and the World Wide Web which cannot always be guaranteed as completely secure, and therefore, there would always remain certain inherent risks regarding use of the Services.</p>
                                <p>8.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We assume no liability or responsibility for disclosure of your information due to errors in transmission, unauthorized third-party access, or other causes beyond our control. You play an important role in keeping your personal information secure. You should not share your user name, password, or other security information for your account with anyone. If we receive instructions using your user name and password, we will consider that you have authorized the instructions for such use.</p>
                                <p>&nbsp;</p>
                                <ol start="9">
                                    <li><strong>Permissible Age</strong></li>
                                </ol>
                                <p>9.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Services are not intended for users under the age of 18 (eighteen), unless permitted under applicable local laws (Permissible Age). We do not knowingly collect any personal information from users or market to or solicit information from anyone under the age of 18 and use of our Services is available only to persons who can form a legally binding contract under the Indian Contract Act, 1872.</p>
                                <p>9.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If you are under the age of 18 years then you must use the Services under the supervision of your parent, or legal guardian. If we become aware that a person submitting personal information is under the age of 18 years, we will delete the account and any related information as soon as possible.</p>
                                <p>&nbsp;</p>
                                <ol start="10">
                                    <li><strong>Data Retention</strong></li>
                                </ol>
                                <p>10.1.&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; A user can close an account by visiting the profile settings page on our website. A user may request for deletion of its personal information by raising a request with the Grievance Officer as per the provisions of Section 14 of this Privacy Policy. Notwithstanding the foregoing, we reserve the right to retain your personal information in accordance with applicable laws, for a period no longer than is required for the purpose for which it was collected or as required under any applicable law. We may retain information about you for the purposes authorized under this Privacy Policy unless prohibited by law. Thereafter, we will either delete your personal information or de-identify it so that it is anonymous and not attributed to your identity. We may archive information to prevent, investigate, or identify possible wrongdoing in connection with the Service or to comply with legal obligations. We may also continue to retain your data in anonymised form for analytical and research purposes.&nbsp; &nbsp;</p>
                                <p>&nbsp;</p>
                                <ol start="11">
                                    <li><strong>Job Applicants</strong></li>
                                </ol>
                                <p>11.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If your information is submitted to us through our Service when applying for a position with the Company, the information will be used to consider your application. We may retain your information for any period of time. This information may be shared with other companies for the purpose of evaluating your qualifications for the particular position or other available positions, as well as with third-party service providers retained by us to collect, maintain and analyze candidate submissions for job postings.</p>
                                <p>&nbsp;</p>
                                <ol start="12">
                                    <li><strong>Your Consent</strong></li>
                                </ol>
                                <p>12.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;By&nbsp;accessing&nbsp;or using the Services or by providing your information, you consent to the collection, use, storage, disclosure and otherwise processing of your information (including sensitive personal information) on the Services in accordance with this Privacy Policy. If you disclose to us any personal information relating to other people, you represent that you have the authority to do so and to permit us to use the information in accordance with this Privacy Policy.</p>
                                <p>12.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You, while providing your personal information in relation to the Services or any partner platforms or establishments, consent to us (including our other corporate entities, affiliates, lending partners, technology partners, marketing channels, business partners and other third parties) to contact you through SMS, instant messaging apps, call and/or e-mail for the purposes specified in this Privacy Policy.</p>
                                <p>&nbsp;</p>
                                <ol start="13">
                                    <li><strong>Changes to this Privacy policy</strong></li>
                                </ol>
                                <p>13.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We reserve the right to amend this Privacy Policy from time to time to reflect changes in the law, our data collection and use practices, the features of our services, or advances in technology. Please check this page periodically for changes. Use of information we collect is subject to the Privacy Policy in effect at the time such information is used. If we make any material changes to this Privacy Policy, we will post the changes here. Please review the changes carefully. Your continued use of the Services following the posting of changes to this Privacy Policy will constitute your consent and acceptance of those changes.</p>
                                <p>&nbsp;</p>
                                <ol start="14">
                                    <li><strong>Grievance Officer</strong></li>
                                </ol>
                                <p>14.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In accordance with Information Technology Act, 2000 and the Information Technology (Reasonable Security Practices and Procedures And Sensitive Personal Data or Information) Rules, 2011, the name and contact details of the Grievance Officer are provided below:</p>
                                <p>&nbsp;</p>
                                <p>Name &ndash; Lopamudra Rao</p>
                                <p>Address - 06-105-B, 06-102, (138 WU), Vaishnavi Signature,</p>
                                <p>No. 78/9, Outer Ring Road, Bellandur Village Varthur Hobli,</p>
                                <p>Bengaluru, Karnataka &ndash; 560103</p>
                                <p>&nbsp;</p>
                                <p>email id:&nbsp;{COMPANYCONST.PLAZACARTMAIL}</p>
                                <p><strong>&nbsp;</strong></p>
                                <ol start="15">
                                    <li><strong>Contact us</strong></li>
                                </ol>
                                <p>15.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If you have any queries relating to the processing/ usage of information provided by you or the Company's Privacy Policy or if you would like to raise any other inquiries, you may email us at the contact information provided above under section 14 of this privacy policy.</p>
                                <p>&nbsp;</p>*/}

                                {/* <p class="font_bold"></p>
                                <p><strong>plazacart</strong> is a leading provider of software and services.</p>
                                <p>plazacart is headquartered in Missisauga Ontario, Canada. We support our customers with a full range of managed and professional computing services, and collaborate with an array of business partners.</p>
                                <p>plazacart is committed to protecting any personal information we collect. To this end, we have prepared this Privacy Policy to communicate our ongoing commitment to ensuring personal information remains accurate, confidential and used only for identified purposes, regardless of how it is provided to us.</p>
                                <p>plazacart believes that having a relationship with our customers that is founded on trust is not merely the law but also good business. We believe in giving full disclosure about the kinds of information we gather and what we do with it. We are committed to ensuring that privacy of our customers and visitors is protected. We are responsible for the personal information we collect, use, maintain and disclose. To ensure this accountability, we have developed this Privacy Policy, and have provided training to our employees on how to properly implement our privacy policies and practices.</p>
                                <p class="font_bold">What is "Personal Information?"</p>
                                <p>The term "Personal Information" refers to any information that identifies an individual or by which an identity could be derived but does not include corporate or business contact information about an identifiable individual. Personal information also excludes aggregated information that is no longer related to a specific identifiable individual. plazacart reserves the right to use aggregated or anonymous information in any way it considers appropriate. This includes anonymous or aggregated information that we obtain from you as a visitor to our web site or by using our programs and software.</p>
                                <p class="font_bold">Collected Personal Information</p>
                                <p>plazacart collects personal information from our customers and visitors for a variety of reasons. We may collect personal information in the furtherance of our contractual obligations.</p>
                                <p>In serving the needs of our visitors and customers, plazacart believes that it is important to have accurate and timely information. Therefore, we may collect information through our website, personal contact and through the use of our software solutions. The kinds of personal information we will ask for may include your name, e-mail address, phone number, address, type of business, as well as such other personal information that is needed to fulfill the purposes of a particular statement of work or to further its obligations under a particular program or as identified in this Privacy Policy. Sometimes we will ask for other personal information to fulfill the needs of the particular application or service agreement requested and authorized by our visitors and customers.</p>
                                <p>We do not solicit customers of our clients, unless authorized specifically in writing to do so by the client.</p>
                                <p>We also collect and store statistics and other information about the online activities of visitors to the plazacart web site or through the use of plazacart's software solutions. These statistics do not contain individual identifying information and constitute aggregated or anonymous data only.</p>
                                <p>Customers may be invited to receive an email newsletter by providing an email address. Customer email addresses and any personal customer information will not be distributed or shared with third parties. Customers can opt out of being contacted by us, or receiving such information from us, at any time by sending an email to <strong>privacyofficer@plazacart.com</strong></p>
                                <p>Business customers may be using our products and services in connection with the use of  data and information about their customers and prospects ("Data"). Sometimes we may also obtain Data from other sources, a government agency or registry, or other individuals or organizations that our customers have  provided informed consent to contact. plazacart will not review, share, distribute, print, or reference any such Data except as provided in the agreement between us and our customer (the "Agreement"), or as may be required by law. The agreement between plazacart and our business customer may provide for the use of non-identifying information to identify and analyze data for business purposes. In no circumstance will the Personal Information of a specific end-user be transferred, sold, or shared with a third party without their express written consent. </p>
                                <p>Individual records may at times be viewed or accessed only for the purpose of resolving a problem, support issue, or suspected violation of the Agreement, or as may be required by law. Of course, customers are responsible for maintaining the confidentiality and security of their user registrations and passwords. </p>
                                <p class="font_bold">Consent</p>
                                <p>Consent for the collection, use and/or disclosure of personal information may be obtained orally, electronically or in writing and may be expressly given or implied. In determining how we obtain consent, we will take into account the sensitivity or the personal information that we are collecting, using and/or disclosing. </p>
                                <p>We may use personal information to provide products or services, including for billing and credit purposes.  Other uses of personal information may include the detection of fraud, security or technical issues, and to protect against imminent harm to the rights, property, or safety of plazacart, or the public as permitted or required by law. We will not send any further material to anyone that informs us that they no longer wish to receive information about our services or about new developments.</p>
                                <p class="font_bold">Disclosure of Personal Information</p>
                                <p>Under certain circumstances, we may disclose personal information when we are required by law to do so or when we have consent for the disclosure. We also reserve the right to disclose personal information to third parties to help collect payment for any product purchase or service. Personal information may also be transferred to third-party services providers for administrative purposes (e.g. computer back-up service) and the third party is bound by an Agreement and our Privacy Policy. Public information may also be freely disclosed unless you have requested that it not be disseminated.</p>
                                <p class="font_bold">Security of Your Personal Information</p>
                                <p>We take all reasonable precautions to ensure that personal information is kept safe from loss, unauthorized access, modification, or disclosure. We operate on a secure data network protected by an industry standard firewall and password protected systems. Our security and privacy policies are periodically reviewed and enhanced, and only authorized individuals at plazacart have access to personal information unless we have consent for access by a third party through an agreement.</p>
                                <p>plazacart employs industry standard technological safeguards such as network security software and hardware firewalls to prevent hacking or unauthorized computer access. Personal Information is password protected on all of our internal networks and continuously monitored by full-time network security personnel. All employees and third parties, including licensed users of our software applications that have access to personal information collected by us, are required to sign a confidentiality agreement in relation to this Privacy Policy.</p>
                                <p class="font_bold">Links to Other Websites or Services</p>
                                <p>Our website contains links to other websites. plazacart is not responsible for the privacy practices or the content of such websites. plazacart is not responsible for third-party actions and disclosure of personal information by such third parties.</p>
                                <p class="font_bold">Email Addresses and Cookies</p>
                                <p>plazacart recognizes that SPAM is a growing problem on networks of all sizes and we are doing our part to prevent its spread. To prevent the growth of SPAM and in accordance with applicable laws, plazacart will not share or sell email addresses to mass marketers or to other third parties.</p>
                                <p>Cookies are pieces of data assigned by a web server and stored on a computer's hard drive for later retrieval. We do not use cookies.</p>
                                <p class="font_bold">Retention of Your Personal Information</p>
                                <p>plazacart keeps personal information for as long as is reasonably necessary for us to complete the objectives set out at the time of collecting the information. When performing services we may retain personal information as long a reasonably necessary to fulfill our contractual obligations. We will retain personal information for as long as our Agreement with our customer requires, or as may be required by law, whichever is longer.</p>
                                <p class="font_bold">Changes to this Privacy Policy</p>
                                <p>Since plazacart regularly reviews all of its policies and procedures, plazacart may change its Privacy Policy from time to time. All changes to this policy will be posted promptly.</p>
                                <p class="font_bold">Request for Access and Inquiries/Complaints</p>
                                <p>If you have any questions please contact our privacy officer at: <strong>privacyofficer@plazacart.com</strong>.</p> */}

                                <p><strong>PLAZACART.COM PRIVACY POLICY</strong></p>
                                <p><strong>I. Introduction / II. Overview / III. Data collections and uses / IV. Choice and transparency / V. Terms for certain jurisdictions / VI. Updates to this policy</strong></p>
                                <p><strong>I. Introduction</strong></p>
                                <p>When you use PLAZACART.COM, you trust us with your personal data. We&rsquo;re committed to keeping that trust. That starts with helping you understand our privacy practices.</p>
                                <p>We recommend that you read this policy to learn more about the personal data we collect, how it&rsquo;s used and shared, and your choices regarding this data.</p>
                                <p>Use of PLAZACART.COM, websites, products or other services made available is at all times subject to our Terms of Use or Shopper Terms and Conditions, as applicable, each of which incorporates this Privacy Policy. Any terms we use in this Privacy Policy without defining them have the definitions given to them in the&nbsp;<a style={{ color: Color.THEME_COLOR }} href={RouteConstant.TermsOfService} target="_blank">Terms of Use</a>&nbsp;or&nbsp;<a style={{ color: Color.THEME_COLOR }} href={RouteConstant.TermsOfService} target="_blank">Shopper Terms and Conditions</a>, as applicable.</p>
                                <p>Last modified: <strong>January 10, 2023</strong></p>
                                <p>Effective date: <strong>January 11, 2023</strong></p>
                                <p><strong>II. Overview</strong></p>

                                <p><strong>A. Scope</strong></p>
                                <p>This policy describes how PLAZACART.COM and its affiliates collect and use personal data. This policy applies to all users of our apps, websites, features, or other services anywhere in the world.</p>
                                <p>Our practices are subject to applicable laws in the places in which we operate. This means that we engage in the practices described in this policy in a particular country or region only if permitted under the laws of those places.</p>
                                <p>This policy also governs PLAZACART.COM other collections of personal data in connection with its services. For example, we may collect the contact information of owners or employees of stores or other merchants.</p>
                                <p>All those subject to this policy are referred to as &ldquo;users&rdquo; in this policy.</p>

                                <p><strong>B. Data controller and transfer</strong></p>
                                <p>PLAZACART.COM Technologies, Inc. is the data controller for the personal data collected in connection with accessing or using the services.</p>
                                <p>Questions, comments, and complaints about PLAZACART.COM data practices can be submitted to our Data Protection Officer at <a href="mailto:admin@plazacart.com" style={{ color: Color.THEME_COLOR }}>admin@plazacart.com</a>.</p>

                                <p><strong>III. Personal data collections and uses</strong></p>

                                <p><strong>A. Sources of personal data</strong></p>
                                <p>The following personal data is collected by or on behalf of PLAZACART.COM:</p>
                                <ol>
                                    <li><strong> Data provided by users. This includes:</strong></li>
                                    <p>when users provide such information directly to us, and when personal data about users is automatically collected in connection with access to or use of the services, such as through our use of cookies when users use our Platform.</p>
                                    <li><strong> Data provided by our subsidiaries and affiliates</strong></li>
                                    <p>when they provide us with personal data about users. For example, when users purchase items through the Plazacart apps, the order may be processed through the Platform and Plazacart will provide certain personal data to us in order to complete the transaction. For more information about how we may share personal data with Plazacart, please see our&nbsp;<a
                                        style={{ color: Color.THEME_COLOR }}
                                        href="https://www.plazacart.com/legal/en/datasharing" target="_blank" >Data Sharing Policy</a>.</p>
                                    <li><strong> Data provided by third parties</strong></li>
                                    <p>when they provide us with personal data about users. Third Parties that share user personal data with us include service providers. For example, we may use analytics service providers to analyze how users interact and engage with the services, or third parties may help us provide users with customer support.</p>
                                    <li><strong> Data provided by networks connected to our Platform</strong></li>
                                    <p>when users provide your third-party account credentials to us or otherwise sign in to access or use the services through a third-party site or service (such as Facebook/Google), some content and/or information in those accounts may be transmitted into a user&rsquo;s account with us.</p>
                                </ol>

                                <p><strong>B. Categories of personal data</strong></p>
                                <p>The following categories of personal data are collected by or on behalf of PLAZACART.COM:</p>
                                <ul>
                                    <li>Personal identifiers, including name, email address, telephone number, IP address, delivery address/current location and Cookies. We collect personal identifiers from users, third parties and affiliates.</li>
                                    <li>Information protected against security breaches under state law, including name, email address, telephone number, IP address, delivery address/current location, Cookies and payment information (not including card number). We collect such information from users, third parties and affiliates.</li>
                                    <li>Commercial information, including purchase details and history. We collect commercial information from users.</li>
                                    <li>Internet or other similar network activity information, including Interactions with the Platform, Cookies, device data (such as device ID, manufacturer, operating system, language), IP address, and browsing, search, and purchase history.</li>
                                    <li>Geolocation data, including current location, which you can choose whether or not to share. We collect geolocation data from users.</li>
                                    <li>Sensory data, including customer service calls (voice recordings), which we collect from users.</li>
                                </ul>

                                <p><strong>C. How we use personal data</strong></p>
                                <p>We use personal data to operate, improve, understand and personalize the services, including:</p>
                                <ul>
                                    <li>To meet or fulfill the reason you provided the information to us.</li>
                                    <li>To communicate with you about the services, including service announcements, updates or offers.</li>
                                    <li>To provide support and assistance for the services.</li>
                                    <li>To create and manage your account.</li>
                                    <li>To personalize website content and communications based on your preferences, including targeted offers and ads served through the services.</li>
                                    <li>To process orders or other transactions, including payment transactions.</li>
                                    <li>To respond to user inquiries and fulfill user requests.</li>
                                    <li>To improve and develop the services and Platform, including testing, research, analysis and product development.</li>
                                </ul>
                                <ul>
                                    <li>To protect against or deter fraudulent, illegal or harmful actions and maintain the safety, security and integrity of the services.</li>
                                    <li>To comply with our legal or contractual obligations, resolve disputes, and enforce our Terms of Use.</li>
                                    <li>To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</li>
                                    <li>For any other business purpose stated when collecting your personal data or as otherwise set forth in applicable data privacy laws.</li>
                                </ul>

                                <p><strong>D. Cookies and third-party technologies</strong></p>
                                <p>PLAZACART.COM uses cookies and similar technologies such as pixel tags, web beacons, clear GIFs, and JavaScript (collectively, &ldquo;Cookies&rdquo;) to enable our servers to recognize your web browser and tell us how and when you visit and use the services, to analyze trends, learn about our user base and operate and improve the services. Cookies are small pieces of data&ndash; usually text files &ndash; placed on your computer, tablet, phone, or similar device when you use that device to visit the Platform. We may also supplement the information we collect from you with information received from third parties, including third parties that have placed their own Cookies on your device(s). Please note that because of our use of Cookies, the Platform does not support &ldquo;Do Not Track&rdquo; requests sent from a browser at this time.</p>
                                <p>We use the following types of Cookies:</p>
                                <ul>
                                    <li>Essential Cookies, which are required for providing you with features or services that you have requested. For example, certain Cookies enable you to log into secure areas of the Platform. Disabling these Cookies may make certain features and services unavailable.</li>
                                    <li>Functional Cookies, which are used to record your choices and settings regarding the services, maintain your preferences over time and recognize you when you return to the Platform. These Cookies help us to personalize our content for you, greet you by name, and remember your preferences (for example, your choice of language or region).</li>
                                    <li>Performance/Analytical Cookies, which allow us to understand how visitors use the services such as by collecting information about the number of visitors to the Platform, what pages visitors view on the Platform and how long visitors are viewing pages on the Platform. Performance/Analytical Cookies also help us measure the performance of our advertising campaigns in order to help us improve our campaigns and the services&rsquo; content for those who engage with our advertising.</li>
                                </ul>
                                <p>Google, Inc. (&ldquo;Google&rdquo;) uses cookies in connection with its Google Analytics services. Google&rsquo;s ability to use and share information collected by Google Analytics about your visits to the Platform is subject to the Google Analytics Terms of Use and the Google Privacy Policy. You have the option to opt-out of Google&rsquo;s use of cookies by visiting the Google advertising opt-out page&nbsp;<a href="https://policies.google.com/technologies/ads" style={{ color: Color.THEME_COLOR }} target="_blank">https://policies.google.com/technologies/ads</a>&nbsp;or the Google Analytics Opt- out Browser Add-on at&nbsp;<a style={{ color: Color.THEME_COLOR }} href="https://tools.google.com/dlpage/gaoptout/" target="_blank">https://tools.google.com/dlpage/gaoptout/</a></p>

                                <p>You can decide whether or not to accept Cookies through your internet browser&rsquo;s settings. Most browsers have an option for turning off the Cookie feature, which will prevent your browser from accepting new Cookies, as well as (depending on the sophistication of your browser software) allow you to decide on acceptance of each new Cookie in a variety of ways. You can also delete all Cookies that are already on your computer. If you do this, however, you may have to manually adjust some preferences every time you visit a site and some services and functionalities may not work.</p>
                                <p>To explore what Cookie settings are available to you, look in the &ldquo;preferences&rdquo; or &ldquo;options&rdquo; section of your browser&rsquo;s menu. To find out more information about Cookies, including information about how to manage and delete Cookies, please visit&nbsp;<a style={{ color: Color.THEME_COLOR }} href="https://www.allaboutcookies.org/" target="_blank">https://www.allaboutcookies.org/</a></p>
                                <p>For mobile users, you have controls in your device's operating system that enable you to choose whether to allow cookies, or share your advertising ID with companies like PLAZACART.COM&nbsp; or our advertising service providers. For information on controlling your mobile choices, you can visit&nbsp;<a style={{ color: Color.THEME_COLOR }} href="https://thenai.org/opt-out/mobile-opt-out/" target="_blank">https://thenai.org/opt-out/mobile-opt-out/</a></p>

                                <p><strong>E. Data sharing and disclosure</strong></p>
                                <p><strong>1. Purposes of data disclosure</strong></p>

                                <p>PLAZACART.COM may share personal data for the purposes of:</p>
                                <ul>
                                    <li>Auditing related to a current interaction and concurrent transactions.</li>
                                    <li>Detecting security incidents, protecting against malicious, deceptive, fraudulent, or illegal activity, and prosecuting those responsible for that activity.</li>
                                    <li>Debugging to identify and repair errors that impair existing intended functionality.</li>
                                    <li>Short-term, transient use of personal data that is not used by another party to build a consumer profile or otherwise alter your consumer experience outside the current interaction.</li>
                                    <li>Performing services on our behalf, a retailer store&rsquo;s behalf or an advertiser&rsquo;s behalf, including maintaining or servicing accounts, providing customer service, processing or fulfilling orders and transactions, verifying customer information, processing payments, providing financing, providing advertising or marketing services, providing analytic services, or providing similar services on behalf of the business or service provider.</li>
                                    <li>Undertaking internal research for technological development and demonstration.</li>
                                    <li>Undertaking activities to verify or maintain the quality or safety of a service or device that we own or control.</li>
                                    <li>Complying with any legal obligation or regulatory requirement.</li>
                                    <li>Enforcing our contractual terms with users and any other agreement.</li>
                                    <li>Protecting our rights or the rights of others, including to prevent fraud or other crimes.</li>
                                </ul>
                                <p><strong>2. Recipients of personal data</strong></p>
                                <p>PLAZACART.COM may share personal data with:</p>
                                <ul>
                                    <li>Service providers, including:
                                        <ul>
                                            <li>Payment processors</li>
                                            <li>Security and fraud prevention consultants</li>
                                            <li>Legal and other professional advisors</li>
                                            <li>Hosting and other technology and communications providers</li>
                                            <li>Analytics providers</li>
                                            <li>Contract personnel</li>
                                        </ul>
                                    </li>
                                    <li>Parties who acquire your personal data through an acquisition or other change of control. Personal data may be transferred to a third party if we undergo a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of PLAZACART.COM assets, whether as a going concern (such as through an acquisition), or as part of a bankruptcy, liquidation, or similar proceeding or transaction in which that third party assumes control of our business (in whole or in part).</li>
                                    <li>Other parties at your direction or with your consent, including:
                                        <ul>
                                            <li>Other users (where you post information publicly or as otherwise necessary to effect a transaction initiated or authorized by you through the services), which may include Shoppers and/or drivers.</li>
                                            <li>Social media and other third-party services (if you intentionally interact with them through your use of the services, such as by linking your PLAZACART.COM accounts).</li>
                                            <li>Third-party business partners who you access through the services, such as retail stores and advertisers.</li>
                                            <li>Other parties authorized by you.</li>
                                        </ul>
                                    </li>
                                    <li>Affiliates, including PLAZACART.COM parent company and subsidiaries, as well as other entities under common ownership or control with PLAZACART.COM . This may include entities owned or controlled by Plazacart, which may use the data for their own purposes, for example to display your order history and status in the Plazacart app, when you purchase items through the Plazacart app.</li>
                                    <li>Parties that require personal data to be disclosed for legal reasons (such as pursuant to subpoenas or other legal process).</li>
                                </ul>

                                <p><strong>F. Data retention</strong></p>
                                <p>We retain personal data about you for as long as you have an open account with us or as otherwise necessary to provide you services or fulfil the purposes for which we have collected the personal data, and afterwards for six (6) months to respond to any follow-up questions or concerns relating to your account. Thereafter, we may retain personal data for longer, if doing so is necessary to comply with our legal obligations, resolve disputes or collect fees owed, or is otherwise permitted or required by applicable law, rule or regulation. Afterwards, we may retain some information in a depersonalized or aggregated form but not in a way that would identify you personally.</p>

                                <p><strong>G. Account safety</strong></p>
                                <p>We recommend appropriately selecting and protecting your password and/or other sign-on mechanism, limiting access to your computer or device and browser, and signing off after you have finished accessing your account to help keep your account safe. Although we work to protect the privacy of your account and other personal data that we hold in our records, no method of transmitting data over the Internet or storing data is completely secure. We cannot guarantee the complete security of any personal data you share with us, and we are not responsible for the theft, destruction, loss or inadvertent disclosure of your information or content except as otherwise required under applicable law.</p>

                                <p><strong>IV. Choice and transparency</strong></p>
                                <p><strong>A. Device permissions</strong></p>
                                <p>Most mobile device platforms (IOS, Android, etc.) have defined certain types of device data that apps cannot access without the device owner&rsquo;s permission, and these platforms have different methods for how that permission can be obtained. Please check the available settings on your device or check with your provider.</p>
                                <p><strong>B. Marketing and notification choices</strong></p>
                                <p>We may communicate with you if you&rsquo;ve provided us the means and any legally required consent to do so. For example, if you&rsquo;ve given us your email address, we may send you promotional email offers or email you about your use of the services. If you want to revoke your consent, or do not want to receive communications from us, please indicate your preference by changing your account settings at&nbsp;<a href="https://plazacart.com/preferences/notifications" style={{ color: Color.THEME_COLOR }} target="_blank">https://plazacart.com/preferences/notifications</a>. You may also opt out of receiving emails and other messages from PLAZACART.COM by following the unsubscribe instructions in those messages. Please note that if you decline to receive essential account communications (such as receipts), you may no longer be able to use some or all features of the services.</p>
                                <p><strong>C. User personal data requests</strong></p>
                                <p>Users may submit a personal data request using the following methods:</p>
                                <ul>
                                    <li>Submit a&nbsp;<a style={{ color: Color.THEME_COLOR }} href="https://plazacart.com/delete-my-data" target="_blank">request to delete your data</a></li>
                                    <li>Submit a&nbsp;<a style={{ color: Color.THEME_COLOR }} href="https://plazacart.com/know-my-data" target="_blank">request to know your data</a></li>
                                </ul>
                                <p>You may also authorize an agent to exercise your rights on your behalf. To do this, you must provide your agent with written permission to exercise your rights on your behalf, and we may request a copy of this written permission from you or your authorized agent when they make a request on your behalf.</p>

                                <p><strong>V. Terms for certain jurisdictions</strong></p>
                                <p><strong>B. For California users</strong></p>
                                <p>California residents may request that PLAZACART.COM:</p>
                                <ul>
                                    <li>Disclose the sources, categories, and specific pieces of personal information we have collected about them, how that information is used including the purpose, and with whom PLAZACART.COM shares it</li>
                                    <li>Delete their personal information</li>
                                    <li>Disclose, for any &ldquo;sales&rdquo; of personal information under the CCPA, the categories of personal information collected and sold and to what categories of third parties it was sold</li>
                                    <li>Opt them out of sales of their personal information (if any)</li>
                                    <li>Provide a copy of their personal information in a readily usable format that allows the information to be transmitted to others</li>
                                    <li>California residents may not be discriminated against for exercising any of the rights described above.</li>
                                </ul>
                                <p>California residents may exercise their rights through the links below:</p>
                                <ul>
                                    <li>Request&nbsp;<a style={{ color: Color.THEME_COLOR }} href="https://plazacart.com/delete-my-data" target="_blank">deletion of your account</a></li>
                                    <li>Request an&nbsp;<a style={{ color: Color.THEME_COLOR }} href="https://plazacart.com/know-my-data" target="_blank">export of your data</a></li>
                                    <li><a style={{ color: Color.THEME_COLOR }} href="https://plazacart.com/dont-sell-data" target="_blank">Opt out of sale of your personal information</a>. PLAZACART.COM does not sell your data &mdash; we are not data brokers, and we don&rsquo;t put your data on the open market. However, under the CCPA, some sharing of personal information necessary to provide you with personalized ads may be considered a &ldquo;sale,&rdquo; even if no money is exchanged. You may opt-out of such sharing using the link above. If you opt out, PLAZACART.COM will stop sharing your information with some of its advertising partners, and you will no longer receive personalized offers from PLAZACART.COM on some sites or services.</li>
                                </ul>
                                <p><strong>VI. Updates to this policy</strong></p>
                                <p>We may occasionally update this policy. If we make significant changes, we will notify users in advance of the changes through the PLAZACART.COM app or through other means, such as email. We encourage users to periodically review this policy for the latest information on our privacy practices.</p>
                                <p>Use of our services after an update constitutes consent to the updated policy to the extent permitted by law.</p>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                    </div>
                </section>
                {(mode != POLICY_IN_VISIBLE_MODE.PRIVATE_MODE)
                    &&
                    <div>

                    </div>
                }
            </Fragment>
        );
    }
}