import React, { Component } from 'react';

import Constants, { DatetimeFormate } from '../Config/Constants'
import { RetriveDataFromStorage, StoreObjectInStorage, RemoveDataInStorage, RetriveObjectFromStorage, parseTimetoHour } from "../Config/Utils";
import Messages from "../Config/Messages";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import Header from '../Header/Header';
import Color from '../Config/Color';
import RouteConstant from '../Config/RouteConstant';
import plazablankIcon from "../../images/icons/plazablank.png";

import MasterContextCreater from '../MasterContextProvider/MasterContextCreater'
import { PostData } from '../../services/PostData';
import { GetData } from "../../services/GetData";
import { GET_TENANT_API, GET_USERPROFILE_API, APPOIMENT_APi } from '../Config/Config.json'
import Moment from "moment"
import LoadingOverlay from 'react-loading-overlay';
import './Appointment.css'
import Calendar from './Calendar';
import { arrayFromHours } from '../UtillCustomComponent/UtillCustomComponent'

const xObj = {
    "_id": "637ca4f3772e72a41ffbbcb7",
    "storeid": "62e3e0ebf4502b075c12e10d",
    "isopen": true,
    "daterangetype": "DATERANGE",
    "nextcalendardays": 31,
    "isdefinitely": false,
    "fromdate": "2022-11-22T10:31:15.564Z",
    "tomdate": "2022-12-22T10:31:15.564Z",
    "duration": "39",
    "workinghourtype": "EXITINGSCHEDULE",
    "weekdays": [
        {
            "weekday": "MONDAY",
            "starttime": "1AM",
            "endtime": "11PM",
            "isopen": true
        }
    ],
    "customdays": null,
    "CreatedDate": "2022-11-22T10:31:15.564Z",
    "ModifiedDate": "2022-11-22T10:38:03.452Z"
}

class Appointment extends Component {
    static contextType = MasterContextCreater
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            daterangeType: "",
            appointmentObj: null,

            storeObj: null,
            selectedDate: "",

            timeSplitArray: [],
            selectedStartTime: null,
            endtime: "",

            pageindex: 0,
            userFields: { customernotes: "" },

            errors: {},
            userId: ""
        }

    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        console.log("authToken", authToken);
        var objStore = RetriveObjectFromStorage(Constants.Obj_STORE, '');

        if (objStore) {
            this.setState({ storeObj: objStore })
            this.getUserDetail(authToken)
            this.getStoreAppointment(authToken, objStore.ObjectId);
        }
    }

    getStoreAppointment(authToken, id) {
        this.setState({ isActive: true });
        var API = APPOIMENT_APi + "/" + id;
        GetData(API, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        this.setState({
                            appointmentObj: responseData,
                            daterangeType: responseData.daterangetype
                        })
                    }
                }
                else {
                    let errors = {};
                    errors['ErrorEmail'] = responseJson["Message"];
                    this.setState({ errors: errors, isActive: false });
                }
                this.setState({ isActive: false });
            }).catch(error => {
                let errors = {};
                errors['ErrorEmail'] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    getUserDetail(authToken) {
        this.setState({ isLoadingActive: true });
        var API = GET_USERPROFILE_API;
        GetData(API, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    this.setState({ userId: responseData ? responseData._id : "" })
                }
                else {
                    let errors = {};
                    errors['ErrorEmail'] = responseJson["Message"];
                    this.setState({ errors: errors, isActive: false });
                }
            }).catch(error => {
                let errors = {};
                errors['ErrorEmail'] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }


    handleDateselect = (dpass, day, tobj) => {
        this.setState({ selectedDate: dpass })
        if (tobj) {
            let starthour = parseTimetoHour(tobj.starttime), endhour = parseTimetoHour(tobj.endtime);
            if (this.state.appointmentObj && this.state.appointmentObj.StoreCurrentTime) {
                if (dpass === Moment(this.state.appointmentObj.StoreCurrentTime).format(DatetimeFormate.YYYYMMDDdash)) {
                    var x = Moment(this.state.appointmentObj.StoreCurrentTime).hours();
                    if (!isNaN(x)) {
                        starthour = x;
                    }
                }
            }
            console.log(arrayFromHours(starthour, endhour));
            if (tobj.starttime != 39 && tobj.endtime != 39) {
                this.setState({
                    timeSplitArray: arrayFromHours(starthour, endhour),
                    selectedStartTime: null,
                    endtime: ""
                })
            }
            else {
                this.setState({ timeSplitArray: [], selectedStartTime: null, endtime: "" })
            }
        }
    }

    handleselectTime(para) {
        this.setState({ selectedStartTime: para })
    }

    handleConfirmTime(e) {
        e.stopPropagation();
        const { selectedStartTime, appointmentObj } = this.state
        if (selectedStartTime) {
            let time = Moment(selectedStartTime.label, DatetimeFormate.T_HHmmA)
            time.add(Number(appointmentObj.duration), DatetimeFormate.T_m)
            let etime = Moment(time).format(DatetimeFormate.T_hhmmA)
            this.setState({ endtime: etime })
        }
        this.setState({ pageindex: 1 })
    }

    handleback(page) {
        this.setState({ pageindex: page })
    }

    handleChange = (event) => {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        userFields[key] = value
        this.setState({ userFields })
    }

    handeAppointment = (e) => {
        var postData = {}
        const { selectedDate, storeObj, selectedStartTime, endtime, userFields, userId } = this.state;
        if (selectedDate && storeObj && selectedStartTime && endtime && userId) {
            postData["customerid"] = userId
            postData["storeid"] = storeObj.ObjectId
            postData["date"] = selectedDate
            postData["starttime"] = selectedStartTime.label
            postData["endtime"] = endtime
            postData["customernotes"] = userFields.customernotes

            console.log("====== postdata=====", postData)

            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            this.postStoreAppointment(authToken, postData)
        }
    }

    postStoreAppointment(authToken, postData) {
        this.setState({ isActive: true });
        var API = APPOIMENT_APi;
        PostData(API, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    this.setState({ pageindex: 2 })
                    let { reffAppointment } = this.context
                    if (typeof reffAppointment == "function") {
                        reffAppointment()
                    }
                }
                else {
                    let errors = {};
                    errors['ErrorEmail'] = responseJson["Message"];
                    this.setState({ errors: errors, isActive: false });
                }
                this.setState({ isActive: false });
            }).catch(error => {
                let errors = {};
                errors['ErrorEmail'] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    handleReset() {
        this.setState({
            selectedDate: "",
            timeSplitArray: [],
            selectedStartTime: null,
            endtime: "",
            pageindex: 0
        })
    }

    render() {
        const { plazaList, hideEmptyPlazaView, storeObj, appointmentObj, timeSplitArray, selectedStartTime,
            pageindex, endtime, selectedDate } = this.state;

        return (

            <div style={{ backgroundColor: Color.WHITE }}>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Constants.LoadingMssg}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="">
                    <Header setHeaderTitle={"Appointment"} />
                    <section className="section" style={{ backgroundColor: Color.WHITE }}>
                        <div className="container appnmnt-container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                            <div className="reg_form_main">

                                <div className="container mb-5" style={{ paddingLeft: "2px", paddingRight: "2px", display: this.state.hideEmptyPlazaView }} >
                                    {storeObj && pageindex != 2 && <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title">{storeObj.ApplicationName} {appointmentObj && appointmentObj.timezoneshortdescription && <span style={{ color: Color.BLACK }}> {"(" + appointmentObj.timezoneshortdescription + ")"}</span>}</h5>
                                            {appointmentObj && <p class="card-text store-info">
                                                <i class="fas fa-clock store-icon"></i>
                                                &nbsp;
                                                {appointmentObj ? appointmentObj.duration + "  min" : ""}</p>}
                                            <p class="card-text store-info">
                                                <i class="fas fa-map-marker-alt store-icon mt-2"></i>
                                                &nbsp;{storeObj.AppAddress}
                                            </p>
                                            {pageindex != 0 && <p class="card-text store-info">
                                                <i class="fas fa-calendar store-icon mt-2"></i>
                                                &nbsp;{`${selectedStartTime ? selectedStartTime.label : ""} - ${endtime}, ${Moment(selectedDate).format(DatetimeFormate.ddN_DD_MN_Y)}`}
                                            </p>}
                                        </div>
                                    </div>
                                    }
                                    {pageindex == 0 && <div class="row m-0 mt-4">
                                        <Calendar
                                            daterangeType={this.state.daterangeType}
                                            appointmentobj={this.state.appointmentObj}
                                            selectedDate={this.state.selectedDate}
                                            handleSelectDate={this.handleDateselect}
                                        />
                                        {timeSplitArray.length != 0 && <div class="col-lg-6 col-md-4 mt-4 mt-md-0 p-0">
                                            <div class="card">
                                                <h6 class="card-header">{selectedDate ? Moment(selectedDate).format(DatetimeFormate.ddN_DD_MN_Y) : ""}</h6>
                                                <ul class="list-group" style={{ maxHeight: "360px", overflow: "auto", border: "none" }}>
                                                    {timeSplitArray.map((item, indexx) => {
                                                        return <li class="list-group-item mt-2 d-flex align-items-center justify-content-between pr-0 setaTag" onClick={() => this.handleselectTime(item)}>
                                                            <span class={(selectedStartTime && selectedStartTime.label == item.label) ? "select-t" : ""}>{item.label}</span>
                                                            {selectedStartTime && selectedStartTime.label == item.label &&
                                                                <label
                                                                    style={{ float: "center", borderRadius: "4px" }}
                                                                    onClick={(e) => this.handleConfirmTime(e)}
                                                                    className="btn btn-done setaTag"
                                                                > Confirm</label>
                                                            }
                                                        </li>
                                                    })}
                                                </ul>
                                            </div>
                                        </div>}
                                    </div>}

                                    {pageindex == 1 && <div class="row m-0 mt-4">
                                        <div class="row m-0 w-100 ml-3 setaTag">
                                            <div class="col-1 p-3 back-btn-lf" onClick={() => this.handleback(0)}>
                                                <i class="fas fa-arrow-left store-icon"></i>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
                                            <label className="row m-0 textcolor">
                                                Please share anything that will help prepare for our meeting. (optional)
                                            </label>
                                            <textarea
                                                className="row m-0 textarea-s"
                                                type="text"
                                                // placeholder="Mobile Number"
                                                name="customernotes"
                                                value={this.state.userFields.customernotes}
                                                onChange={this.handleChange}
                                            ></textarea>
                                        </div>
                                        <div className="row w-100 m-0 mt-4 justify-content-center">
                                            <label
                                                style={{ backgroundColor: Color.THEME_COLOR, borderRadius: "4px" }}
                                                onClick={this.handeAppointment}
                                                className="col-lg-3 col-md-4 col-sm-6 btn btn-done m-0 setaTag"
                                            > Confirm</label>
                                        </div>
                                    </div>}

                                    {storeObj && pageindex == 2 && <div class="card mt-5">
                                        <div class="card-header text-center">
                                            PENDING
                                        </div>
                                        <div class="card-body">
                                            <div class="row m-0">
                                                <div class="col-lg-8 offset-lg-2 col-md-8 offset-md-2">
                                                    <h6 class="card-title text-center">{Messages.appointmentm1} {storeObj.ApplicationName} {Messages.appointmentm2} </h6>
                                                    <h6 class="card-title text-center">{Messages.appointmentm3}</h6>
                                                    <p class="card-text store-info">
                                                        <i class="fas fa-clock store-icon"></i>
                                                        &nbsp;
                                                        {appointmentObj ? appointmentObj.duration + "  min" : ""}</p>
                                                    <p class="card-text store-info">
                                                        <i class="fas fa-map-marker-alt store-icon mt-2"></i>
                                                        &nbsp;{storeObj.AppAddress}
                                                    </p>
                                                    {pageindex != 0 && <p class="card-text store-info">
                                                        <i class="fas fa-calendar store-icon mt-2"></i>
                                                        &nbsp;{`${selectedStartTime ? selectedStartTime.label : ""} - ${endtime}, ${Moment(selectedDate).format(DatetimeFormate.ddN_DD_MN_Y)}`}
                                                    </p>}
                                                    <p class="card-text store-info text-center mt-5">
                                                        <a tabIndex="-1" class="select-t" onClick={() => this.handleReset()}>Take a new appointment</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}


                                </div>

                            </div>
                            <div className="reg_form_main offset-sm-3 col-sm-6">
                                <div className="col-sm-12 errorMsg mb-5">
                                    <center>{this.state.errors.ErrorEmail}</center>
                                </div>
                            </div>
                        </div>
                        {/* </LoadingOverlay> */}
                    </section>
                </LoadingOverlay>
            </div>

        );
    }
}

export default Appointment;