import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from "react";
import StripeStandardCheckoutForm from './StripeStandardCheckoutForm';
import "./StripeStandardPay.css";

function StripeStandardPay({ publickey, clientsecrate, successPayment, billingAddress, UserProfile, phoneNumber, stripeaccountid, stripepaymentprogressshow, stripepaymentprogresshide }) {

    /* console.log(publickey, "-", clientsecrate, "-", stripeaccountid); */

    const stripePromise = loadStripe(publickey, {
        "stripeAccount": stripeaccountid
    });

    const appearance = {
        theme: 'stripe',
    };

    const options = {
        clientSecret: clientsecrate,
        appearance: appearance
    };

    return (
        <Elements
            stripe={stripePromise}
            options={options}>
            <StripeStandardCheckoutForm
                successPayment={successPayment}
                billingAddress={billingAddress}
                UserProfile={UserProfile}
                phoneNumber={phoneNumber} />
        </Elements>
    )
}

export default StripeStandardPay;