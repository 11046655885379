import React, { Component } from 'react';
import { COMPANYCONST } from '../../Config/Constants';
import HeaderOrderWeb from '../Header/HeaderOrderWeb';

export default class AboutUsOrderWeb extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {

    }

    render() {
        return (
            <div>
                <div class="hero_area">
                    <HeaderOrderWeb setHeaderTitle={"About " + COMPANYCONST.PLAZACART} />
                </div>
                <section className="section mb-5">
                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }} >
                        <div className="reg_form_main">
                            {/* Order Web Change */}
                            {/* <center><p id="instruction" className="textcolor" style={{ marginBottom: '10px' }}> About {COMPANYCONST.PLAZACART}</p> </center> */}
                        </div>
                        <div class="midsection pl-2 pr-2 pl-sm-0 pr-sm-0 pages" style={{ textAlign: 'justify' }}>
                            <div class="Justify_Font_11">
                                <p><strong>What is Plazacart?</strong><br />Plazacart&trade; provides web &amp; mobile technology solutions to <strong>retail</strong>, <strong>restaurants</strong> and <strong>businesses</strong>. Our delighted customers come in all sizes -- independent <strong>businesses</strong>, <strong>restaurants</strong>, and <strong>retail</strong>. <br />We are a team of enthusiasts who value innovation and creativity. At the heart of everything we do are our company values. We stand by them being the set of behaviours that we expect all of our people to embrace to further our ambitions for our customers. We are passionately dedicated to supporting our clients' success through our key value propositions:</p>
                                <p><strong>Our Mission</strong></p>

                                {/*  <p>&bull; Help &amp; Empower local business compete better <br />&bull; Help &amp; Nurture consumers with their purchasing habits<br />&bull; Provide superior customer experience &amp; help them save more on purchases and habits<br />&bull; Provide opportunities for merchants to keep better margins &amp; help save lost revenue<br />&bull; Support all local businesses<br />&bull; Shift Delivery App paradigm<br />&bull; Solve problems that exists in the Delivery App space<strong><br /></strong></p> */}

                                <p><strong>For Ourselves</strong></p>
                                <p><strong>Start with Greatness</strong>:<br />Being great at creating unparalleled shopping experience is a starting point, not an endpoint. We set high goals because we know that by stretching to meet them we can continue to offer superior innovation and value to our customers. We are restless, always learning, always improving, and in doing so, we continue to wow our customers.</p>
                                <p><strong>Passionately Listen</strong><br />Our curiosity and customer insights drive growth. We care for our customers. We're courageous in pursuing their full potential. We're innovative, constantly searching for new ideas that drive growth. That doesn't just mean more innovative features based on our expert market research, but also listening and working with our customers to launch new ideas that deliver tangible results.</p>
                                <p><br /><strong>For the World</strong></p>
                                <p><strong>Move the Delivery App Space Forward</strong><br />We will solve fundamental problems that exist within the delivery space, including access to diverse and local providers, providing affordable delivery services, and maintaining a sustainable model for years to come.</p>
                                <p><strong>Support &amp; empower local businesses around the globe</strong><br />With every new customer and order placed using the Plazacart app, local businesses are empowered as their user base expands and they are able to build a community of fans that love and support their mission.</p>
                                <p><strong>Support their community</strong><br />By providing a wide variety of local and artisanal products, we will help establish a richer, deeper connection between consumers and their community storefronts. Businesses will be able to share their story with their community, and customers will be able to discover an even greater selection of businesses to serve their needs.</p>
                                <p><strong>Provide a superior customer experience</strong><br />Our functionality and interface will be designed to provide the most seamless and intuitive commerce experience possible, enabling customers to take full advantage of delivery and save precious time throughout their day and week. The Plazacart experience will keep simplicity and speed in mind, so that consumers can focus on fulfilling their wants and needs.</p>

                                {/* old about us content */}
                                {/* <p>Plazacart&trade; provides web &amp; mobile technology solutions to&nbsp;<strong className="textcolor">retail</strong>,&nbsp;<strong className="textcolor" >restaurants</strong>&nbsp;and<strong className="textcolor" >&nbsp;businesses</strong>. Our delighted customers come in all sizes -- independent businesses, restaurants, and retail.</p>

                                <p>We are a team of enthusiasts who value innovation and creativity. At the heart of everything we do are our company values. We stand by them being the set of behaviours that we expect all of our people to embrace to further our ambitions for our customers. We are passionately dedicated to supporting our clients' success through our five key value propositions:</p>
                                
                                <p><strong className="textcolor">Enhanced User Experience:</strong>&nbsp;We are continuously striving to offer the best user experience possible We take great care to ensure that a captivating and optimal user experience exceeds your expectations to help save more on purchases and habits, ultimately serving you.</p>
                               
                                <p><strong className="textcolor">Save Valuable Time:</strong>&nbsp;We know your time is valuable, so we've made it super easy for you to shop. We keep simplicity and speed in mind with your shopping experience so that you can focus on what you do best.</p>
                                
                                <p><strong className="textcolor">Create Superior Value:</strong>&nbsp;In addition to your valuable time, Plazacart is completely free for you to use and support your local area businesses. With every order you place using Plazacart app/platform you empower your local area businesses to compete against high delivery cost and to keep better margins &amp; help save lost revenue.</p>
                               
                                <p><strong className="textcolor">Passionately Listen:</strong>&nbsp;Our curiosity and customer insights drive growth. We care for our customers. We're courageous in pursuing their full potential. We're innovative, constantly searching for new ideas that drive growth. That doesn't just mean more innovative features based on our expert market research, but also listening and working with our customers to launch new ideas that deliver tangible results.</p>
                                
                                <p><strong className="textcolor">Start with Greatness:</strong>&nbsp;Being great at creating unparalleled shopping experience is a starting point, not an endpoint. We set high goals because we know that by stretching to meet them we can continue to offer superior innovation and value to our customers. We are restless, always learning, always improving, and in doing so, we continue to wow our customers.</p> */}

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}