import React, { Component } from "react";
import { GetData } from "../../../services/GetData";
import { PostData } from "../../../services/PostData";
import "../../../styles/style.css";
import './RegisterOrderWeb.css';
//import 'react-flags-select/css/react-flags-select.css';
import { GET_COUNTRY_API, GOOGLE_CLIENT_ID, GOOGLE_PLACE_KEY, USER_ADDRESS, USER_REGISTRATION_API, VALIDATE_OTP_API } from '../../Config/Config.json';
import Messages from "../../Config/Messages";
import { StoreDataInStorage, checkPostalCode, formatPhoneNumber, removeAllSpace, validateEmail, validateOtpNo, validatePassword, validatePhoneNumber, validateUserName } from "../../Config/Utils";

//import Select from 'react-select'; //https://www.npmjs.com/package/react-select
import Constants, { DEFAULTID, UserSessionTypes } from '../../Config/Constants';
//import FacebookLogin from 'react-facebook-login';
import { GOOGLEPLACEAPIMAP, LandingPageConst } from '../../Config/constvariable';

import email from "../../../images/icons/email.png";
import Color from '../../Config/Color.js';


import ReactFlagsSelect from 'react-flags-select';
import Checkbox from 'react-simple-checkbox';

import 'react-data-table-component-extensions/dist/index.css';
import {
    browserName, fullBrowserVersion, osName,
    osVersion
} from "react-device-detect";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import LoadingOverlay from 'react-loading-overlay';
import NoPriview from '../../../../src/images/nopreviewp.png';
import { LandingPagePH, RegisterPH } from '../../Config/Placeholder';
import RouteConstant from "../../Config/RouteConstant";
import { RegisterConst } from '../../Config/constvariable';
import LoginWithGoogle from "../../LoginWithGoogleFiles/LoginWithGoogle";
import { ACaptcha, GETWORD } from '../../UtillCustomComponent/CaptchaGenerator';
import { PutMandatoryfieldSymbol } from "../../UtillCustomComponent/UtillCustomComponent";
const publicIp = require('public-ip');

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#f0f0f0', border: "none", borderRadius: "50px", height: "50px", marginBottom: "15px" }),
    placeholder: styles => ({ ...styles, fontSize: "16px", fontWeight: "450 !important", marginLeft: "8px" }),
    option: styles => ({ ...styles, color: '#333' }),
    menuPortal: styles => ({ ...styles, zIndex: "99" })
};

class RegisterOrderWeb extends Component {
    constructor(props) {
        super(props);
        this.state = {

            userFields: {},
            errors: {},

            selectedCountry: "",
            countryfields: {},
            countryDataList: [],

            chkBoxChecked: false,

            otp: {},
            page: 1,
            isGoogleFacebook: false,
            isActive: false,

            selectedAddress: null,
            selectfulladdressdetail: null,
            ipAddress: '',
            browserLanguage: '',
            isPassword: true,
            isConfirmPassword: true,

            /* Google Captcha Code */
            /* googleReCaptch: null,
            iscaptchreload: true, */

            /* Native Captcha Code */
            captchWord: "",
            iscaptchwordMatch: false,

            issignupwithgooogle: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleValidationOnBlur = this.handleValidationOnBlur.bind(this);
        this.onCountrySelection = this.onCountrySelection.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.handleSubmitPhoneNo = this.handleSubmitPhoneNo.bind(this);
        this.handleValidateOtpSubmit = this.handleValidateOtpSubmit.bind(this);
        this.handleValidateOtpChange = this.handleValidateOtpChange.bind(this);
        this.handleValidationOTPonBlur = this.handleValidationOTPonBlur.bind(this);
    }

    componentDidMount() {
        /* Country List */
        this.getCountryAPI();

        /* For Ip Address */
        (async () => {
            var ip = await publicIp.v4();
            this.setState({ ipAddress: ip });
            console.log('ip address', ip);
        })();

        /* For Current Location */
        if ("geolocation" in navigator) {
            console.log("Geolocation Available");
            this.getLocation();
        } else {
            console.log("Geolocation Not Available");
            console.error("Error Code = " + error.code + " - " + error.message);
        }

        /* For Detect browser details */
        const detectBrowserLanguage = require('detect-browser-language')
        this.setState({ browserLanguage: detectBrowserLanguage() });
        console.log('detect browser language', detectBrowserLanguage());

        /* Native Captcha Code */
        this.setState({ captchWord: GETWORD(6) });
    }

    /* For Get Curent Location */
    getLocation = () => {
        navigator.geolocation.getCurrentPosition((position) => {
            console.log('Location', 'lat' + position.coords.latitude + "Lon" + position.coords.longitude);
            this.setState({ latitude: position.coords.latitude });
            this.setState({ longitude: position.coords.longitude });
        });
    };

    getCountryAPI() {
        GetData(GET_COUNTRY_API)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("country list ", responseData);
                    var countryCode = [];
                    for (var i = 0; i < responseData.length; i++) {
                        var obj = responseData[i].CountryCode;
                        countryCode.push(obj);
                    }
                    this.setState({ countryDataList: countryCode });
                    console.log("countryDataList ", this.state.countryDataList);
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong);
                }
            });
    }

    onCountrySelection(countryCode) {
        console.log(countryCode)
        let countryfields = this.state.countryfields;
        if (countryfields["CountryCode"] != countryCode) {
            countryfields["PostalCode"] = ""
            this.setState({ selectedAddress: null });
        }
        countryfields["CountryCode"] = countryCode;
        this.setState({ countryfields });
        this.setState({ selectedCountry: countryCode });
        this.setState({ errors: {} });
    }

    handelAddress(addressObj) {
        this.setState({ selectedAddress: addressObj })
        console.log(addressObj)
        let countryfields = this.state.countryfields;
        if (addressObj && addressObj.value && addressObj.value.place_id) {
            this.getaddressGooglePlace(addressObj.value.place_id, countryfields)
        }
        else {
            countryfields["PostalCode"] = "";
        }
        this.setState({ countryfields, selectfulladdressdetail: null })
    }

    getaddressGooglePlace(value, refObj) {
        this.setState({ isActive: true })
        var placeId = value
        console.log(placeId)
        geocodeByPlaceId(placeId)
            .then(result => {
                var responseJson = result ? result[0] : null;
                console.log("====", result)
                if (responseJson) {
                    var responseData = responseJson;
                    this.setState({ selectfulladdressdetail: responseData ? responseData : null })
                    if (responseData && responseData.address_components) {
                        refObj["PostalCode"] = ""
                        for (var obj of responseData.address_components) {
                            if (obj.types && checkPostalCode(obj.types)) {
                                refObj["PostalCode"] = removeAllSpace(obj.short_name)
                                break;
                            }
                        }
                    }
                    else {
                        refObj["PostalCode"] = ""
                    }
                }
                else {
                    refObj["PostalCode"] = ""

                }
                this.setState({ refObj, isActive: false })
            }).catch(err => {
                console.log(err)
                refObj["PostalCode"] = ""
                this.setState({ refObj, isActive: false })
            });
    }

    handleCountryChange(event) {
        let countryfields = this.state.countryfields;
        let key = event.target.name;
        let value = event.target.value;
        countryfields[key] = value;
        this.setState({ countryfields });
    }

    /***************************************************************************************** */
    // START --> FUNCTIONS FOR EMAIL, GOOGLE, FACEBOOK MODULE
    /***************************************************************************************** */

    reloadCaptch() {

        /* Google Captcha Code */
        /* this.setState({ iscaptchreload: false, googleReCaptch: null });
        setTimeout(() => {
            this.setState({ iscaptchreload: true });
        }, 900) */

        /* Native Captcha Code */
        this.setState({ captchWord: GETWORD(6) });
    }

    /***************************************************************************************** */
    // END --> FUNCTIONS FOR EMAIL, GOOGLE, FACEBOOK MODULE
    /***************************************************************************************** */


    navigatepageform() {
        this.setState({ page: 2, issignupwithgooogle: false });
    }


    /***************************************************************************************** */
    // START --> FUNCTIONS FOR REGISTRATION MODULE
    /***************************************************************************************** */

    handleChange(event) {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        if (key == "PhoneNumber") {
            userFields[key] = formatPhoneNumber(value)
        }
        else {
            userFields[key] = value
        }

        /* Native Captcha Code */
        if (key === "captchacode") {
            if (value.length === 6) {
                if (value != this.state.captchWord) {
                    let errors = this.state.errors;
                    errors['captchacode'] = Messages.v_EnterValidCaptcha;
                    this.setState({ errors: errors, iscaptchwordMatch: false });
                } else {
                    this.setState({ iscaptchwordMatch: true, errors: {} });
                }
            } else {
                if (value.length === 0 || value.length === 1) {
                    this.setState({ errors: {} });
                }
                this.setState({ iscaptchwordMatch: false });
            }
        }

        this.setState({ userFields });
        // if (key === 'Name' && value[0] === ' ') value = value.trim()
    }

    /* Google Captcha Code */
    /* handleGoogleRecaptcha = vl => {
        this.setState({ googleReCaptch: vl })
    } */

    handleValidationOnBlur(e) {
        let fields = this.state.userFields;
        let errors = this.state.errors;
        let formIsValid = true;
        let name = e.currentTarget.name;
        let countryfields = this.state.countryfields;

        if (name == "CountryCode") {
            if (!countryfields["CountryCode"]) {
                formIsValid = false;
                errors["CountryCode"] = Messages.v_EnterCountryName;
            }
        }

        if (!this.state.selectedAddress) {
            formIsValid = false;
            errors["Address"] = Messages.v_Address;
        }

        if (name == "PostalCode") {
            if (!countryfields["PostalCode"]) {
                formIsValid = false;
                errors["PostalCode"] = Messages.v_EnterPostalCode;
            }
        }

        if (name == "Email") {
            if (!fields["Email"]) {
                formIsValid = false;
                errors["Email"] = Messages.v_EnterEmail;
            }
            else if (typeof fields["Email"] !== "undefined") {
                if (!validateEmail(fields["Email"])) {
                    formIsValid = false;
                    errors["Email"] = Messages.v_EnterValidEmail;
                }
            }
        }

        if (name == "FirstName") {
            if (!fields["FirstName"]) {
                formIsValid = false;
                errors["FirstName"] = Messages.v_EnterName;
            }
            else if (typeof fields["FirstName"] !== "undefined") {
                if (!validateUserName(fields["FirstName"])) {
                    formIsValid = false;
                    errors["FirstName"] = Messages.v_EnterValidName;
                }
            }
        }

        if (name == "LastName") {
            if (!fields["LastName"]) {
                formIsValid = false;
                errors["LastName"] = Messages.v_EnterLastName;
            }
        }

        if (name == "Password") {
            if (!fields["Password"]) {
                formIsValid = false;
                errors["Password"] = Messages.v_EnterPassword
            }
            else if ((typeof fields["Password"] !== "undefined") && !this.state.isGoogleFacebook) {
                if (!validatePassword(fields["Password"])) {
                    formIsValid = false;
                    errors["Password"] = Messages.v_EnterValidPassword;
                }
            }
        }

        if (name == "ConfirmPassword") {
            if (!fields["ConfirmPassword"]) {
                formIsValid = false;
                errors["ConfirmPassword"] = Messages.v_EnterConfirmPassword;
            }
            else if ((typeof fields["ConfirmPassword"] !== "undefined") && !this.state.isGoogleFacebook) {
                if (!validatePassword(fields["ConfirmPassword"])) {
                    formIsValid = false;
                    errors["ConfirmPassword"] = Messages.v_EnterValidPassword;
                }
            }
        }

        if (name == "PhoneNumber") {
            if (!fields["PhoneNumber"]) {
                formIsValid = false;
                errors["PhoneNumber"] = Messages.v_EnterPhoneNo
            }
            else if (typeof fields["PhoneNumber"] !== "undefined") {
                if (!validatePhoneNumber(fields["PhoneNumber"])) {
                    formIsValid = false;
                    errors["PhoneNumber"] = Messages.v_EnterMoNoFormate;
                }
            }
        }

        /* Native Captcha Code */
        if (name === "captchacode") {
            if (!fields['captchacode']) {
                formIsValid = false;
                errors["captchacode"] = Messages.v_EnterCaptcha;
            }
            else if (typeof fields["captchacode"] !== "undefined") {
                if (fields["captchacode"] != this.state.captchWord) {
                    formIsValid = false;
                    errors["captchacode"] = Messages.v_EnterValidCaptcha;
                }
            }
        }

        if (formIsValid) {
            errors[name] = "";
        }

        this.setState({
            userFields: fields,
            errors: errors,
            countryfields: countryfields
        });
    }

    validateForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;
        let countryfields = this.state.countryfields;

        if (!countryfields["CountryCode"]) {
            formIsValid = false;
            errors["CountryCode"] = Messages.v_EnterCountryName;
        }

        if (!this.state.selectedAddress) {
            formIsValid = false;
            errors["Address"] = Messages.v_Address;
        }

        if (!countryfields["PostalCode"]) {
            formIsValid = false;
            errors["PostalCode"] = Messages.v_EnterPostalCode;
        }

        /* Google Captcha Code */
        /* const { googleReCaptch } = this.state */

        if (!fields["Email"]) {
            formIsValid = false;
            errors["Email"] = Messages.v_EnterEmail;
        }
        else if (typeof fields["Email"] !== "undefined") {
            if (!validateEmail(fields["Email"])) {
                formIsValid = false;
                errors["Email"] = Messages.v_EnterValidEmail;
            }
        }

        if (!fields["FirstName"]) {
            formIsValid = false;
            errors["FirstName"] = Messages.v_EnterName;
        }
        else if (typeof fields["FirstName"] !== "undefined") {
            if (!validateUserName(fields["FirstName"])) {
                formIsValid = false;
                errors["FirstName"] = Messages.v_EnterValidName;
            }
        }

        if (!fields["LastName"]) {
            formIsValid = false;
            errors["LastName"] = Messages.v_EnterLastName;
        }

        if (!fields["Password"]) {
            formIsValid = false;
            errors["Password"] = Messages.v_EnterPassword
        }
        else if ((typeof fields["Password"] !== "undefined") && !this.state.isGoogleFacebook) {
            if (!validatePassword(fields["Password"])) {
                formIsValid = false;
                errors["Password"] = Messages.v_EnterValidPassword;
            }
        }

        if (!fields["ConfirmPassword"]) {
            formIsValid = false;
            errors["ConfirmPassword"] = Messages.v_EnterConfirmPassword;
        }
        else if ((typeof fields["ConfirmPassword"] !== "undefined") && !this.state.isGoogleFacebook) {
            if (!validatePassword(fields["ConfirmPassword"])) {
                formIsValid = false;
                errors["ConfirmPassword"] = Messages.v_EnterValidPassword;
            }
        }

        if (fields["ConfirmPassword"] != fields["Password"]) {
            formIsValid = false;
            errors["ConfirmPassword"] = Messages.v_ConfirmPwdMatchWithPwd
        }

        if (!fields["PhoneNumber"]) {
            formIsValid = false;
            errors["PhoneNumber"] = Messages.v_EnterPhoneNo
        }
        else if (typeof fields["PhoneNumber"] !== "undefined") {
            if (!validatePhoneNumber(fields["PhoneNumber"])) {
                formIsValid = false;
                errors["PhoneNumber"] = Messages.v_EnterMoNoFormate;
            }
        }

        if (this.state.chkBoxChecked === false) {
            formIsValid = false;
            errors["termspolicy"] = Messages.v_Terms_And_Policy;
        }

        /* Native Captcha Code */
        if (!fields['captchacode']) {
            formIsValid = false;
            errors["captchacode"] = Messages.v_EnterCaptcha;
        }
        else if (typeof fields["captchacode"] !== "undefined") {
            if (fields["captchacode"] != this.state.captchWord) {
                formIsValid = false;
                errors["captchacode"] = Messages.v_EnterValidCaptcha;
            }
        }

        /* Google Captcha Code */
        /* if (!googleReCaptch) {
            formIsValid = false;
        } */

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleCheckBoxChange() {
        if (this.state.chkBoxChecked) {
            this.setState({ chkBoxChecked: false })
        } else {
            this.setState({ chkBoxChecked: true })
        }
    }

    /***************************************************************************************** */
    // END --> FUNCTIONS FOR REGISTRATION MODULE
    /***************************************************************************************** */


    /***************************************************************************************** */
    // START --> FUNCTIONS FOR PHONE NUMBER MODULE
    /***************************************************************************************** */

    validatePhoneNoForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;

        if (!fields["PhoneNumber"]) {
            formIsValid = false;
            errors["PhoneNumber"] = Messages.v_EnterPhoneNo
        }
        else if (typeof fields["PhoneNumber"] !== "undefined") {
            if (!validatePhoneNumber(fields["PhoneNumber"])) {
                formIsValid = false;
                errors["PhoneNumber"] = Messages.v_EnterMoNoFormate;
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSubmitPhoneNo(e) {
        var postData = {};
        e.preventDefault();
        if (this.validateForm()) {

            /* Google Captcha Code */
            /* const { googleReCaptch } = this.state; */

            var countryfields = this.state.countryfields;
            var postalCode = countryfields.PostalCode;

            postData = this.state.userFields;
            postData["CountryCode"] = countryfields.CountryCode;

            postData["PostalCode"] = postalCode ? postalCode.toUpperCase() : "";
            postData["DeviceId"] = "";
            postData["DeviceType"] = "";
            postData["PushToken"] = "";
            postData["ipaddress"] = this.state.ipAddress;
            postData["browser"] = browserName;
            postData["browserversion"] = fullBrowserVersion;
            postData["osdetails"] = osName + " " + osVersion;
            if (this.state.selectfulladdressdetail && this.state.selectfulladdressdetail.formatted_address) {
                postData["location"] = this.state.selectfulladdressdetail.formatted_address;
            } else {
                postData["location"] = (postalCode ? postalCode.toUpperCase() : "") + "," + countryfields.CountryCode;
            }
            postData["locale"] = this.state.browserLanguage;
            postData["Latitude"] = this.state.latitude;
            postData["Longitude"] = this.state.longitude;
            postData["questionid"] = DEFAULTID;
            postData["answer"] = "";
            postData["userid"] = "";
            postData["googlecaptcharesponse"] = ""; /* googleReCaptch */

            console.log('===>postData', postData);
            console.log("====post", JSON.stringify(postData));

            this.callResgisterAPI(postData);
        }
    }

    callResgisterAPI(postData) {
        console.log("callResgisterAPI");
        this.setState({ isActive: true })
        var URL = USER_REGISTRATION_API;
        PostData(URL, postData)
            .then(result => {
                var responseJson = result;
                console.log("callResgisterAPI", responseJson);
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ page: 3 })
                }
                else if (responseJson["StatusCode"] === 400) {
                    let errors = {};
                    errors["Email"] = "Email ID already exists";
                    this.setState({ errors: errors, issignupwithgooogle: false });
                }
                else {
                    let errors = {};
                    errors["termspolicy"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false });
                this.reloadCaptch()
            }).catch(err => {
                this.setState({ isActive: false });
                let errors = {};
                errors["termspolicy"] = Messages.ERR
                this.reloadCaptch()
            });
    }

    ResponsewithGooglewithSigninApi = (response) => {
        console.log(response);
        try {
            var userFields = this.state.userFields;
            if (response && response.sub) {
                if (response && response.email) {
                    userFields['Email'] = response.email;
                    this.setState({ issignupwithgooogle: true });
                }
                if (response && response.family_name) {
                    userFields['FirstName'] = response.family_name;
                }
                if (response && response.given_name) {
                    userFields['LastName'] = response.given_name;
                }
                this.setState({ page: 2, userFields: userFields, errors: {} });
            } else {
                this.setState({ page: 1, errors: {}, issignupwithgooogle: false });
            }
        } catch (err) {
            console.log(err);
            this.setState({ page: 1, errors: {}, issignupwithgooogle: false });
        }
    }

    /***************************************************************************************** */
    // END --> FUNCTIONS FOR PHONE NUMBER MODULE
    /***************************************************************************************** */




    /***************************************************************************************** */
    // START --> FUNCTIONS FOR VALIDATE OTP MODULE
    /***************************************************************************************** */

    handleValidateOtpChange(event) {
        let otp = this.state.otp;
        let value = event.target.value
        otp["otp"] = value
        this.setState({ otp })
    }

    handleValidationOTPonBlur(e) {
        let fields = this.state.otp;
        let errors = this.state.errors;
        let formIsValid = true;
        let name = e.currentTarget.name;

        if (name === "otp") {
            if (!fields["otp"]) {
                formIsValid = false;
                errors["otp"] = Messages.v_EnterOTP
            }
            else if (typeof fields["otp"] !== "undefined") {
                if (!validateOtpNo(fields["otp"])) {
                    formIsValid = false;
                    errors["otp"] = Messages.v_EnterValidOTP;
                }
            }
        }

        if (formIsValid) errors[name] = "";
        this.setState({
            fields,
            errors
        });
    }

    validateOTPForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.otp;

        if (!fields["otp"]) {
            formIsValid = false;
            errors["otp"] = Messages.v_EnterOTP
        }
        else if (typeof fields["otp"] !== "undefined") {
            if (!validateOtpNo(fields["otp"])) {
                formIsValid = false;
                errors["otp"] = Messages.v_EnterValidOTP;
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleValidateOtpSubmit(e) {
        e.preventDefault();
        if (this.validateOTPForm()) {
            this.setState({ isActive: true });
            var postData = {};
            postData["Email"] = this.state.userFields.Email;
            postData["PhoneNumber"] = this.state.userFields.PhoneNumber;
            postData["DeviceId"] = "";
            postData["DeviceType"] = "";
            postData["Code"] = this.state.otp.otp;
            console.log('===> otpvaidate', postData)
            this.callValidateOtpAPI(postData);
        }
    }

    callValidateOtpAPI(postData) {
        var URL = VALIDATE_OTP_API;
        PostData(URL, postData)
            .then(result => {
                var responseJson = result;
                console.log('responseJson', responseJson)
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var authToken = responseJson["Data"].Token;
                    var selectfulladdressdetail = this.state.selectfulladdressdetail
                    if (authToken && selectfulladdressdetail) {
                        this.handelAddressSubmit(authToken, selectfulladdressdetail);
                    }
                    StoreDataInStorage(Constants.AUTH_TOKEN, authToken);
                    StoreDataInStorage(Constants.USER_SESSION_TYPES, UserSessionTypes.PLAZACART_CUSTOMER);
                    let errors = {};
                    this.setState({ errors: errors, isActive: false });
                    this.closemyModal();
                    const { onRefresh } = this.props;
                    onRefresh();
                }
                else if (responseJson["StatusCode"] === 400) {
                    let errors = {};
                    errors["otp"] = responseJson.Message;
                    this.setState({ errors: errors, isActive: false });
                }
                else {
                    let errors = {};
                    errors["otp"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors, isActive: false });
                }
            }).catch(error => {
                let errors = {};
                errors["otp"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    handelAddressSubmit(authToken, addressDetail) {
        var postData = {}
        postData['StreetAddress'] = "";
        postData['ApartmentNumber'] = "";
        postData['PostalCode'] = "";
        if (addressDetail && addressDetail.address_components) {
            for (var obj of addressDetail.address_components) {
                const componentType = obj.types[0];
                switch (componentType) {
                    case GOOGLEPLACEAPIMAP.STREET_NUMBER: {
                        postData['ApartmentNumber'] = obj.long_name;
                        break;
                    }
                    case GOOGLEPLACEAPIMAP.ROUTE: {
                        postData['ApartmentNumber'] = postData['ApartmentNumber'] + ", " + obj.short_name;
                        break;
                    }
                    case GOOGLEPLACEAPIMAP.POSTAL_CODE: {
                        postData['PostalCode'] = removeAllSpace(obj.long_name);
                        break;
                    }
                    case GOOGLEPLACEAPIMAP.POSTAL_CODE_SUFFIX: {
                        postData['PostalCode'] = removeAllSpace(postData['PostalCode'] + "-" + obj.long_name);
                        break;
                    }
                    case GOOGLEPLACEAPIMAP.LOCALITY: {
                        postData['StreetAddress'] = obj.long_name;
                        break;
                    }
                    case GOOGLEPLACEAPIMAP.ADMINISTRATIVE_AREA_LEVEL_1: {
                        postData['StreetAddress'] = postData['StreetAddress'] + ", " + obj.short_name;
                        break;
                    }
                    case GOOGLEPLACEAPIMAP.COUNTRY:
                        postData['StreetAddress'] = postData['StreetAddress'] + ", " + obj.long_name;
                        break;
                }
            }
        }
        console.log("=====", postData)
        this.addAddress(postData, authToken)
    }

    addAddress(postData, authToken) {
        this.setState({ isActive: true });
        var URL = USER_ADDRESS;
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                console.log("==== address", result)
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {

                }
                else {
                    this.setState({ isActive: false });
                }
            })
    }

    /***************************************************************************************** */
    // END --> FUNCTIONS FOR VALIDATE OTP MODULE
    /***************************************************************************************** */

    handleModalview(falg) {
        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById('registerpublicmodel');

        if (backgroung && modalId) {
            backgroung.style.display = falg ? "block" : "none";
            modalId.style.display = falg ? "block" : "none";
        }
    }

    closemyModal() {
        this.setState({ userFields: {}, errors: {}, countryfields: {}, page: 1 });
        this.handleModalview(false);
        const { onGRender } = this.props;
        onGRender()
    }

    render() {
        /* Google Captcha Code */
        const { isPassword, isConfirmPassword, /* googleReCaptch, iscaptchreload, */ issignupwithgooogle, iscaptchwordMatch, captchWord } = this.state;

        return (
            <LoadingOverlay
                active={this.state.isActive}
                text={Constants.LoadingMssg}
                spinner
                styles={{
                    overlay: (base) => ({
                        ...base,
                        position: "fixed"
                    })
                }}
                className="">
                <div>
                    <div class="modal modalcustom" id="registerpublicmodel" style={{ marginBottom: "10px", zIndex: 399 }} centered>
                        <div class="modal-dialog" style={{ margin: "auto", top: "25%" }}>
                            <div class="modal-content">
                                <div class="modal-header">
                                    <label></label>
                                    <button type="button" class="close" onClick={() => this.closemyModal()}>&times;</button>
                                </div>
                                <div class="modal-body" style={{ padding: '5px' }}>
                                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }} >

                                        {this.state.page === 1 && <div>
                                            <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }} >
                                                <div className="reg_form_main col-sm-12">
                                                    <div className="bottam_sing">
                                                        <center>
                                                            <div
                                                                className="col-lg-7 btn btn-done d-flex"
                                                                style={{
                                                                    height: "44px",
                                                                    borderRadius: "4px", backgroundColor: Color.THEME_COLOR,
                                                                    maxWidth: '300px',
                                                                    padding: 0,
                                                                    alignItems: 'center',
                                                                    gap: '70px'
                                                                }}
                                                                onClick={() => this.navigatepageform()}>
                                                                <img src={email} style={{ height: "30px", width: "30px" }} ></img>
                                                                <label
                                                                    className="m-0" style={{ fontSize: "14px", letterSpacing: '0.25px' }}> {RegisterConst.Sign_up_with_Email}</label>
                                                            </div>
                                                        </center>
                                                    </div>
                                                    <div className="bottam_sing mb-4">
                                                        <LoginWithGoogle
                                                            mode={2}
                                                            clientId={GOOGLE_CLIENT_ID}
                                                            disabled={false}
                                                            onSuccess={this.ResponsewithGooglewithSigninApi} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}

                                        {this.state.page === 2 && <div>
                                            <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>

                                                <div className="reg_form_main col-sm-12">
                                                    <p className="col-sm-12 textcolor settextalignpro" for="FirstName" style={{ fontWeight: 'bold' }}>
                                                        {RegisterConst.FirstName}<PutMandatoryfieldSymbol />
                                                    </p>
                                                    <input
                                                        type="text"
                                                        placeholder={RegisterPH.FirstName}
                                                        name="FirstName"
                                                        className="float-none"
                                                        value={this.state.userFields.FirstName}
                                                        onChange={this.handleChange}
                                                        onBlur={this.handleValidationOnBlur} />
                                                    <div className="col-sm-12 errorMsg mb-0 settextalignpro">
                                                        {this.state.errors.FirstName}
                                                    </div>
                                                </div>

                                                <div className="reg_form_main col-sm-12">
                                                    <p className="col-sm-12 textcolor settextalignpro" for="LastName" style={{ fontWeight: 'bold' }}>
                                                        {RegisterConst.LastName} <PutMandatoryfieldSymbol />
                                                    </p>
                                                    <input
                                                        type="text"
                                                        placeholder={RegisterPH.LastName}
                                                        name="LastName"
                                                        className="float-none"
                                                        value={this.state.userFields.LastName}
                                                        onChange={this.handleChange}
                                                        onBlur={this.handleValidationOnBlur} />
                                                    <div className="col-sm-12 errorMsg mb-0 settextalignpro">
                                                        {this.state.errors.LastName}
                                                    </div>
                                                </div>

                                                <div className="reg_form_main col-sm-12">
                                                    <p className="col-sm-12 settextalignpro" style={{ color: Color.THEME_COLOR, fontWeight: 'bold' }} for="PostalCode">
                                                        {LandingPageConst.Country} <PutMandatoryfieldSymbol />
                                                    </p>
                                                    <ReactFlagsSelect
                                                        className="menu-flags"
                                                        selectButtonClassName="flag-selection-btn"
                                                        style={{ borderColor: Color.THEME_COLOR }}
                                                        name="CountryCode"
                                                        selected={this.state.selectedCountry}
                                                        countries={this.state.countryDataList}
                                                        placeholder={LandingPagePH.SelectCountry}
                                                        onSelect={this.onCountrySelection} />
                                                    <div className="col-md-12 errorMsg settextalignpro">
                                                        {this.state.errors.CountryCode}
                                                    </div>
                                                </div>

                                                <div className="reg_form_main col-sm-12">
                                                    <p className="col-sm-12 settextalignpro" style={{ color: Color.THEME_COLOR, fontWeight: 'bold' }} for="PostalCode">
                                                        {LandingPageConst.Address} <PutMandatoryfieldSymbol />
                                                    </p>
                                                    <GooglePlacesAutocomplete
                                                        apiKey={GOOGLE_PLACE_KEY}
                                                        selectProps={{
                                                            value: this.state.selectedAddress,
                                                            onChange: (setAddress) => this.handelAddress(setAddress),
                                                            isClearable: true,
                                                            placeholder: LandingPagePH.AddAddress,
                                                            styles: colourStyles
                                                        }}
                                                        autocompletionRequest={{
                                                            componentRestrictions: {
                                                                country: this.state.countryfields.CountryCode ? [this.state.countryfields.CountryCode] : [],
                                                            }
                                                        }} />
                                                    <div className="col-md-12 errorMsg settextalignpro">
                                                        {this.state.errors.Address}
                                                    </div>
                                                </div>

                                                <div className="reg_form_main col-sm-12">
                                                    <p className="col-sm-12 settextalignpro" style={{ color: Color.THEME_COLOR, fontWeight: 'bold' }} for="PostalCode">
                                                        {LandingPageConst.ZIP_PostalCode} <PutMandatoryfieldSymbol />
                                                    </p>
                                                    <input
                                                        type="text"
                                                        onChange={this.handleCountryChange}
                                                        className="float-none"
                                                        name="PostalCode"
                                                        value={this.state.countryfields.PostalCode}
                                                        placeholder={LandingPagePH.ZIP_PostalCode} />
                                                    <div className="col-md-12 errorMsg settextalignpro">
                                                        {this.state.errors.PostalCode}
                                                    </div>
                                                </div>

                                                {!this.state.isGoogleFacebook && <div className="reg_form_main col-sm-12">
                                                    <p className="col-sm-12 textcolor settextalignpro" style={{ color: Color.THEME_COLOR, fontWeight: 'bold' }} for="Email">
                                                        {RegisterConst.Email} <PutMandatoryfieldSymbol />
                                                    </p>
                                                    <input
                                                        type="email"
                                                        placeholder={RegisterPH.Email}
                                                        name="Email"
                                                        className="float-none"
                                                        disabled={issignupwithgooogle}
                                                        value={this.state.userFields.Email}
                                                        onChange={this.handleChange}
                                                        onBlur={this.handleValidationOnBlur} />
                                                    <div className="col-sm-12 errorMsg mb-0 settextalignpro">
                                                        {this.state.errors.Email}
                                                    </div>{" "}
                                                    <div className="col-sm-12 errorMsg mb-0 settextalignpro">
                                                        {this.state.alreadyEmail}
                                                    </div>
                                                </div>}

                                                {!this.state.isGoogleFacebook && <div className="reg_form_main col-sm-12">
                                                    <p className="col-sm-12 textcolor settextalignpro" for="Password" style={{ fontWeight: 'bold' }}>
                                                        {RegisterConst.Password} <PutMandatoryfieldSymbol />
                                                    </p>
                                                    <div className="row" style={{ backgroundColor: "#f0f0f0", borderRadius: "50px", marginBottom: '15px', marginLeft: "0px", marginRight: "0px" }}>
                                                        <input
                                                            style={{ width: "90%", margin: 0 }}
                                                            type={isPassword ? "password" : "text"}
                                                            placeholder={RegisterPH.Password}
                                                            name="Password"
                                                            className="float-none passwordinputregister"
                                                            value={this.state.userFields.Password}
                                                            onChange={this.handleChange}
                                                            onBlur={this.handleValidationOnBlur} />
                                                        <i class={!isPassword ? "fa fa-eye" : "fa fa-eye-slash"} style={{ color: Color.THEME_COLOR, width: '30px', marginTop: "14px" }} onClick={() => this.setState({ isPassword: !isPassword })} />
                                                    </div>
                                                    <div className="col-sm-12 errorMsg mb-0 settextalignpro">
                                                        {this.state.errors.Password}
                                                    </div>
                                                </div>}

                                                {!this.state.isGoogleFacebook &&
                                                    <div className="reg_form_main col-sm-12">
                                                        <p className="col-sm-12 textcolor settextalignpro" for="Password" style={{ fontWeight: 'bold' }}>
                                                            {RegisterConst.ConfirmPassword} <PutMandatoryfieldSymbol />
                                                        </p>
                                                        <div className="row" style={{ backgroundColor: "#f0f0f0", borderRadius: "50px", marginBottom: '15px', marginLeft: "0px", marginRight: "0px" }}>
                                                            <input
                                                                style={{ margin: 0 }}
                                                                type={isConfirmPassword ? "password" : "text"}
                                                                placeholder={RegisterPH.ConfirmPassword}
                                                                name="ConfirmPassword"
                                                                className="float-none passwordinputregister"
                                                                value={this.state.userFields.ConfirmPassword}
                                                                onChange={this.handleChange}
                                                                onBlur={this.handleValidationOnBlur} />
                                                            <i class={!isConfirmPassword ? "fa fa-eye" : "fa fa-eye-slash"} style={{ color: Color.THEME_COLOR, width: '30px', marginTop: "14px" }} onClick={() => this.setState({ isConfirmPassword: !isConfirmPassword })} />
                                                        </div>
                                                        <div className="col-sm-12 errorMsg mb-0 settextalignpro">
                                                            {this.state.errors.ConfirmPassword}
                                                        </div>
                                                    </div>
                                                }

                                                <div className="reg_form_main col-sm-12">
                                                    <p className="col-sm-12 textcolor settextalignpro" for="PhoneNumber" style={{ fontWeight: 'bold' }}>
                                                        {RegisterConst.screen4Msg1} <PutMandatoryfieldSymbol />
                                                    </p>
                                                    <input
                                                        className="col-sm-12 float-none"
                                                        type="text"
                                                        pattern="[0-9]*"
                                                        placeholder={RegisterPH.PhoneNumber}
                                                        name="PhoneNumber"
                                                        maxLength="12"
                                                        value={this.state.userFields.PhoneNumber}
                                                        onChange={this.handleChange}
                                                        onBlur={this.handleValidationOnBlur} />
                                                    <div className="col-sm-12 errorMsg mb-0 settextalignpro">
                                                        {this.state.errors.PhoneNumber}
                                                    </div>
                                                </div>

                                                <div className="reg_form_main col-sm-12">
                                                    <div >
                                                        <div className="col-sm-12">
                                                            <Checkbox
                                                                color={Color.THEME_COLOR}
                                                                size={2}
                                                                tickSize={2}
                                                                checked={this.state.chkBoxChecked}
                                                                delay={0}
                                                                backAnimationDuration={0}
                                                                tickAnimationDuration={0}
                                                                onChange={this.handleCheckBoxChange}></Checkbox>
                                                            &nbsp; &nbsp;<span className="textcolor">{RegisterConst.screen3Msg} <a href={RouteConstant.OrderWebTermsOfService} className="atext" target="_blank">{RegisterConst.screen3Msg1}</a> {RegisterConst.screen3Msg2} <a href={RouteConstant.OrderWebPrivacyPolicy} target="_blank" className="atext">{RegisterConst.screen3Msg3}</a> {RegisterConst.screen3Msg4}</span>
                                                        </div>
                                                        <div className="col-sm-12 errorMsg settextalignpro" style={{ marginTop: '7px' }}>
                                                            {this.state.errors.termspolicy}
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Google Captcha Code */}
                                                {/* <div className="reg_form_main col-sm-12 text-center">
                                                    {iscaptchreload && <ReCAPTCHA
                                                        style={{ display: "inline-block" }}
                                                        // theme="dark"
                                                        // ref={this._reCaptchaRef}
                                                        size="normal"
                                                        sitekey={GOOGLESITEKEY}
                                                        onChange={this.handleGoogleRecaptcha}
                                                    // asyncScriptOnLoad={this.asyncScriptOnLoad}
                                                    />}
                                                </div> */}

                                                {/* Native Captcha Code */}
                                                <div className="reg_form_main col-sm-12">
                                                    <p className="col-sm-12 textcolor settextalignpro" for="PhoneNumber" style={{ fontWeight: 'bold' }}>
                                                        {RegisterConst.Captcha} <PutMandatoryfieldSymbol />
                                                    </p>
                                                    <input
                                                        type="text"
                                                        placeholder={RegisterPH.Captcha}
                                                        name="captchacode"
                                                        className="float-none m-0 mb-2"
                                                        value={this.state.userFields.captchacode}
                                                        maxLength="6"
                                                        onChange={this.handleChange}
                                                        onBlur={this.handleValidationOnBlur} />
                                                    <div className="col-sm-12 errorMsg mb-2 settextalignpro">
                                                        {this.state.errors.captchacode}
                                                    </div>
                                                    <div className="text-center">
                                                        <ACaptcha
                                                            font={'30px Arial'}
                                                            align={'center'}
                                                            baseline={'middle'}
                                                            width={200}
                                                            height={50}
                                                            bgColor={Color.THEME_COLOR}
                                                            color={Color.WHITE}
                                                            captchstr={captchWord} />
                                                    </div>
                                                </div>

                                                <div className="reg_form_main col-sm-12">
                                                    <label className="col-sm-12">
                                                        {RegisterConst.screen4Msg}
                                                    </label>
                                                </div>
                                                <div className="reg_form_main">
                                                    <div className="bottam_sing" >
                                                        <button
                                                            style={{ borderRadius: "4px", backgroundColor: Color.GREY, marginBottom: '10px' }}
                                                            onClick={() => { this.setState({ page: 1 }) }}
                                                            type="button"
                                                            className="btn btn-done"
                                                        > {RegisterConst.btn_back}</button>
                                                        <button
                                                            style={{ borderRadius: "4px", backgroundColor: Color.THEME_COLOR, marginBottom: '10px' }}
                                                            onClick={this.handleSubmitPhoneNo}
                                                            type="button"
                                                            className="btn btn-done"
                                                            disabled={!iscaptchwordMatch /* !googleReCaptch */}/* Google Captcha Code */
                                                        > {RegisterConst.btn_Continue}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}

                                        {this.state.page == 3 && <div>
                                            <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }} >
                                                <div className="reg_form_main col-sm-12">
                                                    <p className="col-sm-12 textcolor" for="otp" style={{ fontWeight: 'bold', marginTop: '20px' }}>
                                                        {RegisterConst.screen5Msg} <PutMandatoryfieldSymbol />
                                                    </p>
                                                    <input
                                                        type="text"
                                                        pattern="[0-9]*"
                                                        inputmode="numeric"
                                                        placeholder={RegisterPH.Enterotpnumber}
                                                        className="float-none"
                                                        name="otp"
                                                        maxLength="6"
                                                        value={this.state.otp.otp}
                                                        onChange={this.handleValidateOtpChange}
                                                        onBlur={this.handleValidationOTPonBlur} />
                                                    <div className="errorMsg mb-0 settextalignpro">
                                                        {this.state.errors.otp}
                                                    </div>
                                                </div>
                                                <div className="reg_form_main">
                                                    <div className="bottam_sing mt-3">
                                                        <button
                                                            style={{ borderRadius: "4px", backgroundColor: Color.GREY, marginRight: '5px' }}
                                                            onClick={() => { this.setState({ page: 2 }) }}
                                                            type="button"
                                                            className="btn btn-done"
                                                        > {RegisterConst.btn_back}</button>
                                                        <label
                                                            style={{ borderRadius: "4px", backgroundColor: Color.THEME_COLOR, margin: '0px' }}
                                                            onClick={this.handleValidateOtpSubmit}
                                                            className="btn btn-done"
                                                        > {RegisterConst.btn_Submit}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </LoadingOverlay>
        );
    }
}

export default RegisterOrderWeb;