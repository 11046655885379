import React, { Component } from 'react';
import './MyCart.css';
import Color from '../Config/Color';
import delete1 from "../../images/icons/delete.png";
import { StoreObjectInStorage, RetriveObjectFromStorage } from '../Config/Utils';
import Constants, { currencyInfo, COMPANYCONST } from '../Config/Constants';
import CartList from './CartList';
import Collapsible from 'react-collapsible';
import CartStoreList from './CartStoreList';

class CartPlazaList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            plazalist: props.data,
            plazaId: "",
            storeId: "",
            itemId: ""
        }
    }

    componentDidMount() {
    }

    setPlazaTotalAmount = (obj, storeId, ItemId) => {
        console.log("obj ", obj);
        console.log("storeId ", storeId);
        console.log("ItemId ", ItemId);
        console.log("ItemId ", this.state.plazalist);

        var list1 = this.state.plazalist.map(curr => {
            var storelist = curr.Stores
            var list2 = storelist.map(objStore => {
                if (objStore.ApplicationId === storeId) {
                    var itemlist = objStore.Items
                    var list3 = itemlist.map(objItem => {
                        if (objItem.ItemId === ItemId) {
                            objItem = obj
                            return { ...objItem }
                        }
                        else {
                            return { ...objItem }
                        }
                    })
                    return { ...objStore, Items: list3 }
                }
                else {
                    return { ...objStore }
                }
            })
            return { ...curr, Stores: list2 }
        })

        console.log("plaza  ", list1);
        this.setState({ plazalist: list1 }, function () {
            this.props.setPlazaTotal(this.state.plazalist);
        })
    }


    setdeletedItem = (deleteItemId, storeId) => {
        // console.log("obj ", obj);
        console.log("storeId ", storeId, deleteItemId);

        console.log("ItemId ", this.state.plazalist);

        var list1 = this.state.plazalist.map(curr => {


            var storelist = curr.Stores
            var list2 = storelist.map(objStore => {
                var itemArray = []
                if (objStore.ApplicationId === storeId) {


                    var itemlist = objStore.Items
                    var list3 = itemlist.map(objItem => {
                        if (objItem.ItemId === deleteItemId) {
                            itemArray = objStore.Items.filter(e1 => e1.ItemId !== deleteItemId);
                            // alert(JSON.stringify(itemArray))
                            // return { ...objItem }
                        }
                        // else {
                        //     itemArray = objStore.Items
                        //     // return { ...objItem }
                        // }
                    })

                    return { ...objStore, "Items": itemArray }
                }
                else {
                    return { ...objStore }
                }
            })

            return { ...curr, "Stores": list2 }
        })

        console.log("plaza  ", list1);
        this.setState({ plazalist: list1 }, function () {
            this.props.deletedItemwith(this.state.plazalist);
        })
    }


    renderStore(store, plazaId) {
        return store
            .map(function (item, index) {
                var storeItemList = item.Items
                return (
                    <React.Fragment key={index}>
                        <div className="col-sm-12" style={{ backgroundColor: Color.THEME_COLOR, color: Color.WHITE }} >
                            {item.ApplicationName}
                        </div>
                        {this.renderStoreItem(plazaId, item.ObjectId, storeItemList)}
                    </React.Fragment>
                )
            }, this);
    }

    openDeleteDialog(plazaId, storeid, item) {
        this.setState({
            plazaId: plazaId,
            storeId: storeid,
            itemId: item.ItemId
        })
        $('#myModal').modal('show')
    }

    unsetDeleteId() {
        this.setState({
            plazaId: "",
            storeId: "",
            itemId: ""
        })
    }

    handleDelete() {
        var plazaIdState = this.state.plazaId;
        var storeIdState = this.state.storeId;
        var itemIdState = this.state.itemId;

        var list1 = this.state.plazalist.map((curr, indexP) => {

            var storelist = curr.Stores
            if (curr.PlazaId === plazaIdState) {
                var list2 = storelist.map((objStore, indexS) => {

                    if (objStore.ObjectId === storeIdState) {

                        var ItemlistIn = objStore.Items.filter(e => itemIdState !== e.ItemId)
                        return { ...objStore, "Items": ItemlistIn }
                    }
                    else {
                        return { ...objStore }
                    }


                })
                return { ...curr, Stores: list2.filter(e1 => e1.Items.length > 0) }
            } else {
                return { ...curr }
            }


        })
        var masterPlazaList = list1.filter(e2 => e2.Stores.length > 0)
        this.setState({ plazalist: masterPlazaList }, function () {
            if (this.state.plazalist.length > 0) {
                StoreObjectInStorage(Constants.Obj_CART, this.state.plazalist)
            }
            else {
                StoreObjectInStorage(Constants.Obj_CART, '')
            }
            this.props.setPlazaTotal(this.state.plazalist);
        })
    }

    handlePlus(plazaId, storeid, item) {
        var list1 = this.state.plazalist.map(curr => {
            var storelist = curr.Stores
            if (curr.PlazaId === plazaId) {
                var list2 = storelist.map(objStore => {
                    if (objStore.ObjectId === storeid) {
                        var itemlist = objStore.Items
                        var list3 = itemlist.map(objItem => {
                            var count = item.ItemQuantity
                            var singleItemPrice = item.TotalItemPrice / count;
                            count = count + 1;
                            if (item.ItemId === objItem.ItemId) {
                                if (objItem.ContinueSelling) {
                                    curr.ItemQuantity = count
                                    return {
                                        ...objItem, "TotalItemPrice": singleItemPrice * count,
                                        "ItemQuantity": count,
                                        "specialdeliverycharges": (objItem.specialdeliverycharges / objItem.ItemQuantity) * count
                                    }
                                }
                                else {
                                    if (objItem.AvailableQuantity >= count) {
                                        curr.ItemQuantity = count
                                        return {
                                            ...objItem, "TotalItemPrice": singleItemPrice * count,
                                            "ItemQuantity": count,
                                            "specialdeliverycharges": (objItem.specialdeliverycharges / objItem.ItemQuantity) * count
                                        }
                                    }
                                    return { ...objItem, "specialdeliverycharges": (objItem.specialdeliverycharges / objItem.ItemQuantity) * count }
                                }
                            } else {
                                return { ...objItem }
                            }
                        })
                        return { ...objStore, Items: list3 }
                    }
                    else {
                        return { ...objStore }
                    }
                })
                return { ...curr, Stores: list2 }
            } else {
                return { ...curr }
            }
        })
        this.setState({ plazalist: list1 }, function () {
            this.props.setPlazaTotal(this.state.plazalist);
        })
    }

    handleMinus(plazaId, storeid, item) {
        var list1 = this.state.plazalist.map(curr => {
            var storelist = curr.Stores
            if (curr.PlazaId === plazaId) {
                var list2 = storelist.map(objStore => {
                    if (objStore.ObjectId === storeid) {
                        var itemlist = objStore.Items
                        var list3 = itemlist.map(objItem => {
                            var count = item.ItemQuantity
                            var singleItemPrice = item.TotalItemPrice / count;
                            if (count !== 1) {
                                count = count - 1;
                                if (item.ItemId === objItem.ItemId) {
                                    // objItem.ItemQuantity = count
                                    return {
                                        ...objItem, "TotalItemPrice": singleItemPrice * count,
                                        "ItemQuantity": count,
                                        "specialdeliverycharges": (objItem.specialdeliverycharges / objItem.ItemQuantity) * count
                                    }
                                } else {
                                    return {
                                        ...objItem,
                                        "specialdeliverycharges": (objItem.specialdeliverycharges / objItem.ItemQuantity) * count
                                    }
                                }
                            }
                            return { ...objItem }
                        })

                        return { ...objStore, Items: list3 }
                    }
                    else {
                        return { ...objStore }
                    }
                })
                return { ...curr, Stores: list2 }
            } else {
                return { ...curr }
            }
        })
        this.setState({ plazalist: list1 }, function () {
            this.props.setPlazaTotal(this.state.plazalist);
        })
    }

    renderStoreItem(plazaId, storeid, storeItemList) {
        var currencySymbole = ""
        var countryFees = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES)
        if (countryFees) {
            currencySymbole = (countryFees[currencyInfo.CountryCode] ?
                "(" + countryFees[currencyInfo.CountryCode] + ") " : "") + countryFees.Symbol

        }
        return storeItemList
            .map(function (item, index) {
                // var singleItemPrice = item.TotalItemPrice / item.ItemQuantity
                var total = item.TotalItemPrice

                // var total = item.TotalItemPrice
                total = (Math.round(total * 100) / 100).toFixed(2);
                return (
                    <React.Fragment key={item.ItemID}>
                        <div className="col-sm-12" style={{ backgroundColor: Color.WHITE, borderBottom: "1px solid" + Color.GREY }}  >
                            <div className="row" style={{ paddingTop: "5px" }} >
                                <img src={item.ItemLogo} style={{ paddingLeft: "10px", height: "40px", width: "20%", marginRight: "5px", width: "40px" }}></img>
                                <label style={{ fontSize: "15px", width: "60%", paddingTop: "5px" }}> {item.ItemName} {item.Instruction ? "(" + item.Instruction + ")" : ""}</label>
                                <span style={{ marginLeft: "auto", width: "20%", paddingTop: "5px", marginRight: "10px" }}>
                                    <label style={{ fontSize: "15px", float: "right", color: Color.THEME_COLOR, }}>{currencySymbole}{total}</label>
                                </span>
                            </div>

                            <div className="row" >
                                <span style={{ float: "right", marginLeft: "auto", }}>
                                    <label style={{ fontSize: "17px", color: Color.BLACK }} onClick={(value) => this.handleMinus(plazaId, storeid, item)}> - </label>
                                    <label style={{ fontSize: "17px", color: Color.BLACK, width: "40px", textAlign: "center" }}> {item.ItemQuantity}  </label>
                                    <label style={{ fontSize: "17px", color: Color.BLACK, marginRight: "20px" }} onClick={(value) => this.handlePlus(plazaId, storeid, item)} > + </label>
                                    <img src={delete1} style={{ marginLeft: "10px", marginRight: "10px", height: "20px", width: "20px" }} onClick={(value) => this.openDeleteDialog(plazaId, storeid, item)}></img>
                                    {/* <i  class="fa fa-trash" style={{ marginLeft:"10px", marginRight:"10px", fontSize: "20px", color: Color.GREY,  }}></i> */}
                                </span>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }, this);
    }

    render() {

        let list = this.state.plazalist
            .map(function (item, index) {
                var store = item.Stores;
                var noOfStore = store.length;
                var noOfProducts = 0;
                var plazaTotalAmount = 0.0
                var storeItemList = [];
                store.map(function (store, index1) {
                    noOfProducts = noOfProducts + store.Items.length
                    storeItemList = store.Items;
                    storeItemList.map(function (objitem, index2) {
                        var data = objitem;
                        plazaTotalAmount = plazaTotalAmount + ((data.TotalItemPrice / data.ItemQuantity) * data.ItemQuantity);
                    })
                })
                var currencySymbole = ""
                var countryFees = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES)
                if (countryFees) {
                    currencySymbole = (countryFees[currencyInfo.CountryCode] ?
                        "(" + countryFees[currencyInfo.CountryCode] + ") " : "") + countryFees.Symbol

                }

                return (
                    <React.Fragment>
                        <Collapsible
                            open={false}
                            trigger={
                                <div className="col-sm-12" style={{ backgroundColor: Color.WHITE, paddingBottom: "5px", borderBottom: "1px solid" + Color.GREY }} >
                                    <div style={{ width: "100%" }} >
                                        <div style={{ width: "20%", }}>
                                            <img src={item.Imageurl} style={{ marginTop: "5px", float: "left", height: "40px", marginRight: "5px", width: "40px", borderRadius: "5px" }}></img>
                                        </div>

                                        <div style={{ width: "100%" }}>
                                            <div className="row" style={{ height: "20px" }}>
                                                <label style={{ marginRight: "auto", color: Color.BLACK, fontSize: "17px" }}>{item.CompanyName}</label>
                                                <i class="fa fa-caret-down" style={{ fontSize: "30px", color: Color.THEME_COLOR, float: "right", marginRight: "5px" }} aria-hidden="true"></i>
                                            </div>
                                            <div className="row" style={{ marginTop: "0px" }}>
                                                <label style={{ marginRight: "auto", color: Color.BLACK, fontSize: "17px" }}>{noOfProducts} Product | {noOfStore} Shop</label>
                                                <label style={{ float: "right", marginRight: "5px", color: Color.THEME_COLOR, fontSize: "17px" }}>{currencySymbole}{(Math.round(plazaTotalAmount * 100) / 100).toFixed(2)}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            triggerWhenOpen={
                                <div className="col-sm-12" style={{ backgroundColor: Color.WHITE, paddingBottom: "5px", borderBottom: "1px solid" + Color.GREY }} >
                                    <div style={{ width: "100%" }} >
                                        <div style={{ width: "20%" }}>
                                            <img src={item.Imageurl} style={{ marginTop: "5px", float: "left", height: "40px", marginRight: "5px", width: "40px", borderRadius: "5px" }}></img>
                                        </div>

                                        <div style={{ width: "100%" }}>
                                            <div className="row" style={{ height: "20px" }}>
                                                <label style={{ marginRight: "auto", color: Color.BLACK, fontSize: "17px" }}>{item.CompanyName}</label>
                                                <i class="fa fa-caret-up" style={{ fontSize: "30px", color: Color.THEME_COLOR, float: "right", marginRight: "5px" }} aria-hidden="true"></i>
                                            </div>
                                            <div className="row">
                                                <label style={{ marginRight: "auto", color: Color.BLACK, fontSize: "17px" }}>{noOfProducts} Product | {noOfStore} Shop</label>
                                                <label style={{ float: "right", marginRight: "5px", color: Color.THEME_COLOR, fontSize: "17px" }}>{currencySymbole}{(Math.round(plazaTotalAmount * 100) / 100).toFixed(2)}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }>
                            {this.renderStore(store, item.PlazaId)}
                            {/* <CartStoreList
                                data={store}
                                setPlazaTotal={this.setPlazaTotalAmount}
                                deleteStoreItem={this.setdeletedItem}
                            >
                            </CartStoreList> */}
                        </Collapsible>
                    </React.Fragment>
                )
            }, this);

        return (
            <div>
                <div class="modal" id="myModal" style={{ verticalAlign: "middle" }}>
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-body">
                                <center>
                                    <label>{COMPANYCONST.BREWERSNEARME}</label><br />
                                    <label>Are you sure you want remove this product from cart?</label>
                                </center>
                            </div>
                            <div class="modal-footer">
                                <label
                                    style={{ color: Color.THEME_COLOR, marginLeft: "auto", marginRight: "auto" }}
                                    data-dismiss="modal"
                                    onClick={() => this.unsetDeleteId()}
                                > No</label>

                                <label
                                    onClick={() => this.handleDelete()}
                                    //onClick={this.handleDelete}
                                    style={{ color: Color.THEME_COLOR, marginLeft: "auto", marginRight: "auto" }}
                                    data-dismiss="modal"
                                > Yes</label>
                            </div>
                        </div>
                    </div>
                </div>
                {list}
            </div>
        );
    }
}

export default CartPlazaList;