import React, { Component } from 'react';
//import './SideMenu.css';
import WheelPhoto from "../../images/gear-wheel.png";
import Color from '../Config/Color';
import Messages from "../Config/Messages";
import Header from '../Header/Header';
import defaultUser from "../../images/default-user.png";
import { NavLink, withRouter } from 'react-router-dom';
import { RetriveObjectFromStorage, RetriveDataFromStorage, validateFloat, formatPhoneNumber, validatePhoneNumber } from '../Config/Utils';
import Constants from '../Config/Constants';
import Select from 'react-select';
import { GET_USERPROFILE_API, POST_PROFILEPIC_API } from '../Config/Config.json';
import { GetData } from "../../services/GetData";
import { PUTData } from "../../services/PUTData";
import { PostData } from "../../services/PostData";
import LoadingOverlay from 'react-loading-overlay';
import { PutMandatoryfieldSymbol } from '../UtillCustomComponent/UtillCustomComponent';

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#F3F4F1', borderRadius: "50px", border: "none", height: "50px" }),
};

const rediusunit = [
    { value: 'KM', label: 'KM' },
    { value: 'MI', label: 'MI' },
]


class EditProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userFields: {},
            errors: {},
            rediusunit: rediusunit,
            selectedRediusunit: null,
            selectedFile: null,
            filename: "",
            fileSize: "",
            isActive: false,
            isprofilePicUpload: false

        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFileInput = this.handleFileInput.bind(this);
    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var objUser = RetriveObjectFromStorage(Constants.Obj_USER);
        console.log("objAddress ", objUser)

        // this.getUserDetail(authToken);
        if (objUser) {
            var userFields = this.state.userFields;
            userFields["email"] = objUser.Email
            userFields["firstName"] = objUser.FirstName
            userFields["lastName"] = objUser.LastName
            userFields["phone1"] = formatPhoneNumber(objUser.Phone1)
            userFields["AptUnit"] = objUser.aptunit
            userFields["radius"] = objUser.Radius
            // userFields['ProfilePicture'] = objUser.ProfilePicture;

            var profilepic = ""
            var profileUrl = objUser.ProfilePicture;
            var profileUrlspite = profileUrl.split("/");
            if (profileUrlspite[profileUrlspite.length - 1] !== "BsonNull") {
                userFields['ProfilePicture'] = profilepic;
                this.setState({ selectedFile: objUser.ProfilePicture })
            }
            this.setState({ selectedRediusunit: this.state.rediusunit.filter(e1 => e1.value == objUser.RadiusUnit)[0] })
            this.setState({ userFields })
        }
        else {
            this.getUserDetail(authToken);
        }
    }

    getUserDetail(authToken) {
        this.setState({ isActive: true })
        var API = GET_USERPROFILE_API;
        GetData(API, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        var userFields = this.state.userFields;
                        userFields["email"] = responseData.Email
                        userFields["firstName"] = responseData.FirstName
                        userFields["lastName"] = responseData.LastName
                        userFields["phone1"] = formatPhoneNumber(responseData.Phone1)
                        userFields["AptUnit"] = responseData.aptunit
                        userFields["radius"] = responseData.Radius
                        // userFields['ProfilePicture'] = responseData.ProfilePicture;


                        var profilepic = ""
                        var profileUrl = responseData.ProfilePicture;
                        var profileUrlspite = profileUrl.split("/");
                        if (profileUrlspite[profileUrlspite.length - 1] !== "BsonNull") {
                            userFields['ProfilePicture'] = profilepic;
                            this.setState({ selectedFile: responseData.ProfilePicture })
                        }
                        this.setState({ selectedRediusunit: this.state.rediusunit.filter(e1 => e1.value == responseData.RadiusUnit)[0] })
                        this.setState({ userFields })

                    }

                }
                else {

                    // alert(Messages.NO_PLAZA);

                }
                this.setState({ isActive: false })
            });
    }

    handleChange(event) {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        if (key == "phone1") {
            userFields[key] = formatPhoneNumber(value)
        } else {
            userFields[key] = value
        }
        this.setState({ userFields })
    }

    handleStateChange = (selectedRediusunit) => {
        this.setState({ selectedRediusunit })
    }

    base64convert() {
        $('#imgupload').trigger('click');
    }

    handleFileInput(event) {
        try {
            var fileobj = event.target.files[0];
            if (fileobj.size > 0) {

                var fileName = event.target.files[0].name;
                var fileSize = event.target.files[0].size;
                var extension = ["jpg", "jpeg", "bmp", "png"]
                var fileSplit = fileName.split('.');
                var filelength = fileSplit.length
                var fileExtension = fileSplit[filelength - 1]
                var extensionFlag = extension.includes(fileExtension)
                if (extensionFlag) {
                    var fileReader = new FileReader();
                    fileReader.readAsDataURL(fileobj);
                    fileReader.onload = (fileLoadedEvent) => {
                        var base64 = fileLoadedEvent.target.result;
                        console.log(base64);
                        this.setState({ selectedFile: base64, isprofilePicUpload: true });

                    };

                    this.setState({ filename: fileName })
                    this.setState({ fileSize: fileSize })

                }
            }
        }
        catch (err) {
            console.log("File Upload Error", err)
        }
    };

    validateForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;

        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = Messages.v_EnterEmail;
        }

        if (!fields["firstName"]) {
            formIsValid = false;
            errors["firstName"] = Messages.v_EnterName;
        }

        if (!fields["lastName"]) {
            formIsValid = false;
            errors["lastName"] = Messages.v_EnterLastName;
        }

        if (!fields["phone1"]) {
            formIsValid = false;
            errors["phone1"] = Messages.v_EnterMoNo;
        }
        else if (fields["phone1"]) {
            if (!validatePhoneNumber(fields["phone1"])) {
                formIsValid = false;
                errors["phone1"] = Messages.v_EnterMoNoFormate;
            }
        }

        if (!fields["radius"]) {
            formIsValid = false;
            errors["radius"] = Messages.v_EnterRedius;
        }
        else if (fields["radius"]) {
            if (!validateFloat(fields["radius"])) {
                formIsValid = false;
                errors["radius"] = Messages.v_EnterRediusNumeric;
            }
        }


        if (!this.state.selectedRediusunit) {
            formIsValid = false;
            errors["radiusUnit"] = Messages.v_SelectRediusUnit;
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSubmit(e) {
        var postData = {};
        e.preventDefault();
        if (this.validateForm()) {
            postData['FirstName'] = this.state.userFields.firstName;
            postData['LastName'] = this.state.userFields.lastName;
            postData['PhoneNumber'] = this.state.userFields.phone1;
            postData['Radius'] = this.state.userFields.radius;
            postData['RadiusUnit'] = this.state.selectedRediusunit.value;

            console.log("==> postdata==", postData)

            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            this.updateUserDetail(postData, authToken)
        }
    }


    updateUserDetail(postData, authToken) {
        this.setState({ isActive: true })
        var URL = GET_USERPROFILE_API;
        PUTData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    if (this.state.selectedFile && this.state.isprofilePicUpload) {
                        var postData1 = {}
                        postData1['Email'] = this.state.userFields.email;
                        postData1['Base64'] = this.state.selectedFile;
                        this.uploadProfilePic(postData1, authToken);
                    } else {
                        // this.setState({ isActive: false })
                        this.props.history.goBack();
                    }
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = responseJson["Message"];
                    this.setState({ errors: errors, isActive: false });
                }
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    uploadProfilePic(postData, authToken) {
        this.setState({ isActive: true })
        var URL = POST_PROFILEPIC_API;
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ isActive: false })
                    this.props.history.goBack();
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = responseJson["Message"];
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    render() {
        return (
            <div>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Constants.LoadingMssg}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="">
                    <Header setHeaderTitle={Constants.Title_MyProfile} />
                    <section className="section">
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                            <div className="col-sm-12" style={{ marginTop: "10px" }}>
                                <center>
                                    <img src={this.state.selectedFile ? this.state.selectedFile : defaultUser} onError={(e) => (e.target.onerror = null, e.target.src = defaultUser)} style={{ borderRadius: "50px" }} width="100" height="100" ></img>
                                    <div style={{ backgroundColor: Color.THEME_COLOR, color: Color.WHITE, height: "30px", width: "30px", position: "absolute", top: "60%", left: "51%", borderRadius: "20px", cursor: "pointer" }} onClick={() => { this.base64convert() }}>
                                        <i class="fas fa-camera" style={{ fontSize: "17px", marginTop: "6px" }} onClick={this.handleEditProfile}></i>
                                    </div>
                                    <input type="file" id="imgupload" style={{ display: "none" }} name="myfile" onChange={this.handleFileInput} accept=".jpg,.bmp,.png,.jpeg" />
                                </center>
                                <br />
                            </div>

                            <div className="reg_form_main offset-lg-3 col-lg-6">
                                <p className="col-sm-12 textcolor" for="StreetAddress">
                                    Email <PutMandatoryfieldSymbol />
                                </p>
                                <input
                                    type="text"
                                    name="email"
                                    placeholder="Email"
                                    value={this.state.userFields.email}
                                // onChange={this.handleChange}
                                // onBlur={this.handleValidationOnBlur}
                                />
                                <div className="errorMsg">
                                    {this.state.errors.email}
                                </div>
                            </div>

                            <div className="reg_form_main offset-lg-3 col-lg-6">
                                <p className="col-sm-12 textcolor" for="AptUnit">
                                    First Name <PutMandatoryfieldSymbol />
                                </p>
                                <input
                                    type="text"
                                    name="firstName"
                                    placeholder="First Name"
                                    value={this.state.userFields.firstName}
                                    onChange={this.handleChange}
                                // onBlur={this.handleValidationOnBlur}
                                />
                                <div className="errorMsg">
                                    {this.state.errors.firstName}
                                </div>
                            </div>

                            <div className="reg_form_main offset-lg-3 col-lg-6">
                                <p className="col-sm-12 textcolor" for="AptUnit">
                                    Last Name <PutMandatoryfieldSymbol />
                                </p>
                                <input
                                    type="text"
                                    name="lastName"
                                    placeholder="Last Name"
                                    value={this.state.userFields.lastName}
                                    onChange={this.handleChange}
                                // onBlur={this.handleValidationOnBlur}
                                />
                                <div className="errorMsg">
                                    {this.state.errors.lastName}
                                </div>
                            </div>

                            <div className="reg_form_main offset-lg-3 col-lg-6">
                                <p className="col-sm-12 textcolor" for="PostalCode">
                                    Mobile Number <PutMandatoryfieldSymbol />
                                </p>
                                <input
                                    type="text"
                                    placeholder="Mobile Number"
                                    name="phone1"
                                    maxLength="12"
                                    value={this.state.userFields.phone1}
                                    onChange={this.handleChange}
                                // onBlur={this.handleValidationOnBlur}
                                />
                                <div className="errorMsg">
                                    {this.state.errors.phone1}
                                </div>
                            </div>

                            <div className="reg_form_main offset-lg-3 col-lg-6">
                                <p className="col-sm-12 textcolor" for="radius">
                                    Radius <PutMandatoryfieldSymbol />
                                </p>
                                <input
                                    type="text"
                                    placeholder="Radius"
                                    name="radius"
                                    value={this.state.userFields.radius}
                                    onChange={this.handleChange}
                                // onBlur={this.handleValidationOnBlur}
                                />
                                <div className="errorMsg">
                                    {this.state.errors.radius}
                                </div>
                            </div>


                            <div className="reg_form_main offset-lg-3 col-lg-6">
                                <p className="col-sm-12 textcolor" for="radiusUnit">
                                    Radius Unit <PutMandatoryfieldSymbol />
                                </p>
                                <Select
                                    value={this.state.selectedRediusunit}
                                    onChange={this.handleStateChange}
                                    options={this.state.rediusunit}
                                    styles={colourStyles}
                                    // getOptionLabel={(option) => option.statename}
                                    // getOptionValue={(option) => option._id}
                                    isDisabled
                                />
                                <div className="errorMsg">
                                    {this.state.errors.radiusUnit}
                                </div>
                            </div>

                            <div className="reg_form_main offset-lg-3 col-lg-6">
                                <div className="errorMsg">
                                    {this.state.errors.ErrorC}
                                </div>
                            </div>
                        </div>
                        <div className="bottam_sing" style={{ marginBottom: "20px" }}>
                            <label
                                for="step5"
                                id="continue-step5"
                                style={{ borderRadius: "4px" }}
                                onClick={this.handleSubmit}
                                className="btn btn-done"
                            >&nbsp;&nbsp;&nbsp;Save&nbsp;&nbsp;&nbsp;</label>
                        </div>
                    </section>
                </LoadingOverlay>
            </div>
        );
    }
}

export default withRouter(EditProfile);