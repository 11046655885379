
export const Cart = (PlazaList) => {
    return {
        PlazaList: PlazaList,
    }
}

// export const PlazaClass = (PlazaId, PlazaSubTotal, PlazaTipAmount, PlazaTotal, ServiceFeeAmount, Stores) => { 
//     return { 
//         PlazaId: PlazaId, 
//         PlazaSubTotal: PlazaSubTotal,
//         PlazaTipAmount:PlazaTipAmount,
//         PlazaTotal:PlazaTotal,
//         ServiceFeeAmount:ServiceFeeAmount,
//         Stores:Stores
//     } 
// }

// export const StoreClass = (ApplicationId, DeliveryDate, DeliveryTime, Items) => {
//     return {
//         ApplicationId: ApplicationId,
//         DeliveryDate: DeliveryDate,
//         DeliveryTime: DeliveryTime,
//         Items: Items
//     }
// }

// export const ItemClass = (CategoryID, Instruction, ItemDescription, ItemId, ItemName, ItemPrice, ItemQuantity, ItemTax, ItemType, ItemWeight, TotalItemPrice, ToppingItems) => {
//     return {
//         CategoryID: CategoryID,
//         Instruction: Instruction,
//         ItemDescription: ItemDescription,
//         ItemId: ItemId,
//         ItemName: ItemName,
//         ItemPrice: ItemPrice,
//         ItemQuantity: ItemQuantity,
//         ItemTax: ItemTax,
//         ItemType: ItemType,
//         ItemWeight: ItemWeight,
//         TotalItemPrice: TotalItemPrice,
//         ToppingItems: ToppingItems,
//     }
// }

export function PlazaClass(Obj) {
    return {
        _id: Obj.plazaid,
        CompanyName: Obj.plazaname
    }
}

export function StoreClass(Obj) {
    return {
        "ObjectId": Obj.storeid,
        "ApplicationId": Obj.applicationguid,
        "ApplicationName": Obj.storename,
        "AppLogo": Obj.AppLogo,
        "AppBannerImage": Obj.AppBannerImage,
        "AppAddress": Obj.StreetAddress,
        "WebAppid": 0,
        "DeliveryCharges": "0",
        "ConvenienceFee": 0,
        "ShippingCharges": 0,
        "Taxes": 0,
        // "Distance": "508 mi",
        // "Duration": "6 days 22 hours",
        "Deliverytimes": Obj.Deliverytimes,
        "Pickuptimes": Obj.Pickuptimes,
        "StoreOpeningTimes": null,
        "isshopifyintegration": false,
        "iswixintegration": false,
        "shopifystorename": "undefined",
        "shopifyaccesstoken": null,
        "isshopifypostorderenable": false,
        "shopifystoreid": null,
        "IsPickupEnable": Obj.IsPickupEnable,
        "IsDeliveryEnable": Obj.IsDeliveryEnable
    }
}

export function ItemClass(Obj) {
    return {
        // "ApplicationID": "000000000000000000000000",
        "CategoryID": Obj.CategoryID ? Obj.CategoryID : "000000000000000000000000",
        "ItemID": Obj._id,
        "ItemName": Obj.ItemName,
        "RewardPoints": 0,
        "ItemLogo": Obj.ItemLogo,
        "ItemSizeColor": Obj.ItemSizeColor ? Obj.ItemSizeColor : [],
        "ItemPrice": Obj.ItemPrice,
        "IsTryItEnable": Obj.IsTryItEnable,
        "ItemDescription": Obj.ItemDescription,
        // "isCustomizable": true,
        "TryItCustomizationMessage": "",
        "ProductType": Obj.ProductType,
        "WeightType": Obj.WeightType,
        "Weight": Obj.Weight,
        "IsProductTax": Obj.IsProductTax,
        "IsSpecialDeliveryItem": Obj.IsSpecialDeliveryItem,
        "tax": Obj.tax,
        "ToppingCategory": Obj.ToppingCategory ? Obj.ToppingCategory : [],
        "toppingminimumquantity": Obj.toppingminimumquantity,
        "toppingmaximumquantity": Obj.toppingmaximumquantity,
        "TrackQuantity": Obj.TrackQuantity,
        "ContinueSelling": Obj.ContinueSelling,
        "SKU": Obj.SKU,
        "BarCode": Obj.BarCode,
        "AvailableQuantity": Obj.AvailableQuantity ? Obj.AvailableQuantity : 0,
        "Sizes": Obj.Sizes ? Obj.Sizes : [],
        "colors": Obj.colors ? Obj.colors : [],
        // "Others": "",
        "specialdeliverycharges": Obj.specialdeliverycharges
    }
}   