const Messages = {
    v_EnterName: "*Please enter your name",
    v_EnterValidName: "*Please enter alphabet characters only",
    v_EnterLastName: "*Please enter your lastname",
    v_EnterEmail: "*Please enter your email",
    v_EnterPassword: "*Please enter your password",
    v_EnterPhoneNo: "*Please enter your phone number",
    v_EnterMoNo: "*Please enter your phone number",
    v_EnterConfirmPassword: "*Please enter confirm password",
    v_EnterCreateNewPassword: "*Please enter your new password",
    v_EnterCreateConfirmPassword: "*Please enter your new confirm password",
    v_EnterRedius: "*Please enter Radius",
    v_EnterRediusNumeric: "*Please enter numerical Radius",
    v_SelectRediusUnit: "*Please Select Radius Unit",
    v_EnterValidEmail: "*Please enter valid email address",
    v_ConfirmPwdMatchWithPwd: "*Confirm Password must match to the entered password",
    v_EnterValidPassword: "*Password must be 8 characters long with one number, one special character and one upper case letter",
    v_EnterValidPhoneNo: "*Please enter valid phone number",
    v_EnterOnlyNumber: "*Please enter only number",
    v_EnterOTP: "*Please enter your one time code",
    v_EnterValidOTP: "*One time code must be 6 digit number",
    v_EnterPostalCode: "*Please enter postal code",
    v_EnterValidPostalCode: "*Please enter valid postal code",
    v_EnterCountryName: "*Please enter country name",
    v_EnterPlaza: "*Please select plaza name",
    NO_DATA: "Data not found. Please try again later.",
    ERR_SomethingWentWrong: "Something went wrong. Please try again later.",
    PAYMENTPROCESSING: "Your payment is processing.",
    REQUIRES_PAYMENT_METHOD: "Your payment was not successful, please try again.",
    MSG_CHECK_EMAIL: "Please check your email.",
    S_NAME: "Enter brewery name",
    S_VALID_NAME: "Enter valid brewery name",
    S_CATE: "Select brewery category",
    S_WEBSITE_URL: "Enter brewery website url",
    S_FACEBOOK: "Enter facebook user",
    S_DEC: "Enter your brewery description",
    S_CITY: "Enter city",
    S_ADDRESS: "Enter brewery adddress",
    S_LOGO: "Select brewery profile image",
    S_BANNER: "Select brewery banner image",
    NO_PLAZA: "There is no plaza listed in this postal code, try other postal code.",
    NO_USER_PLAZA: "There is no plaza listed in this postal code, try other postal code.",
    GUEST_USER_REQUIRED_FIELDS: "Please enter the required guest user fields",

    v_EnterStreetAddress: "*Please select your street address.",
    v_EnterAptUnit: "*Please enter your apartment or unit.",

    v_EnterTip: "*Please enter tip amount.",
    v_SelectDateAndTime: "*Please select date and time",
    v_deliveryAddress: "*Please select delivery address",
    v_PickupTime: "*Service of curbside pickup is not available for this Brewery.",
    v_DeliveryTime: "*Delivery service is not available for this Brewery.",
    v_Address: "*Please type your address.",
    v_Valid_Address: "*Please enter address in the same state as store",
    v_EnterdeliveryAddress: "*Please enter delivery address",

    cart_Title: "Cart will be empty",
    cart_Messg: "If you exit now the cart will be empty",

    v_EnterMoNoFormate: "*Please enter proper phone number",
    v_ProductSearch: "Enter more then 3 characters",
    v_Terms_And_Policy: "Before you continue, kindly accept the Privacy Policy and all of the Terms Of Service.",
    v_ForgotPaswword_Email: "We did not recognise this email address.",
    v_Order_Related_Message: 'Enter message for brewery',
    appointmentm1: "Your appointment will be confirmed once the",
    appointmentm2: "accepts it.",
    appointmentm3: "The request for a schedule appointment was successfully submitted.",
    appointmentm4: "The appointment has been accepted and confirmed by",

    // 5-apr-2023
    v_City: "*Please enter city",
    v_State: "*Please enter state",
    v_ZIP_PostalCode: "*Please enter zip / postal code",
    v_dAddress1: "*Please select address line 1",
    v_EmailDuplication: "Email ID already exists",
    s_successMsgPlazaCartDelivery: "BrewersNearMe delivery user register successful",
    v_EnterCaptchacode: "*Please enter the code shown below",
    v_EnterValidCaptchacode: "*Please enter the valid code shown below",
    v_EnterFullName: "*Please enter your name",
    v_ContactUS_message: "Thank you,We have received your request and will get back to you soon.",
    v_EnterCaptcha: "*Please enter captcha code",
    v_EnterValidCaptcha: "*Please enter valid captcha code",
    v_EnterTip1: "*Please enter proper tip amount.",
    v_productQun: "You cannot add more quantities of this product",
    //

    AddtoCart: "Product has been added to your cart",
    emptyCart: "Are you sure you want to remove the items you've added to your cart?",
    DoordashVaidationMsg: "Since DoorDash is currently handling your order, please adjust the total amount of your order to ##RATE## or below.", // either switch the delivery method to curbside or
    PlazacartValidationMsg: "Orders with a value exceeding ##RATE## will not be accepted by brewers near me. We apologize for any inconvenience."
};

export default Messages;