import React, { Component } from 'react';
import Constants from '../Config/Constants'
import Color from '../Config/Color';
import { withRouter } from 'react-router';
import DataTable from 'react-data-table-component'
import 'react-data-table-component-extensions/dist/index.css';
import Checkbox from 'react-simple-checkbox';
import ToppingItems from './ToppingItems';

var toppingItems = [];

class ToppingList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toppingCategory: this.props.data,
            checked: false,
            toggledClearRows: false,
            removeObj: {},
            selectedToppings: []
        }
        //this.handleCheck = this.handleCheck.bind(this);
    }

    componentDidMount() {
    }


    // handleCheck = (state) => {
    //     //alert(JSON.stringify(state.selectedRows))
    //     //console.log('Selected Rows: ', state.selectedRows);        

    //     // var newItem = this.props.data.find((o) => o._id !== this.state.itemId)
    //     // var currentItem = {
    //     //     currentObj:state.selectedRows[0],
    //     //     list:state.selectedRows 
    //     // } 

    //     // this.props.setToppingObj(state.selectedRows[0], function(){
    //     //     this.props.setToppingList(state.selectedRows)  
    //     // })

    //      this.props.setToppingObj(currentItem)
    //     // this.props.setToppingList(state.selectedRows)        
    // };



    handleSetTopping = (items) => {
        this.props.setToppingObj(items)
    }

    handleSetTopping1 = (items) => {
        this.props.setToppingObj1(items)
    }

    handleCheck(data, toppingItemObj) {
        console.log(JSON.stringify(toppingItemObj))
        var toppingItems = [...data];
        var index = toppingItems.findIndex(obj => obj._id === toppingItemObj._id);
        //console.log(toppingItems[index].checked)
        // if (!toppingItems[index].checked) {
        //     toppingItems[index].checked = true;
        //     selectedList.push(toppingItemObj)
        //     console.log("selectedList1", selectedList)
        // } else {
        //     toppingItems[index].checked = false;
        //     selectedList.splice(selectedList.indexOf(toppingItemObj), 1);
        //     console.log("selectedList1 1", selectedList)
        // }
        toppingItems = toppingItems.map(e => {
            if (e._id === toppingItemObj._id) {
                return { ...e, "checked": !e.checked }
            }
            return { ...e }
        })

        var selectedList = toppingItems.filter(e => e.checked)

        this.setState({ toppingItems }, function () {
            //console.log("toppingItems ",this.state.toppingItems)
            var selectedItems = toppingItems.filter(obj => obj.checked === true);
            var currentItem = {
                currentObj: toppingItemObj,
                list: toppingItems,
                selectedList: selectedList
            }
            // console.log("selected ", selectedItems)
            this.props.setToppingObj(currentItem)
        });
    }


    renderToppingItem(toppingItems) {
        return toppingItems
            .map(function (item, index) {
                return (
                    <React.Fragment key={index} >
                        <div className="col-sm-12" style={{ backgroundColor: Color.WHITE, height: "40px", color: Color.BLACK, borderBottom: "1px solid" + Color.GREY }} >
                            <div className="row" style={{ padding: "5px" }} >
                                <Checkbox
                                    name={item._id}
                                    style={{ marginTop: "0px", paddinTop: "0px" }}
                                    color={Color.THEME_COLOR}
                                    size={2}
                                    tickSize={2}
                                    checked={item.checked}
                                    delay={0.1}
                                    //value={this.state.checked}
                                    //onClick={(value) => this.handleItemClick(item)}
                                    onChange={(value) => this.handleCheck(toppingItems, item)}>
                                </Checkbox>
                                <label style={{ marginLeft: "10px", fontSize: "15px" }}>{item.ToppingItem}</label>
                                <span style={{ marginLeft: "auto" }}>
                                    <label style={{ fontSize: "15px", float: "right", marginRight: "5px" }}>${(Math.round(item.Price * 100) / 100).toFixed(2)}</label>
                                </span>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }, this);
    }


    render() {
        let list = this.props.data
            .map(function (item, index) {
                var toppingCategoryName = item.ToppingCategory;
                var toppingItems = item.ToppingItems;
                return (
                    <React.Fragment key={index} >
                        <div className="col-sm-12" style={{ backgroundColor: Color.THEME_COLOR, color: Color.WHITE }} >
                            {toppingCategoryName}
                        </div>
                        {this.renderToppingItem(toppingItems)}
                    </React.Fragment>
                )
            }, this);

        return (
            <div style={{ marginTop: "10px" }}>
                {list}
            </div>
        );
    }
}

export default withRouter(ToppingList);