import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter, Route, Link, Routes } from 'react-router-dom';
import { Prompt, useLocation, useHistory } from "react-router-dom";
import Color from '../Config/Color';
import './NavConFirmAlert.css'
import RouteConstant from "../Config/RouteConstant";
import MasterContextCreater from '../MasterContextProvider/MasterContextCreater'

const NavConFirmAlert = ({ title, bodyContent, isBlocked, btn1Label, btn2Label, conformFun }) => {
    const masterContxt = useContext(MasterContextCreater)
    const [isModalOpen, setIsModalOpen] = useState(false);

    const location = useLocation();
    const history = useHistory();
    const [lastLocation, setLastLocation] = useState(location);
    const [shouldUnload, setShouldUnload] = useState(false);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);
    const closeModal = () => {
        setIsModalOpen(false);
        setShouldUnload(false);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const showModal = (nextLocation) => {
        openModal();
        setLastLocation(nextLocation);
    };

    const handleBlockedRoute = (nextLocation) => {
        let currentLocation = location.pathname
        let nextPath = nextLocation.pathname
        let currentLocArr = [RouteConstant.PlazaStore, RouteConstant.PlazaStoreItems, RouteConstant.AddToCart, RouteConstant.MyCart, RouteConstant.Checkout, RouteConstant.Payment, RouteConstant.OrderDetail, RouteConstant.MyAppointment, RouteConstant.CurbsideOrder, RouteConstant.AddAddress, RouteConstant.EditProfile, RouteConstant.PlazaStoreItemsShopify, RouteConstant.NotificationDetail, RouteConstant.TryIt]
        let nextPatharr = [RouteConstant.Country]
        var validation1 = currentLocArr.includes(currentLocation)
        var validation2 = nextPatharr.includes(nextPath)
        if (!confirmedNavigation && (isBlocked || masterContxt.isnavalert) && (validation1 && validation2)) {
            showModal(nextLocation);
            return false;
        }

        return true;
    };

    const handleConfirmNavigationClick = () => {
        if (typeof conformFun == "function") {
            conformFun()
        }
        closeModal();
        setConfirmedNavigation(true);
    };

    // Block react routes
    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            // Navigate to the previous blocked location with your navigate function
            setShouldUnload(true);
            history.push(lastLocation.pathname);
        }
    }, [confirmedNavigation, lastLocation, history]);

    // Block non-react routes
    useEffect(() => {
        const unload = (event) => {
            if (isBlocked && !shouldUnload) {
                // eslint-disable-next-line no-param-reassign
                // event.returnValue = "";
            }
            if (shouldUnload) {
                // eslint-disable-next-line no-param-reassign
                event.returnValue = "";
            }
        };
        window.addEventListener("beforeunload", unload);

        return () => window.removeEventListener("beforeunload", unload);
    }, [isBlocked, bodyContent, shouldUnload]);

    return (
        <>
            <Prompt when message={handleBlockedRoute} />
            {isModalOpen && (<>
                <div className="modalBox"></div>
                <div class="modal" style={{ display: isModalOpen ? "block" : "none" }}>
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">{title ? title : ""}</h5>
                                <button type="button" class="close" onClick={closeModal}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <p>{bodyContent ? bodyContent : ""}</p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" style={{ background: Color.THEME_COLOR }}
                                    onClick={handleConfirmNavigationClick}>{btn1Label ? btn1Label : "Confirm"}</button>
                                <button type="button" class="btn btn-secondary" style={{ background: Color.THEME_COLOR }} onClick={closeModal}>{btn2Label ? btn2Label : "Cancel"}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            )}
        </>
    );

}

export default NavConFirmAlert


