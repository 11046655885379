import React, { Component } from 'react';
import Color from '../Config/Color';
import Header from '../Header/Header';

import ScrollMenu from 'react-horizontal-scrolling-menu';
import RouteConstant from '../Config/RouteConstant';
import { MainContext, StoreDataInStorage, RetriveObjectFromStorage, RetriveDataFromStorage, StoreObjectInStorage } from '../Config/Utils';
import Constants, { currencyInfo } from '../Config/Constants';
import Messages from '../Config/Messages';


import { GET_STOREITEM_API } from '../Config/Config.json'
import { GetData } from "../../services/GetData";
//https://mdbootstrap.com/docs/react/utilities/position/
import { PlazaClass, StoreClass, ItemClass } from '../Config/PlazaClass';
import AddToppings from '../Toppings/AddToppings';
import LoadingOverlay from 'react-loading-overlay';

const list = [
    {
        id: "1",
        ItemName: "Hop City Barking Squirrel Larger",
        Price: "3.20",
        Image: "https://webaven.s3.amazonaws.com/48b76318-9b97-4d5b-b307-1fbb77f007c8/images/Store/beer1.jpeg"
    },
    {
        id: "2",
        ItemName: "Mill Street 100th Meridian Organic",
        Price: "3.35",
        Image: "https://webaven.s3.amazonaws.com/48b76318-9b97-4d5b-b307-1fbb77f007c8/images/Store/beer2.jpeg"
    },
    {
        id: "3",
        ItemName: "Von Bungle Munich Lager",
        Price: "2.65",
        Image: "https://webaven.s3.amazonaws.com/48b76318-9b97-4d5b-b307-1fbb77f007c8/images/Store/beer3.jpeg"
    },
    {
        id: "4",
        ItemName: "Woodhouse Lager",
        Price: "3.10",
        Image: "https://webaven.s3.amazonaws.com/48b76318-9b97-4d5b-b307-1fbb77f007c8/images/Store/beer4.jpeg"
    },
    {
        id: "5",
        ItemName: "Almos Malbec",
        Price: "14.20",
        Image: "https://webaven.s3.amazonaws.com/48b76318-9b97-4d5b-b307-1fbb77f007c8/images/Store/ww1.jpeg"
    },
    {
        id: "6",
        ItemName: "Moet & Chandon Brut Rose Champagne",
        Price: "85.95",
        Image: "https://webaven.s3.amazonaws.com/48b76318-9b97-4d5b-b307-1fbb77f007c8/images/Store/ww2.jpeg"
    },
    {
        id: "7",
        ItemName: "Silent Sam Vodka",
        Price: "85.95",
        Image: "https://webaven.s3.amazonaws.com/48b76318-9b97-4d5b-b307-1fbb77f007c8/images/Store/v3.jpeg"
    },
    {
        id: "8",
        ItemName: "Veuve Clicquot Demi Sec Champagne",
        Price: "81.10",
        Image: ""
    },
];

// One item component
// selected prop will be passed

var currencySymbole = ""
var countryFees = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES)
if (countryFees) {
    currencySymbole = (countryFees[currencyInfo.CountryCode] ?
        "(" + countryFees[currencyInfo.CountryCode] + ") " : "") + countryFees.Symbol

}

// const MenuItem = ({ text, selected }) => {
//     return <div className={`menu-item ${selected ? 'active' : ''}`} >
//         <div style={{ width: "unset", height: "150px", marginBottom: "5px" }}>
//             <img src={text.ItemLogo} style={{ width: "80px", height: "100px" }}></img>
//             <br />
//             <label className="textcolor" style={{ textOverflow: "ellipsis", overflow: "hidden", width: "100px" }}>{text.ItemName}</label>
//             <br />
//             <label className="textcolor">{currencySymbole} {Number(text.ItemPrice).toFixed(2)}</label>
//         </div>

//     </div>;
// };

// All items component
// Important! add unique key
// export const Menu = (list, selected) =>
//     list.map(el => {
//         const { ItemID } = el;
//         return <MenuItem text={el} key={ItemID} selected={selected} />;
//     });

//const selected = '';

class AddCart extends Component {
    static contextType = MainContext;
    constructor(props) {
        super(props);
        this.state = {
            itemId: "",
            itemPrice: "",
            labelItemPrice: "",
            totalPrice: "",
            itemImage: "",
            itemCount: 1,
            IsTryItEnable: false,
            title: "",
            selected: '',

            storeData: {},
            plazaData: {},

            storeItemList: [], // array to display bottom item list
            countCartItem: 0,

            cartItems: [],

            cartStore: [], // use to make final cart list
            itemsInCart: [], // use to make final cart list

            finalCart: [],

            toppingItems: [],

            showToppingBtn: "hidden",

            selectedItemObj: {},

            selectedTopping: {},

            selectedToppingList: [],

            specialdeliverycharges: "0.0",

            showAddToppingView: false,
            isActive: false,

            toppingCharge: 0.0,
            AvailableQuantity: 0,
            ContinueSelling: false,
            specialDelivery: false,
            IsDeliveryEnableStore: false,

            selectedItemSize: "",
            selectedItemColor: ""
        }
        this.handleAddToCart = this.handleAddToCart.bind(this);
        this.handleMinus = this.handleMinus.bind(this);
        this.handlePlus = this.handlePlus.bind(this);

        this.countTotal = this.countTotal.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.addSelectedToppings = this.addSelectedToppings.bind(this);
        this.handleAddToppings = this.handleAddToppings.bind(this);
    }

    componentDidMount() {

        //const context = this.context;
        // this.setState({ finalCart: context.cart }, function(){
        //     console.log("finalCart ", this.state.finalCart)
        //     //this.setState({cartStore})
        // });

        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var objPlaza = RetriveObjectFromStorage(Constants.Obj_PLAZA, '');
        var objStore = RetriveObjectFromStorage(Constants.Obj_STORE, '');
        console.log("objPlaza ", objPlaza);
        console.log("objStore ", objStore);

        var objStoreItems = RetriveObjectFromStorage(Constants.Obj_STORE_ITEMS, '');
        console.log("objStoreItems ", objStoreItems);

        if (objStoreItems) {
            this.setState({ title: objStoreItems.ItemName })
            this.setState({ itemId: objStoreItems.ItemID })
            this.setState({
                itemPrice: objStoreItems.ItemPrice,
                selected: objStoreItems.ItemID
            })
            this.setState({ labelItemPrice: objStoreItems.ItemPrice })
            this.setState({ totalPrice: objStoreItems.ItemPrice })
            this.setState({ itemImage: objStoreItems.ItemLogo })
            this.setState({ IsTryItEnable: objStoreItems.IsTryItEnable })
            this.setState({ AvailableQuantity: objStoreItems.AvailableQuantity })
            this.setState({ ContinueSelling: objStoreItems.ContinueSelling })
            this.setState({
                selectedItemObj: objStoreItems
            })

            if (objStoreItems.ToppingCategory && objStoreItems.ToppingCategory.length > 0) {
                this.setState({ toppingItems: objStoreItems.ToppingCategory })
                this.setState({ showToppingBtn: "visible" })
                /*    this.setState({
                       selectedItemObj: objStoreItems
                   }) */
            }

            if (objStoreItems.Sizes) {
                this.setState({ selectedItemSize: objStoreItems.Sizes[0] ? objStoreItems.Sizes[0] : "" })
            }

            if (objStoreItems.colors) {
                this.setState({ selectedItemColor: objStoreItems.colors[0] ? objStoreItems.colors[0] : "" })
            }

            if (objStoreItems.IsSpecialDeliveryItem) {
                this.setState({ specialDelivery: objStoreItems.IsSpecialDeliveryItem })
                this.setState({ specialdeliverycharges: objStoreItems.specialdeliverycharges })
            }
        }

        if (objPlaza && objStore) {
            this.setState({ plazaData: objPlaza })
            this.setState({ storeData: objStore, IsDeliveryEnableStore: objStore.IsDeliveryEnable })
            this.getStoreItemsAPI(authToken, objPlaza._id, objStore.ObjectId);
        }

        var globalFinalCart = RetriveObjectFromStorage(Constants.Obj_CART, '')
        if (globalFinalCart) {
            this.setState({ finalCart: globalFinalCart }, function () {
                console.log("finalCart ", this.state.finalCart);
                this.setHeaderItemCount(globalFinalCart)
            })
        }


        if (objPlaza && objStore && globalFinalCart && objStoreItems) {
            var selectedItemObj = objStoreItems;
            var itemPrice = objStoreItems.ItemPrice;
            var totalPrice = objStoreItems.ItemPrice
            globalFinalCart.map(cartObj => {
                if (cartObj.PlazaId === objPlaza._id) {
                    cartObj.Stores.map(cartStore => {
                        if (cartStore.ObjectId === objStore.ObjectId) {
                            cartStore.Items.map(cartItem => {
                                if (cartItem.ItemId === objStoreItems.ItemID) {
                                    this.setState({ selectedToppingList: cartItem.ToppingItems })
                                    this.setState({ itemCount: cartItem.ItemQuantity })
                                    this.setState({ selectedToppingList: cartItem.ToppingItems })
                                    this.setState({
                                        selectedItemSize: cartItem.ItemSize,
                                        selectedItemColor: cartItem.itemColor
                                    })

                                    for (var withtopinngs of cartItem.ToppingItems.slice(3, cartItem.ToppingItems.length)) {
                                        // alert(JSON.stringify(withtopinngs.Price))
                                        itemPrice = itemPrice + withtopinngs.Price;
                                    }
                                    totalPrice = itemPrice * cartItem.ItemQuantity;
                                    // this.setState({ toppingCharge: 0.0 })
                                    // this.addToppingCharges(cartItem)
                                    selectedItemObj = {
                                        ...selectedItemObj,
                                        "ToppingCategory": objStoreItems.ToppingCategory.map(toppingcategory => {
                                            var ToppingItems = toppingcategory.ToppingItems.map(toppingObj => {
                                                var selectedTopping = cartItem.ToppingItems.filter(e1 => e1._id === toppingObj._id)[0]
                                                if (selectedTopping) {
                                                    return selectedTopping
                                                }
                                                return toppingObj
                                            })
                                            return { ...toppingcategory, "ToppingItems": ToppingItems }
                                        })
                                    }
                                }
                            })
                        }
                    })
                }
            })
            this.setState({ itemPrice: itemPrice })
            this.setState({ totalPrice: totalPrice })
            this.setState({ selectedItemObj: selectedItemObj })
        }

    }

    setHeaderItemCount(globalFinalCart) {
        var countItems = 0;
        if (globalFinalCart.length > 0) {
            globalFinalCart.map(itemPlaza => {
                var storelist = itemPlaza.Stores;
                storelist.map(itemStore => {
                    var itemlist = itemStore.Items;
                    countItems = countItems + itemlist.length;
                })
            })
            this.setState({ countCartItem: countItems })
        }
    }

    getStoreItemsAPI(authToken, plazaID, storeID) {
        this.setState({ isActive: true });
        var API = GET_STOREITEM_API.replace("#STOREID#", storeID);
        var URL = API.replace("#TENANTID#", plazaID);

        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData getStoreItemsAPI  ", responseData);
                    if (responseData && responseData.length > 0) {
                        var list = [];
                        responseData.map(function (item, index) {
                            var itemlist = item.Items;
                            itemlist.map(function (item1, index1) {
                                item1.CategoryID = item.CategoryId;
                            })
                            list = list.concat(itemlist)
                        })
                        this.setState({ storeItemList: list }, function () {
                            console.log("123 ", this.state.storeItemList)
                        })
                    }
                }
                else {
                    this.setState({ hideEmptyPlazaView: false });
                    alert(Messages.ERR_SomethingWentWrong);
                }
                this.setState({ isActive: false });
            });
    }

    setPlazaStore(list) {
        this.setState({ cartStore: list });

        if (objStoreItems.IsSpecialDeliveryItem) {
            this.setState({ specialdeliverycharges: objStoreItems.specialdeliverycharges })
        }

    }

    // /////////////////////////////////// end detail method /////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////

    handleMinus() {
        var count = this.state.itemCount
        var price = this.state.totalPrice / count
        if (count !== 1) {
            count = count - 1;
            //this.setState({itemCount:count})

            this.setState({
                itemCount: count
            }, () => {
                this.countTotal(this.state.itemCount, price);
            });
        }

    }

    handlePlus() {
        var count = this.state.itemCount
        var price = this.state.totalPrice / count
        count = count + 1;
        if (this.state.ContinueSelling) {
            this.setState({
                itemCount: count
            }, () => {
                this.countTotal(this.state.itemCount, price);
            });
        } else {
            if (this.state.AvailableQuantity >= count) {
                this.setState({
                    itemCount: count
                }, () => {
                    this.countTotal(this.state.itemCount, price);
                });
            }
        }


    }

    countTotal(count, price) {
        // var price = this.state.itemPrice
        price = price * count;
        price = (Math.round(price * 100) / 100).toFixed(2);
        this.setState({ totalPrice: price })
    }


    handleAddToCart(e) {
        e.preventDefault();
        var postData = {};

        // alert(JSON.stringify(storeItemObj))

        const found = this.state.cartItems.some(el => el.ItemID === this.state.itemId);
        // alert(found)
        if (!found) {

            var globalFinalCart = RetriveObjectFromStorage(Constants.Obj_CART, '')
            if (globalFinalCart) {
                var countItems = this.state.countCartItem;
                globalFinalCart.map(itemPlaza => {
                    var storelist = itemPlaza.Stores;
                    storelist.map(itemStore => {
                        var itemlist = itemStore.Items.some(el => el.ItemId === this.state.itemId);
                        if (!itemlist) {
                            var count = this.state.countCartItem;
                            count = count + 1
                            this.setState({
                                countCartItem: count,
                            });
                        }

                    })
                })

            }
            else {
                var count = this.state.countCartItem;
                count = count + 1
                this.setState({
                    countCartItem: count,
                });
            }


            var newItem = {}
            newItem = this.state.storeItemList.find((o) => o.ItemID === this.state.itemId)
            newItem["noOfItems"] = this.state.itemCount
            newItem["ToppingItems"] = this.state.selectedToppingList
            newItem["totalPrice"] = this.state.totalPrice

            this.setState(prevState => ({
                cartItems: [...prevState.cartItems, newItem]
            }), function () {
                console.log(this.state.cartItems)
                StoreObjectInStorage(Constants.Obj_CART_ITEMS, this.state.cartItems)
                this.createCartItemsObject(this.state.plazaData, this.state.storeData, newItem);
            })
        } else {

            var newItem = this.state.cartItems.find((o) => o.ItemID === this.state.itemId)
            newItem["noOfItems"] = this.state.itemCount;
            newItem["ToppingItems"] = this.state.selectedToppingList
            newItem["totalPrice"] = this.state.totalPrice

            this.setState({
                cartItems: this.state.cartItems.map(el => (el.ItemID === this.state.itemId ? Object.assign({}, newItem, el) : el))
            }, function () {
                console.log(this.state.cartItems)
                StoreObjectInStorage(Constants.Obj_CART_ITEMS, this.state.cartItems)
                this.createCartItemsObject(this.state.plazaData, this.state.storeData, newItem);
            });
        }
    }

    createCartItemsObject(plazaData, storeData, itemData) {
        var finalCart = this.state.finalCart;
        if (finalCart.length > 0) {
            const plazaExist = finalCart.some(el => el.PlazaId === plazaData._id);
            // alert(plazaExist)
            if (!plazaExist) {
                console.log('plaza not exist')
                var objPlaza = {};
                objPlaza["PlazaId"] = plazaData._id;
                objPlaza["CompanyName"] = plazaData.CompanyName;
                objPlaza["Imageurl"] = plazaData.Imageurl;
                objPlaza["PlazaSubTotal"] = "";
                objPlaza["PlazaTipAmount"] = 0;
                objPlaza["PlazaTotal"] = "";
                objPlaza["ServiceFeeAmount"] = 0;

                var storeArr = [];
                var objStore = {};
                objStore["ObjectId"] = storeData.ObjectId;
                objStore["ApplicationId"] = storeData.ApplicationId;
                objStore["ApplicationName"] = storeData.ApplicationName;
                objStore["Deliverytimes"] = storeData.Deliverytimes ? storeData.Deliverytimes : [];
                objStore["Pickuptimes"] = storeData.Pickuptimes ? storeData.Pickuptimes : [];
                objStore["Taxes"] = storeData.Taxes;
                objStore["DeliveryDate"] = "";
                objStore["DeliveryTime"] = "";
                objStore["isshopifyintegration"] = storeData.isshopifyintegration // new key
                objStore["iswixintegration"] = storeData.iswixintegration // new key
                objStore["IsPickupEnable"] = storeData.IsPickupEnable // new key
                objStore["IsDeliveryEnable"] = storeData.IsDeliveryEnable // new key


                var itemArr = [];
                var objItem = {};
                objItem["CategoryID"] = itemData.CategoryID;
                objItem["Instruction"] = `${this.state.selectedItemSize ? "Size:" + this.state.selectedItemSize : ""}${this.state.selectedItemColor ? ",Color:" + this.state.selectedItemColor : ""}`;
                objItem["ItemDescription"] = itemData.ItemDescription;
                objItem["ItemId"] = itemData.ItemID;
                objItem["ItemName"] = itemData.ItemName;
                objItem["ItemLogo"] = itemData.ItemLogo;
                objItem["ItemPrice"] = itemData.ItemPrice;
                objItem["ItemQuantity"] = itemData.noOfItems;
                objItem["ItemTax"] = itemData.tax;
                objItem["ItemType"] = itemData.ProductType;
                objItem["ItemWeight"] = itemData.Weight;
                // objItem["TotalItemPrice"] = (itemData.noOfItems * itemData.ItemPrice);
                objItem["TotalItemPrice"] = itemData.totalPrice
                objItem["IsSpecialDeliveryItem"] = itemData.IsSpecialDeliveryItem;
                objItem["specialdeliverycharges"] = itemData.specialdeliverycharges * itemData.noOfItems

                objItem["ToppingItems"] = itemData.ToppingItems
                objItem["AvailableQuantity"] = itemData.AvailableQuantity
                objItem["ContinueSelling"] = itemData.ContinueSelling
                objItem["variant_id"] = ""
                objItem["ItemSize"] = this.state.selectedItemSize
                objItem["itemColor"] = this.state.selectedItemColor
                objItem["IsProductTax"] = itemData.IsProductTax
                //objItem["ToppingItems"] = itemData.ToppingCategory;
                // if(itemData.ToppingCategory.length > 0){
                //     objItem["ToppingItems"] = itemData.ToppingItems;
                // }else{
                //     objItem["ToppingItems"] = itemData.ToppingCategory;
                // }


                itemArr.push(objItem);
                objStore["Items"] = itemArr;
                storeArr.push(objStore)
                objPlaza["Stores"] = storeArr
                finalCart.push(objPlaza);
            }
            else {
                console.log('plaza already exist')

                const that = this;

                finalCart.map(function (plazaObj, index1) {

                    if (plazaObj.PlazaId === plazaData._id) {
                        var plazaStore = plazaObj.Stores;
                        if (plazaStore.length > 0) {
                            plazaStore.map(function (storeObj, index1) {
                                var store = storeObj;
                                const storeExist = plazaStore.some(el => el.ObjectId === storeData.ObjectId);
                                if (!storeExist) {
                                    console.log('store not exist')
                                    var objStore = {};
                                    objStore["ObjectId"] = storeData.ObjectId;
                                    objStore["ApplicationId"] = storeData.ApplicationId;
                                    objStore["ApplicationName"] = storeData.ApplicationName;
                                    objStore["Deliverytimes"] = storeData.Deliverytimes ? storeData.Deliverytimes : [];
                                    objStore["Pickuptimes"] = storeData.Pickuptimes ? storeData.Pickuptimes : [];
                                    objStore["Taxes"] = storeData.Taxes;
                                    objStore["DeliveryDate"] = "";
                                    objStore["DeliveryTime"] = "";
                                    objStore["isshopifyintegration"] = storeData.isshopifyintegration // new key
                                    objStore["iswixintegration"] = storeData.iswixintegration // new key
                                    objStore["IsPickupEnable"] = storeData.IsPickupEnable // new key
                                    objStore["IsDeliveryEnable"] = storeData.IsDeliveryEnable // new key


                                    var arr = [];
                                    var objItem = {};
                                    objItem["CategoryID"] = itemData.CategoryID;
                                    objItem["Instruction"] = `${that.state.selectedItemSize ? "Size:" + that.state.selectedItemSize : ""}${that.state.selectedItemColor ? ",Color:" + that.state.selectedItemColor : ""}`;
                                    objItem["ItemDescription"] = itemData.ItemDescription;
                                    objItem["ItemId"] = itemData.ItemID;
                                    objItem["ItemName"] = itemData.ItemName;
                                    objItem["ItemLogo"] = itemData.ItemLogo;
                                    objItem["ItemPrice"] = itemData.ItemPrice;
                                    objItem["ItemQuantity"] = itemData.noOfItems;
                                    objItem["ItemTax"] = itemData.tax;
                                    objItem["ItemType"] = itemData.ProductType;
                                    objItem["ItemWeight"] = itemData.Weight;
                                    //objItem["TotalItemPrice"] = (itemData.noOfItems * itemData.ItemPrice);
                                    objItem["TotalItemPrice"] = itemData.totalPrice
                                    objItem["IsSpecialDeliveryItem"] = itemData.IsSpecialDeliveryItem;
                                    objItem["specialdeliverycharges"] = itemData.specialdeliverycharges * itemData.noOfItems


                                    objItem["ToppingItems"] = itemData.ToppingItems
                                    objItem["AvailableQuantity"] = itemData.AvailableQuantity
                                    objItem["ContinueSelling"] = itemData.ContinueSelling
                                    objItem["variant_id"] = ""
                                    objItem["ItemSize"] = that.state.selectedItemSize
                                    objItem["itemColor"] = that.state.selectedItemColor
                                    objItem["IsProductTax"] = itemData.IsProductTax
                                    //objItem["ToppingItems"] = itemData.ToppingCategory;
                                    // if (itemData.ToppingCategory.length > 0) {
                                    //     objItem["ToppingItems"] = itemData.ToppingItems;
                                    // } else {
                                    //     objItem["ToppingItems"] = itemData.ToppingCategory;
                                    // }

                                    arr.push(objItem)
                                    objStore["Items"] = arr;
                                    plazaStore.push(objStore);
                                }
                                else {
                                    console.log('store already exist')
                                    if (store.ObjectId === storeData.ObjectId) {
                                        var storeItems = store.Items
                                        if (store.Items.length > 0) {
                                            const itemExist = storeItems.some(el => el.ItemId === itemData.ItemID);
                                            if (!itemExist) {
                                                console.log('item not exist')
                                                var objItem = {};
                                                objItem["CategoryID"] = itemData.CategoryID;
                                                objItem["Instruction"] = `${that.state.selectedItemSize ? "Size:" + that.state.selectedItemSize : ""}${that.state.selectedItemColor ? ",Color:" + that.state.selectedItemColor : ""}`;
                                                objItem["ItemDescription"] = itemData.ItemDescription;
                                                objItem["ItemId"] = itemData.ItemID;
                                                objItem["ItemName"] = itemData.ItemName;
                                                objItem["ItemLogo"] = itemData.ItemLogo;
                                                objItem["ItemPrice"] = itemData.ItemPrice;
                                                objItem["ItemQuantity"] = itemData.noOfItems;
                                                objItem["ItemTax"] = itemData.tax;
                                                objItem["ItemType"] = itemData.ProductType;
                                                objItem["ItemWeight"] = itemData.Weight;
                                                //objItem["TotalItemPrice"] = (itemData.noOfItems * itemData.ItemPrice);
                                                objItem["TotalItemPrice"] = itemData.totalPrice
                                                objItem["IsSpecialDeliveryItem"] = itemData.IsSpecialDeliveryItem;
                                                objItem["specialdeliverycharges"] = itemData.specialdeliverycharges * itemData.noOfItems

                                                //objItem["ToppingItems"] = itemData.ToppingCategory;
                                                objItem["ToppingItems"] = itemData.ToppingItems
                                                objItem["AvailableQuantity"] = itemData.AvailableQuantity
                                                objItem["ContinueSelling"] = itemData.ContinueSelling
                                                objItem["variant_id"] = ""
                                                objItem["ItemSize"] = that.state.selectedItemSize
                                                objItem["itemColor"] = that.state.selectedItemColor
                                                objItem["IsProductTax"] = itemData.IsProductTax
                                                // if(itemData.ToppingCategory.length > 0){
                                                //     if( itemData.SelectedToppingItems && itemData.SelectedToppingItems.length > 0)
                                                //     objItem["ToppingItems"] = itemData.SelectedToppingItems;
                                                // }else{
                                                //     objItem["ToppingItems"] = itemData.ToppingCategory;
                                                // }

                                                store.Items.push(objItem)
                                            }
                                            else {
                                                console.log('item already exist')
                                                // var obj = storeItems.find(el => el.ItemId === itemData.ItemID);
                                                // obj.ItemQuantity = itemData.noOfItems;
                                                // obj.TotalItemPrice = (itemData.noOfItems * itemData.ItemPrice);

                                                var data = storeItems.map((item, index) => {
                                                    if (item.ItemId === itemData.ItemID) {
                                                        item.ItemQuantity = itemData.noOfItems;
                                                        //item.TotalItemPrice = (itemData.noOfItems * itemData.ItemPrice);
                                                        item.ItemSize = that.state.selectedItemSize
                                                        item.itemColor = that.state.selectedItemColor
                                                        item.Instruction = `${that.state.selectedItemSize ? "Size:" + that.state.selectedItemSize : ""}${that.state.selectedItemColor ? ",Color:" + that.state.selectedItemColor : ""}`
                                                        item.TotalItemPrice = itemData.totalPrice
                                                        item.ToppingItems = itemData.ToppingItems
                                                        item.IsProductTax = itemData.IsProductTax
                                                        store.Items[index] = item
                                                        return { ...item, }
                                                    }
                                                    else {
                                                        return { ...item, }
                                                    }
                                                })
                                            }
                                        }
                                    }
                                }
                            })
                        }
                    }
                })
            }
            StoreObjectInStorage(Constants.Obj_CART, finalCart)
            var data = RetriveObjectFromStorage(Constants.Obj_CART, '')
            console.log("after second ", data);
        }
        else {

            var plazaList = [];
            var objPlaza = {};
            objPlaza["PlazaId"] = plazaData._id;
            objPlaza["CompanyName"] = plazaData.CompanyName;
            objPlaza["Imageurl"] = plazaData.Imageurl;
            objPlaza["PlazaSubTotal"] = "";
            objPlaza["PlazaTipAmount"] = 0;
            objPlaza["PlazaTotal"] = "";
            objPlaza["ServiceFeeAmount"] = 0;

            var objStore = {};
            objStore["ObjectId"] = storeData.ObjectId;
            objStore["ApplicationId"] = storeData.ApplicationId;
            objStore["ApplicationName"] = storeData.ApplicationName;
            objStore["Deliverytimes"] = storeData.Deliverytimes ? storeData.Deliverytimes : [];
            objStore["Pickuptimes"] = storeData.Pickuptimes ? storeData.Pickuptimes : [];
            objStore["Taxes"] = storeData.Taxes;
            objStore["DeliveryDate"] = "";
            objStore["DeliveryTime"] = "";
            objStore["isshopifyintegration"] = storeData.isshopifyintegration // new key
            objStore["iswixintegration"] = storeData.iswixintegration // new key
            objStore["IsPickupEnable"] = storeData.IsPickupEnable // new key
            objStore["IsDeliveryEnable"] = storeData.IsDeliveryEnable // new key

            var objItem = {};
            objItem["CategoryID"] = itemData.CategoryID;
            objItem["Instruction"] = `${this.state.selectedItemSize ? "Size:" + this.state.selectedItemSize : ""}${this.state.selectedItemColor ? ",Color:" + this.state.selectedItemColor : ""}`;
            objItem["ItemDescription"] = itemData.ItemDescription;
            objItem["ItemId"] = itemData.ItemID;
            objItem["ItemName"] = itemData.ItemName;
            objItem["ItemLogo"] = itemData.ItemLogo;
            objItem["ItemPrice"] = itemData.ItemPrice;
            objItem["ItemQuantity"] = itemData.noOfItems;
            objItem["ItemTax"] = itemData.tax;
            objItem["ItemType"] = itemData.ProductType;
            objItem["ItemWeight"] = itemData.Weight;
            //objItem["TotalItemPrice"] = (itemData.noOfItems * itemData.ItemPrice);
            objItem["IsSpecialDeliveryItem"] = itemData.IsSpecialDeliveryItem;
            objItem["specialdeliverycharges"] = itemData.specialdeliverycharges * itemData.noOfItems

            objItem["TotalItemPrice"] = itemData.totalPrice
            objItem["ToppingItems"] = itemData.ToppingItems
            objItem["AvailableQuantity"] = itemData.AvailableQuantity
            objItem["ContinueSelling"] = itemData.ContinueSelling
            objItem["variant_id"] = ""
            objItem["ItemSize"] = this.state.selectedItemSize
            objItem["itemColor"] = this.state.selectedItemColor
            objItem["IsProductTax"] = itemData.IsProductTax
            // if (itemData.ToppingCategory.length > 0) {
            //     objItem["ToppingItems"] = itemData.ToppingItems;
            // } else {
            //     objItem["ToppingItems"] = itemData.ToppingCategory;
            // }


            if (this.state.itemsInCart.length > 0) {
                const itemExist = this.state.itemsInCart.some(el => (el.ItemId === itemData.ItemID));

                if (!itemExist) {
                    console.log('item not exist')
                    objStore["Items"] = [];
                    this.state.itemsInCart.push(objItem);
                    objStore["Items"] = this.state.itemsInCart;
                }
                else {
                    console.log('item already exist')
                    var obj = this.state.itemsInCart.find(el => el.ItemId === itemData.ItemID);
                    obj.ItemQuantity = itemData.noOfItems;
                    //obj.TotalItemPrice = (itemData.noOfItems * itemData.ItemPrice); 
                    obj.TotalItemPrice = itemData.totalPrice
                    obj.ToppingItems = itemData.ToppingItems
                    this.setState({
                        itemsInCart: this.state.itemsInCart.map(el => (el.ItemId === objItem.ItemId ? Object.assign({}, obj, el) : el))
                    })
                }
            }
            else {
                console.log('item first time')
                objStore["Items"] = [];
                this.state.itemsInCart.push(objItem);
                objStore["Items"] = this.state.itemsInCart;
            }

            if (this.state.cartStore.length > 0) {
                objPlaza["Stores"] = this.state.cartStore;

            } else {
                objPlaza["Stores"] = [];
                this.state.cartStore.push(objStore);
                objPlaza["Stores"] = this.state.cartStore;
            }

            plazaList.push(objPlaza);

            StoreObjectInStorage(Constants.Obj_CART, plazaList)
            var data = RetriveObjectFromStorage(Constants.Obj_CART, '')
            console.log("after first ", data);
        }
    }

    //////////////////////////////////////////////////// understanding ///////////////////////////////
    onSelect = key => {
        var obj = this.state.storeItemList.find((o) => o.ItemID === key)
        console.log("onselect ", obj)
        this.setState({ selected: obj.ItemID })
        this.setState({ itemId: obj.ItemID })
        // this.setState({ itemPrice: obj.ItemPrice })
        this.setState({ itemImage: obj.ItemLogo })
        this.setState({ title: obj.ItemName })
        // this.setState({ totalPrice: obj.ItemPrice })
        this.setState({ itemCount: 1 })
        this.setState({ labelItemPrice: obj.ItemPrice })
        this.setState({ IsTryItEnable: obj.IsTryItEnable })
        this.setState({ AvailableQuantity: obj.AvailableQuantity })
        this.setState({ ContinueSelling: obj.ContinueSelling })

        this.setState({ selectedToppingList: [] })
        this.setState({ toppingCharge: 0.0 })
        this.setState({ specialdeliverycharges: obj.specialdeliverycharges })

        if (obj.Sizes) {
            this.setState({ selectedItemSize: obj.Sizes[0] ? obj.Sizes[0] : "" })
        }

        if (obj.colors) {
            this.setState({ selectedItemColor: obj.colors[0] ? obj.colors[0] : "" })
        }

        var objStoreItem = StoreObjectInStorage(Constants.Obj_STORE_ITEMS, obj);

        var objPlaza = RetriveObjectFromStorage(Constants.Obj_PLAZA, '');
        var objStore = RetriveObjectFromStorage(Constants.Obj_STORE, '');
        console.log("objPlaza ", objPlaza);
        console.log("objStore ", objStore);
        var globalFinalCart = RetriveObjectFromStorage(Constants.Obj_CART, '')

        var list = obj.ToppingCategory;
        var selectedItemObj = obj;
        var itemPrice = obj.ItemPrice;
        var totalPrice = obj.ItemPrice

        if (objPlaza && objStore && globalFinalCart) {
            globalFinalCart.map(cartObj => {
                if (cartObj.PlazaId === objPlaza._id) {
                    cartObj.Stores.map(cartStore => {
                        if (cartStore.ObjectId === objStore.ObjectId) {
                            cartStore.Items.map(cartItem => {
                                if (cartItem.ItemId === obj.ItemID) {
                                    this.setState({ selectedToppingList: cartItem.ToppingItems })
                                    this.setState({ itemCount: cartItem.ItemQuantity })
                                    this.setState({ selectedToppingList: cartItem.ToppingItems })
                                    this.setState({
                                        selectedItemSize: cartItem.ItemSize,
                                        selectedItemColor: cartItem.itemColor
                                    })

                                    for (var withtopinngs of cartItem.ToppingItems.slice(3, cartItem.ToppingItems.length)) {
                                        // alert(JSON.stringify(withtopinngs.Price))
                                        itemPrice = itemPrice + withtopinngs.Price;
                                    }
                                    totalPrice = itemPrice * cartItem.ItemQuantity;
                                    // this.setState({ toppingCharge: 0.0 })
                                    // this.addToppingCharges(cartItem)
                                    selectedItemObj = {
                                        ...selectedItemObj,
                                        "ToppingCategory": obj.ToppingCategory.map(toppingcategory => {
                                            var ToppingItems = toppingcategory.ToppingItems.map(toppingObj => {
                                                var selectedTopping = cartItem.ToppingItems.filter(e1 => e1._id === toppingObj._id)[0]
                                                if (selectedTopping) {
                                                    return selectedTopping
                                                }
                                                return toppingObj
                                            })
                                            return { ...toppingcategory, "ToppingItems": ToppingItems }
                                        })
                                    }
                                }
                            })
                        }
                    })
                }
            })
        }
        this.setState({ itemPrice: itemPrice })
        this.setState({ totalPrice: totalPrice })
        console.log('====selected', obj)

        if (list.length > 0) {
            this.setState({ showToppingBtn: "visible" })
            // this.setState({ selectedItemObj: selectedItemObj })
        } else {
            this.setState({ showToppingBtn: "hidden" })
        }
        this.setState({ selectedItemObj: selectedItemObj })
    }

    handleAddToppings(e) {
        e.preventDefault();
        $('#toppingModal').modal('show')
        this.setState({ showAddToppingView: true });
    }

    addSelectedToppings(selected) {
        //alert(JSON.stringify(selectedTopping))
        if (selected.SelectedToppingItems.length > 3) {
            var price = this.state.labelItemPrice
            var toppingpricer = 0.0;

            for (var withtopinngs of selected.SelectedToppingItems.slice(3, selected.SelectedToppingItems.length)) {
                // alert(JSON.stringify(withtopinngs.Price))
                toppingpricer = toppingpricer + withtopinngs.Price;
            }
            this.setState({ itemPrice: price + toppingpricer })
        }
        else {
            var price = this.state.labelItemPrice;
            this.setState({ itemPrice: price })
        }
        console.log("selectedTopping ", selected)
        this.setState({ selectedToppingList: selected.SelectedToppingItems })
        this.setState({ totalPrice: selected.totalPrice })

        this.setState({ itemCount: selected.noOfItems })
    }


    handleTryIt = () => {
        this.props.history.push({
            pathname: RouteConstant.TryIt,
        })
    }


    setItemObj = itemObj => {
        this.setState({ selectedItemObj: itemObj })
    }

    setSelectedToppings = selectedToppings => {
        this.setState({ selectedToppingList: selectedToppings })
    }

    setToppingPrice = toppingPrice => {
        this.setState({ toppingCharge: toppingPrice })
    }

    setTotalPrice = totalPrice => {
        this.setState({ totalPrice: totalPrice })
    }

    setItemCount = itemCount => {
        this.setState({ itemCount: itemCount })
    }

    handelSize(size) {
        this.setState({ selectedItemSize: size })
    }

    handelColor(color) {
        this.setState({ selectedItemColor: color })
    }

    render() {
        const { storeItemList, selected, countCartItem, showToppingBtn, selectedItemObj } = this.state


        const Arrow = ({ text, className }) => {
            return <div className={className}>{text}</div>;
        };

        const ArrowLeft = Arrow({ text: "<", className: "arrow-prev" });
        const ArrowRight = Arrow({ text: ">", className: "arrow-next" });

        const MenuItem = ({ text, selected }) => {
            return <div className={`menu-item ${selected ? 'active' : ''}`} style={{ padding: "10px 5px" }}>
                <div style={{ width: "unset", height: "150px", marginBottom: "5px" }}>
                    <img src={text.ItemLogo} style={{ width: "80px", height: "100px" }}></img>
                    <br />
                    <label className="textcolor" style={{ textOverflow: "ellipsis", overflow: "hidden", width: "100px" }}>{text.ItemName}</label>
                    <br />
                    <label className="textcolor">{currencySymbole} {Number(text.ItemPrice).toFixed(2)}</label>
                </div>

            </div>;
        };

        const Menu = (list, selected) =>
            list.map(el => {
                const { ItemID } = el;
                return <MenuItem text={el} key={ItemID} selected={this.state.selected == ItemID} />;
            });

        this.menuItems = Menu(storeItemList, selected);
        const menu = this.menuItems;

        var specialDelivery = false
        var storeItemObj = RetriveObjectFromStorage(Constants.Obj_STORE_ITEMS);
        if (storeItemObj) {
            if (storeItemObj.IsSpecialDeliveryItem && this.state.IsDeliveryEnableStore) {
                specialDelivery = true
            }
        }

        var currencySymbole = ""
        var countryFees = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES)
        if (countryFees) {
            currencySymbole = (countryFees[currencyInfo.CountryCode] ?
                "(" + countryFees[currencyInfo.CountryCode] + ") " : "") + countryFees.Symbol

        }

        return (
            <div>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Constants.LoadingMssg}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className=""
                >
                    <Header countItemCart={countCartItem} setHeaderTitle={this.state.title} />
                    <section className="section">

                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                            <div className="reg_form_main">
                                <center>
                                    <img src={this.state.itemImage} style={{ width: "150px", height: "150px" }}></img>
                                </center>
                                <label className="textcolor">{currencySymbole} {Number(this.state.labelItemPrice).toFixed(2)}</label>
                                &nbsp; &nbsp;
                                {selectedItemObj.TrackQuantity && <label className="errorMsg">Only {selectedItemObj.AvailableQuantity} left in stock</label>}
                                <div className="row m-0" style={{ padding: "5px" }} >
                                    <label style={{ fontSize: "25px", marginLeft: "20px", marginRight: "10px" }} onClick={this.handleMinus}>-</label>
                                    <label style={{ fontSize: "25px", marginLeft: "10px", marginRight: "10px", width: "30px" }}> {this.state.itemCount} </label>
                                    <label style={{ fontSize: "25px" }} onClick={this.handlePlus}>+</label>


                                    <span style={{ marginLeft: "auto" }}>
                                        <label className="textcolor" style={{ float: "right", marginRight: "5px" }}>{currencySymbole} {Number(this.state.totalPrice).toFixed(2)}</label>
                                    </span>
                                </div>
                            </div>

                            <div className="row m-0" style={{ padding: "5px", justifyContent: "center" }} >
                                <div className="col-sm-12">
                                    <div className="bottam_sing" style={{ bottom: "0px" }}>
                                        {selectedItemObj.ItemDescription}
                                    </div>
                                </div>
                                {selectedItemObj && selectedItemObj.Sizes && selectedItemObj.Sizes.length > 0 && <div className="mainattBox col-sm-6">
                                    <span className="textcolor setline"> {this.state.optionObj1?.name}Sizes :- </span>
                                    <div>
                                        {selectedItemObj.Sizes.map(value =>
                                        (
                                            <div key={value} className={this.state.selectedItemSize == value ? 'sizeBox clicksizeBox' : 'sizeBox'}
                                                onClick={(e) => this.handelSize(value)}>
                                                <label className="sizeboxitem">{value}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>}
                                {selectedItemObj && selectedItemObj.colors && selectedItemObj.colors.length > 0 && <div className="mainattBox col-sm-6">
                                    <span className="textcolor setline"> {this.state.optionObj1?.name}Colors :- </span>
                                    <div>
                                        {selectedItemObj.colors.map(value =>
                                        (
                                            <div key={value} className={this.state.selectedItemColor == value ? 'sizeBox clicksizeBox' : 'sizeBox'}
                                                onClick={(e) => this.handelColor(value)}>
                                                <label className="sizeboxitem">{value}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>}

                            </div>

                            <div className="row m-0" style={{ padding: "5px", justifyContent: "center" }} >
                                {specialDelivery ?
                                    <div className="col-sm-6 p-0">
                                        <div className="bottam_sing" style={{ bottom: "0px" }}>
                                            <label
                                                style={{ backgroundColor: Color.THEME_COLOR, borderRadius: "50px" }}
                                                // onClick={this.handleAddToCart}
                                                className=" col-sm-6 btn btn-done m-0"
                                                data-toggle="modal"
                                                data-target="#myModal"
                                            > Add Cart</label>
                                        </div>
                                    </div> :
                                    <div className="col-sm-6 p-0">
                                        <div className="bottam_sing" style={{ bottom: "0px" }}>
                                            <label
                                                style={{ backgroundColor: Color.THEME_COLOR, borderRadius: "50px" }}
                                                onClick={this.handleAddToCart}
                                                className=" col-sm-6 btn btn-done m-0"
                                            > Add Cart</label>
                                        </div>
                                    </div>
                                }

                                {this.state.IsTryItEnable ?
                                    <div className="col-sm-6 p-0">
                                        <div className="bottam_sing" style={{ bottom: "0px" }}>
                                            <label
                                                style={{ backgroundColor: Color.THEME_COLOR, borderRadius: "50px" }}
                                                onClick={this.handleTryIt}
                                                className=" col-sm-6 btn btn-done m-0"
                                            > Try It</label>
                                        </div>
                                    </div>
                                    : ""}

                            </div>

                            {/* {this.state.showToppingBtn ?  */}
                            <div className="bottam_sing" style={{ bottom: "0px", visibility: showToppingBtn }}>
                                <label
                                    style={{ backgroundColor: Color.THEME_COLOR, borderRadius: "50px" }}
                                    onClick={this.handleAddToppings}
                                    className="col-lg-3 col-md-4 col-sm-6 btn btn-done m-0"
                                > Customize Toppings</label>
                            </div>
                            {/* :
                        "" } */}

                            {/* {this.state.showAddToppingView ? */}
                            <div class="modal" id="toppingModal" style={{ marginBottom: "10px" }} centered>
                                <div class="modal-dialog" style={{ margin: "auto", top: "25%" }}>
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                                        </div>
                                        <div class="modal-body">
                                            <AddToppings
                                                //data={this.state.selectedItemObj}
                                                selectedToppings={this.addSelectedToppings}
                                                plazaData={this.state.plazaData}
                                                storeData={this.state.storeData}
                                                itemData={this.state.selectedItemObj}
                                                setItemData={this.setItemObj}
                                                selectedToppingList={this.state.selectedToppingList}
                                                setSelectedToppings={this.setSelectedToppings}
                                                itemPrice={this.state.labelItemPrice}
                                                itemCount={this.state.itemCount}
                                                setItemCount={this.setItemCount}
                                                totalPrice={this.state.totalPrice}
                                                setTotalPrice={this.setTotalPrice}
                                                toppingCharge={this.state.toppingCharge}
                                                setToppingPrice={this.setToppingPrice}
                                                AvailableQuantity={this.state.AvailableQuantity}
                                                ContinueSelling={this.state.ContinueSelling}
                                            >

                                            </AddToppings>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* : null} */}

                            <div className="reg_form_main" style={{ marginBottom: "10px" }}>
                                <div className="" style={{ marginBottom: "5px" }}>
                                    <label>Related Products</label>
                                    <ScrollMenu
                                        arrowLeft={ArrowLeft}
                                        arrowRight={ArrowRight}
                                        data={menu}
                                        selected={selected}
                                        onSelect={this.onSelect}
                                        alignCenter={false}
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="modal" id="myModal" style={{ verticalAlign: "middle" }}>
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-body">
                                        <center>
                                            <label><b>{this.state.title}</b></label><br />
                                            <label>The delivery charge on the specific product is {currencySymbole} {Number(this.state.specialdeliverycharges).toFixed(2)} <br></br>
                                                Continue buying the selected products.</label>
                                        </center>

                                    </div>
                                    <div class="modal-footer">
                                        <label
                                            style={{ color: Color.THEME_COLOR, marginLeft: "auto", marginRight: "auto" }}
                                            data-dismiss="modal"
                                        // onClick={() => this.unsetDeleteId()}
                                        > No</label>

                                        <label
                                            // onClick={() => this.handleDelete()}
                                            //onClick={this.handleDelete}
                                            onClick={this.handleAddToCart}
                                            style={{ color: Color.THEME_COLOR, marginLeft: "auto", marginRight: "auto" }}
                                            data-dismiss="modal"
                                        > Yes</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </LoadingOverlay>
            </div>
        );
    }
}

export default AddCart;