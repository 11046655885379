
export const LandingPagePH = {
    SelectCountry: "Select Country",
    AddAddress: "Type Your Address",
    SelectAddress: "Select Address",
    ZIP_PostalCode: "Enter ZIP / Postal Code"
}

export const RegisterPH = {
    FirstName: "First Name",
    LastName: "Last Name",
    Email: "Email",
    Password: "Password",
    ConfirmPassword: "Confirm Password",
    PhoneNumber: "Phone Number",
    Enterotpnumber: "Enter one time code",
    Captcha: "Enter Captcha Code"
}

export const OrderWebCheckoutPH = {
    FirstName: "First Name",
    LastName: "Last Name",
    Email: "Email",
    Password: "Password",
    ConfirmPassword: "Confirm Password",
    PhoneNumber: "Phone Number",
    Enterotpnumber: "Enter one time code",
    Captcha: "Enter Captcha Code"
}

export const LoginPH = {
    email: "name@example.com",
    Password: "Password",
    Captcha: "Enter Captcha Code"
}

export const ForgotPasswordPH = {
    Email: "name@example.com",
    Enterotpnumber: "Enter one time code",
    Password: "Password",
    ConfirmPassword: "Confirm Password",
}

// // add on 5-apr-2023

export const PlazaCartPartnerPH = {
    Name: "Name",
    Email: "Email",
    PhoneNumber: "Phone Number",
    AddAddress: "Type Your Address",
    City: "City",
    State: "State",
    Country: "Country",
    ZIP_PostalCode: "Enter ZIP / Postal Code"
}

//