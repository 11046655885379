import React, { Component } from 'react';
import defaultUser from "../../images/default-user.png";
import './SideMenu.css';
import WheelPhoto from "../../images/gear-wheel.png";

import { MenuItem } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import Color from '../Config/Color';
import RouteConstant from '../Config/RouteConstant';
import { withRouter } from 'react-router';
import Moment from 'moment';
import { StoreObjectInStorage } from '../Config/Utils';
import Constants, { DatetimeFormate, APPOINTMENTSTATUS } from '../Config/Constants';
import CountryList from '../Config/CountryCode'

class MyAppointmentside extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.handleItemClick = this.handleItemClick.bind(this);
        this.closeNav = this.closeNav.bind(this);
    }

    componentDidMount() {
    }

    handleItemClick(data) {
        console.log("item click")
        console.log(JSON.stringify(data))
        StoreObjectInStorage(Constants.APPOINTMENT, data);
        this.closeNav();
        this.props.history.push({
            pathname: RouteConstant.MyAppointment,
        });
        const { onrefresh } = this.props

        if (typeof onrefresh == "function") {
            onrefresh()
        }
    }

    closeNav() {
        window.closeNav();
    }

    render() {
        let list = this.props.data
            .map(function (item, index) {

                return (
                    <React.Fragment key={item.OrderId}>
                        <div className="col-sm-12 p-0 setaTag" style={{ backgroundColor: Color.WHITE, borderBottom: "1px solid" + Color.GREY }} onClick={(value) => this.handleItemClick(item)} >
                            <div className="row m-0" style={{ color: Color.THEME_COLOR }}>
                                {item.storename}
                            </div>
                            <div className="row m-0" style={{ fontSize: "12px" }}>
                                <div class="col-1 p-0">
                                    <i class="fas fa-map-marker-alt mt-2" style={{ color: Color.THEME_COLOR }}></i>
                                </div>
                                <div class="col-11 pl-0">
                                    <span>{item.storeaddress} </span>
                                </div>
                            </div>
                            <div className="row m-0" style={{ fontSize: "12px" }}>
                                <div class="col-1 p-0">
                                    <i class="fas fa-calendar store-icon mt-2" style={{ color: Color.THEME_COLOR }}></i>
                                </div>
                                <div class="col-11 pl-0">
                                    <span>
                                        {`${item.starttime} - ${item.endtime}, ${item.date ? Moment.utc(item.date).format(DatetimeFormate.ddN_DD_MN_Y) : ""}`}
                                    </span>
                                </div>
                            </div>
                            <div className="row m-0" style={{ fontSize: "12px", color: item.appoimentstatus == APPOINTMENTSTATUS.CONFIRMED ? Color.GREEN : Color.BLUE }}>
                                <div class="col-6 offset-1 p-0">
                                    {item.appoimentstatus}
                                </div>
                            </div>

                        </div>
                    </React.Fragment>
                )
            }, this);

        return (
            <div>
                {list}
            </div>
        );
    }
}

export default withRouter(MyAppointmentside);