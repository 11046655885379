import GoogleMapReact from 'google-map-react';
import Moment from 'moment';
import React, { Component } from 'react';
import Collapsible from 'react-collapsible';
import Geocode from "react-geocode";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import LoadingOverlay from 'react-loading-overlay';
import { Radio, RadioGroup } from 'react-radio-group';
import Checkbox from 'react-simple-checkbox';
import discountcupon from "../../../images/extraicon/coupon.png";
import accountuser from "../../../images/icons/accountuser.png";
import creditcard1 from "../../../images/icons/credit-card2.png";
import marker1 from "../../../images/icons/marker1.png";
import pencil from "../../../images/icons/pencil.png";
import people1 from "../../../images/icons/people2.png";
import Storepickuplocation from "../../../images/icons/storelocation.png";
import { GetData } from "../../../services/GetData";
import { PostData } from "../../../services/PostData";
import Color from '../../Config/Color';
import { ORDER_WEB_STORE_ADDRESS_LIST_API, GET_USERPROFILE_API, GOOGLE_PLACE_KEY, ORDER_WEB_STORE_COUPON_LIST_API, ORDER_WEB_STORE_DELIVERY_CHARGES_API, ORDER_WEB_STORE_HOURS_API, PLAZAID, STOREID, USER_REGISTRATION_API, VALIDATE_OTP_API } from '../../Config/Config.json';
import Constants, { DEFAULTID, DELIVERY_OPTIONS, DateFormate, UserSessionTypes, currencyInfo } from '../../Config/Constants';
import Messages from '../../Config/Messages';
import { OrderWebCheckoutPH } from '../../Config/Placeholder';
import RouteConstant from '../../Config/RouteConstant';
import { RetriveDataFromStorage, RetriveObjectFromStorage, StoreDataInStorage, StoreObjectInStorage, TimeToHours, checkPostalCode, formatPhoneNumber, formatToDigit, removeAllSpace, validateEmail, validateOtpNo, validatePhoneNumber, validateUserName } from '../../Config/Utils';
import { OrderWebCheckoutConst } from '../../Config/constvariable';
import { PutMandatoryfieldSymbol } from '../../UtillCustomComponent/UtillCustomComponent';
import HeaderOrderWeb from '../Header/HeaderOrderWeb';
import AddressListOrderWeb from './AddressListOrderWeb';
import PlazaStoreOrderWeb from './PlazaStoreOrderWeb';
import {
    browserName, fullBrowserVersion, osName,
    osVersion
} from "react-device-detect";
const publicIp = require('public-ip');
import './CheckoutOrderWebSingleStore.css';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#f0f0f0', border: "none", borderRadius: "50px", height: "50px", marginBottom: "15px" }),
    placeholder: styles => ({ ...styles, fontSize: "16px", fontWeight: "450 !important", marginLeft: "8px" }),
    option: styles => ({ ...styles, color: '#333' }),
    menuPortal: styles => ({ ...styles, zIndex: "99" })
};

class CheckoutOrderWebSingleStore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ipAddress: '',
            browserLanguage: '',
            selectedValue: "1",
            selectedTipValue: "1",
            selectedTipValueTemp: "1",
            userFields: {},
            errors: {},
            disableDeliveryTip: true,
            userDetails: {},
            countryFees: {},
            taxDetails: [],
            // serviceFee: 0.0,
            subtotal: 0.0,
            totalCAD: 0.0,
            tipAmount: 0.0,
            deliveryCharge: 0.0,
            listAddress: [],
            specialdeliverycharges: 0.0,
            timeDayHeader: "",
            cartList: [],
            userAddress: {},
            finalCartObj: {},
            couponList: [],
            isPickupAvailable: true,
            isDeliveryAvailable: true,
            totalItemTax: 0.0,
            tempSelectedCoupon: null,
            selectedCoupon: null,

            plazaSubtotel_Tfreep: 0.0,
            deliveryCharge_TfreeP: 0.0,
            specialdeliverycharges_TfreeP: 0.0,
            totalItemTax_TfreeP: 0.0,

            isDeliveryCostres: true,
            deliveryCostErrMsg: "",
            masterstorehours: [],
            isStoretimerender: false,
            isShopifyAvailable: false,
            Latitude: 0,
            Longitude: 0,
            alatitude: 0,
            alongitude: 0,
            StoreMainObject: {},
            checkboxId: -1,

            pickupordertime: null,
            isActive: false,
            sessionToken: '',
            guestuserFields: {},
            selectedAddress: null,
            mainselectedAddress: null,
            selectfulladdressdetail: null,
            chkBoxChecked: false,
            otp: {},

            totalproductTax: 0,
            SessionUserType: "",
            doordashmaximumorderamount: 0,
            deliveryoption: "",

            applicationfee: 0,
            applicationfeepercentage: 0,
            finalTotal: 0,
            applicationfeetotal: 0
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeRadioBtn = this.handleChangeRadioBtn.bind(this);
        this.handleChangeTipRadioBtn = this.handleChangeTipRadioBtn.bind(this);
        this.handlePaymentForLoginUser = this.handlePaymentForLoginUser.bind(this);
        this.handlePaymentForGuestUser = this.handlePaymentForGuestUser.bind(this);
        this.handleDeliveryTip = this.handleDeliveryTip.bind(this);
        this.setDeliveryCharge = this.setDeliveryCharge.bind(this);
        this.updateCartObj = this.updateCartObj.bind(this);
        this.handleContinueShopping = this.handleContinueShopping.bind(this);
        this.handleChangeguest = this.handleChangeguest.bind(this);
        this.handleValidationOnBlurGuest = this.handleValidationOnBlurGuest.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.handleValidateOtpChange = this.handleValidateOtpChange.bind(this);
        this.handleValidationOTPonBlur = this.handleValidationOTPonBlur.bind(this);
        this.handleValidateOtpSubmit = this.handleValidateOtpSubmit.bind(this);
    }

    componentDidMount() {
        /* For Ip Address */
        (async () => {
            var ip = await publicIp.v4();
            this.setState({ ipAddress: ip });
            console.log('ip address', ip);
        })();

        /* For Detect browser details */
        const detectBrowserLanguage = require('detect-browser-language')
        this.setState({ browserLanguage: detectBrowserLanguage() });
        console.log('detect browser language', detectBrowserLanguage());

        /* For Current Location */
        if ("geolocation" in navigator) {
            console.log("Geolocation Available");
            this.getLocation();
        } else {
            console.log("Geolocation Not Available");
            console.error("Error Code = " + error.code + " - " + error.message);
        }

        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        var objStore = RetriveObjectFromStorage(Constants.Obj_STORE, '');
        if (objStore) {
            this.setState({ StoreMainObject: objStore }, function () {
                console.log('Test', JSON.stringify(objStore));
                if (this.state.StoreMainObject && this.state.StoreMainObject.AppAddress) {
                    this.getgeocoder(this.state.StoreMainObject.AppAddress);
                }
            });
        }

        if (authToken) {
            this.setState({ sessionToken: authToken });
        } else {
            this.setState({ sessionToken: '' });
        }

        /* Session User Type */
        var SessionUserType = RetriveDataFromStorage(Constants.USER_SESSION_TYPES);
        if (SessionUserType) {
            this.setState({ SessionUserType: SessionUserType });
        }

        var objCart = RetriveObjectFromStorage(Constants.Obj_CART, '');
        var plazaid = "", storeid = "";
        var isShopifyAvailable = false;

        if (objCart && objCart.length > 0) {
            objCart.map(e1 => {
                plazaid = e1.PlazaId;
                storeid = e1.ObjectId;
                return { ...e1 }
            });
            isShopifyAvailable = objCart[0].isshopifyintegration;
            this.setState({ cartList: objCart, isShopifyAvailable: isShopifyAvailable });
        }
        else {
            this.props.history.goBack()
        }

        if (plazaid && storeid) {
            this.getCouponAPI(storeid, plazaid);
            this.getStoreHoursAPI(storeid);
        }

        var objUserDetails = RetriveObjectFromStorage(Constants.Obj_USER, '');
        if (objUserDetails) {
            var userFields = this.state.userFields;
            userFields["MobileNumber"] = formatPhoneNumber(objUserDetails.Phone1);
            this.setState({ userFields });
            this.setState({ userDetails: objUserDetails }, function () {
                var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
                if (storeid && authToken) {
                    this.GetDeliverableAddress(authToken, storeid);
                }
            });
        } else {
            if (storeid && authToken) {
                this.GetCurrentUserDetails(authToken, storeid);
            }
        }

        var objCountryFees = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES, '');
        {/* Changes are made based on the call discussion. Date 10 Aug 2022 */ }
        if (objCountryFees) {
            this.setState({ countryFees: objCountryFees }, function () {
                // this.setState({ serviceFee: 0.0 /* this.state.countryFees.ServiceFee */ }, function () {
                this.setState({ taxDetails: this.state.countryFees.TaxesDetail, applicationfee: this.state.countryFees.ApplicationFee, applicationfeepercentage: this.state.countryFees.ApplicationFeePercentage }, function () {
                    this.setSubTotal();
                });
            })
            // });
        }
    }

    /* For Get Curent Location */
    getLocation = () => {
        navigator.geolocation.getCurrentPosition((position) => {
            console.log('Location', 'lat' + position.coords.latitude + "Lon" + position.coords.longitude);
            this.setState({ alatitude: position.coords.latitude });
            this.setState({ alongitude: position.coords.longitude });
        });
    };

    /* Get User Details */
    GetCurrentUserDetails(authToken, storeid) {
        var API = GET_USERPROFILE_API;
        GetData(API, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData) {
                        this.setState({ userDetails: responseData }, function () {
                            var userFields = this.state.userFields;
                            userFields["MobileNumber"] = formatPhoneNumber(responseData.Phone1);
                            this.setState({ userFields });
                            if (storeid) {
                                this.GetDeliverableAddress(authToken, storeid);
                            }
                            StoreObjectInStorage(Constants.Obj_USER, responseData);
                        });
                    }
                }
            });
    }

    /* Get deliver Address List */
    GetDeliverableAddress(authToken, storeid) {
        this.setState({ isActive: true })
        var API = ORDER_WEB_STORE_ADDRESS_LIST_API + "?" + STOREID + storeid;
        GetData(API, authToken)
            .then(result => {
                var responseJson = result;
                console.log('Delivery Address Api List ', JSON.stringify(responseJson));
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData && responseData.Addresslist) {
                        this.setState({ listAddress: responseData.Addresslist });
                        if (responseData.Addresslist.length > 0) {
                            var userAddress = {}, checkboxId = 0;
                            userAddress = responseData.Addresslist.filter(e => e.IsDeliverableaddress === true)[0];
                            if (userAddress) {
                                checkboxId = responseData.Addresslist.findIndex(e => e._id === userAddress._id);
                                var errors = this.state.errors;
                                errors['deliveryAddress'] = "";
                                this.setState({ userAddress: userAddress, checkboxId: checkboxId, errors: errors });
                            }
                        }
                    }
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })
            }).catch(err => {
                console.log(err);
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    getgeocoder(address) {
        Geocode.setApiKey(GOOGLE_PLACE_KEY);
        Geocode.setLanguage("en");
        Geocode.enableDebug();
        Geocode.fromAddress(address).then(
            response => {
                console.log('country address response', response);
                try {
                    const { lat, lng } = response.results[0].geometry.location;
                    this.setState({ Latitude: parseFloat(lat), Longitude: parseFloat(lng) });
                    if (response && response.results[0] && response.results[0].address_components) {
                        var StateObject = response.results[0].address_components.filter(e => e.types && e.types.length > 0 && (e.types[0] === "administrative_area_level_1"))[0];
                        if (StateObject && StateObject.long_name && StateObject.short_name) {
                            console.log('country address response', JSON.stringify(StateObject));
                            var objStore = RetriveObjectFromStorage(Constants.Obj_STORE, '');
                            if (objStore && StateObject) {
                                objStore.Statecode = StateObject.short_name;
                                objStore.Statename = StateObject.long_name;
                                StoreObjectInStorage(Constants.Obj_STORE, objStore);
                                this.setState({ StoreMainObject: objStore });
                            }
                        }
                    }
                }
                catch (e) {
                    console.log("err", e)
                }
            },
            error => {

            }
        );
    }

    /* Get GeoCoder Postalcode */
    getgeocodercheckpostalcodeValid(postalcode, PFLAG) {
        Geocode.setApiKey(GOOGLE_PLACE_KEY);
        Geocode.setLanguage("en");
        Geocode.enableDebug();
        Geocode.fromAddress(postalcode).then(
            response => {
                console.log('country address response', response, JSON.stringify(this.state.mainselectedAddress));
                try {
                    if (response &&
                        response.results[0] &&
                        response.results[0].address_components &&
                        response.results[0].address_components.length > 0 &&
                        this.state.mainselectedAddress &&
                        this.state.mainselectedAddress.address_components &&
                        this.state.mainselectedAddress.address_components.length > 0) {
                        var PostalCodeObject = response.results[0];
                        var AddressObject = this.state.mainselectedAddress;
                        var StoreMainObject = this.state.StoreMainObject;
                        if (PostalCodeObject && AddressObject && StoreMainObject) {
                            var PS = PostalCodeObject.address_components.filter(e => e.types && e.types.length > 0 && (e.types[0] === "administrative_area_level_1"))[0];
                            var AS = AddressObject.address_components.filter(e => e.types && e.types.length > 0 && (e.types[0] === "administrative_area_level_1"))[0]
                            if (PS && PS.long_name && PS.short_name && AS && AS.long_name && AS.short_name) {
                                if (PS.short_name == AS.short_name) {
                                    if (StoreMainObject.Statecode == PS.short_name) {
                                        if (PFLAG != 1) {
                                            var deliveryoption = this.state.deliveryoption;
                                            if (deliveryoption == DELIVERY_OPTIONS.DOORDASH) {
                                                /* For Doordash Delivery Order*/
                                                var totalamount = 0;
                                                if (this.state.selectedCoupon) {
                                                    totalamount = Number(((Math.round(this.state.finalTotal * 100) / 100).toFixed(2)) - this.state.selectedCoupon.DicountPrice).toFixed(2);
                                                } else {
                                                    totalamount = Number((Math.round(this.state.finalTotal * 100) / 100).toFixed(2));
                                                }
                                                var doordashmaximumorderamount = Number(this.state.doordashmaximumorderamount);
                                                if (totalamount > doordashmaximumorderamount) {

                                                    var currencySymbole = "";
                                                    var countryFeesLocal = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES)
                                                    if (countryFeesLocal) {
                                                        currencySymbole = (countryFeesLocal[currencyInfo.CountryCode] ?
                                                            "(" + countryFeesLocal[currencyInfo.CountryCode] + ") " : "") + countryFeesLocal.Symbol;
                                                    }
                                                    var errors = {};
                                                    errors["CommonError"] = Messages.DoordashVaidationMsg.replace("##RATE##", "" + currencySymbole + doordashmaximumorderamount);
                                                    this.setState({ errors: errors });
                                                } else {
                                                    var errors = {};
                                                    errors["CommonError"] = "";
                                                    this.setState({ errors: errors });
                                                    this.createCartObject(2);
                                                }
                                            } else {
                                                /* For Plazacart Delivery Order */
                                                var totalamount = 0;
                                                if (this.state.selectedCoupon) {
                                                    totalamount = Number(((Math.round(this.state.finalTotal * 100) / 100).toFixed(2)) - this.state.selectedCoupon.DicountPrice).toFixed(2);
                                                } else {
                                                    totalamount = Number((Math.round(this.state.finalTotal * 100) / 100).toFixed(2));
                                                }
                                                var plazacartmaximumorderamount = Number(Constants.PLAZACARTMAXIMUMORDERAMOUNT);
                                                if (totalamount > plazacartmaximumorderamount) {

                                                    var currencySymbole = "";
                                                    var countryFeesLocal = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES)
                                                    if (countryFeesLocal) {
                                                        currencySymbole = (countryFeesLocal[currencyInfo.CountryCode] ?
                                                            "(" + countryFeesLocal[currencyInfo.CountryCode] + ") " : "") + countryFeesLocal.Symbol;
                                                    }
                                                    var errors = {};
                                                    errors["CommonError"] = Messages.PlazacartValidationMsg.replace("##RATE##", "" + currencySymbole + plazacartmaximumorderamount);
                                                    this.setState({ errors: errors });
                                                } else {
                                                    var errors = {};
                                                    errors["CommonError"] = "";
                                                    this.setState({ errors: errors });
                                                    this.createCartObject(2);
                                                }
                                            }
                                        } else {
                                            this.getDeliveryChargesFun(1);
                                        }
                                    } else {
                                        let errors = {};
                                        errors["Address"] = (this.state.countryFees.CountryCode && this.state.countryFees.CountryCode == "CA") ? Messages.v_Valid_Address.replace("state", "province") : Messages.v_Valid_Address;
                                        errors["CommonError"] = Messages.GUEST_USER_REQUIRED_FIELDS;
                                        this.setState({ errors: errors });
                                    }

                                } else {
                                    let errors = {};
                                    errors["PostalCode"] = Messages.v_EnterValidPostalCode;
                                    errors["CommonError"] = Messages.GUEST_USER_REQUIRED_FIELDS;
                                    this.setState({ errors: errors });
                                }
                            }
                        }
                    }
                }
                catch (e) {
                    this.getDeliveryChargesFun(3);
                }
            },
            error => {
                this.getDeliveryChargesFun(3);
            }
        );
    }

    handleContinueShopping() {
        const { StoreMainObject } = this.state;
        this.props.history.push(RouteConstant.OrderWebNavigation + "/" + StoreMainObject.ObjectId + "?web=" + StoreMainObject.websiteurl);
    }

    getStoreHoursAPI(storeid) {
        this.setState({ isActive: true })
        var API = ORDER_WEB_STORE_HOURS_API.replace("#STOREID#", storeid);
        GetData(API)
            .then(result => {
                var responseJson = result;
                console.log('Store Hours ', JSON.stringify(responseJson));
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData && responseData.length > 0) {
                        var MasterStoreHourlist = responseData ? responseData : [];

                        /* Store Current Time */
                        var StoreCurrentDate = MasterStoreHourlist[0];
                        if (StoreCurrentDate && StoreCurrentDate.storecurrenttime) {
                            var strdate = StoreCurrentDate.storecurrenttime.slice(0, -1);
                            const scurrent = Moment(strdate);

                            let cartList = this.state.cartList;
                            cartList = cartList.map((obj, index) => {
                                let DeliveryDate = "", deliveryTime = ""
                                var StoreTimeObject = MasterStoreHourlist.filter(e => e.day == scurrent.format("dddd"))[0];
                                if (obj.IsPickupEnable) {
                                    if (StoreTimeObject &&
                                        StoreTimeObject.ispickupenable &&
                                        StoreTimeObject.currentpickuptimes &&
                                        StoreTimeObject.currentpickuptimes.length > 0) {
                                        DeliveryDate = scurrent.format("DD MMM YYYY");
                                        deliveryTime = StoreTimeObject.currentpickuptimes[0];

                                    }
                                }
                                else if (obj.IsDeliveryEnable) {
                                    if (StoreTimeObject &&
                                        StoreTimeObject.isdeliveryenable &&
                                        StoreTimeObject.currentdeliverytimes &&
                                        StoreTimeObject.currentdeliverytimes.length > 0) {
                                        DeliveryDate = scurrent.format("DD MMM YYYY");
                                        deliveryTime = StoreTimeObject.currentdeliverytimes[0];
                                    }
                                }
                                return {
                                    ...obj,
                                    DeliveryDate: DeliveryDate,
                                    DeliveryTime: deliveryTime,
                                    StoreHourList: MasterStoreHourlist
                                }
                            });

                            this.setState({
                                cartList: cartList,
                                masterstorehours: MasterStoreHourlist,
                                isStoretimerender: true
                            });

                            /* Update and refresh Data */
                            this.handleChangeRadioBtn(this.state.selectedValue);
                        }
                    }
                    console.log("Cart List Model", JSON.stringify(this.state.cartList));
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })
            }).catch(err => {
                console.log(err);
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    getCouponAPI(storeid, plazaid) {
        this.setState({ isActive: true });
        var API = ORDER_WEB_STORE_COUPON_LIST_API.replace("#STOREID#", storeid) + "?" + PLAZAID + plazaid;
        GetData(API)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    this.setState({ couponList: responseData ? responseData : [] });
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false })
            }).catch(err => {
                console.log(err);
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    selecteUpdateAddress = (addressObj) => {
        var addressObjlength = Object.keys(addressObj).length;
        if (addressObjlength > 0) {
            this.setState({ userAddress: addressObj }, function () {
                this.getDeliveryChargesFun(1);
            })
        }
    }

    selectUpdateAddressindex = (index) => {
        this.setState({ checkboxId: index });
    }

    setSubTotal() {
        var cartList = this.state.cartList;
        const that = this
        var isPickupAvailable = false, isDeliveryAvailable = false
        if (cartList.length > 0) {
            var plazaTotalAmount = 0.0, TotalItemTax = 0.0, plazaSubtotel_Tfreep = 0.0, totalItemTax_TfreeP = 0.0, totalproductTax = 0.0
            let list = cartList
                .map((item, index) => {
                    isPickupAvailable = item.IsPickupEnable
                    isDeliveryAvailable = item.IsDeliveryEnable
                    var storeItemList = item.Items;
                    storeItemList.map(function (objitem, index2) {
                        var data = objitem;

                        plazaTotalAmount = plazaTotalAmount + ((data.TotalItemPrice / data.ItemQuantity) * data.ItemQuantity);
                        if (!data.IsProductTax) {
                            plazaSubtotel_Tfreep = plazaSubtotel_Tfreep + ((data.TotalItemPrice / data.ItemQuantity) * data.ItemQuantity)
                        }

                        TotalItemTax = TotalItemTax + (((data.TotalItemPrice / data.ItemQuantity) * data.ItemQuantity) * data.ItemTax)
                        if (!data.IsProductTax) {
                            totalItemTax_TfreeP = totalItemTax_TfreeP + (((data.TotalItemPrice / data.ItemQuantity) * data.ItemQuantity) * data.ItemTax)
                        }

                        //// produxt tax total //
                        if (objitem.itemLevelTax && !data.IsProductTax) {
                            for (var taxObj of objitem.itemLevelTax) {
                                if (that.state.selectedValue == "1") {
                                    totalproductTax = totalproductTax + (
                                        (((data.TotalItemPrice / data.ItemQuantity) * data.ItemQuantity)) * (Number(taxObj.TaxRate) / 100))
                                }
                                else {
                                    totalproductTax = totalproductTax + (
                                        (((data.TotalItemPrice / data.ItemQuantity) * data.ItemQuantity) + data.specialdeliverycharges) * (Number(taxObj.TaxRate) / 100))
                                }
                            }
                        }
                        //
                    });
                });
            this.setState({
                plazaSubtotel_Tfreep: plazaSubtotel_Tfreep,
                totalItemTax_TfreeP: totalItemTax_TfreeP,
                subtotal: plazaTotalAmount,
                isPickupAvailable: isPickupAvailable,
                isDeliveryAvailable: isDeliveryAvailable,
                totalItemTax: TotalItemTax,
                totalproductTax: totalproductTax
            }, function () {
                if (isPickupAvailable) {
                    this.handleChangeRadioBtn("1")
                }
                else if (isDeliveryAvailable) {
                    this.handleChangeRadioBtn("2")
                }
            });
        }
    }

    getNewSubtotalset() {
        var cartList = this.state.cartList;
        const that = this
        var isPickupAvailable = false, isDeliveryAvailable = false
        if (cartList.length > 0) {
            var plazaTotalAmount = 0.0, TotalItemTax = 0.0, plazaSubtotel_Tfreep = 0.0, totalItemTax_TfreeP = 0.0, totalproductTax = 0.0
            let list = cartList
                .map((item, index) => {
                    isPickupAvailable = item.IsPickupEnable
                    isDeliveryAvailable = item.IsDeliveryEnable
                    var storeItemList = item.Items;
                    storeItemList.map(function (objitem, index2) {
                        var data = objitem;

                        plazaTotalAmount = plazaTotalAmount + ((data.TotalItemPrice / data.ItemQuantity) * data.ItemQuantity);
                        if (!data.IsProductTax) {
                            plazaSubtotel_Tfreep = plazaSubtotel_Tfreep + ((data.TotalItemPrice / data.ItemQuantity) * data.ItemQuantity)
                        }


                        TotalItemTax = TotalItemTax + (((data.TotalItemPrice / data.ItemQuantity) * data.ItemQuantity) * data.ItemTax)
                        if (!data.IsProductTax) {
                            totalItemTax_TfreeP = totalItemTax_TfreeP + (((data.TotalItemPrice / data.ItemQuantity) * data.ItemQuantity) * data.ItemTax)
                        }

                        //// produxt tax total //
                        if (objitem.itemLevelTax && !data.IsProductTax) {
                            for (var taxObj of objitem.itemLevelTax) {
                                if (that.state.selectedValue == "1") {
                                    totalproductTax = totalproductTax + (
                                        (((data.TotalItemPrice / data.ItemQuantity) * data.ItemQuantity)) * (Number(taxObj.TaxRate) / 100))
                                }
                                else {
                                    totalproductTax = totalproductTax + (
                                        (((data.TotalItemPrice / data.ItemQuantity) * data.ItemQuantity) + data.specialdeliverycharges) * (Number(taxObj.TaxRate) / 100))
                                }
                            }
                        }
                        //
                    });
                });
            this.setState({
                plazaSubtotel_Tfreep: plazaSubtotel_Tfreep,
                totalItemTax_TfreeP: totalItemTax_TfreeP,
                subtotal: plazaTotalAmount,
                isPickupAvailable: isPickupAvailable,
                isDeliveryAvailable: isDeliveryAvailable,
                totalItemTax: TotalItemTax,
                totalproductTax: totalproductTax
            }, () => {
                this.countCADTotal1()
            });
        }
    }

    countCADTotal1() {
        var TotalCAD = 0.0, TotalCAD_TfreeP = 0.0;
        var taxValue = 0.0, totalDelivery_Charges = 0.0;
        var subTotal = this.state.subtotal;
        // var serviceFee = 0.0 /* this.state.countryFees.ServiceFee; */ /* Changes are made based on the call discussion. Date 10 Aug 2022 */
        var totalItemTax = this.state.totalItemTax
        // serviceFee = serviceFee * this.state.cartList.length;
        // this.setState({ serviceFee: serviceFee });
        var deliveryCharge = this.state.deliveryCharge;
        var specialdeliverycharges = this.state.specialdeliverycharges

        // /////////////////// tax free product //////////////////////
        var plazaSubtotel_Tfreep = this.state.plazaSubtotel_Tfreep
        var specialdeliverycharges_TfreeP = this.state.specialdeliverycharges_TfreeP
        var totalItemTax_TfreeP = this.state.totalItemTax_TfreeP
        // /////////////////// tax free product //////////////////////

        if (this.state.selectedValue === "2") {
            // + parseFloat(serviceFee)
            TotalCAD = parseFloat(subTotal) /* + parseFloat(deliveryCharge) */ + parseFloat(specialdeliverycharges) + totalItemTax;

            // /////////// tax free product /////////////////
            // + parseFloat(serviceFee)
            TotalCAD_TfreeP = parseFloat(plazaSubtotel_Tfreep) /* + parseFloat(deliveryCharge) */ + parseFloat(specialdeliverycharges_TfreeP) + totalItemTax_TfreeP
            // /////////// tax free product /////////////////

            if (this.state.taxDetails.length > 0) {
                this.state.taxDetails.map(function (item, index) {
                    var obj = item;
                    var value = Object.values(obj);
                    var keyValue = (TotalCAD_TfreeP * value) / 100;
                    taxValue = parseFloat(taxValue) + parseFloat(keyValue);
                    totalDelivery_Charges = totalDelivery_Charges + (deliveryCharge * value) / 100;
                })
            }
            totalDelivery_Charges = parseFloat((Math.round((totalDelivery_Charges + deliveryCharge) * 100) / 100).toFixed(2))
            TotalCAD = parseFloat(TotalCAD) + parseFloat(taxValue) + parseFloat(this.state.tipAmount) + parseFloat(this.state.totalproductTax) + totalDelivery_Charges
            this.setState({ totalCAD: TotalCAD }, function () {
                this.TotalApplicationFeeWithfinalTotal();
            });
        } else {
            // + parseFloat(serviceFee)
            TotalCAD = parseFloat(subTotal) + totalItemTax

            // /////////// tax free product /////////////////   
            // + parseFloat(serviceFee)
            TotalCAD_TfreeP = parseFloat(plazaSubtotel_Tfreep) + totalItemTax_TfreeP
            // /////////// tax free product /////////////////

            if (this.state.taxDetails.length > 0) {
                this.state.taxDetails.map(function (item, index) {
                    var obj = item;
                    var value = Object.values(obj);
                    var keyValue = (TotalCAD_TfreeP * value) / 100;
                    taxValue = parseFloat(taxValue) + parseFloat(keyValue);

                })
            }
            TotalCAD = parseFloat(TotalCAD) + parseFloat(taxValue) + parseFloat(this.state.totalproductTax)
            this.setState({ totalCAD: TotalCAD }, function () {
                this.TotalApplicationFeeWithfinalTotal();
            });
        }
    }

    //////////////////////////////////// understand ///////////////////////////////////////

    setDeliveryCharge = value => {
        var cost = parseFloat(value)
        this.setState({ deliveryCharge: cost }, function () {
            this.countCADTotal1();
        });
    }

    handleChangeRadioBtn(value) {
        var errors = {};
        errors['dateandtimes'] = ""
        this.setState({ errors })

        /////////////////////////////////////////////////////////////////
        var fineldeliveryCharges = 0.0
        var specialdeliverycharges = 0.0, specialdeliverycharges_TfreeP = 0.0

        /* Store Current Time */
        var StoreCurrentDate = this.state.masterstorehours[0];
        if (StoreCurrentDate && StoreCurrentDate.storecurrenttime) {
            var strdate = StoreCurrentDate.storecurrenttime.slice(0, -1);
            const scurrent = Moment(strdate);

            var StoreTimeObject = this.state.masterstorehours.filter(e => e.day == scurrent.format("dddd"))[0];

            var cartList = this.state.cartList.map(e2 => {
                var plazzadeliveryCharge = 0.0;

                if (value == "1") {
                    if (StoreTimeObject && StoreTimeObject.ispickupenable &&
                        StoreTimeObject.currentpickuptimes &&
                        StoreTimeObject.currentpickuptimes.length > 0) {
                        e2.DeliveryDate = scurrent.format("DD MMM YYYY");
                        e2.DeliveryTime = StoreTimeObject.currentpickuptimes[0];
                    } else {
                        e2.DeliveryDate = ""
                        e2.DeliveryTime = ""
                    }
                    e2.TotalDeliveryCharges = 0;
                }
                else {
                    if (StoreTimeObject &&
                        StoreTimeObject.isdeliveryenable &&
                        StoreTimeObject.currentdeliverytimes &&
                        StoreTimeObject.currentdeliverytimes.length > 0) {
                        e2.DeliveryDate = scurrent.format("DD MMM YYYY");
                        e2.DeliveryTime = StoreTimeObject.currentdeliverytimes[0];
                    } else {
                        e2.DeliveryDate = ""
                        e2.DeliveryTime = ""
                    }
                    e2.TotalDeliveryCharges = 0;
                    plazzadeliveryCharge = 0;
                    e2.Items.map(e3 => {
                        if (e3.IsSpecialDeliveryItem) {
                            specialdeliverycharges = specialdeliverycharges + e3.specialdeliverycharges
                            if (!e3.IsProductTax) {
                                specialdeliverycharges_TfreeP = specialdeliverycharges_TfreeP + e3.specialdeliverycharges
                            }
                        }
                    });
                }
                fineldeliveryCharges = fineldeliveryCharges + plazzadeliveryCharge
                console.log("============== shop list ======>", e2)
                return { ...e2 }
            });

            this.setState({ deliveryCharge: fineldeliveryCharges, errors: {} });

            this.setState({
                specialdeliverycharges: specialdeliverycharges,
                specialdeliverycharges_TfreeP: specialdeliverycharges_TfreeP
            })

            //////////////////////////////////////////////////////////////////

            this.setState({ cartList: cartList, tempSelectedCoupon: null, selectedCoupon: null }, function () {
                this.getDeliveryChargesFun(1)
            })
        }

        this.setState({ selectedValue: value }, function () {
            this.getNewSubtotalset();
        });

        if (value === "2") {
            this.setState({ timeDayHeader: "Delivery" });
            if (this.state.sessionToken && this.state.userDetails && this.state.userDetails._id && this.state.SessionUserType == UserSessionTypes.PLAZACART_CUSTOMER) {
                if (document.getElementById("addAddress")) {
                    document.getElementById("addAddress").style.display = "block";
                }
                if (document.getElementById("addAccountUser")) {
                    document.getElementById("addAccountUser").style.display = "none";
                }
            }
            else {
                if (document.getElementById("addAccountUser")) {
                    document.getElementById("addAccountUser").style.display = "block";
                }
            }
            if (document.getElementById("deliveryCharges")) {
                document.getElementById("deliveryCharges").style.display = "block";
            }
            document.getElementById("specialdeliveryCharges").style.display = "block";
            document.getElementById("deliveryTip").style.display = "block";
            this.setState({ deliveryCostErrMsg: "", isDeliveryCostres: false })
        } else {
            this.setState({ timeDayHeader: "Pickup" });
            if (this.state.sessionToken && this.state.userDetails && this.state.userDetails._id && this.state.SessionUserType == UserSessionTypes.PLAZACART_CUSTOMER) {
                if (document.getElementById("addAddress")) {
                    document.getElementById("addAddress").style.display = "none";
                }
                if (document.getElementById("addAccountUser")) {
                    document.getElementById("addAccountUser").style.display = "none";
                }
            } else {
                if (document.getElementById("addAccountUser")) {
                    document.getElementById("addAccountUser").style.display = "block";
                }
            }
            if (document.getElementById("deliveryCharges")) {
                document.getElementById("deliveryCharges").style.display = "none";
            }
            document.getElementById("specialdeliveryCharges").style.display = "none";
            document.getElementById("deliveryTip").style.display = "none";
            this.setState({ deliveryCostErrMsg: "", isDeliveryCostres: true })
        }
    }

    getDeliveryChargesFun(ERRORFLAG) {
        var isValidAddress = true;
        var isValidDeliveryDate = true;
        var isValidDeliveryTime = true;
        var errors = {};

        var postData = {};

        /* For Guest Flow Delivery Charge */
        var TempAddress = {
            ApartmentNumber: "",
            BussinesName: "",
            CountryCode: this.state.countryFees.CountryCode,
            DeliveryInstructions: "",
            PostalCode: this.state.guestuserFields.PostalCode,
            StreetAddress: this.state.selectfulladdressdetail
        }

        var userAddress = (!this.state.sessionToken || this.state.SessionUserType == UserSessionTypes.GUEST_CUSTOMER) ? TempAddress : this.state.userAddress;
        postData["dropoff_address"] = userAddress.ApartmentNumber + "," + userAddress.StreetAddress + "," + userAddress.PostalCode;

        if (!userAddress.StreetAddress && !userAddress.PostalCode) {
            isValidAddress = false;
        }

        var userDetails = (!this.state.sessionToken || this.state.SessionUserType == UserSessionTypes.GUEST_CUSTOMER) ? this.state.guestuserFields : this.state.userDetails;
        postData["dropoff_contact_given_name"] = (!this.state.sessionToken || this.state.SessionUserType == UserSessionTypes.GUEST_CUSTOMER) ? (userDetails.FirstName + " " + userDetails.LastName) : (userDetails.FirstName + " " + userDetails.LastName);
        postData["dropoff_phone_number"] = formatToDigit((!this.state.sessionToken || this.state.SessionUserType == UserSessionTypes.GUEST_CUSTOMER) ? userDetails.PhoneNumber : userDetails.Phone1)

        let cartList = this.state.cartList;
        for (var obj of cartList) {
            if (!obj.DeliveryDate) {
                isValidDeliveryDate = false;
            }
            if (!obj.DeliveryTime) {
                isValidDeliveryTime = false;
            }
            postData["dropdate"] = Moment(obj.DeliveryDate).format(DateFormate.YYYYMMDD);
            postData["droptimeinhour"] = TimeToHours(obj.DeliveryTime);
            postData["storeid"] = obj.ObjectId
        }

        if (this.state.selectedValue == "2") {
            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            if (isValidAddress && isValidDeliveryDate && isValidDeliveryTime) {
                if (ERRORFLAG == 3) {
                    let errors = {};
                    errors["PostalCode"] = Messages.v_EnterValidPostalCode;
                    errors["CommonError"] = Messages.GUEST_USER_REQUIRED_FIELDS;
                    this.setState({ errors: errors });
                } else {
                    this.setState({ errors: {} });
                }
                this.getDeliveryCharges(postData, authToken, postData.storeid);
            } else {
                if (!isValidAddress) {
                    if (!this.state.sessionToken || this.state.SessionUserType == UserSessionTypes.GUEST_CUSTOMER) {
                        if (ERRORFLAG != 2) {
                            errors['Address'] = Messages.v_EnterdeliveryAddress;
                        } else {
                            errors["Address"] = (this.state.countryFees.CountryCode && this.state.countryFees.CountryCode == "CA") ? Messages.v_Valid_Address.replace("state", "province") : Messages.v_Valid_Address;
                            errors["CommonError"] = Messages.GUEST_USER_REQUIRED_FIELDS;
                        }
                    } else {
                        errors['deliveryAddress'] = Messages.v_deliveryAddress;
                    }
                }
                if (!isValidDeliveryDate) {
                    errors['dateandtimes'] = Messages.v_SelectDateAndTime;
                }
                if (!isValidDeliveryTime) {
                    errors['dateandtimes'] = Messages.v_SelectDateAndTime;
                }
                this.setState({ errors: errors });
            }
        }
    }

    getDeliveryCharges(postData, authToken, storeId) {
        this.setState({ isActive: true, isDeliveryCostres: false });
        var URL = ORDER_WEB_STORE_DELIVERY_CHARGES_API.replace("#STOREID#", storeId);
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                console.log('Delivery Charge API', JSON.stringify(responseJson));
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    /* Delivery Charge Apply */
                    let cartList = this.state.cartList;
                    cartList = cartList.map(e => {
                        e.TotalDeliveryCharges = responseData.fee ? Number(responseData.fee) : 0;
                        return e
                    });
                    this.setState({ cartList: cartList, deliveryCharge: responseData.fee ? Number(responseData.fee) : 0, doordashmaximumorderamount: responseData.doordashmaximumorderamount ? Number(responseData.doordashmaximumorderamount) : 0, deliveryoption: responseData.deliveryoption ? responseData.deliveryoption : "" }, function () {
                        this.countCADTotal1()
                    });

                    this.setState({
                        deliveryCostErrMsg: "", isDeliveryCostres: true,
                        pickupordertime: responseData.pickupordertime
                    })
                }
                else if (responseJson["StatusCode"] == 400) {
                    /* Delivery Charge Apply */
                    let cartList = this.state.cartList;
                    cartList = cartList.map(e => {
                        e.TotalDeliveryCharges = 0;
                        return e
                    });
                    this.setState({
                        cartList: cartList,
                        deliveryCharge: 0, doordashmaximumorderamount: 0, deliveryoption: ""
                    }, function () {
                        this.countCADTotal1()
                    });

                    this.setState({ deliveryCostErrMsg: responseJson["Message"], isDeliveryCostres: false, pickupordertime: null })
                }
                else {
                    /* Delivery Charge Apply */
                    let cartList = this.state.cartList;
                    cartList = cartList.map(e => {
                        e.TotalDeliveryCharges = 0;
                        return e
                    });
                    this.setState({
                        cartList: cartList,
                        deliveryCharge: 0, doordashmaximumorderamount: 0, deliveryoption: ""
                    }, function () {
                        this.countCADTotal1()
                    });

                    this.setState({ deliveryCostErrMsg: Messages.ERR_SomethingWentWrong, isDeliveryCostres: false, pickupordertime: null })
                }
                this.setState({ isActive: false });
            }).catch(err => {
                /* Delivery Charge Apply */
                let cartList = this.state.cartList;
                cartList = cartList.map(e => {
                    e.TotalDeliveryCharges = 0;
                    return e
                });
                this.setState({
                    cartList: cartList,
                    deliveryCharge: 0, doordashmaximumorderamount: 0, deliveryoption: ""
                }, function () {
                    this.countCADTotal1()
                });

                this.setState({ isActive: false, deliveryCostErrMsg: Messages.ERR_SomethingWentWrong, isDeliveryCostres: false, pickupordertime: null })
            });
    }

    handleChange(event) {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        if (key == "MobileNumber") {
            userFields[key] = formatPhoneNumber(value)
        }
        else {
            userFields[key] = value
        }
        this.setState({ userFields })
    }

    handleChangeguest(event) {
        let guestuserFields = this.state.guestuserFields;
        let key = event.target.name
        let value = event.target.value;
        if (key == "PhoneNumber") {
            guestuserFields[key] = formatPhoneNumber(value)
        }
        else {
            guestuserFields[key] = value;
        }
        this.setState({ guestuserFields: guestuserFields });
    }

    handelChangeAddress(addressObj) {
        if (this.state.selectedValue == "1") {
            this.setState({ selectedAddress: addressObj });
        }
        let guestuserFields = this.state.guestuserFields;
        if (addressObj && addressObj.value && addressObj.value.place_id) {
            this.getaddressGooglePlace(addressObj.value.place_id, guestuserFields, addressObj);
        }
        else {
            guestuserFields["PostalCode"] = "";
            this.setState({ selectedAddress: addressObj });
        }
        this.setState({ guestuserFields: guestuserFields, selectfulladdressdetail: null, mainselectedAddress: null });
    }

    handleCheckBoxChange() {
        if (this.state.chkBoxChecked) {
            this.setState({ chkBoxChecked: false })
        } else {
            this.setState({ chkBoxChecked: true })
        }
    }

    getaddressGooglePlace(value, guestuserFields, addressObj) {
        this.setState({ isActive: true })
        var placeId = value
        console.log(placeId)
        geocodeByPlaceId(placeId)
            .then(result => {
                var responseJson = result ? result[0] : null;
                console.log("====", JSON.stringify(result))
                if (responseJson) {
                    var responseData = responseJson;
                    if (responseData && responseData.address_components) {
                        guestuserFields["PostalCode"] = "";

                        if (this.state.selectedValue != "1") {
                            var StateObject = responseData.address_components.filter(e => e.types && e.types.length > 0 && (e.types[0] === "administrative_area_level_1"))[0];
                            if (StateObject && StateObject.long_name && StateObject.short_name) {
                                var StoreMainObject = this.state.StoreMainObject;
                                if (StoreMainObject) {
                                    if (StoreMainObject.Statecode == StateObject.short_name) {
                                        var PostalCodeObject = responseData.address_components.filter(e => e.types && e.types.length > 0 && (e.types[0] === "postal_code"))[0];
                                        if (PostalCodeObject && PostalCodeObject.short_name) {
                                            guestuserFields["PostalCode"] = removeAllSpace(PostalCodeObject.short_name);
                                            var errors = {};
                                            errors["Address"] = "";
                                            errors["PostalCode"] = "";
                                            this.setState({ errors: errors });
                                            this.setState({ selectedAddress: addressObj });
                                            if (responseData && responseData.formatted_address) {
                                                this.setState({ selectfulladdressdetail: responseData.formatted_address ? responseData.formatted_address : "", mainselectedAddress: responseData });
                                            }
                                        } else {
                                            var errors = {};
                                            errors["Address"] = (this.state.countryFees.CountryCode && this.state.countryFees.CountryCode == "CA") ? Messages.v_Valid_Address.replace("state", "province") : Messages.v_Valid_Address;
                                            this.setState({ errors: errors });
                                        }
                                    } else {
                                        var errors = {};
                                        errors["Address"] = (this.state.countryFees.CountryCode && this.state.countryFees.CountryCode == "CA") ? Messages.v_Valid_Address.replace("state", "province") : Messages.v_Valid_Address;
                                        this.setState({ errors: errors });
                                    }
                                }
                            }
                        } else {
                            /* Billing Postal Code */
                            var PostalCodeObject = responseData.address_components.filter(e => e.types && e.types.length > 0 && (e.types[0] === "postal_code"))[0];
                            if (PostalCodeObject && PostalCodeObject.short_name) {
                                guestuserFields["PostalCode"] = removeAllSpace(PostalCodeObject.short_name);
                                var errors = {};
                                errors["Address"] = "";
                                errors["PostalCode"] = "";
                                this.setState({ selectedAddress: addressObj, errors: errors });
                                if (responseData && responseData.formatted_address) {
                                    this.setState({
                                        selectfulladdressdetail: responseData.formatted_address ? responseData.formatted_address : "", mainselectedAddress: responseData
                                    });
                                }
                            }
                        }
                    }
                    else {
                        guestuserFields["PostalCode"] = "";
                    }
                }
                else {
                    guestuserFields["PostalCode"] = "";
                }
                this.setState({ guestuserFields: guestuserFields, isActive: false }, function () {
                    this.getDeliveryChargesFun(2);
                });
            }).catch(err => {
                console.log(err)
                guestuserFields["PostalCode"] = "";
                this.setState({ guestuserFields: guestuserFields, isActive: false }, function () {
                    this.getDeliveryChargesFun(2);
                });
            });
    }

    handleValidationOnBlurGuest(e) {
        let fields = this.state.guestuserFields;
        let errors = {};
        let formIsValid = true;
        let name = e.currentTarget.name;

        if (!this.state.selectedAddress) {
            formIsValid = false;
            errors["Address"] = Messages.v_Address;
        }

        if (name == "PostalCode") {
            if (!fields["PostalCode"]) {
                formIsValid = false;
                errors["PostalCode"] = Messages.v_EnterPostalCode;
            }
        }

        if (name == "Email") {
            if (!fields["Email"]) {
                formIsValid = false;
                errors["Email"] = Messages.v_EnterEmail;
            }
            else if (typeof fields["Email"] !== "undefined") {
                if (!validateEmail(fields["Email"])) {
                    formIsValid = false;
                    errors["Email"] = Messages.v_EnterValidEmail;
                }
            }
        }

        if (name == "FirstName") {
            if (!fields["FirstName"]) {
                formIsValid = false;
                errors["FirstName"] = Messages.v_EnterName;
            }
            else if (typeof fields["FirstName"] !== "undefined") {
                if (!validateUserName(fields["FirstName"])) {
                    formIsValid = false;
                    errors["FirstName"] = Messages.v_EnterValidName;
                }
            }
        }

        if (name == "LastName") {
            if (!fields["LastName"]) {
                formIsValid = false;
                errors["LastName"] = Messages.v_EnterLastName;
            }
        }

        if (name == "PhoneNumber") {
            if (!fields["PhoneNumber"]) {
                formIsValid = false;
                errors["PhoneNumber"] = Messages.v_EnterPhoneNo
            }
            else if (typeof fields["PhoneNumber"] !== "undefined") {
                if (!validatePhoneNumber(fields["PhoneNumber"])) {
                    formIsValid = false;
                    errors["PhoneNumber"] = Messages.v_EnterMoNoFormate;
                }
            }
        }

        if (formIsValid) {
            errors[name] = "";
        }

        this.setState({
            guestuserFields: fields,
            errors: errors
        });
    }

    validateFormGuest() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.guestuserFields;

        if (!this.state.selectedAddress) {
            formIsValid = false;
            errors["Address"] = this.state.selectedValue != "1" ? Messages.v_EnterdeliveryAddress : Messages.v_Address;
        }

        if (!fields["PostalCode"]) {
            formIsValid = false;
            errors["PostalCode"] = Messages.v_EnterPostalCode;
        }

        if (!fields["Email"]) {
            formIsValid = false;
            errors["Email"] = Messages.v_EnterEmail;
        }
        else if (typeof fields["Email"] !== "undefined") {
            if (!validateEmail(fields["Email"])) {
                formIsValid = false;
                errors["Email"] = Messages.v_EnterValidEmail;
            }
        }

        if (!fields["FirstName"]) {
            formIsValid = false;
            errors["FirstName"] = Messages.v_EnterName;
        }
        else if (typeof fields["FirstName"] !== "undefined") {
            if (!validateUserName(fields["FirstName"])) {
                formIsValid = false;
                errors["FirstName"] = Messages.v_EnterValidName;
            }
        }

        if (!fields["LastName"]) {
            formIsValid = false;
            errors["LastName"] = Messages.v_EnterLastName;
        }

        if (!fields["PhoneNumber"]) {
            formIsValid = false;
            errors["PhoneNumber"] = Messages.v_EnterPhoneNo
        }
        else if (typeof fields["PhoneNumber"] !== "undefined") {
            if (!validatePhoneNumber(fields["PhoneNumber"])) {
                formIsValid = false;
                errors["PhoneNumber"] = Messages.v_EnterMoNoFormate;
            }
        }

        if (this.state.chkBoxChecked === false) {
            formIsValid = false;
            errors["termspolicy"] = Messages.v_Terms_And_Policy;
        }

        if (!formIsValid) {
            errors["CommonError"] = Messages.GUEST_USER_REQUIRED_FIELDS;
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleguestSave = () => {
        if (this.validateFormGuest()) {
            if (this.state.selectedValue != "1") {
                this.getgeocodercheckpostalcodeValid(this.state.guestuserFields.PostalCode, 1);
            }
        }
    }

    validateForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;

        if (!fields["tip"]) {
            formIsValid = false;
            errors["tip"] = Messages.v_EnterTip
        }

        if (isNaN(fields["tip"])) {
            formIsValid = false;
            errors["tip"] = Messages.v_EnterTip1
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleChangeTipRadioBtn(value) {
        let userFields = this.state.userFields
        userFields["tip"] = ""
        this.setState({ selectedTipValueTemp: value, errors: {}, userFields });
        if (value === "3") {
            this.setState({ disableDeliveryTip: false });
        } else {
            this.setState({ disableDeliveryTip: true });
        }
    }

    // add for modal method 18-may-2023
    handleAddColor(falg) {
        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById('myModal');

        if (backgroung && modalId) {
            backgroung.style.display = falg ? "block" : "none";
            modalId.style.display = falg ? "block" : "none";
        }
    }

    openDeliveryTipModal() {
        this.handleAddColor(true)
        let userFields = this.state.userFields
        if (this.state.selectedTipValue === "3") {
            userFields["tip"] = String(this.state.tipAmount)
        }
        else {
            userFields["tip"] = ""
        }
        this.setState({
            errors: {},
            selectedTipValueTemp: this.state.selectedTipValue,
            userFields: userFields
        })
    }

    closeDeliveryTipModal() {
        this.handleAddColor(false)
    }

    //

    handleDeliveryTip() {
        if (this.state.selectedTipValueTemp === "3") {
            // new code 18-may-2023
            if (this.validateForm()) {
                var tip = parseFloat(this.state.userFields.tip);
                var reversepreviousTip = parseFloat(this.state.totalCAD) - parseFloat(this.state.tipAmount)
                this.setState({ tipAmount: tip, totalCAD: reversepreviousTip }, () => {
                    var cad = parseFloat(this.state.totalCAD) + parseFloat(this.state.tipAmount);
                    this.setState({ totalCAD: cad, selectedTipValue: this.state.selectedTipValueTemp }, function () {
                        this.TotalApplicationFeeWithfinalTotal();
                    })
                });
                this.closeDeliveryTipModal()
            }
            //
        } else if (this.state.selectedTipValueTemp === "2") {
            var userFields = this.state.userFields;
            userFields["tip"] = "";
            this.setState({ userFields });
            var tip = 5.00;

            // new code 18-may-2023
            var tip = parseFloat(tip);
            var reversepreviousTip = parseFloat(this.state.totalCAD) - parseFloat(this.state.tipAmount)
            this.setState({ tipAmount: tip, totalCAD: reversepreviousTip }, () => {
                var cad = parseFloat(this.state.totalCAD) + parseFloat(this.state.tipAmount);
                this.setState({ totalCAD: cad, selectedTipValue: this.state.selectedTipValueTemp }, function () {
                    this.TotalApplicationFeeWithfinalTotal();
                })
            });
            //
            this.closeDeliveryTipModal()
        }
        else {
            var userFields = this.state.userFields;
            userFields["tip"] = "";
            this.setState({ userFields });

            // new code 18-may-2023
            var tip = 0.00;
            var reversepreviousTip = parseFloat(this.state.totalCAD) - parseFloat(this.state.tipAmount)
            this.setState({ tipAmount: tip, totalCAD: reversepreviousTip }, () => {
                var cad = parseFloat(this.state.totalCAD) + parseFloat(this.state.tipAmount);
                this.setState({ totalCAD: cad, selectedTipValue: this.state.selectedTipValueTemp }, function () {
                    this.TotalApplicationFeeWithfinalTotal();
                })
            });
            //
            this.closeDeliveryTipModal()
        }
    }

    handlePhoneSave = () => {
        var errors = {};
        if (!this.state.userFields.MobileNumber) {
            errors['MobileNumber'] = Messages.v_EnterMoNo;
            this.setState({ errors })
        }
        else if (this.state.userFields.MobileNumber && !validatePhoneNumber(this.state.userFields.MobileNumber)) {
            errors["MobileNumber"] = Messages.v_EnterMoNoFormate;
            this.setState({ errors })
        }
        else {
            errors['MobileNumber'] = "";
            this.setState({ errors })
            var userFields = this.state.userFields;
            var userDetails = this.state.userDetails;
            userDetails['Phone1'] = userFields["MobileNumber"];
            this.setState({ userDetails }, function () {
                this.getDeliveryChargesFun(1)
            })

            var finalCartObj = this.state.finalCartObj;
            finalCartObj.PhoneNumber = formatPhoneNumber(userFields.MobileNumber)
            this.setState({ finalCartObj })
        }
    }

    updateCartObj = (list) => {
        if (this.state.selectedValue !== "1") {
            var fineldeliveryCharges = 0.0;

            var list1 = list.map(objStore => {
                var deliveryplazzcharge = [];
                var plazzadeliveryCharge = 0.0;
                var singletimePlazzadeliveryCharge = 0.0;

                if (objStore.DeliveryDate !== "" && objStore.DeliveryTime !== "") {
                    const index = deliveryplazzcharge.findIndex((e) => (e.DeliveryDate === objStore.DeliveryDate && e.DeliveryTime === objStore.DeliveryTime));
                    if (index === -1 && objStore.DeliveryDate !== "") {
                        var obj = {}
                        obj['DeliveryDate'] = objStore.DeliveryDate;
                        obj['DeliveryTime'] = objStore.DeliveryTime;
                        obj['DeliveryPrice'] = objStore.TotalDeliveryCharges;
                        obj['IsSpecialDelivery'] = objStore.IsSpecialDelivery;
                        obj['specialdeliverycharges'] = objStore.specialdeliverycharges;
                        deliveryplazzcharge.push(obj);
                        plazzadeliveryCharge = plazzadeliveryCharge + objStore.TotalDeliveryCharges;
                    }
                    else {
                        if (objStore.TotalDeliveryCharges > deliveryplazzcharge[index].DeliveryPrice) {
                            plazzadeliveryCharge = plazzadeliveryCharge + objStore.TotalDeliveryCharges - deliveryplazzcharge[index].DeliveryPrice;
                            deliveryplazzcharge[index].DeliveryPrice = objStore.TotalDeliveryCharges;
                        }
                    }
                }
                fineldeliveryCharges = fineldeliveryCharges + plazzadeliveryCharge
                return { ...objStore }
            });

            this.setState({ /* deliveryCharge: fineldeliveryCharges */ }, function () {
                this.countCADTotal1();
                this.getDeliveryChargesFun(1);
            });
        }
        else {
            this.setState({ deliveryCharge: 0.0 });
            this.setState({ tipAmount: 0.0 });
        }

        this.setState({ cartList: list, errors: {} }, function () {
            console.log("updated1 ", this.state.cartList);
        });
    }

    handlePaymentForLoginUser() {
        if (this.state.selectedValue === "2") {
            var deliveryoption = this.state.deliveryoption;
            if (deliveryoption == DELIVERY_OPTIONS.DOORDASH) {
                /* For Doordash Delivery Order */
                var totalamount = 0;
                if (this.state.selectedCoupon) {
                    totalamount = Number(((Math.round(this.state.finalTotal * 100) / 100).toFixed(2)) - this.state.selectedCoupon.DicountPrice).toFixed(2);
                } else {
                    totalamount = Number((Math.round(this.state.finalTotal * 100) / 100).toFixed(2));
                }
                var doordashmaximumorderamount = Number(this.state.doordashmaximumorderamount);
                if (totalamount > doordashmaximumorderamount) {

                    var currencySymbole = "";
                    var countryFeesLocal = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES)
                    if (countryFeesLocal) {
                        currencySymbole = (countryFeesLocal[currencyInfo.CountryCode] ?
                            "(" + countryFeesLocal[currencyInfo.CountryCode] + ") " : "") + countryFeesLocal.Symbol;
                    }
                    var errors = {};
                    errors["CommonError"] = Messages.DoordashVaidationMsg.replace("##RATE##", "" + currencySymbole + doordashmaximumorderamount);
                    this.setState({ errors: errors });
                } else {
                    var errors = {};
                    errors["CommonError"] = "";
                    this.setState({ errors: errors });
                    this.createCartObject(1);
                }
            } else {
                /* For Plazacart Delivery Order */
                var totalamount = 0;
                if (this.state.selectedCoupon) {
                    totalamount = Number(((Math.round(this.state.finalTotal * 100) / 100).toFixed(2)) - this.state.selectedCoupon.DicountPrice).toFixed(2);
                } else {
                    totalamount = Number((Math.round(this.state.finalTotal * 100) / 100).toFixed(2));
                }
                var plazacartmaximumorderamount = Number(Constants.PLAZACARTMAXIMUMORDERAMOUNT);
                if (totalamount > plazacartmaximumorderamount) {

                    var currencySymbole = "";
                    var countryFeesLocal = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES)
                    if (countryFeesLocal) {
                        currencySymbole = (countryFeesLocal[currencyInfo.CountryCode] ?
                            "(" + countryFeesLocal[currencyInfo.CountryCode] + ") " : "") + countryFeesLocal.Symbol;
                    }
                    var errors = {};
                    errors["CommonError"] = Messages.PlazacartValidationMsg.replace("##RATE##", "" + currencySymbole + plazacartmaximumorderamount);
                    this.setState({ errors: errors });
                } else {
                    var errors = {};
                    errors["CommonError"] = "";
                    this.setState({ errors: errors });
                    this.createCartObject(1);
                }
            }
        } else {
            /* For Curbside */
            var totalamount = 0;
            if (this.state.selectedCoupon) {
                totalamount = Number(((Math.round(this.state.finalTotal * 100) / 100).toFixed(2)) - this.state.selectedCoupon.DicountPrice).toFixed(2);
            } else {
                totalamount = Number((Math.round(this.state.finalTotal * 100) / 100).toFixed(2));
            }
            var plazacartmaximumorderamount = Number(Constants.PLAZACARTMAXIMUMORDERAMOUNT);
            if (totalamount > plazacartmaximumorderamount) {

                var currencySymbole = "";
                var countryFeesLocal = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES)
                if (countryFeesLocal) {
                    currencySymbole = (countryFeesLocal[currencyInfo.CountryCode] ?
                        "(" + countryFeesLocal[currencyInfo.CountryCode] + ") " : "") + countryFeesLocal.Symbol;
                }
                var errors = {};
                errors["CommonError"] = Messages.PlazacartValidationMsg.replace("##RATE##", "" + currencySymbole + plazacartmaximumorderamount);
                this.setState({ errors: errors });
            } else {
                var errors = {};
                errors["CommonError"] = "";
                this.setState({ errors: errors });
                this.createCartObject(1);
            }
        }
    }

    handlePaymentForGuestUser() {
        console.log("check flag", this.validateFormGuest(), (this.state.selectedValue != "2" || this.state.isDeliveryCostres), this.validateformCartForGuestUser());
        if (this.validateFormGuest() && this.validateformCartForGuestUser() && (this.state.selectedValue != "2" || this.state.isDeliveryCostres)) {
            if (this.state.selectedValue != "1") {
                this.getgeocodercheckpostalcodeValid(this.state.guestuserFields.PostalCode, 2);
            } else {
                /* For Guest User Curbside */
                var totalamount = 0;
                if (this.state.selectedCoupon) {
                    totalamount = Number(((Math.round(this.state.finalTotal * 100) / 100).toFixed(2)) - this.state.selectedCoupon.DicountPrice).toFixed(2);
                } else {
                    totalamount = Number((Math.round(this.state.finalTotal * 100) / 100).toFixed(2));
                }
                var plazacartmaximumorderamount = Number(Constants.PLAZACARTMAXIMUMORDERAMOUNT);
                if (totalamount > plazacartmaximumorderamount) {

                    var currencySymbole = "";
                    var countryFeesLocal = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES)
                    if (countryFeesLocal) {
                        currencySymbole = (countryFeesLocal[currencyInfo.CountryCode] ?
                            "(" + countryFeesLocal[currencyInfo.CountryCode] + ") " : "") + countryFeesLocal.Symbol;
                    }
                    var errors = {};
                    errors["CommonError"] = Messages.PlazacartValidationMsg.replace("##RATE##", "" + currencySymbole + plazacartmaximumorderamount);
                    this.setState({ errors: errors });
                } else {
                    var errors = {};
                    errors["CommonError"] = "";
                    this.setState({ errors: errors });
                    this.createCartObject(2);
                }
            }
        }
    }

    handelCouponRadio = (couponObj) => {
        if (this.state.tempSelectedCoupon) {
            this.setState({
                tempSelectedCoupon: this.state.tempSelectedCoupon._id != couponObj._id ? couponObj : null,
                selectedCoupon: this.state.tempSelectedCoupon._id != couponObj._id ? this.state.selectedCoupon : null
            })
        }
        else {
            this.setState({ tempSelectedCoupon: couponObj })
        }
    }

    handelCoupon = () => {
        this.setState({ selectedCoupon: this.state.tempSelectedCoupon })
    }

    validateformCartForGuestUser() {
        let formIsValid = true;
        var errors = {};
        var plazzaList = this.state.cartList;
        for (var store of plazzaList) {
            if (this.state.selectedValue === '1') {
                if (!store.DeliveryTime) {
                    formIsValid = false;
                    errors['dateandtimes'] = Messages.v_SelectDateAndTime;
                    break;
                }
                if (store.DeliveryDate == "" || store.DeliveryTime == "") {
                    formIsValid = false;
                    errors['dateandtimes'] = Messages.v_SelectDateAndTime;
                    break;
                }
            }
            else {
                if (!store.DeliveryTime) {
                    formIsValid = false;
                    errors['dateandtimes'] = Messages.v_SelectDateAndTime;
                    break;
                }
                if (store.DeliveryDate == "" || store.DeliveryTime == "") {
                    formIsValid = false;
                    errors['dateandtimes'] = Messages.v_SelectDateAndTime;
                    break;
                }
            }
        }
        this.setState({ errors });
        return formIsValid;
    }

    validateformCart() {
        let formIsValid = true;
        var errors = {};
        var plazzaList = this.state.cartList;
        for (var store of plazzaList) {
            if (this.state.selectedValue === '1') {
                if (!store.DeliveryTime) {
                    formIsValid = false;
                    errors['dateandtimes'] = Messages.v_SelectDateAndTime;
                    break;
                }
                if (store.DeliveryDate == "" || store.DeliveryTime == "") {
                    formIsValid = false;
                    errors['dateandtimes'] = Messages.v_SelectDateAndTime;
                    break;
                }
            }
            else {
                if (!store.DeliveryTime) {
                    formIsValid = false;
                    errors['dateandtimes'] = Messages.v_SelectDateAndTime;
                    break;
                }
                if (store.DeliveryDate == "" || store.DeliveryTime == "") {
                    formIsValid = false;
                    errors['dateandtimes'] = Messages.v_SelectDateAndTime;
                    break;
                }
            }
        }
        if (this.state.selectedValue !== "1") {
            var addressObjlength = Object.keys(this.state.userAddress).length;
            if (addressObjlength == 0) {
                formIsValid = false;
                errors['deliveryAddress'] = Messages.v_deliveryAddress
            }
        }
        if (!this.state.userFields.MobileNumber) {
            formIsValid = false;
        }
        this.setState({ errors });
        return formIsValid;
    }

    createCartObject(FALG) {
        if (FALG == 1 ? this.validateformCart() : this.validateformCartForGuestUser()) {
            var IsPickupOrder = false;
            if (this.state.selectedValue === "1") {
                IsPickupOrder = true
            } else {
                IsPickupOrder = false
            }

            let that = this.state

            var TempAddress = {
                ApartmentNumber: "",
                BussinesName: "",
                CountryCode: this.state.countryFees.CountryCode,
                DeliveryInstructions: "",
                PostalCode: this.state.guestuserFields.PostalCode,
                StreetAddress: this.state.selectfulladdressdetail
            }

            if (FALG === 2) {
                var objStore = RetriveObjectFromStorage(Constants.Obj_STORE, '');
                if (objStore) {
                    objStore.UserBillingAddress = TempAddress;
                    StoreObjectInStorage(Constants.Obj_STORE, objStore);
                    this.setState({ StoreMainObject: objStore });
                }
            }

            var userAddress = FALG == 1 ? this.state.userAddress : TempAddress;
            var address = userAddress.ApartmentNumber + " " + userAddress.StreetAddress + " " + userAddress.PostalCode;

            var finalCartObj = this.state.finalCartObj;
            finalCartObj["CustomerEmail"] = FALG == 1 ? this.state.userDetails.Email : this.state.guestuserFields.Email;
            finalCartObj["PhoneNumber"] = formatPhoneNumber(FALG == 1 ? this.state.userDetails.Phone1 : this.state.guestuserFields.PhoneNumber)
            finalCartObj["IsPickupOrder"] = IsPickupOrder
            finalCartObj["Currency"] = this.state.countryFees.Currency
            finalCartObj["transactionid"] = "NONCE TOKEN FOR PAYMENT IN BACKEND";
            finalCartObj["CustomerID"] = this.state.userDetails._id ? this.state.userDetails._id : DEFAULTID
            if (this.state.selectedValue === "1") {
                finalCartObj["DeliveryAddress"] = ""
                finalCartObj["dCardAddress"] = undefined
            }
            else {
                finalCartObj["DeliveryAddress"] = address;
                finalCartObj["dCardAddress"] = userAddress
            }

            finalCartObj["Additionalinstructions"] = "";
            finalCartObj["isshopifyintegration"] = this.state.isShopifyAvailable;
            finalCartObj["Deliverytype"] = (this.state.selectedValue != "1") ? (this.state.deliveryoption ? this.state.deliveryoption : DELIVERY_OPTIONS.PLAZACART) : "";
            finalCartObj["deliverypickupdatetime"] = this.state.pickupordertime;

            var totalAmount = 0.0, totalAmount_TfreeP = 0.0;
            var totalTax = 0.0, totalTax_TfreeP = 0.0;
            var totalProductAmount = 0.0;
            var fineldeliveryCharges = 0.0;
            var specialdeliverycharges = 0.0, specialdeliverycharges_tfreeP = 0.0;
            var totalItemTax = 0.0, totalproductLevelTax = 0;

            var list = this.state.cartList.map(curr => {
                var storeSubTotal = 0.0, storeSubTotal_TfreeP = 0.0;
                // var serviceFee = (0.0 /* this.state.countryFees.ServiceFee */ * this.state.cartList.length); /* Changes are made based on the call discussion. Date 10 Aug 2022 */

                var itemList = curr.Items.map(objitem => {
                    var data = objitem;
                    storeSubTotal = storeSubTotal + ((data.TotalItemPrice / data.ItemQuantity) * data.ItemQuantity) + (((data.TotalItemPrice / data.ItemQuantity) * data.ItemQuantity) * data.ItemTax)
                    objitem.TotalItemTax = ((data.TotalItemPrice / data.ItemQuantity) * data.ItemQuantity) * data.ItemTax

                    // ////////////////////// tax free product ///////////////////////////////////
                    if (!data.IsProductTax) {
                        storeSubTotal_TfreeP = storeSubTotal_TfreeP + ((data.TotalItemPrice / data.ItemQuantity) * data.ItemQuantity) + (((data.TotalItemPrice / data.ItemQuantity) * data.ItemQuantity) * data.ItemTax)
                    }
                    // ////////////////////// tax free product ///////////////////////////////////

                    if (objitem.IsSpecialDeliveryItem) {
                        if (that.selectedValue === "1") {
                            objitem.specialdeliverycharges = 0.0
                            specialdeliverycharges = 0.0
                            specialdeliverycharges_tfreeP = 0.0
                        }
                        else {
                            specialdeliverycharges = specialdeliverycharges + objitem.specialdeliverycharges
                            objitem.specialdeliverycharges = objitem.specialdeliverycharges

                            // ////////////////////// tax free product ///////////////////////////////////
                            if (!data.IsProductTax) {
                                specialdeliverycharges_tfreeP = specialdeliverycharges_tfreeP + objitem.specialdeliverycharges
                                objitem.specialdeliverycharges = objitem.specialdeliverycharges
                            }
                            // ////////////////////// tax free product ///////////////////////////////////
                        }
                    }
                    if (objitem.variant_id) {
                        objitem.ItemId = DEFAULTID
                    }

                    //// produxt tax total //
                    if (objitem.itemLevelTax && !data.IsProductTax) {
                        for (var taxObj of objitem.itemLevelTax) {
                            if (this.state.selectedValue == "1") {
                                totalproductLevelTax += (
                                    (((data.TotalItemPrice / data.ItemQuantity) * data.ItemQuantity)) * (Number(taxObj.TaxRate) / 100))
                            }
                            else {
                                totalproductLevelTax += (
                                    (((data.TotalItemPrice / data.ItemQuantity) * data.ItemQuantity) + data.specialdeliverycharges) * (Number(taxObj.TaxRate) / 100))
                            }
                        }
                    }
                    //

                    return { ...objitem }

                })

                var taxValue = 0.0, taxValue_TfreeP = 0.0, StoreTaxDetail = []
                var totalDeliveryCharges = curr.TotalDeliveryCharges + specialdeliverycharges;
                var totalDeliveryCharges_TfreeP = /* curr.TotalDeliveryCharges + */ specialdeliverycharges_tfreeP;

                // new add on 22-june-2023
                let totalDeliveryChargewithTax = 0, temltotalDeliveryChargewithTax = curr.TotalDeliveryCharges;
                //
                if (this.state.taxDetails.length > 0) {
                    this.state.taxDetails.map(function (item, index) {
                        var obj = item;
                        var value = Object.values(obj);
                        // + serviceFee
                        var keyValue = ((storeSubTotal + curr.TotalDeliveryCharges) * value) / 100;
                        taxValue = parseFloat(taxValue) + parseFloat(keyValue);

                        // ////////////////////// tax free product ///////////////////////////////////
                        // + serviceFee
                        var keyValue_TfreeP = ((storeSubTotal_TfreeP + totalDeliveryCharges_TfreeP) * value) / 100;
                        totalTax_TfreeP = parseFloat(totalTax_TfreeP) + parseFloat(keyValue_TfreeP);
                        // ////////////////////// tax free product ///////////////////////////////////

                        var storeTaxObj = {}
                        storeTaxObj.TaxRate = parseFloat(value)
                        storeTaxObj.TaxType = String(Object.keys(item))
                        storeTaxObj.TotalTax = parseFloat(keyValue_TfreeP)
                        StoreTaxDetail.push(storeTaxObj)

                        totalDeliveryChargewithTax = totalDeliveryChargewithTax + ((temltotalDeliveryChargewithTax * value) / 100)

                    });
                }

                totalDeliveryChargewithTax = Number((Math.round((totalDeliveryChargewithTax + temltotalDeliveryChargewithTax) * 100) / 100).toFixed(2));

                const { countryFees } = this.state;
                if (true) {
                    var storeTaxObj = {}
                    storeTaxObj.TaxRate = "0"
                    storeTaxObj.TaxType = "Estimated tax"
                    storeTaxObj.TotalTax = Number(totalTax_TfreeP + totalproductLevelTax).toFixed(2)
                    StoreTaxDetail = [storeTaxObj]
                }

                var couponDetail = this.state.selectedCoupon, postCouponDetail = {}
                if (couponDetail) {
                    postCouponDetail["couponid"] = couponDetail._id
                    postCouponDetail["discountamount"] = couponDetail.DicountPrice
                    postCouponDetail["coupontitle"] = couponDetail.Title
                    postCouponDetail["coupondescription"] = couponDetail.Description
                }
                finalCartObj["DeliveryTime"] = curr.DeliveryTime;
                finalCartObj["DeliveryDate"] = curr.DeliveryDate;
                finalCartObj["TotalProductAmount"] = (Math.round((storeSubTotal) * 100) / 100).toFixed(2).toString();
                finalCartObj["TipAmount"] = Number(this.state.tipAmount).toFixed(2);
                finalCartObj["ServiceFeeAmount"] = 0 /* serviceFee */
                finalCartObj["TotalDeliveryAmount"] = totalDeliveryChargewithTax + specialdeliverycharges //totalDeliveryCharges;
                finalCartObj["TotalTaxes"] = Number(totalTax_TfreeP + totalproductLevelTax).toFixed(2);
                finalCartObj["TaxDetails"] = StoreTaxDetail;
                finalCartObj["CouponDetail"] = couponDetail ? postCouponDetail : null
                finalCartObj["plazaid"] = curr.PlazaId;
                finalCartObj["storeid"] = curr.ObjectId;
                finalCartObj["ApplicationFee"] = Number(this.state.applicationfeetotal).toFixed(2);
                // + serviceFee
                finalCartObj["TotalAmount"] = Number(storeSubTotal + finalCartObj["TotalDeliveryAmount"] /*  totalDeliveryCharges */ + Number(finalCartObj["TotalTaxes"]) /* totalTax_TfreeP */ + this.state.tipAmount + Number(finalCartObj["ApplicationFee"]) - (this.state.selectedCoupon ? this.state.selectedCoupon.DicountPrice : 0)).toFixed(2);
                finalCartObj["Items"] = itemList;
                return { ...curr }
            });
            this.setState({ finalCartObj: finalCartObj }, function () {
                this.callPostOrderAPI(this.state.finalCartObj, FALG);
            });
        }
    }

    callPostOrderAPI(postData, FALG) {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        console.log("finalCartObj ==========", postData);
        StoreObjectInStorage(Constants.finelObj_BeforePayemt, postData);
        if (FALG === 1) {
            this.props.history.push({
                pathname: RouteConstant.OrderWebPayment,
            });
        } else {
            var postData = {};
            postData = this.state.guestuserFields;
            postData["CountryCode"] = this.state.countryFees.CountryCode;
            postData["PostalCode"] = this.state.guestuserFields.PostalCode ? this.state.guestuserFields.PostalCode.toUpperCase() : "";
            postData["DeviceId"] = "";
            postData["DeviceType"] = "";
            postData["PushToken"] = "";
            postData["ipaddress"] = this.state.ipAddress;
            postData["browser"] = browserName;
            postData["browserversion"] = fullBrowserVersion;
            postData["osdetails"] = osName + " " + osVersion;
            postData["customertype"] = "GUEST_CUSTOMER";
            if (this.state.selectfulladdressdetail) {
                postData["location"] = this.state.selectfulladdressdetail;
            } else {
                postData["location"] = (this.state.guestuserFields.PostalCode ? this.state.guestuserFields.PostalCode.toUpperCase() : "") + "," + this.state.countryFees.CountryCode;
            }
            postData["locale"] = this.state.browserLanguage;
            postData["Latitude"] = this.state.alatitude;
            postData["Longitude"] = this.state.alongitude;
            postData["questionid"] = DEFAULTID;
            postData["answer"] = "";
            postData["userid"] = "";
            postData["googlecaptcharesponse"] = "";

            console.log('===>postData', postData);
            console.log("====post", JSON.stringify(postData));

            this.GuestUserRegister(postData);
        }
    }

    /* Guest Register */
    GuestUserRegister(postData) {
        this.setState({ isActive: true })
        var URL = USER_REGISTRATION_API;
        PostData(URL, postData)
            .then(result => {
                var responseJson = result;
                console.log("GuestUserRegister", responseJson);
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.handleValidOTPModalview(true);
                }
                else if (responseJson["StatusCode"] === 400) {
                    let errors = {};
                    errors["CommonError"] = "Email ID already exists";
                    this.setState({ errors: errors });
                }
                else {
                    let errors = {};
                    errors["CommonError"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false });
            }).catch(err => {
                let errors = {};
                errors["CommonError"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }



    renderTaxDetailsCA(data, countryFees, currencySymbole) {
        var amount = 0.0, totalItemTax_TfreeP = this.state.totalItemTax_TfreeP;
        if (this.state.selectedValue === "1") {
            // var serviceFee = 0.0 /* countryFees.ServiceFee */ * this.state.cartList.length; /* Changes are made based on the call discussion. Date 10 Aug 2022 */
            amount = parseFloat(this.state.plazaSubtotel_Tfreep) + parseFloat(totalItemTax_TfreeP);
        } else {
            // var serviceFee = 0.0 /* countryFees.ServiceFee */ * this.state.cartList.length; /* Changes are made based on the call discussion. Date 10 Aug 2022 */ + parseFloat(serviceFee)
            amount = parseFloat(this.state.plazaSubtotel_Tfreep) + /* parseFloat(this.state.deliveryCharge)  + */ parseFloat(this.state.specialdeliverycharges_TfreeP) + parseFloat(totalItemTax_TfreeP);
        }
        return data.map(e => {
            var obj = e;
            var key = Object.keys(obj);
            var value = Object.values(obj);
            var keyvalue = (amount * value) / 100;
            return (<React.Fragment>
                <div className="row m-0">
                    <label style={{ color: Color.BLACK }}> {key} {"(" + value + "%)"}</label>
                    <label style={{ marginLeft: "auto", color: Color.BLACK, fontWeight: '500' }}>{currencySymbole}{(Math.round(keyvalue * 100) / 100).toFixed(2)}</label>
                </div>
            </React.Fragment>
            )
        })
    }

    renderTaxDetailsUSA(data, countryFees, currencySymbole) {
        var amount = 0.0
        let totalItemTax_TfreeP = this.state.totalItemTax_TfreeP;
        let plazaSubtotel_Tfreep = this.state.plazaSubtotel_Tfreep;
        let productIndTax = 0, totalDefaultTax = 0

        if (this.state.selectedValue === "1") {
            // var serviceFee = 0.0 /* countryFees.ServiceFee */ * this.state.cartList.length; /* Changes are made based on the call discussion. Date 10 Aug 2022 */ + parseFloat(serviceFee)
            amount = parseFloat(plazaSubtotel_Tfreep) + parseFloat(totalItemTax_TfreeP);
            productIndTax = this.state.totalproductTax;
        } else {
            // var serviceFee = 0.0 /* countryFees.ServiceFee */ * this.state.cartList.length; /* Changes are made based on the call discussion. Date 10 Aug 2022 */ + parseFloat(serviceFee)
            amount = parseFloat(plazaSubtotel_Tfreep) /* + parseFloat(this.state.deliveryCharge) */ + parseFloat(this.state.specialdeliverycharges_TfreeP) + parseFloat(totalItemTax_TfreeP);
            productIndTax = this.state.totalproductTax;
        }

        data.map(e => {
            var obj = e;
            var key = Object.keys(obj);
            var value = Object.values(obj);
            var keyvalue = (amount * value) / 100;
            totalDefaultTax = totalDefaultTax + keyvalue
        })

        return <div className="row m-0">
            <label style={{ color: Color.BLACK }}> Estimated tax {"(%)"} {/* {productIndTax + " - " + totalDefaultTax} */} </label>
            <label style={{ marginLeft: "auto", color: Color.BLACK, fontWeight: '500' }}>{currencySymbole}  {(Math.round((productIndTax + totalDefaultTax) * 100) / 100).toFixed(2)}</label>
        </div>

    }

    /* Calculation Application Fee */
    TotalApplicationFeeWithfinalTotal() {
        var data = this.state.totalCAD;
        var applicationfee = 0.0;
        var finalTotal = 0;
        if (data) {
            applicationfee = (Number(data) * Number(this.state.applicationfeepercentage)) / 100;
            applicationfee = Number(applicationfee) + Number(this.state.applicationfee);
            finalTotal = Number(data) + Number(applicationfee);
            this.setState({ finalTotal: finalTotal, applicationfeetotal: applicationfee });
        }
    }

    renderDeliveryCharges(currencySymbole) {
        const { taxDetails, deliveryCharge } = this.state;
        let totalDeliveryCharges = deliveryCharge, dliveryCharges = deliveryCharge

        taxDetails.map(e => {
            var obj = e;
            var key = Object.keys(obj);
            var value = Object.values(obj);
            var keyvalue = (dliveryCharges * value) / 100;
            totalDeliveryCharges = totalDeliveryCharges + keyvalue
        })

        return <div id="deliveryCharges" className="row m-0" style={{ display: "none" }}>
            <label style={{ color: Color.BLACK }}>Delivery Charges {/* {deliveryCharge} */}</label>
            <label style={{ marginLeft: "auto", color: Color.BLACK, float: "right", fontWeight: '500' }}>{currencySymbole}{(Math.round(totalDeliveryCharges * 100) / 100).toFixed(2)}</label>
        </div>
    }

    handleModalviewLogin(falg) {
        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById('loginpublicmodel');

        if (backgroung && modalId) {
            backgroung.style.display = falg ? "block" : "none";
            modalId.style.display = falg ? "block" : "none";
        }
    }

    handleModalviewRegister(falg) {
        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById('registerpublicmodel');

        if (backgroung && modalId) {
            backgroung.style.display = falg ? "block" : "none";
            modalId.style.display = falg ? "block" : "none";
        }
    }

    handleValidOTPModalview(falg) {
        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById('validOTPmodel');

        if (backgroung && modalId) {
            backgroung.style.display = falg ? "block" : "none";
            modalId.style.display = falg ? "block" : "none";
        }
    }

    handleValidateOtpChange(event) {
        let otp = this.state.otp;
        let value = event.target.value
        otp["otp"] = value
        this.setState({ otp });
    }

    handleValidationOTPonBlur(e) {
        let fields = this.state.otp;
        let errors = {};
        let formIsValid = true;
        let name = e.currentTarget.name;

        if (name === "otp") {
            if (!fields["otp"]) {
                formIsValid = false;
                errors["otp"] = Messages.v_EnterOTP
            }
            else if (typeof fields["otp"] !== "undefined") {
                if (!validateOtpNo(fields["otp"])) {
                    formIsValid = false;
                    errors["otp"] = Messages.v_EnterValidOTP;
                }
            }
        }

        if (formIsValid) errors[name] = "";

        this.setState({
            otp: fields,
            errors: errors
        });
    }

    validateOTPForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.otp;

        if (!fields["otp"]) {
            formIsValid = false;
            errors["otp"] = Messages.v_EnterOTP
        }
        else if (typeof fields["otp"] !== "undefined") {
            if (!validateOtpNo(fields["otp"])) {
                formIsValid = false;
                errors["otp"] = Messages.v_EnterValidOTP;
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleValidateOtpSubmit(e) {
        e.preventDefault();
        if (this.validateOTPForm()) {
            this.setState({ isActive: true });
            var postData = {};
            postData["Email"] = this.state.guestuserFields.Email;
            postData["PhoneNumber"] = this.state.guestuserFields.PhoneNumber;
            postData["DeviceId"] = "";
            postData["DeviceType"] = "";
            postData["Code"] = this.state.otp.otp;
            console.log('===> otpvaidate', postData)
            this.callValidateOtpAPI(postData);
        }
    }

    callValidateOtpAPI(postData) {
        var URL = VALIDATE_OTP_API;
        PostData(URL, postData)
            .then(result => {
                var responseJson = result;
                console.log('responseJson', responseJson)
                if (responseJson["StatusCode"] === 200) {
                    var authToken = responseJson["Data"].Token;
                    if (authToken) {
                        StoreDataInStorage(Constants.AUTH_TOKEN, authToken);
                        StoreDataInStorage(Constants.USER_SESSION_TYPES, UserSessionTypes.GUEST_CUSTOMER);
                        this.setState({ errors: {}, isActive: false });
                        this.handleValidOTPModalview(false);
                        this.props.history.push({
                            pathname: RouteConstant.OrderWebPayment,
                        });
                    }
                }
                else if (responseJson["StatusCode"] === 400) {
                    let errors = {};
                    errors["otp"] = responseJson.Message;
                    this.setState({ errors: errors, isActive: false });
                }
                else {
                    let errors = {};
                    errors["otp"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors, isActive: false });
                }
            }).catch(error => {
                console.log(error);
                let errors = {};
                errors["otp"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }


    render() {
        const { cartList, countryFees, taxDetails, selectedValue, StoreMainObject } = this.state;

        var currencySymbole = "", countrycode = ""
        var countryFeesLocal = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES)
        if (countryFeesLocal) {
            currencySymbole = (countryFeesLocal[currencyInfo.CountryCode] ?
                "(" + countryFeesLocal[currencyInfo.CountryCode] + ") " : "") + countryFeesLocal.Symbol;
            countrycode = countryFeesLocal.CountryCode
        }


        return (
            <div>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Constants.LoadingMssg}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="">
                    <HeaderOrderWeb setHeaderTitle={Constants.Title_CheckOut} />
                    <section className="section" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                            <div className="reg_form_main">
                                <p className="col-sm-12" for="" style={{ fontWeight: 'bold' }}>
                                    Select pickup or delivery option:
                                </p>
                                <RadioGroup name="options" selectedValue={this.state.selectedValue} onChange={this.handleChangeRadioBtn}>
                                    {this.state.isPickupAvailable && <><Radio style={{ marginTop: '3px', accentColor: Color.THEME_COLOR, height: '15px', width: '15px' }} value="1" /> &nbsp;Curbside Pickup</>} <br />
                                    {this.state.isDeliveryAvailable && <><Radio style={{ marginTop: '3px', accentColor: Color.THEME_COLOR, height: '15px', width: '15px' }} value="2" /> &nbsp;Delivery to your address</>}
                                </RadioGroup>
                            </div>

                            {/* For Orderweb account user change */}
                            {(!this.state.sessionToken || this.state.SessionUserType == UserSessionTypes.GUEST_CUSTOMER) && <section id="addAccountUser" className="reg_form_main" style={{ display: "none" }}>
                                <div className="">
                                    <Collapsible
                                        open={false}
                                        trigger={
                                            <div className="col-sm-12" style={{ marginTop: "10px", backgroundColor: Color.WHITE, paddingBottom: "5px", borderBottom: "1px solid" + Color.GREY }} >
                                                <div style={{ width: "100%" }}>
                                                    <div className="row">
                                                        <img src={accountuser} style={{ height: "30px", width: "30px" }}></img>
                                                        <label style={{ marginLeft: "5px", color: Color.BLACK, fontWeight: 'bold' }}>Your info {"("}
                                                            <span className="" style={{ fontSize: '14px' }}>Do you have an account?
                                                                <a className="atext" href={""} style={{ cursor: 'pointer' }} onClick={() => this.handleModalviewLogin(true)}> {OrderWebCheckoutConst.btn_SIGNIN}</a> And Don't have an account? <a className="atext" href={""} style={{ cursor: 'pointer' }} onClick={() => this.handleModalviewRegister(true)}>{OrderWebCheckoutConst.btn_SIGNUP}</a>{") OR ("}</span>
                                                            <span className="" style={{ fontSize: '14px' }}>Checkout as a <span className='textcolor'>guest</span></span>{")"}</label>
                                                        <i class="fa fa-caret-down" style={{ marginLeft: "auto", fontSize: "30px", color: Color.THEME_COLOR, float: "right", marginRight: "5px" }} aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        triggerWhenOpen={
                                            <div className="col-sm-12" style={{ marginTop: "10px", backgroundColor: Color.WHITE, paddingBottom: "5px", borderBottom: "1px solid" + Color.GREY }} >
                                                <div style={{ width: "100%" }}>
                                                    <div className="row">
                                                        <img src={accountuser} style={{ height: "30px", width: "30px" }}></img>
                                                        <label style={{ marginLeft: "5px", color: Color.BLACK, fontWeight: 'bold' }}>Your info  {"("}
                                                            <span className="" style={{ fontSize: '14px' }}>Do you have an account?
                                                                <a className="atext" href={""} style={{ cursor: 'pointer' }} onClick={() => this.handleModalviewLogin(true)}>{OrderWebCheckoutConst.btn_SIGNIN}</a> And Don't have an account? <a className="atext" href={""} style={{ cursor: 'pointer' }} onClick={() => this.handleModalviewRegister(true)}>{OrderWebCheckoutConst.btn_SIGNUP}</a>{") OR ("}</span>
                                                            <span className="" style={{ fontSize: '14px' }}>Checkout as a <span className='textcolor'>guest</span></span>{")"}
                                                        </label>
                                                        <i class="fa fa-caret-up" style={{ marginLeft: "auto", fontSize: "30px", color: Color.THEME_COLOR, float: "right", marginRight: "5px" }} aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        }>
                                        <div className="" style={{ marginTop: "10px" }}>
                                            <div className="reg_form_main col-sm-12">
                                                <div>

                                                    {/*  <div className="col-sm-12" style={{ paddingBottom: "10px", borderBottom: "1px solid" + Color.GREY }} >
                                                        <span className="">Do you have an account? <label className="textcolor" onClick={() => this.handleModalviewLogin(true)}>{OrderWebCheckoutConst.btn_SIGNIN}</label> And Don't have an account? <label className="textcolor" onClick={() => this.handleModalviewRegister(true)}>{OrderWebCheckoutConst.btn_SIGNUP}</label></span>
                                                    </div> */}
                                                    {/*  <div className="col-sm-12" style={{ paddingBottom: "10px", marginTop: "10px" }}>
                                                        <span className="">Checkout as a <span className='textcolor'>guest</span></span>
                                                    </div> */}

                                                    <div className="reg_form_main col-sm-6 guestfieldsize">
                                                        <p className="col-sm-6 textcolor settextalignpro" for="FirstName" style={{ fontWeight: 'bold' }}>
                                                            {OrderWebCheckoutConst.FirstName}<PutMandatoryfieldSymbol />
                                                        </p>
                                                        <input
                                                            type="text"
                                                            placeholder={OrderWebCheckoutPH.FirstName}
                                                            name="FirstName"
                                                            className="float-none"
                                                            value={this.state.guestuserFields.FirstName}
                                                            onChange={this.handleChangeguest}
                                                            onBlur={this.handleValidationOnBlurGuest} />
                                                        <div className="col-sm-12 errorMsg mb-0 settextalignpro">
                                                            {this.state.errors.FirstName}
                                                        </div>
                                                    </div>

                                                    <div className="reg_form_main col-sm-6 guestfieldsize">
                                                        <p className="col-sm-12 textcolor settextalignpro" for="LastName" style={{ fontWeight: 'bold' }}>
                                                            {OrderWebCheckoutConst.LastName} <PutMandatoryfieldSymbol />
                                                        </p>
                                                        <input
                                                            type="text"
                                                            placeholder={OrderWebCheckoutPH.LastName}
                                                            name="LastName"
                                                            className="float-none"
                                                            value={this.state.guestuserFields.LastName}
                                                            onChange={this.handleChangeguest}
                                                            onBlur={this.handleValidationOnBlurGuest} />
                                                        <div className="col-sm-12 errorMsg mb-0 settextalignpro">
                                                            {this.state.errors.LastName}
                                                        </div>
                                                    </div>

                                                    <div className="reg_form_main col-sm-6 guestfieldsize">
                                                        <p className="col-sm-12 textcolor settextalignpro" style={{ color: Color.THEME_COLOR, fontWeight: 'bold' }} for="Email">
                                                            {OrderWebCheckoutConst.Email} <PutMandatoryfieldSymbol />
                                                        </p>
                                                        <input
                                                            type="email"
                                                            placeholder={OrderWebCheckoutPH.Email}
                                                            name="Email"
                                                            className="float-none"
                                                            value={this.state.guestuserFields.Email}
                                                            onChange={this.handleChangeguest}
                                                            onBlur={this.handleValidationOnBlurGuest} />
                                                        <div className="col-sm-12 errorMsg mb-0 settextalignpro">
                                                            {this.state.errors.Email}
                                                        </div>{" "}
                                                        <div className="col-sm-12 errorMsg mb-0 settextalignpro">
                                                            {this.state.alreadyEmail}
                                                        </div>
                                                    </div>

                                                    <div className="reg_form_main col-sm-6 guestfieldsize">
                                                        <p className="col-sm-12 textcolor settextalignpro" for="PhoneNumber" style={{ fontWeight: 'bold' }}>
                                                            {OrderWebCheckoutConst.screen4Msg1} <PutMandatoryfieldSymbol />
                                                        </p>
                                                        <input
                                                            className="col-sm-12 float-none"
                                                            type="text"
                                                            pattern="[0-9]*"
                                                            placeholder={OrderWebCheckoutPH.PhoneNumber}
                                                            name="PhoneNumber"
                                                            maxLength="12"
                                                            value={this.state.guestuserFields.PhoneNumber}
                                                            onChange={this.handleChangeguest}
                                                            onBlur={this.handleValidationOnBlurGuest} />
                                                        <div className="col-sm-12 errorMsg mb-0 settextalignpro">
                                                            {this.state.errors.PhoneNumber}
                                                        </div>
                                                    </div>

                                                    <div className="reg_form_main col-sm-6 guestfieldsize">
                                                        <p className="col-sm-12 settextalignpro" style={{ color: Color.THEME_COLOR, fontWeight: 'bold' }} for="PostalCode">
                                                            {this.state.selectedValue === "1" ? OrderWebCheckoutConst.TYPE_BILLING_ADDRESS : OrderWebCheckoutConst.TYPE_DELIVERY_ADDRESS} <PutMandatoryfieldSymbol />
                                                        </p>
                                                        <GooglePlacesAutocomplete
                                                            apiKey={GOOGLE_PLACE_KEY}
                                                            selectProps={{
                                                                value: this.state.selectedAddress,
                                                                onChange: (setAddress) => this.handelChangeAddress(setAddress),
                                                                isClearable: true,
                                                                placeholder: this.state.selectedValue === "1" ? OrderWebCheckoutConst.BILLING_ADDRESS : OrderWebCheckoutConst.DELIVERY_ADDRESS,
                                                                styles: colourStyles,
                                                                menuPortalTarget: document.body
                                                            }}
                                                            autocompletionRequest={{
                                                                componentRestrictions: {
                                                                    country: this.state.countryFees.CountryCode ? [this.state.countryFees.CountryCode] : [],
                                                                }
                                                            }} />
                                                        <div className="col-md-12 errorMsg settextalignpro">
                                                            {this.state.errors.Address}
                                                        </div>
                                                    </div>

                                                    <div className="reg_form_main col-sm-6 guestfieldsize">
                                                        <p className="col-sm-12 settextalignpro" style={{ color: Color.THEME_COLOR, fontWeight: 'bold' }} for="PostalCode">
                                                            {OrderWebCheckoutConst.ZIP_PostalCode} <PutMandatoryfieldSymbol />
                                                        </p>
                                                        <input
                                                            type="text"
                                                            onChange={this.handleChangeguest}
                                                            onBlur={this.handleValidationOnBlurGuest}
                                                            className="float-none"
                                                            name="PostalCode"
                                                            value={this.state.guestuserFields.PostalCode}
                                                            placeholder={OrderWebCheckoutConst.ZIP_PostalCode_H} />
                                                        <div className="col-md-12 errorMsg settextalignpro">
                                                            {this.state.errors.PostalCode}
                                                        </div>
                                                    </div>

                                                    <div className="reg_form_main col-sm-12">
                                                        <div >
                                                            <div className="col-sm-12">
                                                                <Checkbox
                                                                    color={Color.THEME_COLOR}
                                                                    size={2}
                                                                    tickSize={2}
                                                                    checked={this.state.chkBoxChecked}
                                                                    delay={0}
                                                                    backAnimationDuration={0}
                                                                    tickAnimationDuration={0}
                                                                    onChange={this.handleCheckBoxChange}></Checkbox>
                                                                &nbsp; &nbsp;<span className="textcolor">{OrderWebCheckoutConst.screen3Msg} <a href={RouteConstant.OrderWebTermsOfService} className="atext" target="_blank">{OrderWebCheckoutConst.screen3Msg1}</a> {OrderWebCheckoutConst.screen3Msg2} <a href={RouteConstant.OrderWebPrivacyPolicy} target="_blank" className="atext">{OrderWebCheckoutConst.screen3Msg3}</a> {OrderWebCheckoutConst.screen3Msg4}</span>
                                                            </div>
                                                            <div className="col-sm-12 errorMsg settextalignpro" style={{ marginTop: '7px' }}>
                                                                {this.state.errors.termspolicy}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="bottam_sing" style={{ borderBottom: "1px solid" + Color.GREY, marginTop: '15px' }}>
                                                        <label
                                                            style={{ marginLeft: "auto", float: "right", borderRadius: "4px", marginBottom: "10px" }}
                                                            onClick={this.handleguestSave}
                                                            className="btn btn-done"
                                                        > Save</label>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </Collapsible>
                                </div>
                            </section>}

                            {selectedValue == '1' && <div class="reg_form_main mb-4 mt-2" style={{ height: '30vh', width: '100%' }}>
                                <div style={{ color: Color.BLACK, marginBottom: '5px' }} ><img src={Storepickuplocation} style={{ height: "30px", width: "30px", marginRight: '5px' }}></img><span style={{ color: Color.THEME_COLOR, fontWeight: 'bold' }}>{(StoreMainObject && StoreMainObject.ApplicationName) ? StoreMainObject.ApplicationName : ""}</span>{(StoreMainObject && StoreMainObject.AppAddress) ? " (" + StoreMainObject.AppAddress + ")" : ""}
                                </div>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: "" }}
                                    center={{
                                        lat: this.state.Latitude,
                                        lng: this.state.Longitude
                                    }}
                                    defaultZoom={15}
                                    options={{ zoomControl: true, mapTypeControl: true }}>
                                    <AnyReactComponent
                                        lat={this.state.Latitude}
                                        lng={this.state.Longitude}
                                        text={<i class="fas fa-map-marker-alt" style={{ fontSize: "20px", color: "red" }}></i>} />
                                </GoogleMapReact>
                            </div>}

                            {(this.state.sessionToken && this.state.userDetails && this.state.userDetails._id && this.state.SessionUserType == UserSessionTypes.PLAZACART_CUSTOMER) && <section id="addAddress" className="reg_form_main" style={{ display: "none" }}>
                                <div className="">
                                    <Collapsible
                                        open={false}
                                        trigger={
                                            <div className="col-sm-12" style={{ marginTop: "10px", backgroundColor: Color.WHITE, paddingBottom: "5px", borderBottom: "1px solid" + Color.GREY }} >
                                                <div style={{ width: "100%" }}>
                                                    <div className="row">
                                                        <img src={marker1} style={{ height: "30px", width: "30px" }}></img>
                                                        <label style={{ marginLeft: "5px", color: Color.BLACK, fontWeight: 'bold' }}>Add Delivery address</label>
                                                        <i class="fa fa-caret-down" style={{ marginLeft: "auto", fontSize: "30px", color: Color.THEME_COLOR, float: "right", marginRight: "5px" }} aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        triggerWhenOpen={
                                            <div className="col-sm-12" style={{ marginTop: "10px", backgroundColor: Color.WHITE, paddingBottom: "5px", borderBottom: "1px solid" + Color.GREY }} >
                                                <div style={{ width: "100%" }}>
                                                    <div className="row">
                                                        <img src={marker1} style={{ height: "30px", width: "30px" }}></img>
                                                        <label style={{ marginLeft: "5px", color: Color.THEME_COLOR, fontWeight: 'bold' }}>Add Delivery address</label>
                                                        <i class="fa fa-caret-up" style={{ marginLeft: "auto", fontSize: "30px", color: Color.THEME_COLOR, float: "right", marginRight: "5px" }} aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        } >
                                        <div className="" style={{ marginTop: "10px" }}>
                                            <AddressListOrderWeb
                                                data={this.state.listAddress}
                                                addressindex={this.state.checkboxId}
                                                updateaddressindex={this.selectUpdateAddressindex}
                                                updateAddressObj={this.selecteUpdateAddress}>
                                            </AddressListOrderWeb>
                                        </div>
                                    </Collapsible>
                                </div>
                            </section>}

                            {(this.state.sessionToken && this.state.userDetails && this.state.userDetails._id && this.state.SessionUserType == UserSessionTypes.PLAZACART_CUSTOMER) && <div className="errorMsg" style={{ color: "#cc0000" }}>
                                {this.state.errors.deliveryAddress}
                            </div>}

                            <div className="reg_form_main">
                                {this.state.isStoretimerender &&
                                    <PlazaStoreOrderWeb
                                        updateCartObj={this.updateCartObj}
                                        selectedRdoBtn={this.state.selectedValue}
                                        timeDayHeader={this.state.timeDayHeader}
                                        data={this.state.cartList}>
                                    </PlazaStoreOrderWeb>
                                }
                                <div className="errorMsg" style={{ color: "#cc0000" }}>
                                    {this.state.errors.dateandtimes}
                                </div>
                            </div>

                            {(this.state.sessionToken && this.state.userDetails && this.state.userDetails._id && this.state.SessionUserType == UserSessionTypes.PLAZACART_CUSTOMER) && <div className="reg_form_main">
                                <Collapsible
                                    open={false}
                                    trigger={
                                        <div className="col-sm-12" style={{ marginTop: "10px", backgroundColor: Color.WHITE, paddingBottom: "5px", borderBottom: "1px solid" + Color.GREY }} >
                                            <div style={{ width: "100%" }}>
                                                <div className="row">
                                                    <img src={people1} style={{ height: "30px", width: "30px" }}></img>
                                                    <label style={{ marginLeft: "5px", color: Color.BLACK, fontWeight: 'bold' }}>Mobile Number</label>
                                                    <i class="fa fa-caret-down" style={{ marginLeft: "auto", fontSize: "30px", color: Color.THEME_COLOR, float: "right", marginRight: "5px" }} aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    triggerWhenOpen={
                                        <div className="col-sm-12" style={{ marginTop: "10px", backgroundColor: Color.WHITE, paddingBottom: "5px", borderBottom: "1px solid" + Color.GREY }} >
                                            <div style={{ width: "100%" }}>
                                                <div className="row">
                                                    <img src={people1} style={{ height: "30px", width: "30px" }}></img>
                                                    <label style={{ marginLeft: "5px", color: Color.THEME_COLOR, fontWeight: 'bold' }}>Mobile Number</label>
                                                    <i class="fa fa-caret-up" style={{ marginLeft: "auto", fontSize: "30px", color: Color.THEME_COLOR, float: "right", marginRight: "5px" }} aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </div>
                                    } >

                                    <div className="col-sm-12 p-0" >
                                        <label className="row m-0" style={{ color: Color.BLACK, fontSize: "13px" }} >
                                            We use your Mobile Number to text or call you about your order.
                                        </label>
                                        <label className="row m-0 textcolor">
                                            Mobile Number (10 Digit) <PutMandatoryfieldSymbol />
                                        </label>
                                        <input
                                            className="row m-0"
                                            type="text"
                                            placeholder="Mobile Number"
                                            maxLength="12"
                                            name="MobileNumber"
                                            value={this.state.userFields.MobileNumber}
                                            onChange={this.handleChange}
                                        />
                                        <div className="errorMsg" style={{ color: "#cc0000" }}>
                                            {this.state.errors.MobileNumber}
                                        </div>
                                        <div className="bottam_sing" style={{ borderBottom: "1px solid" + Color.GREY, marginTop: '15px' }}>
                                            <label
                                                style={{ marginLeft: "auto", float: "right", borderRadius: "4px", marginBottom: "10px" }}
                                                for="step5"
                                                id="continue-step5"
                                                onClick={this.handlePhoneSave}
                                                className="btn btn-done"
                                            > Save</label>
                                        </div>
                                    </div>
                                </Collapsible>
                            </div>}

                            <div className="reg_form_main">
                                <div className="col-sm-12" style={{ marginTop: "10px", backgroundColor: Color.WHITE, paddingBottom: "5px", borderBottom: "1px solid" + Color.GREY }} >
                                    <div style={{ width: "100%" }}>
                                        <div className="row">
                                            <img src={creditcard1} style={{ height: "30px", width: "30px" }}></img>
                                            {/* <i class="fa fa-credit-card" style={{ fontSize: "30px", color: Color.BLACK }}></i> */}
                                            <label style={{ marginLeft: "5px", color: Color.BLACK, fontWeight: 'bold' }}>Total Payment</label>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-12 p-0" style={{ marginTop: "10px" }}>
                                    <div className="row m-0">
                                        <label style={{ color: Color.BLACK }}>Item(s) Subtotal</label>
                                        <label style={{ marginLeft: "auto", color: Color.BLACK, fontWeight: '500' }}>{currencySymbole}{(Math.round(this.state.subtotal * 100) / 100).toFixed(2)}</label>
                                    </div>
                                    {this.state.totalItemTax != 0 && <div className="row m-0">
                                        <label style={{ color: Color.BLACK }}>Total Product Tax</label>
                                        <label style={{ marginLeft: "auto", color: Color.BLACK, fontWeight: '500' }}>{currencySymbole}{(Math.round(this.state.totalItemTax * 100) / 100).toFixed(2)}</label>
                                    </div>}
                                    {this.renderDeliveryCharges(currencySymbole)}

                                    <div id="specialdeliveryCharges" className="row m-0" style={{ display: "none" }}>
                                        <label style={{ color: Color.BLACK }}>Special Delivery Charges</label>
                                        <label style={{ marginLeft: "auto", color: Color.BLACK, float: "right", fontWeight: '500' }}>{currencySymbole}{(Math.round(this.state.specialdeliverycharges * 100) / 100).toFixed(2)}</label>
                                    </div>

                                    {/* Changes are made based on the call discussion. Date 10 Aug 2022 */}
                                    {/*  <div className="row m-0">
                                    <label style={{ color: Color.BLACK }}>Service Fee</label>
                                    <label style={{ marginLeft: "auto", color: Color.BLACK, fontWeight: '500' }}>{currencySymbole}{(Math.round(this.state.serviceFee * 100) / 100).toFixed(2)}</label>
                                </div> */}

                                    {this.renderTaxDetailsUSA(taxDetails, countryFees, currencySymbole)}

                                    {/* <div className="row">
                                    <label style={{ marginLeft: "10px", color: Color.BLACK }}>HST (13%)</label>
                                    <label style={{ marginLeft: "auto", color: Color.BLACK, marginRight: "10px" }}>{currencySymbole}</label>
                                  
                                    <TaxDetails
                                         setTaxDetails={this.setTaxDetails}
                                         subtotal={this.state.subtotal}
                                         deliveryCharge={this.state.deliveryCharge}
                                         radioBtnValue={this.state.selectedValue}
                                         taxList={taxDetails}>                                            
                                    </TaxDetails>
                                </div> */}

                                    <div id="deliveryTip" style={{ display: "none" }}>
                                        <div className="row m-0" style={{ borderBottom: "1px solid" + Color.GREY }}>
                                            <label style={{ color: Color.BLACK }}>Delivery Tip</label>
                                            <label style={{ marginLeft: "auto", color: Color.BLACK, fontWeight: '500' }}>{currencySymbole}{(Math.round(this.state.tipAmount * 100) / 100).toFixed(2)}</label>
                                        </div>

                                        <div className="col-sm-12" style={{ marginTop: "10px" }}>
                                            <div className="row">
                                                <label style={{ color: Color.BLACK, fontWeight: "bold" }}>Delivery Tip</label>
                                                <span style={{ marginLeft: "auto" }}>
                                                    <label style={{ color: Color.BLACK, marginRight: "10px", fontWeight: '500' }}>{currencySymbole}{(Math.round(this.state.tipAmount * 100) / 100).toFixed(2)}</label>
                                                    <img src={pencil} style={{ height: "20px", width: "20px", float: "right", marginRight: "5px" }} onClick={() => this.openDeliveryTipModal()}></img>
                                                </span>
                                            </div>
                                            <label className="row" style={{ color: Color.THEME_COLOR, fontSize: "12px", fontWeight: 'bold', fontFamily: 'sans-serif' }}>Want to recognize your delivery person's efforts? Consider a larger tip as a thank you- 100% of the tip goes to them.</label>
                                        </div>
                                    </div>

                                    {/* Subtotal */}
                                    <div className="row m-0" style={{ paddingTop: '5px', borderTop: "1px solid" + Color.GREY }}>
                                        <label style={{ color: Color.BLACK }}>Subtotal </label>
                                        <label style={{ marginLeft: "auto", color: Color.BLACK, fontWeight: '500' }}>{currencySymbole}{(Math.round(this.state.totalCAD * 100) / 100).toFixed(2)}</label>
                                    </div>

                                    {/* Render Application Fee */}
                                    <div className="row m-0">
                                        <label style={{ color: Color.BLACK }}> Service charge </label>
                                        <label style={{ marginLeft: "auto", color: Color.BLACK, fontWeight: '500' }}>{currencySymbole}  {Number(this.state.applicationfeetotal).toFixed(2)}</label>
                                    </div>

                                </div>

                                <div className="col-sm-12 p-0" style={{ marginTop: "10px", borderBottom: "1px solid" + Color.GREY }}>
                                    <div className="row m-0">
                                        <label style={{ color: Color.BLACK, fontWeight: 'bold' }}>Total </label>
                                        <label style={{ marginLeft: "auto", color: Color.BLACK, fontWeight: 'bold' }}>{currencySymbole}{(Math.round(this.state.finalTotal * 100) / 100).toFixed(2)}</label>
                                    </div>
                                </div>

                                {this.state.selectedCoupon && <div className="col-sm-12 p-0" style={{ marginTop: "10px", borderBottom: "1px solid" + Color.GREY }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <label style={{ marginLeft: "0px", color: Color.BLACK, fontWeight: '500' }}>Payable Amount  {currencySymbole} {Number(this.state.selectedCoupon.DicountPrice).toFixed(2) + " OFF "}
                                        </label>
                                        <label style={{ marginLeft: "auto", color: Color.BLACK, marginRight: "0px", fontWeight: 'bold' }}>{currencySymbole} {Number(this.state.finalTotal - this.state.selectedCoupon.DicountPrice).toFixed(2)}</label>
                                    </div>
                                </div>}

                                {/*  Coupon render /////////////////////////////////// */}
                                {/*  Coupon render /////////////////////////////////// */}
                                {/*  Coupon render /////////////////////////////////// */}
                                {/*  Coupon render /////////////////////////////////// */}

                                {this.state.couponList.length > 0 && <div className="reg_form_main">
                                    <Collapsible
                                        open={false}
                                        trigger={
                                            <div className="col-sm-12" style={{ marginTop: "10px", backgroundColor: Color.WHITE, paddingBottom: "5px", borderBottom: "1px solid" + Color.GREY }} >
                                                <div style={{ width: "100%" }}>
                                                    <div className="row">
                                                        <img src={discountcupon} style={{ height: "30px", width: "30px" }}></img>
                                                        {/* <i class="fa fa-user" style={{ fontSize: "30px", color: Color.BLACK }}></i> */}
                                                        <label style={{ marginLeft: "5px", color: Color.BLACK, fontWeight: 'bold' }}>Discount Coupon</label>
                                                        <i class="fa fa-caret-down" style={{ marginLeft: "auto", fontSize: "30px", color: Color.THEME_COLOR, float: "right", marginRight: "5px" }} aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        triggerWhenOpen={
                                            <div className="col-sm-12" style={{ marginTop: "10px", backgroundColor: Color.WHITE, paddingBottom: "5px", borderBottom: "1px solid" + Color.GREY }} >
                                                <div style={{ width: "100%" }}>
                                                    <div className="row">
                                                        <img src={discountcupon} style={{ height: "30px", width: "30px" }}></img>
                                                        {/* <i class="fa fa-user" style={{ fontSize: "30px", color: Color.BLACK }}></i> */}
                                                        <label style={{ marginLeft: "5px", color: Color.THEME_COLOR, fontWeight: 'bold' }}>Discount Coupon</label>
                                                        <i class="fa fa-caret-up" style={{ marginLeft: "auto", fontSize: "30px", color: Color.THEME_COLOR, float: "right", marginRight: "5px" }} aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        } >
                                        <div className="reg_form_main">
                                            <p className="col-sm-12" for="" style={{ color: Color.BLACK, fontWeight: '600', paddingLeft: '0px' }}>
                                                Select Discount Coupon option:
                                            </p>
                                            {this.state.couponList.map(e =>
                                                <div
                                                    className={'row-over row setaTag ' + (Number(this.state.subtotal) >= Number(e.MinimumrequiredAmount) ? "" : "row-over-disable")}
                                                    style={{ borderRadius: '4px' }}
                                                    onClick={(Number(this.state.subtotal) >= Number(e.MinimumrequiredAmount)) ? () => this.handelCouponRadio(e) : () => { }}>
                                                    <div className="setaTag">
                                                        <input type={"radio"}
                                                            style={{ accentColor: Color.THEME_COLOR, height: '15px', width: '15px' }}
                                                            checked={
                                                                (Boolean(this.state.tempSelectedCoupon
                                                                    && (this.state.tempSelectedCoupon._id == e._id)))} />
                                                        <img src={e.imageurl} style={{ width: "70px", marginLeft: "10px" }}></img>
                                                    </div>

                                                    <div className='coupondesc couponblock'>
                                                        <label className="remove-mg" style={{ marginLeft: "10px", color: Color.BLACK, fontWeight: 'bold', }}>{e.Title}
                                                            <span className="coupondescription"> Minimum Required Amount {currencySymbole + " " + Number(e.MinimumrequiredAmount).toFixed(2)}</span></label>
                                                        <label className="remove-mg coupondescription" style={{ marginLeft: "10px", color: Color.BLACK }}> {e.Description} </label>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div className="col-sm-12 p-0">
                                            <div className="bottam_sing" style={{ borderBottom: "1px solid" + Color.GREY, marginTop: '10px' }}>
                                                <label
                                                    style={{ marginLeft: "auto", float: "right", borderRadius: "4px", marginBottom: "15px", marginRight: '0px' }}
                                                    for="step5"
                                                    id="continue-step5"
                                                    onClick={this.handelCoupon}
                                                    className="btn btn-done"
                                                >&nbsp;&nbsp;Apply&nbsp;&nbsp;</label>
                                            </div>
                                        </div>
                                    </Collapsible>
                                </div>}

                                {/*  End Coupon render ////////////////////////////////// */}
                                {/*  End Coupon render ////////////////////////////////// */}
                                {/*  End Coupon render ////////////////////////////////// */}
                                {/*  End Coupon render ////////////////////////////////// */}

                                {(!this.state.isDeliveryCostres && this.state.deliveryCostErrMsg) && <div className="errorMsg" style={{ color: "#cc0000" }}>
                                    {this.state.deliveryCostErrMsg}
                                </div>}

                                {!this.state.deliveryCostErrMsg && <div className="errorMsg" style={{ color: "#cc0000" }}>
                                    {this.state.errors.ErrorC}
                                </div>}

                                <div class="modal modalcustom" id="myModal">
                                    <div class="modal-dialog dTip-modal-dialog">
                                        <div class="modal-content">
                                            <div className="col-sm-12" style={{ marginTop: "10px" }}> Tip your delivery person  </div>
                                            <ul>
                                                <li style={{ color: Color.THEME_COLOR }}>100% of tips go to your delivery person</li>
                                                <li style={{ color: Color.THEME_COLOR }}>Tips are always optional</li>
                                            </ul>

                                            <div class="modal-body">
                                                <RadioGroup
                                                    className="col-sm-12"
                                                    name="tipOptions"
                                                    selectedValue={this.state.selectedTipValueTemp}
                                                    onChange={this.handleChangeTipRadioBtn} >
                                                    <Radio value="1" />&nbsp;None <br />
                                                    <Radio value="2" />&nbsp;{currencySymbole}5.00<br />
                                                    <Radio value="3" />&nbsp;Other Amount <br />
                                                </RadioGroup>
                                                <input
                                                    type="text"
                                                    disabled={this.state.disableDeliveryTip}
                                                    placeholder="Delivery Tip (Optional)"
                                                    name="tip"
                                                    maxLength={3}
                                                    value={this.state.userFields.tip}
                                                    onChange={this.handleChange} />
                                                <div className="errorMsg row ml-0 mr-0 col-12">
                                                    {this.state.errors.tip}
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <label
                                                    class="m-btn"
                                                    /* style={{ color: Color.THEME_COLOR }} */
                                                    for="step5"
                                                    onClick={() => this.closeDeliveryTipModal()}

                                                > Cancel</label>

                                                <label
                                                    class="m-btn"
                                                    /* style={{ float: "center", borderRadius: "4px", marginBottom: "10px" }} */
                                                    for="step5"
                                                    id="continue-step5"
                                                    onClick={this.handleDeliveryTip}
                                                /* className="btn btn-done" */
                                                > Save</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Common For Login and Guest Flow Error */}
                                <div className="errorMsg" style={{ color: "#cc0000" }}>
                                    {this.state.errors.CommonError}
                                </div>

                            </div>
                        </div>

                        {(this.state.sessionToken && this.state.userDetails && this.state.userDetails._id && this.state.SessionUserType == UserSessionTypes.PLAZACART_CUSTOMER) ? ((this.state.selectedValue != "2" || this.state.isDeliveryCostres) && <div className="reg_form_main">
                            <div className="bottam_sing">
                                <label
                                    style={{ backgroundColor: Color.THEME_COLOR, float: "center", borderRadius: "4px" }}
                                    onClick={this.handlePaymentForLoginUser}
                                    className="btn btn-done"
                                > Complete Payment</label>
                            </div>
                        </div>) :
                            ((!this.state.sessionToken || this.state.SessionUserType == UserSessionTypes.GUEST_CUSTOMER) && <div className="reg_form_main">
                                <div className="bottam_sing">
                                    <label
                                        style={{ backgroundColor: Color.THEME_COLOR, float: "center", borderRadius: "4px" }}
                                        onClick={this.handlePaymentForGuestUser}
                                        className="btn btn-done"
                                    > Complete Payment</label>
                                </div>
                            </div>)}

                        <div className="reg_form_main" style={{ marginTop: "0px" }}>
                            <div className="bottam_sing mt-3">
                                <label
                                    style={{ float: "center", borderRadius: "4px", marginBottom: "15px" }}
                                    onClick={this.handleContinueShopping}
                                    className="btn btn-done">Continue Shopping</label>
                            </div>
                        </div>

                        <div class="modal modalcustom" id="validOTPmodel" style={{ marginBottom: "10px", zIndex: 399 }} centered>
                            <div class="modal-dialog" style={{ margin: "auto", top: "25%" }}>
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <label></label>
                                        <button type="button" class="close" onClick={() => this.handleValidOTPModalview(false)}>&times;</button>
                                    </div>
                                    <div class="modal-body" style={{ padding: '5px' }}>
                                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }} >
                                            <div>
                                                <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }} >
                                                    <div className="reg_form_main col-sm-12">
                                                        <p className="col-sm-12 textcolor" for="otp" style={{ fontWeight: 'bold', marginTop: '20px' }}>
                                                            {OrderWebCheckoutConst.screen5Msg} <PutMandatoryfieldSymbol />
                                                        </p>
                                                        <input
                                                            type="text"
                                                            pattern="[0-9]*"
                                                            inputmode="numeric"
                                                            placeholder={OrderWebCheckoutPH.Enterotpnumber}
                                                            className="float-none"
                                                            name="otp"
                                                            maxLength="6"
                                                            value={this.state.otp.otp}
                                                            onChange={this.handleValidateOtpChange}
                                                            onBlur={this.handleValidationOTPonBlur} />
                                                        <div className="errorMsg mb-0 settextalignpro">
                                                            {this.state.errors.otp}
                                                        </div>
                                                    </div>
                                                    <div className="reg_form_main">
                                                        <div className="bottam_sing mt-3">
                                                            <label
                                                                style={{ borderRadius: "4px", backgroundColor: Color.THEME_COLOR, margin: '0px' }}
                                                                onClick={this.handleValidateOtpSubmit}
                                                                className="btn btn-done"
                                                            > {OrderWebCheckoutConst.btn_Submit}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                </LoadingOverlay >
            </div>
        );
    }
}

export default CheckoutOrderWebSingleStore;