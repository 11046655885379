import React, { Component } from 'react';
import Color from '../../Config/Color';
import Messages from "../../Config/Messages";
import HeaderOrderWeb from '../Header/HeaderOrderWeb';
import './SideMenuOrderWeb.css';

import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import LoadingOverlay from 'react-loading-overlay';
import { withRouter } from 'react-router-dom';
import { GetData } from "../../../services/GetData";
import { PUTData } from "../../../services/PUTData";
import { PostData } from "../../../services/PostData";
import { GET_USERPROFILE_API, GOOGLE_PLACE_KEY, USER_ADDRESS, ORDER_WEB_STORE_ADD_ADDRESS_API, STOREID } from '../../Config/Config.json';
import Constants from '../../Config/Constants';
import { RetriveDataFromStorage, RetriveObjectFromStorage, StoreObjectInStorage, removeAllSpace } from '../../Config/Utils';
import { GOOGLEPLACEAPIMAP } from '../../Config/constvariable';
import { PutMandatoryfieldSymbol } from '../../UtillCustomComponent/UtillCustomComponent';

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#f0f0f0', border: "none", borderRadius: "50px", height: "50px", marginBottom: "15px" }),
    placeholder: styles => ({ ...styles, fontSize: "16px", fontWeight: "450 !important", marginLeft: "8px" }),
    option: styles => ({ ...styles, color: '#333' }),
    menuPortal: styles => ({ ...styles, zIndex: "99" })
};

class AddAddressOrderWeb extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userFields: {},
            errors: {},
            addressId: "",
            isActive: false,
            pagetitle: "",
            StoreMainObject: {},
            countryInfo: null,
            selectedAddress: null
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        var countryFees = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES)
        if (countryFees) {
            this.setState({ countryInfo: countryFees });
            console.log('Country Object CAll', JSON.stringify(countryFees));
        }

        var objAddress = RetriveObjectFromStorage(Constants.ADDRESS_OBJ);
        console.log("objAddress ", objAddress)
        if (objAddress) {

            this.setState({ addressId: objAddress._id })

            var userFields = this.state.userFields;
            userFields["AptUnit"] = objAddress.ApartmentNumber
            userFields['BusinessName'] = objAddress.BussinesName
            userFields['InstructionDelivery'] = objAddress.DeliveryInstructions
            userFields["PostalCode"] = objAddress.PostalCode
            var selectedAddress = {
                label: objAddress.StreetAddress,
                value: {
                    description: objAddress.StreetAddress
                }
            }
            this.setState({ selectedAddress: selectedAddress })
            this.setState({ userFields })
            this.setState({ pagetitle: Constants.Title_UpdateAddress })
        }
        else {
            var userFields = this.state.userFields;
            userFields["AptUnit"] = "";
            userFields['BusinessName'] = "";
            userFields['InstructionDelivery'] = "";
            userFields["PostalCode"] = "";
            this.setState({ pagetitle: Constants.Title_AddAdress, userFields: userFields, errors: {}, addressId: "", isActive: false, selectedAddress: null });
            if (countryFees) {
                this.setState({ countryInfo: countryFees });
            }
        }
    }

    // ////////////////////////////// detail api ////////////////////////////////

    handleRefresh() {
        this.componentDidMount()
    }

    handleChange(event) {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        if (key == "PostalCode") {
            userFields[key] = value
            userFields["AptUnit"] = ""
            this.setState({ userFields, selectedAddress: null })
        }
        else {
            userFields[key] = value
            this.setState({ userFields })
        }
    }

    handelAddress(addressObj) {
        this.setState({ selectedAddress: addressObj })
        console.log(addressObj)
        let userFields = this.state.userFields;
        if (addressObj && addressObj.value && addressObj.value.place_id) {
            this.getaddressGooglePlace("", addressObj.value.place_id, userFields)
        }
        userFields["PostalCode"] = "";
        userFields["AptUnit"] = ""
        this.setState({ userFields, selectedAddressDetail: null })
    }

    getaddressGooglePlace(authToken, value, refObj) {
        this.setState({ isActive: true })
        var placeId = value
        console.log(placeId)
        geocodeByPlaceId(placeId)
            .then(result => {
                var responseJson = result ? result[0] : null;
                console.log("====", result)
                if (responseJson) {
                    var responseData = responseJson;
                    this.setState({ selectedAddressDetail: responseData ? responseData : null })
                    if (responseData && responseData.address_components) {
                        var remaingAddress = ""
                        for (var obj of responseData.address_components) {
                            const componentType = obj.types[0];
                            switch (componentType) {
                                case GOOGLEPLACEAPIMAP.STREET_NUMBER: {
                                    refObj['AptUnit'] = obj.long_name;
                                    break;
                                }
                                case GOOGLEPLACEAPIMAP.ROUTE: {
                                    refObj['AptUnit'] = refObj['AptUnit'] + ", " + obj.short_name;
                                    break;
                                }
                                case GOOGLEPLACEAPIMAP.POSTAL_CODE: {
                                    refObj['PostalCode'] = removeAllSpace(obj.long_name);
                                    break;
                                }
                                case GOOGLEPLACEAPIMAP.POSTAL_CODE_SUFFIX: {
                                    refObj['PostalCode'] = removeAllSpace(refObj['PostalCode'] + "-" + obj.long_name);
                                    break;
                                }
                                case GOOGLEPLACEAPIMAP.LOCALITY: {
                                    remaingAddress = obj.long_name;
                                    break;
                                }
                                case GOOGLEPLACEAPIMAP.ADMINISTRATIVE_AREA_LEVEL_1: {
                                    remaingAddress = remaingAddress + ", " + obj.short_name;
                                    break;
                                }
                                case GOOGLEPLACEAPIMAP.COUNTRY:
                                    remaingAddress = remaingAddress + ", " + obj.long_name;
                                    break;
                            }
                        }
                        var selectedAddress = {
                            label: remaingAddress,
                            value: {
                                description: remaingAddress
                            }
                        }
                        this.setState({ selectedAddress: selectedAddress })
                    }
                    else {
                        // refObj["PostalCode"] = ""
                    }
                }
                else {
                    // refObj["PostalCode"] = ""

                }
                this.setState({ refObj, isActive: false })
            }).catch(err => {
                // refObj["PostalCode"] = ""
                this.setState({ refObj, isActive: false })
            });
    }

    validateForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;

        if (!this.state.selectedAddress) {
            formIsValid = false;
            errors["StreetAddress"] = Messages.v_EnterStreetAddress;
        }

        if (!fields["AptUnit"]) {
            formIsValid = false;
            errors["AptUnit"] = Messages.v_EnterAptUnit;
        }

        if (!fields["PostalCode"]) {
            formIsValid = false;
            errors["PostalCode"] = Messages.v_EnterPostalCode;
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSubmit(e) {
        var postData = {};
        e.preventDefault();
        if (this.validateForm()) {
            this.setState({ isActive: true });
            postData['StreetAddress'] = this.state.selectedAddress ? this.state.selectedAddress.label : "";
            postData['ApartmentNumber'] = this.state.userFields.AptUnit;
            postData['BussinesName'] = this.state.userFields.BusinessName;
            postData['PostalCode'] = this.state.userFields.PostalCode;
            postData['DeliveryInstructions'] = this.state.userFields.InstructionDelivery

            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            console.log("==> postdata==", postData)

            if (this.state.addressId != "" && this.state.addressId) {
                postData['Addressid'] = this.state.addressId;
                this.updateAddress(postData, authToken)
            }
            else {
                var objStore = RetriveObjectFromStorage(Constants.Obj_STORE, '');
                if (objStore && objStore.ObjectId) {
                    this.addAddress(postData, authToken, objStore.ObjectId);
                    this.setState({ StoreMainObject: objStore });
                }
            }
        }
    }

    addAddress(postData, authToken, storeid) {
        this.setState({ isActive: true });
        var URL = ORDER_WEB_STORE_ADD_ADDRESS_API + "?" + STOREID + storeid;
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.getUserDetail(authToken);
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = responseJson["Message"];
                    this.setState({ errors: errors, isActive: false });
                }
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    updateAddress(postData, authToken) {
        this.setState({ isActive: true });
        var URL = USER_ADDRESS;
        PUTData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.getUserDetail(authToken);
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = responseJson["Message"];
                    this.setState({ errors: errors, isActive: false });
                }
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }


    getUserDetail(authToken) {
        var API = GET_USERPROFILE_API;
        GetData(API, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    StoreObjectInStorage(Constants.Obj_USER, responseData)
                    this.setState({ isActive: false });
                    this.props.history.goBack();
                }
                else {
                    let errors = {};
                    errors["ErrorC"] = responseJson["Message"];
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false });
            }).catch(error => {
                let errors = {};
                errors["ErrorC"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    render() {

        const countryCode = this.state.countryInfo ? this.state.countryInfo.CountryCode : "";
        console.log('===========================', countryCode);

        return (
            <div>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Constants.LoadingMssg}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="" >
                    <HeaderOrderWeb setHeaderTitle={this.state.pagetitle} onrefresh={() => this.handleRefresh()} />
                    <section className="section" style={{ margin: "20px 0px 0px 0px" }}>
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                            <div className="reg_form_main offset-lg-3 col-lg-6">
                                {/* col-sm-12 */}
                                <div className="" style={{ backgroundColor: Color.WHITE }}>
                                    <p className="textcolor" for="StreetAddress">
                                        Street Address <PutMandatoryfieldSymbol />
                                    </p>
                                    <GooglePlacesAutocomplete
                                        apiKey={GOOGLE_PLACE_KEY}
                                        selectProps={{
                                            value: this.state.selectedAddress,
                                            onChange: (setAddress) => this.handelAddress(setAddress),
                                            isClearable: true,
                                            placeholder: "Street Address",
                                            styles: colourStyles
                                        }}
                                        autocompletionRequest={{
                                            componentRestrictions: {
                                                country: countryCode ? [countryCode] : []
                                            }
                                        }}
                                    />
                                    <div className="errorMsg" style={{ color: "#cc0000" }}>
                                        {this.state.errors.StreetAddress}
                                    </div>
                                </div>
                                {/* col-sm-12 */}
                                <div className="">
                                    <p className="textcolor" for="AptUnit">
                                        Apt/Unit # <PutMandatoryfieldSymbol />
                                    </p>
                                    <input
                                        type="text"
                                        placeholder="Apt/Unit #"
                                        name="AptUnit"
                                        value={this.state.userFields.AptUnit}
                                        onChange={this.handleChange}
                                    // onBlur={this.handleValidationOnBlur}
                                    />
                                    <div className="errorMsg" style={{ color: "#cc0000" }}>
                                        {this.state.errors.AptUnit}
                                    </div>
                                </div>
                                {/* col-sm-12 */}
                                <div className="">
                                    <input
                                        type="text"
                                        placeholder="Business Name (Optional)"
                                        name="BusinessName"
                                        value={this.state.userFields.BusinessName}
                                        onChange={this.handleChange}
                                    />

                                    <input
                                        type="text"
                                        placeholder="Instructions for delivery (Optional)"
                                        name="InstructionDelivery"
                                        value={this.state.userFields.InstructionDelivery}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                {/* col-sm-12 */}
                                <div className="">
                                    <p className="textcolor" for="PostalCode">
                                        Postal Code <PutMandatoryfieldSymbol />
                                    </p>
                                    <input
                                        type="text"
                                        placeholder="Postal Code"
                                        name="PostalCode"
                                        value={this.state.userFields.PostalCode}
                                        onChange={this.handleChange}
                                    // onBlur={this.handleValidationOnBlur}
                                    />
                                    <div className="errorMsg" style={{ color: "#cc0000" }}>
                                        {this.state.errors.PostalCode}
                                    </div>
                                </div>

                                <div className="">
                                    <div className="errorMsg" style={{ color: "#cc0000" }}>
                                        {this.state.errors.ErrorC}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="bottam_sing">
                            <label
                                style={{ backgroundColor: Color.THEME_COLOR, borderRadius: "4px", marginBottom: '10px' }}
                                onClick={this.handleSubmit}
                                className="btn btn-done"
                            > {this.state.pagetitle}</label>
                        </div>

                    </section>
                </LoadingOverlay>
            </div>
        );
    }
}

export default withRouter(AddAddressOrderWeb);