
const PlazaStoreForm = {
    "PickupServiceAvailable": "Pick up Service Available",
    "DeliveryServicesAvailable": "Delivery Services Available",
    "PickupServiceNotAvailable": "Pick up Service Not Available",
    "DeliveryServicesNotAvailable": "Delivery Services Not Available",
    "bookappointment": "Book An Appointment",
    "AddToFavorites": "Add To Favorites",
    "RemoveFromFavorites": "Remove From Favorites",
    "CartServicesAvailable": "Available for Cart"
}

export default PlazaStoreForm;

export const PlazaListHover = {
    "Favorite": "Favorite Brewery",
    "AddToFavorites": "Add To Favorites",
    "RemoveFromFavorites": "Remove From Favorites"
}