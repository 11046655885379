import React, { Component } from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { GetData } from "../../../services/GetData";
import Color from '../../Config/Color';
import { GET_STORECATEGORYITEM_API } from '../../Config/Config.json';
import Constants, { DEFAULTID, currencyInfo } from '../../Config/Constants';
import RouteConstant from '../../Config/RouteConstant';
import { MainContext, RetriveDataFromStorage, RetriveObjectFromStorage, StoreObjectInStorage } from '../../Config/Utils';
import Header from '../../Header/Header';
import LoadingOverlay from 'react-loading-overlay';
import NoPriview from '../../../../src/images/nopreviewp.png';
import AddToppingsSingleStore from './AddToppingsSingleStore';
import { getProductCountInCart, getItemTaxes } from '../../Config/utilityFunction';
import MasterContextCreater from '../../MasterContextProvider/MasterContextCreater'
import { Toast } from '../../Toast/Toast'
import Messages from '../../Config/Messages'

var currencySymbole = "";
var countryFees = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES);
if (countryFees) {
    currencySymbole = (countryFees[currencyInfo.CountryCode] ? "(" + countryFees[currencyInfo.CountryCode] + ") " : "") + countryFees.Symbol;
}

class AddCartSingleStore extends Component {
    static contextType = MasterContextCreater;
    constructor(props) {
        super(props);
        this.state = {
            itemId: "",
            itemPrice: "",
            labelItemPrice: "",
            totalPrice: "",
            itemImage: "",
            itemCount: 1,
            IsTryItEnable: false,
            title: "",
            selected: '',
            storeData: {},
            // plazaData: {},
            storeItemList: [], // array to display bottom item list
            countCartItem: 0,
            cartItems: [],
            cartStore: [], // use to make final cart list
            itemsInCart: [], // use to make final cart list
            finalCart: [],
            toppingItems: [],
            showToppingBtn: "hidden",
            selectedItemObj: {},
            selectedTopping: {},
            selectedToppingList: [],
            specialdeliverycharges: "0.0",
            showAddToppingView: false,
            isActive: false,
            toppingCharge: 0.0,
            AvailableQuantity: 0,
            ContinueSelling: false,
            TrackQuantity: false,
            specialDelivery: false,
            IsDeliveryEnableStore: false,
            selectedItemSize: "",
            selectedItemColor: "",
            AvailableQuantityForCart: 0,

            /* For User Event Logic */
            TempselectedItemObj: {},
            TempselectedToppingList: [],
            TemptoppingCharge: 0.0,
            TemptotalPrice: "",
            TempitemCount: 1,

            readmore: false,

            allItemTaxes: [],
            countryCode: ""
        }

        this.handleAddToCart = this.handleAddToCart.bind(this);
        this.handleContinueShopping = this.handleContinueShopping.bind(this);
        this.handleMinus = this.handleMinus.bind(this);
        this.handlePlus = this.handlePlus.bind(this);
        this.countTotal = this.countTotal.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.addSelectedToppings = this.addSelectedToppings.bind(this);
        this.handleAddToppings = this.handleAddToppings.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        // var objPlaza = RetriveObjectFromStorage(Constants.Obj_PLAZA, '');
        var objStore = RetriveObjectFromStorage(Constants.Obj_STORE, '');
        var objStoreItems = RetriveObjectFromStorage(Constants.Obj_STORE_ITEMS, '');
        console.log("get ")
        if (objStoreItems) {
            this.setState({ title: objStoreItems.ItemName });
            this.setState({ itemId: objStoreItems.ItemID });
            this.setState({ itemPrice: objStoreItems.ItemPrice, selected: objStoreItems.ItemID });
            this.setState({ labelItemPrice: objStoreItems.ItemPrice });
            this.setState({ totalPrice: objStoreItems.ItemPrice });
            this.setState({ itemImage: objStoreItems.ItemLogo });
            this.setState({ IsTryItEnable: objStoreItems.IsTryItEnable });
            this.setState({ ContinueSelling: objStoreItems.ContinueSelling });
            this.setState({ TrackQuantity: objStoreItems.TrackQuantity });

            if (objStoreItems.ToppingCategory && objStoreItems.ToppingCategory.length > 0) {
                this.setState({ toppingItems: objStoreItems.ToppingCategory });
                this.setState({ showToppingBtn: "visible" });
            }

            if (objStoreItems.ItemSizeColor && objStoreItems.ItemSizeColor.length > 0) {
                var selectedItemSize = objStoreItems.ItemSizeColor[0];
                if (selectedItemSize) {
                    this.setState({ selectedItemSize: selectedItemSize });

                    if (selectedItemSize.colors && selectedItemSize.colors.length > 0) {
                        var selectedItemColor = selectedItemSize.colors[0];
                        if (selectedItemColor) {


                            // for quntity 22-may-2023
                            objStoreItems.AvailableQuantityForCart = selectedItemColor.quantity
                            objStoreItems.ItemSizeColor = objStoreItems.ItemSizeColor.map((sizeObj => {
                                let colors = sizeObj.colors?.map(colorObj => {
                                    // if (sizeObj.size == selectedItemSize.size && colorObj.color == selectedItemColor.color) {
                                    let inCart = getProductCountInCart(objStoreItems.ItemID, sizeObj.size, colorObj.color)
                                    return {
                                        ...colorObj,
                                        "quantity": colorObj.quantity - inCart
                                    }
                                    // }
                                    // return colorObj
                                })

                                return {
                                    ...sizeObj,
                                    "colors": colors
                                }
                            }))
                            //

                            // new add 19-may-2023
                            let inCart = getProductCountInCart(objStoreItems.ItemID, selectedItemSize.size, selectedItemColor.color)
                            objStoreItems.AvailableQuantity = selectedItemColor.quantity - (Number(inCart) ? Number(inCart) : 0);
                            this.setState({ selectedItemColor: selectedItemColor });
                            //

                        }
                    }
                    objStoreItems.ItemDescription = selectedItemSize.description;


                }
            } else {
                // new add 19-may-2023
                objStoreItems.AvailableQuantityForCart = objStoreItems.AvailableQuantity
                let inCart = getProductCountInCart(objStoreItems.ItemID)
                objStoreItems.AvailableQuantity = objStoreItems.AvailableQuantity - (Number(inCart) ? Number(inCart) : 0);
                //

                objStoreItems.ItemDescription = objStoreItems.ItemDescription;
                this.setState({ selectedItemSize: "" });
                this.setState({ selectedItemColor: "" });
            }

            this.setState({ selectedItemObj: objStoreItems });
            this.setState({ AvailableQuantity: objStoreItems.AvailableQuantity });

            if (objStoreItems.IsSpecialDeliveryItem) {
                this.setState({ specialDelivery: objStoreItems.IsSpecialDeliveryItem });
                this.setState({ specialdeliverycharges: objStoreItems.specialdeliverycharges });
            }
        }

        if (objStore) {
            // this.setState({ plazaData: objPlaza });
            this.setState({ storeData: objStore, IsDeliveryEnableStore: objStore.IsDeliveryEnable });
            if (objStoreItems && objStoreItems.CategoryID) {
                // 62d7b5a28c4367320403cf65
                this.getStoreItemsAPI(authToken, DEFAULTID, objStore.ObjectId, objStoreItems.CategoryID);
            }
        }

        var globalFinalCart = RetriveObjectFromStorage(Constants.Obj_CART, '');
        if (globalFinalCart) {
            this.setState({ finalCart: globalFinalCart }, function () {
                this.setHeaderItemCount(globalFinalCart);
            });
        }

        var objCountryFees = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES);
        if (objCountryFees) {
            this.setState({
                allItemTaxes: objCountryFees.itemLevelTax ? objCountryFees.itemLevelTax : [],
                countryCode: objCountryFees.CountryCode
            })
        }
    }

    setHeaderItemCount(globalFinalCart) {
        var countItems = 0;
        if (globalFinalCart.length > 0) {
            globalFinalCart.map(itemPlaza => {
                countItems = countItems + itemPlaza.Items.length;
            });
            this.setState({ countCartItem: countItems });
        }
    }

    getStoreItemsAPI(authToken, plazaID, storeID, CategoryID) {
        this.setState({ isActive: true });
        var API = GET_STORECATEGORYITEM_API.replace("#STOREID#", storeID);
        var URL = API.replace("#TENANTID#", plazaID);
        var URL1 = URL.replace('#CATEGORYID#', CategoryID);

        GetData(URL1, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData && responseData.Items) {
                        var list = [];
                        responseData.Items.map(function (item1, index1) {
                            item1.CategoryID = responseData.CategoryId;
                            list.push(item1);
                        })
                        this.setState({ storeItemList: list }, function () {

                        });
                    }
                }
                else {
                    this.setState({ hideEmptyPlazaView: false });
                }
                this.setState({ isActive: false });
            }).catch(err => {
                console.log(err);
                this.setState({ isActive: false })
            });
    }

    setPlazaStore(list) {
        this.setState({ cartStore: list });
        if (objStoreItems.IsSpecialDeliveryItem) {
            this.setState({ specialdeliverycharges: objStoreItems.specialdeliverycharges });
        }
    }

    handleMinus() {
        var count = this.state.itemCount;
        var price = this.state.totalPrice / count;
        if (count !== 1) {
            count = count - 1;
            this.setState({
                itemCount: count
            }, () => {
                this.countTotal(this.state.itemCount, price);
            });
        }
    }

    handlePlus() {
        var count = this.state.itemCount
        var price = this.state.totalPrice / count
        count = count + 1;
        if (this.state.TrackQuantity) {
            if (this.state.AvailableQuantity >= count) {
                this.setState({
                    itemCount: count
                }, () => {
                    this.countTotal(this.state.itemCount, price);
                });
            }
        } else {
            this.setState({
                itemCount: count
            }, () => {
                this.countTotal(this.state.itemCount, price);
            });
        }
    }

    countTotal(count, price) {
        price = price * count;
        price = (Math.round(price * 100) / 100).toFixed(2);
        this.setState({ totalPrice: price })
    }


    handleAddToCart(e) {
        e.preventDefault();
        var globalFinalCart = RetriveObjectFromStorage(Constants.Obj_CART, '');

        if (this.state.TrackQuantity) {
            if (this.state.AvailableQuantity >= this.state.itemCount) {
                if (globalFinalCart && globalFinalCart.Items) {
                    var count = globalFinalCart.Items.length;
                }
                else {
                    var count = this.state.countCartItem;
                }
                count = count + 1
                this.setState({
                    countCartItem: count,
                });
            }
        }
        else {
            if (globalFinalCart && globalFinalCart.Items) {
                var count = globalFinalCart.Items.length;
            }
            else {
                var count = this.state.countCartItem;
            }
            count = count + 1
            this.setState({
                countCartItem: count,
            });
        }


        /* Add Cart Logic Change */
        this.setState({ finalCart: globalFinalCart ? globalFinalCart : [] }, function () {
            var newItem = {}
            newItem = this.state.selectedItemObj;
            newItem["noOfItems"] = this.state.itemCount;
            newItem["ToppingItems"] = this.state.selectedToppingList;
            newItem["totalPrice"] = this.state.totalPrice;

            // quntity changes 22-may-2023
            if (this.state.TrackQuantity) {
                if (this.state.AvailableQuantity >= this.state.itemCount) {
                    this.createCartItemsObject(/*this.state.plazaData,*/ this.state.storeData, newItem);
                }
            } else {
                this.createCartItemsObject(/*this.state.plazaData,*/ this.state.storeData, newItem);
            }
            //

        });
    }

    handleContinueShopping() {
        this.props.history.push({
            pathname: RouteConstant.PlazaStoreItems,
        });
    }

    createCartItemsObject(storeData, itemData) {
        var finalCart = this.state.finalCart;
        const { allItemTaxes, countryCode } = this.state;
        if (finalCart && finalCart.length > 0) {
            const plazaExist = finalCart.some(el => (el.ObjectId == storeData.ObjectId));
            if (plazaExist) {
                finalCart = finalCart.map(cartObj => {
                    let itemList = cartObj.Items;
                    const that = this;
                    var itemIndex = -1;
                    if (itemIndex >= 0) {
                        let item = itemList[itemIndex];
                        item.ItemQuantity = itemData.noOfItems;
                        //item.TotalItemPrice = (itemData.noOfItems * itemData.ItemPrice);
                        item.ItemSize = this.state.selectedItemSize ? this.state.selectedItemSize.size : "";
                        item.size = this.state.selectedItemSize ? this.state.selectedItemSize.size : "";
                        item.SelectItemSize = this.state.selectedItemSize;
                        item.itemColor = this.state.selectedItemColor ? this.state.selectedItemColor.color : "";
                        item.color = this.state.selectedItemColor ? this.state.selectedItemColor.color : "";
                        item.SelectitemColor = this.state.selectedItemColor;
                        item.Instruction = `${that.state.selectedItemSize ? "Size:" + that.state.selectedItemSize.size : ""}${that.state.selectedItemColor ? ",Color:" + that.state.selectedItemColor.color : ""}`;
                        item.TotalItemPrice = itemData.totalPrice;
                        item.ToppingItems = itemData.ToppingItems;
                        item.IsProductTax = itemData.IsProductTax;

                        /* Edit Flow Changes */
                        item.ItemSizeColor = itemData.ItemSizeColor;
                        item.ToppingCategory = itemData.ToppingCategory;
                        item.toppingmaximumquantity = itemData.toppingmaximumquantity;
                        item.toppingminimumquantity = itemData.toppingminimumquantity;

                        itemList[itemIndex] = item;
                    }
                    else {
                        let objItem = {};
                        objItem["CategoryID"] = itemData.CategoryID;
                        objItem["Instruction"] = `${that.state.selectedItemSize ? "Size:" + that.state.selectedItemSize.size : ""}${that.state.selectedItemColor ? ",Color:" + that.state.selectedItemColor.color : ""}`;
                        objItem["ItemDescription"] = itemData.ItemDescription;
                        objItem["ItemId"] = itemData.ItemID;
                        objItem["ItemName"] = itemData.ItemName;
                        objItem["ItemLogo"] = itemData.ItemLogo;
                        objItem["ItemPrice"] = itemData.ItemPrice;
                        objItem["ItemQuantity"] = itemData.noOfItems;
                        objItem["ItemTax"] = itemData.tax;
                        objItem["ItemType"] = itemData.ProductType;
                        objItem["ItemWeightType"] = itemData.WeightType;
                        objItem["ItemWeight"] = itemData.Weight;
                        //objItem["TotalItemPrice"] = (itemData.noOfItems * itemData.ItemPrice);
                        objItem["TotalItemPrice"] = itemData.totalPrice;
                        objItem["IsSpecialDeliveryItem"] = itemData.IsSpecialDeliveryItem;
                        objItem["specialdeliverycharges"] = itemData.specialdeliverycharges * itemData.noOfItems;
                        //objItem["ToppingItems"] = itemData.ToppingCategory;
                        objItem["ToppingItems"] = itemData.ToppingItems;
                        objItem["AvailableQuantity"] = itemData.AvailableQuantity;
                        objItem["AvailableQuantityForCart"] = itemData.AvailableQuantityForCart
                        objItem["ContinueSelling"] = itemData.ContinueSelling;
                        objItem["TrackQuantity"] = itemData.TrackQuantity;
                        objItem["variant_id"] = "";
                        objItem["ItemSize"] = this.state.selectedItemSize ? this.state.selectedItemSize.size : "";
                        objItem["size"] = this.state.selectedItemSize ? this.state.selectedItemSize.size : "";
                        objItem["SelectItemSize"] = this.state.selectedItemSize;
                        objItem["itemColor"] = this.state.selectedItemColor ? this.state.selectedItemColor.color : "";
                        objItem["color"] = this.state.selectedItemColor ? this.state.selectedItemColor.color : "";
                        objItem["SelectitemColor"] = this.state.selectedItemColor;
                        objItem["IsProductTax"] = itemData.IsProductTax;

                        /* Edit Flow Changes */
                        objItem["ItemSizeColor"] = itemData.ItemSizeColor;
                        objItem["ToppingCategory"] = itemData.ToppingCategory;
                        objItem["toppingmaximumquantity"] = itemData.toppingmaximumquantity;
                        objItem["toppingminimumquantity"] = itemData.toppingminimumquantity;
                        objItem["itemLevelTax"] = getItemTaxes(itemData.taxes ? itemData.taxes : [], allItemTaxes)  // getItemTaxes(taxList, allItemTaxes)

                        itemList.push(objItem);
                    }
                    return { ...cartObj, "Items": itemList }
                });
            }
            this.setState({ finalCart: finalCart });
            StoreObjectInStorage(Constants.Obj_CART, finalCart);
        }
        else {

            var cartFinelArray = [], objCart = {};
            var objPlaza = {};

            objPlaza["PlazaId"] = DEFAULTID;
            objPlaza["CompanyName"] = "";
            objPlaza["Imageurl"] = "";
            objPlaza["PlazaSubTotal"] = "";
            objPlaza["PlazaTipAmount"] = 0;
            objPlaza["PlazaTotal"] = "";
            objPlaza["ServiceFeeAmount"] = 0;

            var objStore = {};
            objStore["ObjectId"] = storeData.ObjectId;
            objStore["ApplicationId"] = storeData.ApplicationId;
            objStore["ApplicationName"] = storeData.ApplicationName;
            objStore["Deliverytimes"] = storeData.Deliverytimes ? storeData.Deliverytimes : [];
            objStore["Pickuptimes"] = storeData.Pickuptimes ? storeData.Pickuptimes : [];
            objStore["Taxes"] = storeData.Taxes;
            objStore["DeliveryDate"] = "";
            objStore["DeliveryTime"] = "";
            objStore["isshopifyintegration"] = storeData.isshopifyintegration; // new key
            objStore["iswixintegration"] = storeData.iswixintegration; // new key
            objStore["IsPickupEnable"] = storeData.IsPickupEnable; // new key
            objStore["IsDeliveryEnable"] = storeData.IsDeliveryEnable; // new key
            objStore["AppBannerImage"] = storeData.AppBannerImage; // new key

            var objItem = {};
            objItem["CategoryID"] = itemData.CategoryID;
            objItem["Instruction"] = `${this.state.selectedItemSize ? "Size:" + this.state.selectedItemSize.size : ""}${this.state.selectedItemColor ? ",Color:" + this.state.selectedItemColor.color : ""}`;
            objItem["ItemDescription"] = itemData.ItemDescription;
            objItem["ItemId"] = itemData.ItemID;
            objItem["ItemName"] = itemData.ItemName;
            objItem["ItemLogo"] = itemData.ItemLogo;
            objItem["ItemPrice"] = itemData.ItemPrice;
            objItem["ItemQuantity"] = itemData.noOfItems;
            objItem["ItemTax"] = itemData.tax;
            objItem["ItemType"] = itemData.ProductType;
            objItem["ItemWeightType"] = itemData.WeightType;
            objItem["ItemWeight"] = itemData.Weight;
            //objItem["TotalItemPrice"] = (itemData.noOfItems * itemData.ItemPrice);
            objItem["IsSpecialDeliveryItem"] = itemData.IsSpecialDeliveryItem;
            objItem["specialdeliverycharges"] = itemData.specialdeliverycharges * itemData.noOfItems;

            objItem["TotalItemPrice"] = itemData.totalPrice;
            objItem["ToppingItems"] = itemData.ToppingItems;
            objItem["AvailableQuantity"] = itemData.AvailableQuantity;
            objItem["AvailableQuantityForCart"] = itemData.AvailableQuantityForCart
            objItem["ContinueSelling"] = itemData.ContinueSelling;
            objItem["TrackQuantity"] = itemData.TrackQuantity;
            objItem["variant_id"] = "";
            objItem["ItemSize"] = this.state.selectedItemSize ? this.state.selectedItemSize.size : "";
            objItem["size"] = this.state.selectedItemSize ? this.state.selectedItemSize.size : "";
            objItem["SelectItemSize"] = this.state.selectedItemSize;
            objItem["itemColor"] = this.state.selectedItemColor ? this.state.selectedItemColor.color : "";
            objItem["color"] = this.state.selectedItemColor ? this.state.selectedItemColor.color : "";
            objItem["SelectitemColor"] = this.state.selectedItemColor;
            objItem["IsProductTax"] = itemData.IsProductTax;

            /* Edit Flow Changes */
            objItem["ItemSizeColor"] = itemData.ItemSizeColor;
            objItem["ToppingCategory"] = itemData.ToppingCategory;
            objItem["toppingmaximumquantity"] = itemData.toppingmaximumquantity;
            objItem["toppingminimumquantity"] = itemData.toppingminimumquantity;
            objItem["itemLevelTax"] = getItemTaxes(itemData.taxes ? itemData.taxes : [], allItemTaxes)  // getItemTaxes(taxList, allItemTaxes)

            objCart = { ...objPlaza, ...objStore };
            objCart["Items"] = [objItem];

            cartFinelArray.push(objCart);
            this.setState({ finalCart: cartFinelArray });
            StoreObjectInStorage(Constants.Obj_CART, cartFinelArray);
        }

        const { setStateByChild } = this.context
        if (typeof setStateByChild == "function") {
            setStateByChild({ isnavalert: true })
        }
        Toast({ msg: Messages.AddtoCart })

        // new add 19-may-2023
        let { selectedItemSize, selectedItemColor, selectedItemObj, itemCount } = this.state;
        //  let inCart = getProductCountInCart(itemData.ItemID,selectedItemSize,selectedItemColor)

        if (selectedItemSize && selectedItemColor && selectedItemObj.ItemSizeColor) {
            selectedItemObj.ItemSizeColor = selectedItemObj.ItemSizeColor.map((sizeObj => {
                let colors = sizeObj.colors?.map(colorObj => {
                    if (sizeObj.size == selectedItemSize.size && colorObj.color == selectedItemColor.color) {
                        selectedItemObj.AvailableQuantity = colorObj.quantity - itemCount;
                        return {
                            ...colorObj,
                            "quantity": colorObj.quantity - itemCount
                        }
                    }
                    return colorObj
                })

                return {
                    ...sizeObj,
                    "colors": colors
                }
            }))
        }
        else {
            selectedItemObj.AvailableQuantity = selectedItemObj.AvailableQuantity - itemCount;
        }
        var avQunt = selectedItemObj.AvailableQuantity
        this.setState({
            selectedItemObj: selectedItemObj,
            AvailableQuantity: avQunt
        })
        //

        this.sDeliveryNo()
    }

    //////////////////////////////////////////////////// understanding ///////////////////////////////
    onSelect = key => {
        var obj1 = this.state.storeItemList.find((o) => o.ItemID === key);
        var obj = JSON.parse(JSON.stringify(obj1));
        StoreObjectInStorage(Constants.Obj_STORE_ITEMS, obj);

        // console.log("Store item add to cart on selection", JSON.stringify(obj));

        this.setState({ selected: obj.ItemID })
        this.setState({ itemId: obj.ItemID })
        // this.setState({ itemPrice: obj.ItemPrice })
        this.setState({ itemImage: obj.ItemLogo })
        this.setState({ title: obj.ItemName })
        // this.setState({ totalPrice: obj.ItemPrice })
        this.setState({ itemCount: 1 })
        this.setState({ labelItemPrice: obj.ItemPrice })
        this.setState({ IsTryItEnable: obj.IsTryItEnable })
        this.setState({ ContinueSelling: obj.ContinueSelling })
        this.setState({ TrackQuantity: obj.TrackQuantity })

        this.setState({ selectedToppingList: [] })
        this.setState({ toppingCharge: 0.0 })
        this.setState({ specialdeliverycharges: obj.specialdeliverycharges });

        if (obj.ItemSizeColor && obj.ItemSizeColor.length > 0) {
            var selectedItemSize = obj.ItemSizeColor[0];
            if (selectedItemSize) {
                this.setState({ selectedItemSize: selectedItemSize });
                if (selectedItemSize.colors && selectedItemSize.colors.length > 0) {
                    var selectedItemColor = selectedItemSize.colors[0];
                    if (selectedItemColor) {

                        // for quntity 22-may-2023
                        obj.AvailableQuantityForCart = selectedItemColor.quantity
                        obj.ItemSizeColor = obj.ItemSizeColor.map((sizeObj => {
                            let colors = sizeObj.colors?.map(colorObj => {
                                // if (sizeObj.size == selectedItemSize.size && colorObj.color == selectedItemColor.color) {
                                let inCart = getProductCountInCart(obj.ItemID, sizeObj.size, colorObj.color)
                                return {
                                    ...colorObj,
                                    "quantity": colorObj.quantity - inCart
                                }
                                // }
                                // return colorObj
                            })

                            return {
                                ...sizeObj,
                                "colors": colors
                            }
                        }))
                        //

                        // for quntity 22-may-2023
                        let inCart = getProductCountInCart(obj.ItemID, selectedItemSize.size, selectedItemColor.color)
                        obj.AvailableQuantity = selectedItemColor.quantity - (Number(inCart) ? Number(inCart) : 0);
                        //

                        this.setState({ selectedItemColor: selectedItemColor });
                    }
                }

                obj.ItemDescription = selectedItemSize.description;
            }
        } else {
            // quntity for 22-may-2023
            obj.AvailableQuantityForCart = obj.AvailableQuantity
            let inCart = getProductCountInCart(obj.ItemID)
            obj.AvailableQuantity = obj.AvailableQuantity - (Number(inCart) ? Number(inCart) : 0);
            //F
            obj.ItemDescription = obj.ItemDescription;
            this.setState({ selectedItemSize: '' });
            this.setState({ selectedItemColor: '' });
        }

        this.setState({ AvailableQuantity: obj.AvailableQuantity });

        var objStoreItem = StoreObjectInStorage(Constants.Obj_STORE_ITEMS, obj);

        // var objPlaza = RetriveObjectFromStorage(Constants.Obj_PLAZA, '');
        var objStore = RetriveObjectFromStorage(Constants.Obj_STORE, '');

        var globalFinalCart = RetriveObjectFromStorage(Constants.Obj_CART, '');

        var list = obj.ToppingCategory;
        var selectedItemObj = obj;
        var itemPrice = obj.ItemPrice;
        var totalPrice = obj.ItemPrice;
        var toppingCharge = 0;

        this.setState({ itemPrice: itemPrice, toppingCharge: toppingCharge });
        this.setState({ totalPrice: totalPrice });

        if (list.length > 0) {
            this.setState({ showToppingBtn: "visible" });
        } else {
            this.setState({ showToppingBtn: "hidden" });
        }
        this.setState({ selectedItemObj: selectedItemObj, readmore: false });
    }

    handleAddToppings(e) {
        e.preventDefault();
        $('#toppingModal').modal('show');
        this.setState({ showAddToppingView: true });

        /* User Event Logic  */
        var selectedItemObj = this.state.selectedItemObj;
        if (selectedItemObj) {
            var selectedToppingList = this.state.selectedToppingList;
            if (selectedToppingList && selectedToppingList.length == 0) {
                if (selectedItemObj.ToppingCategory) {
                    selectedItemObj.ToppingCategory = selectedItemObj.ToppingCategory.map(e1 => {
                        var ToppingItems = e1.ToppingItems.map(e => {
                            return { ...e, "checked": false }
                        });
                        return { ...e1, "ToppingItems": ToppingItems }
                    });
                }
            }
            this.setState({
                TempselectedItemObj: JSON.parse(JSON.stringify(selectedItemObj)),
                TempselectedToppingList: JSON.parse(JSON.stringify(selectedToppingList)),
                TemptoppingCharge: this.state.toppingCharge,
                TemptotalPrice: this.state.totalPrice,
                TempitemCount: this.state.itemCount
            });
        }
    }

    ////////////// new modal method 19-may-2023
    handleModal(falg, id) {
        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById(id);

        if (backgroung && modalId) {
            backgroung.style.display = falg ? "block" : "none";
            modalId.style.display = falg ? "block" : "none";
        }
    }

    sDeliveryNo() {
        this.handleModal(false, 'myModal')
    }

    openSDeliveryModal() {
        this.handleModal(true, 'myModal')
    }
    //

    addSelectedToppings(selected) {
        if (selected.SelectedToppingItems.length > 0) {/* 3 */
            var price = this.state.labelItemPrice;
            var toppingpricer = 0.0;

            for (var withtopinngs of selected.SelectedToppingItems/* .slice(3, selected.SelectedToppingItems.length) */) {
                toppingpricer = toppingpricer + withtopinngs.Price;
            }
            this.setState({ itemPrice: price + toppingpricer });
        }
        else {
            var price = this.state.labelItemPrice;
            this.setState({ itemPrice: price });
        }

        this.setState({ selectedToppingList: selected.SelectedToppingItems });
        this.setState({ totalPrice: selected.totalPrice });
        this.setState({ itemCount: selected.noOfItems });

        /* Add new Changes */
        this.setState({
            selectedItemObj: this.state.TempselectedItemObj,
            selectedToppingList: this.state.TempselectedToppingList,
            toppingCharge: this.state.TemptoppingCharge,
            totalPrice: this.state.TemptotalPrice,
            itemCount: this.state.TempitemCount
        });
    }


    handleTryIt = () => {
        this.props.history.push({
            pathname: RouteConstant.TryIt,
        });
    }

    setItemObj = itemObj => {
        /* For User Event Logic */
        this.setState({ TempselectedItemObj: JSON.parse(JSON.stringify(itemObj)) });

        /* this.setState({ selectedItemObj: itemObj }); */
    }

    readmoreFun() {
        this.setState({ readmore: !this.state.readmore })
    }

    setSelectedToppings = selectedToppings => {
        /* For User Event Logic */
        this.setState({ TempselectedToppingList: selectedToppings });

        /* this.setState({ selectedToppingList: selectedToppings }); */
    }

    setToppingPrice = toppingPrice => {
        /* For User Event Logic */
        this.setState({ TemptoppingCharge: toppingPrice });

        /* this.setState({ toppingCharge: toppingPrice }); */
    }

    setTotalPrice = totalPrice => {
        /* For User Event Logic */
        this.setState({ TemptotalPrice: totalPrice });

        /* this.setState({ totalPrice: totalPrice }); */
    }

    setItemCount = itemCount => {
        /* For User Event Logic */
        this.setState({ TempitemCount: itemCount });

        /* this.setState({ itemCount: itemCount }); */
    }

    handelSize(size) {
        this.setState({ selectedItemSize: size });
        var selectedItemObj = this.state.selectedItemObj;
        if (selectedItemObj && size) {
            selectedItemObj.ItemDescription = size.description;
            if (size && size.colors && size.colors.length > 0) {
                var selectedItemColor = size.colors[0];
                if (selectedItemColor) {
                    selectedItemObj.AvailableQuantity = selectedItemColor.quantity;
                    selectedItemObj.AvailableQuantityForCart = selectedItemColor.quantity;
                    if (selectedItemObj && selectedItemObj.TrackQuantity) {
                        this.countTotal(1, selectedItemObj.ItemPrice);
                        this.setState({
                            selectedItemObj: selectedItemObj, selectedItemColor: selectedItemColor,
                            AvailableQuantity: selectedItemObj.AvailableQuantity, itemCount: 1,
                            readmore: false
                        });
                    } else {
                        this.countTotal(this.state.itemCount, selectedItemObj.ItemPrice);
                        this.setState({
                            selectedItemObj: selectedItemObj, selectedItemColor: selectedItemColor,
                            AvailableQuantity: selectedItemObj.AvailableQuantity, itemCount: this.state.itemCount,
                            readmore: false
                        });
                    }
                }
            }
        }
    }

    handelColor(color) {
        this.setState({ selectedItemColor: color })
        var selectedItemObj = this.state.selectedItemObj;
        if (selectedItemObj && color) {
            selectedItemObj.AvailableQuantity = color.quantity;
            selectedItemObj.AvailableQuantityForCart = color.quantity;
            if (selectedItemObj && selectedItemObj.TrackQuantity) {
                this.countTotal(1, selectedItemObj.ItemPrice);
                this.setState({
                    selectedItemObj: selectedItemObj, AvailableQuantity: selectedItemObj.AvailableQuantity,
                    itemCount: 1
                });
            } else {
                this.countTotal(this.state.itemCount, selectedItemObj.ItemPrice);
                this.setState({
                    selectedItemObj: selectedItemObj, AvailableQuantity: selectedItemObj.AvailableQuantity,
                    itemCount: this.state.itemCount
                });
            }
        }
    }

    handleMouseMove(e) {
        const { left, top, width, height } = e.target.getBoundingClientRect();

        let original = document.querySelector('#img-1'),
            magnified = document.querySelector('#img-2'),
            style = magnified.style,
            x = e.pageX - left,
            y = e.pageY - top,
            imgWidth = original.offsetWidth,
            imgHeight = original.offsetHeight,
            xperc = ((x / imgWidth) * 100),
            yperc = ((y / imgHeight) * 100);

        if (x > (.01 * imgWidth)) {
            xperc += (.15 * xperc);
        };

        if (y >= (.01 * imgHeight)) {
            yperc += (.15 * yperc);
        };

        style.backgroundPositionX = (xperc - 9) + '%';
        style.backgroundPositionY = (yperc - 9) + '%';

        document.getElementById("img-2").style.backgroundSize = `${width * 2}px ${height * 2}px`;

        style.left = (x - 100) + 'px';
        style.top = (y - 100) + 'px';
    }

    render() {
        const { storeItemList, selected, countCartItem, showToppingBtn, selectedItemObj, storeData } = this.state;

        const Arrow = ({ text, className }) => {
            return <div className={className}>{text}</div>;
        };

        const ArrowLeft = Arrow({ text: "<", className: "arrow-prev" });
        const ArrowRight = Arrow({ text: ">", className: "arrow-next" });

        const MenuItem = ({ text, s }) => {
            return (text.ItemID != selected) && <div className={`menu-item mr-1 pr-2 pl-2 ${s ? 'active' : ''}`} style={{ padding: "10px 5px", borderRadius: '10px' }}>
                <div class='cal' style={{ width: "unset", height: "150px", marginBottom: "5px" }}>
                    <div class="text-center">
                        <img src={text.ItemLogo ? text.ItemLogo : NoPriview} onError={(e) => (e.target.onerror = null, e.target.src = NoPriview)} style={{ width: "80px", height: "100px" }}></img>
                    </div>
                    <div>
                        <label className="textcolor" style={{ textOverflow: "ellipsis", overflow: "hidden", width: "100px", color: 'black' }}>{text.ItemName}</label>
                    </div>
                    <div>
                        <label className="textcolor">{currencySymbole} {Number(text.ItemPrice).toFixed(2)}</label>
                    </div>
                </div>
            </div>;
        };

        const Menu = (list, selected) =>
            list.map(el => {
                const { ItemID } = el;
                return <MenuItem text={el} key={ItemID} selected={selected} />;
            });

        this.menuItems = Menu(storeItemList, selected);
        const menu = this.menuItems;

        var specialDelivery = false
        var storeItemObj = RetriveObjectFromStorage(Constants.Obj_STORE_ITEMS);
        if (storeItemObj) {
            if (storeItemObj.IsSpecialDeliveryItem && this.state.IsDeliveryEnableStore) {
                specialDelivery = true
            }
        }

        var currencySymbole = ""
        var countryFees = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES)
        if (countryFees) {
            currencySymbole = (countryFees[currencyInfo.CountryCode] ?
                "(" + countryFees[currencyInfo.CountryCode] + ") " : "") + countryFees.Symbol
        }

        return (
            <div>
                <div className="modal-background" id="modalbackgroundId"> </div>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Constants.LoadingMssg}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="">
                    <Header countItemCart={countCartItem} setHeaderTitle={this.state.title} />
                    <section className="section">
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>

                            <div className="reg_form_main col-sm-6">
                                <div className='row m-0'>
                                    <div className="col-sm-3 pl-sm-0 p-0 priviewimagemain">
                                        <img className='priviewimage' src={this.state.itemImage ? this.state.itemImage : NoPriview} onError={(e) => (e.target.onerror = null, e.target.src = NoPriview)} loading='lazy' style={{ borderRadius: '10px', border: '1px solid', borderColor: Color.THEME_COLOR, marginBottom: '10px' }}></img>
                                    </div>
                                    <div className="col-sm-9 text-center pl-sm-2 p-0">
                                        <div style={{ position: "relative", width: "inherit" }}>
                                            <div id="img-zoomer-box" onMouseMove={this.handleMouseMove}>
                                                <img src={this.state.itemImage ? this.state.itemImage : NoPriview} id="img-1" alt="Zoom Image on Mouseover" onError={(e) => (e.target.onerror = null, e.target.src = NoPriview)} loading='lazy' />
                                                <div id="img-2" style={{ background: `url(${this.state.itemImage ? this.state.itemImage : NoPriview}) no-repeat #FFF` }}></div>
                                            </div>
                                            {/* <img src={this.state.itemImage ? this.state.itemImage : NoPriview} onError={(e) => (e.target.onerror = null, e.target.src = NoPriview)} loading='lazy' style={{ width: "inherit", zIndex: "-1", borderRadius: '10px' }}></img> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="reg_form_main col-sm-6">
                                <div className='mb-2'>
                                    <span className="" style={{ color: Color.BLACK, fontWeight: 'bold' }}>{selectedItemObj.ItemName} </span>
                                </div>
                                <div className='mb-2'>
                                    <label className="textcolor" style={{ fontWeight: 'bold' }} >{currencySymbole} {Number(this.state.labelItemPrice).toFixed(2)}</label>
                                    &nbsp; &nbsp;
                                    {selectedItemObj.TrackQuantity && ((selectedItemObj.AvailableQuantity > 0) ? <label className="errorMsg">Only {selectedItemObj.AvailableQuantity} left in stock</label> : <label className="errorMsg">Sold out</label>)}
                                </div>

                                <div className=''>
                                    <label class="mr-2" style={{ fontSize: "25px" }} onClick={this.handleMinus}> - </label>
                                    <label style={{ fontSize: "25px" }}> {this.state.itemCount} </label>
                                    <label class="ml-2 mr-4" style={{ fontSize: "25px" }} onClick={this.handlePlus}> + </label>
                                    <label className="textcolor">{currencySymbole} {Number(this.state.totalPrice).toFixed(2)}</label>
                                </div>

                                {selectedItemObj && selectedItemObj.ItemSizeColor && selectedItemObj.ItemSizeColor.length > 0 &&
                                    <div className="mainattBox mb-2">
                                        <span className="textcolor setline"> {this.state.optionObj1?.name}Sizes :- </span>
                                        <div>
                                            {selectedItemObj.ItemSizeColor.map(value =>
                                            (
                                                <div key={value} className={this.state.selectedItemSize.size == value.size ? 'sizeBox clicksizeBox' : 'sizeBox'}
                                                    onClick={(e) => this.handelSize(value)}>
                                                    <label className="sizeboxitem">{value.size}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>}

                                {selectedItemObj && selectedItemObj.ItemSizeColor && selectedItemObj.ItemSizeColor.length > 0 &&
                                    <div className="mainattBox mb-2">
                                        <span className="textcolor setline"> {this.state.optionObj1?.name}Colors :- </span>
                                        <div>
                                            {selectedItemObj.ItemSizeColor.map(value => {
                                                if ((value.size == this.state.selectedItemSize.size) && value.colors && value.colors.length > 0) {
                                                    return value.colors.map(colorobj => {
                                                        return <div key={colorobj} className={this.state.selectedItemColor.color == colorobj.color ? 'sizeBox clicksizeBox' : 'sizeBox'}
                                                            onClick={(e) => this.handelColor(colorobj)}>
                                                            <label className="sizeboxitem">{colorobj.color}</label>
                                                        </div>
                                                    })
                                                }
                                                return "";
                                            })}
                                        </div>
                                    </div>}

                                {(selectedItemObj.ProductType && selectedItemObj.ProductType === "W" && selectedItemObj.Weight && selectedItemObj.Weight != 0) && <div className='mt-3'>
                                    <span className="textcolor">Product Weight:</span>
                                    <span>{" " + selectedItemObj.Weight + " " + selectedItemObj.WeightType}</span>
                                </div>}

                                <div className='mt-3'>
                                    <span className="textcolor">Details:</span>
                                </div>
                                <div className='mb-3'>
                                    {selectedItemObj.ItemDescription && <span className="">
                                        {selectedItemObj.ItemDescription?.length > 100 ?
                                            this.state.readmore ?
                                                <>{selectedItemObj.ItemDescription + "..."}<span class="atag-text" onClick={() => this.readmoreFun()}>read less</span></> :
                                                <>{selectedItemObj.ItemDescription.slice(0, 100) + "..."}<span class="atag-text" onClick={() => this.readmoreFun()}>read more</span></>
                                            :
                                            selectedItemObj.ItemDescription} </span>
                                    }
                                </div>

                                {/*{storeData && (!storeData.IsPickupEnable || !storeData.IsDeliveryEnable) && <div><center><p style={{ color: Color.RED, fontWeight: 'bold' }}>Temporarily closed, will be back soon!</p></center></div>}*/}

                                {storeData && (storeData.IsPickupEnable || storeData.IsDeliveryEnable) && ((selectedItemObj.TrackQuantity && (selectedItemObj.AvailableQuantity < 1)) ? false : true) && (
                                    specialDelivery ?
                                        < div className="bottam_sing" style={{ bottom: "0px" }}>
                                            <label
                                                // class="mr-3"
                                                style={{ backgroundColor: Color.THEME_COLOR, borderRadius: "4px" }}
                                                className="col-sm-6 btn btn-done m-0"
                                                // data-toggle="modal"
                                                // data-target="#myModal"
                                                onClick={() => this.openSDeliveryModal()}
                                            >Add to Cart</label>
                                        </div> :
                                        <div className="bottam_sing" style={{ bottom: "0px" }}>
                                            <label
                                                style={{ backgroundColor: Color.THEME_COLOR, borderRadius: "4px" }}
                                                onClick={this.handleAddToCart}
                                                className=" col-sm-6 btn btn-done m-0"
                                            >Add to Cart</label>
                                        </div>
                                )}

                                <div className="bottam_sing" style={{ bottom: "0px", marginBottom: "0px", marginTop: '10px' }}>
                                    <label
                                        style={{ backgroundColor: Color.THEME_COLOR, borderRadius: "4px" }}
                                        onClick={this.handleContinueShopping}
                                        className="col-sm-6 btn btn-done m-0">Continue Shopping</label>
                                </div>

                                {/* {this.state.IsTryItEnable ?
                                    <div className="bottam_sing" style={{ bottom: "0px", marginTop: '10px' }}>
                                        <label
                                            style={{ backgroundColor: Color.THEME_COLOR, borderRadius: "4px" }}
                                            onClick={this.handleTryIt}
                                            className="col-sm-6 btn btn-done m-0"
                                        > Tap and Try </label>
                                    </div>
                                    : ""} */}

                                <div className="bottam_sing" style={{ bottom: "0px", visibility: showToppingBtn, marginTop: '10px' }}>
                                    <label
                                        style={{ backgroundColor: Color.THEME_COLOR, borderRadius: "4px" }}
                                        onClick={this.handleAddToppings}
                                        className="col-sm-6 btn btn-done m-0"
                                    > Customize Toppings</label>
                                </div>
                            </div>

                            <div class="modal" id="toppingModal" style={{ marginBottom: "10px" }} centered>
                                <div class="modal-dialog" style={{ margin: "auto", top: "25%" }}>
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                                        </div>
                                        <div class="modal-body">
                                            <AddToppingsSingleStore
                                                selectedToppings={this.addSelectedToppings}
                                                plazaData={this.state.plazaData}
                                                storeData={this.state.storeData}
                                                itemData={this.state.selectedItemObj}
                                                setItemData={this.setItemObj}
                                                selectedToppingList={this.state.TempselectedToppingList /* this.state.selectedToppingList */}
                                                setSelectedToppings={this.setSelectedToppings}
                                                itemPrice={this.state.labelItemPrice}
                                                itemCount={this.state.TempitemCount/* this.state.itemCount */}
                                                setItemCount={this.setItemCount}
                                                totalPrice={this.state.TemptotalPrice/* this.state.totalPrice */}
                                                setTotalPrice={this.setTotalPrice}
                                                toppingCharge={this.state.TemptoppingCharge/* this.state.toppingCharge */}
                                                setToppingPrice={this.setToppingPrice}
                                                AvailableQuantity={this.state.AvailableQuantity}
                                                ContinueSelling={this.state.ContinueSelling}
                                                TrackQuantity={this.state.TrackQuantity} >
                                            </AddToppingsSingleStore>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* : null} */}
                            <div className="reg_form_main" style={{ marginBottom: "10px" }}>
                                {(storeItemList && storeItemList.length > 1) && <div className="" style={{ marginBottom: "5px" }}>
                                    <label style={{ fontWeight: 'bold' }}>Related Products</label>
                                    <ScrollMenu
                                        arrowLeft={ArrowLeft}
                                        arrowRight={ArrowRight}
                                        data={menu}
                                        selected={selected}
                                        onSelect={this.onSelect}
                                        alignCenter={false} />
                                </div>}
                            </div>
                        </div>

                        <div class="modal modalcustom" id="myModal" style={{ verticalAlign: "middle" }}>
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-body">
                                        <center>
                                            <label><b>{this.state.title}</b></label><br />
                                            <label>The delivery charge on the specific product is {currencySymbole} {Number(this.state.specialdeliverycharges).toFixed(2)} <br></br>
                                                Continue buying the selected products.</label>
                                        </center>

                                    </div>
                                    <div class="modal-footer">
                                        {/*<div class="w-50 text-center setaTag" >*/}
                                        <label
                                            class="m-btn"
                                            onClick={() => this.sDeliveryNo()}
                                        /* style={{ color: Color.THEME_COLOR, marginLeft: "auto", marginRight: "auto" }}*/
                                        // data-dismiss="modal"
                                        // onClick={() => this.unsetDeleteId()}
                                        > No</label>
                                        {/*</div>*/}

                                        {/*<div class="w-50 text-center setaTag" >*/}
                                        <label
                                            class="m-btn"
                                            onClick={this.handleAddToCart}
                                            // onClick={() => this.handleDelete()}
                                            //onClick={this.handleDelete}
                                            /*style={{ color: Color.THEME_COLOR, marginLeft: "auto", marginRight: "auto" }}*/
                                            data-dismiss="modal"
                                        > Yes</label>
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </LoadingOverlay >
            </div >
        );
    }
}

export default AddCartSingleStore;