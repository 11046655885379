import React, { Component } from 'react';
import './MyCart.css';
import Color from '../Config/Color';
import delete1 from "../../images/icons/delete.png";
import { StoreObjectInStorage } from '../Config/Utils';
import Constants,{COMPANYCONST} from '../Config/Constants';

class CartList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itemList: props.data,
            deleteItemID: ''
        }


        this.handleMinus = this.handleMinus.bind(this);
        this.handlePlus = this.handlePlus.bind(this);
        // this.handleDelete = this.handleDelete.bind(this);
        this.openDeleteDialog = this.openDeleteDialog.bind(this);
    }

    componentDidMount() {
    }



    handleMinus(item) {
        //console.log(item)
        var count = item.ItemQuantity
        var singleItemPrice = item.TotalItemPrice / count;

        var totalAmount = 0;
        if (count !== 1) {
            count = count - 1;
            var list1 = this.state.itemList.map(curr => {
                if (item.ItemId === curr.ItemId) {
                    curr.ItemQuantity = count
                    return { ...curr, "TotalItemPrice": singleItemPrice * count }
                } else {
                    return { ...curr }
                }
            })

            this.setState({ itemList: list1 }, function () {
                var updatedItem = this.state.itemList.find((o) => o.ItemId === item.ItemId)
                console.log("updatedItem ", updatedItem)
                this.props.setPlazaTotal(updatedItem);
            })
        }
    }

    handlePlus(item) {
        //console.log("item ",item)
        var count = item.ItemQuantity
        var singleItemPrice = item.TotalItemPrice / count;
        count = count + 1;

        var totalAmount = 0;

        var list1 = this.state.itemList.map(curr => {
            if (item.ItemId === curr.ItemId) {
                curr.ItemQuantity = count
                return { ...curr, "TotalItemPrice": singleItemPrice * count }
            } else {
                return { ...curr }
            }
        })

        this.setState({ itemList: list1 }, function () {
            var updatedItem = this.state.itemList.find((o) => o.ItemId === item.ItemId)
            console.log("updatedItem ", updatedItem)
            this.props.setPlazaTotal(updatedItem);
        })
    }

    handleDelete() {
        //$('#myModal').modal('show')
        console.log("delete")
        var list = this.props.data.filter((o) => o.ItemID !== this.state.deleteItemID)
        alert(JSON.stringify(list))
        StoreObjectInStorage(Constants.Obj_CART_ITEMS, list);
        this.props.populateList(this.state.deleteItemID);

    }

    openDeleteDialog(item) {
        console.log("delete obj", item)
        alert(JSON.stringify(item))
        this.setState({ deleteItemID: item.ItemId })
        $('#myModal').modal('show')
    }


    render() {
        let list = this.props.data
            .map(function (item, index) {
                // var singleItemPrice = item.TotalItemPrice / item.ItemQuantity
                var total = item.TotalItemPrice

                // var total = item.TotalItemPrice
                total = (Math.round(total * 100) / 100).toFixed(2);
                return (
                    <React.Fragment key={item.ItemID}>
                        <div className="col-sm-12" style={{ backgroundColor: Color.WHITE, borderBottom: "1px solid" + Color.GREY }}  >
                            <div className="row" style={{ paddingTop: "5px" }} >
                                <img src={item.ItemLogo} style={{ paddingLeft: "10px", height: "40px", width: "20%", marginRight: "5px", width: "40px" }}></img>
                                <label style={{ fontSize: "15px", width: "60%", paddingTop: "5px" }}> {item.ItemName} </label>
                                <span style={{ marginLeft: "auto", width: "20%", paddingTop: "5px", marginRight: "10px" }}>
                                    <label style={{ fontSize: "15px", float: "right", color: Color.THEME_COLOR, }}>${total}</label>
                                </span>
                            </div>

                            <div className="row" >
                                <span style={{ float: "right", marginLeft: "auto", }}>
                                    <label style={{ fontSize: "17px", color: Color.BLACK }} onClick={(value) => this.handleMinus(item)}> - </label>
                                    <label style={{ fontSize: "17px", color: Color.BLACK, width: "40px", textAlign: "center" }}> {item.ItemQuantity}  </label>
                                    <label style={{ fontSize: "17px", color: Color.BLACK, marginRight: "20px" }} onClick={(value) => this.handlePlus(item)} > + </label>
                                    <img src={delete1} style={{ marginLeft: "10px", marginRight: "10px", height: "20px", width: "20px" }} onClick={(value) => this.openDeleteDialog(item)}></img>
                                    {/* <i  class="fa fa-trash" style={{ marginLeft:"10px", marginRight:"10px", fontSize: "20px", color: Color.GREY,  }}></i> */}
                                </span>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }, this);

        return (
            <div>
                <div class="modal" id="myModal" style={{ verticalAlign: "middle" }}>
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-body">
                                <center>
                                    <label>{COMPANYCONST.PLAZACART}</label><br />
                                    <label>Are you sure you want remove this item from cart?</label>
                                </center>
                            </div>
                            <div class="modal-footer">
                                <label
                                    style={{ color: Color.THEME_COLOR, marginLeft: "auto", marginRight: "auto" }}
                                    data-dismiss="modal"
                                > No</label>

                                <label
                                    onClick={() => this.handleDelete()}
                                    //onClick={this.handleDelete}
                                    style={{ color: Color.THEME_COLOR, marginLeft: "auto", marginRight: "auto" }}
                                    data-dismiss="modal"
                                > Yes</label>
                            </div>
                        </div>
                    </div>
                </div>

                {list}
            </div>
        );
    }
}

export default CartList;