import React, { Component } from 'react';

import Constants, { DatetimeFormate, APPOINTMENTSTATUS } from '../Config/Constants'
import { RetriveDataFromStorage, StoreObjectInStorage, RemoveDataInStorage, RetriveObjectFromStorage, parseTimetoHour } from "../Config/Utils";
import Messages from "../Config/Messages";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import Header from '../Header/Header';
import Color from '../Config/Color';
import RouteConstant from '../Config/RouteConstant';
import plazablankIcon from "../../images/icons/plazablank.png";

import { GetData } from "../../services/GetData";
import { GET_TENANT_API, GET_USERPROFILE_API, APPOIMENT_APi } from '../Config/Config.json'
import Moment from "moment"
import LoadingOverlay from 'react-loading-overlay';
import './Appointment.css'
import Calendar from './Calendar';
import { arrayFromHours } from '../UtillCustomComponent/UtillCustomComponent'

class MyAppointment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            appointmentDetail: null
        }

    }

    componentDidMount() {
        var appointmentobj = RetriveObjectFromStorage(Constants.APPOINTMENT)
        if (appointmentobj) {
            this.setState({ appointmentDetail: appointmentobj })
        }
    }

    handleRefresh() {
        this.componentDidMount()
    }

    render() {
        const { appointmentDetail } = this.state;

        return (
            <div style={{ backgroundColor: Color.WHITE }}>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Constants.LoadingMssg}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className=""  >
                    <Header setHeaderTitle={"My Appointment"} onrefresh={() => this.handleRefresh()} />
                    <section className="section" style={{ backgroundColor: Color.WHITE }}>
                        <div className="container appnmnt-container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                            <div className="reg_form_main">

                                <div className="container mb-5" style={{ paddingLeft: "2px", paddingRight: "2px" }} >

                                    {appointmentDetail && <div class="card mt-5">
                                        <div class="card-header text-center">
                                            {appointmentDetail.appoimentstatus}
                                        </div>
                                        <div class="card-body">
                                            <div class="row m-0">
                                                <div class="col-lg-8 offset-lg-2 col-md-8 offset-md-2">
                                                    {appointmentDetail.appoimentstatus == APPOINTMENTSTATUS.PENDING && <>
                                                        <h6 class="card-title text-center">{Messages.appointmentm1} {appointmentDetail.storename} {Messages.appointmentm2} </h6>
                                                        <h6 class="card-title text-center">{Messages.appointmentm3}</h6>
                                                    </>}
                                                    {appointmentDetail.appoimentstatus == APPOINTMENTSTATUS.CONFIRMED && <h6 class="card-title text-center">{Messages.appointmentm4} {appointmentDetail.storename}</h6>}
                                                    <p class="card-text store-info">
                                                        <i class="fas fa-map-marker-alt store-icon mt-2"></i>
                                                        &nbsp;{appointmentDetail.storeaddress}
                                                    </p>
                                                    <p class="card-text store-info">
                                                        <i class="fas fa-calendar store-icon mt-2"></i>
                                                        &nbsp;
                                                        {`${appointmentDetail.starttime} - ${appointmentDetail.endtime}, ${Moment(appointmentDetail.date).format(DatetimeFormate.ddN_DD_MN_Y)}`}
                                                    </p>
                                                    <p class="card-text store-info">
                                                        {appointmentDetail.customernotes}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}


                                </div>

                            </div>
                        </div>
                        {/* </LoadingOverlay> */}
                    </section>
                </LoadingOverlay>
            </div>

        );
    }
}

export default MyAppointment;