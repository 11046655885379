import React, { Component } from 'react';
import Color from '../../Config/Color';
import HeaderOrderWeb from '../../OrderWeb/Header/HeaderOrderWeb';
import './MyCartOrderWebSingleStore.css';
import RouteConstant from '../../Config/RouteConstant';
import Constants, { currencyInfo } from '../../Config/Constants';
import { RetriveObjectFromStorage, StoreObjectInStorage, RemoveDataInStorage } from '../../Config/Utils';
import CartPlazaListOrderWeb from './CartPlazaListOrderWeb';
import Messages from '../../Config/Messages'
import 'react-data-table-component-extensions/dist/index.css';

class MyCartOrderWebSingleStore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countryFees: {},
            cartList: [],
            totalAmount: 0.0,
            plazzaList: [],
            showEmptyCartVw: true,
            isShopifyAvailable: false,
            error: {}
        }
        this.handleShopNow = this.handleShopNow.bind(this);
        this.handleCheckout = this.handleCheckout.bind(this);
        this.handleContinueShopping = this.handleContinueShopping.bind(this);
    }

    componentDidMount() {
        var objCountryFees = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES, '');
        console.log("objCountryFees ", objCountryFees);
        this.setState({ countryFees: objCountryFees });
        var objCart = RetriveObjectFromStorage(Constants.Obj_CART, '');
        if (objCart && objCart.length > 0) {
            this.setState({ showEmptyCartVw: false });
            this.setState({ cartList: objCart }, function () {
                console.log("cart model", JSON.stringify(objCart));
                if (objCart && objCart.length > 0) {
                    var isShopifyAvailable = objCart[0].isshopifyintegration;
                    this.setState({ isShopifyAvailable: isShopifyAvailable });
                }
                this.setTotalCheckoutAmount(this.state.cartList);
            });
        }
        else {
            this.setState({ showEmptyCartVw: true })
        }
    }

    handleContinueShopping() {
        var StoreMainObject = RetriveObjectFromStorage(Constants.Obj_STORE, '');
        if (StoreMainObject) {
            this.props.history.push(RouteConstant.OrderWebNavigation + "/" + StoreMainObject.ObjectId + "?web=" + StoreMainObject.websiteurl);
        }
    }

    setTotalCheckoutAmount = (list1) => {
        var itemCount = 0
        var plazaTotalAmount = 0.0
        let list = list1
            .map(function (item, index) {
                var storeItemList = item.Items;
                storeItemList.map(function (objitem, index2) {
                    var data = objitem;
                    itemCount = itemCount + 1
                    plazaTotalAmount = plazaTotalAmount + ((data.TotalItemPrice / data.ItemQuantity) * data.ItemQuantity);
                });
            });
        this.setState({ totalAmount: plazaTotalAmount, showEmptyCartVw: itemCount == 0 });
        this.setState({ plazzaList: list1 });
        list1 = list1.length == 0 ? "" : list1;
        StoreObjectInStorage(Constants.Obj_CART, list1);
        if (!list1) {
            window.location.reload(true);
        }
    }

    deletedItem(list) {
        this.setTotalCheckoutAmount(list);
    }

    handleShopNow(e) {
        var StoreMainObject = RetriveObjectFromStorage(Constants.Obj_STORE, '');
        if (StoreMainObject) {
            this.props.history.push(RouteConstant.OrderWebNavigation + "/" + StoreMainObject.ObjectId + "?web=" + StoreMainObject.websiteurl);
        }
    }

    handleCheckout() {
        var objCart = RetriveObjectFromStorage(Constants.Obj_CART, '');
        console.log('plazza list=>', this.state.plazzaList);
        StoreObjectInStorage(Constants.Obj_CART, this.state.plazzaList);
        this.props.history.push({
            pathname: RouteConstant.OrderWebCheckout
        });
    }

    updateStateByChild = () => {
        let { error } = this.state;
        error["mastererr"] = Messages.v_productQun
        this.setState({ error })
        setTimeout(() => {
            this.setState({ error: {} })
        }, 2000)
    }

    // modal method
    handleModal(falg, id) {
        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById(id);

        if (backgroung && modalId) {
            backgroung.style.display = falg ? "block" : "none";
            modalId.style.display = falg ? "block" : "none";
        }
    }
    //

    handleEmptyCart() {
        this.handleModal(false, "myModalDelete")
        RemoveDataInStorage(Constants.Obj_CART)
        RemoveDataInStorage(Constants.finelObj_BeforePayemt)
        window.location.reload();
    }

    render() {
        var currencySymbole = "";
        var countryFees = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES);
        if (countryFees) {
            currencySymbole = (countryFees[currencyInfo.CountryCode] ?
                "(" + countryFees[currencyInfo.CountryCode] + ") " : "") + countryFees.Symbol;
        }

        return (
            <div>
                <HeaderOrderWeb
                    setHeaderTitle={Constants.Title_MyCart}
                    countItemCart={(this.state.plazzaList && this.state.plazzaList.length > 0 && this.state.plazzaList[0].Items) ? "" + this.state.plazzaList[0].Items.length : " "} />
                <section className="section">
                    {
                        (this.state.showEmptyCartVw && this.state.totalAmount == 0) ?
                            <div className="container" id="vwEmptyCart" style={{ paddingLeft: "2px", paddingRight: "2px", verticalAlign: "middle" }}>
                                <div className="reg_form_main">
                                    <center>
                                        <i class="fas fa-shopping-cart" style={{ fontSize: "50px", color: Color.BLACK }}></i><br /><br />
                                        <label style={{ fontWeight: "bold" }}>Your cart is empty</label>
                                    </center>

                                    <div className="bottam_sing" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                                        <label
                                            style={{ backgroundColor: Color.THEME_COLOR, borderRadius: "4px", marginLeft: '0px', marginRight: '0px' }}
                                            onClick={this.handleShopNow}
                                            className="col-sm-5 btn btn-done"
                                        > Shop Now</label>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                                <div className="reg_form_main offset-sm-3 col-sm-6">
                                    <label className="col-sm-12 errorMsg">
                                        {this.state.error.mastererr}
                                    </label>
                                </div>
                                <div className="reg_form_main" style={{ margin: "0px 0px 200px 0px" }}>
                                    <CartPlazaListOrderWeb
                                        data={this.state.cartList}
                                        setPlazaTotal={this.setTotalCheckoutAmount}
                                        updateState={this.updateStateByChild}
                                        deletedItemwith={(list) => { this.deletedItem(list) }}>
                                    </CartPlazaListOrderWeb>
                                </div>
                                <div className="bottam_sing" style={{ paddingBottom: "5px", left: "0px", position: "fixed", bottom: "120px" }}>
                                    <label
                                        style={{ float: "center", borderRadius: "4px", marginBottom: "10px", minWidth: "360px" }}
                                        onClick={() => this.handleModal(true, "myModalDelete")}
                                        className="btn btn-done setaTag"
                                    > Clear Cart</label>
                                </div>
                                <div className="bottam_sing" style={{ paddingBottom: "5px", left: "0px", position: "fixed", bottom: "60px" }}>
                                    <label
                                        style={{ float: "center", borderRadius: "4px", marginBottom: "10px", minWidth: "360px" }}
                                        onClick={this.handleCheckout}
                                        className="btn btn-done setaTag"
                                    > Go to Checkout - {currencySymbole}{(Math.round(this.state.totalAmount * 100) / 100).toFixed(2)}</label>
                                </div>
                                <div className="bottam_sing" style={{ paddingBottom: "5px", left: "0px", position: "fixed", bottom: "0px" }}>
                                    <label
                                        style={{ float: "center", borderRadius: "4px", marginBottom: "10px", minWidth: "360px" }}
                                        onClick={this.handleContinueShopping}
                                        className="btn btn-done setaTag">Continue Shopping</label>
                                </div>
                            </div>
                    }
                    {/* delete cart product modal */}
                    <div class="modal modalcustom" id="myModalDelete" style={{ verticalAlign: "middle" }}>
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <center>
                                        <label>Plazacart</label><br />
                                        <label>{Messages.emptyCart}</label>
                                    </center>
                                </div>
                                <div class="modal-footer">
                                    <label
                                        onClick={() => this.handleModal(false, "myModalDelete")}
                                        class="m-btn"
                                    > No</label>

                                    <label
                                        onClick={() => this.handleEmptyCart()}
                                        class="m-btn"
                                    > Yes</label>
                                </div>
                            </div>
                        </div>
                        {/* delete cart product modal */}
                    </div>
                </section>
            </div>
        );
    }
}

export default MyCartOrderWebSingleStore;