export const GOOGLEPLACEAPIMAP = {
    STREET_NUMBER: "street_number",
    ROUTE: "route",
    POSTAL_CODE: "postal_code",
    POSTAL_CODE_SUFFIX: "postal_code_suffix",
    LOCALITY: "locality",
    ADMINISTRATIVE_AREA_LEVEL_1: "administrative_area_level_1",
    COUNTRY: "country"
}

export const LandingPageConst = {
    Country: "Country",
    Address: "Type Your Address",
    ZIP_PostalCode: "Enter ZIP / Postal Code",
    btn_SIGNUP: "Sign Up",
    btn_SIGNIN: "Sign In"
}

export const RegisterConst = {
    btn_Continue: "Continue",
    btn_back: "Back",
    btn_JOINWITHEMAIL: "Join with Email",
    btn_JOINWITHGOOGLE: "Join with Google",
    btn_JOINWITHFACEBOOK: "Join with Facebook",
    ALREADYAUSERSIGNIN: "Already Registered?",
    FirstName: "First Name",
    LastName: "Last Name",
    Email: "Email",
    Password: "Password",
    ConfirmPassword: "Confirm Password",
    screen3Msg: "I agree to the",
    screen3Msg1: "Terms of Service",
    screen3Msg2: "and",
    screen3Msg3: "Privacy Policy",
    screen3Msg4: "and agree to accept email communication.",
    screen4Msg: "Note: Your phone number will only be used if the brewersnearme.com needs to confirm something with your order.",
    screen4Msg1: "Phone Number",
    Sign_up_with_Email: "Sign up with Email",
    screen5Msg: "Your One Time Code has been sent to the e-mail associated with your account.",
    btn_Submit: "Submit",
    Captcha: "Captcha Code"
}

export const OrderWebCheckoutConst = {
    btn_Continue: "Continue",
    btn_back: "Back",
    btn_JOINWITHEMAIL: "Join with Email",
    Sign_up_with_Email: "Sign up with Email",
    btn_JOINWITHGOOGLE: "Join with Google",
    btn_JOINWITHFACEBOOK: "Join with Facebook",
    ALREADYAUSERSIGNIN: "Already Registered?",
    FirstName: "First Name",
    LastName: "Last Name",
    Email: "Email",
    Password: "Password",
    ConfirmPassword: "Confirm Password",
    screen3Msg: "I agree to the",
    screen3Msg1: "Terms of Service",
    screen3Msg2: "and",
    screen3Msg3: "Privacy Policy",
    screen3Msg4: "and agree to accept email communication.",
    screen4Msg: "Note: Your phone number will only be used if the plazacart.com needs to confirm something with your order.",
    screen4Msg1: "Phone Number",
    screen5Msg: "Your One Time Code has been sent to the e-mail associated with your account.",
    btn_Submit: "Submit",
    Captcha: "Captcha Code",
    btn_SIGNIN: "Sign In",
    btn_SIGNUP: "Sign Up",
    BILLING_ADDRESS: "Billing Address",
    DELIVERY_ADDRESS: "Delivery Address",
    TYPE_BILLING_ADDRESS: "Type Your Billing Address",
    TYPE_DELIVERY_ADDRESS: "Type Your Delivery Address",
    ZIP_PostalCode: "Enter ZIP / Postal Code",
    ZIP_PostalCode_H: "ZIP / Postal Code"
}

export const LoginConst = {
    screenTitle: "Sign in with your Email and Password",
    Rememberme: "Remember me",
    Forgotpassword: "Forgot password?",
    btn_SIGNIN: "Sign In",
    btn_SIGNUP: "Sign Up",
    btn_JOINWITHGOOGLE: "Join with Google",
    btn_JOINWITHFACEBOOK: "Join with Facebook"
}

export const ForgotPasswordConst = {
    YourEmail: "Your Email",
    btn_Submit: "Submit",
    Title_ForgotPwd: "Reset Password",
    ForgotPasswordValidOTP: "Your One Time Code has been sent to the e-mail associated with your account.",
    CreateNewPassword: "Create New Password",
    CreateNewConfirmPassword: "Create New Confirm Password",
}

export const TermsService = {
    screenTitle: "Terms and Conditions"
}

export const IMAGEFILEEXTENSIONS = ["jpg", "jpeg", "bmp", "gif", "png", "JPG", "JPEG", "BMP", "GIF", "PNG"];

export const IMAGEFILEEXTENSIONSINHTML = ".jpg,.jpeg,.bmp,.gif,.png,.JPG,.JPEG,.BMP,.GIF,.PNG";

export const PrivacyAndPolicy = {
    screenTitle: "Privacy Policy"
}

// // add on 5-apr-2023

export const PlazaCartPartnerConst = {
    Name: "Name",
    Email: "Email",
    PhoneNumber: "Phone Number",
    AddressLine1: "Address Line 1",
    City: "City",
    State: "State",
    Country: "Country",
    ZIP_PostalCode: "ZIP / Postal Code",
    btn_Submit: "Submit",
    PlazaCartDeliveryPartner: "BrewersNearMe Delivery"
}

//