import React, { Component } from 'react';
import Collapsible from 'react-collapsible';
import ontime1 from "../../../images/icons/on-time2.png";
import Color from '../../Config/Color';
import './CheckoutOrderWebSingleStore.css';
import DeliveryTimeDayOrderWeb from './DeliveryTimeDayOrderWeb';
import PickupTimeDayOrderWeb from './PickupTimeDayOrderWeb';

class PlazaStoreOrderWeb extends Component {
    constructor(props) {
        super(props);
        this.state = {
            plazalist: props.data,
            timeDayHeader: "",
            selectedRdoBtn: props.selectedRdoBtn
        }
    }

    componentDidMount() {
        this.setHeaderForDeliveryPickup();
    }

    setHeaderForDeliveryPickup = () => {
        if (this.state.selectedRdoBtn === "2") {
            this.setState({ timeDayHeader: "Delivery" });
        } else {
            this.setState({ timeDayHeader: "Pickup" });
        }
    }

    setDate = (date, storeId, plazaId, time) => {
        var list1 = this.props.data.map(curr => {
            return { ...curr, DeliveryDate: date, DeliveryTime: time }
        });
        this.setState({ plazalist: list1 }, function () {
            this.props.updateCartObj(this.state.plazalist);
        });
    }

    setTime = (time, storeId, plazaId) => {
        var list1 = this.props.data.map(objStore => {
            return { ...objStore, DeliveryTime: time }
        });

        this.setState({ plazalist: list1 }, function () {
            this.props.updateCartObj(this.state.plazalist);
        });
    }

    setTimewithcharge = (timeObj, storeId, plazaId) => {
        var list1 = this.props.data.map(objStore => {
            return { ...objStore, DeliveryTime: timeObj, "TotalDeliveryCharges": 0 }
        });
        console.log("setTime plaza  delivery=====", list1);
        this.setState({ plazalist: list1 }, function () {
            this.props.updateCartObj(this.state.plazalist);
        });
    }

    render() {
        let list = this.props.data
            .map(function (objStore, index) {
                var Deliverytimes = objStore.Deliverytimes
                var Pickuptimes = objStore.Pickuptimes
                var storeID = objStore.ObjectId
                var preselectedTime = objStore.Deliverytimes[0] ? objStore.Deliverytimes[0].DeliveryTime : "";

                var TimeZone = "";
                if (objStore && objStore.StoreHourList.length > 0) {
                    if (objStore.StoreHourList[0] && objStore.StoreHourList[0].timezoneshortdescription) {
                        TimeZone = objStore.StoreHourList[0].timezoneshortdescription;
                    }
                }

                return (
                    <React.Fragment key={objStore.ObjectId}>
                        <div className="">
                            <Collapsible
                                open={false}
                                trigger={
                                    <div className="col-sm-12" style={{ marginTop: "10px", backgroundColor: Color.WHITE, paddingBottom: "5px", borderBottom: "1px solid" + Color.GREY, marginBottom: "5px" }} >
                                        <div style={{ width: "100%" }}>
                                            <div className="row">
                                                <img src={ontime1} style={{ height: "30px", width: "30px" }}></img>
                                                <label style={{ marginLeft: "5px", color: Color.BLACK, fontWeight: 'bold' }}>{objStore.ApplicationName} - {this.props.selectedRdoBtn === "1" ? "Pickup" : "Delivery"} Time/Day {"(" + TimeZone + ")"}</label>
                                                <i class="fa fa-caret-down" style={{ marginLeft: "auto", fontSize: "30px", color: Color.THEME_COLOR, float: "right", marginRight: "5px" }} aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                }
                                triggerWhenOpen={
                                    <div className="col-sm-12" style={{ marginTop: "10px", backgroundColor: Color.WHITE, paddingBottom: "5px", borderBottom: "1px solid" + Color.GREY, marginBottom: "5px" }} >
                                        <div style={{ width: "100%" }}>
                                            <div className="row">
                                                <img src={ontime1} style={{ height: "30px", width: "30px" }}></img>
                                                <label style={{ marginLeft: "5px", color: Color.THEME_COLOR, fontWeight: 'bold' }}>{objStore.ApplicationName} - {this.props.selectedRdoBtn === "1" ? "Pickup" : "Delivery"} Time/Day {"(" + TimeZone + ")"}</label>
                                                <i class="fa fa-caret-up" style={{ marginLeft: "auto", fontSize: "30px", color: Color.THEME_COLOR, float: "right", marginRight: "5px" }} aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                } >

                                <div className="col-sm-12 p-0" style={{ marginTop: "10px" }}>
                                    {
                                        this.props.selectedRdoBtn === "1" ?
                                            <PickupTimeDayOrderWeb
                                                storehourlist={objStore.StoreHourList ? objStore.StoreHourList : []}
                                                plazaid={objStore.PlazaId}
                                                storeid={objStore.ObjectId}
                                                setPickupDay={this.setDate}
                                                setPickupTime={this.setTime}
                                                pickUpTimesList={Pickuptimes}>
                                            </PickupTimeDayOrderWeb>
                                            :
                                            <DeliveryTimeDayOrderWeb
                                                storehourlist={objStore.StoreHourList ? objStore.StoreHourList : []}
                                                plazaid={objStore.PlazaId}
                                                storeid={objStore.ObjectId}
                                                setPickupDay={this.setDate}
                                                setPickupTime={this.setTimewithcharge}
                                                setDeliveryCharge={this.setDeliveryCharge}
                                                pickUpTimesList={Deliverytimes}>
                                            </DeliveryTimeDayOrderWeb>
                                    }
                                </div>
                            </Collapsible>
                        </div>
                    </React.Fragment>
                )
                return (
                    <React.Fragment key={item.PlazaId}>
                        <p className="textcolor" style={{ marginTop: "5px" }}>
                            {item.CompanyName}
                            {storelist}
                        </p>
                    </React.Fragment>
                )
            }, this);

        return (
            <div>
                {list}
            </div>
        );
    }
}

export default PlazaStoreOrderWeb;



