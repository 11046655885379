import React, { Component } from 'react';
import { GetData } from "../../services/GetData";
import Color from '../Config/Color';
import { FAVORITE, GETAPPBY_TENANT_API, MERCHANT, GET_USERPROFILE_API, PRODUCTS_API, PRODUCTS_SEARCH_API, SEARCHKEY, MY_STORE_API, POSTALCODEKEY, GET_COUNTRY_API, GET_TENANT_API } from '../Config/Config.json';
import Constants, { PlatFrom, DEFAULTID, currencyInfo } from '../Config/Constants';
import Messages from "../Config/Messages";
import { RemoveDataInStorage, RetriveDataFromStorage, RetriveObjectFromStorage, StoreDataInStorage, StoreObjectInStorage } from "../Config/Utils";
import Header from '../Header/Header';
import './PlazaStore.css';

import ScrollMenu from 'react-horizontal-scrolling-menu';
import RouteConstant from '../Config/RouteConstant';

import LoadingOverlay from 'react-loading-overlay';
import "react-sliding-pane/dist/react-sliding-pane.css";
import NoPriview from '../../../src/images/nopreviewp.png';
import shipping from '../../images/extraicon/shipping.png';
import shippingDis from '../../images/extraicon/shippingDis.png';
import { ItemClass, PlazaClass, StoreClass } from '../Config/PlazaClass';
import truck from '../../images/extraicon/truck.png';
import truckDis from '../../images/extraicon/truckDis.png';
import { PostData } from "../../services/PostData";
import PlazaStoreForm, { PlazaListHover } from '../Config/HoverMessage';
import OnHoverIconInfo from '../UtillCustomComponent/UtillCustomComponent';


class PlazaStore extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: "",
            pageTitle: '',

            suggestedStoreList: [],
            favoriteList: [],
            allApplicationList: [],

            storeList: [],
            isActive: false,

            plazaId: DEFAULTID,

            // changes for Brewers 9-may-2023
            productSearch: "",
            productSearchKey: "",
            profileDetail: null,
            ProductSearchList: [],
            favoriteStoreIds: [],
            countryInfo: null,
            error: {}
            //
        }

        this.handelChange = this.handelChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this)
    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);

        // changes for Brewers 9-may-2023
        // var objPlaza = RetriveObjectFromStorage(Constants.Obj_PLAZA);
        //console.log(objPlaza);
        RemoveDataInStorage(Constants.Obj_CART)
        RemoveDataInStorage(Constants.finelObj_BeforePayemt)
        // if (objPlaza) { objPlaza.CompanyName
        this.setState({ pageTitle: "", plazaId: DEFAULTID })
        var objUser = RetriveObjectFromStorage(Constants.Obj_USER);
        if (objUser) {
            this.setState({ profileDetail: objUser })
            this.getStoreByTentatIDAPI(authToken, objUser.PostalCode)
        }
        else {
            this.getUserDetail(authToken)
        }
        this.getFavoriteStore(authToken)
        this.getCountryAPI(authToken)
        // }
        //
    }

    getFavoriteStore(authToken) {
        var API = MERCHANT + "/" + FAVORITE;
        GetData(API, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    this.setState({ favoriteStoreIds: responseData ? responseData : [] });
                }
            });
    }

    getUserDetail(authToken) {
        this.setState({ isActive: true })
        var API = GET_USERPROFILE_API;
        GetData(API, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        StoreObjectInStorage(Constants.Obj_USER, responseData)
                        this.setState({ profileDetail: responseData })
                        this.getStoreByTentatIDAPI(authToken, responseData.PostalCode)
                    }
                    else {
                        this.setState({ isActive: false })
                    }
                } else {
                    this.setState({ isActive: false })
                }

            });
    }

    // changes for Brewers 9-may-2023
    getCountryAPI(authToken) {
        GetData(GET_TENANT_API, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData) {
                        const { suggestedplaza, plaza, ...rest } = responseData;
                        StoreObjectInStorage(Constants.Obj_COUNTRY_FEES, rest);
                    }
                }
            });
    }
    //

    getStoreByTentatIDAPI(authToken, postalCode) {
        this.setState({ isActive: true });
        // 62d7b5a28c4367320403cf65
        var API = GETAPPBY_TENANT_API.replace('#TENANTID#', DEFAULTID) + "&postalcode=" + postalCode;
        GetData(API, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("getStoreByTentatIDAPI=>", responseData)

                    var list = [];
                    if (responseData.SuggestedApplicationList && responseData.SuggestedApplicationList.length > 0) {
                        var suggestedStore = {}
                        suggestedStore["SubCategoryName"] = "Suggested"
                        suggestedStore["ApplicationList"] = responseData.SuggestedApplicationList
                        list.push(suggestedStore);
                        // this.setState({ suggestedStoreList: list });
                    }
                    if (responseData.AllApplicationList) {
                        list = [...list, ...responseData.AllApplicationList]
                    }
                    this.setState({
                        allApplicationList: list,
                        favoriteList: responseData.favoriteApplicationList ? responseData.favoriteApplicationList : []
                    });
                }
                else {
                    //this.setState({ hideEmptyPlazaView: false });
                    // alert(Messages.ERR_SomethingWentWrong);
                }
                this.setState({ isActive: false });
            });
    }

    onSelect = key => {
        StoreObjectInStorage(Constants.Obj_STORE, JSON.parse(key));
        var storeKey = JSON.parse(key);
        if (storeKey && storeKey.TaxesDetail) {
            var co = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES);
            if (co) {
                co['TaxesDetail'] = storeKey.TaxesDetail;
                co["itemLevelTax"] = storeKey.storetaxes ? storeKey.storetaxes : [];
                StoreObjectInStorage(Constants.Obj_COUNTRY_FEES, co);
            }
        }
        if (storeKey && storeKey.ApplicationFee && storeKey.ApplicationFeePercentage) {
            var co = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES);
            if (co) {
                co['ApplicationFee'] = storeKey.ApplicationFee;
                co["ApplicationFeePercentage"] = storeKey.ApplicationFeePercentage;
                StoreObjectInStorage(Constants.Obj_COUNTRY_FEES, co);
            }
        }
        if (storeKey.isshopifyintegration) {
            StoreDataInStorage(Constants.PLATFORM_FLAG, PlatFrom.Shopify);
            this.props.history.push({
                pathname: RouteConstant.PlazaStoreItemsShopify
            });
        }
        else if (storeKey.iswixintegration) {
            StoreDataInStorage(Constants.PLATFORM_FLAG, PlatFrom.Wix);
        }
        else {
            StoreDataInStorage(Constants.PLATFORM_FLAG, PlatFrom.Plazacart);
            this.props.history.push({
                pathname: RouteConstant.PlazaStoreItems
            });
        }
    }

    handelFavorite(e, storeobj, flag) {
        e.stopPropagation();
        var postData = {}
        postData["storeid"] = storeobj.ObjectId
        postData['plazaid'] = this.state.plazaId
        postData["Isfavorite"] = flag

        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);

        if (storeobj.ObjectId && this.state.plazaId) {
            this.setFavorite(authToken, postData)
        }

    }

    setFavorite(authToken, postData) {
        this.setState({ isActive: true });
        var URL = MERCHANT + "/" + FAVORITE;
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                console.log("fav ", result)
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    const { profileDetail } = this.state
                    this.getStoreByTentatIDAPI(authToken, profileDetail.PostalCode)
                    // if (this.state.productSearch.length > 2) {
                    //     this.callSearchApi();
                    // }
                    this.getFavoriteStore(authToken)

                }
                else {
                    this.setState({ isActive: false });
                    // alert(Messages.ERR_SomethingWentWrong)
                }
            })
    }

    handelAppointment(e, obj) {
        e.stopPropagation();
        var storeKey = obj
        StoreObjectInStorage(Constants.Obj_STORE, obj)
        if (storeKey && storeKey.TaxesDetail) {
            var co = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES);
            if (co) {
                co['TaxesDetail'] = storeKey.TaxesDetail;
                co["itemLevelTax"] = storeKey.storetaxes ? storeKey.storetaxes : [];
                StoreObjectInStorage(Constants.Obj_COUNTRY_FEES, co);
            }
        }
        if (storeKey && storeKey.ApplicationFee && storeKey.ApplicationFeePercentage) {
            var co = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES);
            if (co) {
                co['ApplicationFee'] = storeKey.ApplicationFee;
                co["ApplicationFeePercentage"] = storeKey.ApplicationFeePercentage;
                StoreObjectInStorage(Constants.Obj_COUNTRY_FEES, co);
            }
        }
        if (storeKey.isshopifyintegration) {
            StoreDataInStorage(Constants.PLATFORM_FLAG, PlatFrom.Shopify);
            this.props.history.push({
                pathname: RouteConstant.Appointment
            });
        }
        else if (storeKey.iswixintegration) {
            StoreDataInStorage(Constants.PLATFORM_FLAG, PlatFrom.Wix);
        }
        else {
            StoreDataInStorage(Constants.PLATFORM_FLAG, PlatFrom.Plazacart);
            this.props.history.push({
                pathname: RouteConstant.Appointment
            });
        }
    }

    // changes for Brewers 9-may-2023
    ////////////////////////////////////////////////////////////////////////////////////////////////

    handelChange(event) {
        let value = event.target.value
        this.setState({ productSearch: value })
        if (!value) {
            this.setState({ productSearchKey: "", error: {} })
        }
    }

    handleKeyPress(event) {
        if (event.key == "Enter") {
            event.preventDefault()
            if (this.state.productSearch.length > 2) {
                this.setState({ productSearchKey: this.state.productSearch, error: {} });
                this.callSearchApi();
            }
            else {
                var error = this.state.error
                error["searchProducterr"] = Messages.v_ProductSearch;
                this.setState({ error });
            }
        }
    }

    searchItem = (e) => {
        e.preventDefault();
        if (this.state.productSearch.length > 2) {
            this.setState({ productSearchKey: this.state.productSearch, error: {} });
            this.callSearchApi();
        }
        else {
            var error = this.state.error
            error["searchProducterr"] = Messages.v_ProductSearch;
            this.setState({ error })
        }
    }

    callSearchApi() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        let productSearch = this.state.productSearch;
        if (this.state.profileDetail) {
            this.getProductSearchapi(authToken, productSearch);
        }
    }

    getProductSearchapi(authToken, productSearch) {
        this.setState({ isActive: true });
        var API = MY_STORE_API + "/" + PRODUCTS_SEARCH_API + "?" + SEARCHKEY + productSearch + "&" + POSTALCODEKEY + this.state.profileDetail.PostalCode;
        GetData(API, authToken)
            .then(result => {
                var responseJson = result;
                console.log("Product Search =======", JSON.stringify(result))
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    this.setState({ ProductSearchList: responseData ? responseData : [] })
                }
                else {
                    this.setState({ hideEmptyPlazaView: false });
                }
                this.setState({ isActive: false });
            }).catch(error => {
                this.setState({ isActive: false });
            });
    }

    handelAppointmentSearch(e, item) {
        e.stopPropagation();
        if (item && item.items && item.items[0]) {
            // var plazaObj = PlazaClass(item.items[0]);
            var storeKey = StoreClass(item.items[0]);
            StoreObjectInStorage(Constants.Obj_STORE, storeKey);
            if (item && item.TaxesDetail) {
                var co = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES);
                if (co) {
                    co['TaxesDetail'] = item.TaxesDetail;
                    co["itemLevelTax"] = item.storetaxes ? item.storetaxes : [];
                    StoreObjectInStorage(Constants.Obj_COUNTRY_FEES, co);
                }
            }
            if (item && item.ApplicationFee && item.ApplicationFeePercentage) {
                var co = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES);
                if (co) {
                    co['ApplicationFee'] = item.ApplicationFee;
                    co["ApplicationFeePercentage"] = item.ApplicationFeePercentage;
                    StoreObjectInStorage(Constants.Obj_COUNTRY_FEES, co);
                }
            }
            // StoreObjectInStorage(Constants.Obj_PLAZA, plazaObj);
            if (storeKey.isshopifyintegration) {
                // StoreDataInStorage(Constants.PLATFORM_FLAG, PlatFrom.Shopify);
                // this.props.history.push({
                //     pathname: RouteConstant.Appointment
                // });
            }
            else if (storeKey.iswixintegration) {
                // StoreDataInStorage(Constants.PLATFORM_FLAG, PlatFrom.Wix);
            }
            else {
                StoreDataInStorage(Constants.PLATFORM_FLAG, PlatFrom.Plazacart);
                this.props.history.push({
                    pathname: RouteConstant.Appointment
                });
            }
        }
    }

    handelFavoriteSearch(plazaID, stroreID, isStoreFavorite) {
        var postData = {}
        postData["storeid"] = stroreID;
        postData['plazaid'] = DEFAULTID;
        postData["Isfavorite"] = isStoreFavorite;
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        if (stroreID) {
            this.setFavoriteSearch(authToken, postData);
        }
    }

    setFavoriteSearch(authToken, postData) {
        this.setState({ isActive: true });
        var URL = MERCHANT + "/" + FAVORITE;
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.getFavoriteStore(authToken);
                    if (this.state.productSearch.length > 2) {
                        this.setState({ productSearchKey: this.state.productSearch, error: {} });
                        this.callSearchApi();
                        const { profileDetail } = this.state
                        this.getStoreByTentatIDAPI(authToken, profileDetail.PostalCode)
                    }
                    else {
                        var error = this.state.error
                        error["searchProducterr"] = Messages.v_ProductSearch;
                        this.setState({ error });
                    }
                }
                else {
                    this.setState({ isActive: false });
                }
            }).catch(error => {
                this.setState({ isActive: false });
            });
    }

    handleItemClick(itemObj, plazaObj) {
        if (plazaObj && plazaObj.TaxesDetail) {
            var co = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES);
            if (co) {
                co['TaxesDetail'] = plazaObj.TaxesDetail;
                co["itemLevelTax"] = plazaObj.storetaxes ? plazaObj.storetaxes : []
                StoreObjectInStorage(Constants.Obj_COUNTRY_FEES, co);
            }
        }
        if (plazaObj && plazaObj.ApplicationFee && plazaObj.ApplicationFeePercentage) {
            var co = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES);
            if (co) {
                co['ApplicationFee'] = plazaObj.ApplicationFee;
                co["ApplicationFeePercentage"] = plazaObj.ApplicationFeePercentage;
                StoreObjectInStorage(Constants.Obj_COUNTRY_FEES, co);
            }
        }
        // var plazaObj = PlazaClass(itemObj);
        let logoPath = itemObj.logoImagepath ? itemObj.logoImagepath : "", bannerPath = itemObj.bannerImagepath ? itemObj.bannerImagepath : "", ToppingCategory = []
        if (itemObj.ToppingCategory) {
            ToppingCategory = itemObj.ToppingCategory.map(e => {
                var ToppingItem = []
                if (e.ToppingItems) {
                    ToppingItem = e.ToppingItems.filter(itemObj => itemObj.Status == PlazaCartAllStatus.ACTIVE)
                }
                return {
                    ...e,
                    "ToppingItems": ToppingItem
                }
            })
            ToppingCategory = ToppingCategory.filter(e => (e.ToppingItems.length > 0 && e.Status == PlazaCartAllStatus.ACTIVE))
        }
        itemObj = {
            ...itemObj,
            "AppLogo": logoPath.replace("##APPID##", itemObj.applicationguid) + itemObj.LogoPath,
            "AppBannerImage": bannerPath.replace("##APPID##", itemObj.applicationguid) + itemObj.bannerpath,
            "ToppingCategory": ToppingCategory
        }

        var storeObj = StoreClass(itemObj), itemobj = ItemClass(itemObj);
        // StoreObjectInStorage(Constants.Obj_PLAZA, plazaObj);
        StoreObjectInStorage(Constants.Obj_STORE, storeObj);
        StoreObjectInStorage(Constants.Obj_STORE_ITEMS, itemobj);
        this.props.history.push({
            pathname: RouteConstant.AddToCart
        });
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////


    renderAllApplicationList(menu) {
        const { selected, allApplicationList } = this.state;

        let list = allApplicationList.map(function (item, index) {

            let MenuItem = ({ text, selected }) => {
                return <div className={`menu-item ${selected ? 'active' : ''}`} >
                    <div style={{ width: "300px", borderRadius: '10px', height: "150px", backgroundImage: "url('" + text.AppBannerImage + "')", backgroundSize: "cover" }}>
                        <div style={{ float: "right", marginTop: "60px", marginRight: "5px", color: Color.WHITE }} >
                            <i class="fa fa-angle-double-right" style={{ fontSize: "10px" }}></i>
                        </div>
                        {(text.IsStoreOpen && (text.IsDeliveryEnable || text.IsPickupEnable)) ? <div style={{ borderTopLeftRadius: '10px', borderBottomRightRadius: '7px', position: 'absolute', backgroundColor: Color.BLACK, width: 'fit-content', height: '25px' }}>
                            <i class="fa fa-circle" style={{ fontSize: "10px", color: Color.LIGHT_GREEN, marginLeft: '2px' }}></i><label style={{ marginLeft: '2px', marginRight: '5px', fontSize: '13px', color: Color.WHITE, fontWeight: 'bold' }}>Open</label>
                        </div> : <div style={{ borderTopLeftRadius: '10px', position: 'absolute', backgroundColor: Color.BLACK, width: 'fit-content', height: '25px', borderBottomRightRadius: '7px' }}>
                            <i class="fa fa-circle" style={{ fontSize: "10px", color: Color.LIGHT_RED, marginLeft: '2px' }}></i><label style={{ marginLeft: '2px', marginRight: '5px', fontSize: '13px', color: Color.WHITE, fontWeight: 'bold' }}>Closed</label>
                        </div>}

                        <div style={{ float: "right", marginRight: "0px", marginTop: "5px", height: "30px", width: "30px", color: Color.WHITE, textAlign: "center", backgroundColor: Color.THEME_COLOR, borderRadius: "50px" }}>
                            {/* <div className="tooltip"> */}
                            <OnHoverIconInfo
                                title={<i class="fa fa-shopping-cart" style={{ fontSize: "15px", marginTop: "7px" }}></i>}
                                message={PlazaStoreForm.CartServicesAvailable} />
                            {/* <span className="tooltiptext settooltip">Message will soon</span>
                            </div> */}
                        </div>

                        <div style={{ float: "right", marginRight: "5px", marginTop: "5px", height: "30px", width: "30px", color: Color.WHITE, textAlign: "center", backgroundColor: Color.THEME_COLOR, borderRadius: "50px" }} onClick={(e) => this.handelFavorite(e, text, true)}>

                            <OnHoverIconInfo
                                title={<i class="fas fa-star" style={{ fontSize: "15px", marginTop: "7px" }} ></i>}
                                message={PlazaStoreForm.AddToFavorites}
                            />
                        </div>

                        <div className={text.IsDeliveryEnable ? "enableIconbg" : "disableIconbg"}
                            style={{
                                float: "right",
                                marginRight: "5px",
                                marginTop: "5px", height: "30px", width: "30px",
                                textAlign: "center", borderRadius: "50%"
                            }}>

                            <OnHoverIconInfo
                                title={<img src={text.IsDeliveryEnable ? shipping : shippingDis} style={{ width: '22px', height: '22px', marginTop: "3px" }} />}
                                message={text.IsDeliveryEnable ? PlazaStoreForm.DeliveryServicesAvailable : PlazaStoreForm.DeliveryServicesNotAvailable}
                            />
                        </div>

                        <div className={text.IsPickupEnable ? "enableIconbg" : "disableIconbg"}
                            style={{
                                float: "right",
                                marginRight: "5px",
                                marginTop: "5px", height: "30px", width: "30px",
                                textAlign: "center", borderRadius: "50%"
                            }}>
                            <OnHoverIconInfo
                                title={<img src={text.IsPickupEnable ? truck : truckDis} style={{ width: '22px', height: '22px', marginTop: "3px" }} />}
                                message={text.IsPickupEnable ? PlazaStoreForm.PickupServiceAvailable : PlazaStoreForm.PickupServiceNotAvailable}
                            />
                        </div>

                        <div style={{ float: "right", marginRight: "5px", marginTop: "5px", height: "30px", width: "30px", color: Color.WHITE, textAlign: "center", backgroundColor: Color.THEME_COLOR, borderRadius: "50px" }} onClick={(e) => this.handelAppointment(e, text)}>
                            <OnHoverIconInfo
                                title={<i class="fas fa-calendar setaTag" style={{ fontSize: "18px", marginTop: "5px" }}></i>}
                                message={PlazaStoreForm.bookappointment}
                            />
                        </div>

                        <div className="overlay" style={{ marginBottom: '0px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                            <div style={{ width: "15%", height: "inherit", left: "0%", position: "absolute" }}>
                                <img src={text.AppLogo ? text.AppLogo : NoPriview} style={{ width: "35px", height: "35px", margin: "5px", borderRadius: "5px" }} onError={(e) => (e.target.onerror = null, e.target.src = NoPriview)} />
                            </div>
                            <div style={{ width: "85%", height: "inherit", position: "absolute", right: "0%" }} >
                                <div className="row" style={{ marginLeft: "0px", height: "20%" }}>
                                    <label style={{ fontSize: "12px", color: Color.WHITE, fontWeight: 'bold' }}>{text.ApplicationName}</label>
                                </div>
                                <div className="row" style={{ marginLeft: "0px", width: "100%", height: "70%", marginTop: "6px" }}>
                                    <text style={{ fontSize: "11px", width: "inherit", height: "100%", color: Color.WHITE, maxLines: "2px", textOverflow: "ellipsis", overflow: "hidden", }}>
                                        {text.AppAddress}
                                    </text>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>;
            };

            let Menu = (list, selected) =>
                list.map(el => {
                    const { ObjectId } = el;
                    let obj = JSON.stringify(el);
                    return <MenuItem text={el} key={obj} selected={selected} />;
                });

            this.menuItems = Menu(item.ApplicationList, selected);
            const menu = this.menuItems;
            return (
                <div className="reg_form_main" style={{ marginTop: "5px" }}>
                    <p className="col-sm-12" for="StreetAddress" style={{ fontWeight: 'bold', marginTop: '5px', marginBottom: '5px' }}>
                        {item.SubCategoryName}
                    </p>
                    <div className="col-sm-12" style={{ backgroundColor: Color.WHITE }}  >
                        <ScrollMenu
                            data={menu}
                            selected={selected}
                            onSelect={this.onSelect}
                            alignCenter={false} />
                    </div>
                </div>
            )
        }, this);

        return (
            <div>
                {list}
            </div>
        );
    }

    render() {
        const { selected, suggestedStoreList, favoriteList, productSearchKey, countryInfo } = this.state;

        let MenuItem = ({ text, selected }) => {
            return <div className={`menu-item ${selected ? 'active' : ''}`} >
                <div style={{ width: "300px", height: "150px", borderRadius: '10px', backgroundImage: "url('" + text.AppBannerImage + "')", backgroundSize: "cover" }}>
                    <div style={{ float: "right", marginTop: "60px", marginRight: "5px", color: Color.WHITE }} >
                        <i class="fa fa-angle-double-right" style={{ fontSize: "17px" }}></i>
                    </div>

                    {(text.IsStoreOpen && (text.IsDeliveryEnable || text.IsPickupEnable)) ? <div style={{ borderTopLeftRadius: '10px', borderBottomRightRadius: '7px', position: 'absolute', backgroundColor: Color.BLACK, width: 'fit-content', height: '25px' }}>
                        <i class="fa fa-circle" style={{ fontSize: "10px", color: Color.LIGHT_GREEN, marginLeft: '2px' }}></i><label style={{ marginLeft: '2px', marginRight: '5px', fontSize: '13px', color: Color.WHITE, fontWeight: 'bold' }}>Open</label>
                    </div> : <div style={{ borderTopLeftRadius: '10px', position: 'absolute', backgroundColor: Color.BLACK, width: 'fit-content', height: '25px', borderBottomRightRadius: '7px' }}>
                        <i class="fa fa-circle" style={{ fontSize: "10px", color: Color.LIGHT_RED, marginLeft: '2px' }}></i><label style={{ marginLeft: '2px', marginRight: '5px', fontSize: '13px', color: Color.WHITE, fontWeight: 'bold' }}>Closed</label>
                    </div>}

                    <div style={{ float: "right", marginRight: "0px", marginTop: "5px", height: "30px", width: "30px", color: Color.WHITE, textAlign: "center", backgroundColor: Color.THEME_COLOR, borderRadius: "50px" }}>
                        {/* <div className="tooltip"> */}
                        <OnHoverIconInfo
                            title={<i class="fa fa-shopping-cart" style={{ fontSize: "15px", marginTop: "7px" }}></i>}
                            message={PlazaStoreForm.CartServicesAvailable} />
                        {/* <span className="tooltiptext settooltip">Message will soon</span>
                        </div> */}
                    </div>

                    <div style={{ float: "right", marginRight: "5px", marginTop: "5px", height: "30px", width: "30px", color: Color.WHITE, textAlign: "center", backgroundColor: Color.THEME_COLOR, borderRadius: "50px" }} onClick={(e) => this.handelFavorite(e, text, false)}>

                        <OnHoverIconInfo
                            title={<i class="fas fa-star" style={{ fontSize: "15px", marginTop: "7px", color: Color.YELLOW }} ></i>}
                            message={PlazaStoreForm.RemoveFromFavorites}
                        />
                    </div>

                    <div className={text.IsDeliveryEnable ? "enableIconbg" : "disableIconbg"}
                        style={{
                            float: "right",
                            marginRight: "5px",
                            marginTop: "5px", height: "30px", width: "30px",
                            textAlign: "center", borderRadius: "50%"
                        }}>

                        <OnHoverIconInfo
                            title={<img src={text.IsDeliveryEnable ? shipping : shippingDis} style={{ width: '22px', height: '22px', marginTop: "3px" }} />}
                            message={text.IsDeliveryEnable ? PlazaStoreForm.DeliveryServicesAvailable : PlazaStoreForm.DeliveryServicesNotAvailable}
                        />
                    </div>

                    <div className={text.IsPickupEnable ? "enableIconbg" : "disableIconbg"}
                        style={{
                            float: "right",
                            marginRight: "5px",
                            marginTop: "5px", height: "30px", width: "30px",
                            textAlign: "center", borderRadius: "50%"
                        }}>
                        <OnHoverIconInfo
                            title={<img src={text.IsPickupEnable ? truck : truckDis} style={{ width: '22px', height: '22px', marginTop: "3px" }} />}
                            message={text.IsPickupEnable ? PlazaStoreForm.PickupServiceAvailable : PlazaStoreForm.PickupServiceNotAvailable}
                        />
                    </div>

                    <div style={{ float: "right", marginRight: "5px", marginTop: "5px", height: "30px", width: "30px", color: Color.WHITE, textAlign: "center", backgroundColor: Color.THEME_COLOR, borderRadius: "50px" }} onClick={(e) => this.handelAppointment(e, text)}>

                        <OnHoverIconInfo
                            title={<i class="fas fa-calendar setaTag" style={{ fontSize: "18px", marginTop: "5px" }}></i>}
                            message={PlazaStoreForm.bookappointment}
                        />
                    </div>

                    <div className="overlay" style={{ marginBottom: '0px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                        <div style={{ width: "15%", height: "inherit", left: "0%", position: "absolute" }}>
                            <img src={text.AppLogo ? text.AppLogo : NoPriview} style={{ width: "35px", height: "35px", margin: "5px", borderRadius: "5px" }} onError={(e) => (e.target.onerror = null, e.target.src = NoPriview)} />
                        </div>
                        <div style={{ width: "85%", height: "inherit", position: "absolute", right: "0%" }} >
                            <div className="row" style={{ marginLeft: "0px", height: "20%" }}>
                                <label style={{ fontSize: "12px", color: Color.WHITE, fontWeight: 'bold' }}>{text.ApplicationName}</label>
                            </div>
                            <div className="row" style={{ marginLeft: "0px", width: "100%", height: "70%", marginTop: "6px" }}>
                                <text style={{ fontSize: "11px", width: "inherit", height: "100%", color: Color.WHITE, maxLines: "2px", textOverflow: "ellipsis", overflow: "hidden", }}>
                                    {text.AppAddress}
                                </text>
                            </div>
                        </div>
                    </div>
                </div>
            </div>;
        };

        let Menu = (list, selected) =>
            list.map(el => {
                const { ObjectId } = el;
                let obj = JSON.stringify(el);
                return <MenuItem text={el} key={obj} selected={selected} />;
            });

        this.menuItems = Menu(favoriteList, selected);
        let menu = this.menuItems;

        // changes for Brewers 9-may-2023
        var currencySymbole = ""
        var countryFees = countryInfo
        if (countryFees) {
            currencySymbole = (countryFees[currencyInfo.CountryCode] ?
                "(" + countryFees[currencyInfo.CountryCode] + ") " : "") + countryFees.Symbol

        }
        //


        return (
            <div>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Constants.LoadingMssg}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="">
                    <Header setHeaderTitle={Constants.Title_StoreList} />
                    <section className="section">
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                            <div className="col-sm-12">
                                <center><h5>{this.state.pageTitle}</h5></center>
                            </div>
                            <div className="search-bar-box">
                                <input type="text"
                                    className="search-input searchinputp m-0"
                                    placeholder="Search for Product"
                                    onChange={this.handelChange}
                                    onKeyPress={this.handleKeyPress} />
                                <i class="fa fa-search search-icon1" aria-hidden="true" onClick={this.searchItem}></i>
                            </div>

                            <div className="col-md-12 errorMsg">
                                {this.state.error.searchProducterr}
                            </div>

                            {productSearchKey
                                ?
                                (this.state.ProductSearchList.length > 0 ? <div className="reg_form_main">
                                    {this.state.ProductSearchList.map((plazaObj, plazaInd) => {

                                        let plazaName = "", plazaImage = "", plazaAddress = "";
                                        let storeName = "", storeImage = "", storeAddress = "", IsDeliveryEnable = false, IsPickupEnable = false, isStoreFavorite = false, plazaID = '', stroreID = '';

                                        if (plazaObj.items) {
                                            for (var obj of plazaObj.items.slice(0, 1)) {
                                                plazaName = obj.plazaname
                                                storeName = obj.storename
                                                storeImage = ("" + obj.bannerImagepath).replace("##APPID##", obj.applicationguid) + obj.bannerpath
                                                storeAddress = obj.StreetAddress
                                                IsDeliveryEnable = obj.IsDeliveryEnable
                                                IsPickupEnable = obj.IsPickupEnable
                                                isStoreFavorite = this.state.favoriteStoreIds.some(e => e.storeid == obj.storeid);
                                                plazaID = obj.plazaid;
                                                stroreID = obj.storeid;
                                            }
                                        }

                                        return <>
                                            <ul class="list-unstyled plaza-title-box" key={plazaInd}>
                                                <li class="media">
                                                    {/* <div class="media-body">
                                                        <h5 class="mt-0 mb-1">{plazaName}</h5>
                                                    </div> */}
                                                </li>
                                            </ul>
                                            <ul class="list-unstyled store-title-box" key={plazaInd}>
                                                <li class="media">
                                                    <img src={storeImage ? storeImage : NoPriview} style={{ borderRadius: '10px' }} class="p-2 plaza-logo" alt="..." />
                                                    <div class="media-body storedetail-container d-md-flex d-sm-block d-block mb-sm-1 mb-1">
                                                        <div className="storedetail-box">
                                                            <div><h6 class="mt-0 mb-1 d-inline-block">{storeName}</h6>&nbsp;{(IsDeliveryEnable || IsPickupEnable) ? <div style={{ borderRadius: '10px', display: 'inline-block', backgroundColor: Color.BLACK, width: 'fit-content', height: '25px' }}>
                                                                <i class="fa fa-circle" style={{ fontSize: "10px", color: Color.LIGHT_GREEN, marginLeft: '2px' }}></i><label style={{ marginLeft: '2px', marginRight: '5px', fontSize: '13px', color: Color.WHITE, fontWeight: 'bold' }}>Open</label>
                                                            </div> : <div style={{ borderRadius: '10px', display: 'inline-block', backgroundColor: Color.BLACK, width: 'fit-content', height: '25px' }}>
                                                                <i class="fa fa-circle" style={{ fontSize: "10px", color: Color.LIGHT_RED, marginLeft: '2px' }}></i><label style={{ marginLeft: '2px', marginRight: '5px', fontSize: '13px', color: Color.WHITE, fontWeight: 'bold' }}>Closed</label>
                                                            </div>}</div>
                                                            <p>{storeAddress}</p>
                                                        </div>
                                                        <div className="store-banner-container">

                                                            <div style={{ float: "right", marginRight: "5px", marginTop: "5px", height: "30px", width: "30px", color: Color.WHITE, textAlign: "center", backgroundColor: Color.THEME_COLOR, borderRadius: "50px" }} onClick={(e) => this.handelAppointmentSearch(e, plazaObj)}>
                                                                <OnHoverIconInfo
                                                                    title={<i class="fas fa-calendar setaTag" style={{ fontSize: "18px", marginTop: "5px" }}></i>}
                                                                    message={PlazaStoreForm.bookappointment}
                                                                />
                                                            </div>

                                                            <div className={IsPickupEnable ? "enableIconbg icon3-box" : "disableIconbg icon3-box"}>
                                                                <OnHoverIconInfo
                                                                    title={<img src={IsPickupEnable ? truck : truckDis} style={{ width: '22px', height: '22px', marginTop: "3px" }} />}
                                                                    message={IsPickupEnable ? PlazaStoreForm.PickupServiceAvailable : PlazaStoreForm.PickupServiceNotAvailable} />
                                                            </div>

                                                            <div className={IsDeliveryEnable ? "enableIconbg icon3-box" : "disableIconbg icon3-box"}>
                                                                <OnHoverIconInfo
                                                                    title={<img src={IsDeliveryEnable ? shipping : shippingDis} style={{ width: '22px', height: '22px', marginTop: "3px" }} />}
                                                                    message={IsDeliveryEnable ? PlazaStoreForm.DeliveryServicesAvailable : PlazaStoreForm.DeliveryServicesNotAvailable} />
                                                            </div>

                                                            <div className="icon2-box">
                                                                <OnHoverIconInfo
                                                                    title={<i class="fas fa-star icon2" style={{ fontSize: "15px", color: isStoreFavorite ? Color.YELLOW : Color.WHITE }}
                                                                        onClick={() => this.handelFavoriteSearch(plazaID, stroreID, !isStoreFavorite)}
                                                                    ></i>}
                                                                    message={isStoreFavorite ? PlazaListHover.RemoveFromFavorites : PlazaListHover.AddToFavorites} />
                                                            </div>

                                                            <div className="icon2-box">
                                                                <OnHoverIconInfo
                                                                    title={<i class="fa fa-shopping-cart icon2"></i>}
                                                                    message={PlazaStoreForm.CartServicesAvailable} />
                                                            </div>

                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <div className="row m-0">
                                                {plazaObj.items && plazaObj.items.map((itemObj, index) => {
                                                    return <div
                                                        class="col-lg-2 col-md-3 col-sm-6 col-6 text-center product-container"
                                                        style={{ marginBottom: "5px" }}
                                                        key={index}
                                                        onClick={(e) => this.handleItemClick(itemObj, plazaObj)}>
                                                        <img src={itemObj.ItemLogo ? itemObj.ItemLogo : NoPriview} onError={(e) => (e.target.onerror = null, e.target.src = NoPriview)} style={{ width: "120px", height: "120px", marginTop: "20px", borderRadius: '10px' }}></img>
                                                        <br />
                                                        <label className="textcolor" style={{ textOverflow: "ellipsis", overflow: "hidden", maxwidth: "100%" }}>{itemObj.ItemName}</label>
                                                        <br />
                                                        <label className="textcolor">{currencySymbole} {Number(itemObj.ItemPrice).toFixed(2)}</label>
                                                    </div>
                                                })}
                                            </div>
                                        </>
                                    })}
                                </div>
                                    :
                                    <div class="card text-center mt-2">
                                        <div class="card-body">
                                            <h6 class="card-title">No results for {this.state.productSearchKey}.</h6>
                                            <p class="card-text">Try checking your spelling or use more general terms</p>
                                        </div>
                                    </div>)
                                :
                                <React.Fragment>
                                    {favoriteList.length > 0 ?
                                        <div className="reg_form_main">
                                            <p className="col-sm-12" for="StreetAddress" style={{ fontWeight: 'bold' }}>
                                                Favorite
                                            </p>
                                            <div className="col-sm-12" style={{ backgroundColor: Color.WHITE }}>
                                                <ScrollMenu
                                                    data={menu}
                                                    selected={selected}
                                                    onSelect={this.onSelect}
                                                    alignCenter={false}
                                                />
                                            </div>
                                        </div>
                                        : null
                                    }

                                    {this.renderAllApplicationList(menu)}
                                </React.Fragment>
                            }

                            {/* <div className="col-sm-5"  style={{maxHeight:"0px"}}>                       
                            <SlidingPane
                                className="col-sm-5"
                                style={{maxHeight:"100px"}}
                                //closeIcon={<div>Some div containing custom close icon.</div>}
                                isOpen={true}
                                title="Hey, it is optional pane title.  I can be React component too."
                                from="bottom"
                                width="200px"
                                height="100px"
                                //onRequestClose={() => setState({ isPaneOpenLeft: false })}
                            >
                                <div>And I am pane content on left.</div>
                            </SlidingPane>
                        </div> */}
                        </div>
                    </section>
                </LoadingOverlay>
            </div>
        );
    }
}

export default PlazaStore;