import React, { Component } from 'react';
import "./Calendar.css"
import { DateRangeT, DatetimeFormate } from '../Config/Constants'
import Moment from 'moment'
import Color from '../Config/Color';

const startyear = 1990
const endyear = 2030
let years = []
for (var year = startyear; year <= endyear; year = year + 1) {
    years.push(year)
}

let today = new Date();
let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

class Calendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            today: today,
            currentMonth: today.getMonth(),
            currentYear: today.getFullYear(),
            selectYear: today.getFullYear(),
            selectMonth: today.getMonth()
        }
    }

    componentDidMount() {
        let { currentYear, currentMonth } = this.state
        // this.showCalendar(currentMonth, currentYear)
    }

    next() {
        let { currentYear, currentMonth } = this.state
        currentYear = (currentMonth === 11) ? currentYear + 1 : currentYear;
        currentMonth = (currentMonth + 1) % 12;
        if (1990 <= currentYear && currentYear <= 2030) {
            this.setState({
                currentMonth: currentMonth,
                currentYear: currentYear
            })
        }
        // this.showCalendar(currentMonth, currentYear);
    }

    previous() {
        let { currentYear, currentMonth } = this.state
        currentYear = (currentMonth === 0) ? currentYear - 1 : currentYear;
        currentMonth = (currentMonth === 0) ? 11 : currentMonth - 1;
        if (1990 <= currentYear && currentYear <= 2030) {
            this.setState({
                currentMonth: currentMonth,
                currentYear: currentYear
            })
        }
        // this.showCalendar(currentMonth, currentYear);
    }

    jump(e) {
        let { currentYear, currentMonth } = this.state
        currentYear = parseInt(currentYear);
        currentMonth = parseInt(e.target.value);
        this.setState({
            currentMonth: currentMonth,
            currentYear: currentYear
        })
        // this.showCalendar(currentMonth, currentYear);
    }

    jump1(e) {
        let { currentYear, currentMonth } = this.state
        currentYear = parseInt(e.target.value);
        currentMonth = parseInt(currentMonth);
        this.setState({
            currentMonth: currentMonth,
            currentYear: currentYear
        })
        // this.showCalendar(currentMonth, currentYear);
    }

    handleSelectDate(date, day, tobj) {
        const { handleSelectDate } = this.props
        if (typeof handleSelectDate == "function") {
            handleSelectDate(date, day, tobj)
        }
    }

    showCalendar(month, year) {
        try {
            let { daterangeType, appointmentobj, selectedDate } = this.props
            var startDate = "", endDate = "", dayarr = []

            if (appointmentobj) {
                if (daterangeType == DateRangeT.FUTUREDAYS) {
                    startDate = Moment(today).format(DatetimeFormate.YYYYMMDDdash)
                    endDate = Moment(new Date(today.getFullYear(), today.getMonth(), Number(today.getDate() + appointmentobj.nextcalendardays))).format(DatetimeFormate.YYYYMMDDdash)
                }
                else if (daterangeType == DateRangeT.DATERANGE) {
                    startDate = Moment.utc(appointmentobj.fromdate).format(DatetimeFormate.YYYYMMDDdash)
                    endDate = Moment.utc(appointmentobj.tomdate).format(DatetimeFormate.YYYYMMDDdash)
                }
                else if (daterangeType == DateRangeT.INDEFINITELY) {
                    startDate = Moment(today).format(DatetimeFormate.YYYYMMDDdash)
                    endDate = Moment(new Date(9999, 11, 1)).format(DatetimeFormate.YYYYMMDDdash)
                }
                dayarr = appointmentobj.weekdays ? appointmentobj.weekdays : []
            }

            let firstDay = (new Date(year, month)).getDay();
            let daysInMonth = 32 - new Date(year, month, 32).getDate();

            var todaydate = Moment(today).format(DatetimeFormate.YYYYMMDDdash)

            let date = 1;
            var tr = []
            for (let i = 0; i < 6; i++) {
                var td = []
                for (let j = 0; j < 7; j++) {
                    if (i === 0 && j < firstDay) {
                        td.push(
                            <td></td>
                        )
                    }
                    else if (date > daysInMonth) {
                        break;
                    }
                    else {
                        let currentDate = Moment(new Date(year, month, date)).format(DatetimeFormate.YYYYMMDDdash)
                        let currentDay = Moment(new Date(year, month, date)).format(DatetimeFormate.DDDD)
                        // console.log(startDate, currentDate, endDate, startDate <= currentDate && currentDate <= endDate)
                        if (startDate <= currentDate && currentDate <= endDate) {
                            let checkis = dayarr.filter(k => String(k.weekday).toUpperCase() == String(currentDay).toUpperCase() && k.isopen)[0]
                            if (checkis) {
                                if (todaydate <= currentDate && currentDate <= endDate) {
                                    td.push(
                                        <td class={"allowday setaTag " + (selectedDate == currentDate ? "selectdate" : "")}
                                            onClick={() => this.handleSelectDate(currentDate, currentDay, checkis)}>{date}</td>
                                    )
                                }
                                else {
                                    td.push(
                                        <td class="allowday">{date}</td>
                                    )
                                }
                            }
                            else {
                                td.push(
                                    <td>{date}</td>
                                )
                            }
                        }
                        else {
                            td.push(
                                <td>{date}</td>
                            )
                        }
                        date++;
                    }
                }
                tr.push(<tr>{td}</tr>)
            }

            return <tbody id="calendar-body">
                {tr}
            </tbody>
        }
        catch (err) {
            console.log("---", err)
            return <tbody id="calendar-body">
            </tbody>
        }
    }


    render() {
        const { selectYear, selectMonth, currentMonth, currentYear } = this.state
        return (
            <div class="col-sm-12 col-md-8 col-lg-6 pl-0 pr-0 pr-md-2">
                <div class="card">
                    <div class="row m-0">
                        <div class="col-8 p-0">
                            <h4 class="card-header" id="monthAndYear">{months[currentMonth]} {currentYear}</h4>
                        </div>
                        <div class="col-2 ar-icon-box" onClick={() => this.previous()}>
                            <i class="fas fa-angle-left ar-icon"></i>
                        </div>
                        <div class="col-2 ar-icon-box" onClick={() => this.next()}>
                            <i class="fas fa-angle-right ar-icon"></i>
                        </div>
                    </div>
                    <table class="table table-bordered table-responsive-sm" id="calendar">
                        <thead>
                            <tr>
                                <th>Sun</th>
                                <th>Mon</th>
                                <th>Tue</th>
                                <th>Wed</th>
                                <th>Thu</th>
                                <th>Fri</th>
                                <th>Sat</th>
                            </tr>
                        </thead>
                        {this.showCalendar(currentMonth, currentYear)}
                        {/* <tbody id="calendar-body">

                        </tbody> */}
                    </table>

                    {/* <div class="form-inline p-1">

                        <button class="btn btn-outline-primary col-sm-5" id="previous" onClick={() => this.previous()} style={{ backgroundColor: Color.THEME_COLOR }}>Previous</button>
                        <button class="btn btn-outline-primary col-sm-5 offset-sm-2 mt-1 mt-sm-0" id="next" onClick={() => this.next()} style={{ backgroundColor: Color.THEME_COLOR }}>Next</button>
                    </div>
                    <br /> */}
                    <form class="form-inline">
                        <label class="lead mr-2 ml-2" for="month">Jump To: </label>
                        <select class="form-control col-sm-4" name="month" id="month" onChange={(e) => this.jump(e)}>
                            <option value="0" selected={currentMonth == 0}>Jan</option>
                            <option value="1" selected={currentMonth == 1}>Feb</option>
                            <option value="2" selected={currentMonth == 2}>Mar</option>
                            <option value="3" selected={currentMonth == 3}>Apr</option>
                            <option value="4" selected={currentMonth == 4}>May</option>
                            <option value="5" selected={currentMonth == 5}>Jun</option>
                            <option value="6" selected={currentMonth == 6}>Jul</option>
                            <option value="7" selected={currentMonth == 7}>Aug</option>
                            <option value="8" selected={currentMonth == 8}>Sep</option>
                            <option value="9" selected={currentMonth == 9}>Oct</option>
                            <option value="10" selected={currentMonth == 10}>Nov</option>
                            <option value="11" selected={currentMonth == 11}>Dec</option>
                        </select>


                        <label for="year"></label>
                        <select class="form-control col-sm-4" name="year" id="year" onChange={(e) => this.jump1(e)}>
                            {years.map(e => {
                                return <option key={e} value={e} selected={currentYear == e}>{e}</option>
                            })}
                        </select>
                    </form>
                </div>
            </div>
        );
    }
}

export default Calendar