import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { PostData } from '../../services/PostData';
import Color from '../Config/Color';
import { CONTACT_US_API } from '../Config/Config.json';
import { CONTACT_US_VISIBLE_MODE, ContactUSConstForm, ContactUSConstFormPH } from '../Config/Constants';
import Messages from "../Config/Messages";
import { formatPhoneNumber, validateEmail, validatePhoneNumber } from '../Config/Utils';
import MainHeader from '../MainHeader/MainHeader';
import MainFooter from '../MainFooter/MainFooter';
import { ACaptcha, GETWORD } from '../UtillCustomComponent/CaptchaGenerator';
import OnHoverIconInfo, { PutMandatoryfieldSymbol } from '../UtillCustomComponent/UtillCustomComponent';

export default class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            userFields: {},
            isActive: false,
            mode: 0,
            successMsg: "",
            captchaword: "",
            captchWord: ""
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleValidationOnBlur = this.handleValidationOnBlur.bind(this);
    }

    componentDidMount() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        if (params.get(CONTACT_US_VISIBLE_MODE.MODE) != null
            && params.get(CONTACT_US_VISIBLE_MODE.MODE).length > 0
            && params.get(CONTACT_US_VISIBLE_MODE.MODE) != "") {
            if (params.get(CONTACT_US_VISIBLE_MODE.MODE) == CONTACT_US_VISIBLE_MODE.REQUEST_A_DEMO_MODE) {
                this.setState({ mode: CONTACT_US_VISIBLE_MODE.REQUEST_A_DEMO_MODE, captchaword: "" });
            }
        }
        this.setState({ captchWord: GETWORD(6) });
    }

    handleChange(event) {
        let userFields = this.state.userFields;
        let key = event.target.name;
        let value = event.target.value;
        if (key == "phone") {
            userFields[key] = formatPhoneNumber(value);
        }
        else {
            userFields[key] = value;
        }
        this.setState({ userFields });
    }

    handleValidationOnBlur(e) {
        let fields = this.state.userFields;
        let errors = this.state.errors;
        let formIsValid = true;
        let name = e.currentTarget.name;

        if (name == "name") {
            if (!fields["name"]) {
                formIsValid = false;
                errors["name"] = Messages.v_EnterFullName;
            }
        }

        if (name == "email") {
            if (!fields["email"]) {
                formIsValid = false;
                errors["email"] = Messages.v_EnterEmail;
            }
            else if (typeof fields["email"] !== "undefined") {
                if (!validateEmail(fields["email"])) {
                    formIsValid = false;
                    errors["email"] = Messages.v_EnterValidEmail;
                }
            }
        }

        if (name == "phone") {
            if (!fields["phone"]) {
                formIsValid = false;
                errors["phone"] = Messages.v_EnterPhoneNo
            }
            else if (typeof fields["phone"] !== "undefined") {
                if (!validatePhoneNumber(fields["phone"])) {
                    formIsValid = false;
                    errors["phone"] = Messages.v_EnterValidPhoneNo;
                }
            }
        }

        if (name == "comments") {
            if (!fields["comments"]) {
                formIsValid = false;
                errors["comments"] = Messages.v_EnterComments;
            }
        }

        if (formIsValid)
            errors[name] = "";

        this.setState({
            fields,
            errors
        });
    }

    validateForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;
        const { } = this.state

        if (!fields["name"]) {
            formIsValid = false;
            errors["name"] = Messages.v_EnterFullName;
        }

        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = Messages.v_EnterEmail;
        }
        else if (typeof fields["email"] !== "undefined") {
            if (!validateEmail(fields["email"])) {
                formIsValid = false;
                errors["email"] = Messages.v_EnterValidEmail;
            }
        }

        if (!fields["phone"]) {
            formIsValid = false;
            errors["phone"] = Messages.v_EnterPhoneNo
        }
        else if (typeof fields["phone"] !== "undefined") {
            if (!validatePhoneNumber(fields["phone"])) {
                formIsValid = false;
                errors["phone"] = Messages.v_EnterValidPhoneNo;
            }
        }

        if (!fields["comments"]) {
            formIsValid = false;
            errors["comments"] = Messages.v_EnterComments;
        }

        if (!fields["captchacode"]) {
            formIsValid = false;
            errors["captchacode"] = Messages.v_EnterCaptchacode;
        }
        else if (this.state.captchWord != fields["captchacode"]) {
            formIsValid = false;
            errors["captchacode"] = Messages.v_EnterValidCaptchacode;
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSubmit(e) {
        var postData = {};
        e.preventDefault();
        if (this.validateForm()) {
            const { userFields } = this.state;
            postData["name"] = userFields.name;
            postData["email"] = userFields.email;
            postData["phone"] = userFields.phone;
            postData["company"] = userFields.company;
            postData["title"] = userFields.title;
            postData["comments"] = userFields.comments;
            postData["iscontactus"] = this.state.mode === CONTACT_US_VISIBLE_MODE.REQUEST_A_DEMO_MODE ? false : true;
            console.log(">", postData);
            this.CallContactUSAPI(postData);
        }
    }

    CallContactUSAPI(postData) {
        this.setState({ isActive: true })
        var URL = CONTACT_US_API;
        PostData(URL, postData)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({
                        userFields: {
                            name: "",
                            email: "",
                            phone: "",
                            company: "",
                            title: "",
                            comments: "",
                            captchacode: ""
                        },
                        successMsg: Messages.v_ContactUS_message,
                    });
                    this.setState({ captchWord: GETWORD(6) });
                }
                else {
                    let errors = {};
                    errors["masterError"] = Messages.ERR_SomethingWentWrong;
                    this.setState({
                        errors: errors,
                        successMsg: ""
                    });
                    this.setState({ captchWord: GETWORD(6) });
                }
                this.setState({ isActive: false });
            }).catch(err => {
                let errors = {};
                errors["masterError"] = Messages.ERR_SomethingWentWrong;
                this.setState({
                    errors: errors,
                    successMsg: "",
                    isActive: false
                });
                this.setState({ captchWord: GETWORD(6) });
            });
    }

    render() {
        const { mode, successMsg, captchWord } = this.state;

        return (
            <LoadingOverlay
                active={this.state.isActive}
                text={Messages.Loading}
                spinner
                styles={{
                    overlay: (base) => ({
                        ...base,
                        position: "fixed"
                    })
                }}
                className="lodingspinner">
                <div>
                    <div class="hero_area">
                        <MainHeader />
                    </div>
                    <section className="section mb-5">
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }} >
                            <div className="reg_form_main">
                                <center><p id="instruction" className="textcolor" style={{ marginBottom: '10px' }}>{mode === CONTACT_US_VISIBLE_MODE.REQUEST_A_DEMO_MODE ? ContactUSConstForm.RequestADemo : ContactUSConstForm.ContactUS}</p> </center>
                                <center><p id="instruction" className="" style={{ marginBottom: '10px', color: Color.BLACK }}>Thank you for your interest. Please fill out the form below and we will contact you.</p> </center>
                            </div>
                            <div class="midsection pl-2 pr-2 pl-sm-0 pr-sm-0 pages">

                                <div className="reg_form_main offset-sm-3 col-sm-6">
                                    <p className="col-sm-12 textcolor" for="FirstName" style={{ fontWeight: 'bold' }}>
                                        {ContactUSConstForm.Name} <OnHoverIconInfo
                                            message={ContactUSConstForm.Name} /> <PutMandatoryfieldSymbol />
                                    </p>
                                    <input
                                        type="text"
                                        placeholder={ContactUSConstFormPH.Name}
                                        name="name"
                                        className="float-none"
                                        value={this.state.userFields.name}
                                        onChange={this.handleChange}
                                        onBlur={this.handleValidationOnBlur} />
                                    <div className="col-sm-12 errorMsg mb-0">
                                        {this.state.errors.name}
                                    </div>
                                </div>

                                <div className="reg_form_main offset-sm-3 col-sm-6">
                                    <p className="col-sm-12 textcolor" for="FirstName" style={{ fontWeight: 'bold' }}>
                                        {ContactUSConstForm.Title} <OnHoverIconInfo
                                            message={ContactUSConstForm.Title} />
                                    </p>
                                    <input
                                        type="text"
                                        placeholder={ContactUSConstFormPH.Title}
                                        name="title"
                                        className="float-none"
                                        value={this.state.userFields.title}
                                        onChange={this.handleChange}
                                        onBlur={this.handleValidationOnBlur} />
                                    <div className="col-sm-12 errorMsg mb-0">
                                        {this.state.errors.title}
                                    </div>
                                </div>

                                <div className="reg_form_main offset-sm-3 col-sm-6">
                                    <p className="col-sm-12 textcolor" style={{ color: Color.THEME_COLOR, fontWeight: 'bold' }} for="Email">
                                        {ContactUSConstForm.Company} <OnHoverIconInfo
                                            message={ContactUSConstForm.Company} />
                                    </p>
                                    <input
                                        type="text"
                                        placeholder={ContactUSConstFormPH.Company}
                                        name="company"
                                        className="float-none"
                                        value={this.state.userFields.company}
                                        onChange={this.handleChange}
                                        onBlur={this.handleValidationOnBlur} />
                                    <div className="col-sm-12 errorMsg mb-0">
                                        {this.state.errors.company}
                                    </div>
                                </div>

                                <div className="reg_form_main offset-sm-3 col-sm-6">
                                    <p className="col-sm-12 textcolor" for="LastName" style={{ fontWeight: 'bold' }}>
                                        {ContactUSConstForm.Email} <OnHoverIconInfo
                                            message={ContactUSConstForm.Email} /> <PutMandatoryfieldSymbol />
                                    </p>
                                    <input
                                        type="text"
                                        placeholder={ContactUSConstFormPH.Email}
                                        name="email"
                                        className="float-none"
                                        value={this.state.userFields.email}
                                        onChange={this.handleChange}
                                        onBlur={this.handleValidationOnBlur} />
                                    <div className="col-sm-12 errorMsg mb-0">
                                        {this.state.errors.email}
                                    </div>
                                </div>

                                <div className="reg_form_main offset-sm-3 col-sm-6">
                                    <p className="col-sm-12 textcolor" style={{ color: Color.THEME_COLOR, fontWeight: 'bold' }} for="Email">
                                        {ContactUSConstForm.PhoneNumber} <OnHoverIconInfo
                                            message={ContactUSConstForm.PhoneNumber} />
                                    </p>
                                    <input
                                        type="text"
                                        placeholder={ContactUSConstFormPH.PhoneNumber}
                                        name="phone"
                                        className="float-none"
                                        value={this.state.userFields.phone}
                                        maxLength="12"
                                        onChange={this.handleChange}
                                        onBlur={this.handleValidationOnBlur} />
                                    <div className="col-sm-12 errorMsg mb-0">
                                        {this.state.errors.phone}
                                    </div>
                                </div>

                                <div className="reg_form_main offset-sm-3 col-sm-6">
                                    <p className="col-sm-12 textcolor" style={{ color: Color.THEME_COLOR, fontWeight: 'bold' }} for="Email">
                                        {ContactUSConstForm.Comments} <OnHoverIconInfo
                                            message={ContactUSConstForm.Comments} /> <PutMandatoryfieldSymbol />
                                    </p>
                                    <textarea
                                        style={{ borderRadius: '10px' }}
                                        type="text"
                                        name="comments"
                                        className='add-item-textarea float-none'
                                        placeholder={ContactUSConstForm.Comments}
                                        value={this.state.userFields.comments}
                                        onChange={this.handleChange} />
                                    <div className="col-sm-12 errorMsg mb-0">
                                        {this.state.errors.comments}
                                    </div>
                                </div>

                                <div className="reg_form_main offset-sm-3 col-sm-6">
                                    <p className="col-sm-12 textcolor" style={{ color: Color.THEME_COLOR, fontWeight: 'bold' }} for="Email">
                                        {ContactUSConstForm.CAPTCHAcode}  <OnHoverIconInfo
                                            message={ContactUSConstForm.CAPTCHAcode} /><PutMandatoryfieldSymbol />
                                    </p>
                                    <input
                                        type="text"
                                        placeholder={ContactUSConstFormPH.CAPTCHAcode}
                                        name="captchacode"
                                        className="float-none"
                                        value={this.state.userFields.captchacode}
                                        maxLength="12"
                                        onChange={this.handleChange} />
                                    <div className="col-sm-12 errorMsg mb-0">
                                        {this.state.errors.captchacode}
                                    </div>
                                    <center>
                                        <div class="mt-2">
                                            <ACaptcha
                                                font={'30px Arial'}
                                                align={'center'}
                                                baseline={'middle'}
                                                width={200}
                                                height={50}
                                                bgColor={Color.THEME_COLOR}
                                                color={Color.WHITE}
                                                captchstr={captchWord} />
                                        </div>
                                    </center>
                                </div>

                                <div className="reg_form_main">
                                    <div className="bottam_sing" >
                                        <button
                                            style={{ borderRadius: "4px", backgroundColor: Color.THEME_COLOR, marginBottom: '10px' }}
                                            type="button"
                                            onClick={this.handleSubmit}
                                            className="btn btn-done"
                                        > {ContactUSConstForm.btn_Submit}</button>
                                    </div>
                                </div>

                                <div className="col-sm-12 errorMsg mb-0 mt-2">
                                    {this.state.errors.masterError}
                                </div>

                                <div className="reg_form_main offset-sm-3 col-sm-6">
                                    {successMsg && <div class="alert alert-success alert-dismissible" role="alert">
                                        <button type="button" class="close" data-dismiss="alert" onClick={() => { this.setState({ successMsg: "" }) }} >&times;</button>
                                        {successMsg}
                                    </div>}
                                </div>

                            </div>
                        </div>
                    </section>
                    <MainFooter />
                </div>
            </LoadingOverlay>
        );
    }
}