import React, { Component } from 'react';
import './PlazaOrderWebStoreItems';
import { withRouter } from 'react-router';
import Color from '../../Config/Color';
import Collapsible from 'react-collapsible';
import ItemListOrderWeb from './ItemListOrderWeb';

class CategoryListOrderWeb extends Component {
    constructor(props) {
        super(props);
        this.state = {
            storeData: props.storeData,
            tab: 0
        }
        this.handleItemClick = this.handleItemClick.bind(this);
    }

    componentDidMount() {
    }

    handleItemClick(data) {
        document.getElementById(data.CategoryID).classList.remove('fa fa-caret-down');
        document.getElementById(data.CategoryID).classList.add('fa fa-caret-up');
    }

    handleSetInd = (t) => {
        this.setState({ tab: t })
    }

    render() {
        let list = this.props.data
            .map((item, index) => {
                return (
                    <React.Fragment >
                        <Collapsible
                            open={index == 0}
                            // open={this.state.tab == index}
                            // accordionPosition={index}
                            // handleTriggerClick={this.handleSetInd}
                            trigger={<div className="col-sm-12" style={{ margin: "0px", backgroundColor: Color.THEME_COLOR, color: Color.WHITE }} /* onClick={(value) => this.handleItemClick(item)} */>
                                <div className="row" >
                                    <label style={{ fontSize: "15px", color: Color.WHITE, padding: "5px", marginBottom: '0px' }}> {item.Category_name} </label>
                                    <span style={{ marginLeft: "auto", padding: "5px" }}>
                                        <label style={{ fontSize: "15px", marginRight: "5px", marginBottom: '0px' }}>Price</label>
                                        <i id={item.CategoryId} class="fa fa-caret-down" style={{ fontSize: "20px", padding: "2px", color: Color.WHITE, float: "right", marginRight: "5px" }}></i>
                                    </span>
                                </div>
                            </div>}>

                            <ItemListOrderWeb storeData={this.state.storeData} categoryID={item.CategoryId} data={item.Items}></ItemListOrderWeb>
                        </Collapsible>
                    </React.Fragment>
                )
            }, this);

        return (
            <div>
                {list}
            </div>
        );
    }
}

export default withRouter(CategoryListOrderWeb);