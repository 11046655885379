import React, { Component } from 'react';
import './SideMenu.css';
import WheelPhoto from "../../images/gear-wheel.png";
import Color from '../Config/Color';
import Messages from "../Config/Messages";
import Header from '../Header/Header';
import { NavLink, withRouter } from 'react-router-dom';
import emailread from "../../images/icons/read.png";
import Constants from '../Config/Constants';
import { GetData } from "../../services/GetData";
import { GET_NOTIFICATION_API } from '../Config/Config.json';
import { RetriveDataFromStorage, StoreObjectInStorage, RetriveObjectFromStorage } from "../Config/Utils";
import Moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import MasterContextCreater from '../MasterContextProvider/MasterContextCreater';

class NotificationDetails extends Component {
    static contextType = MasterContextCreater;
    constructor(props) {
        super(props);
        this.state = {
            userFields: {},
            errors: {},
            isActive: false

        }
        this.goBack = this.goBack.bind(this);
    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        let search = window.location.search;
        let params = new URLSearchParams(search);
        if (params.get('id') !== null && (params.get('id').length > 0)) {
            var id = params.get('id');
            this.getNotificationAPI(authToken, id)
        }
    }

    getNotificationAPI(authToken, id) {
        this.setState({ isActive: true });
        var API = GET_NOTIFICATION_API + "/" + id;
        GetData(API, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        this.setState({ userFields: responseData });
                        const { reffotificationList } = this.context
                        if (typeof reffotificationList == "function") {
                            reffotificationList()
                        }
                    }
                }
                else {
                    // alert(Messages.NO_PLAZA);
                }
                this.setState({ isActive: false });
            });
    }

    goBack() {
        this.props.history.goBack();
    }

    handleRefresh() {
        this.componentDidMount()
    }

    render() {

        return (
            <div>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Constants.LoadingMssg}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="" >
                    <Header setHeaderTitle={Constants.Title_Notification} onrefresh={() => this.handleRefresh()} />
                    <section className="section">
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                            <div className="reg_form_main offset-lg-3 col-lg-6">
                                <div className="col-sm-12" style={{ backgroundColor: Color.WHITE }}>
                                    <div className="row">
                                        <div style={{ width: "20%", paddingLeft: "2px", fontSize: "15px" }}>
                                            Title:
                                        </div>
                                        <div >
                                            <span style={{ fontSize: "15px", color: Color.THEME_COLOR }}>{this.state.userFields.NotificationSubject}</span><br />
                                            {/* <span style={{ fontSize: "15px", color: Color.THEME_COLOR }}>OD602ba91521a58f1df0a0ab5c</span> */}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div style={{ width: "20%", paddingLeft: "2px", fontSize: "15px" }}>
                                            Message:
                                        </div>
                                        <div style={{ width: "80%" }}>
                                            <span style={{ fontSize: "15px", }}><div dangerouslySetInnerHTML={{ __html: this.state.userFields.NotificationMessage }} ></div></span><br />
                                        </div>
                                        <span style={{ marginLeft: "auto", }}>
                                            <label style={{ fontSize: "13px", marginRight: "10px" }}> {this.state.userFields.CreatedDate ? Moment(this.state.userFields.CreatedDate).format("DD MMM YYYY h:mm a") : ""}</label>
                                            <img src={emailread} style={{ height: "20px", width: "20px", float: "right", marginRight: "5px" }}></img>
                                            {/* <i class="fa fa-envelope-open" style={{ fontSize: "20px", float: "right", color: Color.GREY, marginRight: "5px", width: "20px" }}></i> */}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </LoadingOverlay>
            </div>
        );
    }
}

export default withRouter(NotificationDetails);