import React, { Component } from 'react';
import './Checkout.css';
import Color from '../Config/Color';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';

import { RadioGroup, Radio } from 'react-radio-group'
import RouteConstant from '../Config/RouteConstant';
import { StoreObjectInStorage } from '../Config/Utils';
import Constants from '../Config/Constants';

class AddressList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedAddress: {},
            checkboxId: 0
        }
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleAddNewAddress = this.handleAddNewAddress.bind(this);
    }

    componentDidMount() {
    }

    handleEdit(data) {
        console.log("item Edit")
        console.log(JSON.stringify(data))
        StoreObjectInStorage(Constants.ADDRESS_OBJ, data);
        this.props.history.push({
            pathname: RouteConstant.AddAddress,
        })
        //window.location.reload(true);
    }

    handleAddNewAddress() {
        StoreObjectInStorage(Constants.ADDRESS_OBJ, "");
        this.props.history.push({
            pathname: RouteConstant.AddAddress,
        })
    }

    handleDelete(data) {
        console.log("item Delete")
        console.log(JSON.stringify(data))
    }

    handleChangeRadioBtn(addressObj, index) {
        this.setState({ selectedAddress: addressObj, checkboxId: index })
    }
    chooseAddress() {
        var objectlength = Object.keys(this.state.checkboxId).length;
        this.props.updateAddressObj(this.state.selectedAddress)
    }

    render() {
        let list = this.props.data
            .map(function (item, index) {
                return (
                    <React.Fragment key={item.OrderId}>
                        <div className="col-sm-12" style={{ backgroundColor: Color.WHITE, paddingBottom: "3px", borderBottom: "1px solid" + Color.GREY }} >
                            <div className="row" style={{ fontSize: "15px", marginLeft: "10px" }}>
                                <div className="col-sm-12">
                                    <div className="row" style={{ paddingTop: "2px" }}>
                                        <RadioGroup name="deliveryoption" selectedValue={this.state.selectedValue} onClick={() => this.handleChangeRadioBtn(item, index)}>
                                            <Radio value="Curbside Pickup" style={{ color: Color.BLACK }} checked={this.state.checkboxId == index} />
                                        </RadioGroup> &nbsp;&nbsp;
                                        {/* <input type="radio" name="deliveryoption" style={{ color: Color.BLACK }} onClick={() => this.handleChangeRadioBtn(item)} />   &nbsp;&nbsp; */}
                                        {item.ApartmentNumber}<br />
                                        {item.StreetAddress}<br />
                                        {item.PostalCode}
                                        <span style={{ marginLeft: "auto" }}>
                                            <i class="fas fa-pen-square" style={{ fontSize: "20px", color: Color.GREY, marginRight: "10px", width: "20px" }} onClick={(value) => this.handleEdit(item)}></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment >
                )
            }, this);

        return (
            <div>
                {list}

                {/* <NavLink to={RouteConstant.AddAddress}><a style={{marginLeft:"auto", color:"blue", float: "right", marginTop:"10px", borderRadius: "50px", marginBottom: "10px"}}> Add new Address</a></NavLink> */}
                <a onClick={this.handleAddNewAddress} style={{ marginLeft: "auto", color: Color.BLUE, float: "right", marginTop: "10px", borderRadius: "50px", marginBottom: "10px" }}> Add new Address</a>


                {this.props.data.length > 0 ? <label
                    style={{ float: "left", marginTop: "10px", borderRadius: "50px", marginBottom: "10px" }}
                    for="step5"
                    id="continue-step5"
                    onClick={() => this.chooseAddress()}
                    className="btn btn-done"
                > Choose Address</label> : ""}
            </div>
        );
    }
}

export default withRouter(AddressList);