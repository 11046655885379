import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './SelectCountry.css';
import Messages from "../Config/Messages";
import { RemoveDataInStorage, StoreObjectInStorage, removeAllSpace, checkPostalCode, logoutSession } from "../Config/Utils";
import Constants, { sessionclear } from '../Config/Constants'
import Header from "../Header/Header";
import Color from '../Config/Color';
import ReactFlagsSelect from 'react-flags-select'; // https://www.npmjs.com/package/react-flags-select
import RouteConstant from '../Config/RouteConstant';
import { GetDataGooglePlace } from '../../services/GetDataGooglePlace';
import plazacartLogo from "../../images/logo.png";

import { GetData } from "../../services/GetData";
import { GET_COUNTRY_API, GOOGLE_PLACE_KEY, KEY_STRING, PLACE_ID } from '../Config/Config.json'
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { LandingPageConst } from "../Config/constvariable";
import { LandingPagePH } from '../Config/Placeholder';
import { PutMandatoryfieldSymbol } from '../UtillCustomComponent/UtillCustomComponent';
import MainHeader from '../MainHeader/MainHeader';
import MainFooter from '../MainFooter/MainFooter';

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#f0f0f0', border: "none", borderRadius: "50px", height: "50px", marginBottom: "15px" }),
    placeholder: styles => ({ ...styles, fontSize: "16px", fontWeight: "450 !important", marginLeft: "8px" }),
    option: styles => ({ ...styles, color: '#333' }),
    menuPortal: styles => ({ ...styles, zIndex: "99" })
};

export default class SelectCountry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userFields: {},
            errors: {},
            selectedCountry: "",
            countryFields: {}, //select country fields
            countryData: [],  //array of country list bind from API -> country fields        

            selectedAddress: null,
            selectedAddressDetail: null
        }

        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.onSelectFlag = this.onSelectFlag.bind(this);
        this.handleSignIN = this.handleSignIN.bind(this);
        this.handleSignUP = this.handleSignUP.bind(this);
    }

    componentDidMount() {
        logoutSession()
        RemoveDataInStorage(Constants.AUTH_TOKEN, "");
        sessionclear()
        this.getCountryAPI();
    }

    getCountryAPI() {
        console.log("country list1 ",);
        GetData(GET_COUNTRY_API)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("country list ", responseData);
                    var countryCode = [];
                    for (var i = 0; i < responseData.length; i++) {
                        var obj = responseData[i].CountryCode;
                        countryCode.push(obj);
                    }
                    this.setState({ countryData: countryCode });
                    console.log("countryData ", this.state.countryData);
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong);
                }
            });
    }

    handleCountryChange(event) {
        let countryFields = this.state.countryFields;
        let key = event.target.name
        let value = event.target.value
        countryFields[key] = value
        this.setState({ countryFields })
    }

    onSelectFlag(countryCode) {
        console.log(countryCode)
        let countryFields = this.state.countryFields;
        if (countryFields["CountryCode"] != countryCode) {
            countryFields["PostalCode"] = ""
            this.setState({ selectedAddress: null })
        }
        countryFields["CountryCode"] = countryCode
        this.setState({ countryFields })
        this.setState({ selectedCountry: countryCode })
        var errors = this.state.errors.CountryCode;
        this.setState({ errors: "" });
    }

    handelAddress(addressObj) {
        this.setState({ selectedAddress: addressObj })
        console.log(addressObj)
        let countryFields = this.state.countryFields;
        if (addressObj && addressObj.value && addressObj.value.place_id) {
            this.getaddressGooglePlace("", addressObj.value.place_id, countryFields)
        }
        else {
            countryFields["PostalCode"] = "";
        }
        this.setState({ countryFields, selectedAddressDetail: null })
    }

    getaddressGooglePlace(authToken, value, refObj) {
        this.setState({ isActive: true })
        var placeId = value
        console.log(placeId)
        geocodeByPlaceId(placeId)
            .then(result => {
                var responseJson = result ? result[0] : null;
                console.log("====", result)
                if (responseJson) {
                    var responseData = responseJson;
                    this.setState({ selectedAddressDetail: responseData ? responseData : null })
                    if (responseData && responseData.address_components) {
                        refObj["PostalCode"] = ""
                        for (var obj of responseData.address_components) {
                            if (obj.types && checkPostalCode(obj.types)) {
                                refObj["PostalCode"] = removeAllSpace(obj.short_name)
                                break;
                            }
                        }
                    }
                    else {
                        refObj["PostalCode"] = ""
                    }
                }
                else {
                    refObj["PostalCode"] = ""

                }
                this.setState({ refObj, isActive: false })
            }).catch(err => {
                console.log(err)
                refObj["PostalCode"] = ""
                this.setState({ refObj, isActive: false })
            });
    }

    validateCountryForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.countryFields;

        if (!fields["CountryCode"]) {
            formIsValid = false;
            errors["CountryCode"] = Messages.v_EnterCountryName;
        }

        if (!this.state.selectedAddress) {
            formIsValid = false;
            errors["Address"] = Messages.v_Address;
        }

        if (!fields["PostalCode"]) {
            formIsValid = false;
            errors["PostalCode"] = Messages.v_EnterPostalCode;
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSignUP(e) {
        e.preventDefault();
        if (this.validateCountryForm()) {
            var countryData = {
                "countryCode": this.state.countryFields.CountryCode,
                "postalCode": this.state.countryFields.PostalCode,
                "addressDetail": this.state.selectedAddressDetail ? this.state.selectedAddressDetail : ""
            }

            StoreObjectInStorage(Constants.Obj_COUNTRY, countryData)

            this.props.history.push({
                pathname: RouteConstant.Register
            })
        }
    }

    handleSignIN() {
        this.props.history.push({
            pathname: RouteConstant.Login
        });
    }

    render() {
        return (
            <div>
                <div class="hero_area">
                    <MainHeader />
                </div>
                <section className="section">
                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>

                        {/* <div className="reg_form_main">
                            <center><img src={plazacartLogo} className="col-sm-4"></img></center>
                        </div> */}

                        <div className="reg_form_main offset-sm-3 col-sm-6" style={{ marginTop: "20px" }}>
                            <p className="col-sm-12" style={{ color: Color.THEME_COLOR, fontWeight: 'bold' }} for="PostalCode">
                                {LandingPageConst.Country} <PutMandatoryfieldSymbol />
                            </p>
                            <ReactFlagsSelect
                                className="menu-flags"
                                selectButtonClassName="flag-selection-btn"
                                style={{ borderColor: Color.THEME_COLOR }}
                                name="CountryCode"
                                // defaultCountry="US"
                                selected={this.state.selectedCountry}
                                //countries={["US", "CA", "IN"]}
                                countries={this.state.countryData}
                                placeholder={LandingPagePH.SelectCountry}
                                onSelect={this.onSelectFlag} />

                            <div className="col-md-12 errorMsg">
                                {this.state.errors.CountryCode}
                            </div>
                        </div>
                        <div className="reg_form_main offset-sm-3 col-sm-6">
                            <p className="col-sm-12" style={{ color: Color.THEME_COLOR, fontWeight: 'bold' }} for="PostalCode">
                                {LandingPageConst.Address} <PutMandatoryfieldSymbol />
                            </p>
                            <GooglePlacesAutocomplete
                                apiKey={GOOGLE_PLACE_KEY}
                                selectProps={{
                                    value: this.state.selectedAddress,
                                    onChange: (setAddress) => this.handelAddress(setAddress),
                                    isClearable: true,
                                    placeholder: LandingPagePH.AddAddress,
                                    styles: colourStyles
                                }}
                                autocompletionRequest={{
                                    componentRestrictions: {
                                        country: this.state.countryFields.CountryCode ? [this.state.countryFields.CountryCode] : [],
                                    }
                                }} />
                            <div className="col-md-12 errorMsg">
                                {this.state.errors.Address}
                            </div>
                        </div>
                        <div className="reg_form_main offset-sm-3 col-sm-6">
                            <p className="col-sm-12" style={{ color: Color.THEME_COLOR, fontWeight: 'bold' }} for="PostalCode">
                                {LandingPageConst.ZIP_PostalCode} <PutMandatoryfieldSymbol />
                            </p>
                            <input
                                type="text"
                                onChange={this.handleCountryChange}
                                className="float-none"
                                name="PostalCode"
                                value={this.state.countryFields.PostalCode}
                                placeholder={LandingPagePH.ZIP_PostalCode} />
                            <div className="col-md-12 errorMsg">
                                {this.state.errors.PostalCode}
                            </div>
                        </div>
                        <div
                            className="reg_form_main mbottom"
                            style={{ marginTop: "5px", bottom: "0px" }}>
                            <div className="bottam_sing mt-1">
                                <label
                                    style={{ borderRadius: "4px" }}
                                    for="step2"
                                    id="continue-step2"
                                    onClick={this.handleSignUP}
                                    className="btn btn-done"> {LandingPageConst.btn_SIGNUP}</label>
                            </div>
                            {/* <div className="bottam_sing mt-3">
                                <label
                                    style={{ borderRadius: "4px" }}
                                    for="step2"
                                    id="continue-step2"
                                    onClick={this.handleSignUP}
                                    className="btn btn-done"
                                > {LandingPageConst.btn_SIGNUP}</label>
                            </div> */}
                            <div className="bottam_sing mt-4 mb-2">
                                Do you have an account? <a onClick={this.handleSignIN}
                                    className="theme-color font-wight-bold"
                                    style={{ color: Color.THEME_COLOR, fontWeight: 'bold' }}>{LandingPageConst.btn_SIGNIN}</a>
                            </div>
                        </div>
                    </div>
                </section>
                <MainFooter />
            </div>
        );
    }
}

