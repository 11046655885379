import React, { Component, Fragment } from 'react';
import RouteConstant from '../Config/RouteConstant'
import { COMPANYCONST, CONTACT_US_VISIBLE_MODE } from '../Config/Constants'

class MainFooter extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return <Fragment>
            <section class="info_section layout_padding">
                <div class="container links_container">
                    <div class="row ">
                        <div class="col-md-4 text-center">
                            <h3>
                                Customer Service
                            </h3>
                            <ul>
                                {/* <li>
                                    <a href="">
                                        International Help
                                    </a>
                                </li>
                                <li>
                                    <a href="">
                                        Contact Customer Care
                                    </a>
                                </li>
                                <li>
                                    <a href="">
                                        Return Policy
                                    </a>
                                </li> */}
                                <li>
                                    <a href={RouteConstant.PrivacyPolicy} target="_blank">
                                        Privacy Policy
                                    </a>
                                </li>
                                {/* <li>
                                    <a href="">
                                        Shipping Information
                                    </a>
                                </li>
                                <li>
                                    <a href="">
                                        Promotion Terms
                                    </a>
                                </li> */}
                            </ul>
                        </div>
                        <div class="col-md-4 text-center">
                            <h3>
                                Let Us Help You
                            </h3>
                            <ul>
                                {/* <li>
                                    <a href="">
                                        Your Account
                                    </a>
                                </li>
                                <li>
                                    <a href="">
                                        Your Orders
                                    </a>
                                </li>
                                <li>
                                    <a href="">
                                        Shipping Rates & Policies
                                    </a>
                                </li>
                                <li>
                                    <a href="">
                                        Amazon Prime
                                    </a>
                                </li>
                                <li>
                                    <a href="">
                                        Returns & Replacements
                                    </a>
                                </li> */}
                                <li>
                                    <a href={RouteConstant.HelpAndSupport} target="_blank">
                                        Help and Support
                                    </a>
                                </li>

                                {/* Contact Us */}
                                <li>
                                    <a href={RouteConstant.ContactUs + "?" + CONTACT_US_VISIBLE_MODE.MODE + "=" + CONTACT_US_VISIBLE_MODE.CONTACT_US_MODE} target="_blank">
                                        Contact Us
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-4 text-center">
                            <h3>
                                Information
                            </h3>
                            <ul>
                                <li>
                                    <a href={RouteConstant.AboutUs} target="_blank">
                                        About Us
                                    </a>
                                </li>
                                {/* <li>
                                    <a href="">
                                        Careers
                                    </a>
                                </li>
                                <li>
                                    <a href="">
                                        Sell on shop
                                    </a>
                                </li>
                                <li>
                                    <a href="">
                                        Press & News
                                    </a>
                                </li>
                                <li>
                                    <a href="">
                                        Competitions
                                    </a>
                                </li> */}
                                <li>
                                    <a href={RouteConstant.TermsOfService} target="_blank">
                                        Terms & Conditions
                                    </a>
                                </li>
                            </ul>
                        </div>
                        {/* <div class="col-md-3">
                            <h3>
                                OUR SHOP
                            </h3>
                            <ul>
                                <li>
                                    <a href="">
                                        Daily Deals
                                    </a>
                                </li>
                                <li>
                                    <a href="">
                                        App Only Deals
                                    </a>
                                </li>
                                <li>
                                    <a href="">
                                        Our Hottest Products
                                    </a>
                                </li>
                                <li>
                                    <a href="">
                                        Gift Vouchers
                                    </a>
                                </li>
                                <li>
                                    <a href="">
                                        Trending Product
                                    </a>
                                </li>
                                <li>
                                    <a href="">
                                        Hot Flash Sale
                                    </a>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                </div>
                {/* <div class="container">
                    <div class="follow_container">
                        <div class="row">
                            <div class="col-md-9">
                                <div class="app_container">
                                    <h3>
                                        DOWNLOAD OUR APPS
                                    </h3>
                                    <div>
                                        <img src={process.env.PUBLIC_URL + "/asset/images/google-play.png"} alt="" />
                                        <img src={process.env.PUBLIC_URL + "/asset/images/apple-store.png"} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 ">
                                <div class="info_social">
                                    <div>
                                        <a href="">
                                            <img src={process.env.PUBLIC_URL + "/asset/mages/fb.png"} alt="" />
                                        </a>
                                    </div>
                                    <div>
                                        <a href="">
                                            <img src={process.env.PUBLIC_URL + "/asset/images/twitter.png"} alt="" />
                                        </a>
                                    </div>
                                    <div>
                                        <a href="">
                                            <img src={process.env.PUBLIC_URL + "/asset/images/linkedin.png"} alt="" />
                                        </a>
                                    </div>
                                    <div>
                                        <a href="">
                                            <img src={process.env.PUBLIC_URL + "/asset/images/instagram.png"} alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>

            <section class="container-fluid footer_section">
                <p>
                    Copyright &copy; 2023 All Rights Reserved By <a href="">{COMPANYCONST.PLAZACARTCOM}</a>
                </p>
            </section>
        </Fragment>

    }
}

export default MainFooter