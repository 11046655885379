import React, { Component, Fragment } from "react";
import {
  COMPANYCONST,
  TERMS_OF_SERVICE_IN_VISIBLE_MODE,
} from "../Config/Constants";
import { TermsService } from "../Config/constvariable";
import MainFooter from "../MainFooter/MainFooter";
import MainHeader from "../MainHeader/MainHeader";

export default class TermsofService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 0,
    };
  }

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    if (
      params.get(TERMS_OF_SERVICE_IN_VISIBLE_MODE.MODE) != null &&
      params.get(TERMS_OF_SERVICE_IN_VISIBLE_MODE.MODE).length > 0 &&
      params.get(TERMS_OF_SERVICE_IN_VISIBLE_MODE.MODE) != ""
    ) {
      if (
        params.get(TERMS_OF_SERVICE_IN_VISIBLE_MODE.MODE) ==
        TERMS_OF_SERVICE_IN_VISIBLE_MODE.PRIVATE_MODE
      ) {
        this.setState({ mode: TERMS_OF_SERVICE_IN_VISIBLE_MODE.PRIVATE_MODE });
      }
    }
  }

  render() {
    const { mode } = this.state;
    return (
      <Fragment>
        {mode != TERMS_OF_SERVICE_IN_VISIBLE_MODE.PRIVATE_MODE && (
          <div class="hero_area">
            <MainHeader />
          </div>
        )}
        <section className="section" style={{ float: "unset" }}>
          <div
            className="container mbottom"
            style={{ paddingLeft: "2px", paddingRight: "2px" }}
          >
            <div className="reg_form_main">
              <center>
                <p
                  id="instruction"
                  className="textcolor"
                  style={{ marginBottom: "10px" }}
                >
                  {TermsService.screenTitle}
                </p>{" "}
              </center>
            </div>
            <div
              class="midsection pl-2 pr-2 pl-sm-0 pr-sm-0 pages"
              style={{ textAlign: "justify" }}
            >
              <p>
                <strong>Terms of Use</strong>
              </p>
              <p>
                The present Terms of Use govern the use by every person of the
                Platform, the Content and the Services owned, controlled or
                provided by {COMPANYCONST.BREWERSNEARME}. This document together
                with the Privacy Policy contains important legal information. By
                approving these Terms of Use, by creating a Profile and/or by
                using the Platform or the Services, you confirm having read and
                understood these Terms of Use and the Privacy Policy, and you
                accept that these Terms of Use, the Privacy Policy and any other
                rules regarding operations, policies and procedures that may be
                published by {COMPANYCONST.BREWERSNEARME} on the Platform
                are&nbsp;<strong>legally binding on You</strong>.
              </p>

              <p>
                <strong>1. Acceptance of Terms of Use</strong>
              </p>
              <p>
                By indicating approval of these Terms of Use, by creating a
                Profile and/or by using, in any way, the Services or the
                Platform, the User accepts all these Terms of Use, including the
                Privacy Policy.
              </p>
              <p>
                If the User does not approve of the Terms of Use, the User must
                NOT access or use the Platform in any way, either directly or
                indirectly, or access or use any information or service offered
                by the Platform, including any Services or Content.
              </p>
              <p>
                Some Services may be subject to additional terms and conditions,
                in which case, by accessing or using such Services the User
                accepts such additional terms and conditions, and such
                additional terms and conditions are thereby incorporated by
                reference into these Terms of Use. If the User does not accept
                such additional terms and conditions, the User must&nbsp;
                <strong>NOT access or use</strong>&nbsp;such Services, in any
                way.
              </p>
              <p>
                You agree that it is solely your responsibility to ensure that
                your access to and use of the Platform and Services complies
                with the laws of your jurisdiction.
              </p>
              <p>
                The Services are&nbsp;
                <strong>
                  only available for use by persons who are 18 years of age or
                  older
                </strong>
                . You may not authorize third parties to use your Profile, and
                you may not allow persons under 18 years of age to receive
                services from {COMPANYCONST.BREWERSNEARME}, Third Party
                Providers, Shoppers or Deliverers. In certain instances you may
                be asked to provide proof of identity and age to access or use
                the Platform or Services, and you may be denied access to or use
                of the Platform or Services if you refuse to provide proof of
                identity and age.
              </p>
              <p>
                You are responsible for all software, hardware, services (such
                as Internet service), and equipment necessary to access and use
                the Platform and Services, including all related expenses.
              </p>
              <p>
                Some jurisdictions (including the Province of Quebec) do not
                allow exclusions or limitations on implied warranties, so the
                following exclusions and limitations may not apply to you: We
                make no representation that information on the Platform or any
                Services, Content or Product provided via the Platform, is
                appropriate or available for use in Canada. You accept the use
                of the Platform, Contents, Products and Services "as is".
              </p>

              <p>
                <strong>2. About {COMPANYCONST.BREWERSNEARME}</strong>
              </p>
              <p>
                {COMPANYCONST.BREWERSNEARME} is a communication platform that
                facilitates the connection between Users who wish to place
                Shopping Orders for Products from Breweries, and Shoppers who
                carry out such Shopping Orders on behalf of such Users and
                delivered by the Deliverers. The amount paid in addition to the
                sale prices for the Products corresponds to the payment for the
                services provided by our Shoppers and Deliverers. The User
                acknowledges and accepts that each of the Shopper and the
                Deliverer is a contractor independent of{" "}
                {COMPANYCONST.BREWERSNEARME}, and acts on behalf of the User and
                as an agent of the User in providing services relating to a
                Shopping Order, and that {COMPANYCONST.BREWERSNEARME} has no
                relationship with the User other than to provide Services as set
                out in these Terms of Use (except in rare circumstances when a
                Shopper or Deliverer is providing services as an employee of{" "}
                {COMPANYCONST.BREWERSNEARME}). Payment by Users for the services
                provided by Shoppers and Deliverers for Shopping Orders shall be
                provided by {COMPANYCONST.BREWERSNEARME} to such Shoppers and
                Deliverers, and the User accepts that{" "}
                {COMPANYCONST.BREWERSNEARME} may charge commissions to Shoppers
                and/or Deliverers for the services provided by Shoppers and/or
                Deliverers.
              </p>
              <p>
                {COMPANYCONST.BREWERSNEARME} has no control over the quality,
                time, legality, failures or any other aspects relating in any
                way to the services offered by Shoppers and Deliverers; but has
                control only over those Services provided directly by{" "}
                {COMPANYCONST.BREWERSNEARME}.
              </p>

              <p>
                <strong>3. Definitions</strong>
              </p>
              <p>
                All words and phrases used in these Terms of Use that have an
                upper case first letter (except, the first word in a sentence
                that is not defined herein or a proper name), will carry the
                meaning assigned below, or as otherwise expressly assigned in
                these Terms of Use or Privacy Policy:
              </p>
              <p>
                <strong>Content</strong>: all types of information, whether
                graphic or written, images, audio and videos, including but not
                limited to locations, ads, news, data, scripts, graphics,
                drawings or images, texts, designs, schemes, maps and
                interactive characteristics presented by{" "}
                {COMPANYCONST.BREWERSNEARME} on the Platform (and any software
                and subjacent programming codes), programs or computational
                algorithms (in any format), programming modules, operational
                guidelines, or any content created, provided for or in any way
                generated and managed by {COMPANYCONST.BREWERSNEARME}, the Users
                or third parties.
              </p>
              <p>
                <strong>{COMPANYCONST.BREWERSNEARME} or our:</strong>&nbsp;
                {COMPANYCONST.BREWERSNEARME} Technologies Canada, its
                affiliates, directors, subsidiaries, licensors, shareholders,
                officers and directors.
              </p>
              <p>
                <strong>{COMPANYCONST.BREWERSNEARME} Credit(s):</strong>&nbsp;a
                score equivalent to $1 in the local currency, exclusively
                belonging to the User, and which has been given in order to be
                used as a discount voucher to be applied to the total final sum
                in the Shopping Order done though {COMPANYCONST.BREWERSNEARME}.
              </p>
              <p>
                <strong>Deliverers</strong>: contractors independent of{" "}
                {COMPANYCONST.BREWERSNEARME}, who are linked to or integrated on
                the Platform and who deliver the Products in a Shopping Order.
              </p>
              <p>
                <strong>Device</strong>: mobile telephones and smartphones,
                tablets, computers and any other electronic devices through
                which a User may access the Platform.
              </p>
              <p>
                <strong>Platform</strong>: website, mobile apps, and the
                platform operated by {COMPANYCONST.BREWERSNEARME}, which Users
                use in order to access the Services offered/provided by{" "}
                {COMPANYCONST.BREWERSNEARME} and any Third Party Providers.
              </p>
              <p>
                <strong>Privacy Policy</strong>: {COMPANYCONST.BREWERSNEARME}'s
                privacy policy, as amended from time to time, which is contained
                on the Platform.
              </p>
              <p>
                <strong>Product(s)</strong>: grocery and pharmacy items,
                including without limitation food stuffs, personal care
                products, over-the-counter health products.
              </p>
              <p>
                <strong>Profile</strong>: the personal account created by each
                User that certifies the User's registration on the Platform,
                with personal information provided by the User to{" "}
                {COMPANYCONST.BREWERSNEARME}, including but not limited to first
                name, last name, date of birth, address, telephone number,
                username, email address and password.
              </p>
              <p>
                <strong>Services</strong>: all those services offered to Users
                through the Platform, whether by {COMPANYCONST.BREWERSNEARME},
                Shoppers, Deliverers or Third Party Providers.
              </p>
              <p>
                <strong>Shoppers</strong>: contractors independent of{" "}
                {COMPANYCONST.BREWERSNEARME}, who are linked to or integrated on
                the Platform and who attend at the Breweries to purchase the
                Products in a Shopping Order.
              </p>
              <p>
                <strong>Shopping Order</strong>: a User's order for the purchase
                of selected or substituted Products.
              </p>
              <p>
                <strong>Brewery(ies)</strong>: those retail businesses whose
                Products are offered through the Platform for purchase in a
                Shopping Order.
              </p>
              <p>
                <strong>Terms of Use or Agreement</strong>: these Terms of Use
                for {COMPANYCONST.BREWERSNEARME}, as amended from time to time.
              </p>
              <p>
                <strong>Third Party Providers</strong>&nbsp;: third parties
                unrelated to {COMPANYCONST.BREWERSNEARME} that are linked to or
                integrated on the Platform and/or provide Services.
              </p>
              <p>
                <strong>User(s), you, your</strong>: every person, natural or
                legal, or any representative of that person, who uses or is
                registered on the Platform for the purchase of Products posted
                on the Platform.
              </p>
              <p>
                <strong>User Material</strong>&nbsp;: all types of photographs,
                videos, comments, messages or other content generated by Users,
                which has been uploaded to and shared on the Platform
              </p>

              <p>
                <strong>4. Payment</strong>
              </p>
              <p>
                You understand that use of the Platform or the Services will
                result in charges to you for the Services or Products you
                receive through {COMPANYCONST.BREWERSNEARME}, a Shopper and/or a
                Deliverer ("Charges"). {COMPANYCONST.BREWERSNEARME} will
                facilitate your payment of the applicable Charges on behalf of
                the Shopper or Deliverer as a payment service corollary to the
                principal Services provided. Payment of the Charges in such
                manner shall be considered the same as payment made directly by
                you to the Shopper or Deliverer. Charges will be inclusive of
                applicable taxes where required by law, including GST/HST and
                PST. Charges paid by you are final and non-refundable, unless
                otherwise determined by {COMPANYCONST.BREWERSNEARME} or by the
                applicable legislation.
              </p>
              <p>
                All Charges are due immediately and payment will be facilitated
                by {COMPANYCONST.BREWERSNEARME} using a payment method made
                available at {COMPANYCONST.BREWERSNEARME}'s discretion, after
                which {COMPANYCONST.BREWERSNEARME} will send you a receipt by
                email. If your primary payment card or other payment method is
                found to be expired, invalid or otherwise not able to be
                charged, you agree that {COMPANYCONST.BREWERSNEARME} may, as the
                Shopper's or Deliverer's as payment service provider, use
                another available payment card or method in your Profile.
              </p>
              <p>
                As between you and {COMPANYCONST.BREWERSNEARME},{" "}
                {COMPANYCONST.BREWERSNEARME} reserves the right to establish,
                remove and/or revise Charges for any or all Services or Products
                obtained through the use of the Platform or Services at any time
                in {COMPANYCONST.BREWERSNEARME}'s sole discretion, but in all
                cases in accordance with the applicable legislation. Further,
                you acknowledge and agree that Charges applicable in certain
                geographical areas may increase substantially during times of
                high demand or vary for each retail/grocery brewery available on{" "}
                {COMPANYCONST.BREWERSNEARME}. {COMPANYCONST.BREWERSNEARME} will
                inform you of Charges that may apply, provided that you will be
                responsible for Charges incurred under your Profile regardless
                of your awareness of the amounts of such Charges. You may cancel
                your request for Services or Products at any time prior to
                payment for Products in a Shopping Order, in which case you may
                be charged a cancellation fee, unless prohibited by the
                applicable legislation.
              </p>
              <p>
                This payment structure is intended to fully compensate{" "}
                {COMPANYCONST.BREWERSNEARME}, the Shopper and Deliverer for the
                Services and Products provided.
              </p>
              <p>
                {COMPANYCONST.BREWERSNEARME} does not designate any portion of
                the Charges as a tip or gratuity to a Shopper.You understand and
                agree that, while you are free to provide an additional payment
                as a gratuity to a Shopper in connection with fulfilling a
                Shopping Order (&ldquo;Gratuity&rdquo;), whether through the
                Platform or in cash, you are under no obligation to do so.
                Gratuities are voluntary. In connection with any Shopping Order,
                the Gratuity amount may be set to a default percentage greater
                than 0%. You can edit the Gratuity amount prior to completing
                the Shopping Order on the Platform or up to one hour after your
                Shopping Order is delivered.
              </p>

              <p>
                <strong>
                  5. {COMPANYCONST.BREWERSNEARME}'s Liability Release
                </strong>
              </p>
              <p>
                <strong>
                  {COMPANYCONST.BREWERSNEARME} will bear no responsibility at
                  all for the acts or omissions of Shoppers or Deliverers, or of
                  any Breweries or Third Party Providers, all of whom shall bear
                  full responsibility for Services or other services they
                  provide to Users.
                </strong>
                .
              </p>
              <p>
                {COMPANYCONST.BREWERSNEARME} will not be liable for any
                complaint, injury or damage that may arise due to the acts or
                omissions of any Brewery, Shopper, Deliverer or Third Party
                Provider, except the stated conditions set out in the previous
                paragraph. If a dispute arises with a Brewery, Shopper,
                Deliverer or Third Party Provider, such dispute shall be between
                the User and such Brewery, Shopper, Deliverer and/or Third Party
                Provider.
              </p>
              <p>
                THE USER HEREBY RELEASES {COMPANYCONST.BREWERSNEARME}, ITS
                DIRECTORS, EMPLOYEES, SUBSIDIARIES, AFFILIATES, AGENTS AND
                REPRESENTATIVES, OF ALL LIABILITY AND RESPONSIBILITY FOR ANY AND
                ALL COMPLAINTS, ACTIONS, PROCEEDINGS, COSTS, CLAIMS, DAMAGES,
                DEMANDS, AND EXPENSES INCLUDING BUT NOT LIMITED TO, LAWYER'S
                FEES OR LOSSES OF ANY KIND, WHETHER DIRECT OR INDIRECT, THAT MAY
                ARISE AS A CONSEQUENCE OF A DISPUTE BETWEEN A USER AND A
                BREWERY, SHOPPER, DELIVERER OR THIRD PARTY PROVIDER.
              </p>
              <p>
                The User acknowledges and understands that{" "}
                {COMPANYCONST.BREWERSNEARME} is no more than the mere
                facilitator, through providing use of the Platform, of the
                interaction between the User and Brewery, Shopper, Deliverer and
                Third Party Provider; and the User understands in no case is the
                User purchasing Products from {COMPANYCONST.BREWERSNEARME}, and
                that, should damages or infringement to User rights occur, such
                damages or infringement shall be a matter directly between the
                User and the Brewery, Shopper, Deliverer or Third Party
                Provider, and {COMPANYCONST.BREWERSNEARME} shall not be involved
                or affected thereby in any way.
              </p>
              <p>
                Note: There may be limited situations where services of a
                Shopper or Deliverer are provided by an employee of{" "}
                {COMPANYCONST.BREWERSNEARME}. In those circumstances{" "}
                {COMPANYCONST.BREWERSNEARME} will be responsible to the extent
                imposed by applicable law. However, as these situations are
                rare, Users should not rely upon such a possibility.
              </p>

              <p>
                <strong>
                  6. Specific rules about purchases of alcohol, tobacco,
                  cannabis and vapour products or over-the-counter medications
                </strong>
              </p>
              <p>
                The sale and other distribution of alcohol, tobacco (including
                cigarettes), cannabis (including cannabis-related products) and
                vapor products, as well as over-the-counter medications, are or
                may be subject to restriction under applicable law.
              </p>
              <p>
                <strong>Alcohol Products</strong>
              </p>
              <p>
                The Platform does not currently support the sale or delivery of
                Alcohol products.
              </p>
              <p>
                <strong>Tobacco, Cannabis and Vapour Products</strong>
              </p>
              <p>
                The Platform does not currently support the sale or delivery of
                tobacco (including cigarettes), cannabis (including
                cannabis-related products) or vapour products.
              </p>
              <p>
                <strong>Over-the-Counter Medications</strong>
              </p>
              <p>
                If you place any Shopping Order that includes any product that
                is an over-the-counter drug or other medical product, you
                acknowledge that your ordering and use of such product is
                entirely at your own risk. You acknowledge and agree that{" "}
                {COMPANYCONST.BREWERSNEARME} does not provide any medical
                advice, diagnosis or treatment, and that that no
                pharmacy-patient relationship nor physician-patient relationship
                exists between {COMPANYCONST.BREWERSNEARME} and you as a result
                of your use of the Services.
              </p>
              <p>
                We encourage you to consult with your healthcare professional or
                pharmacist regarding all health-related issues.
              </p>
              <p>
                {COMPANYCONST.BREWERSNEARME} MAKES NO REPRESENTATIONS REGARDING
                ANY HEALTH BENEFITS, RISKS OR HEALTH INFORMATION RELATING TO ANY
                OVER-THE-COUNTER DRUG OR OTHER MEDICAL PRODUCTS, AND{" "}
                {COMPANYCONST.BREWERSNEARME}, ITS DIRECTORS, EMPLOYEES,
                SUBSIDIARIES, AFFILIATES, AGENTS AND REPRESENTATIVES, AND THE
                CONTRACTORS SHALL HAVE NO RESPONSIBILITY OR LIABILITY FOR SUCH
                OVER-THE-COUNTER DRUG AND OTHER MEDICAL PRODUCTS (INCLUDING YOUR
                USE THEREOF) AT ALL.
              </p>
              <p>
                IF YOU ARE HAVING A MEDICAL EMERGENCY, CONTACT YOUR LOCAL
                EMERGENCY ASSISTANCE SERVICE IMMEDIATELY.
              </p>

              <p>
                <strong>7. Use of Content</strong>
              </p>
              <p>
                Unless otherwise stated, all rights over the Content belong to{" "}
                {COMPANYCONST.BREWERSNEARME}. Users are authorized to use the
                Platform and download the material contained in it solely for
                their personal use. The User is hereby granted a personal,
                revocable, limited, non-exclusive, non-sub licensable, and
                non-transferable license to use the Content conditioned on your
                continued compliance with the Terms of Use, all other
                requirements for the Platform and Services, and all applicable
                laws and regulations.
              </p>
              <p>
                Any copy, reproduction, publication, download for non-personal
                use, coding, modification, translation, interpretation,
                exhibition, distribution, transmission or media distribution, of
                the Content without {COMPANYCONST.BREWERSNEARME}'s previous,
                explicit and written authorization is expressly prohibited.
                Copyrights and all other rights relating to the Content belong
                to {COMPANYCONST.BREWERSNEARME}, and{" "}
                {COMPANYCONST.BREWERSNEARME} expressly reserves all of its
                rights to exercise all actions, both civil and criminal, to
                safeguard its legitimate rights over its intellectual and
                industrial property in the Content, Platform and Services.
              </p>
              <p>
                Occasionally there may be Content or other information on the
                Platform that contains typographical errors, inaccuracies, or
                omissions that may relate to Product prices, descriptions or
                availability, and {COMPANYCONST.BREWERSNEARME} Credits. Unless
                prohibited by the applicable legislation,{" "}
                {COMPANYCONST.BREWERSNEARME} reserves the right to correct any
                errors, inaccuracies or omissions and to change or update
                information or refuse to cancel Shopping Orders if any
                information on the Platform is inaccurate at any time, without
                prior notice (including after you have submitted your Shopping
                Order and provided payment). Please note that prices of Products
                on the Platform may be different than prices offered for the
                same Products in-brewery by the same retailer.
              </p>
              <p>
                {COMPANYCONST.BREWERSNEARMECAP} ASSUMES NO OBLIGATION TO UPDATE
                THE CONTENT ON THE PLATFORM. THE CONTENT ON THE PLATFORM MAY BE
                CHANGED WITHOUT NOTICE TO YOU. {COMPANYCONST.BREWERSNEARMECAP}{" "}
                IS NOT RESPONSIBLE FOR ANY CONTENT OR INFORMATION THAT YOU MAY
                FIND UNDESIRABLE OR OBJECTIONABLE.{" "}
                {COMPANYCONST.BREWERSNEARMECAP} DISCLAIMS ANY LIABILITY FOR
                UNAUTHORIZED USE OR REPRODUCTION OF ANY PORTION OF THE WEBSITE.
                ACCESSING THE CONTENT FROM TERRITORIES WHERE IT MAY BE ILLEGAL
                IS PROHIBITED.
              </p>

              <p>
                <strong>8. User Material</strong>
              </p>
              <p>
                Should the Platform permit the publication or uploading of User
                Material by Users, such User Material will be deemed, at all
                times, to be non-confidential. By publishing or uploading User
                Material to the Platform or otherwise for the Services, the User
                grants {COMPANYCONST.BREWERSNEARME} a perpetual, worldwide,
                non-exclusive, royalty-free, sub-licensable and transferable
                right to use, reproduce, distribute, modify, show and transfer
                User Material for {COMPANYCONST.BREWERSNEARME}'s promotion,
                support and any other purposes of {COMPANYCONST.BREWERSNEARME}{" "}
                or permitted Third Party Providers. {COMPANYCONST.BREWERSNEARME}{" "}
                grants every User with a personal, revocable, limited,
                non-exclusive, non-sub-licensable, and non-transferable license
                to access their User Material through the Platform, and to
                publicly show and represent such User Material in accordance
                with the Terms of Use.
              </p>
              <p>
                The User represents and warrants that: (i) the User is the owner
                of the User Material or - the User has the authorization of the
                owner of the User Material for the publishing and uploading of
                the User Material to the Platform or otherwise for the Services;
                (ii) the User Material is not illicit, obscene, defamatory,
                offensive, pornographic, odious, racist or inadequate in any
                way; (iii) the User Material will not damage the reputation of{" "}
                {COMPANYCONST.BREWERSNEARME} or any third party; and that (iv)
                the User has all rights and authorities to provide the User
                Material and these representations and warranties to{" "}
                {COMPANYCONST.BREWERSNEARME}, and in doing so the User is acting
                in accordance with all applicable laws.{" "}
                {COMPANYCONST.BREWERSNEARME} reserves the right to refuse to
                make any User Material accessible via the Platform or any
                Services, and to remove any User Material from the Platform, in{" "}
                {COMPANYCONST.BREWERSNEARME}'s sole discretion and without any
                previous warning, liability or responsibility to the User or any
                other person.
              </p>
              <p>
                {COMPANYCONST.BREWERSNEARME} does not support or endorse any
                kind of User Material, including but not limited to any
                recommendation or advice, and the User releases{" "}
                {COMPANYCONST.BREWERSNEARME} from any and all liability and
                responsibility relating in any way to User Material generated by
                any User. The User understands and accepts that User Material
                can contain inaccurate, harmful or offensive information, and
                the User agrees that it will at no time lodge any complaint or
                file any legal claim of any nature against{" "}
                {COMPANYCONST.BREWERSNEARME} in relation to any User Material.
              </p>
              <p>
                {COMPANYCONST.BREWERSNEARME} may provide links, in its sole
                discretion, to other Internet sites or networks of Third Party
                Providers, or provide Third Party Provider content, on the
                Platform or its Services. Such content is owned by Third Party
                Providers, and such links are to other sites or networks that
                are maintained by Third Party Providers over which{" "}
                {COMPANYCONST.BREWERSNEARME} exercises no control and for which{" "}
                {COMPANYCONST.BREWERSNEARME} bears no liability or
                responsibility. These Terms of Use are not applicable to any
                third party links, websites or networks, as those are governed
                by the policies of the third party, and we suggest that you
                review the applicable policies before engaging in use of any
                third party link, website or network. Your correspondence or any
                other dealings with third parties are solely between you and the
                third parties.
              </p>

              <p>
                <strong>9. User Account</strong>
              </p>
              <p>
                Each person may only create one Profile and the person who
                creates a User Profile is the only authorized person to access
                the Platform by means of said User Profile. The User is
                responsible for maintaining the confidentiality of any password
                or account number provided by the User or{" "}
                {COMPANYCONST.BREWERSNEARME} in order to access the Platform.
                Every User is the sole responsible party for all the activities
                executed under their Profile or password.{" "}
                {COMPANYCONST.BREWERSNEARME} has no control over the use of the
                User's Profile and therefore disclaims all responsibility and
                liability relating to such use. If the User suspects a third
                party might be accessing the Platform through their User Profile
                or using their password, the User shall immediately notify{" "}
                {COMPANYCONST.BREWERSNEARME} in writing by sending an email
                to&nbsp;ADMIN@{COMPANYCONST.BREWERSNEARMECOMCAP}. By providing
                your telephone number you are granting express consent for{" "}
                {COMPANYCONST.BREWERSNEARME} to make use of said number in order
                to make calls and send text messages for the corresponding
                provision of the Platform and Services.
              </p>
              <p>
                {COMPANYCONST.BREWERSNEARME} may suspend and/or delete any User
                Profile that is not created in accordance with these Terms of
                Use, including by providing false information, or by creating
                more than one Profile for one person.
              </p>
              <p>
                The User acknowledges and agrees that a Shopper or Deliverer may
                contact the User in relation to a Shopping Order, or the
                provision of Services relating to a Shopping Order, through any
                contact information provided in the User's Profile, or through
                contact information the User provides directly to the Shopper or
                Deliverer, or with a Shopping Order.{" "}
                {COMPANYCONST.BREWERSNEARME} does not bear any responsibility or
                liability for any such contact between the User and any Shopper
                and/or Deliverer.
              </p>
              <p>
                Should any Product in a Shopping Order not be available when a
                Shopper is filling a Shopping Order, the Shopper has the
                discretion to either contact the User to obtain instructions for
                a substitution for the unavailable Product, or Product to be
                included in the Shopping Order.
              </p>

              <p>
                <strong>10. Disclaimers</strong>
              </p>
              <p>
                SOME JURISDICTIONS (INCLUDING THE PROVINCE OF QUEBEC) DO NOT
                ALLOW EXCLUSIONS OR LIMITATIONS ON IMPLIED WARRANTIES,
                INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES, OR LIABILITY, SO THE
                BELOW EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU.
              </p>
              <p>
                THE USE OF THE PLATFORM AND THE SERVICES PROVIDED BY{" "}
                {COMPANYCONST.BREWERSNEARMECAP} IS AT THE USER'S OWN RISK.{" "}
                {COMPANYCONST.BREWERSNEARMECAP} DOES NOT REPRESENT OR WARRANT
                THAT THE PLATFORM OR SERVICES WILL OPERATE ERROR-FREE, ON AN
                UNINTERRUPTED BASIS, OR THAT IT IS FREE FROM VIRUSES, MALWARE OR
                OTHER MALFUNCTIONING OR DANGEROUS PRODUCTS. THE PLATFORM AND
                SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE," AND{" "}
                {COMPANYCONST.BREWERSNEARMECAP} DISCLAIMS ANY AND ALL
                WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION,
                ANY IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, NON-VIOLATION
                OF THIRD PARTY RIGHTS, MERCHANTABILITY, OR FITNESS OR
                SUITABILITY FOR A PARTICULAR PURPOSE OF THE PLATFORM OR
                SERVICES. MOREOVER, {COMPANYCONST.BREWERSNEARMECAP} DISCLAIMS
                RESPONSIBILITY AND LIABILITY FOR ANY BREWERY, SHOPPER,
                DELIVERER, USER OR THIRD-PARTY PROVIDED CONTENT, MATERIALS,
                LINKS, WEBSITES, NETWORKS, PROGRAMS, PRODUCTS, AND SERVICES SET
                FORTH, DESCRIBED ON, OR ACCESSED THROUGH THE PLATFORM OR
                SERVICES, AND YOU AGREE THAT {COMPANYCONST.BREWERSNEARMECAP}{" "}
                SHALL NOT BE RESPONSIBLE FOR ANY LOSS OR DAMAGE OF ANY SORT
                INCURRED AS A RESULT OF ANY SUCH DEALINGS BETWEEN YOU AND A
                BREWERY, SHOPPER, DELIVERER OR THIRD PARTY.
              </p>
              <p>
                {COMPANYCONST.BREWERSNEARMECAP} DOES NOT OFFER ANY KIND OF
                REPRESENTATION OR WARRANTY RELATED TO THE EXACTITUDE,
                RELIABILITY, EXHAUSTIVENESS, VALIDITY OR TOTALITY OF THE
                CONTENT, SERVICES, SUPPORT, SOFTWARE, TEXTS, GRAPHICS OR LINKS
                AVAILABLE THROUGH THE PLATFORM, OR TO THE CONTENT OF ANY WEBSITE
                LINKED TO THE PLATFORM, OR ANY OF THE BREWERIES.{" "}
                {COMPANYCONST.BREWERSNEARMECAP} ASSUMES NO RESPONSIBILITY
                DERIVED FROM: (I) MISTAKES OR IMPRECISIONS IN THE CONTENT; (II)
                PERSONAL INJURIES OR DAMAGES TO PROPERTY, OF ANY KIND, AS A
                RESULT OF THE ACCESS AND USE OF THE PLATFORM; (III) UNAUTHORIZED
                ACCESS TO OR USE OF THE SERVERS FOR{" "}
                {COMPANYCONST.BREWERSNEARMECAP} AND/OR ANY TYPE OF PERSONAL
                AND/OR FINANCIAL INFORMATION STORED IN THEM; OR (IV) A BREWERY'S
                DENIAL TO GIVE OUT THE PURCHASED PRODUCTS.
              </p>
              <p>
                THERE IS NO GUARANTEE THAT INFORMATION OR PERSONAL INFORMATION
                ON THE PLATFORM OR ON THE INTERNET, OR SUBMISSIONS TO THE
                PLATFORM OR THROUGH THE INTERNET WILL BE MAINTAINED CONFIDENTIAL
                AND SECURE. THE USE OF THE PLATFORM AND THE CONTENT IS AT YOUR
                OWN RISK AND {COMPANYCONST.BREWERSNEARMECAP} AND SUPPLIERS
                ASSUME NO LIABILITY OR RESPONSIBILITY PERTAINING TO THE CONTENT,
                YOUR USE OF THE PLATFORM, OR THE RECEIPT, STORAGE, TRANSMISSION
                OR OTHER USE OF YOUR INFORMATION OR PERSONAL INFORMATION, OR ANY
                MISAPPROPRIATION, INTERCEPTION, DELETION, DESTRUCTION OR USE BY
                THIRD PARTIES THEREOF.
              </p>
              <p>
                If the use of the Platform results in the need to get technical
                assistance or the replacement of a device or data,{" "}
                {COMPANYCONST.BREWERSNEARMECAP} will not be responsible for such
                costs.
              </p>

              <p>
                <strong>11. License to Use</strong>
              </p>
              <p>
                {COMPANYCONST.BREWERSNEARME} owns all intellectual property
                rights in the Platform and Services. You are granted a personal,
                revocable, limited, non-exclusive, non-sublicensable, and
                non-transferable license to use the Platform and Services
                conditioned on your continued compliance with the terms and
                conditions of these Terms of Use, all other{" "}
                {COMPANYCONST.BREWERSNEARME} requirements for the Platform and
                Services, and all applicable laws and regulations. Neither these
                Terms of Use nor your use of the Platform or Services convey or
                grant to you any rights: (i) in or related to the Platform or
                Services except for the limited licenses expressly granted
                herein; or (ii) to use or reference in any manner{" "}
                {COMPANYCONST.BREWERSNEARME}'s company names, logos, product and
                service names, trademarks or services marks or those of Third
                Party Providers or any of {COMPANYCONST.BREWERSNEARME}'s
                licensors.
              </p>
              <p>
                You agree not to do any of the following: use the Platform or
                Services in any manner that could disable, overburden, damage or
                impair the Platform or interfere with any other party's use of
                the Platform or Services including their ability to engage in
                real time activities through the Platform; use any robot,
                spider, other automatic device process or means, or similar data
                gathering, data mining, or extraction methods to access the
                Platform for any purpose including monitoring or copying any of
                the material on the Platform; attempt to gain unauthorized
                access to, interfere with, attack, damage or disrupt any parts
                of the Platform, web/Internet or the server on which the
                Platform is hosted; attempt to interfere with the proper working
                of the Platform, including but not limited to through hacking,
                ransoming, or other attacks upon the services provided by and
                function of the Platform; use the Platform or Service to
                fraudulently misrepresent yourself, impersonate another person,
                engage in false advertising, defraud or defame any person,
                engage or participate in any ponzi scheme, pyramid scheme, chain
                letter, unsolicited bulk or commercial emails, or collect any
                personally identifiable information from any user of the
                Platform or Services without that person's knowledge and
                consent; create derivative works of, reverse engineer,
                decompile, disassemble, adapt, translate, transmit, arrange,
                modify, copy, bundle, sell, sub-license, export, merge,
                transfer, adapt, loan, rent, lease, assign, share, outsource,
                host, publish, make available to any person or otherwise use,
                either directly or indirectly, the Content in whole or in part,
                in any form or by any means, be they physical, electronic or
                otherwise; or use the Platform or Services in any manner that
                promotes and/or enables illegal or unlawful activities;
              </p>
              <p>
                Such unauthorized use is a material breach of these Terms of
                Use, and may also violate applicable laws including without
                limitation copyright and trademark laws, the laws of privacy and
                publicity, and applicable communications regulations and
                statutes.
              </p>

              <p>
                <strong>12. Notifications and Complaints</strong>
              </p>
              <p>
                Users shall immediately notify {COMPANYCONST.BREWERSNEARME} of
                any behaviour or content that may infringe these Terms of Use,
                the Privacy Policy or the applicable law.
              </p>
              <p>
                If you believe that the User Material or Content residing on or
                accessible through the Platform infringes your copyright (or the
                copyright of someone whom you are authorized to act on behalf
                of), please send a notice of copyright infringement
                to&nbsp;ADMIN@{COMPANYCONST.BREWERSNEARMECOMCAP}
              </p>
              <p>
                Upon receipt of such report, {COMPANYCONST.BREWERSNEARME}, at
                its sole discretion, will determine if any activity shall be
                taken in response to such report, including but not limited to
                whether such behavior or content is to be deleted or modified.
              </p>

              <p>
                <strong>13. Limitation of Liability</strong>
              </p>
              <p>
                SOME JURISDICTIONS (INCLUDING THE PROVINCE OF QUEBEC) DO NOT
                ALLOW EXCLUSIONS OR LIMITATIONS ON IMPLIED WARRANTIES,
                INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES, OR LIMITATION OF
                LIABILITY, SO THE BELOW EXCLUSIONS AND LIMITATIONS MAY NOT APPLY
                TO YOU.
              </p>
              <p>
                {COMPANYCONST.BREWERSNEARMECAP} SHALL NOT BE RESPONSIBLE FOR OR
                BEAR ANY LIABILITY FOR ANY CLAIM OF HARM RESULTING FROM A CAUSE
                BEYOND {COMPANYCONST.BREWERSNEARMECAP}'S CONTROL. MOREOVER, AND
                TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL{" "}
                {COMPANYCONST.BREWERSNEARMECAP} BE LIABLE FOR ANY INDIRECT,
                PUNITIVE, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES ARISING
                OUT OF OR IN ANY WAY CONNECTED WITH: THE USE OF THE PLATFORM OR
                SERVICES OR FOR ANY INFORMATION, WEBSITE, NETWORK, PRODUCT,
                CONTENT, SERVICES OR MATERIALS AVAILABLE THROUGH THE PLATFORM OR
                SERVICES; ANY CLAIM {COMPANYCONST.BREWERSNEARMECAP} EXPRESSLY
                DISCLAIMS RESPONSIBILITY FOR IN THIS AGREEMENT; ANY DIRECT OR
                INDIRECT DAMAGE, LOSS OF PROFIT, EMERGING DAMAGE, INCIDENTAL,
                SPECIAL OR CONSEQUENTIAL DAMAGE, RESULTING FROM: (I) THE USE OF
                THE PLATFORM; (II) THE RESPONSIBILITY OF ANY BREWERY, SHOPPER OR
                DELIVERER THAT DOES NOT OFFER THEIR SERVICES UNDER{" "}
                {COMPANYCONST.BREWERSNEARMECAP}'S SUBORDINATION-DEPENDENCY
                AGREEMENT; (III) THE PERFORMANCE OF OR BROWSING IN THE PLATFORM
                AND/OR LINK THEREIN TO OTHER SITES, EVEN IF{" "}
                {COMPANYCONST.BREWERSNEARMECAP} HAS BEEN INFORMED OF THE
                POTENTIAL FOR DAMAGES; (IV) THE QUALITY AND QUANTITY OF THE
                PRODUCTS DELIVERED TO THE USER; (V) THE SAFETY OF THE PURCHASED
                PRODUCTS, INCLUDING BUT NOT LIMITED TO ANY FOOD SAFETY RELATED
                ISSUES; OR (VI) INCORRECT, INCOMPLETE OR ERRONEOUS INFORMATION
                ABOUT THE PRODUCTS; OR FOR ANY DAMAGES DERIVED FROM THE
                INTERRUPTION, SUSPENSION OR TERMINATION OF THE SERVICES,
                INCLUDING WITHOUT LIMITATION, DIRECT OR INDIRECT DAMAGES, LOSS
                OF PROFIT, EMERGENT DAMAGE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL
                DAMAGE, WHETHER SUCH INTERRUPTION, SUSPENSION OR TERMINATION IS
                JUSTIFIED OR NOT; WHETHER BASED IN CONTRACT, TORT, STRICT
                LIABILITY, OR OTHERWISE, AND EVEN IF{" "}
                {COMPANYCONST.BREWERSNEARMECAP} HAS BEEN ADVISED OF THE
                POSSIBILITY OF SUCH DAMAGES. IN ADDITION, TOTAL LIABILITY OF{" "}
                {COMPANYCONST.BREWERSNEARMECAP} FOR ANY REASON RELATED TO USE OF
                THE PLATFORM OR SERVICES, OR ANY OTHER REASON AT ALL, SHALL NOT
                EXCEED THE AMOUNT PAID BY YOU FOR USE OF THE PLATFORM OR
                SERVICES (DURING THE TWELVE (12) MONTHS PRIOR TO ANY EVENT ON
                WHICH A CLAIM IS BASED).
              </p>

              <p>
                <strong>14. Indemnity</strong>
              </p>
              <p>
                You agree at all times to indemnify, defend and hold harmless{" "}
                {COMPANYCONST.BREWERSNEARME}, its agents, suppliers, Third Party
                Providers and other third parties engaged by or otherwise
                assisting {COMPANYCONST.BREWERSNEARME}, and their respective
                parent companies, directors and employees against all actions,
                proceedings, costs, claims, damages, fines, sanctions, demands,
                liabilities and expenses (including legal and other fees and
                disbursements) sustained, incurred or paid by{" "}
                {COMPANYCONST.BREWERSNEARME} directly or indirectly in respect
                of: (i) any information, Profile, User Materials, or any other
                content you provide on or through the Platform or Services or
                which is sent to {COMPANYCONST.BREWERSNEARME} by email or other
                correspondence, or any activities you engage in through the
                Platform or Services as a member or in any other capacity; (ii)
                any third party claims, including but not limited to any
                intellectual property infringement claims, other infringement of
                rights claims, or privacy or personal information claims, that
                relate to any information, Profile, User Materials, or any other
                content you provide on or through the Platform or Services, or
                any activity or omission relating to the Platform or Services by
                you; (iii) your use or misuse of the Platform or Services; (iv)
                your unauthorized use of the Platform; or (v) any infringement
                of these Terms of Use or any applicable law, including without
                limitation, the violation of any law, ordinance, administrative
                order, rule or regulation.
              </p>
              <p>
                {COMPANYCONST.BREWERSNEARME} will have the right to assume the
                defense against any claim, appeal or procedure.
              </p>

              <p>
                <strong>15. Termination</strong>
              </p>
              <p>
                {COMPANYCONST.BREWERSNEARME} may modify or interrupt the
                Platform, or may modify, suspend or interrupt its access or
                support, due to any reason, at its sole discretion, with or
                without notice, and with no responsibility towards any Users or
                third party. Upon a User's loss of the right to use the Platform
                or Services, the Terms of Use will be applicable or executable
                against such User.
              </p>
              <p>
                The User may terminate these Terms of Use at any time by
                stopping use of the Platform or closing the User's Profile.
                These Terms of Use will be terminated upon ninety (90) days of
                non-use by a User of the Platform and/or the Services, or upon
                any illicit or abusive activity by a User. Upon such a
                termination: all terms that survive termination shall remain
                applicable and in effect for User; and{" "}
                {COMPANYCONST.BREWERSNEARME} may delete the User's account,
                Profile and all other information provided by said User. The
                termination of the Agreement for such User, for any reason, will
                not cause {COMPANYCONST.BREWERSNEARME} to owe any compensation
                to User other than as is expressly set out in these Terms of
                Use.
              </p>

              <p>
                <strong>16. Dispute Resolution-Arbitration</strong>
              </p>
              <p>
                This dispute resolution provision is not applicable in the
                Province of Quebec.
              </p>
              <p>
                Any difficulty or dispute that may arise between the User and{" "}
                {COMPANYCONST.BREWERSNEARME} regarding the application,
                interpretation, duration, validity, or implementation of these
                Terms of Use will be submitted for arbitration and resolution
                before a sole arbitrator (the "Arbitrator") selected in
                accordance with the Simplified Rules of the ADR Institute of
                Canada, Inc. The parties agree that the arbitration shall
                proceed under the Simplified Rules of the ADR Institute of
                Canada, Inc.
              </p>
              <p>
                No appeal against the Arbitrator's resolutions shall proceed,
                resigning both parties from making such appeals. The Arbitrator
                is especially empowered to resolve every issue related to these
                Terms of Use and the relationship between User and{" "}
                {COMPANYCONST.BREWERSNEARME}.
              </p>

              <p>
                <strong>
                  17. {COMPANYCONST.BREWERSNEARME} credits, free deliveries,
                  discounts and offers
                </strong>
              </p>
              <p>
                {COMPANYCONST.BREWERSNEARME} reserves the right to give free or
                reduced-cost Services, deliveries, discounts, coupons, or
                credits to Users (the "{COMPANYCONST.BREWERSNEARME} Credits") as
                part of its marketing campaigns. {COMPANYCONST.BREWERSNEARME}{" "}
                credits are promotional codes that can only be obtained as part
                of a {COMPANYCONST.BREWERSNEARME} promotion or marketing
                campaign which happens from time to time.{" "}
                {COMPANYCONST.BREWERSNEARME} credits cannot be purchased. These{" "}
                {COMPANYCONST.BREWERSNEARME} Credits may be directed to a User's
                Profile, and may be applied to a User's Shopping Order
                subsequent to the provision of the {COMPANYCONST.BREWERSNEARME}{" "}
                Credits. Under no circumstance can any{" "}
                {COMPANYCONST.BREWERSNEARME} Credits be transferred from one
                User to another, or be exchanged for cash or other monetary
                value.
              </p>
              <p>
                {COMPANYCONST.BREWERSNEARME} Credits cannot be obtained through
                the creation of multiple User Profiles. A User may only have one
                User Profile. {COMPANYCONST.BREWERSNEARME} Credits accumulated
                in multiple {COMPANYCONST.BREWERSNEARME} Profiles cannot be
                combined into one {COMPANYCONST.BREWERSNEARME} Profile.
              </p>
              <p>
                {COMPANYCONST.BREWERSNEARME} Credits cannot be used to purchase
                alcoholic beverages.
              </p>
              <p>
                In any given Shopping Order, {COMPANYCONST.BREWERSNEARME}{" "}
                reserves the right to liquify the total or any portion of the{" "}
                {COMPANYCONST.BREWERSNEARME} Credits accumulated in the Profile
                by the User in order to pay for such Shopping Order or the
                delivery costs for such Shopping Order.
              </p>
              <p>
                At the sole discretion of {COMPANYCONST.BREWERSNEARME},{" "}
                {COMPANYCONST.BREWERSNEARME} Credits promotions can have a
                limited duration of time of availability or utility.{" "}
                {COMPANYCONST.BREWERSNEARME} reserves the right to revoke any{" "}
                {COMPANYCONST.BREWERSNEARME} Credits without any notification to
                Users. {COMPANYCONST.BREWERSNEARME} may suspend or terminate{" "}
                {COMPANYCONST.BREWERSNEARME} Credits accumulated in a Profile,
                the User's capacity to participate in the accumulation of any{" "}
                {COMPANYCONST.BREWERSNEARME} Credits, or any marketing campaign
                or other program granting {COMPANYCONST.BREWERSNEARME} Credits,
                at any given time and for any reason at{" "}
                {COMPANYCONST.BREWERSNEARME}'s sole discretion. Requirements and
                incentives relating to {COMPANYCONST.BREWERSNEARME} Credits may
                be changed at any time by {COMPANYCONST.BREWERSNEARME} at its
                sole discretion.
              </p>
              <p>
                {COMPANYCONST.BREWERSNEARME} Credits obtained as a result of
                illicit activities are null and void, with no effect.{" "}
                {COMPANYCONST.BREWERSNEARME} reserves the right to suspend any
                Profile or eliminate any recommendation if{" "}
                {COMPANYCONST.BREWERSNEARME} notices any activity that is
                illicit or abusive by any User or otherwise in relation to any{" "}
                {COMPANYCONST.BREWERSNEARME} Credits.
              </p>

              <p>
                <strong>18. Modifications</strong>
              </p>
              <p>
                {COMPANYCONST.BREWERSNEARME} may, at its sole discretion, change
                or modify without any previous notice, these Terms of Use
                including the Privacy Policy. Modifications to the Terms of Use
                will be applied from the moment of their publication on the
                Platform and shall be applicable to all transactions occurring
                after such publication, without altering, in any way, the
                transactions executed prior to such publication.
              </p>
              <p>
                {COMPANYCONST.BREWERSNEARME} will send you, at least 30 days
                before the amendment comes into force, a notice setting out the
                new provision, or the amended provision and the provision as it
                read formerly, and the date of the coming into force of the
                amendment. You may refuse the amendment and terminate these
                Terms and Use without cost, penalty or cancellation indemnity by
                sending {COMPANYCONST.BREWERSNEARME} a notice to that effect no
                later than 30 days after the amendment comes into force, if the
                amendment entails an increase in your obligations or a reduction
                in the {COMPANYCONST.BREWERSNEARME}&rsquo;s obligations.
              </p>

              <p>
                <strong>19. Non-Agency</strong>
              </p>
              <p>
                The Agreement or your use of the Platform or Services does not
                create any contractual agency relationship, association, joint
                company, employer-employee, joint venture, partnership,
                affiliate, agency or franchisor-franchisee relationship between
                you and {COMPANYCONST.BREWERSNEARME}.
              </p>

              <p>
                <strong>20. No Assignment</strong>
              </p>
              <p>
                These Terms of Use are personal and cannot be delegated,
                assigned, transferred or sublicensed, except with{" "}
                {COMPANYCONST.BREWERSNEARME}'s prior and written consent.{" "}
                {COMPANYCONST.BREWERSNEARME} may assign, transfer, sublicense or
                delegate any of its rights and obligations in virtue of the
                present Terms of Use without the User's consent.
              </p>
              <p>
                Unless otherwise stated in this Terms of Use, all warnings and
                notifications will be considered duly delivered from the earlier
                of: the moment of their publication on the Platform, or the
                moment the User has been notified accordingly.
              </p>

              <p>
                <strong>21. Contact</strong>
              </p>
              <p>
                If there are any questions or doubts about the Terms of Use,
                including the Privacy Policy, the use of the Platform, Services
                or the Profile, the User may contact{" "}
                {COMPANYCONST.BREWERSNEARME} through our Customer Service chat
                available in the app and on the website, or via email
                at:&nbsp;ADMIN@{COMPANYCONST.BREWERSNEARME}.COM. Messages
                received by {COMPANYCONST.BREWERSNEARME} at such chat or email
                address will be processed as quickly as feasible in a
                commercially reasonable manner.
              </p>

              <p>
                <strong>22. Enforcing Security on the Platform</strong>
              </p>
              <p>
                {COMPANYCONST.BREWERSNEARME} reserves the right to view,
                monitor, and record your activity on the Platform without notice
                or permission from you. Any information obtained by monitoring,
                reviewing, or recording may be provided to: (i) law enforcement
                organizations in connection with investigation or prosecution of
                possible criminal or unlawful activity on the Platform; (ii)
                government agencies as required for any disclosures required by
                or under applicable law; and (iii) other third parties as
                required for {COMPANYCONST.BREWERSNEARME} to comply with any
                court orders or subpoenas involving requests for such
                information.
              </p>

              <p>
                <strong>23. Applicable Law and Severability</strong>
              </p>
              <p>
                This applicable law provision is not applicable in the Province
                of Quebec.
              </p>
              <p>
                These Terms of Use have been made and will be construed and
                enforced solely in accordance with the laws of the Province of
                Ontario and the federal laws of Canada applicable therein.
              </p>
              <p>
                The Terms of Use including the Privacy Policy constitute a
                complete and the entire agreement between the User and{" "}
                {COMPANYCONST.BREWERSNEARME} with respect to the Services,
                Platform, Content use and other subject matter herein, and
                substitute all communication and proposals, prior or
                contemporary &ndash; either written, oral or electronic &ndash;
                between the User and {COMPANYCONST.BREWERSNEARME}.
              </p>

              <p>
                <strong>24. Miscellaneous</strong>
              </p>
              <p>
                <strong>Injunctive Relief.</strong>&nbsp;You acknowledge that
                any breach, whether threatened or actual, of these Terms of Use,
                including, without limitation, with respect to unauthorized use
                of {COMPANYCONST.BREWERSNEARME}'s proprietary rights or assets,
                will cause irreparable injury to {COMPANYCONST.BREWERSNEARME},
                such injury would not be quantifiable in monetary damages, and{" "}
                {COMPANYCONST.BREWERSNEARME} would not have an adequate remedy
                at law. You therefore agree that {COMPANYCONST.BREWERSNEARME}{" "}
                shall be entitled, in addition to other available remedies, to
                seek and be awarded an injunction or other appropriate equitable
                relief from a court of competent jurisdiction restraining any
                breach, threatened or actual, of your obligations under any
                provision of these Terms of Use. Accordingly, you hereby waive
                any requirement that {COMPANYCONST.BREWERSNEARME} post any bond
                or other security in the event any injunctive or equitable
                relief is sought by or awarded to {COMPANYCONST.BREWERSNEARME}{" "}
                to enforce any provision of these Terms of Use.
              </p>
              <p>
                <strong>Waiver &amp; Severability.</strong>&nbsp;Failure to
                insist on strict performance of any of the terms and conditions
                of these Terms of Use will not operate as a waiver of any
                subsequent default or failure of performance. No waiver by{" "}
                {COMPANYCONST.BREWERSNEARME} of any right under these Terms of
                Use will be deemed to be either a waiver of any other right or
                provision or a waiver of that same right or provision at any
                other time. If any part of these Terms of Use is determined to
                be invalid or unenforceable pursuant to applicable law
                including, but not limited to, the warranty disclaimers and the
                liability limitations set forth above, then the invalid or
                unenforceable provision will be deemed superseded by a valid,
                enforceable provision that most closely matches the intent of
                the original provision and the remainder of these Terms of Use
                shall continue in effect.
              </p>
              <p>
                <strong>Exclusion:</strong>&nbsp;We expressly exclude the UN
                Convention on Contracts for the International Sale of Goods, and
                the International Sale of Goods Act (Ontario), as amended,
                replaced or re-enacted from time to time. You agree to waive any
                right you may have to a trial by jury. Except if you are located
                in the Province of Qu&eacute;bec, you also agree to waive any
                right you may have to commence or participate in any class
                action against {COMPANYCONST.BREWERSNEARME} related to the
                Platform, the Services, the Content, the Products, the services
                or products of any Shopper, Deliverer or Third Party Provider,
                or these Terms of Use and, where, applicable, you also agree to
                opt out of any class proceedings against{" "}
                {COMPANYCONST.BREWERSNEARME}, its agents, or its licensors.
              </p>

              <p>
                <strong>25. Returns</strong>
              </p>
              <p>
                If a product you receive is in poor condition or it is wrong,
                please report it to ADMIN@{COMPANYCONST.BREWERSNEARMECOMCAP}
                &nbsp;or through the in-app chat so we can find you a solution.
              </p>
              <p>
                Returns are subject to the policies of each specific brewery and
                branch.
              </p>
            </div>
          </div>
        </section>
        {mode != TERMS_OF_SERVICE_IN_VISIBLE_MODE.PRIVATE_MODE && (
          <MainFooter />
        )}
      </Fragment>
    );
  }
}
