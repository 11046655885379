import React, { Component } from 'react';
import './MyCartOrderWebSingleStore.css';
import Color from '../../Config/Color';
import delete1 from "../../../images/icons/delete.png";
import { StoreObjectInStorage, RetriveObjectFromStorage } from '../../Config/Utils';
import Constants, { currencyInfo } from '../../Config/Constants';
import Collapsible from 'react-collapsible';
import NoPriview from '../../../../src/images/nopreviewp.png';
import UpdateToppingsOrderWebSingleStore from '../AddToCart/UpdateToppingsOrderWebSingleStore';
import SizeColorItemOrderWebSingleStore from '../AddToCart/SizeColorItemOrderWebSingleStore';
import { getProductCountInCart } from '../../Config/utilityFunction';

class CartPlazaListOrderWeb extends Component {
    constructor(props) {
        super(props);
        this.state = {
            plazalist: props.data,
            plazaId: "",
            storeId: "",
            itemId: "",
            itemindex: -1,
            selectitemobj: {},
            toppingCharge: 0.0,

            readmeInd: -1
        }
    }

    componentDidMount() {

    }

    setPlazaTotalAmount = (obj, storeId, ItemId) => {
        console.log("obj ", obj);
        console.log("storeId ", storeId);
        console.log("ItemId ", ItemId);
        console.log("ItemId ", this.state.plazalist);

        var list1 = this.state.plazalist.map(curr => {
            var storelist = curr.Stores
            var list2 = storelist.map(objStore => {
                if (objStore.ApplicationId === storeId) {
                    var itemlist = objStore.Items
                    var list3 = itemlist.map(objItem => {
                        if (objItem.ItemId === ItemId) {
                            objItem = obj
                            return { ...objItem }
                        }
                        else {
                            return { ...objItem }
                        }
                    })
                    return { ...objStore, Items: list3 }
                }
                else {
                    return { ...objStore }
                }
            })
            return { ...curr, Stores: list2 }
        })

        console.log("plaza  ", list1);
        this.setState({ plazalist: list1 }, function () {
            this.props.setPlazaTotal(this.state.plazalist);
        });
    }

    setdeletedItem = (deleteItemId, storeId) => {
        // console.log("obj ", obj);
        console.log("storeId ", storeId, deleteItemId);

        console.log("ItemId ", this.state.plazalist);

        var list1 = this.state.plazalist.map(curr => {


            var storelist = curr.Stores
            var list2 = storelist.map(objStore => {
                var itemArray = []
                if (objStore.ApplicationId === storeId) {


                    var itemlist = objStore.Items
                    var list3 = itemlist.map(objItem => {
                        if (objItem.ItemId === deleteItemId) {
                            itemArray = objStore.Items.filter(e1 => e1.ItemId !== deleteItemId);
                            // alert(JSON.stringify(itemArray))
                            // return { ...objItem }
                        }
                        // else {
                        //     itemArray = objStore.Items
                        //     // return { ...objItem }
                        // }
                    })

                    return { ...objStore, "Items": itemArray }
                }
                else {
                    return { ...objStore }
                }
            })

            return { ...curr, "Stores": list2 }
        })

        console.log("plaza  ", list1);
        this.setState({ plazalist: list1 }, function () {
            this.props.deletedItemwith(this.state.plazalist);
        })
    }

    renderStore(store, plazaId) {
        return store
            .map(function (item, index) {
                var storeItemList = item.Items
                return (
                    <React.Fragment key={index}>
                        <div className="col-sm-12" style={{ backgroundColor: Color.THEME_COLOR, color: Color.WHITE }} >
                            {item.ApplicationName}
                        </div>
                        {this.renderStoreItem(plazaId, item.ObjectId, storeItemList, item)}
                    </React.Fragment>
                )
            }, this);
    }

    openDeleteDialog(plazaId, storeid, item, index) {
        this.setState({
            plazaId: plazaId,
            storeId: storeid,
            itemId: item.ItemId,
            itemindex: index
        });
        // $('#myModal').modal('show');
        this.handleModal(true, 'myModal')
    }

    unsetDeleteId() {
        this.setState({
            plazaId: "",
            storeId: "",
            itemId: "",
            itemindex: -1
        });
        this.handleModal(false, 'myModal')
    }

    handleDelete() {
        var plazaIdState = this.state.plazaId;
        var storeIdState = this.state.storeId;
        var itemIdState = this.state.itemId;
        var itemindex = this.state.itemindex;

        var list1 = this.state.plazalist.map((curr, indexP) => {
            var ItemlistIn = curr.Items.filter((e, index) => itemindex !== index);
            return { ...curr, "Items": ItemlistIn }
        });

        var masterPlazaList = list1.filter(e2 => e2.Items.length > 0);
        this.setState({ plazalist: masterPlazaList }, function () {
            if (this.state.plazalist.length > 0) {
                StoreObjectInStorage(Constants.Obj_CART, this.state.plazalist);
            }
            else {
                StoreObjectInStorage(Constants.Obj_CART, '');
            }
            this.props.setPlazaTotal(this.state.plazalist);
        });

        this.handleModal(false, 'myModal')
    }

    handlePlus(plazaId, storeid, item, index) {
        console.log('Hi');
        var list1 = this.state.plazalist.map(curr => {
            var itemlist = curr.Items;
            var list3 = itemlist.map((objItem, iindex) => {
                var count = item.ItemQuantity;
                var singleItemPrice = item.TotalItemPrice / count;

                if (index === iindex) {

                    // for quntity 22-may-2023
                    // count = count + 1;
                    if (objItem.TrackQuantity) {
                        if (objItem.size && objItem.color) {
                            let itemcount = getProductCountInCart(objItem.ItemId, objItem.size, objItem.color)
                            if (objItem.AvailableQuantityForCart > itemcount) {
                                count = count + 1;
                            }
                        }
                        else {
                            let itemcount = getProductCountInCart(objItem.ItemId)
                            if (objItem.AvailableQuantityForCart > itemcount) {
                                itemcount = itemcount + Number(objItem.ItemQuantity)
                                count = count + 1;
                            }
                        }
                    }
                    else {
                        count = count + 1;
                    }
                    //


                    if (objItem.TrackQuantity) {
                        if (objItem.AvailableQuantity >= count) {
                            return {
                                ...objItem, "TotalItemPrice": singleItemPrice * count,
                                "ItemQuantity": count,
                                "specialdeliverycharges": (objItem.specialdeliverycharges / objItem.ItemQuantity) * count
                            }
                        }
                        return { ...objItem, "specialdeliverycharges": (objItem.specialdeliverycharges / objItem.ItemQuantity) * count }
                    }
                    else {
                        return {
                            ...objItem, "TotalItemPrice": singleItemPrice * count,
                            "ItemQuantity": count,
                            "specialdeliverycharges": (objItem.specialdeliverycharges / objItem.ItemQuantity) * count
                        }
                    }
                } else {
                    return { ...objItem }
                }
            });
            return { ...curr, Items: list3 }
        });
        this.setState({ plazalist: list1 }, function () {
            this.props.setPlazaTotal(this.state.plazalist);
        });
    }

    handleMinus(plazaId, storeid, item, index) {
        var list1 = this.state.plazalist.map(curr => {
            var itemlist = curr.Items
            var list3 = itemlist.map((objItem, iindex) => {
                var count = item.ItemQuantity
                var singleItemPrice = item.TotalItemPrice / count;
                if (count !== 1) {
                    count = count - 1;
                    if (index === iindex) {
                        return {
                            ...objItem, "TotalItemPrice": singleItemPrice * count,
                            "ItemQuantity": count,
                            "specialdeliverycharges": (objItem.specialdeliverycharges / objItem.ItemQuantity) * count
                        }
                    }
                }
                return { ...objItem }
            });
            return { ...curr, Items: list3 }
        });
        this.setState({ plazalist: list1 }, function () {
            this.props.setPlazaTotal(this.state.plazalist);
        });
    }

    renderStoreItem(plazaId, storeid, storeItemList, StoreMainObject) {
        var currencySymbole = ""
        var countryFees = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES)
        if (countryFees) {
            currencySymbole = (countryFees[currencyInfo.CountryCode] ? "(" + countryFees[currencyInfo.CountryCode] + ") " : "") + countryFees.Symbol
        }

        return storeItemList
            .map((item, index) => {
                var total = item.TotalItemPrice;
                total = (Math.round(total * 100) / 100).toFixed(2);

                return (
                    <React.Fragment key={item.ItemID}>
                        <div className="col-sm-12" style={{ backgroundColor: Color.WHITE, borderBottom: "1px solid" + Color.GREY }}  >
                            <div className="row" style={{ paddingTop: "15px", paddingLeft: '15px', paddingRight: '10px' }} >
                                <img src={item.ItemLogo ? item.ItemLogo : NoPriview} style={{ borderRadius: "5px", height: "40px", width: "20%", marginRight: "7px", width: "40px" }}></img>
                                <label style={{ fontSize: "15px", width: "55%", paddingTop: "5px", color: Color.BLACK, fontWeight: 'bold' }}> {item.ItemName}
                                    {((item.ItemType === "Q" && item.ToppingItems.length === 0) || (item.ItemType === "" && item.ToppingItems.length >= 0 && item.ToppingCategory.length > 0)) ? <span style={{ color: Color.THEME_COLOR, fontWeight: 'bold' }} onClick={() => this.handleEditCartItem(item, index)}> <u>Edit</u> </span> : ""}
                                </label> {/* {item.Instruction ? "(" + item.Instruction + ")" : ""} */}
                                <span style={{ marginLeft: "auto", width: "25%", paddingTop: "5px", marginRight: "10px" }}>
                                    <label style={{ fontSize: "15px", float: "right", color: Color.THEME_COLOR, fontWeight: 'bold' }}>{currencySymbole}{total}</label>
                                </span>
                            </div>
                            <div className="row" style={{ paddingLeft: '15px' }} >
                                {(item.ItemType === "" && item.ToppingItems.length === 0) && <label style={{ marginLeft: "47px", fontSize: "14px", width: "55%", color: Color.BLACK }}>

                                    {item.ItemDescription && item.ItemDescription?.length > 100 ?
                                        index == this.state.readmeInd ?
                                            <>{item.ItemDescription + "..."}<span class="atag-text" onClick={() => this.readmoreFun(index)}>read less</span></> :
                                            <>{item.ItemDescription.slice(0, 100) + "..."}<span class="atag-text" onClick={() => this.readmoreFun(index)}>read more</span></>
                                        :
                                        item.ItemDescription}

                                </label>}

                                {/* Shopify  */}
                                {StoreMainObject && StoreMainObject.isshopifyintegration && item && item.variantdetail && item.variantdetail != "" && <label style={{ marginLeft: "47px", fontSize: "14px", width: "55%", color: Color.BLACK, fontWeight: 'bold' }}>Variant  Detail: {(item.variantdetail != "") ? <span style={{ color: Color.THEME_COLOR }} >{item.variantdetail}</span> : ""}</label>}

                                {(item.ItemSize != "" && item.itemColor != "" && StoreMainObject && !StoreMainObject.isshopifyintegration) && <label style={{ marginLeft: "47px", fontSize: "14px", width: "55%", color: Color.BLACK, fontWeight: 'bold' }}>Size: {(item.ItemSize != "") ? <span style={{ color: Color.THEME_COLOR }} >{item.ItemSize}</span> : ""}{", "}Color: {(item.itemColor != "") ? <span style={{ color: Color.THEME_COLOR }} >{item.itemColor}</span> : ""}</label>}

                                {(item.ItemWeight != 0 && item.ItemWeightType != "") && <label style={{ marginLeft: "47px", fontSize: "14px", width: "55%", color: Color.BLACK, fontWeight: 'bold' }}>Weight: {(item.ItemWeight != "") ? <span style={{ color: Color.THEME_COLOR }} >{item.ItemWeight}</span> : ""}{" "}{(item.ItemWeightType != "") ? <span style={{ color: Color.BLACK }} >{item.ItemWeightType}</span> : ""}</label>}

                                {item.ToppingItems && item.ToppingItems.length > 0 != "" &&
                                    <label
                                        style={{ marginLeft: "47px", fontSize: "14px", width: "55%", color: Color.BLACK, fontWeight: 'bold' }}>
                                        {item.ToppingItems.map(function (titem, index) {
                                            return (<span style={{ color: Color.THEME_COLOR }} >{titem.ToppingItem}<span style={{ color: Color.BLACK }}>, </span></span>);
                                        })}
                                    </label>
                                }

                                <span style={{ float: "right", marginLeft: "auto", }}>
                                    <label style={{ fontSize: "20px", color: Color.BLACK, fontWeight: 'bold' }} onClick={(value) => this.handleMinus(plazaId, storeid, item, index)}> &#8722; </label>
                                    <label style={{ fontSize: "20px", color: Color.THEME_COLOR, width: "40px", textAlign: "center", fontWeight: 'bold', marginBottom: '5px' }}> {item.ItemQuantity}  </label>
                                    <label style={{ fontSize: "20px", color: Color.BLACK, marginRight: "20px", fontWeight: 'bold' }} onClick={(value) => this.handlePlus(plazaId, storeid, item, index)} > &#x2b; </label>
                                    <img src={delete1} style={{ marginLeft: "0px", marginRight: "15px", height: "20px", width: "20px" }} onClick={(value) => this.openDeleteDialog(plazaId, storeid, item, index)}></img>
                                    {/* <i  class="fa fa-trash" style={{ marginLeft:"10px", marginRight:"10px", fontSize: "20px", color: Color.GREY,  }}></i> */}
                                </span>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }, this);
    }

    handleEditCartItem(item, index) {
        if (item.ItemSize != "" && item.itemColor != "" && item.ItemType === "Q" && item.ToppingItems.length === 0) {
            $('#sizecolorModal').modal('show');
        } else {
            $('#toppingModal').modal('show');
        }
        var itemObj = JSON.parse(JSON.stringify(item));
        this.setState({ selectitemobj: itemObj });
        var toppingCharge = 0.0;
        if (item && item.ToppingItems) {
            for (var withtopinngs of item.ToppingItems/* .slice(3, item.ToppingItems.length) */) {
                toppingCharge = toppingCharge + withtopinngs.Price
            }
        }
        this.setState({ toppingCharge: toppingCharge, itemindex: index });
    }

    AddUpdateSelectedToppings = selectitemobj => {
        const { updateState } = this.props
        var list1 = this.state.plazalist.map(curr => {
            var itemlist = curr.Items
            var list3 = itemlist.map((objItem, iindex) => {
                if (this.state.itemindex === iindex) {
                    // objItem = selectitemobj;

                    // for quntity 22-may-2023
                    if (objItem.TrackQuantity) {
                        if (objItem.size && objItem.color) {
                            let itemcount = getProductCountInCart(objItem.ItemId, selectitemobj.size, selectitemobj.color)
                            if (selectitemobj.size == objItem.size && selectitemobj.color == objItem.color) {
                                itemcount = itemcount - Number(objItem.ItemQuantity) + Number(selectitemobj.ItemQuantity)
                            }
                            else {
                                itemcount = itemcount + Number(selectitemobj.ItemQuantity)
                            }

                            if (selectitemobj.AvailableQuantityForCart >= itemcount) {
                                return selectitemobj
                            }
                            else {
                                updateState()
                            }
                        }
                        else {
                            let itemcount = getProductCountInCart(objItem.ItemId)
                            itemcount = itemcount - Number(objItem.ItemQuantity) + Number(selectitemobj.ItemQuantity)
                            if (selectitemobj.AvailableQuantityForCart >= itemcount) {
                                return selectitemobj
                            }
                            else {
                                updateState()
                            }
                        }
                    }
                    else {
                        return selectitemobj
                    }
                    //

                    return {
                        ...objItem,
                    }
                } else {
                    return {
                        ...objItem,
                    }
                }
                return { ...objItem }
            });
            return { ...curr, Items: list3 }
        });
        this.setState({ plazalist: list1 }, function () {
            this.props.setPlazaTotal(this.state.plazalist);
        });
    }

    SetItemObjectUpdate = itemObj => {
        if (itemObj) {
            this.setState({ selectitemobj: itemObj });
        }
    }

    setTotalPrice = totalPrice => {
        var selectitemobj = this.state.selectitemobj;
        if (selectitemobj) {
            selectitemobj.TotalItemPrice = totalPrice;
        }
        this.setState({ selectitemobj: selectitemobj });
    }

    setSelectedToppings = selectedToppings => {
        var selectitemobj = this.state.selectitemobj;
        if (selectitemobj) {
            selectitemobj.ToppingItems = selectedToppings;
            this.setState({ selectitemobj: selectitemobj });
        }
    }

    setToppingPrice = toppingPrice => {
        this.setState({ toppingCharge: toppingPrice });
    }

    setItemCount = itemCount => {
        var selectitemobj = this.state.selectitemobj;
        if (selectitemobj) {
            if (selectitemobj.IsSpecialDeliveryItem) {
                selectitemobj.specialdeliverycharges = (selectitemobj.specialdeliverycharges / selectitemobj.ItemQuantity) * itemCount;
            }
            selectitemobj.ItemQuantity = itemCount;
        }
        this.setState({ selectitemobj: selectitemobj });
    }

    handelSize = size => {
        var selectitemobj = this.state.selectitemobj;
        if (selectitemobj && size) {
            selectitemobj.ItemDescription = size.description;
            selectitemobj.size = size.size;
            selectitemobj.ItemSize = size.size;
            selectitemobj.SelectItemSize = size;
            if (size && size.colors && size.colors.length > 0) {
                var selectedItemColor = size.colors[0];
                if (selectedItemColor) {
                    selectitemobj.AvailableQuantity = selectedItemColor.quantity;
                    selectitemobj.AvailableQuantityForCart = selectedItemColor.quantity;
                    selectitemobj.itemColor = selectedItemColor.color;
                    selectitemobj.color = selectedItemColor.color;
                    selectitemobj.SelectitemColor = selectedItemColor;
                    this.setState({ selectitemobj: selectitemobj });
                }
            }
        }
    }

    handelColor = color => {
        var selectitemobj = this.state.selectitemobj;
        if (selectitemobj && color) {
            selectitemobj.AvailableQuantity = color.quantity;
            selectitemobj.AvailableQuantityForCart = color.quantity;
            selectitemobj.itemColor = color.color;
            selectitemobj.color = color.color;
            selectitemobj.SelectitemColor = color;
            this.setState({ selectitemobj: selectitemobj });
        }
    }

    // new modal methods 19-may-2023

    // modal method 19-may-2023
    handleModal(falg, id) {
        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById(id);

        if (backgroung && modalId) {
            backgroung.style.display = falg ? "block" : "none";
            modalId.style.display = falg ? "block" : "none";
        }
    }

    //

    readmoreFun(ind) {
        this.setState({ readmeInd: this.state.readmeInd == ind ? -1 : ind })
    }

    render() {
        const { selectitemobj } = this.state;

        let list = this.state.plazalist
            .map(function (item, index) {
                var itemList = item.Items;
                var noOfStore = 1 /* store.length */;
                var noOfProducts = itemList.length;
                var plazaTotalAmount = 0.0
                var storeItemList = [];
                itemList.map(function (objitem, index1) {
                    var data = objitem;
                    plazaTotalAmount = plazaTotalAmount + ((data.TotalItemPrice / data.ItemQuantity) * data.ItemQuantity);
                })
                var currencySymbole = ""
                var countryFees = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES)
                if (countryFees) {
                    currencySymbole = (countryFees[currencyInfo.CountryCode] ?
                        "(" + countryFees[currencyInfo.CountryCode] + ") " : "") + countryFees.Symbol
                }

                return (
                    <React.Fragment>
                        <Collapsible
                            open={true}
                            trigger={
                                <div className="col-sm-12" style={{ backgroundColor: Color.WHITE, paddingBottom: "5px", borderBottom: "1px solid" + Color.GREY }} >
                                    <div style={{ width: "100%" }} >
                                        {item.AppBannerImage && <div style={{ width: "20%", }}>
                                            <img src={item.AppBannerImage ? item.AppBannerImage : NoPriview}
                                                onError={(e) => (e.target.onerror = null, e.target.src = NoPriview)} loading='lazy'
                                                style={{ marginTop: "5px", float: "left", height: "40px", marginRight: "7px", width: "40px", borderRadius: "5px" }}></img>
                                        </div>}

                                        <div style={{ width: "100%" }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0px' }}>
                                                <label style={{ marginRight: "auto", marginBottom: '0px', color: Color.BLACK, fontSize: "17px", fontWeight: 'bold' }}>{item.ApplicationName} {item.CompanyName ? "(" + item.CompanyName + ")" : ""}</label>
                                                <i class="fa fa-caret-down" style={{ fontSize: "30px", color: Color.THEME_COLOR, float: "right", marginRight: "5px" }} aria-hidden="true"></i>
                                            </div>
                                            <div style={{ marginLeft: '40px', display: 'flex', justifyContent: 'space-between' }}>
                                                <label style={{ marginRight: "auto", color: Color.BLACK, fontSize: "17px" }}><b><span style={{ color: Color.THEME_COLOR }}>{noOfProducts}</span></b> Product {/* {item.CompanyName ? " |" + noOfStore + " Shop" : ""} */} </label>
                                                <label style={{ float: "right", marginRight: "5px", color: Color.THEME_COLOR, fontSize: "17px", fontWeight: 'bold' }}>{currencySymbole}{(Math.round(plazaTotalAmount * 100) / 100).toFixed(2)}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            triggerWhenOpen={
                                <div className="col-sm-12" style={{ backgroundColor: Color.WHITE, paddingBottom: "5px", borderBottom: "1px solid" + Color.GREY }} >
                                    <div style={{ width: "100%" }} >
                                        {item.AppBannerImage && <div style={{ width: "20%" }}>
                                            <img src={item.AppBannerImage ? item.AppBannerImage : NoPriview} style={{ marginTop: "5px", float: "left", height: "40px", marginRight: "7px", width: "40px", borderRadius: "5px" }}></img>
                                        </div>}

                                        <div style={{ width: "100%" }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0px' }}>
                                                <label style={{ marginRight: "auto", marginBottom: '0px', color: Color.BLACK, fontSize: "17px" }}>{item.ApplicationName} {item.CompanyName ? "(" + item.CompanyName + ")" : ""}</label>
                                                <i class="fa fa-caret-up" style={{ fontSize: "30px", color: Color.THEME_COLOR, float: "right", marginRight: "5px" }} aria-hidden="true"></i>
                                            </div>
                                            <div style={{ marginLeft: "40px", display: 'flex', justifyContent: 'space-between' }}>
                                                <label style={{ marginRight: "auto", color: Color.BLACK, fontSize: "17px" }}>{noOfProducts} Product {/* {item.CompanyName ? " |" + noOfStore + " Shop" : ""} */} </label>
                                                <label style={{ float: "right", marginRight: "5px", color: Color.THEME_COLOR, fontSize: "17px" }}>{currencySymbole}{(Math.round(plazaTotalAmount * 100) / 100).toFixed(2)}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }>
                            <React.Fragment key={index}>
                                {item.CompanyName
                                    &&
                                    <div className="col-sm-12" style={{ backgroundColor: Color.THEME_COLOR, color: Color.WHITE }} >
                                        {/* {item.ApplicationName} */}
                                    </div>}
                                {this.renderStoreItem(item.PlazaId, item.ObjectId, itemList, item)}
                            </React.Fragment>
                            {/* <CartStoreList
                                data={store}
                                setPlazaTotal={this.setPlazaTotalAmount}
                                deleteStoreItem={this.setdeletedItem}>
                            </CartStoreList> */}
                        </Collapsible>
                    </React.Fragment>
                )
            }, this);

        return (
            <div>
                {/* Delete Model */}
                <div class="modal modalcustom" id="myModal" style={{ verticalAlign: "middle" }}>
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-body">
                                <center>
                                    <label>Plazacart</label><br />
                                    <label>Are you sure you want remove this product from cart?</label>
                                </center>
                            </div>
                            <div class="modal-footer">
                                {/*  <div class="w-50 text-center setaTag" > */}
                                <label
                                    onClick={() => this.unsetDeleteId()}
                                    class="m-btn"
                                /* style={{ color: Color.THEME_COLOR, marginLeft: "auto", marginRight: "auto" }} */
                                // data-dismiss="modal"
                                > No</label>
                                {/*  </div> */}
                                {/* <div class="w-50 text-center setaTag" > */}
                                <label
                                    onClick={() => this.handleDelete()}
                                    class="m-btn"
                                /*  style={{ color: Color.THEME_COLOR, marginLeft: "auto", marginRight: "auto" }} */
                                // data-dismiss="modal"
                                > Yes</label>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* Topping Model */}
                <div class="modal" id="toppingModal" style={{ marginBottom: "10px" }} centered>
                    <div class="modal-dialog" style={{ margin: "auto", top: "25%" }}>
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div class="modal-body">
                                <UpdateToppingsOrderWebSingleStore
                                    selectedToppings={this.AddUpdateSelectedToppings}
                                    itemData={selectitemobj}
                                    itemPrice={selectitemobj.ItemPrice}
                                    setItemData={this.SetItemObjectUpdate}
                                    selectedToppingList={selectitemobj.ToppingItems}
                                    setSelectedToppings={this.setSelectedToppings}
                                    itemCount={selectitemobj.ItemQuantity}
                                    setItemCount={this.setItemCount}
                                    totalPrice={selectitemobj.TotalItemPrice}
                                    setTotalPrice={this.setTotalPrice}
                                    toppingCharge={this.state.toppingCharge}
                                    setToppingPrice={this.setToppingPrice}
                                    AvailableQuantity={selectitemobj.AvailableQuantity}
                                    ContinueSelling={selectitemobj.ContinueSelling}
                                    TrackQuantity={selectitemobj.TrackQuantity}>
                                </UpdateToppingsOrderWebSingleStore>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Size/Color Model */}
                <div class="modal" id="sizecolorModal" style={{ marginBottom: "10px" }} centered>
                    <div class="modal-dialog" style={{ margin: "auto", top: "25%" }}>
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div class="modal-body">
                                <SizeColorItemOrderWebSingleStore
                                    selectedToppings={this.AddUpdateSelectedToppings}
                                    itemData={selectitemobj}
                                    itemPrice={selectitemobj.ItemPrice}
                                    itemCount={selectitemobj.ItemQuantity}
                                    setItemCount={this.setItemCount}
                                    totalPrice={selectitemobj.TotalItemPrice}
                                    setTotalPrice={this.setTotalPrice}
                                    AvailableQuantity={selectitemobj.AvailableQuantity}
                                    ContinueSelling={selectitemobj.ContinueSelling}
                                    TrackQuantity={selectitemobj.TrackQuantity}
                                    setSize={this.handelSize}
                                    setColor={this.handelColor}>
                                </SizeColorItemOrderWebSingleStore>
                            </div>
                        </div>
                    </div>
                </div>
                {list}
            </div>
        );
    }
}

export default CartPlazaListOrderWeb;