import React, { Component } from 'react';
import defaultUser from "../../images/default-user.png";
import './SideMenu.css';
import WheelPhoto from "../../images/gear-wheel.png";

import { MenuItem } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import Color from '../Config/Color';
import RouteConstant from '../Config/RouteConstant';
import { withRouter } from 'react-router';
import Moment from 'moment';
import { StoreObjectInStorage } from '../Config/Utils';
import Constants from '../Config/Constants';
import CountryList from '../Config/CountryCode'

class MenuData extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.handleItemClick = this.handleItemClick.bind(this);
        this.closeNav = this.closeNav.bind(this);
    }

    componentDidMount() {

    }

    handleItemClick(data) {
        console.log("item click")
        console.log(JSON.stringify(data))
        StoreObjectInStorage(Constants.orderDetail, data);
        this.closeNav();
       
        this.props.history.push({
            pathname: RouteConstant.OrderDetail,
        });
        
        const { onrefresh } = this.props;

        if (typeof onrefresh == "function") {
            onrefresh();
        }
    }

    closeNav() {
        window.closeNav();
    }

    render() {
        let list = this.props.data
            .map(function (item, index) {

                var imageurl = item.storebanner;

                if (item.Items) {
                    imageurl = item.Items[0] ? item.Items[0].ItemImage : ""
                }

                var noOfShope = 0;
                var itemOfShope = 0;
                // var plazz = item.Plaza.map(e1 => {
                //     imageurl = e1.PlazaImage;
                //     noOfShope = e1.Stores.length

                //     var shop = e1.Stores.map(e2 => {
                itemOfShope = itemOfShope + item.Items.length;
                //     })

                // })

                var currency = this.props.countryArr.filter(e => e.Currency == item.Currency)[0]
                var currencySymbols = "(" + item.Currency + ")" + (currency ? currency.Symbol + " " : "")

                return (
                    <React.Fragment key={item.OrderId}>
                        <div className="col-sm-12" style={{ backgroundColor: Color.WHITE, borderBottom: "1px solid" + Color.GREY }} onClick={(value) => this.handleItemClick(item)} >
                            <div className="row" style={{ fontSize: "12px" }}>Order ID: {item.OrderId} </div>
                            <div className="row" style={{ fontSize: "12px" }}>Order Date:<span style={{ color: Color.THEME_COLOR }}> {Moment(item.OrderDate).format("DD MMM YYYY h:mm a")}</span></div>
                            <div className="row" style={{ fontSize: "12px" }}>Order Status: <span style={{ color: Color.GREEN }}> {item.OrderStatus}</span></div>
                            <div className="row" style={{ fontSize: "12px" }}>
                                <div style={{ fontSize: "12px", marginLeft: "auto", marginRight: "18px" }}>
                                    <center>{/* {noOfShope} Shops with <br /> */} {itemOfShope} Products</center>
                                </div>
                            </div>
                            <div className="row" >
                                <label style={{ fontSize: "12px", color: Color.THEME_COLOR }}>{currencySymbols} {(Math.round((item.TotalAmount) * 100) / 100).toFixed(2).toString()} </label>
                                <span style={{ marginLeft: "auto", }}>
                                    <img src={imageurl} style={{ height: "25px", marginRight: "10px", width: "25px" }}></img>
                                    <label style={{ fontSize: "13px", float: "right", marginRight: "18px" }}>+ View More</label>
                                </span>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }, this);

        return (
            <div>
                {list}
            </div>
        );
    }
}

export default withRouter(MenuData);