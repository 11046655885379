import React, { Component } from 'react';
import './MyCart.css';
import Color from '../Config/Color';
import delete1 from "../../images/icons/delete.png";
import { StoreObjectInStorage } from '../Config/Utils';
import Constants from '../Config/Constants';
import CartList from './CartList';
import Collapsible from 'react-collapsible';

class CartStoreList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            storeList: props.data,
        }
    }

    componentDidMount() {
    }

    setPlazaTotalAmount = (obj) => {
        console.log("amount store ", obj);

        var updateStoreID = "";
        var updateItemID = "";
        console.log("data", this.state.storeList)
        var list1 = this.state.storeList.map(curr => {
            var storeItemList = curr.Items
            var list2 = storeItemList.map(function (objitem, index2) {
                if (obj.ItemId === objitem.ItemId) {
                    updateStoreID = curr.ApplicationId;
                    updateItemID = obj.ItemId
                    objitem = obj
                    return { ...objitem }
                }
                else {
                    return { ...objitem }
                }
            })
            return { ...curr, "Items": list2 }
        })

        console.log("amount store1 ", list1);
        this.setState({ storeList: list1 }, function () {
            // var updatedItem = this.state.itemList.find((o) => o.ItemId === item.ItemId )
            // console.log("updatedItem ",updatedItem)
            // this.props.setPlazaTotal(updatedItem);
            this.props.setPlazaTotal(obj, updateStoreID, updateItemID);
        })
    }
    updateItemList = (deleteItemId) => {
        // alert(deleteItemId)
        var updateStoreID = "";
        var list3 = []
        // var updateItemID = "";
        console.log("data", this.state.storeList)
        var list1 = this.state.storeList.map(curr => {
            var storeItemList = curr.Items
            var list2 = storeItemList.map(function (objitem, index2) {
                if (deleteItemId === objitem.ItemId) {
                    updateStoreID = curr.ApplicationId;
                    list3 = curr.Items.filter(e1 => e1.ItemId !== deleteItemId)
                }
                else {
                    list3 = curr.Items;
                }
            })

            // alert(JSON.stringify(list3))
            return { ...curr, "Items": list3 }

        })
        console.log("amount store1 ", list1);
        this.setState({ storeList: list1 }, function () {
            // var updatedItem = this.state.itemList.find((o) => o.ItemId === item.ItemId )
            // console.log("updatedItem ",updatedItem)
            // this.props.setPlazaTotal(updatedItem);
            this.props.deleteStoreItem(deleteItemId, updateStoreID);
        })
    }

    renderStoreItem(storeItemList){
        return storeItemList
        .map(function (item, index) {
            // var singleItemPrice = item.TotalItemPrice / item.ItemQuantity
            var total = item.TotalItemPrice

            // var total = item.TotalItemPrice
            total = (Math.round(total * 100) / 100).toFixed(2);
            return (
                <React.Fragment key={item.ItemID}>
                    <div className="col-sm-12" style={{ backgroundColor: Color.WHITE, borderBottom: "1px solid" + Color.GREY }}  >
                        <div className="row" style={{ paddingTop: "5px" }} >
                            <img src={item.ItemLogo} style={{ paddingLeft: "10px", height: "40px", width: "20%", marginRight: "5px", width: "40px" }}></img>
                            <label style={{ fontSize: "15px", width: "60%", paddingTop: "5px" }}> {item.ItemName} </label>
                            <span style={{ marginLeft: "auto", width: "20%", paddingTop: "5px", marginRight: "10px" }}>
                                <label style={{ fontSize: "15px", float: "right", color: Color.THEME_COLOR, }}>${total}</label>
                            </span>
                        </div>

                        <div className="row" >
                            <span style={{ float: "right", marginLeft: "auto", }}>
                                <label style={{ fontSize: "17px", color: Color.BLACK }} onClick={(value) => this.handleMinus(item)}> - </label>
                                <label style={{ fontSize: "17px", color: Color.BLACK, width: "40px", textAlign: "center" }}> {item.ItemQuantity}  </label>
                                <label style={{ fontSize: "17px", color: Color.BLACK, marginRight: "20px" }} onClick={(value) => this.handlePlus(item)} > + </label>
                                <img src={delete1} style={{ marginLeft: "10px", marginRight: "10px", height: "20px", width: "20px" }} onClick={(value) => this.openDeleteDialog(item)}></img>
                                {/* <i  class="fa fa-trash" style={{ marginLeft:"10px", marginRight:"10px", fontSize: "20px", color: Color.GREY,  }}></i> */}
                            </span>
                        </div>
                    </div>
                </React.Fragment>
            )
        }, this);
    }


    render() {
        let list = this.props.data
            .map(function (item, index) {
                var storeItemList = item.Items
                return (
                    <React.Fragment key={index}>
                        <div className="col-sm-12" style={{ backgroundColor: Color.THEME_COLOR, color: Color.WHITE }} >
                            {item.ApplicationName}
                        </div>
                        {this.renderStoreItem(storeItemList)}
                        {/* <CartList
                            data={storeItemList}
                            setPlazaTotal={this.setPlazaTotalAmount}
                            populateList={this.updateItemList}
                        >
                        </CartList> */}
                    </React.Fragment>
                )
            }, this);

        return (
            <div>
                { list}
            </div >
        );
    }
}

export default CartStoreList;