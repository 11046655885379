import React, { Component } from 'react';
import Constants from '../Config/Constants'
import Color from '../Config/Color';
import { withRouter } from 'react-router';
import { StoreObjectInStorage } from "../Config/Utils";
import RouteConstant from '../Config/RouteConstant';
import Checkbox from 'react-simple-checkbox';

var selectedList=[];

class ToppingItems extends Component {
    constructor(props) {
        super(props);
        this.state = {          
            checked:false,
            toppingItems : props.data,

            
        }

        this.handleCheck = this.handleCheck.bind(this);       
    }

    componentDidMount() {
        selectedList =  this.state.toppingItems.filter(obj => obj.checked === true);
    }

    handleCheck(data){       
        console.log(JSON.stringify(data))
        var toppingItems = [...this.state.toppingItems];
        var index = toppingItems.findIndex(obj => obj._id === data._id);
        //console.log(toppingItems[index].checked)
        if(!toppingItems[index].checked){
            toppingItems[index].checked = true;
            selectedList.push(data)
            console.log("selectedList1", selectedList)
        }else{
            toppingItems[index].checked = false;
            selectedList.splice(selectedList.indexOf(data), 1);
            console.log("selectedList1 1", selectedList)
        }
       
        this.setState({ toppingItems }, function () {
            //console.log("toppingItems ",this.state.toppingItems)
            var selectedItems = toppingItems.filter(obj => obj.checked === true);
            var currentItem = {
                currentObj:data,
                list:this.state.toppingItems,
                selectedList:selectedList
            } 
           // console.log("selected ", selectedItems)
            this.props.setTopping(currentItem)            
        });
    }


    // handleCheck(data){
       
    //     console.log(JSON.stringify(data))

    //     var toppingItems = [...this.state.toppingItems];
    //     var index = toppingItems.findIndex(obj => obj._id === data._id);
    //     console.log(toppingItems[index].checked)
    //     if(!toppingItems[index].checked){
    //         toppingItems[index].checked = true;
    //     }else{
    //         toppingItems[index].checked = false;
    //     }

    //     this.setState({ toppingItems }, function () {
    //         // console.log("toppingItems ", this.state.toppingItems)
    //         var selectedItems = toppingItems.filter(obj => obj.checked === true);           
    //         console.log("selected ", selectedItems)
    //         this.props.setTopping1(selectedItems)
    //     });
    // }

    render() {
        let list = this.props.data
            .map(function (item, index) {             
                return (
                    <React.Fragment key={index} >
                        <div className="col-sm-12" style={{backgroundColor:Color.WHITE,  height:"40px", color:Color.BLACK, borderBottom: "1px solid" + Color.GREY}} >
                            <div className="row" style={{padding: "5px" }} >
                                <Checkbox
                                    name={item._id}
                                    style={{marginTop:"0px", paddinTop:"0px"}}
                                    color={Color.THEME_COLOR}
                                    size={2}
                                    tickSize={2}
                                    checked={item.checked}
                                    delay={0.1}
                                    //value={this.state.checked}
                                    //onClick={(value) => this.handleItemClick(item)}
                                    onChange={(value) => this.handleCheck(item)}>
                                 </Checkbox>
                                <label style={{ marginLeft:"10px", fontSize: "15px"}}>{item.ToppingItem}</label>
                                <span style={{ marginLeft: "auto"}}>
                                    <label style={{ fontSize: "15px", float: "right", marginRight: "5px" }}>${(Math.round(item.Price * 100) / 100).toFixed(2)}</label>                                    
                                </span>
                            </div>
                        </div>                       
                    </React.Fragment>
                )
            }, this);
        return (
            <div> 
                {list}
            </div>
        );
    }
}

export default withRouter(ToppingItems);