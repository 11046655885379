import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Color from '../../Config/Color';
import Constants, { currencyInfo } from '../../Config/Constants';
import './PlazaOrderWebStoreItems.css';
import { RetriveObjectFromStorage, StoreObjectInStorage } from "../../Config/Utils";
import RouteConstant from '../../Config/RouteConstant';

class ItemListOrderWeb extends Component {
    constructor(props) {
        super(props);
        this.state = {
            storeData: props.storeData,
            categoryID: props.categoryID,
            itemList: []
        }

        this.handleItemClick = this.handleItemClick.bind(this);
    }

    componentDidMount() {

    }

    handleItemClick(data) {
        console.log(JSON.stringify(data));
        StoreObjectInStorage(Constants.Obj_STORE_ITEMS, data);
        this.props.history.push({
            pathname: RouteConstant.OrderWebAddtoCart
        });
    }

    createCartItemObject(data) {
        var objPlazaList = this.state.plazaList;
        console.log("1 ", objPlazaList)
        var plazalist = objPlazaList[0].Plaza;

        var objItem = {};
        objItem["CategoryID"] = data;
        objItem["Instruction"] = "";
        objItem["ItemDescription"] = "Product Description";
        objItem["ItemId"] = "";
        objItem["ItemName"] = "";
        objItem["ItemPrice"] = "";
        objItem["ItemQuantity"] = "";
        objItem["ItemTax"] = "";
        objItem["ItemType"] = "";
        objItem["ItemWeight"] = "";
        objItem["TotalItemPrice"] = "";
        objItem["ToppingItems"] = [];
        objItem["specialdeliverycharges"] = ""

        this.state.itemList.push(objItem);
        plazalist["Stores"] = this.state.storeList;
    }

    render() {
        var currencySymbole = ""
        var countryFees = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES);
        if (countryFees) {
            currencySymbole = (countryFees[currencyInfo.CountryCode] ?
                "(" + countryFees[currencyInfo.CountryCode] + ") " : "") + countryFees.Symbol;

        }
        let list = this.props.data
            .map(function (item, index) {
                return (
                    <React.Fragment >
                        <div className="col-sm-12" style={{ backgroundColor: Color.WHITE, color: Color.BLACK, borderBottom: "1px solid" + Color.GREY, padding: '0px' }} onClick={(value) => this.handleItemClick(item)}>
                            <div style={{ padding: "5px", display: 'flex' }} >
                                <label style={{ fontSize: "15px", wordBreak: 'break-all', paddingRight: '5px', marginBottom: '0px' }}>{item.ItemName}</label>
                                <span style={{ marginLeft: "auto" }}>
                                    <label style={{ fontSize: "15px", float: "right", marginRight: "5px", marginBottom: '0px' }}>{currencySymbole} {Number(item.ItemPrice).toFixed(2)}</label>
                                </span>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }, this);
        return (
            <div>
                {list}
            </div>
        );
    }
}

export default withRouter(ItemListOrderWeb);