import React, { Component } from 'react';
import './Checkout.css';
import Color from '../Config/Color';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { RadioGroup, Radio } from 'react-radio-group'
import moment from "moment";

const MenuItem = ({ text, selected }) => {
    var colorCode;
    if (selected) {
        colorCode = Color.THEME_COLOR
    }
    else {
        colorCode = Color.GREY
    }
    return <div className={`menu-item ${selected ? 'active' : ''}`} style={{ width: "60px", margin: "5px", height: "80px", marginBottom: "5px", border: "1px solid" + colorCode, borderRadius: "5px" }}>
        <label style={{ width: "40px", whiteSpace: "break-spaces", height: "50px", fontSize: "12px", margin: "5px" }}>{text}</label>
    </div>;
};


export const Menu = (list, selected) =>
    list.map(function (item, index) {
        return <MenuItem text={item} key={index} selected={selected} />;
    });

const selected = '0';



class PickupTimeDay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateArray: [],

            pickUpTimes: props.pickUpTimesList,
            selectedValue: "",
            selected: 0,
            storeid: props.storeid,
            plazaid: props.plazaid
        }
        this.handleChangeRadioBtn = this.handleChangeRadioBtn.bind(this);
    }

    componentDidMount() {
        this.setState({ selectedValue: this.props.pickUpTimesList[0] ? this.props.pickUpTimesList[0].PickupTime : "" });
        this.setDateArray();
    }

    setDateArray() {
        const current = moment()
        let n = 10
        var resultDates = [];
        while (n > 0) {
            resultDates.push(current.format("DD MMM YYYY"))
            current.add(1, "day")
            n--;
        }
        this.setState({ dateArray: resultDates })
    }

    onSelect = key => {
        var date = this.state.dateArray[key]
        this.setState({ selected: key })
        this.props.setPickupDay(date, this.state.storeid, this.state.plazaid)
    }

    handleChangeRadioBtn(value) {
        this.setState({ selectedValue: value });
        var obj = this.state.pickUpTimes.find((o) => o.PickupTime === value)
        this.props.setPickupTime(obj.PickupTime, this.state.storeid, this.state.plazaid)
    }

    render() {
        const { selectedValue } = this.state

        this.menuItems = Menu(this.state.dateArray, selected);
        const menu = this.menuItems;

        let list = this.props.pickUpTimesList
            .map(function (item, index) {
                return (
                    <React.Fragment key={index}>
                        <div style={{ height: "30px", borderBottom: "1px solid" + Color.GREY }}>
                            <Radio value={item.PickupTime} style={{ paddingTop: "5px", color: Color.BLACK }} />&nbsp;{item.PickupTime}<br />
                        </div>
                    </React.Fragment>
                )
            }, this);

        return (
            <React.Fragment>
                <div className="col-sm-12" style={{ borderBottom: "1px solid" + Color.GREY, marginBottom: "5px", border: "1px solid" + Color.THEME_COLOR, borderRadius: "5px" }}>
                    <ScrollMenu
                        data={menu}
                        selected={selected}
                        onSelect={this.onSelect}
                        alignCenter={false}
                        innerWrapperStyle={{ display: "flex" }}
                    />
                </div>
                <div>
                    <RadioGroup id={this.state.storeid} selectedValue={this.state.selectedValue} onChange={this.handleChangeRadioBtn}>
                        {list}
                    </RadioGroup>
                </div>
            </React.Fragment>
        );
    }
}

export default PickupTimeDay;