import Badge from '@uiw/react-badge'; // https://www.npmjs.com/package/@uiw/react-badge
import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import NoPriview from '../../../../src/images/nopreviewp.png';
import NavConFirmAlert from "../../Alert/NavConFirmAlert";
import Color from "../../Config/Color";
import Constants from '../../Config/Constants';
import Messages from '../../Config/Messages';
import RouteConstant from "../../Config/RouteConstant";
import { RemoveDataInStorage, RetriveDataFromStorage, RetriveObjectFromStorage } from "../../Config/Utils";
import SideMenuOrderWeb from '../../OrderWeb/SideMenu/SideMenuOrderWeb';
import "./HeaderOrderWeb.css";

class HeaderOrderWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: true,
      headerTitle: "",
      hasHeaderImage: false,
      headerImage: "",
      hasHamburgerDisplay: false,

      visibleIconCart: "hidden",
      visibleIconBackBtn: "visible",

      noOfItemsInCart: props.countItemCart,
      itemCount: 0,
      isnavalert: false,
      navObj: {}
    };

    this.closeNav = this.closeNav.bind(this);
    this.openNav = this.openNav.bind(this);
    this.goBack = this.goBack.bind(this);
    this.handleCart = this.handleCart.bind(this);
  }

  componentDidMount() {

    /* For Public Page */
    /* var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
    if (!authToken) {
      this.props.history.push({
        pathname: RouteConstant.Default,
      });
    } */

    var items = 0;
    var objCart = RetriveObjectFromStorage(Constants.Obj_CART, '');
    if (objCart && objCart.length > 0) {
      objCart.map(function (item, index) {
        items = items + item.Items.length;
      });
    }

    this.setState({ itemCount: items });

    var cartObj = {
      title: Messages.cart_Title,
      bodyContent: Messages.cart_Messg
    }

    var path = window.location.pathname;
    const { id } = this.props.match.params;

    if (path === RouteConstant.Login) {

    }
    else if (path === RouteConstant.ForgotPassword) {

    }
    else if (path === RouteConstant.Register) {
      /* this.setState({ headerTitle: Constants.Title_PlazaList, itemCount: 0 })
      this.setState({ visibleIconCart: "visible" }); */
    }
    else if (path === RouteConstant.Dashboard) {
      /* this.setState({ visibleIconCart: "visible" });
      this.setState({ hasHamburgerDisplay: true }); */
    }
    else if (path === RouteConstant.PlazaStore) {
      /* var obj = RetriveObjectFromStorage(Constants.Obj_PLAZA)
      this.setState({ headerImage: obj ? obj.Imageurl : "" })
      this.setState({ visibleIconCart: "visible" });
      this.setState({ hasHeaderImage: true });
      this.setState({ hasHamburgerDisplay: true, isnavalert: items > 0, navObj: cartObj }); */
    }
    else if (path === RouteConstant.Appointment) {
      /* this.setState({ visibleIconCart: "visible" });
      this.setState({ hasHamburgerDisplay: true }); */
    }
    else if (path === RouteConstant.MyAppointment) {
      /* this.setState({ visibleIconCart: "visible", isnavalert: items > 0, navObj: cartObj });
      this.setState({ hasHamburgerDisplay: true }); */
    }
    else if (path === RouteConstant.PlazaStoreItems) {
      /* this.setState({ hasHeaderImage: false, isnavalert: items > 0, navObj: cartObj });
      this.setState({ visibleIconCart: "visible" });
      this.setState({ hasHamburgerDisplay: true }); */
    }
    else if (path === RouteConstant.AddToppings) {
      /* this.setState({ visibleIconCart: "visible" }); */
    }
    else if (path === RouteConstant.AddToCart) {
      /* this.setState({ visibleIconCart: "visible", isnavalert: items > 0, navObj: cartObj });
      this.setState({ hasHamburgerDisplay: true }); */
    }
    else if (path === RouteConstant.TryIt) {
      /* this.setState({ isnavalert: items > 0, navObj: cartObj }); */
    }
    else if (path === RouteConstant.Country) {
      /* this.setState({ visibleIconCart: "visible" });
      this.setState({ hasHamburgerDisplay: true }); */
    }
    else if (path === RouteConstant.MyCart) {
      /* this.setState({ visibleIconCart: "visible", isnavalert: items > 0, navObj: cartObj });
      this.setState({ hasHamburgerDisplay: true }); */
    }
    else if (path === RouteConstant.Checkout) {
      /* this.setState({ visibleIconCart: "visible", isnavalert: items > 0, navObj: cartObj });
      this.setState({ hasHamburgerDisplay: true }); */
    }
    else if (path === RouteConstant.Payment) {
      /* this.setState({ visibleIconCart: "visible", isnavalert: items > 0, navObj: cartObj });
      this.setState({ hasHamburgerDisplay: true }); */
    }
    else if (path === RouteConstant.AddAddress) {
      /* this.setState({ visibleIconCart: "visible", isnavalert: items > 0, navObj: cartObj });
      this.setState({ hasHamburgerDisplay: true }); */
    }
    else if (path === RouteConstant.NotificationDetail) {
      /* this.setState({ visibleIconCart: "visible", isnavalert: items > 0, navObj: cartObj });
      this.setState({ hasHamburgerDisplay: true }); */
    }
    else if (path === RouteConstant.EditProfile) {
      /* this.setState({ visibleIconCart: "visible", isnavalert: items > 0, navObj: cartObj });
      this.setState({ hasHamburgerDisplay: true }); */
    }
    else if (path === RouteConstant.OrderDetail) {
      /* this.setState({ visibleIconCart: "visible", isnavalert: items > 0, navObj: cartObj });
      this.setState({ hasHamburgerDisplay: true }); */
    }
    else if (path === RouteConstant.CurbsideOrder) {
      /* this.setState({ visibleIconCart: "visible", isnavalert: items > 0, navObj: cartObj });
      this.setState({ hasHamburgerDisplay: true }); */
    }
    else if (path === RouteConstant.PlazaStoreItemsDetailShopify) {
      /* this.setState({ visibleIconCart: "visible" });
      this.setState({ hasHamburgerDisplay: true }); */
    }
    else if (path == RouteConstant.PlazaStoreItemsShopify) {
      /* this.setState({ visibleIconCart: "visible", isnavalert: items > 0, navObj: cartObj });
      this.setState({ hasHamburgerDisplay: true }); */
    }
    else if (path == RouteConstant.OrderWebNavigation + "/" + id) {
      this.setState({ hasHeaderImage: false, isnavalert: items > 0, navObj: cartObj });
      this.setState({ visibleIconCart: "visible" });
      this.setState({ hasHamburgerDisplay: true });
    } else if (path === RouteConstant.OrderWebAddtoCart) {
      this.setState({ visibleIconCart: "visible", isnavalert: items > 0, navObj: cartObj });
      this.setState({ hasHamburgerDisplay: true });
    } else if (path === RouteConstant.OrderWebMyCart) {
      this.setState({ visibleIconCart: "visible", isnavalert: items > 0, navObj: cartObj });
      this.setState({ hasHamburgerDisplay: true });
    } else if (path === RouteConstant.OrderWebTryIt) {
      this.setState({ isnavalert: items > 0, navObj: cartObj });
    }
    else if (path === RouteConstant.OrderWebCheckout) {
      this.setState({ visibleIconCart: "visible", isnavalert: items > 0, navObj: cartObj });
      this.setState({ hasHamburgerDisplay: true });
    }
    else if (path === RouteConstant.OrderWebPayment) {
      this.setState({ visibleIconCart: "visible", isnavalert: items > 0, navObj: cartObj });
      this.setState({ hasHamburgerDisplay: true });
    }
    else if (path === RouteConstant.OrderWebAddress) {
      this.setState({ visibleIconCart: "visible", isnavalert: items > 0, navObj: cartObj });
      this.setState({ hasHamburgerDisplay: true });
    }
    else if (path === RouteConstant.OrderWebPrivacyPolicy) {
      /* this.setState({ visibleIconCart: "visible", isnavalert: items > 0, navObj: cartObj });
      this.setState({ hasHamburgerDisplay: true }); */
    }
    else if (path === RouteConstant.OrderWebContactUs) {
      /* this.setState({ visibleIconCart: "visible", isnavalert: items > 0, navObj: cartObj });
      this.setState({ hasHamburgerDisplay: true });*/
    }
    else if (path === RouteConstant.OrderWebAboutUs) {
      /* this.setState({ visibleIconCart: "visible", isnavalert: items > 0, navObj: cartObj });
      this.setState({ hasHamburgerDisplay: true }); */
    }
    else if (path === RouteConstant.OrderWebHelpAndSupport) {
      /* this.setState({ visibleIconCart: "visible", isnavalert: items > 0, navObj: cartObj });
      this.setState({ hasHamburgerDisplay: true }); */
    }
    else if (path === RouteConstant.OrderWebTermsOfService) {
      /* this.setState({ visibleIconCart: "visible", isnavalert: items > 0, navObj: cartObj });
      this.setState({ hasHamburgerDisplay: true }); */
    } else {

    }

    /* var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
    if (authToken === '' || authToken === null) {
      this.setState({ isLoggedIn: false });
    } else {
      this.setState({ isLoggedIn: true });
    } */

    var cartItems = RetriveDataFromStorage(Constants.Obj_CART_ITEMS, '');
    if (cartItems && cartItems.length > 0) {
      if (cartItems && cartItems.length > 0) {
        this.setState({ noOfItemsInCart: cartItems.length });
      } else {
        this.setState({ noOfItemsInCart: '0' });
      }
    }
  }

  setHeaderTitle = title => {
    this.setState({ headerTitle: Constants.Title_CurbsideOrders })
  }

  closeNav() {
    window.closeNav();
  }

  openNav() {
    window.openNav();
  }

  goBack() {
    this.props.history.goBack();
  }

  goDashboard() {
    /* this.props.history.push({ pathname: RouteConstant.Dashboard }) */
  }

  handleCart() {
    this.props.history.push({
      pathname: RouteConstant.OrderWebMyCart,
      countCartItem: this.state.noOfItemsInCart
    })
  }

  popupConform() {
    RemoveDataInStorage(Constants.Obj_CART)
    RemoveDataInStorage(Constants.finelObj_BeforePayemt)
  }

  render() {
    const { isLoggedIn, hasHeaderImage, headerImage, hasHamburgerDisplay, noOfItemsInCart } = this.state;
    const cartCont = this.props.countItemCart ? (Number(this.props.countItemCart) ? this.props.countItemCart : 0) : this.state.itemCount
    return (
      <div>
        <header className="header" >
          <div id="snackbar"></div>
          <div id="mySidenav" className="sidenav">
            <a
              href="javascript:void(0)"
              className="closebtn"
              onClick={this.closeNav}>
              &times;
            </a>
            <SideMenuOrderWeb onrefresh={this.props.onrefresh} />
          </div>

          <div className="top-bar" style={{ backgroundColor: Color.THEME_COLOR }}>
            <div className="col-sm-12">
              <div className="row">
                {/* <NavConFirmAlert
                  isBlocked={this.state.isnavalert}
                  conformFun={this.popupConform}
                  {...this.state.navObj} /> */}

                {isLoggedIn && hasHamburgerDisplay ?
                  <a className="nav-link" onClick={this.openNav}>
                    <i class="fa fa-bars" aria-hidden="true" style={{ float: "left", fontSize: "30px", color: Color.WHITE, cursor: "pointer" }}></i>
                  </a>
                  : <a className="nav-link"  >
                    {/* <i class="fas fa-arrow-left" style={{ float: "left", fontSize: "30px", color: Color.WHITE, cursor: "pointer" }} onClick={this.goBack}></i> */}
                  </a>
                }

                {hasHeaderImage ?
                  <a className="navbar-brand" style={{ marginLeft: "auto", marginRight: "auto", cursor: "pointer" }}>
                    <center>
                      <img
                        style={{ height: "50px", width: "50px", borderRadius: "10px" }}
                        src={headerImage ? headerImage : NoPriview}
                        onError={(e) => (e.target.onerror = null, e.target.src = NoPriview)}
                        onClick={() => this.goDashboard()}
                        className="iconDetails" />
                    </center>
                  </a>
                  :
                  <a className="navbar-brand header-title" style={{ marginLeft: "auto", marginRight: "auto", cursor: "pointer" }}>
                    <label
                      id="headername"
                      className="header-name"
                      style={{ marginLeft: "0px", overflow: "hidden", textOverflow: "ellipsis", fontSize: "23px", paddingTop: "5px", fontWeight: "bold", color: Color.WHITE }}>
                      {this.props.setHeaderTitle}
                    </label>
                  </a>
                }

                <a className="nav-link" id="headerCart" style={{ visibility: this.state.visibleIconCart, float: "right", cursor: "pointer" }}>
                  <Badge
                    max={999}
                    count={this.props.countItemCart ? (Number(this.props.countItemCart) ? this.props.countItemCart : 0) : this.state.itemCount}
                    style={{
                      padding: "1px",
                      backgroundColor: Color.BLACK,
                      marginLeft: "30px", borderRadius: "50px", height: "20px", width: cartCont < 100 ? "20px" : "30px"
                    }}>
                    <i class="fas fa-shopping-cart" aria-hidden="true"
                      style={{ color: Color.WHITE, fontSize: "25px", float: "right" }}
                      onClick={this.handleCart}></i>
                  </Badge>
                </a>
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  }
}
export default withRouter(HeaderOrderWeb);
