import React, { Component } from 'react';

import Constants from '../Config/Constants'
import { RetriveDataFromStorage, StoreObjectInStorage ,RemoveDataInStorage} from "../Config/Utils";
import Messages from "../Config/Messages";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import Header from '../Header/Header';
import Color from '../Config/Color';
import RouteConstant from '../Config/RouteConstant';
import plazablankIcon from "../../images/icons/plazablank.png";

import { GetData } from "../../services/GetData";
import { GET_TENANT_API, GET_USERPROFILE_API } from '../Config/Config.json'
import LoadingOverlay from 'react-loading-overlay';
import './PlazaList.css'

class PlazaList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hideEmptyPlazaView: false,
            isActive: false,
            plazaList: []
        }
        this.handleRowClick = this.handleRowClick.bind(this);
    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        console.log("authToken", authToken);
        RemoveDataInStorage(Constants.Obj_CART)
        RemoveDataInStorage(Constants.finelObj_BeforePayemt)
        this.getUserDetail(authToken)
        this.getPlazaByAuthToken(authToken);
    }

    getPlazaByAuthToken(authToken) {
        this.setState({ isActive: true });
        var API = GET_TENANT_API;
        GetData(API, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData) {
                        const x = responseData;
                        const { suggestedplaza, plaza, ...rest } = x;
                        //console.log(rest)
                        StoreObjectInStorage(Constants.Obj_COUNTRY_FEES, rest);

                        var list = [];
                        if (responseData.suggestedplaza && responseData.suggestedplaza.length > 0) {
                            list = responseData.suggestedplaza;
                        }

                        if (responseData.plaza && responseData.plaza.length > 0) {
                            list = list.concat(responseData.plaza)
                            //  this.setState({ hideEmptyPlazaView: true });
                            // this.setState({ isLoadingActive: false });
                        }
                        else {
                            this.setState({ isLoadingActive: false });
                            this.setState({ hideEmptyPlazaView: false });
                        }
                        this.setState({ plazaList: list });
                    }

                }
                else {
                    this.setState({ isLoadingActive: false });
                    this.setState({ hideEmptyPlazaView: false });
                    alert(Messages.NO_PLAZA);
                }
                this.setState({ isActive: false });
            });
    }

    getUserDetail(authToken) {
        this.setState({ isLoadingActive: true });
        var API = GET_USERPROFILE_API;
        GetData(API, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    StoreObjectInStorage(Constants.Obj_USER, responseData)
                    this.setState({ isLoadingActive: false });
                }
                else {
                    this.setState({ isLoadingActive: false });
                    this.setState({ hideEmptyPlazaView: false });
                    alert(Messages.NO_PLAZA);

                }
            });
    }

    handleRowClick(data) {
        StoreObjectInStorage(Constants.Obj_PLAZA, data)
        this.props.history.push({
            pathname: RouteConstant.PlazaStore,
        });
    }

    render() {
        const { plazaList, hideEmptyPlazaView } = this.state;
        const columns = [
            {
                maxWidth: "132px",
                cell: row => <img style={{ height: "70px", width: "70px", padding: "5px", borderRadius: "10px", cursor: "pointer" }} src={row.Imageurl} className="iconDetails" onClick={() => this.handleRowClick(row)} />,
                right: true
            },
            {
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px", cursor: "pointer" }} onClick={() => this.handleRowClick(row)}>{row.CompanyName}</div>
            },
        ];

        return (

            <div style={{ backgroundColor: Color.WHITE }}>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Constants.LoadingMssg}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className=""
                >
                    <Header setHeaderTitle={Constants.Title_PlazaList} />
                    <section className="section" style={{ backgroundColor: Color.WHITE }}>
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                            <div className="reg_form_main">

                                {plazaList.length === 0
                                    ?
                                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }} >
                                        <div className="bottam_sing">
                                            <img src={plazablankIcon}></img>
                                            <p className="col-sm-12" for="FirstName">
                                                {Messages.NO_PLAZA}
                                            </p>
                                        </div>
                                    </div>
                                    :

                                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px", display: this.state.hideEmptyPlazaView }} >
                                        <DataTableExtensions
                                            data={plazaList}
                                            columns={columns}
                                            export={false}
                                            print={false}
                                            filter={true}
                                            filterHidden={false}
                                            filterPlaceholder={"Search for Plaza"}
                                            filterStyle={{ width: "100px" }}
                                        >
                                            <DataTable
                                                noHeader={true}
                                                noTableHead={true}
                                                //pagination
                                                onRowClicked={this.handleRowClick}
                                            />
                                        </DataTableExtensions>
                                    </div>
                                }
                            </div>
                        </div>
                        {/* </LoadingOverlay> */}
                    </section>
                </LoadingOverlay>
            </div>

        );
    }
}

export default PlazaList;