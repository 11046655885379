import React, { Component, PropTypes } from 'react';
import './PlazaStoreItems.css'
import Constants, { DEFAULTID, COMPANYCONST } from '../Config/Constants'
import { MainContext, RetriveDataFromStorage, RetriveObjectFromStorage } from "../Config/Utils";
import Header from '../Header/Header';
import Color from '../Config/Color';
import { GET_STOREITEM_API } from '../Config/Config.json'
import { GetData } from "../../services/GetData";
import Messages from '../Config/Messages';
import NoPriview from '../../../src/images/nopreviewp.png';
import NoPriviewBanner from '../../../src/images/nopreviewb.png';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import CategoryList from './CategoryList';
import LoadingOverlay from 'react-loading-overlay';

class PlazaStoreItems extends Component {
    constructor(props) {
        super(props);
        this.state = {

            storeItemList: [],
            storeData: {},
            isActive: false,

        }
    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        // var objPlaza = RetriveObjectFromStorage(Constants.Obj_PLAZA, '');
        var objStore = RetriveObjectFromStorage(Constants.Obj_STORE, '');

        if (objStore) {
            this.setState({ storeData: objStore })
            this.getStoreItemsAPI(authToken, DEFAULTID, objStore.ObjectId);
        }
    }

    getStoreItemsAPI(authToken, plazaID, storeID) {
        this.setState({ isActive: true });
        var API = GET_STOREITEM_API.replace("#STOREID#", storeID);
        var URL = API.replace("#TENANTID#", plazaID);
        console.log("API ", URL);
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData && responseData.length > 0) {
                        responseData.map(function (item, index) {
                            var itemlist = item.Items;
                            itemlist.map(function (item1, index1) {
                                item1.CategoryID = item.CategoryId;
                            })
                        });
                        this.setState({ storeItemList: responseData }, function () {
                            console.log("123 ", this.state.storeItemList)
                        });
                    }
                }
                else {
                    this.setState({ hideEmptyPlazaView: false });
                }
                this.setState({ isActive: false });
            });
    }

    render() {
        const { storeItemList, storeData } = this.state

        return (
            <div>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Constants.LoadingMssg}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="">
                    <Header setHeaderTitle={this.state.storeData.ApplicationName} />
                    <section className="section">

                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px", zIndex: "-1" }}>
                            <div className="reg_form_main offset-sm-3 col-sm-6">
                                <div style={{ position: "relative", width: "inherit", zIndex: "-1" }}>
                                    {/* , height: "150px",  */}
                                    <img src={storeData.AppBannerImage ? storeData.AppBannerImage : NoPriviewBanner} onError={(e) => (e.target.onerror = null, e.target.src = NoPriviewBanner)} style={{ width: "inherit", zIndex: "-1", borderRadius: '10px' }} />
                                    {/* height: "150px", */}
                                    <div className="overlay" style={{ marginBottom: '0px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', backgroundColor: 'rgba(0,0,0,0.6)' }}>
                                        <div style={{ width: "13%", height: "inherit", left: "0%", position: "absolute" }}>
                                            <img src={storeData.AppLogo ? storeData.AppLogo : NoPriview} onError={(e) => (e.target.onerror = null, e.target.src = NoPriview)} style={{ width: "35px", height: "35px", margin: "5px", borderRadius: "5px" }} />
                                        </div>
                                        <div style={{ width: "87%", height: "inherit", position: "absolute", right: "0%" }} >
                                            <div className="row" style={{ marginLeft: "0px", height: "70%", width: '100%', marginTop: "4px" }}>
                                                <text style={{ lineHeight: "1.5", fontSize: "12px", width: "inherit", height: "100%", color: Color.WHITE, maxLines: "2px", textOverflow: "ellipsis", overflow: "hidden", }}>
                                                    {storeData.AppAddress}
                                                </text>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="reg_form_main">
                                {/*<Accordion allowZeroExpanded={true} style={{ borderColor: 'white', paddingLeft: '10px', paddingRight: '10px' }}>*/}
                                <CategoryList
                                    storeData={storeData}
                                    data={storeItemList}></CategoryList>
                                {/* </Accordion>*/}
                            </div>

                            <div class="modal" id="deleteModal">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <label>{COMPANYCONST.BREWERSNEARME}</label>
                                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                                        </div>

                                        <div class="modal-body">
                                            <label>Are you sure you want to remove all the items from the cart?</label>
                                        </div>
                                        <div class="modal-footer">
                                            <label
                                                style={{ color: Color.THEME_COLOR }}
                                                for="step5"
                                                id="continue-step5"
                                                data-dismiss="modal"
                                            > No</label>
                                            <label
                                                style={{ float: "center", borderRadius: "50px", marginBottom: "10px" }}
                                                for="step5"
                                                id="continue-step5"
                                                //onClick={this.handlePickupDialog}
                                                className="btn btn-done"
                                            > Yes</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </LoadingOverlay>
            </div>
        );
    }
}

export default PlazaStoreItems;