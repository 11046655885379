import React, { Component, PropTypes } from 'react';
import './PlazaStoreItemsDetailShopify.css'
import Constants, { currencyInfo, COMPANYCONST } from '../../Config/Constants'
import { MainContext, RetriveDataFromStorage, RetriveObjectFromStorage, StoreObjectInStorage } from "../../Config/Utils";
import Header from '../../Header/Header';
import Color from '../../Config/Color';
import { GetData } from "../../../services/GetData";
import Messages from '../../Config/Messages';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
// import CategoryList from './CategoryListShopify';
import LoadingOverlay from 'react-loading-overlay';
import { product } from './Product.json'

const ProductType = {
    DefaultTitle: "Default Title",
    Title: "Title"
}

const products = product

class PlazaStoreItemsShopify extends Component {
    constructor(props) {
        super(props);
        this.state = {

            plazaData: null,
            storeData: null,
            isActive: false,
            finalCart: [],
            productObj: null,

            productPrice: 0.00,
            productQuntity: 1,
            optionObj1: null,
            optionObj2: null,
            optionObj3: null,
            selectedOption1: "",
            selectedOption2: "",
            selectedOption3: "",

            imageObj: null,
            imagearr: [],
            variantsList: [],
            selectedVariant: null,

            countCartItem: 0

        }
        this.handleMinus = this.handleMinus.bind(this)
        this.handlePlus = this.handlePlus.bind(this)
        this.handleAddToCart = this.handleAddToCart.bind(this)
    }


    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);

        var objPlaza = RetriveObjectFromStorage(Constants.Obj_PLAZA, '');
        var objStore = RetriveObjectFromStorage(Constants.Obj_STORE, '');

        var globalFinalCart = RetriveObjectFromStorage(Constants.Obj_CART, '')
        if (globalFinalCart) {
            this.setState({ finalCart: globalFinalCart })
            this.setHeaderItemCount(globalFinalCart)

        }

        if (objPlaza && objStore) {
            this.setState({
                storeData: objStore,
                plazaData: objPlaza
            })
        }

        const productObj = RetriveObjectFromStorage(Constants.Obj_STORE_ITEMS)
        if (productObj) {
            this.autopopulated(globalFinalCart, objPlaza, objStore, productObj)
        }


    }

    autopopulated(cartArray, plazaData, storeData, productObj) {
        cartArray = cartArray ? cartArray : []
        var itemCartObj = null
        if (plazaData && storeData && productObj) {
            cartArray.map(e => {
                if (e.PlazaId == plazaData._id) {
                    e.Stores.map(storObj => {
                        if (storObj.ObjectId == storeData.ObjectId) {
                            storObj.Items.map(itemObj => {
                                if (itemObj.ItemId == productObj.id) {
                                    itemCartObj = itemObj
                                }
                            })
                        }
                    })
                }
            })
        }

        this.setState({
            productObj: productObj,
            imagearr: productObj.images,
            variantsList: productObj.variants
        })
        if (itemCartObj) {
            var imageObjCart = productObj.images.filter(e => e.src == itemCartObj.ItemImage)[0]
            var varierntCart = productObj.variants.filter(e => e.id == itemCartObj.variant_id)[0]
            this.setState({
                imageObj: imageObjCart ? imageObjCart : productObj.image,
                selectedVariant: varierntCart ? varierntCart : productObj.variants[0],
                productQuntity: itemCartObj.ItemQuantity
            })
            if (varierntCart && (varierntCart.option1 !== ProductType.DefaultTitle)) {
                this.setState({
                    selectedOption1: varierntCart.option1,
                    selectedOption2: varierntCart.option2,
                    selectedOption3: varierntCart.option3,
                    productPrice: varierntCart.price
                })
            }
            else {
                this.setState({
                    selectedOption1: "",
                    selectedOption2: "",
                    selectedOption3: "",
                    productPrice: productObj.variants[0].price
                })
            }
        }
        else {
            this.setState({
                imageObj: productObj.image,
                selectedVariant: productObj.variants[0]
            })
            if (productObj.variants[0].option1 !== ProductType.DefaultTitle) {
                this.setState({
                    selectedOption1: productObj.variants[0].option1,
                    selectedOption2: productObj.variants[0].option2,
                    selectedOption3: productObj.variants[0].option3,
                    productPrice: productObj.variants[0].price
                })
            }
            else {
                this.setState({
                    selectedOption1: "",
                    selectedOption2: "",
                    selectedOption3: "",
                    productPrice: productObj.variants[0].price
                })
            }
        }



        for (var n in productObj.options) {
            if (productObj.options[n].name !== ProductType.Title) {
                if (n == 0) {
                    this.setState({ optionObj1: productObj.options[n] })
                }
                else if (n == 1) {
                    this.setState({ optionObj2: productObj.options[n] })
                }
                else if (n == 2) {
                    this.setState({ optionObj3: productObj.options[n] })
                }
            }
        }
    }

    setHeaderItemCount(globalFinalCart) {
        var countItems = 0;
        if (globalFinalCart.length > 0) {
            globalFinalCart.map(itemPlaza => {
                var storelist = itemPlaza.Stores;
                storelist.map(itemStore => {
                    var itemlist = itemStore.Items;
                    countItems = countItems + itemlist.length;
                })
            })
            this.setState({ countCartItem: countItems })
        }
    }

    handleMinus(e) {
        if (this.state.productQuntity > 1) {
            this.setState({ productQuntity: this.state.productQuntity - 1 })
        }
    }

    handlePlus(e) {
        this.setState({ productQuntity: this.state.productQuntity + 1 })
    }

    handelImage(flag) {
        var index = -1
        if (!this.state.imageObj) {
            return
        }
        for (var i in this.state.imagearr) {
            if (this.state.imagearr[i].id == this.state.imageObj.id) {
                index = i
            }
        }
        if (flag == "Right") {
            index = Number(index) + 1
        }
        else if (flag == "Left") {
            index = Number(index) - 1
        }
        if (index < 0) {
            index = this.state.imagearr.length - 1
        }
        if (index > (this.state.imagearr.length - 1)) {
            index = 0
        }
        this.setState({ imageObj: this.state.imagearr[index] })

    }

    handelVariant(optionObj, value) {
        var matchString = ""
        if (optionObj.position == 1) {
            this.setState({ selectedOption1: value })
            matchString = this.variantString(value, this.state.selectedOption2, this.state.selectedOption3)
        }
        else if (optionObj.position == 2) {
            this.setState({ selectedOption2: value })
            matchString = this.variantString(this.state.selectedOption1, value, this.state.selectedOption3)
        }
        else if (optionObj.position == 3) {
            this.setState({ selectedOption3: value })
            matchString = this.variantString(this.state.selectedOption1, this.state.selectedOption2, value)
        }

        for (var obj of this.state.variantsList) {
            if (obj.title == matchString) {
                this.setState({
                    productPrice: obj.price,
                    selectedVariant: obj
                })
                var imageObjCart = this.state.imagearr.filter(e => e.id == obj.image_id)[0]
                this.setState({ imageObj: imageObjCart ? imageObjCart : this.state.imageObj })
                break;
            }
        }
    }

    variantString(selectedOption1, selectedOption2, selectedOption3) {
        var selectString = []
        if (selectedOption1) {
            selectString.push(selectedOption1)
        }
        if (selectedOption2) {
            selectString.push(selectedOption2)
        }
        if (selectedOption3) {
            selectString.push(selectedOption3)
        }
        return selectString.join(" / ")
    }

    handleAddToCart(e) {
        if (this.state.plazaData && this.state.storeData && this.state.productObj && this.state.selectedVariant) {
            this.createCartItemsObject(this.state.plazaData, this.state.storeData, this.state.productObj, this.state.selectedVariant)
        }
    }

    createCartItemsObject(plazaData, storeData, itemData, selectedVariant) {
        var finalCart = this.state.finalCart;
        var imageObjCart = this.state.imagearr.filter(e => e.id == selectedVariant.image_id)[0]
        if (finalCart.length > 0) {
            const plazaExist = finalCart.some(el => el.PlazaId === plazaData._id);
            // alert(plazaExist)
            if (!plazaExist) {
                console.log('plaza not exist')
                var objPlaza = {};
                objPlaza["PlazaId"] = plazaData._id;
                objPlaza["CompanyName"] = plazaData.CompanyName;
                objPlaza["Imageurl"] = plazaData.Imageurl;
                objPlaza["PlazaSubTotal"] = "";
                objPlaza["PlazaTipAmount"] = 0;
                objPlaza["PlazaTotal"] = "";
                objPlaza["ServiceFeeAmount"] = 0;

                var storeArr = [];
                var objStore = {};
                objStore["ObjectId"] = storeData.ObjectId;
                objStore["ApplicationId"] = storeData.ApplicationId;
                objStore["ApplicationName"] = storeData.ApplicationName;
                objStore["Deliverytimes"] = storeData.Deliverytimes ? storeData.Deliverytimes : [];
                objStore["Pickuptimes"] = storeData.Pickuptimes ? storeData.Pickuptimes : [];
                objStore["Taxes"] = storeData.Taxes;
                objStore["DeliveryDate"] = "";
                objStore["DeliveryTime"] = "";
                objStore["isshopifyintegration"] = storeData.isshopifyintegration // new key
                objStore["iswixintegration"] = storeData.iswixintegration // new key
                objStore["IsPickupEnable"] = storeData.IsPickupEnable // new key
                objStore["IsDeliveryEnable"] = storeData.IsDeliveryEnable // new key

                var itemArr = [];
                var objItem = {};
                objItem["CategoryID"] = "000000000000000000000000";
                objItem["Instruction"] = "";
                objItem["ItemDescription"] = "";
                objItem["ItemId"] = String(itemData.id);
                objItem["ItemName"] = itemData.title;
                objItem["ItemLogo"] = imageObjCart ? imageObjCart.src : (itemData.image ? itemData.image.src : null)
                objItem["ItemPrice"] = selectedVariant.price;
                objItem["ItemQuantity"] = this.state.productQuntity;
                objItem["ItemTax"] = 0.0;
                objItem["ItemType"] = selectedVariant.weight_unit;
                objItem["ItemWeight"] = selectedVariant.weight;
                //objItem["TotalItemPrice"] = (itemData.noOfItems * itemData.ItemPrice);
                objItem["IsSpecialDeliveryItem"] = false;
                objItem["specialdeliverycharges"] = 0.0

                objItem["TotalItemPrice"] = parseFloat(selectedVariant.price) * parseInt(this.state.productQuntity)
                objItem["ToppingItems"] = []
                objItem["AvailableQuantity"] = selectedVariant.inventory_quantity
                objItem["ContinueSelling"] = true
                objItem["variant_id"] = String(selectedVariant.id) // new key
                objItem["variantdetail"] = selectedVariant.title
                objItem['ItemImage'] = imageObjCart ? imageObjCart.src : (itemData.image ? itemData.image.src : null)

                //objItem["ToppingItems"] = itemData.ToppingCategory;
                // if(itemData.ToppingCategory.length > 0){
                //     objItem["ToppingItems"] = itemData.ToppingItems;
                // }else{
                //     objItem["ToppingItems"] = itemData.ToppingCategory;
                // }


                itemArr.push(objItem);
                objStore["Items"] = itemArr;
                storeArr.push(objStore)
                objPlaza["Stores"] = storeArr
                finalCart.push(objPlaza);
            }
            else {
                console.log('plaza already exist')
                const that = this
                finalCart.map(function (plazaObj, index1) {

                    if (plazaObj.PlazaId === plazaData._id) {
                        var plazaStore = plazaObj.Stores;
                        if (plazaStore.length > 0) {
                            plazaStore.map(function (storeObj, index1) {
                                var store = storeObj;
                                const storeExist = plazaStore.some(el => el.ObjectId === storeData.ObjectId);
                                if (!storeExist) {
                                    console.log('store not exist')
                                    var objStore = {};
                                    objStore["ObjectId"] = storeData.ObjectId;
                                    objStore["ApplicationId"] = storeData.ApplicationId;
                                    objStore["ApplicationName"] = storeData.ApplicationName;
                                    objStore["Deliverytimes"] = storeData.Deliverytimes ? storeData.Deliverytimes : [];
                                    objStore["Pickuptimes"] = storeData.Pickuptimes ? storeData.Pickuptimes : [];
                                    objStore["Taxes"] = storeData.Taxes;
                                    objStore["DeliveryDate"] = "";
                                    objStore["DeliveryTime"] = "";
                                    objStore["isshopifyintegration"] = storeData.isshopifyintegration // new key
                                    objStore["iswixintegration"] = storeData.iswixintegration // new key
                                    objStore["IsPickupEnable"] = storeData.IsPickupEnable // new key
                                    objStore["IsDeliveryEnable"] = storeData.IsDeliveryEnable // new key

                                    var arr = [];
                                    var objItem = {};
                                    objItem["CategoryID"] = "000000000000000000000000";
                                    objItem["Instruction"] = "";
                                    objItem["ItemDescription"] = "";
                                    objItem["ItemId"] = String(itemData.id);
                                    objItem["ItemName"] = itemData.title;
                                    objItem["ItemLogo"] = imageObjCart ? imageObjCart.src : (itemData.image ? itemData.image.src : null)
                                    objItem["ItemPrice"] = selectedVariant.price;
                                    objItem["ItemQuantity"] = that.state.productQuntity;
                                    objItem["ItemTax"] = 0.0;
                                    objItem["ItemType"] = selectedVariant.weight_unit;
                                    objItem["ItemWeight"] = selectedVariant.weight;
                                    //objItem["TotalItemPrice"] = (itemData.noOfItems * itemData.ItemPrice);
                                    objItem["IsSpecialDeliveryItem"] = false;
                                    objItem["specialdeliverycharges"] = 0.0

                                    objItem["TotalItemPrice"] = parseFloat(selectedVariant.price) * parseInt(that.state.productQuntity)
                                    objItem["ToppingItems"] = []
                                    objItem["AvailableQuantity"] = selectedVariant.inventory_quantity
                                    objItem["ContinueSelling"] = true
                                    objItem["variant_id"] = String(selectedVariant.id) // new key
                                    objItem["variantdetail"] = selectedVariant.title
                                    objItem['ItemImage'] = imageObjCart ? imageObjCart.src : (itemData.image ? itemData.image.src : null)

                                    //objItem["ToppingItems"] = itemData.ToppingCategory;
                                    // if (itemData.ToppingCategory.length > 0) {
                                    //     objItem["ToppingItems"] = itemData.ToppingItems;
                                    // } else {
                                    //     objItem["ToppingItems"] = itemData.ToppingCategory;
                                    // }

                                    arr.push(objItem)
                                    objStore["Items"] = arr;
                                    plazaStore.push(objStore);
                                }
                                else {
                                    console.log('store already exist')
                                    if (store.ObjectId === storeData.ObjectId) {
                                        var storeItems = store.Items
                                        if (store.Items.length > 0) {
                                            const itemExist = storeItems.some(el => el.ItemId == itemData.id);
                                            if (!itemExist) {
                                                console.log('item not exist')
                                                var objItem = {};
                                                objItem["CategoryID"] = "000000000000000000000000";
                                                objItem["Instruction"] = "";
                                                objItem["ItemDescription"] = "";
                                                objItem["ItemId"] = String(itemData.id);
                                                objItem["ItemName"] = itemData.title;
                                                objItem["ItemLogo"] = imageObjCart ? imageObjCart.src : (itemData.image ? itemData.image.src : null)
                                                objItem["ItemPrice"] = selectedVariant.price;
                                                objItem["ItemQuantity"] = that.state.productQuntity;
                                                objItem["ItemTax"] = 0.0;
                                                objItem["ItemType"] = selectedVariant.weight_unit;
                                                objItem["ItemWeight"] = selectedVariant.weight;
                                                //objItem["TotalItemPrice"] = (itemData.noOfItems * itemData.ItemPrice);
                                                objItem["IsSpecialDeliveryItem"] = false;
                                                objItem["specialdeliverycharges"] = 0.0

                                                objItem["TotalItemPrice"] = parseFloat(selectedVariant.price) * parseInt(that.state.productQuntity)
                                                objItem["ToppingItems"] = []
                                                objItem["AvailableQuantity"] = selectedVariant.inventory_quantity
                                                objItem["ContinueSelling"] = true
                                                objItem["variant_id"] = String(selectedVariant.id) // new key
                                                objItem["variantdetail"] = selectedVariant.title
                                                objItem['ItemImage'] = imageObjCart ? imageObjCart.src : (itemData.image ? itemData.image.src : null)

                                                // if(itemData.ToppingCategory.length > 0){
                                                //     if( itemData.SelectedToppingItems && itemData.SelectedToppingItems.length > 0)
                                                //     objItem["ToppingItems"] = itemData.SelectedToppingItems;
                                                // }else{
                                                //     objItem["ToppingItems"] = itemData.ToppingCategory;
                                                // }

                                                store.Items.push(objItem)
                                            }
                                            else {
                                                console.log('item already exist')
                                                // var obj = storeItems.find(el => el.ItemId === itemData.ItemID);
                                                // obj.ItemQuantity = itemData.noOfItems;
                                                // obj.TotalItemPrice = (itemData.noOfItems * itemData.ItemPrice);

                                                var data = storeItems.map((item, index) => {
                                                    if (item.ItemId == itemData.id) {
                                                        item.ItemQuantity = that.state.productQuntity;
                                                        item.TotalItemPrice = parseFloat(selectedVariant.price) * parseInt(that.state.productQuntity)
                                                        item.ToppingItems = []
                                                        item["ItemLogo"] = imageObjCart ? imageObjCart.src : (itemData.image ? itemData.image.src : null)
                                                        item.variant_id = selectedVariant.id
                                                        item["ItemPrice"] = selectedVariant.price;
                                                        item["ItemType"] = selectedVariant.weight_unit;
                                                        item["ItemWeight"] = selectedVariant.weight;
                                                        item["AvailableQuantity"] = selectedVariant.inventory_quantity
                                                        item["variantdetail"] = selectedVariant.title
                                                        item['ItemImage'] = imageObjCart ? imageObjCart.src : (itemData.image ? itemData.image.src : null)
                                                        return { ...item, }
                                                    }
                                                    else {
                                                        return { ...item, }
                                                    }
                                                })
                                            }
                                        }
                                    }
                                }
                            })
                        }
                    }
                })
            }
            StoreObjectInStorage(Constants.Obj_CART, finalCart)
            this.setHeaderItemCount(finalCart)
            var data = RetriveObjectFromStorage(Constants.Obj_CART, '')
            console.log("after second ", data);
        }
        else {

            var plazaList = [];
            var objPlaza = {};
            objPlaza["PlazaId"] = plazaData._id;
            objPlaza["CompanyName"] = plazaData.CompanyName;
            objPlaza["Imageurl"] = plazaData.Imageurl;
            objPlaza["PlazaSubTotal"] = "";
            objPlaza["PlazaTipAmount"] = 0;
            objPlaza["PlazaTotal"] = "";
            objPlaza["ServiceFeeAmount"] = 0;

            var objStore = {};
            objStore["ObjectId"] = storeData.ObjectId;
            objStore["ApplicationId"] = storeData.ApplicationId;
            objStore["ApplicationName"] = storeData.ApplicationName;
            objStore["Deliverytimes"] = storeData.Deliverytimes ? storeData.Deliverytimes : [];
            objStore["Pickuptimes"] = storeData.Pickuptimes ? storeData.Pickuptimes : [];
            objStore["Taxes"] = storeData.Taxes;
            objStore["DeliveryDate"] = "";
            objStore["DeliveryTime"] = "";
            objStore["isshopifyintegration"] = storeData.isshopifyintegration // new key
            objStore["iswixintegration"] = storeData.iswixintegration // new key
            objStore["IsPickupEnable"] = storeData.IsPickupEnable // new key
            objStore["IsDeliveryEnable"] = storeData.IsDeliveryEnable // new key

            var objItem = {};
            objItem["CategoryID"] = "000000000000000000000000";
            objItem["Instruction"] = "";
            objItem["ItemDescription"] = "";
            objItem["ItemId"] = String(itemData.id);
            objItem["ItemName"] = itemData.title;
            objItem["ItemLogo"] = imageObjCart ? imageObjCart.src : (itemData.image ? itemData.image.src : null)
            objItem["ItemPrice"] = selectedVariant.price;
            objItem["ItemQuantity"] = this.state.productQuntity;
            objItem["ItemTax"] = 0.0;
            objItem["ItemType"] = selectedVariant.weight_unit;
            objItem["ItemWeight"] = selectedVariant.weight;
            //objItem["TotalItemPrice"] = (itemData.noOfItems * itemData.ItemPrice);
            objItem["IsSpecialDeliveryItem"] = false;
            objItem["specialdeliverycharges"] = 0.0

            objItem["TotalItemPrice"] = parseFloat(selectedVariant.price) * parseInt(this.state.productQuntity)
            objItem["ToppingItems"] = []
            objItem["AvailableQuantity"] = selectedVariant.inventory_quantity
            objItem["ContinueSelling"] = true
            objItem["variant_id"] = String(selectedVariant.id) // new key
            objItem["variantdetail"] = selectedVariant.title
            objItem['ItemImage'] = imageObjCart ? imageObjCart.src : (itemData.image ? itemData.image.src : null)

            // if (itemData.ToppingCategory.length > 0) {
            //     objItem["ToppingItems"] = itemData.ToppingItems;
            // } else {
            //     objItem["ToppingItems"] = itemData.ToppingCategory;
            // }

            objStore["Items"] = [objItem]
            objPlaza["Stores"] = [objStore]
            plazaList.push(objPlaza)
            console.log('==>', JSON.stringify(plazaList))

            // if (this.state.itemsInCart.length > 0) {
            //     const itemExist = this.state.itemsInCart.some(el => (el.ItemId === itemData.ItemID));

            //     if (!itemExist) {
            //         console.log('item not exist')
            //         objStore["Items"] = [];
            //         this.state.itemsInCart.push(objItem);
            //         objStore["Items"] = this.state.itemsInCart;
            //     }
            //     else {
            //         console.log('item already exist')
            //         var obj = this.state.itemsInCart.find(el => el.ItemId === itemData.ItemID);
            //         obj.ItemQuantity = itemData.noOfItems;
            //         //obj.TotalItemPrice = (itemData.noOfItems * itemData.ItemPrice); 
            //         obj.TotalItemPrice = itemData.totalPrice
            //         obj.ToppingItems = itemData.ToppingItems
            //         this.setState({
            //             itemsInCart: this.state.itemsInCart.map(el => (el.ItemId === objItem.ItemId ? Object.assign({}, obj, el) : el))
            //         })
            //     }
            // }
            // else {
            //     console.log('item first time')
            //     objStore["Items"] = [];
            //     this.state.itemsInCart.push(objItem);
            //     objStore["Items"] = this.state.itemsInCart;
            // }

            // if (this.state.cartStore.length > 0) {
            //     objPlaza["Stores"] = this.state.cartStore;

            // } else {
            //     objPlaza["Stores"] = [];
            //     this.state.cartStore.push(objStore);
            //     objPlaza["Stores"] = this.state.cartStore;
            // }

            // plazaList.push(objPlaza);

            StoreObjectInStorage(Constants.Obj_CART, plazaList)
            this.setHeaderItemCount(plazaList)
            // var data = RetriveObjectFromStorage(Constants.Obj_CART, '')
            // console.log("after first ", data);
        }

    }

    render() {
        const { storeItemList, storeData, productObj } = this.state

        var currencySymbole = ""
        var countryFees = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES)
        if (countryFees) {
            currencySymbole = (countryFees[currencyInfo.CountryCode] ?
                "(" + countryFees[currencyInfo.CountryCode] + ") " : "") + countryFees.Symbol


        }

        return (
            // <MainContext.Provider value={this.state}>
            <div>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Constants.LoadingMssg}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className=""
                >
                    <Header countItemCart={this.state.countCartItem} setHeaderTitle={this.state.productObj ? this.state.productObj.title : ""} />
                    {this.state.productObj && <section className="section">

                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px", zIndex: "-1" }} >
                            <div className="reg_form_main col-sm-6">
                                {this.state.imageObj &&
                                    <div style={{ position: "relative", width: "inherit" }}>
                                        <img src={this.state.imageObj.src} style={{ width: "inherit", zIndex: "-1" }} />
                                        <div className="overlay imageScrollbox">
                                            <div className='leftimagescrollarrow' onClick={(e) => this.handelImage("Left")}>
                                                {"<"}
                                            </div>
                                            <div className="leftimagescrollarrow rightimagescrollarrow" onClick={(e) => this.handelImage("Right")}>
                                                {">"}
                                            </div>
                                        </div>
                                    </div>}
                            </div>

                            <div className="reg_form_main col-sm-6">
                                <div>
                                    <span className="textcolor"> Product Price:- </span>
                                    <label className="">{currencySymbole} {Number(this.state.productPrice).toFixed(2)}</label>
                                </div>
                                <div className=""  >
                                    <span className="textcolor setline"> Quantity:- </span>
                                    <div className='itemcountBox'>
                                        <label className="itemcountContent" style={{}} onClick={this.handleMinus}>-</label>
                                        <label style={{ fontSize: "25px" }}> {this.state.productQuntity} </label>
                                        <label className='itemcountContent' onClick={this.handlePlus}>+</label>
                                    </div>
                                </div>

                                {this.state.optionObj1 && <div className="mainattBox">
                                    <span className="textcolor setline"> {this.state.optionObj1.name}:- </span>
                                    <div>
                                        {this.state.optionObj1.values.map(value =>
                                        (
                                            <div className={this.state.selectedOption1 == value ? 'sizeBox clicksizeBox' : 'sizeBox'}
                                                onClick={(e) => this.handelVariant(this.state.optionObj1, value)}>
                                                <label className="sizeboxitem">{value}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>}

                                {this.state.optionObj2 && <div className="mainattBox">
                                    <span className="textcolor setline"> {this.state.optionObj2.name}:- </span>
                                    <div>
                                        {this.state.optionObj2.values.map(value => (
                                            <div className={this.state.selectedOption2 == value ? 'sizeBox clicksizeBox' : 'sizeBox'}
                                                onClick={(e) => this.handelVariant(this.state.optionObj2, value)}>
                                                <label className="sizeboxitem">{value}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>}

                                {this.state.optionObj3 && <div className="mainattBox">
                                    <span className="textcolor setline"> {this.state.optionObj3.name}:- </span>
                                    <div>
                                        {this.state.optionObj3.values.map(value => (
                                            <div className={this.state.selectedOption3 == value ? 'sizeBox clicksizeBox' : 'sizeBox'}
                                                onClick={(e) => this.handelVariant(this.state.optionObj3, value)}>
                                                <label className="sizeboxitem" >{value}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>}

                                <div className="bottam_sing" style={{ bottom: "0px" }}>
                                    <label
                                        style={{ backgroundColor: Color.THEME_COLOR, borderRadius: "50px" }}
                                        onClick={this.handleAddToCart}
                                        className=" col-sm-6 btn btn-done"
                                    > Add Cart</label>
                                </div>
                            </div>

                            <div class="modal" id="deleteModal">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <label>{COMPANYCONST.BREWERSNEARME}</label>
                                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                                        </div>

                                        <div class="modal-body">
                                            <label>Are you sure you want to remove all the items from the cart?</label>
                                        </div>
                                        <div class="modal-footer">
                                            <label
                                                style={{ color: Color.THEME_COLOR }}
                                                for="step5"
                                                id="continue-step5"
                                                data-dismiss="modal"
                                            > No</label>

                                            <label
                                                style={{ float: "center", borderRadius: "50px", marginBottom: "10px" }}
                                                for="step5"
                                                id="continue-step5"
                                                //onClick={this.handlePickupDialog}
                                                className="btn btn-done"
                                            > Yes</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>}
                </LoadingOverlay>
            </div>
            // </MainContext.Provider> 
        );
    }
}

export default PlazaStoreItemsShopify;