import React from 'react';
import './Toast.css';

export function Toast({ msg }) {
  var x = document.getElementById("snackbar");
  if (x) {
    x.className = "show";
    x.innerHTML = msg
    setTimeout(function () { x.className = x.className.replace("show", ""); }, 1000);
  }
}