import React, { Component } from 'react';
import Constants, { DatetimeFormate, currencyInfo, DEFAULTID } from '../../Config/Constants';
import { RetriveDataFromStorage, RetriveObjectFromStorage } from "../../Config/Utils";
import { NavLink, withRouter } from 'react-router-dom';
import Color from '../../Config/Color';
import { GetData } from "../../../services/GetData";
import Collapsible from 'react-collapsible';
import Header from '../../Header/Header';
import Moment from 'moment';
import './CurbsideOrderSingleStore.css';
import { POST_CHECKIN_MSSG, POST_CANCEL_MSSG, GET_COUNTRY_API, STOREORDER_POST_CHECKIN_MSSG, STOREORDER_POST_CANCEL_MSSG } from '../../Config/Config.json';
import { PostData } from "../../../services/PostData";
import LoadingOverlay from 'react-loading-overlay';
import CountryList from '../../Config/CountryCode';
import Messages from '../../Config/Messages';

class CurbsideOrderSingleStore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dismissmodal: "modal",
            orderDetail: {},
            currencySymbols: "",
            applicationId: "",
            plazzaId: "",
            checkInMssg: {},
            isActive: false,
            countryData: [],
            errors: {}
        }
        this.setmodal = this.setmodal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {
        var orderDetails = RetriveObjectFromStorage(Constants.orderDetail);
        if (orderDetails) {
            this.setState({ orderDetail: orderDetails });
        }
        var countryFees = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES);
        if (countryFees) {
            var currencySymbole = (countryFees[currencyInfo.CountryCode] ?
                "(" + countryFees[currencyInfo.CountryCode] + ") " : "") + countryFees.Symbol
            this.setState({ currencySymbols: currencySymbole });
        }
        this.getCountryAPI();
    }

    getCountryAPI() {
        console.log("country list1 ",);
        GetData(GET_COUNTRY_API)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData) {
                        this.setState({ countryData: responseData });
                    }
                    else {
                        this.setState({ countryData: [] });
                    }
                    console.log("countryData ", responseData);
                }
            });
    }

    setAppandPlazzaIds(e, plazzaId, appId) {
        e.stopPropagation()
        let { checkInMssg } = this.state;
        checkInMssg["checkInMessage"] = ""
        this.handleModalview(true)
        this.setState({ plazzaId: DEFAULTID, applicationId: appId, errors: {}, checkInMssg: checkInMssg });
    }

    closemyModal() {
        this.handleModalview(false)
    }

    handleChange(event) {
        let checkInMssg = this.state.checkInMssg;
        let key = event.target.name;
        let value = event.target.value;
        checkInMssg[key] = value;
        this.setState({ checkInMssg });
    }

    validateForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.checkInMssg;

        if (!fields["checkInMessage"]) {
            errors["sendMssg"] = Messages.v_Order_Related_Message;
            formIsValid = false;
        }

        /*  if (!this.state.plazzaId) {
              formIsValid = false;
          }*/

        if (!this.state.applicationId) {
            formIsValid = false;
        }
        if (!this.state.orderDetail.OrderId) {
            formIsValid = false;
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSubmit(e) {
        var postData = {};
        e.preventDefault();
        if (this.validateForm()) {
            postData['ApplicationId'] = this.state.applicationId;
            postData['PlazaId'] = this.state.plazzaId;
            postData['Message'] = this.state.checkInMssg.checkInMessage;

            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            console.log("==> postdata==", postData)
            this.addCheckinMssg(postData, authToken);
        }
    }

    handleRefresh() {
        this.componentDidMount();
    }

    addCheckinMssg(postData, authToken) {
        var URL = STOREORDER_POST_CHECKIN_MSSG.replace("#id#", this.state.orderDetail.OrderId);
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                console.log("====== response", result)
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    let errors = {};
                    this.setState({ errors: errors });
                    this.closemyModal()
                    var checkInMssg = this.state.checkInMssg;
                    checkInMssg['checkInMessage'] = "";
                    this.setState({ checkInMssg, plazzaId: "", applicationId: "" });
                }
                else {
                    let errors = {};
                    errors["sendMssg"] = responseJson["Message"];
                    this.setState({ errors: errors });
                }
            }).catch(error => {
                let errors = {};
                errors["sendMssg"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors });
            });
    }


    handleCancel(e) {
        var postData = {};
        $('#myModal1').modal('hide');
        e.preventDefault();
        if (this.state.orderDetail.OrderId) {
            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            postData["Isconfirm"] = false;
            this.cancelOrderAPI(postData, authToken);
        }
    }
    cancelOrderAPI(postData, authToken) {
        this.setState({ isActive: false });
        var URL = STOREORDER_POST_CANCEL_MSSG.replace("#id#", this.state.orderDetail.OrderId);
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                console.log("====== ", result)
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ isActive: false });
                    this.props.history.goBack();
                }
                else {
                    alert(responseJson["Message"])
                }
                this.setState({ isActive: false });
            })
    }

    setmodal(e) {
        e.preventDefault();
        this.setState({ dismissmodal: "" });
    }

    // add for modal method 2-june-2023
    handleModalview(falg) {
        var backgroung = document.getElementById('modalbackgroundId');
        var modalId = document.getElementById('myModal');

        if (backgroung && modalId) {
            backgroung.style.display = falg ? "block" : "none";
            modalId.style.display = falg ? "block" : "none";
        }
    }
    //

    render() {
        var currencySymbols = this.state.currencySymbols;
        let orderDetailsvalue = this.state.orderDetail;
        var lengthofDetail = Object.keys(orderDetailsvalue).length;
        var DetailDOM
        if (lengthofDetail > 0) {

            var currency = this.state.countryData.filter(e => e.Currency == orderDetailsvalue.Currency)[0]
            currencySymbols = "(" + orderDetailsvalue.Currency + ")" + (currency ? currency.Symbol + " " : "")

            var totelItem = 0, totalItemTax = 0, subTotal = 0, couponAmount = orderDetailsvalue.CouponDetail ? Number(orderDetailsvalue.CouponDetail.discountamount) : 0;

            var SubMainTotal = 0;
            SubMainTotal = Number(orderDetailsvalue.TotalProductAmount) + Number(orderDetailsvalue.TotalDeliveryAmount) + Number(orderDetailsvalue.TotalTaxes) + Number(orderDetailsvalue.TipAmount);

            var storeDetailDOM = (
                <div className="reg_form_main" >
                    <Collapsible
                        open={true}
                        trigger={<>
                            <div className="col-sm-12 d-flex justify-content-between align-items-center border-bottom" style={{ marginTop: "10px", borderColor: Color.GREY }}>
                                <div className="d-flex">
                                    <img
                                        className="mt-1"
                                        src={orderDetailsvalue.storebanner}
                                        style={{ height: "50px", borderRadius: "5px", width: "50px" }}>
                                    </img>
                                    <div className="ml-1" style={{ fontSize: "13px" }}>
                                        <div style={{ fontWeight: "bold", fontSize: "18px" }}>{orderDetailsvalue.storname} {orderDetailsvalue.plazaname && "(" + orderDetailsvalue.plazaname + ")"}</div>
                                        <div>Brewery order {orderDetailsvalue.Items.length} Products</div>
                                        <div className=""> Status: <span style={{ color: Color.GREEN }}>{orderDetailsvalue.OrderStatus}</span>
                                        </div>
                                        <div style={{ fontSize: "13px" }}>{orderDetailsvalue.IsPickupOrder ? "Pickup" : "Delivery"} Date: <span style={{ color: Color.BLUE }}>{Moment(orderDetailsvalue.DeliveryDate).format(DatetimeFormate.DDMMYYYYN)}</span></div>
                                        <div style={{ fontSize: "13px" }}>{orderDetailsvalue.IsPickupOrder ? "Pickup" : "Delivery"} Time: <span style={{ color: Color.BLUE }}>{orderDetailsvalue.DeliveryTime}</span></div>
                                    </div>
                                </div>
                                <label style={{ marginLeft: "auto", color: Color.THEME_COLOR, float: "right", background: "none" }} >
                                    <span className="btn setaTag"
                                        style={{
                                            display: "inline",
                                            fontSize: "14px",
                                            backgroundColor: Color.THEME_COLOR,
                                            float: "center",
                                            borderRadius: "4px",
                                            padding: "4px 20px"
                                        }}
                                        onClick={(e) => this.setAppandPlazzaIds(e, orderDetailsvalue.plazaid, orderDetailsvalue.storeid)}>
                                        Check in
                                    </span>
                                </label>
                            </div>
                        </>
                        }>
                        <div style={{ paddingTop: "5px" }}>
                            {orderDetailsvalue.Items && orderDetailsvalue.Items.map((itemObj, index) => {
                                totalItemTax = totalItemTax + Number(itemObj.TotalItemTax)
                                subTotal = subTotal + Number(itemObj.TotalItemPrice)
                                return (<div style={{ marginLeft: "0px", marginTop: "10px", borderBottom: "1px solid" + Color.GREY }}>
                                    <div className="col-sm-12">
                                        <div style={{ display: 'flex' }}>
                                            <img src={itemObj.ItemImage} style={{ height: "50px", width: "50px" }}></img>
                                            <label style={{ marginLeft: "5px", fontSize: "13px" }}>{itemObj.ItemName}</label>
                                            <label style={{ fontSize: "13px", float: "right", marginLeft: "auto", color: Color.THEME_COLOR }}>{itemObj.ItemQuantity} Products</label>
                                        </div>
                                        <div style={{ marginTop: "5px", fontSize: "13px" }}>
                                            <label className="plazzanametitle textcolor">{currencySymbols} {(Math.round((itemObj.TotalItemPrice) * 100) / 100).toFixed(2).toString()}</label>
                                            {itemObj.Instruction && <label className="plazzanametitle veriantDetailBox" >
                                                <span className='setshopifydetail textcolor'> {itemObj.Instruction}</span></label>}
                                            {itemObj.variantdetail && <label className="plazzanametitle veriantDetailBox" >Variant Detail :
                                                <span className='setshopifydetail textcolor'> {itemObj.variantdetail}</span></label>}
                                            {(itemObj.ItemType && itemObj.ItemType === "W") && <div className='mt-3'>
                                                <label className="plazzanametitle veriantDetailBox" >Weight :
                                                    <span className='setshopifydetail textcolor'> {itemObj.ItemWeight ? itemObj.ItemWeight : 0}</span></label>
                                            </div>}
                                            <Collapsible
                                                open={index == 0}
                                                triggerStyle={{ position: "relative" }}
                                                trigger={<label style={{ float: "right", color: Color.BLUE, marginTop: "-35px" }}>More Details</label>}>
                                                Details:<br />
                                                <span style={{ color: Color.THEME_COLOR }} >{itemObj.ItemDescription}</span><br />
                                                {itemObj.ToppingItems && itemObj.ToppingItems.length > 0 != "" &&
                                                    <label>
                                                        Toppings: <br />
                                                        {itemObj.ToppingItems.map(function (titem, index) {
                                                            return (<span style={{ color: Color.THEME_COLOR }} >{titem.ToppingItem}<span style={{ color: Color.BLACK }}>, </span></span>);
                                                        })}
                                                    </label>
                                                }
                                            </Collapsible>
                                        </div>
                                    </div>
                                </div >)
                            })}
                        </div>
                    </Collapsible>
                </div>
            )

            DetailDOM = (<div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                <div className="col-sm-12" style={{ backgroundColor: Color.WHITE, borderBottom: "1px solid" + Color.GREY, borderTop: "0px solid" + Color.GREY, marginTop: "10px" }} >

                    <div className="row" style={{ marginLeft: "3px", color: Color.BLACK }}>{orderDetailsvalue.OrderId}</div>

                    <div className="row" style={{ marginLeft: "3px", fontSize: "14px" }}>Order Status: <label style={{ marginBottom: '0px', color: Color.GREEN }}>{orderDetailsvalue.OrderStatus}</label></div>

                    <div className="row" style={{ marginLeft: "3px", fontSize: "14px" }}>Order Date: <span style={{ color: Color.BLUE }}>{Moment(orderDetailsvalue.OrderDate).format(DatetimeFormate.DDMMMYYYYHHMMA)}</span></div>

                    <div className="row" style={{ marginLeft: "3px", fontSize: "14px" }}>Confirmation Code: <b><span style={{ color: Color.THEME_COLOR }}>{orderDetailsvalue.PickupOrderCode}</span></b></div>
                </div>

                {storeDetailDOM}
                {/* {plazzaList} */}

                <div className="reg_form_main">
                    <div className="col-sm-12" style={{ marginTop: "10px" }}>
                        <div className="row">
                            <label style={{ marginLeft: "20px", color: Color.BLACK }}>Item(s) Subtotal</label>
                            <label style={{ marginLeft: "auto", color: Color.BLACK, marginRight: "10px" }}>{currencySymbols}{(Math.round((orderDetailsvalue.TotalProductAmount) * 100) / 100).toFixed(2).toString()}</label>
                        </div>

                        {/* {totalItemTax != 0 && <div className="row">
                            <label style={{ marginLeft: "20px", color: Color.BLACK }}>Total Product Tax</label>
                            <label style={{ marginLeft: "auto", color: Color.BLACK, marginRight: "10px" }}>{currencySymbols}{(Math.round(totalItemTax * 100) / 100).toFixed(2)}</label>
                        </div>} */}

                        {orderDetailsvalue.TotalDeliveryAmount != 0 && <div className="row">
                            <label style={{ marginLeft: "20px", color: Color.BLACK }}>Delivery Charges</label>
                            <label style={{ marginLeft: "auto", color: Color.BLACK, marginRight: "10px" }}>{currencySymbols}{(Math.round((orderDetailsvalue.TotalDeliveryAmount) * 100) / 100).toFixed(2).toString()}</label>
                        </div>}

                        {/* Changes are made based on the call discussion. Date 10 Aug 2022 */}
                        {/*  <div className="row">
                            <label style={{ marginLeft: "20px", color: Color.BLACK }}>Service Fee</label>
                            <label style={{ marginLeft: "auto", color: Color.BLACK, marginRight: "10px" }}>{currencySymbols}{(Math.round((orderDetailsvalue.ServiceFeeAmount) * 100) / 100).toFixed(2).toString()}</label>
                        </div> */}

                        {orderDetailsvalue.TaxDetails.map(e2 => {
                            return <div className="row">
                                <label style={{ marginLeft: "20px", color: Color.BLACK }}>{e2.TaxType} ({Number(e2.TaxRate) ? e2.TaxRate : ""}%)</label>
                                <label style={{ marginLeft: "auto", color: Color.BLACK, marginRight: "10px" }}>{currencySymbols}{(Math.round((e2.TotalTax) * 100) / 100).toFixed(2).toString()}</label>
                            </div>
                        })}

                        <div>
                            {orderDetailsvalue.TipAmount != 0 && <div className="row" style={{ borderBottom: "1px solid" + Color.GREY }}>
                                <label style={{ marginLeft: "20px", color: Color.BLACK }}>Delivery Tip</label>
                                <label style={{ marginLeft: "auto", color: Color.BLACK, marginRight: "10px" }}>{currencySymbols}{(Math.round((orderDetailsvalue.TipAmount) * 100) / 100).toFixed(2).toString()}</label>
                            </div>}
                        </div>

                        <div>
                            {SubMainTotal != 0 && <div className="row" style={{ paddingTop: '10px', borderTop: "1px solid" + Color.GREY }}>
                                <label style={{ marginLeft: "20px", color: Color.BLACK }}>Subtotal</label>
                                <label style={{ marginLeft: "auto", color: Color.BLACK, marginRight: "10px" }}>{currencySymbols}{(Math.round((SubMainTotal) * 100) / 100).toFixed(2).toString()}</label>
                            </div>}
                        </div>

                        {orderDetailsvalue.ApplicationFee != 0 && <div className="row">
                            <label style={{ marginLeft: "20px", color: Color.BLACK }}>Service charge</label>
                            <label style={{ marginLeft: "auto", color: Color.BLACK, marginRight: "10px" }}>{currencySymbols}{(Math.round((orderDetailsvalue.ApplicationFee) * 100) / 100).toFixed(2).toString()}</label>
                        </div>}
                    </div>

                    <div className="col-sm-12" style={{ marginTop: "10px", borderBottom: "1px solid" + Color.GREY }}>
                        <div className="row">
                            <label style={{ marginLeft: "20px", color: Color.BLACK }}>Total </label>
                            <label style={{ marginLeft: "auto", color: Color.BLACK, marginRight: "10px" }}>{currencySymbols}{(Math.round((orderDetailsvalue.TotalAmount + couponAmount) * 100) / 100).toFixed(2).toString()}</label>
                        </div>
                        {orderDetailsvalue.CouponDetail &&
                            <div className="row">
                                <label style={{ marginLeft: "20px", color: Color.BLACK }}>Payable Amount  {currencySymbols} {Number(orderDetailsvalue.CouponDetail ? orderDetailsvalue.CouponDetail.discountamount : 0).toFixed(2) + " OFF "}
                                </label>
                                <label style={{ marginLeft: "auto", color: Color.BLACK, marginRight: "10px" }}>{currencySymbols} {Number(orderDetailsvalue.TotalAmount).toFixed(2)}</label>
                            </div>
                        }
                        {orderDetailsvalue.CouponDetail && <div className={'row-over row setaTag'}                        >
                            <div className='coupondesc couponblock'>
                                <label className="remove-mg" style={{ marginLeft: "10px", color: Color.BLACK, fontWeight: 'bold', }}>{orderDetailsvalue.CouponDetail.coupontitle}
                                </label>
                                <label className="remove-mg coupondescription" style={{ marginLeft: "10px", color: Color.BLACK }}> {orderDetailsvalue.CouponDetail.coupondescription}</label>
                            </div>
                        </div>}
                        <label className="row" style={{ color: Color.BLACK, fontSize: "12px", marginLeft: "3px" }}>All taxes and charges are included in total price.</label>
                    </div>
                </div>

                {/* Changes are made based on the call discussion. Date 10 Aug 2022 */}
                {/* {orderDetailsvalue.IsCanCancel ? <div className="reg_form_main">
                    <div className="bottam_sing">
                        <label
                            style={{ backgroundColor: Color.THEME_COLOR, float: "center", borderRadius: "4px", marginBottom: "10px" }}
                            //onClick={this.handlePayment}
                            className="btn btn-done"
                            data-toggle="modal" data-target="#myModal1"
                        > Cancel Order</label>
                    </div>
                </div> : ""} */}


                {/* /////////////////////start model///////////////////////////// */}
                <div class="modal modalcustom" id="myModal">
                    <div class="modal-dialog" style={{ top: "25%" }}>
                        <div class="modal-content">
                            <div class="modal-header">
                                <label>Check in Message to brewery (Optional):</label>
                                <button type="button" class="close" onClick={() => this.closemyModal()}>&times;</button>
                            </div>
                            <div class="modal-body">
                                <div>
                                    <textarea className="col-sm-12" style={{ marginTop: "10px" }} rows="7" name="checkInMessage" onChange={this.handleChange} value={this.state.checkInMssg.checkInMessage}>
                                    </textarea>
                                </div>
                                <div className="errorMsg">
                                    {this.state.errors.sendMssg}
                                </div>
                            </div>
                            <div class="modal-footer">
                                <label
                                    class="m-btn"
                                    for="step5"
                                    id="continue-step5"
                                    onClick={() => this.closemyModal()}
                                > Cancel</label>
                                <label
                                    for="step5"
                                    id="continue-step5"
                                    //onClick={this.handlePayment}
                                    onClick={this.handleSubmit}
                                    class="m-btn"
                                > Send</label>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /////////////////////end model///////////////////////////// */}


                {/* /////////////////////start checkin model///////////////////////////// */}

                <div class="modal" id="myModal1">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <label style={{ color: Color.THEME_COLOR, textAlign: "justify" }}>Your order status is pending.if you would like to cancel the entire order click OK or click CANCEL to wait for merchant to confirm. Alternatively you can notify the business by clicking Message Business link and check for status on your order.</label>
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div class="modal-footer">
                                <label
                                    style={{ color: Color.THEME_COLOR }}
                                    for="step5"
                                    id="continue-step5"
                                    data-dismiss="modal"
                                > Cancel</label>
                                <label
                                    style={{ float: "center", borderRadius: "4px", marginBottom: "10px" }}
                                    for="step5"
                                    id="continue-step5"
                                    //onClick={this.handlePayment}
                                    onClick={this.handleCancel}
                                    className="btn btn-done"
                                > Send</label>
                            </div>
                        </div>
                    </div>
                </div>

                {/* /////////////////////end checkin model///////////////////////////// */}
            </div>)
        }

        return (
            <div>
                {this.state.isActive ?
                    <LoadingOverlay
                        active={this.state.isActive}
                        text={Constants.LoadingMssg}
                        spinner
                        styles={{
                            overlay: (base) => ({
                                ...base,
                                position: "fixed",
                                zIndex: "1079"
                            })
                        }}
                        cl
                        className="lodingspinner"
                    /> : ""}
                <Header setHeaderTitle={Constants.Title_CurbsideOrders} onrefresh={() => this.handleRefresh()} />
                <div className="modal-background" id="modalbackgroundId"> </div>
                <section className="section">
                    {DetailDOM}
                </section>
            </div>
        );
    }
}

export default withRouter(CurbsideOrderSingleStore);