import React, { Component } from "react";
import { PostData } from "../../services/PostData";
import "../../styles/style.css";
//import 'react-flags-select/css/react-flags-select.css';
import { DELIVERYUSER_API, GOOGLESITEKEY, GOOGLE_PLACE_KEY } from '../Config/Config.json';
import Messages from "../Config/Messages";
import { formatPhoneNumber, removeAllSpace, validateEmail, validatePhoneNumber } from "../Config/Utils";

//import Select from 'react-select'; //https://www.npmjs.com/package/react-select
import Constants from '../Config/Constants';
//import FacebookLogin from 'react-facebook-login';
import { GOOGLEPLACEAPIMAP } from '../Config/constvariable';

import Color from '../Config/Color.js';
import 'react-data-table-component-extensions/dist/index.css';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import ReCAPTCHA from "react-google-recaptcha";
import LoadingOverlay from 'react-loading-overlay';
import { PlazaCartPartnerPH } from '../Config/Placeholder';
import { PlazaCartPartnerConst } from '../Config/constvariable';
import MainFooter from "../MainFooter/MainFooter";
import MainHeader from "../MainHeader/MainHeader";
import { PutMandatoryfieldSymbol } from "../UtillCustomComponent/UtillCustomComponent";

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: '#f0f0f0', border: "none", borderRadius: "50px", height: "50px", marginBottom: "15px" }),
  placeholder: styles => ({ ...styles, fontSize: "16px", fontWeight: "450 !important", marginLeft: "8px" }),
  option: styles => ({ ...styles, color: '#333' }),
  menuPortal: styles => ({ ...styles, zIndex: "99" })
};

class PlazaCartPartner extends Component {
  constructor(props) {
    super(props);
    this.state = {


      userFields: {
        name: "",
        email: "",
        phonenumber: "",
        city: "",
        state: "",
        country: "",
        postalcode: ""
      },   //registrstion fields
      errors: {},


      isActive: false,
      selectedAddress: null,

        googleReCaptch: null,
        successMsg: "",

        iscaptchreload:true


    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleValidationOnBlur = this.handleValidationOnBlur.bind(this);

  }

  componentDidMount() {
    console.log("=====> api")
  };


  /***************************************************************************************** */
  // START --> FUNCTIONS FOR REGISTRATION MODULE
  /***************************************************************************************** */

  handleChange(event) {
    let userFields = this.state.userFields;
    let key = event.target.name
    let value = event.target.value
    if (key == "phonenumber") {
      userFields[key] = formatPhoneNumber(value)
    }
    else {
      userFields[key] = value
    }
    this.setState({ userFields })
  }

  handleGoogleRecaptcha = vl => {
    this.setState({ googleReCaptch: vl })
    }

    reloadCaptch() {
        this.setState({ iscaptchreload: false, googleReCaptch: null })
        setTimeout(() => {
            this.setState({ iscaptchreload:true})
        },900)
    }

  handelAddress(addressObj) {
    // this.setState({ selectedAddress: addressObj })
    console.log(addressObj)
    let userFields = this.state.userFields;
    if (addressObj && addressObj.value && addressObj.value.place_id) {
      this.getaddressGooglePlace("", addressObj.value.place_id, userFields)
    }
    userFields["city"] = "";
    userFields["state"] = ""
    userFields["country"] = ""
    userFields["postalcode"] = ""
    this.setState({ userFields, selectedAddress: null })
  }

  getaddressGooglePlace(authToken, value, refObj) {
    this.setState({ isActive: true })
    var placeId = value
    console.log(placeId)
    geocodeByPlaceId(placeId)
      .then(result => {
        var responseJson = result ? result[0] : null;
        console.log("====", result)
        if (responseJson) {
          var responseData = responseJson;
          this.setState({ selectedAddress: responseData ? responseData : null })
          if (responseData && responseData.address_components) {
            var remaingAddress = ""

            let { userFields } = this.state
            for (var obj of responseData.address_components) {
              const componentType = obj.types[0];
              switch (componentType) {
                case GOOGLEPLACEAPIMAP.STREET_NUMBER: {
                  remaingAddress = obj.long_name + ", ";
                  break;
                }
                case GOOGLEPLACEAPIMAP.ROUTE: {
                  remaingAddress = remaingAddress + obj.short_name;
                  break;
                }
                case GOOGLEPLACEAPIMAP.POSTAL_CODE: {
                  userFields['postalcode'] = removeAllSpace(obj.long_name);
                  break;
                }
                case GOOGLEPLACEAPIMAP.POSTAL_CODE_SUFFIX: {
                  userFields['postalcode'] = removeAllSpace(userFields['postalcode'] + "-" + obj.long_name);
                  break;
                }
                case GOOGLEPLACEAPIMAP.LOCALITY: {
                  // remaingAddress = obj.long_name;
                  userFields["city"] = obj.long_name
                  break;
                }
                case GOOGLEPLACEAPIMAP.ADMINISTRATIVE_AREA_LEVEL_1: {
                  // remaingAddress = remaingAddress + ", " + obj.short_name;
                  userFields["state"] = obj.short_name
                  break;
                }
                case GOOGLEPLACEAPIMAP.COUNTRY:
                  // remaingAddress = remaingAddress + ", " + obj.long_name;
                  userFields["country"] = obj.long_name
                  break;
              }
            }
            var selectedAddress = {
              label: remaingAddress,
              value: {
                description: remaingAddress
              }
            }
            this.setState({ selectedAddress: selectedAddress, userFields: userFields })
          }
        }
        else {
          // refObj["PostalCode"] = ""

        }
        this.setState({ isActive: false })
      }).catch(err => {
        // refObj["PostalCode"] = ""
        this.setState({ isActive: false })
      });
  }

  handleValidationOnBlur(e) {
    let fields = this.state.userFields;
    let errors = this.state.errors;
    let formIsValid = true;
    let name = e.currentTarget.name;

    if (name == "name") {
      if (!fields["name"]) {
        formIsValid = false;
        errors["name"] = Messages.v_EnterName;
      }
    }

    if (name == "email") {
      if (!fields["email"]) {
        formIsValid = false;
        errors["email"] = Messages.v_EnterEmail;
      }
      else if (typeof fields["email"] !== "undefined") {
        if (!validateEmail(fields["email"])) {
          formIsValid = false;
          errors["email"] = Messages.v_EnterValidEmail;
        }
      }
    }

    if (name == "phonenumber") {
      if (!fields["phonenumber"]) {
        formIsValid = false;
        errors["phonenumber"] = Messages.v_EnterPhoneNo
      }
      else if (typeof fields["phonenumber"] !== "undefined") {
        if (!validatePhoneNumber(fields["phonenumber"])) {
          formIsValid = false;
          errors["phonenumber"] = Messages.v_EnterMoNoFormate;
        }
      }
    }

    if (name == "city") {
      if (!fields["city"]) {
        formIsValid = false;
        errors["city"] = Messages.v_City;
      }
    }

    if (name == "state") {
      if (!fields["state"]) {
        formIsValid = false;
        errors["state"] = Messages.v_State;
      }
    }

    if (name == "country") {
      if (!fields["country"]) {
        formIsValid = false;
        errors["country"] = Messages.v_EnterCountryName;
      }
    }

    if (name == "postalcode") {
      if (!fields["postalcode"]) {
        formIsValid = false;
        errors["postalcode"] = Messages.v_ZIP_PostalCode;
      }
    }

    if (formIsValid) errors[name] = "";
    this.setState({
      fields,
      errors
    });
  }


  validateForm() {
    let errors = {};
    let formIsValid = true;
    let fields = this.state.userFields;
    const { selectedAddress, googleReCaptch } = this.state

    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = Messages.v_EnterName;
    }

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = Messages.v_EnterEmail;
    }
    else if (typeof fields["email"] !== "undefined") {
      if (!validateEmail(fields["email"])) {
        formIsValid = false;
        errors["email"] = Messages.v_EnterValidEmail;
      }
    }

    if (!fields["phonenumber"]) {
      formIsValid = false;
      errors["phonenumber"] = Messages.v_EnterPhoneNo
    }
    else if (typeof fields["phonenumber"] !== "undefined") {
      if (!validatePhoneNumber(fields["phonenumber"])) {
        formIsValid = false;
        errors["phonenumber"] = Messages.v_EnterMoNoFormate;
      }
    }

    if (!selectedAddress) {
      formIsValid = false;
      errors["addressline1"] = Messages.v_dAddress1;
    }
    else if (selectedAddress) {
      if (!selectedAddress.label) {
        formIsValid = false;
        errors["addressline1"] = Messages.v_dAddress1;
      }
    }

    if (!fields["city"]) {
      formIsValid = false;
      errors["city"] = Messages.v_City;
    }

    if (!fields["state"]) {
      formIsValid = false;
      errors["state"] = Messages.v_State;
    }

    if (!fields["country"]) {
      formIsValid = false;
      errors["country"] = Messages.v_EnterCountryName;
    }

    if (!fields["postalcode"]) {
      formIsValid = false;
      errors["postalcode"] = Messages.v_ZIP_PostalCode;
    }

    if (!googleReCaptch) {
      formIsValid = false;
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit(e) {
    var postData = {};
    e.preventDefault();
    if (this.validateForm()) {
      const { userFields, selectedAddress, googleReCaptch } = this.state;
      postData["name"] = userFields.name;
      postData["email"] = userFields.email;
      postData["phonenumber"] = userFields.phonenumber;
      postData["addressline1"] = selectedAddress ? selectedAddress.label : "";
      postData["city"] = userFields.city;
      postData["state"] = userFields.state;
      postData["country"] = userFields.country;
      postData["postalcode"] = userFields.postalcode;
      postData["googlecaptcharesponse"] = googleReCaptch

      console.log(">", postData)

      this.callResgisterDeliveryUserAPI(postData)
    }
  }


  /***************************************************************************************** */
  // END --> FUNCTIONS FOR REGISTRATION MODULE
  /***************************************************************************************** */


  /***************************************************************************************** */
  // START --> FUNCTIONS FOR PHONE NUMBER MODULE
  /***************************************************************************************** */

  callResgisterDeliveryUserAPI(postData) {
    console.log("callResgisterAPI");
    this.setState({ isActive: true })
    var URL = DELIVERYUSER_API;
      PostData(URL, postData)
          .then(result => {
              var responseJson = result;
              console.log("callResgisterAPI", responseJson);
              if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                  //this.props.history.goBack()
                  this.setState({
                      userFields: {
                          name: "",
                          email: "",
                          phonenumber: "",
                          city: "",
                          state: "",
                          country: "",
                          postalcode: ""
                      },
                      selectedAddress: null,
                      successMsg: Messages.s_successMsgPlazaCartDelivery
                  })
              }
              else if (responseJson["StatusCode"] === 409) {
                  let errors = {};
                  errors["email"] = Messages.v_EmailDuplication /* responseJson.Message */;
                  this.setState({
                      errors: errors,
                      successMsg: ""
                  });
              }
              else {
                  let errors = {};
                  errors["masterError"] = Messages.ERR_SomethingWentWrong;
                  this.setState({
                      errors: errors,
                      successMsg: ""
                  });
              }
              this.setState({ isActive: false });
              this.reloadCaptch()
          }).catch(err => {
              let errors = {};
              errors["masterError"] = Messages.ERR_SomethingWentWrong;
              this.setState({
                  errors: errors,
                  successMsg: "",
                  isActive: false
              });
              this.reloadCaptch()
          });
  }

  /***************************************************************************************** */
  // END --> FUNCTIONS FOR PHONE NUMBER MODULE
  /***************************************************************************************** */

  render() {
      const { googleReCaptch, successMsg, iscaptchreload } = this.state;

    return (
      <LoadingOverlay
        active={this.state.isActive}
        text={Constants.LoadingMssg}
        spinner
        styles={{
          overlay: (base) => ({
            ...base,
            position: "fixed"
          })
        }}
        className="">
        <div>
          {/* <Header /> */}
          <div class="hero_area">
            <MainHeader />
          </div>
          <section className="section mbottom" /* style={{ height: `${window.screen.height}px` }} */>
            <div className="registed_form_tab">

              <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                <div className="reg_form_main">
                  <center>
                    <p id="instruction"
                      className="textcolor"
                      style={{ marginBottom: '10px' }}>{PlazaCartPartnerConst.PlazaCartDeliveryPartner}</p> </center>
                </div>
                <div className="reg_form_main offset-sm-3 col-sm-6">
                  <p className="col-sm-12 textcolor" for="FirstName" style={{ fontWeight: 'bold' }}>
                    {PlazaCartPartnerConst.Name}<PutMandatoryfieldSymbol />
                  </p>
                  <input
                    type="text"
                    placeholder={PlazaCartPartnerPH.Name}
                    name="name"
                    className="float-none"
                    value={this.state.userFields.name}
                    onChange={this.handleChange}
                    onBlur={this.handleValidationOnBlur}
                  />
                  <div className="col-sm-12 errorMsg mb-0">
                    {this.state.errors.name}
                  </div>
                </div>

                <div className="reg_form_main offset-sm-3 col-sm-6">
                  <p className="col-sm-12 textcolor" for="LastName" style={{ fontWeight: 'bold' }}>
                    {PlazaCartPartnerConst.Email} <PutMandatoryfieldSymbol />
                  </p>
                  <input
                    type="text"
                    placeholder={PlazaCartPartnerPH.Email}
                    name="email"
                    className="float-none"
                    value={this.state.userFields.email}
                    onChange={this.handleChange}
                    onBlur={this.handleValidationOnBlur}
                  />
                  <div className="col-sm-12 errorMsg mb-0">
                    {this.state.errors.email}
                  </div>
                </div>

                <div className="reg_form_main offset-sm-3 col-sm-6">
                  <p className="col-sm-12 textcolor" style={{ color: Color.THEME_COLOR, fontWeight: 'bold' }} for="Email">
                    {PlazaCartPartnerConst.PhoneNumber} <PutMandatoryfieldSymbol />
                  </p>
                  <input
                    type="text"
                    placeholder={PlazaCartPartnerPH.PhoneNumber}
                    name="phonenumber"
                    className="float-none"
                    value={this.state.userFields.phonenumber}
                    maxLength="12"
                    onChange={this.handleChange}
                    onBlur={this.handleValidationOnBlur}
                  />
                  <div className="col-sm-12 errorMsg mb-0">
                    {this.state.errors.phonenumber}
                  </div>
                </div>

                <div className="reg_form_main offset-sm-3 col-sm-6">
                  <p className="col-sm-12 textcolor" for="Password" style={{ fontWeight: 'bold' }}>
                    {PlazaCartPartnerConst.AddressLine1} <PutMandatoryfieldSymbol />
                  </p>
                  <div className="col-12 pr-0 pl-0" style={{ backgroundColor: "#f0f0f0", borderRadius: "50px", marginBottom: '15px', marginLeft: "0px", marginRight: "0px" }}>
                    <GooglePlacesAutocomplete
                      apiKey={GOOGLE_PLACE_KEY}
                      selectProps={{
                        value: this.state.selectedAddress,
                        onChange: (setAddress) => this.handelAddress(setAddress),
                        isClearable: true,
                        placeholder: PlazaCartPartnerPH.AddAddress,
                        styles: colourStyles
                      }}
                    />
                  </div>
                  <div className="col-sm-12 errorMsg mb-0">
                    {this.state.errors.addressline1}
                  </div>
                </div>

                <div className="reg_form_main offset-sm-3 col-sm-6">
                  <p className="col-sm-12 textcolor" style={{ color: Color.THEME_COLOR, fontWeight: 'bold' }} for="Email">
                    {PlazaCartPartnerConst.City} <PutMandatoryfieldSymbol />
                  </p>
                  <input
                    type="text"
                    placeholder={PlazaCartPartnerPH.City}
                    name="city"
                    className="float-none"
                    value={this.state.userFields.city}
                    onChange={this.handleChange}
                    onBlur={this.handleValidationOnBlur}
                  />
                  <div className="col-sm-12 errorMsg mb-0">
                    {this.state.errors.city}
                  </div>
                </div>

                <div className="reg_form_main offset-sm-3 col-sm-6">
                  <p className="col-sm-12 textcolor" style={{ color: Color.THEME_COLOR, fontWeight: 'bold' }} for="Email">
                    {PlazaCartPartnerConst.State} <PutMandatoryfieldSymbol />
                  </p>
                  <input
                    type="text"
                    placeholder={PlazaCartPartnerPH.State}
                    name="state"
                    className="float-none"
                    value={this.state.userFields.state}
                    onChange={this.handleChange}
                    onBlur={this.handleValidationOnBlur}
                  />
                  <div className="col-sm-12 errorMsg mb-0">
                    {this.state.errors.state}
                  </div>
                </div>

                <div className="reg_form_main offset-sm-3 col-sm-6">
                  <p className="col-sm-12 textcolor" style={{ color: Color.THEME_COLOR, fontWeight: 'bold' }} for="Email">
                    {PlazaCartPartnerConst.Country} <PutMandatoryfieldSymbol />
                  </p>
                  <input
                    type="text"
                    placeholder={PlazaCartPartnerPH.Country}
                    name="country"
                    className="float-none"
                    value={this.state.userFields.country}
                    onChange={this.handleChange}
                    onBlur={this.handleValidationOnBlur}
                  />
                  <div className="col-sm-12 errorMsg mb-0">
                    {this.state.errors.country}
                  </div>
                </div>

                <div className="reg_form_main offset-sm-3 col-sm-6">
                  <p className="col-sm-12 textcolor" for="FirstName" style={{ fontWeight: 'bold' }}>
                    {PlazaCartPartnerConst.ZIP_PostalCode}<PutMandatoryfieldSymbol />
                  </p>
                  <input
                    type="text"
                    placeholder={PlazaCartPartnerPH.ZIP_PostalCode}
                    name="postalcode"
                    className="float-none"
                    value={this.state.userFields.postalcode}
                    onChange={this.handleChange}
                    onBlur={this.handleValidationOnBlur}
                  />
                  <div className="col-sm-12 errorMsg mb-0">
                    {this.state.errors.postalcode}
                  </div>
                </div>

                <div className="reg_form_main offset-sm-3 col-sm-6 text-center">
                                {iscaptchreload && <ReCAPTCHA
                                    style={{ display: "inline-block" }}
                                    // theme="dark"
                                    // ref={this._reCaptchaRef}
                                    size="normal"
                                    sitekey={GOOGLESITEKEY}
                                    onChange={this.handleGoogleRecaptcha}
                                // asyncScriptOnLoad={this.asyncScriptOnLoad}
                                />}
                  <div className="col-sm-12 errorMsg mb-0 mt-2">
                    {this.state.errors.masterError}
                                </div>
                                {successMsg && <div class="alert alert-success" role="alert">
                                    {successMsg}
                                </div>}
                </div>

                <div className="reg_form_main">
                  <div className="bottam_sing" >
                    <button
                      style={{ borderRadius: "4px", backgroundColor: Color.THEME_COLOR, marginBottom: '10px' }}
                      type="button"
                      onClick={this.handleSubmit}
                      className="btn btn-done"
                      disabled={!googleReCaptch}
                    > {PlazaCartPartnerConst.btn_Submit}</button>
                  </div>
                </div>
              </div>

            </div>
          </section>
          <MainFooter />
        </div>
      </LoadingOverlay>
    );
  }
}
export default PlazaCartPartner;