import { RetriveObjectFromStorage, StoreObjectInStorage } from './Utils';
import Constants from './Constants';

export function getProductCountInCart(itemId, size, color) {
  console.log("=====")
  try {
    var objCart = RetriveObjectFromStorage(Constants.Obj_CART, '');
    let quntityCount = 0;
    if (objCart) {
      for (var objStorePlaza of objCart) {
        for (var item of objStorePlaza.Items) {
          if (item.ItemId == itemId) {
            if (item.TrackQuantity) {
              if (item.ItemType == "Q") {
                if ((item.size == size) && (item.color == color)) {
                  quntityCount = quntityCount + Number(item.ItemQuantity)
                }
              }
              else {
                quntityCount = quntityCount + Number(item.ItemQuantity)
              }
            }
          }
        }
      }
    }
    return quntityCount;
  }
  catch (err) {
    console.log("======")
    return ""
  }
}


export function getItemTaxes(itemTaxIds, allTaxList) {
  try {
    let taxList = allTaxList.filter(e => itemTaxIds.some(t => e._id == t.taxid))
    return taxList
  }
  catch (err) {
    console.log("=== getItemTax ==>", err)
    return []
  }
}