import React, { Component } from 'react';

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import Color from '../Config/Color';
import Constants, { currencyInfo, DEFAULTID, PlatFrom, PlazaCartAllStatus } from '../Config/Constants';
import Messages from "../Config/Messages";
import RouteConstant from '../Config/RouteConstant';
import { RemoveDataInStorage, RetriveDataFromStorage, RetriveObjectFromStorage, StoreDataInStorage, StoreObjectInStorage } from "../Config/Utils";
import Header from '../Header/Header';

import { GetData } from "../../services/GetData";
import { PostData } from '../../services/PostData';

import LoadingOverlay from 'react-loading-overlay';
import NoPriview from '../../../src/images/nopreviewp.png';
import shipping from '../../images/extraicon/shipping.png';
import shippingDis from '../../images/extraicon/shippingDis.png';
import truck from '../../images/extraicon/truck.png';
import truckDis from '../../images/extraicon/truckDis.png';
import { FAVORITE, GET_TENANT_API, GET_USERPROFILE_API, MERCHANT, MY_STORE_API, POSTALCODEKEY, PRODUCTS_API, SEARCHKEY } from '../Config/Config.json';
import PlazaStoreForm, { PlazaListHover } from '../Config/HoverMessage';
import { ItemClass, PlazaClass, StoreClass } from '../Config/PlazaClass';
import OnHoverIconInfo from '../UtillCustomComponent/UtillCustomComponent';
import './productSearch.css';

class ProductSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hideEmptyPlazaView: false,
            isActive: false,
            plazaList: [],

            productSearch: "",
            productSearchKey: "",

            allApplicationList: [],

            countryInfo: null,

            ProductSearchList: [],
            profileDetail: null,
            error: {},
            favoriteStoreIds: []
        }
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handelChange = this.handelChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        RemoveDataInStorage(Constants.Obj_CART)
        RemoveDataInStorage(Constants.finelObj_BeforePayemt)
        this.getUserDetail(authToken)
        this.getPlazaByAuthToken(authToken);
        this.getFavoriteStore(authToken)
    }

    getPlazaByAuthToken(authToken) {
        this.setState({ isActive: true });
        var API = GET_TENANT_API;
        GetData(API, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData) {
                        const x = responseData;
                        const { suggestedplaza, plaza, ...rest } = x;
                        var list = [];

                        StoreObjectInStorage(Constants.Obj_COUNTRY_FEES, rest);
                        if (responseData.suggestedplaza && responseData.suggestedplaza.length > 0) {
                            list = responseData.suggestedplaza;
                        }

                        if (responseData.plaza && responseData.plaza.length > 0) {
                            list = list.concat(responseData.plaza)
                            //  this.setState({ hideEmptyPlazaView: true });
                            //  this.setState({ isLoadingActive: false });
                        }
                        else {
                            this.setState({ isLoadingActive: false });
                            this.setState({ hideEmptyPlazaView: false });
                        }
                        this.setState({ countryInfo: rest })
                        this.setState({ plazaList: list });
                    }
                }
                else {
                    this.setState({ isLoadingActive: false });
                    this.setState({ hideEmptyPlazaView: false });
                }
                this.setState({ isActive: false });
            }).catch(error => {
                this.setState({ isActive: false });
            });
    }

    getUserDetail(authToken) {
        this.setState({ isLoadingActive: true });
        var API = GET_USERPROFILE_API;
        GetData(API, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData) {
                        this.setState({ profileDetail: responseData })
                        StoreObjectInStorage(Constants.Obj_USER, responseData)
                    }
                    this.setState({ isLoadingActive: false });
                }
                else {
                    this.setState({ isLoadingActive: false });
                    this.setState({ hideEmptyPlazaView: false });
                }
            }).catch(error => {
                this.setState({ isLoadingActive: false });
            });
    }

    getFavoriteStore(authToken) {
        var API = MERCHANT + "/" + FAVORITE;
        GetData(API, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    this.setState({ favoriteStoreIds: responseData ? responseData : [] });
                }
            });
    }

    handleRowClick(data) {
        StoreObjectInStorage(Constants.Obj_PLAZA, data)
        this.props.history.push({
            pathname: RouteConstant.PlazaStore,
        });
    }

    handelChange(event) {
        let value = event.target.value
        this.setState({ productSearch: value })
        if (!value) {
            this.setState({ productSearchKey: "", error: {} })
        }
    }

    searchItem = (e) => {
        e.preventDefault();
        var productSearch = String(this.state.productSearch).trim();
        if (productSearch.length > 2) {
            this.setState({ productSearchKey: productSearch, error: {} });
            this.callSearchApi();
        }
        else {
            var error = this.state.error
            error["searchProducterr"] = Messages.v_ProductSearch;
            this.setState({ error })
        }
    }

    handleKeyPress(event) {
        if (event.key == "Enter") {
            event.preventDefault()
            var productSearch = String(this.state.productSearch).trim();
            if (productSearch.length > 2) {
                this.setState({ productSearchKey: productSearch, error: {} });
                this.callSearchApi();
            }
            else {
                var error = this.state.error
                error["searchProducterr"] = Messages.v_ProductSearch;
                this.setState({ error });
            }
        }
    }

    callSearchApi() {
        /* previous comment code changes  */
        // let keyword = String(this.state.productSearch).replace(/[^A-Z0-9]/ig, " ").replace(/\s\s+/g, ' ').trim().split(" ")
        // let itemList = searchProductList.map(plazaObj => {
        //     let stores = plazaObj.stores.map(storeObj => {
        //         let Item = []
        //         for (let key in keyword) {
        //             var ItemList = storeObj.Item.filter(itemObj => filterFunction(itemObj, keyword[key]))
        //             Item = [...Item, ...ItemList]
        //         }
        //         Item = Item.reduce((unique, o) => {
        //             if (!unique.some(obj => obj.ItemID === o.ItemID)) {
        //                 unique.push(o);
        //             }
        //             return unique;
        //         }, []);
        //         return { ...storeObj, "Item": Item }
        //     })
        //     stores = stores.filter(storeObj => storeObj.Item.length > 0)
        //     return { ...plazaObj, "stores": stores }
        // })
        // itemList = itemList.filter(plazaObj => plazaObj.stores.length > 0)
        // this.setState({
        //     ItemSearchList: itemList
        // })
        /* New Update Code Changes */
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        let productSearch = this.state.productSearch;
        if (this.state.profileDetail) {
            this.getProductSearchapi(authToken, productSearch);
        }
    }

    getProductSearchapi(authToken, productSearch) {
        this.setState({ isActive: true });
        var API = MY_STORE_API + "/" + DEFAULTID + "/" + PRODUCTS_API + "?" + SEARCHKEY + String(productSearch).trim() + "&" + POSTALCODEKEY + this.state.profileDetail.PostalCode;
        GetData(API, authToken)
            .then(result => {
                var responseJson = result;
                console.log("Product Search =======", JSON.stringify(result))
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    this.setState({ ProductSearchList: responseData ? responseData : [] })
                }
                else {
                    this.setState({ hideEmptyPlazaView: false });
                }
                this.setState({ isActive: false });
            }).catch(error => {
                this.setState({ isActive: false });
            });
    }

    handelFavorite(plazaID, stroreID, isStoreFavorite) {
        var postData = {}
        postData["storeid"] = stroreID;
        postData['plazaid'] = plazaID;
        postData["Isfavorite"] = isStoreFavorite;
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        if (stroreID && plazaID) {
            this.setFavorite(authToken, postData);
        }
    }

    setFavorite(authToken, postData) {
        this.setState({ isActive: true });
        var URL = MERCHANT + "/" + FAVORITE;
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.getFavoriteStore(authToken);
                    var productSearch = String(this.state.productSearch).trim();
                    if (productSearch.length > 2) {
                        this.setState({ productSearchKey: productSearch, error: {} });
                        this.callSearchApi();
                    }
                    else {
                        var error = this.state.error
                        error["searchProducterr"] = Messages.v_ProductSearch;
                        this.setState({ error });
                    }
                }
                else {
                    this.setState({ isActive: false });
                }
            }).catch(error => {
                this.setState({ isActive: false });
            });
    }

    handleItemClick(itemObj, plazaObj) {
        if (plazaObj && plazaObj.TaxesDetail) {
            var co = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES);
            if (co) {
                co['TaxesDetail'] = plazaObj.TaxesDetail;
                StoreObjectInStorage(Constants.Obj_COUNTRY_FEES, co);
            }
        }

        var plazaObj = PlazaClass(itemObj);
        let logoPath = itemObj.logoImagepath ? itemObj.logoImagepath : "", bannerPath = itemObj.bannerImagepath ? itemObj.bannerImagepath : "", ToppingCategory = []
        if (itemObj.ToppingCategory) {
            ToppingCategory = itemObj.ToppingCategory.map(e => {
                var ToppingItem = []
                if (e.ToppingItems) {
                    ToppingItem = e.ToppingItems.filter(itemObj => itemObj.Status == PlazaCartAllStatus.ACTIVE)
                }
                return {
                    ...e,
                    "ToppingItems": ToppingItem
                }
            })
            ToppingCategory = ToppingCategory.filter(e => (e.ToppingItems.length > 0 && e.Status == PlazaCartAllStatus.ACTIVE))
        }
        itemObj = {
            ...itemObj,
            "AppLogo": logoPath.replace("##APPID##", itemObj.applicationguid) + itemObj.LogoPath,
            "AppBannerImage": bannerPath.replace("##APPID##", itemObj.applicationguid) + itemObj.bannerpath,
            "ToppingCategory": ToppingCategory
        }

        var storeObj = StoreClass(itemObj), itemobj = ItemClass(itemObj);
        StoreObjectInStorage(Constants.Obj_PLAZA, plazaObj);
        StoreObjectInStorage(Constants.Obj_STORE, storeObj);
        StoreObjectInStorage(Constants.Obj_STORE_ITEMS, itemobj);
        this.props.history.push({
            pathname: RouteConstant.AddToCart
        });
    }

    handelAppointment(e, item) {
        e.stopPropagation();
        if (item && item.items && item.items[0]) {
            var plazaObj = PlazaClass(item.items[0]);
            var storeKey = StoreClass(item.items[0]);
            StoreObjectInStorage(Constants.Obj_STORE, storeKey);
            StoreObjectInStorage(Constants.Obj_PLAZA, plazaObj);
            if (storeKey.isshopifyintegration) {
                // StoreDataInStorage(Constants.PLATFORM_FLAG, PlatFrom.Shopify);
                // this.props.history.push({
                //     pathname: RouteConstant.Appointment
                // });
            }
            else if (storeKey.iswixintegration) {
                // StoreDataInStorage(Constants.PLATFORM_FLAG, PlatFrom.Wix);
            }
            else {
                StoreDataInStorage(Constants.PLATFORM_FLAG, PlatFrom.Plazacart);
                this.props.history.push({
                    pathname: RouteConstant.Appointment
                });
            }
        }
    }

    render() {
        const { plazaList, hideEmptyPlazaView, productSearch, countryInfo, productSearchKey, selected } = this.state;
        const columns = [
            {
                maxWidth: "132px",
                cell: row => <img style={{ height: "70px", width: "70px", padding: "5px", borderRadius: "10px", cursor: "pointer" }} src={row.Imageurl ? row.Imageurl : NoPriview} onError={(e) => (e.target.onerror = null, e.target.src = NoPriview)} className="iconDetails" onClick={() => this.handleRowClick(row)} />,
                right: true
            },
            {
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px", cursor: "pointer" }} onClick={() => this.handleRowClick(row)}>{row.CompanyName}</div>
            },
        ];

        var currencySymbole = ""
        var countryFees = countryInfo
        if (countryFees) {
            currencySymbole = (countryFees[currencyInfo.CountryCode] ?
                "(" + countryFees[currencyInfo.CountryCode] + ") " : "") + countryFees.Symbol

        }

        const Arrow = ({ text, className }) => {
            return <div className={className}>{text}</div>;
        };

        const ArrowLeft = Arrow({ text: "<", className: "arrow-prev" });
        const ArrowRight = Arrow({ text: ">", className: "arrow-next" });

        const MenuItem = ({ text, selected }) => {
            return <div className={`menu-item ${selected ? 'active' : ''}`} style={{ padding: "10px 5px" }}>
                <div style={{ width: "unset", height: "150px", marginBottom: "5px" }}>
                    <img src={text.ItemLogo} style={{ width: "80px", height: "100px" }}></img>
                    <br />
                    <label className="textcolor" style={{ textOverflow: "ellipsis", overflow: "hidden", width: "100px" }}>{text.ItemName}</label>
                    <br />
                    <label className="textcolor">{currencySymbole} {Number(text.ItemPrice).toFixed(2)}</label>
                </div>

            </div>;
        };

        const Menu = (list, selected) =>
            list.map(el => {
                const { ItemID } = el;
                return <MenuItem text={el} key={ItemID} selected={this.state.selected == ItemID} />;
            });

        // this.menuItems = Menu(Itemdumy, selected);
        // const menu = this.menuItems;

        return (

            <div style={{ backgroundColor: Color.WHITE }}>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Constants.LoadingMssg}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className="">
                    <Header setHeaderTitle={Constants.Title_PlazaList} />
                    <section className="section" style={{ backgroundColor: Color.WHITE }}>
                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                            <div className="reg_form_main">
                                <div className="search-bar-box">
                                    <input type="text"
                                        className="search-input"
                                        placeholder="Search for Product"
                                        onChange={this.handelChange}
                                        onKeyPress={this.handleKeyPress} />
                                    <i class="fa fa-search search-icon1" aria-hidden="true" onClick={this.searchItem}></i>
                                </div>

                                <div className="col-md-12 errorMsg">
                                    {this.state.error.searchProducterr}
                                </div>

                                {/* <div className="reg_form_main">
                                    {searchProductList.map((plazaObj, plazaInd) => {
                                        return <>
                                            <ul class="list-unstyled store-title-box" key={plazaInd}>
                                                <li class="media">
                                                    <img src={plazaObj.Imageurl} class="p-2 plaza-logo" alt="..." />
                                                    <div class="media-body">
                                                        <h6 class="mt-0 mb-1">{plazaObj.CompanyName}</h6>
                                                        <p>{plazaObj.Address}</p>
                                                    </div>
                                                </li>
                                            </ul>
                                            {plazaObj.stores && plazaObj.stores.map((storeObj, storeInd) => {
                                                this.menuItems = Menu(storeObj.Item, selected);
                                                const menu = this.menuItems;
                                                return <div className="row m-0">
                                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                                        <div style={{ backgroundImage: "url('" + storeObj.AppBannerImage + "')", }}
                                                            className="store-banner-container">
                                                            <div className="icon1-box" >
                                                                <i class="fa fa-angle-double-right icon1"></i>
                                                            </div>

                                                            <div className="icon2-box">
                                                                <i class="fa fa-shopping-cart icon2"></i>
                                                            </div>

                                                            <div className="icon2-box" onClick={(e) => this.handelFavorite(e, text, true)}>
                                                                <OnHoverIconInfo
                                                                    title={<i class="fas fa-star icon2"  ></i>}
                                                                    message={PlazaStoreForm.AddToFavorites}
                                                                />
                                                            </div>

                                                            <div className={storeObj.IsDeliveryEnable ? "enableIconbg icon3-box" : "disableIconbg icon3-box"}
                                                            >
                                                                <OnHoverIconInfo
                                                                    title={<img src={storeObj.IsDeliveryEnable ? shipping : shippingDis} />}
                                                                    message={storeObj.IsDeliveryEnable ? PlazaStoreForm.DeliveryServicesAvailable : PlazaStoreForm.DeliveryServicesNotAvailable}
                                                                />
                                                            </div>

                                                            <div className={storeObj.IsPickupEnable ? "enableIconbg icon3-box" : "disableIconbg icon3-box"}
                                                            >
                                                                <OnHoverIconInfo
                                                                    title={<img src={storeObj.IsPickupEnable ? truck : truckDis} />}
                                                                    message={storeObj.IsPickupEnable ? PlazaStoreForm.PickupServiceAvailable : PlazaStoreForm.PickupServiceNotAvailable}
                                                                />
                                                            </div>

                                                            <div className="overlay">
                                                                <div style={{ width: "20%", height: "inherit", left: "0%", position: "absolute" }}>
                                                                    <img src={storeObj.AppLogo} style={{ width: "35px", height: "35px", margin: "5px", borderRadius: "5px" }} />
                                                                </div>
                                                                <div style={{ width: "80%", height: "inherit", position: "absolute", right: "0%" }} >
                                                                    <div className="row" style={{ marginLeft: "0px", height: "20%" }}>
                                                                        <label style={{ fontSize: "12px", color: Color.WHITE, }}>{storeObj.ApplicationName}</label>
                                                                    </div>
                                                                    <div className="row" style={{ marginLeft: "0px", width: "100%", height: "70%", marginTop: "6px" }}>
                                                                        <text style={{ fontSize: "11px", width: "inherit", height: "100%", color: Color.WHITE, maxLines: "2px", textOverflow: "ellipsis", overflow: "hidden", }}>
                                                                            {storeObj.AppAddress}
                                                                        </text>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-8 col-md-6 col-sm-12">
                                                        <ScrollMenu
                                                            arrowLeft={ArrowLeft}
                                                            arrowRight={ArrowRight}
                                                            data={menu}
                                                            selected={selected}
                                                            onSelect={this.onSelect}
                                                            alignCenter={false}
                                                        />
                                                    </div>
                                                </div>
                                            })}
                                        </>
                                    })}
                                </div> */}

                                {productSearchKey
                                    ?
                                    (this.state.ProductSearchList.length > 0 ? <div className="reg_form_main">
                                        {this.state.ProductSearchList.map((plazaObj, plazaInd) => {

                                            let plazaName = "", plazaImage = "", plazaAddress = "";
                                            let storeName = "", storeImage = "", storeAddress = "", IsDeliveryEnable = false, IsPickupEnable = false, isStoreFavorite = false, plazaID = '', stroreID = '', IsStoreOpen = false;

                                            if (plazaObj && plazaObj.isstoreopen) {
                                                IsStoreOpen = plazaObj.isstoreopen;
                                            }

                                            if (plazaObj.items) {
                                                for (var obj of plazaObj.items.slice(0, 1)) {
                                                    plazaName = obj.plazaname
                                                    storeName = obj.storename
                                                    storeImage = ("" + obj.bannerImagepath).replace("##APPID##", obj.applicationguid) + obj.bannerpath
                                                    storeAddress = obj.StreetAddress
                                                    IsDeliveryEnable = obj.IsDeliveryEnable
                                                    IsPickupEnable = obj.IsPickupEnable
                                                    isStoreFavorite = this.state.favoriteStoreIds.some(e => e.storeid == obj.storeid);
                                                    plazaID = obj.plazaid;
                                                    stroreID = obj.storeid;
                                                }
                                            }

                                            return <>
                                                <ul class="list-unstyled plaza-title-box" key={plazaInd}>
                                                    <li class="media">
                                                        {/* <img src={plazaImage} class="p-2 plaza-logo" alt="..." /> */}
                                                        <div class="media-body">
                                                            <h5 class="mt-0 mb-1">{plazaName}</h5>
                                                            {/* <p>{plazaAddress}</p> */}
                                                        </div>
                                                    </li>
                                                </ul>
                                                <ul class="list-unstyled store-title-box" key={plazaInd}>
                                                    <li class="media">
                                                        <img src={storeImage ? storeImage : NoPriview} style={{ borderRadius: '10px' }} class="p-2 plaza-logo" alt="..." />
                                                        <div class="media-body storedetail-container d-md-flex d-sm-block d-block mb-sm-1 mb-1">
                                                            <div className="storedetail-box">
                                                                <div><h6 class="mt-0 mb-1 d-inline-block">{storeName}</h6>&nbsp;{(IsStoreOpen && (IsDeliveryEnable || IsPickupEnable)) ? <div style={{ borderRadius: '10px', display: 'inline-block', backgroundColor: Color.BLACK, width: 'fit-content', height: '25px' }}>
                                                                    <i class="fa fa-circle" style={{ fontSize: "10px", color: Color.LIGHT_GREEN, marginLeft: '2px' }}></i><label style={{ marginLeft: '2px', marginRight: '5px', fontSize: '13px', color: Color.WHITE, fontWeight: 'bold' }}>Open</label>
                                                                </div> : <div style={{ borderRadius: '10px', display: 'inline-block', backgroundColor: Color.BLACK, width: 'fit-content', height: '25px' }}>
                                                                    <i class="fa fa-circle" style={{ fontSize: "10px", color: Color.LIGHT_RED, marginLeft: '2px' }}></i><label style={{ marginLeft: '2px', marginRight: '5px', fontSize: '13px', color: Color.WHITE, fontWeight: 'bold' }}>Closed</label>
                                                                </div>}</div>
                                                                <p>{storeAddress}</p>
                                                            </div>
                                                            <div className="store-banner-container">

                                                                <div style={{ float: "right", marginRight: "5px", marginTop: "5px", height: "30px", width: "30px", color: Color.WHITE, textAlign: "center", backgroundColor: Color.THEME_COLOR, borderRadius: "50px" }} onClick={(e) => this.handelAppointment(e, plazaObj)}>
                                                                    <OnHoverIconInfo
                                                                        title={<i class="fas fa-calendar setaTag" style={{ fontSize: "18px", marginTop: "5px" }}></i>}
                                                                        message={PlazaStoreForm.bookappointment}
                                                                    />
                                                                </div>

                                                                <div className={IsPickupEnable ? "enableIconbg icon3-box" : "disableIconbg icon3-box"}>
                                                                    <OnHoverIconInfo
                                                                        title={<img src={IsPickupEnable ? truck : truckDis} style={{ width: '22px', height: '22px', marginTop: "3px" }} />}
                                                                        message={IsPickupEnable ? PlazaStoreForm.PickupServiceAvailable : PlazaStoreForm.PickupServiceNotAvailable} />
                                                                </div>

                                                                <div className={IsDeliveryEnable ? "enableIconbg icon3-box" : "disableIconbg icon3-box"}>
                                                                    <OnHoverIconInfo
                                                                        title={<img src={IsDeliveryEnable ? shipping : shippingDis} style={{ width: '22px', height: '22px', marginTop: "3px" }} />}
                                                                        message={IsDeliveryEnable ? PlazaStoreForm.DeliveryServicesAvailable : PlazaStoreForm.DeliveryServicesNotAvailable} />
                                                                </div>

                                                                <div className="icon2-box">
                                                                    <OnHoverIconInfo
                                                                        title={<i class="fas fa-star icon2" style={{ fontSize: "15px", color: isStoreFavorite ? Color.YELLOW : Color.WHITE }}
                                                                            onClick={() => this.handelFavorite(plazaID, stroreID, !isStoreFavorite)}
                                                                        ></i>}
                                                                        message={isStoreFavorite ? PlazaListHover.RemoveFromFavorites : PlazaListHover.AddToFavorites} />
                                                                </div>

                                                                <div className="icon2-box">
                                                                    <OnHoverIconInfo
                                                                        title={<i class="fa fa-shopping-cart icon2"></i>}
                                                                        message={PlazaStoreForm.CartServicesAvailable} />
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <div className="row m-0">
                                                    {plazaObj.items && plazaObj.items.map((itemObj, index) => {
                                                        return <div
                                                            class="col-lg-2 col-md-3 col-sm-6 col-6 text-center product-container"
                                                            style={{ marginBottom: "5px" }}
                                                            key={index}
                                                            onClick={(e) => this.handleItemClick(itemObj, plazaObj)}>
                                                            <img src={itemObj.ItemLogo ? itemObj.ItemLogo : NoPriview} onError={(e) => (e.target.onerror = null, e.target.src = NoPriview)} style={{ width: "120px", height: "120px", marginTop: "20px", borderRadius: '10px' }}></img>
                                                            <br />
                                                            <label className="textcolor" style={{ textOverflow: "ellipsis", overflow: "hidden", maxwidth: "100%" }}>{itemObj.ItemName}</label>
                                                            <br />
                                                            <label className="textcolor">{currencySymbole} {Number(itemObj.ItemPrice).toFixed(2)}</label>
                                                        </div>
                                                    })}
                                                </div>
                                            </>
                                        })}
                                    </div>
                                        :
                                        <div class="card text-center mt-2">
                                            <div class="card-body">
                                                <h6 class="card-title">No results for {this.state.productSearchKey}.</h6>
                                                <p class="card-text">Try checking your spelling or use more general terms</p>
                                            </div>
                                        </div>)
                                    :
                                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px", display: this.state.hideEmptyPlazaView }} >
                                        <DataTableExtensions
                                            data={plazaList}
                                            columns={columns}
                                            export={false}
                                            print={false}
                                            filter={false}
                                            filterHidden={false}
                                            filterPlaceholder={"Search for Plaza"}
                                            filterStyle={{ width: "100px" }}>
                                            <DataTable
                                                noHeader={true}
                                                noTableHead={true}
                                                //pagination
                                                onRowClicked={this.handleRowClick}
                                            />
                                        </DataTableExtensions>
                                    </div>
                                }
                            </div>
                        </div>
                        {/* </LoadingOverlay> */}
                    </section>
                </LoadingOverlay>
            </div>

        );
    }
}

export default ProductSearch;