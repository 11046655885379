import { LinkAuthenticationElement, PaymentElement, useElements, useStripe, AddressElement } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";

export default function StripeStandardCheckoutForm({ successPayment, billingAddress, UserProfile, phoneNumber }) {
    const stripe = useStripe();
    const elements = useElements();

    /* const [email, setEmail] = useState('');*/
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(null);

    /*console.log(billingAddress, UserProfile);*/

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            successPayment(paymentIntent);
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: window.location.href,
            },
        });

        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occurred." + error.message);
        }

        setIsLoading(false);
        /* console.log('Stripe', JSON.stringify(error)); */
    };

    const paymentElementOptions = {
        layout: "tabs",
        defaultValues: {
            billingDetails: {
                name: UserProfile ? UserProfile.FirstName + " " + UserProfile.LastName : "",
                phone: phoneNumber ? phoneNumber : "",
            },
        },
    }

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            {<LinkAuthenticationElement
                options={{
                    defaultValues: {
                        email: UserProfile ? UserProfile.Email : "",
                    },
                }}
                id="link-authentication-element"
                /* onChange={(e) => setEmail(e.target.value)} */ />}
            <AddressElement options={{
                mode: "billing",
                defaultValues: {
                    name: UserProfile ? UserProfile.FirstName + " " + UserProfile.LastName : "",
                    address: {
                        line1: (billingAddress && billingAddress.ApartmentNumber && billingAddress.StreetAddress) ? billingAddress.ApartmentNumber + billingAddress.StreetAddress : "",
                        line2: '',
                        city: '',
                        state: '',
                        postal_code: (billingAddress && billingAddress.PostalCode) ? billingAddress.PostalCode : "",
                        country: (billingAddress && billingAddress.CountryCode) ? billingAddress.CountryCode.toUpperCase() : "",
                    },
                },
            }} />
            <PaymentElement
                id="payment-element"
                options={paymentElementOptions} />
            <button disabled={isLoading || !stripe || !elements} id="submit">
                <span id="button-text">Pay now
                    {/* {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"} */}
                </span>
            </button>
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
}