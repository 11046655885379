import React, { Component } from 'react';
import Color from '../Config/Color';
import Header from '../Header/Header';

import RouteConstant from '../Config/RouteConstant';
//import { RadioGroup, RadioButton } from 'react-radio-buttons';

import { RadioGroup, Radio } from 'react-radio-group'
import Collapsible from 'react-collapsible';
import moment from "moment";
import AddressList from './AddressList';

import marker1 from "../../images/icons/marker1.png";
import ontime1 from "../../images/icons/on-time2.png";
import creditcard1 from "../../images/icons/credit-card2.png";
import people1 from "../../images/icons/people2.png";
import { GetData } from "../../services/GetData";
import pencil from "../../images/icons/pencil.png";
import { RetriveObjectFromStorage, RetriveDataFromStorage, StoreObjectInStorage, formatPhoneNumber, validatePhoneNumber } from '../Config/Utils';
import Constants, { currencyInfo } from '../Config/Constants';
import PickupTimeDay from './PickupTimeDay';
import DeliveryTimeDay from './DeliveryTimeDay';
import { COUPONS, STOREID, PLAZAID } from '../Config/Config.json'
import Messages from '../Config/Messages';
import discountcupon from "../../images/extraicon/coupon.png"

import PlazaStore from './PlazaStore';

import { PostData } from "../../services/PostData";
import { POST_ORDER, POST_ORDER_V2 } from '../Config/Config.json'

import Moment from 'moment';

// const listAddress = [
//     {
//         Id: 1,
//         streetaddress: "Wilson street",
//         aptunit: "100",
//         postalcode: "L5M6V9"
//     }
// ]

class Checkout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedValue: "1",
            selectedTipValue: "",
            userFields: {},
            errors: {},

            disableDeliveryTip: true,

            userDetails: {},
            countryFees: {},
            taxDetails: [],
            serviceFee: 0.0,
            subtotal: 0.0,
            totalCAD: 0.0,
            tipAmount: 0.0,
            deliveryCharge: 0.0,
            listAddress: [],
            specialdeliverycharges: 0.0,
            timeDayHeader: "",
            cartList: [],
            userAddress: {},

            finalCartObj: {},

            couponList: [],
            isPickupAvailable: true,
            isDeliveryAvailable: true,
            totalItemTax: 0.0,
            tempSelectedCoupon: null,
            selectedCoupon: null,

            plazaSubtotel_Tfreep: 0.0,
            deliveryCharge_TfreeP: 0.0,
            specialdeliverycharges_TfreeP: 0.0,
            totalItemTax_TfreeP: 0.0
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeRadioBtn = this.handleChangeRadioBtn.bind(this);
        this.handleChangeTipRadioBtn = this.handleChangeTipRadioBtn.bind(this);
        this.handlePayment = this.handlePayment.bind(this);
        this.handleDeliveryTip = this.handleDeliveryTip.bind(this);
        this.setDeliveryCharge = this.setDeliveryCharge.bind(this);
        this.updateCartObj = this.updateCartObj.bind(this);
    }

    componentDidMount() {

        // this.setState({ selectedValue: "1" });
        // this.setState({ timeDayHeader: "Pickup" });

        var objCart = RetriveObjectFromStorage(Constants.Obj_CART, '');
        //console.log("objCart ", objCart);

        var plazaid = "", storeid = ""

        if (objCart && objCart.length > 0) {
            /*  var objCart = */
            objCart.map(e1 => {
                plazaid = e1.PlazaId
                e1.Stores.map(e2 => {
                    storeid = e2.ObjectId
                    // e2.DeliveryDate = Moment().format("DD MMM YYYY");
                    // if (this.state.selectedValue == "1") {
                    //     e2.DeliveryTime = e2.Pickuptimes[0] ? e2.Pickuptimes[0].PickupTime : "";
                    // }
                    // else {
                    //     e2.DeliveryTime = e2.Deliverytimes[0] ? e2.Deliverytimes[0].DeliveryTime : "";
                    // }

                    // return { ...e2 }
                })
                return { ...e1 }
            })
            this.setState({ cartList: objCart });
        }

        if (plazaid && storeid) {
            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            this.getCouponAPI(authToken, storeid, plazaid)
        }

        var objUserDetails = RetriveObjectFromStorage(Constants.Obj_USER, '');
        //console.log("Obj_USER ", objUserDetails);
        if (objUserDetails) {
            var userFields = this.state.userFields
            userFields["MobileNumber"] = formatPhoneNumber(objUserDetails.Phone1)
            this.setState({ userFields })
            this.setState({ userDetails: objUserDetails }, function () {
                if (objUserDetails.Addresslist) {
                    this.setState({ listAddress: objUserDetails.Addresslist })
                    if (objUserDetails.Addresslist.length > 0) {
                        this.setState({ userAddress: objUserDetails.Addresslist[0] })
                    }
                }
            })
        }

        var objCountryFees = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES, '');
        //console.log("objCountryFees ", objCountryFees);        

        if (objCountryFees) {
            this.setState({ countryFees: objCountryFees }, function () {
                this.setState({ serviceFee: this.state.countryFees.ServiceFee }, function () {
                    this.setState({ taxDetails: this.state.countryFees.TaxesDetail }, function () {
                        this.setSubTotal();
                    });
                })
            });
        }
    }

    getCouponAPI(authToken, storeid, plazaid) {
        this.setState({ isLoadingActive: true });
        var API = COUPONS + "?" + STOREID + storeid + "&" + PLAZAID + plazaid;
        GetData(API, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    this.setState({ couponList: responseData ? responseData : [] })
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong);
                }
            });
    }

    selecteUpdateAddress = (addressObj) => {
        var addressObjlength = Object.keys(addressObj).length;
        if (addressObjlength > 0) {
            this.setState({ userAddress: addressObj })
        }
    }

    setSubTotal() {
        var cartList = this.state.cartList;
        var isPickupAvailable = false, isDeliveryAvailable = false
        if (cartList.length > 0) {
            var plazaTotalAmount = 0.0, TotalItemTax = 0.0, plazaSubtotel_Tfreep = 0.0, totalItemTax_TfreeP = 0.0
            let list = cartList
                .map(function (item, index) {
                    var store = item.Stores;
                    store.map(function (store, index1) {
                        isPickupAvailable = store.IsPickupEnable
                        isDeliveryAvailable = store.IsDeliveryEnable
                        var storeItemList = store.Items;
                        storeItemList.map(function (objitem, index2) {
                            var data = objitem;
                            plazaTotalAmount = plazaTotalAmount + ((data.TotalItemPrice / data.ItemQuantity) * data.ItemQuantity);
                            if (!data.IsProductTax) {
                                plazaSubtotel_Tfreep = plazaSubtotel_Tfreep + ((data.TotalItemPrice / data.ItemQuantity) * data.ItemQuantity)
                            }
                            TotalItemTax = TotalItemTax + (((data.TotalItemPrice / data.ItemQuantity) * data.ItemQuantity) * data.ItemTax)
                            if (!data.IsProductTax) {
                                totalItemTax_TfreeP = totalItemTax_TfreeP + (((data.TotalItemPrice / data.ItemQuantity) * data.ItemQuantity) * data.ItemTax)
                            }
                        })
                    })
                })
            this.setState({
                plazaSubtotel_Tfreep: plazaSubtotel_Tfreep,
                totalItemTax_TfreeP: totalItemTax_TfreeP,
                subtotal: plazaTotalAmount,
                isPickupAvailable: isPickupAvailable,
                isDeliveryAvailable: isDeliveryAvailable,
                totalItemTax: TotalItemTax
            }, function () {
                if (isPickupAvailable) {
                    this.handleChangeRadioBtn("1")
                }
                else if (isDeliveryAvailable) {
                    this.handleChangeRadioBtn("2")
                }
                // this.countCADTotal1();
            })
        }
    }


    countCADTotal1() {
        var TotalCAD = 0.0, TotalCAD_TfreeP = 0.0;
        var taxValue = 0.0;
        var subTotal = this.state.subtotal;
        var serviceFee = this.state.countryFees.ServiceFee;
        var totalItemTax = this.state.totalItemTax
        serviceFee = serviceFee * this.state.cartList.length;
        this.setState({ serviceFee: serviceFee })
        var deliveryCharge = this.state.deliveryCharge;
        var specialdeliverycharges = this.state.specialdeliverycharges

        // /////////////////// tax free product //////////////////////
        var plazaSubtotel_Tfreep = this.state.plazaSubtotel_Tfreep
        var specialdeliverycharges_TfreeP = this.state.specialdeliverycharges_TfreeP
        var totalItemTax_TfreeP = this.state.totalItemTax_TfreeP
        // /////////////////// tax free product //////////////////////

        if (this.state.selectedValue === "2") {
            TotalCAD = parseFloat(subTotal) + parseFloat(serviceFee) + parseFloat(deliveryCharge) + parseFloat(specialdeliverycharges) + totalItemTax;

            // /////////// tax free product /////////////////
            TotalCAD_TfreeP = parseFloat(plazaSubtotel_Tfreep) + parseFloat(serviceFee) + parseFloat(deliveryCharge) + parseFloat(specialdeliverycharges_TfreeP) + totalItemTax_TfreeP
            // /////////// tax free product /////////////////

            if (this.state.taxDetails.length > 0) {
                this.state.taxDetails.map(function (item, index) {
                    var obj = item;
                    var value = Object.values(obj);
                    var keyValue = (TotalCAD_TfreeP * value) / 100;
                    taxValue = parseFloat(taxValue) + parseFloat(keyValue);
                })
            }
            TotalCAD = parseFloat(TotalCAD) + parseFloat(taxValue) + parseFloat(this.state.tipAmount)
            this.setState({ totalCAD: TotalCAD });
        } else {
            TotalCAD = parseFloat(subTotal) + parseFloat(serviceFee) + totalItemTax

            // /////////// tax free product /////////////////
            TotalCAD_TfreeP = parseFloat(plazaSubtotel_Tfreep) + parseFloat(serviceFee) + totalItemTax_TfreeP
            // /////////// tax free product /////////////////

            if (this.state.taxDetails.length > 0) {
                this.state.taxDetails.map(function (item, index) {
                    var obj = item;
                    var value = Object.values(obj);
                    var keyValue = (TotalCAD_TfreeP * value) / 100;
                    taxValue = parseFloat(taxValue) + parseFloat(keyValue);

                })
            }
            TotalCAD = parseFloat(TotalCAD) + parseFloat(taxValue)
            this.setState({ totalCAD: TotalCAD });
        }
    }

    //////////////////////////////////// understand ///////////////////////////////////////

    setDeliveryCharge = value => {
        var cost = parseFloat(value)
        this.setState({ deliveryCharge: cost }, function () {
            this.countCADTotal1();
        });
    }

    handleChangeRadioBtn(value) {
        //console.log(value)

        var errors = this.state.errors;
        errors['dateandtimes'] = ""
        this.setState({ errors })

        /////////////////////////////////////////////////////////////////
        var fineldeliveryCharges = 0.0
        var specialdeliverycharges = 0.0, specialdeliverycharges_TfreeP = 0.0

        var cartList = this.state.cartList.map(e1 => {
            var plazzadeliveryCharge = 0.0


            e1.Stores.map(e2 => {
                e2.DeliveryDate = Moment().format("DD MMM YYYY");
                if (value == "1") {
                    e2.DeliveryTime = e2.Pickuptimes[0] ? e2.Pickuptimes[0].PickupTime : "";
                    e2.TotalDeliveryCharges = 0
                }
                else {
                    e2.DeliveryTime = e2.Deliverytimes[0] ? e2.Deliverytimes[0].DeliveryTime : "";
                    e2.TotalDeliveryCharges = e2.Deliverytimes[0] ? e2.Deliverytimes[0].DeliveryCost : 0;
                    plazzadeliveryCharge = (e2.Deliverytimes[0] ? e2.Deliverytimes[0].DeliveryCost : 0);
                    e2.Items.map(e3 => {
                        if (e3.IsSpecialDeliveryItem) {
                            specialdeliverycharges = specialdeliverycharges + e3.specialdeliverycharges
                            if (!e3.IsProductTax) {
                                specialdeliverycharges_TfreeP = specialdeliverycharges_TfreeP + e3.specialdeliverycharges
                            }
                        }
                    })

                }
                return { ...e2 }
            })
            fineldeliveryCharges = fineldeliveryCharges + plazzadeliveryCharge
            console.log("============== shop list ======>", e1.Stores)
            return { ...e1 }
        })

        this.setState({ deliveryCharge: fineldeliveryCharges })

        this.setState({
            specialdeliverycharges: specialdeliverycharges,
            specialdeliverycharges_TfreeP: specialdeliverycharges_TfreeP
        })

        //////////////////////////////////////////////////////////////////

        this.setState({ cartList: cartList, tempSelectedCoupon: null, selectedCoupon: null })

        this.setState({ selectedValue: value }, function () {
            this.countCADTotal1();
        });
        if (value === "2") {
            this.setState({ timeDayHeader: "Delivery" });
            document.getElementById("addAddress").style.display = "block";
            document.getElementById("deliveryCharges").style.display = "block";
            document.getElementById("specialdeliveryCharges").style.display = "block";
            document.getElementById("deliveryTip").style.display = "block";

        } else {
            this.setState({ timeDayHeader: "Pickup" });
            // $('#pickupModal').modal('show')
            document.getElementById("addAddress").style.display = "none";
            document.getElementById("deliveryCharges").style.display = "none";
            document.getElementById("specialdeliveryCharges").style.display = "none";
            document.getElementById("deliveryTip").style.display = "none";

        }
    }

    handleChange(event) {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        if (key == "MobileNumber") {
            userFields[key] = formatPhoneNumber(value)
        }
        else {
            userFields[key] = value
        }
        this.setState({ userFields })
    }

    validateForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;

        if (!fields["tip"]) {
            formIsValid = false;
            errors["tip"] = Messages.v_EnterTip
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleChangeTipRadioBtn(value) {
        this.setState({ selectedTipValue: value });
        if (value === "3") {
            this.setState({ disableDeliveryTip: false });
        } else {
            this.setState({ disableDeliveryTip: true });
        }
    }

    handleDeliveryTip() {

        if (this.state.selectedTipValue === "3") {
            if (this.state.tipAmount > 0) {
                var cad = parseFloat(this.state.totalCAD) - parseFloat(this.state.tipAmount);
                var tip = parseFloat(this.state.userFields.tip);
                this.setState({ tipAmount: tip }, function () {
                    var cad1 = parseFloat(cad) + parseFloat(tip);
                    this.setState({ totalCAD: cad1 })
                });
            }
            else {
                var tip = parseFloat(this.state.userFields.tip);
                this.setState({ tipAmount: tip }, function () {
                    var cad = parseFloat(this.state.totalCAD) + parseFloat(this.state.tipAmount);
                    this.setState({ totalCAD: cad })
                });
            }
        } else if (this.state.selectedTipValue === "2") {
            var userFields = this.state.userFields;
            userFields["tip"] = 0.00;
            this.setState({ userFields });

            var tip = 5.00;
            if (this.state.tipAmount > 0) {
                var cad = parseFloat(this.state.totalCAD) - parseFloat(this.state.tipAmount);
                tip = parseFloat(tip);
                this.setState({ tipAmount: tip }, function () {
                    var cad1 = parseFloat(cad) + parseFloat(tip);
                    this.setState({ totalCAD: cad1 })
                });
            }
            else {
                var tip = parseFloat(tip);
                this.setState({ tipAmount: tip }, function () {
                    var cad = parseFloat(this.state.totalCAD) + parseFloat(this.state.tipAmount);
                    this.setState({ totalCAD: cad })
                });
            }
        }
        else {
            var userFields = this.state.userFields;
            userFields["tip"] = 0.00;
            this.setState({ userFields });
            var tip = 0.00;
            if (this.state.tipAmount > 0) {
                var cad = parseFloat(this.state.totalCAD) - parseFloat(this.state.tipAmount);
                tip = parseFloat(tip);
                this.setState({ tipAmount: tip }, function () {
                    var cad1 = parseFloat(cad) + parseFloat(tip);
                    this.setState({ totalCAD: cad1 })
                });
            }
        }
        $('#myModal').modal('hide')
    }

    handlePhoneSave = () => {
        var errors = this.state.errors;
        if (!this.state.userFields.MobileNumber) {
            errors['MobileNumber'] = Messages.v_EnterMoNo;
            this.setState({ errors })
        }
        else if (this.state.userFields.MobileNumber && !validatePhoneNumber(this.state.userFields.MobileNumber)) {
            errors["MobileNumber"] = Messages.v_EnterMoNoFormate;
            this.setState({ errors })
        }
        else {
            errors['MobileNumber'] = "";
            this.setState({ errors })
            var userFields = this.state.userFields;
            var userDetails = this.state.userDetails;
            userDetails['Phone1'] = userFields["MobileNumber"];
            this.setState({ userDetails })

            var finalCartObj = this.state.finalCartObj;
            finalCartObj.PhoneNumber = formatPhoneNumber(userFields.MobileNumber)
            this.setState({ finalCartObj })
        }

    }

    updateCartObj = (list) => {
        if (this.state.selectedValue !== "1") {
            var fineldeliveryCharges = 0.0

            var list1 = list.map(curr => {
                var deliveryplazzcharge = [];
                // fineldeliveryCharges = 0.0
                var plazzadeliveryCharge = 0.0
                var singletimePlazzadeliveryCharge = 0.0
                var storelist = curr.Stores
                var list2 = storelist.map(objStore => {

                    if (objStore.DeliveryDate !== "" && objStore.DeliveryTime !== "") {
                        const index = deliveryplazzcharge.findIndex((e) => (e.DeliveryDate === objStore.DeliveryDate && e.DeliveryTime === objStore.DeliveryTime));
                        if (index === -1 && objStore.DeliveryDate !== "") {
                            var obj = {}
                            obj['DeliveryDate'] = objStore.DeliveryDate;
                            obj['DeliveryTime'] = objStore.DeliveryTime;
                            obj['DeliveryPrice'] = objStore.TotalDeliveryCharges;
                            obj['IsSpecialDelivery'] = objStore.IsSpecialDelivery;
                            obj['specialdeliverycharges'] = objStore.specialdeliverycharges


                            deliveryplazzcharge.push(obj);
                            plazzadeliveryCharge = plazzadeliveryCharge + objStore.TotalDeliveryCharges;
                        }
                        else {
                            if (objStore.TotalDeliveryCharges > deliveryplazzcharge[index].DeliveryPrice) {
                                plazzadeliveryCharge = plazzadeliveryCharge + objStore.TotalDeliveryCharges - deliveryplazzcharge[index].DeliveryPrice;
                                deliveryplazzcharge[index].DeliveryPrice = objStore.TotalDeliveryCharges;

                            }

                        }
                    }
                    return { ...objStore }

                })


                fineldeliveryCharges = fineldeliveryCharges + plazzadeliveryCharge
                return { ...curr, Stores: list2 }

            })


            this.setState({ deliveryCharge: fineldeliveryCharges }, function () {

                this.countCADTotal1();
            })

        }
        else {
            this.setState({ deliveryCharge: 0.0 })
            this.setState({ tipAmount: 0.0 })
        }

        this.setState({ cartList: list }, function () {
            console.log("updated1 ", this.state.cartList);
        })
    }

    handlePayment() {
        this.createCartObject();



        // this.props.history.push({
        //     pathname: RouteConstant.Payment,
        // })
    }

    handelCouponRadio = (couponObj) => {
        if (this.state.tempSelectedCoupon) {
            this.setState({
                tempSelectedCoupon: this.state.tempSelectedCoupon._id != couponObj._id ? couponObj : null,
                selectedCoupon: this.state.tempSelectedCoupon._id != couponObj._id ? this.state.selectedCoupon : null
            })
        }
        else {
            this.setState({ tempSelectedCoupon: couponObj })
        }
    }

    handelCoupon = () => {
        this.setState({ selectedCoupon: this.state.tempSelectedCoupon })
    }

    validateformCart() {
        let formIsValid = true;
        var errors = this.state.errors;
        var plazzaList = this.state.cartList;
        for (var plazza of plazzaList) {
            var storeList = plazza.Stores;
            for (var store of storeList) {
                if (this.state.selectedValue === '1') {
                    if (store.Pickuptimes.length === 0) {
                        formIsValid = false;
                        errors['dateandtimes'] = Messages.v_PickupTime;
                        break;
                    }
                }
                else {
                    if (store.Deliverytimes.length === 0) {
                        formIsValid = false;
                        errors['dateandtimes'] = Messages.v_DeliveryTime;
                        break;
                    }
                    if (store.DeliveryDate == "" || store.DeliveryTime == "") {
                        formIsValid = false;
                        errors['dateandtimes'] = Messages.v_SelectDateAndTime;
                        break;
                    }
                }


            }
        }
        if (this.state.selectedValue !== "1") {
            var addressObjlength = Object.keys(this.state.userAddress).length;
            if (addressObjlength == 0) {
                formIsValid = false;
                errors['deliveryAddress'] = Messages.v_deliveryAddress
            }
        }
        if (!this.state.userFields.MobileNumber) {
            // errors['MobileNumber'] = Messages.v_EnterMoNo;
            formIsValid = false;
        }
        this.setState({ errors })
        return formIsValid;
    }

    createCartObject() {

        if (this.validateformCart()) {
            var IsPickupOrder = false;
            if (this.state.selectedValue === "1") {
                IsPickupOrder = true
            } else {
                IsPickupOrder = false
            }

            let that = this.state

            var userAddress = this.state.userAddress;
            var address = userAddress.ApartmentNumber + " " + userAddress.StreetAddress + " " + userAddress.PostalCode;

            var finalCartObj = this.state.finalCartObj;
            finalCartObj["CustomerEmail"] = this.state.userDetails.Email
            finalCartObj["PhoneNumber"] = formatPhoneNumber(this.state.userDetails.Phone1)
            finalCartObj["IsPickupOrder"] = IsPickupOrder
            finalCartObj["Currency"] = this.state.countryFees.Currency
            finalCartObj["PaymentToken"] = "NONCE TOKEN FOR PAYMENT IN BACKEND";
            if (this.state.selectedValue === "1") {
                finalCartObj["DeliveryAddress"] = ""
                finalCartObj["dCardAddress"] = undefined
            }
            else {
                finalCartObj["DeliveryAddress"] = address;
                finalCartObj["dCardAddress"] = userAddress
            }

            finalCartObj["Additionalinstructions"] = ""
            //finalCartObj["Plaza"] = this.state.cartList

            var totalAmount = 0.0, totalAmount_TfreeP = 0.0;
            var totalTax = 0.0, totalTax_TfreeP = 0.0;
            var totalProductAmount = 0.0;
            var fineldeliveryCharges = 0.0
            var specialdeliverycharges = 0.0, specialdeliverycharges_tfreeP = 0.0
            var totalItemTax = 0.0

            var list1 = this.state.cartList.map(curr => {
                // totalProductAmount = 0.0;
                var plazaSubTotal = 0.0, plazaSubTotal_TfreeP = 0.0;
                var noOfItems = 0;
                var singletimePlazzadeliveryCharge = 0.0;
                var plazzadeliveryCharge = 0;
                var deliveryplazzcharge = [];
                var storelist = curr.Stores

                var list2 = storelist.map(objStore => {

                    if (this.state.selectedValue !== "1") {
                        const index = deliveryplazzcharge.findIndex((e) => (e.DeliveryDate === objStore.DeliveryDate && e.DeliveryTime === objStore.DeliveryTime));
                        if (index === -1 && objStore.DeliveryDate !== "") {
                            var obj = {}
                            obj['DeliveryDate'] = objStore.DeliveryDate;
                            obj['DeliveryTime'] = objStore.DeliveryTime;
                            obj['DeliveryPrice'] = objStore.TotalDeliveryCharges;
                            obj['IsSpecialDelivery'] = objStore.IsSpecialDeliveryItem;
                            obj['specialdeliverycharges'] = objStore.specialdeliverycharges

                            deliveryplazzcharge.push(obj);
                            plazzadeliveryCharge = plazzadeliveryCharge + objStore.TotalDeliveryCharges;

                        }
                        else {
                            if (objStore.TotalDeliveryCharges > deliveryplazzcharge[index].DeliveryPrice) {
                                plazzadeliveryCharge = plazzadeliveryCharge + objStore.TotalDeliveryCharges - deliveryplazzcharge[index].DeliveryPrice;
                                deliveryplazzcharge[index].DeliveryPrice = objStore.TotalDeliveryCharges;
                            }

                        }

                    }

                    var storeSubTotal = 0.0, storeSubTotal_TfreeP = 0.0;
                    var storeItemList = objStore.Items;
                    noOfItems = noOfItems + objStore.Items.length
                    storeItemList.map(function (objitem, index2) {
                        var data = objitem;
                        storeSubTotal = storeSubTotal + ((data.TotalItemPrice / data.ItemQuantity) * data.ItemQuantity) + (((data.TotalItemPrice / data.ItemQuantity) * data.ItemQuantity) * data.ItemTax)
                        objitem.TotalItemTax = ((data.TotalItemPrice / data.ItemQuantity) * data.ItemQuantity) * data.ItemTax

                        // ////////////////////// tax free product ///////////////////////////////////
                        if (!data.IsProductTax) {
                            storeSubTotal_TfreeP = storeSubTotal_TfreeP + ((data.TotalItemPrice / data.ItemQuantity) * data.ItemQuantity) + (((data.TotalItemPrice / data.ItemQuantity) * data.ItemQuantity) * data.ItemTax)
                        }
                        // ////////////////////// tax free product ///////////////////////////////////

                        if (objitem.IsSpecialDeliveryItem) {
                            if (that.selectedValue === "1") {
                                objitem.specialdeliverycharges = 0.0
                                specialdeliverycharges = 0.0
                                specialdeliverycharges_tfreeP = 0.0
                            }
                            else {
                                specialdeliverycharges = specialdeliverycharges + objitem.specialdeliverycharges
                                objitem.specialdeliverycharges = objitem.specialdeliverycharges

                                // ////////////////////// tax free product ///////////////////////////////////
                                if (!data.IsProductTax) {
                                    specialdeliverycharges_tfreeP = specialdeliverycharges_tfreeP + objitem.specialdeliverycharges
                                    objitem.specialdeliverycharges = objitem.specialdeliverycharges
                                }
                                // ////////////////////// tax free product ///////////////////////////////////
                            }


                        }
                    })

                    objStore["ServiceFeeAmount"] = this.state.countryFees.ServiceFee
                    objStore["SubTotal"] = storeSubTotal
                    // objStore["TotalDeliveryCharges"] = 0

                    objStore.ApplicationId = objStore.ObjectId;
                    objStore["specialdeliverycharges"] = specialdeliverycharges

                    var storeTex = 0.0 /* this.state.countryFees.ServiceFee + */
                    var storetotal = storeSubTotal + plazzadeliveryCharge + specialdeliverycharges

                    // ////////////////////// tax free product ///////////////////////////////////
                    var storeTex_TfreeP = 0.0 /* this.state.countryFees.ServiceFee + */
                    var storetotal_TfreeP = storeSubTotal_TfreeP + plazzadeliveryCharge
                    // ////////////////////// tax free product ///////////////////////////////////

                    if (this.state.taxDetails.length > 0) {
                        this.state.taxDetails.map(function (item, index) {
                            var obj = item;
                            var value = Object.values(obj);
                            var keyValue = (storetotal * value) / 100;
                            storeTex = parseFloat(storeTex) + parseFloat(keyValue);

                            // ////////////////////// tax free product ///////////////////////////////////
                            var keyValue_TfreeP = (storetotal_TfreeP * value) / 100;
                            storeTex_TfreeP = parseFloat(storeTex_TfreeP) + parseFloat(keyValue_TfreeP);
                            // ////////////////////// tax free product ///////////////////////////////////

                        })
                    }
                    objStore["TotalTaxes"] = storeTex_TfreeP

                    plazaSubTotal = plazaSubTotal + storeSubTotal
                    plazaSubTotal_TfreeP = plazaSubTotal_TfreeP + storeSubTotal_TfreeP
                    return { ...objStore }
                })


                totalProductAmount = totalProductAmount + plazaSubTotal


                fineldeliveryCharges = fineldeliveryCharges + plazzadeliveryCharge;

                curr.PlazaSubTotal = (Math.round(plazaSubTotal * 100) / 100).toFixed(2).toString()
                curr.ServiceFeeAmount = this.state.countryFees.ServiceFee
                var taxValue = 0.0, taxValue_TfreeP = 0.0

                var StoreTaxDetail = []
                var total = plazaSubTotal + this.state.countryFees.ServiceFee + plazzadeliveryCharge + specialdeliverycharges

                // ////////////////////// tax free product ///////////////////////////////////
                var total_TfreeP = plazaSubTotal_TfreeP + this.state.countryFees.ServiceFee + plazzadeliveryCharge + specialdeliverycharges_tfreeP
                // ////////////////////// tax free product ///////////////////////////////////


                if (this.state.taxDetails.length > 0) {
                    this.state.taxDetails.map(function (item, index) {
                        var obj = item;
                        var value = Object.values(obj);
                        var keyValue = (total * value) / 100;
                        taxValue = parseFloat(taxValue) + parseFloat(keyValue);

                        // ////////////////////// tax free product ///////////////////////////////////
                        var keyValue_TfreeP = (total_TfreeP * value) / 100;
                        taxValue_TfreeP = parseFloat(taxValue_TfreeP) + parseFloat(keyValue_TfreeP);
                        // ////////////////////// tax free product ///////////////////////////////////

                        var storeTaxObj = {}
                        storeTaxObj.TaxRate = parseFloat(value)
                        storeTaxObj.TaxType = String(Object.keys(item))
                        storeTaxObj.TotalTax = parseFloat(keyValue_TfreeP)
                        StoreTaxDetail.push(storeTaxObj)
                    })
                }

                // alert(total)
                totalTax = totalTax + taxValue;

                // ////////////////////// tax free product ///////////////////////////////////
                totalTax_TfreeP = totalTax_TfreeP + taxValue_TfreeP
                // ////////////////////// tax free product ///////////////////////////////////

                curr.PlazaTotal = (Math.round((total + taxValue_TfreeP) * 100) / 100).toFixed(2).toString()
                curr["TotalDeliveryAmount"] = parseFloat(plazzadeliveryCharge)
                curr["TotalPlazaAmount"] = (Math.round((total + taxValue_TfreeP + (this.state.tipAmount / this.state.cartList.length)) * 100) / 100).toFixed(2).toString()
                curr["PlazaTipAmount"] = (this.state.tipAmount / this.state.cartList.length)
                curr["TotalPlazaItems"] = noOfItems
                curr["TotalTaxes"] = (Math.round((taxValue_TfreeP) * 100) / 100).toFixed(2).toString()
                curr["specialdeliverycharges"] = specialdeliverycharges

                curr["TaxDetails"] = StoreTaxDetail
                totalAmount = totalAmount + total + taxValue
                totalAmount_TfreeP = totalAmount_TfreeP + total + taxValue_TfreeP

                return { ...curr, Stores: list2 }
            })

            var tipamount = (Math.round((this.state.tipAmount) * 100) / 100).toFixed(2).toString()

            finalCartObj["TotalAmount"] = (Math.round((totalAmount_TfreeP + this.state.tipAmount) * 100) / 100).toFixed(2).toString()

            var couponDetail = this.state.selectedCoupon, postCouponDetail = {}
            if (couponDetail) {
                postCouponDetail["couponid"] = couponDetail._id
                postCouponDetail["discountamount"] = couponDetail.DicountPrice
                postCouponDetail["coupontitle"] = couponDetail.Title
                postCouponDetail["coupondescription"] = couponDetail.Description
            }
            finalCartObj["CouponDetail"] = couponDetail ? postCouponDetail : null
            finalCartObj["TotalpayAmount"] = Number(totalAmount_TfreeP + this.state.tipAmount - (this.state.selectedCoupon ? Number(this.state.selectedCoupon.DicountPrice) : 0)).toFixed(2)
            finalCartObj["TotalTaxes"] = (Math.round((totalTax_TfreeP) * 100) / 100).toFixed(2).toString()
            finalCartObj["TotalProductAmount"] = (Math.round((totalProductAmount) * 100) / 100).toFixed(2).toString()
            finalCartObj["TipAmount"] = tipamount;
            finalCartObj["ServiceFeeAmount"] = (this.state.countryFees.ServiceFee * this.state.cartList.length)
            finalCartObj["TotalDeliveryAmount"] = fineldeliveryCharges + specialdeliverycharges

            finalCartObj["Plaza"] = list1
            console.log("finalCartObj ", finalCartObj)

            this.setState({ finalCartObj: finalCartObj }, function () {
                console.log("finalCartObj ==========", this.state.finalCartObj)
                // StoreObjectInStorage("postModel", finalCartObj)
                this.callPostOrderAPI(this.state.finalCartObj)
            })
        }
    }

    callPostOrderAPI(postData) {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        console.log("finalCartObj ==========", postData)

        StoreObjectInStorage(Constants.finelObj_BeforePayemt, postData);

        this.props.history.push({
            pathname: RouteConstant.Payment,
        })

        // var URL = POST_ORDER_V2;
        // PostData(URL, postData, authToken)
        //     .then(result => {
        //         var responseJson = result;
        //         if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
        //             var authToken = responseJson["Data"].Token;
        //             StoreDataInStorage(Constants.AUTH_TOKEN, authToken);
        //             if (this.state.checkedRememberMe) {
        //                 StoreDataInStorage(Constants.FLAG_REMEMBER_ME, true);
        //                 var userfields = this.state.userFields;
        //                 setCookie(Constants.Cookie_LoginCredential, userfields);
        //                 //cookie.save(Constants.Cookie_LoginCredential, userfields) 
        //             } else {
        //                 removeCookie(Constants.Cookie_LoginCredential)
        //             }

        //             alert()
        //             // this.props.history.push({
        //             //     pathname: RouteConstant.Dashboard,
        //             // })
        //             // window.location.reload(true);
        //         }
        //         else {
        //             alert(responseJson["Message"])
        //         }
        //     });
    }

    renderTaxDetails(data, countryFees, currencySymbole) {
        var amount = 0.0, totalItemTax_TfreeP = this.state.totalItemTax_TfreeP;
        if (this.state.selectedValue === "1") {
            var serviceFee = countryFees.ServiceFee * this.state.cartList.length;
            amount = parseFloat(this.state.plazaSubtotel_Tfreep) + parseFloat(serviceFee) + parseFloat(totalItemTax_TfreeP);
        } else {
            var serviceFee = countryFees.ServiceFee * this.state.cartList.length;
            amount = parseFloat(this.state.plazaSubtotel_Tfreep) + parseFloat(this.state.deliveryCharge) + parseFloat(serviceFee) + parseFloat(this.state.specialdeliverycharges_TfreeP) + parseFloat(totalItemTax_TfreeP);
        }
        return data.map(e => {
            var obj = e;
            var key = Object.keys(obj);
            var value = Object.values(obj);
            var keyvalue = (amount * value) / 100;
            return (<React.Fragment>
                <div className="row">
                    <label style={{ marginLeft: "10px", color: Color.BLACK }}> {key} {"(" + value + "%)"}</label>
                    <label style={{ marginLeft: "auto", color: Color.BLACK, marginRight: "10px" }}>{currencySymbole}{(Math.round(keyvalue * 100) / 100).toFixed(2)}</label>
                </div>
            </React.Fragment>
            )
        })
    }

    render() {
        const { cartList, countryFees, taxDetails } = this.state
        //console.log("cartList ", cartList)

        var currencySymbole = ""
        var countryFeesLocal = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES)
        if (countryFeesLocal) {
            currencySymbole = (countryFeesLocal[currencyInfo.CountryCode] ?
                "(" + countryFeesLocal[currencyInfo.CountryCode] + ") " : "") + countryFeesLocal.Symbol

        }

        return (
            <div>
                <Header setHeaderTitle={Constants.Title_CheckOut} />
                <section className="section">
                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                        <div className="reg_form_main">
                            <p className="col-sm-12" for="">
                                Select pickup or delivery option:
                            </p>
                            <RadioGroup name="options" selectedValue={this.state.selectedValue} onChange={this.handleChangeRadioBtn}>
                                {this.state.isPickupAvailable && <><Radio value="1" /> &nbsp;Curbside Pickup</>} <br />
                                {this.state.isDeliveryAvailable && <><Radio value="2" />&nbsp;Delivery to your address</>}
                            </RadioGroup>
                        </div>

                        <section id="addAddress" className="reg_form_main" style={{ display: "none" }}>
                            <div className="">
                                <Collapsible
                                    open={false}
                                    trigger={
                                        <div className="col-sm-12" style={{ marginTop: "10px", backgroundColor: Color.WHITE, paddingBottom: "5px", borderBottom: "1px solid" + Color.GREY }} >
                                            <div style={{ width: "100%" }}>
                                                <div className="row">
                                                    <img src={marker1} style={{ height: "30px", width: "30px" }}></img>
                                                    {/* <i class="fa fa-map-marker" style={{ fontSize: "30px", color: Color.RED }}></i> */}
                                                    <label style={{ marginLeft: "5px", color: Color.BLACK }}>Add Delivery address</label>
                                                    <i class="fa fa-caret-down" style={{ marginLeft: "auto", fontSize: "30px", color: Color.THEME_COLOR, float: "right", marginRight: "5px" }} aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    triggerWhenOpen={
                                        <div className="col-sm-12" style={{ marginTop: "10px", backgroundColor: Color.WHITE, paddingBottom: "5px", borderBottom: "1px solid" + Color.GREY }} >
                                            <div style={{ width: "100%" }}>
                                                <div className="row">
                                                    <img src={marker1} style={{ height: "30px", width: "30px" }}></img>
                                                    {/* <i class="fa fa-map-marker" style={{ fontSize: "30px", color: Color.RED }}></i> */}
                                                    <label style={{ marginLeft: "5px", color: Color.BLACK }}>Add Delivery address</label>
                                                    <i class="fa fa-caret-up" style={{ marginLeft: "auto", fontSize: "30px", color: Color.THEME_COLOR, float: "right", marginRight: "5px" }} aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </div>
                                    } >

                                    <div className="" style={{ marginTop: "10px" }}>
                                        <AddressList
                                            data={this.state.listAddress}
                                            updateAddressObj={this.selecteUpdateAddress}
                                        >
                                        </AddressList>

                                    </div>
                                </Collapsible>
                            </div>
                        </section>
                        <div className="errorMsg" style={{ color: "#cc0000" }}>
                            {this.state.errors.deliveryAddress}
                        </div>
                        <div className="reg_form_main">
                            <PlazaStore
                                updateCartObj={this.updateCartObj}
                                selectedRdoBtn={this.state.selectedValue}
                                timeDayHeader={this.state.timeDayHeader}
                                data={this.state.cartList}>
                            </PlazaStore>
                            {this.state.errors.dateandtimes}
                        </div>

                        <div className="reg_form_main">
                            <Collapsible
                                open={false}
                                trigger={
                                    <div className="col-sm-12" style={{ marginTop: "10px", backgroundColor: Color.WHITE, paddingBottom: "5px", borderBottom: "1px solid" + Color.GREY }} >
                                        <div style={{ width: "100%" }}>
                                            <div className="row">
                                                <img src={people1} style={{ height: "30px", width: "30px" }}></img>
                                                {/* <i class="fa fa-user" style={{ fontSize: "30px", color: Color.BLACK }}></i> */}
                                                <label style={{ marginLeft: "5px", color: Color.BLACK }}>Mobile Number</label>
                                                <i class="fa fa-caret-down" style={{ marginLeft: "auto", fontSize: "30px", color: Color.THEME_COLOR, float: "right", marginRight: "5px" }} aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                }
                                triggerWhenOpen={
                                    <div className="col-sm-12" style={{ marginTop: "10px", backgroundColor: Color.WHITE, paddingBottom: "5px", borderBottom: "1px solid" + Color.GREY }} >
                                        <div style={{ width: "100%" }}>
                                            <div className="row">
                                                <img src={people1} style={{ height: "30px", width: "30px" }}></img>
                                                {/* <i class="fa fa-user" style={{ fontSize: "30px", color: Color.BLACK }}></i> */}
                                                <label style={{ marginLeft: "5px", color: Color.BLACK }}>Mobile Number</label>
                                                <i class="fa fa-caret-up" style={{ marginLeft: "auto", fontSize: "30px", color: Color.THEME_COLOR, float: "right", marginRight: "5px" }} aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                } >

                                <div className="col-sm-12" >
                                    <label className="row" style={{ color: Color.BLACK, fontSize: "13px" }} >
                                        We use your Mobile Number to text or call you about your order.
                                    </label>
                                    <label className="row textcolor">
                                        Mobile Number (10 Digit)
                                    </label>
                                    <input
                                        className="row"
                                        type="text"
                                        placeholder="Mobile Number"
                                        maxLength="14"
                                        name="MobileNumber"
                                        value={this.state.userFields.MobileNumber}
                                        onChange={this.handleChange}
                                    />
                                    <div className="errorMsg" style={{ color: "#cc0000" }}>
                                        {this.state.errors.MobileNumber}
                                    </div>
                                    <div className="bottam_sing" style={{ borderBottom: "1px solid" + Color.GREY }}>
                                        <label
                                            style={{ marginLeft: "auto", float: "right", borderRadius: "50px", marginBottom: "10px" }}
                                            for="step5"
                                            id="continue-step5"
                                            onClick={this.handlePhoneSave}
                                            className="btn btn-done"
                                        > Save</label>
                                    </div>
                                </div>
                            </Collapsible>
                        </div>

                        <div className="reg_form_main">
                            <div className="col-sm-12" style={{ marginTop: "10px", backgroundColor: Color.WHITE, paddingBottom: "5px", borderBottom: "1px solid" + Color.GREY }} >
                                <div style={{ width: "100%" }}>
                                    <div className="row">
                                        <img src={creditcard1} style={{ height: "30px", width: "30px" }}></img>
                                        {/* <i class="fa fa-credit-card" style={{ fontSize: "30px", color: Color.BLACK }}></i> */}
                                        <label style={{ marginLeft: "5px", color: Color.BLACK }}>Total Payment</label>

                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-12" style={{ marginTop: "10px" }}>
                                <div className="row">
                                    <label style={{ marginLeft: "10px", color: Color.BLACK }}>Subtotal</label>
                                    <label style={{ marginLeft: "auto", color: Color.BLACK, marginRight: "10px" }}>{currencySymbole}{(Math.round(this.state.subtotal * 100) / 100).toFixed(2)}</label>
                                </div>
                                {this.state.totalItemTax != 0 && <div className="row">
                                    <label style={{ marginLeft: "10px", color: Color.BLACK }}>Total Product Tax</label>
                                    <label style={{ marginLeft: "auto", color: Color.BLACK, marginRight: "10px" }}>{currencySymbole}{(Math.round(this.state.totalItemTax * 100) / 100).toFixed(2)}</label>
                                </div>}
                                <div id="deliveryCharges" className="row" style={{ display: "none" }}>
                                    <label style={{ marginLeft: "10px", color: Color.BLACK }}>Delivery Charges</label>
                                    <label style={{ marginLeft: "auto", color: Color.BLACK, float: "right", marginRight: "10px" }}>{currencySymbole}{(Math.round(this.state.deliveryCharge * 100) / 100).toFixed(2)}</label>
                                </div>
                                <div id="specialdeliveryCharges" className="row" style={{ display: "none" }}>
                                    <label style={{ marginLeft: "10px", color: Color.BLACK }}>Special Delivery Charges</label>
                                    <label style={{ marginLeft: "auto", color: Color.BLACK, float: "right", marginRight: "10px" }}>{currencySymbole}{(Math.round(this.state.specialdeliverycharges * 100) / 100).toFixed(2)}</label>
                                </div>

                                <div className="row">
                                    <label style={{ marginLeft: "10px", color: Color.BLACK }}>Service Fee</label>
                                    <label style={{ marginLeft: "auto", color: Color.BLACK, marginRight: "10px" }}>{currencySymbole}{(Math.round(this.state.serviceFee * 100) / 100).toFixed(2)}</label>
                                </div>


                                {this.renderTaxDetails(taxDetails, countryFees, currencySymbole)}

                                {/* <div className="row">
                                    <label style={{ marginLeft: "10px", color: Color.BLACK }}>HST (13%)</label>
                                    <label style={{ marginLeft: "auto", color: Color.BLACK, marginRight: "10px" }}>{currencySymbole}</label>
                                  
                                    <TaxDetails
                                         setTaxDetails={this.setTaxDetails}
                                         subtotal={this.state.subtotal}
                                         deliveryCharge={this.state.deliveryCharge}
                                         radioBtnValue={this.state.selectedValue}
                                         taxList={taxDetails}>                                            
                                    </TaxDetails>
                                </div>                                */}

                                <div id="deliveryTip" style={{ display: "none" }}>
                                    <div className="row" style={{ borderBottom: "1px solid" + Color.GREY }}>
                                        <label style={{ marginLeft: "10px", color: Color.BLACK }}>Delivery Tip</label>
                                        <label style={{ marginLeft: "auto", color: Color.BLACK, marginRight: "10px" }}>{currencySymbole}{(Math.round(this.state.tipAmount * 100) / 100).toFixed(2)}</label>
                                    </div>

                                    <div className="col-sm-12" style={{ marginTop: "10px" }}>
                                        <div className="row">
                                            <label style={{ color: Color.BLACK, fontWeight: "bold" }}>Delivery Tip</label>
                                            <span style={{ marginLeft: "auto" }}>
                                                <label style={{ color: Color.BLACK, marginRight: "10px" }}>{currencySymbole}{(Math.round(this.state.tipAmount * 100) / 100).toFixed(2)}</label>
                                                <img data-toggle="modal" data-target="#myModal" src={pencil} style={{ height: "20px", width: "20px", float: "right", marginRight: "5px" }}></img>
                                                {/* <i data-toggle="modal" data-target="#myModal" class="fas fa-pencil-alt" style={{ float: "right", fontSize: "20px", color: Color.THEME_COLOR, marginRight: "5px" }}></i> */}
                                            </span>
                                        </div>
                                        <label className="row" style={{ color: Color.BLACK, fontSize: "12px" }}>Want to recognize your delivery person's efforts? Consider a larger tip as a thank you- 100% of the tip goes to them.</label>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-12" style={{ marginTop: "10px", borderBottom: "1px solid" + Color.GREY }}>
                                <div className="row">
                                    <label style={{ marginLeft: "10px", color: Color.BLACK }}>Total </label>
                                    <label style={{ marginLeft: "auto", color: Color.BLACK, marginRight: "10px" }}>{currencySymbole}{(Math.round(this.state.totalCAD * 100) / 100).toFixed(2)}</label>
                                </div>
                            </div>
                            {this.state.selectedCoupon && <div className="col-sm-12" style={{ marginTop: "10px", borderBottom: "1px solid" + Color.GREY }}>
                                <div className="row">
                                    <label style={{ marginLeft: "10px", color: Color.BLACK }}>Payable Amount  {currencySymbole} {Number(this.state.selectedCoupon.DicountPrice).toFixed(2) + " OFF "}
                                    </label>
                                    <label style={{ marginLeft: "auto", color: Color.BLACK, marginRight: "10px" }}>{currencySymbole} {Number(this.state.totalCAD - this.state.selectedCoupon.DicountPrice).toFixed(2)}</label>
                                </div>
                            </div>}

                            {/*  Coupon render /////////////////////////////////// */}
                            {/*  Coupon render /////////////////////////////////// */}
                            {/*  Coupon render /////////////////////////////////// */}
                            {/*  Coupon render /////////////////////////////////// */}

                            {this.state.couponList.length > 0 && <div className="reg_form_main">
                                <Collapsible
                                    open={false}
                                    trigger={
                                        <div className="col-sm-12" style={{ marginTop: "10px", backgroundColor: Color.WHITE, paddingBottom: "5px", borderBottom: "1px solid" + Color.GREY }} >
                                            <div style={{ width: "100%" }}>
                                                <div className="row">
                                                    <img src={discountcupon} style={{ height: "30px", width: "30px" }}></img>
                                                    {/* <i class="fa fa-user" style={{ fontSize: "30px", color: Color.BLACK }}></i> */}
                                                    <label style={{ marginLeft: "5px", color: Color.BLACK }}>Discount Coupon</label>
                                                    <i class="fa fa-caret-down" style={{ marginLeft: "auto", fontSize: "30px", color: Color.THEME_COLOR, float: "right", marginRight: "5px" }} aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    triggerWhenOpen={
                                        <div className="col-sm-12" style={{ marginTop: "10px", backgroundColor: Color.WHITE, paddingBottom: "5px", borderBottom: "1px solid" + Color.GREY }} >
                                            <div style={{ width: "100%" }}>
                                                <div className="row">
                                                    <img src={discountcupon} style={{ height: "30px", width: "30px" }}></img>
                                                    {/* <i class="fa fa-user" style={{ fontSize: "30px", color: Color.BLACK }}></i> */}
                                                    <label style={{ marginLeft: "5px", color: Color.BLACK }}>Discount Coupon</label>
                                                    <i class="fa fa-caret-up" style={{ marginLeft: "auto", fontSize: "30px", color: Color.THEME_COLOR, float: "right", marginRight: "5px" }} aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </div>
                                    } >
                                    <div className="reg_form_main">
                                        <p className="col-sm-12" for="">
                                            Select Discount Coupon option:
                                        </p>
                                        {this.state.couponList.map(e =>
                                            <div
                                                className={'row-over row setaTag ' + (Number(this.state.subtotal) >= Number(e.MinimumrequiredAmount) ? "" : "row-over-disable")}
                                                onClick={(Number(this.state.subtotal) >= Number(e.MinimumrequiredAmount)) ? () => this.handelCouponRadio(e) : () => { }}>
                                                <div className="setaTag">
                                                    <input type={"radio"}
                                                        checked={
                                                            ((this.state.tempSelectedCoupon
                                                                && (this.state.tempSelectedCoupon._id) == e._id))} />
                                                    <img src={e.imageurl} style={{ width: "70px", marginLeft: "10px" }}></img>
                                                </div>

                                                <div className='coupondesc couponblock'>
                                                    <label className="remove-mg" style={{ marginLeft: "10px", color: Color.BLACK, fontWeight: 'bold', }}>{e.Title}
                                                        <span className="coupondescription"> Minimum Required Amount {currencySymbole + " " + Number(e.MinimumrequiredAmount).toFixed(2)}</span></label>
                                                    <label className="remove-mg coupondescription" style={{ marginLeft: "10px", color: Color.BLACK }}> {e.Description} </label>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="col-sm-12" >
                                        <div className="bottam_sing" style={{ borderBottom: "1px solid" + Color.GREY }}>
                                            <label
                                                style={{ marginLeft: "auto", float: "right", borderRadius: "50px", marginBottom: "10px" }}
                                                for="step5"
                                                id="continue-step5"
                                                onClick={this.handelCoupon}
                                                className="btn btn-done"
                                            > Apply</label>
                                        </div>
                                    </div>
                                </Collapsible>
                            </div>}

                            {/*  End Coupon render ////////////////////////////////// */}
                            {/*  End Coupon render ////////////////////////////////// */}
                            {/*  End Coupon render ////////////////////////////////// */}
                            {/*  End Coupon render ////////////////////////////////// */}

                            <div class="modal" id="myModal">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div className="col-sm-12" style={{ marginTop: "10px" }}> Tip your delivery person  </div>
                                        <ul>
                                            <li style={{ color: Color.THEME_COLOR }}>100% of tips go to your delivery person</li>
                                            <li style={{ color: Color.THEME_COLOR }}>Tips are always optional</li>
                                        </ul>

                                        <div class="modal-body">
                                            <RadioGroup
                                                className="col-sm-12"
                                                name="tipOptions"
                                                selectedValue={this.state.selectedTipValue}
                                                onChange={this.handleChangeTipRadioBtn} >
                                                <Radio value="1" />&nbsp;None <br />
                                                <Radio value="2" />&nbsp;{currencySymbole}5.00<br />
                                                <Radio value="3" />&nbsp;Other Amount <br />
                                            </RadioGroup>
                                            <input
                                                type="text"
                                                disabled={this.state.disableDeliveryTip}
                                                placeholder="Delivery Tip (Optional)"
                                                name="tip"
                                                value={this.state.userFields.tip}
                                                onChange={this.handleChange}
                                            />
                                            <div className="errorMsg">
                                                {this.state.errors.tip}
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <label
                                                style={{ color: Color.THEME_COLOR }}
                                                for="step5"
                                                id="continue-step5"
                                                data-dismiss="modal"
                                            > Cancel</label>

                                            <label
                                                style={{ float: "center", borderRadius: "50px", marginBottom: "10px" }}
                                                for="step5"
                                                id="continue-step5"
                                                onClick={this.handleDeliveryTip}
                                                className="btn btn-done"
                                            > Save</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="modal" id="pickupModal">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        {/* <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                        </div> */}

                                        <div className="col-sm-12" style={{ marginTop: "10px" }}> Pickup message  </div>
                                        <div class="modal-body">
                                            <input
                                                // className="row"
                                                type="text"
                                                placeholder="Pickup message"
                                                name="pickupMessage"
                                                value={this.state.userFields.pickupMessage}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div class="modal-footer">
                                            <label
                                                style={{ color: Color.THEME_COLOR }}
                                                for="step5"
                                                id="continue-step5"
                                                data-dismiss="modal"
                                            > Cancel</label>

                                            <label
                                                style={{ float: "center", borderRadius: "50px", marginBottom: "10px" }}
                                                for="step5"
                                                id="continue-step5"
                                                onClick={this.handlePickupDialog}
                                                className="btn btn-done"
                                            > Save</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                    <div className="reg_form_main">
                        <div className="bottam_sing">
                            <label
                                style={{ backgroundColor: Color.THEME_COLOR, float: "center", borderRadius: "50px", marginBottom: "10px" }}
                                onClick={this.handlePayment}
                                className="btn btn-done"
                            > Complete Payment</label>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Checkout;