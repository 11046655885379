import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './ForgotPassword.css';
import "../../styles/style.css";
import Messages from "../Config/Messages";
import { validateEmail, StoreDataInStorage, validateOtpNo, validatePassword, RetriveDataFromStorage, logoutSession } from "../Config/Utils";
import { PostData } from "../../services/PostData";
import { PUTData } from "../../services/PUTData";
import { FORGOT_PASSWORD_API_v1, FORGOT_PASSWORD_VALID_OTP_API, RESET_PASSWORD_API } from '../Config/Config.json'
import Constants from '../Config/Constants'
import Header from '../Header/Header';
import Color from '../Config/Color';
import { ForgotPasswordConst } from '../Config/constvariable';
import { ForgotPasswordPH } from '../Config/Placeholder';
import LoadingOverlay from 'react-loading-overlay';
import MainHeader from '../MainHeader/MainHeader';
import MainFooter from '../MainFooter/MainFooter';
import { PutMandatoryfieldSymbol } from '../UtillCustomComponent/UtillCustomComponent';
import RouteConstant from '../Config/RouteConstant';

export default class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userFields: {},
            errors: {},
            isActive: false,
            isPassword: true,
            isConfirmPassword: true,
            page: 0
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleValidationOnBlurEmail = this.handleValidationOnBlurEmail.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    componentDidMount() {
        logoutSession()
    }

    handleChange(event) {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        userFields[key] = value
        this.setState({ userFields });
    }

    handleValidationOnBlurEmail(e) {
        let fields = this.state.userFields;
        let errors = this.state.errors;
        let formIsValid = true;
        let name = e.currentTarget.name;

        if (name === "Email") {
            if (!fields["Email"]) {
                formIsValid = false;
                errors["Email"] = Messages.v_EnterEmail;
            }
            else if (typeof fields["Email"] !== "undefined") {
                if (!validateEmail(fields["Email"])) {
                    formIsValid = false;
                    errors["Email"] = Messages.v_EnterValidEmail;
                }
            }
        }

        if (formIsValid) errors[name] = "";
        this.setState({
            fields,
            errors
        });
    }

    validateFormEmail() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;

        if (!fields["Email"]) {
            formIsValid = false;
            errors["Email"] = Messages.v_EnterEmail;
        }
        else if (typeof fields["Email"] !== "undefined") {
            if (!validateEmail(fields["Email"])) {
                formIsValid = false;
                errors["Email"] = Messages.v_EnterValidEmail;
            }
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSubmitEmail(e) {
        var url = "";
        var postData = {};
        e.preventDefault();
        if (this.validateFormEmail()) {
            this.setState({ isActive: true });
            postData = this.state.userFields;
            postData["DeviceId"] = "";/* Constants.DEVICE_ID; */
            postData["DeviceType"] = ""; /* Constants.DEVICE_TYPE; */
            this.callForgotPasswordAPI(postData);
        }
    }

    callForgotPasswordAPI(postData) {
        var URL = FORGOT_PASSWORD_API_v1;
        PostData(URL, postData)
            .then(result => {
                var responseJson = result;
                console.log('====', responseJson)
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var response = responseJson["Message"];
                    let errors = {};
                    errors["Email"] = Messages.MSG_CHECK_EMAIL;
                    this.setState({ errors: errors, isActive: false, page: 1 });
                } else if (responseJson["StatusCode"] === 400) {
                    let errors = {};
                    errors["Email"] = Messages.v_ForgotPaswword_Email;
                    this.setState({ errors: errors, isActive: false });
                }
                else {
                    let errors = {};
                    errors["Email"] = responseJson["Message"];
                    this.setState({ errors: errors, isActive: false });
                }
            }).catch(error => {
                let errors = {};
                errors["Email"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    ///////////////////////////////////////// page 1 method /////////////////////////////////////////
    ///////////////////////////////////////// page 1 method /////////////////////////////////////////
    ///////////////////////////////////////// page 1 method /////////////////////////////////////////

    handleValidationOnBlurOTP(e) {
        let fields = this.state.userFields;
        let errors = this.state.errors;
        let formIsValid = true;
        let name = e.currentTarget.name;

        if (name === "otp") {
            if (!fields["otp"]) {
                formIsValid = false;
                errors["otp"] = Messages.v_EnterOTP
            }
            else if (typeof fields["otp"] !== "undefined") {
                if (!validateOtpNo(fields["otp"])) {
                    formIsValid = false;
                    errors["otp"] = Messages.v_EnterValidOTP;
                }
            }
        }

        if (formIsValid) errors[name] = "";
        this.setState({
            fields,
            errors
        });
    }

    validateFormOTP() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;

        if (!fields["otp"]) {
            formIsValid = false;
            errors["otp"] = Messages.v_EnterOTP
        }
        else if (typeof fields["otp"] !== "undefined") {
            if (!validateOtpNo(fields["otp"])) {
                formIsValid = false;
                errors["otp"] = Messages.v_EnterValidOTP;
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSubmitOTP(e) {
        var url = "";
        var postData = {};
        e.preventDefault();
        if (this.validateFormOTP()) {
            this.setState({ isActive: true });
            postData["Email"] = this.state.userFields.Email;
            postData["DeviceId"] = "";
            postData["DeviceType"] = "";
            postData["Code"] = this.state.userFields.otp;
            this.callForgotPasswordValidOTPAPI(postData);
        }
    }

    callForgotPasswordValidOTPAPI(postData) {
        var URL = FORGOT_PASSWORD_VALID_OTP_API;
        PostData(URL, postData)
            .then(result => {
                var responseJson = result;
                console.log('====', responseJson)
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var authToken = responseJson["Data"].Token;
                    StoreDataInStorage(Constants.AUTH_TOKEN, authToken);
                    this.setState({ errors: {}, isActive: false, page: 2 });
                } else if (responseJson["StatusCode"] === 400) {
                    let errors = {};
                    errors["otp"] = responseJson.Message;
                    this.setState({ errors: errors, isActive: false });
                }
                else {
                    let errors = {};
                    errors["otp"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors, isActive: false });
                }
            }).catch(error => {
                let errors = {};
                errors["otp"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    ///////////////////////////////////////// page 1 method /////////////////////////////////////////
    ///////////////////////////////////////// page 1 method /////////////////////////////////////////
    ///////////////////////////////////////// page 1 method /////////////////////////////////////////

    //////////////////////////////////////// page 2 method ////////////////////////////////////////
    //////////////////////////////////////// page 2 method ////////////////////////////////////////
    //////////////////////////////////////// page 2 method ////////////////////////////////////////

    handleValidationOnBlurPassWord(e) {
        let fields = this.state.userFields;
        let errors = this.state.errors;
        let formIsValid = true;
        let name = e.currentTarget.name;

        if (name == "Password") {
            if (!fields["Password"]) {
                formIsValid = false;
                errors["Password"] = Messages.v_EnterPassword
            }
            else if (typeof fields["Password"] !== "undefined") {
                if (!validatePassword(fields["Password"])) {
                    formIsValid = false;
                    errors["Password"] = Messages.v_EnterValidPassword;
                }
            }
        }
        if (name == "ConfirmPassword") {
            if (!fields["ConfirmPassword"]) {
                formIsValid = false;
                errors["ConfirmPassword"] = Messages.v_EnterConfirmPassword
            }
            else if (typeof fields["ConfirmPassword"] !== "undefined") {
                if (!validatePassword(fields["ConfirmPassword"])) {
                    formIsValid = false;
                    errors["ConfirmPassword"] = Messages.v_EnterValidPassword;
                }
            }
        }

        if (formIsValid) errors[name] = "";
        this.setState({
            fields,
            errors
        });
    }

    validateFormPassWord() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;

        if (!fields["Password"]) {
            formIsValid = false;
            errors["Password"] = Messages.v_EnterCreateNewPassword
        }
        else if (typeof fields["Password"] !== "undefined") {
            if (!validatePassword(fields["Password"])) {
                formIsValid = false;
                errors["Password"] = Messages.v_EnterValidPassword;
            }
        }

        if (!fields["ConfirmPassword"]) {
            formIsValid = false;
            errors["ConfirmPassword"] = Messages.v_EnterCreateConfirmPassword;
        }
        else if (typeof fields["ConfirmPassword"] !== "undefined") {
            if (!validatePassword(fields["ConfirmPassword"])) {
                formIsValid = false;
                errors["ConfirmPassword"] = Messages.v_EnterValidPassword;
            }
        }
        else if (fields["ConfirmPassword"] != fields["Password"]) {
            formIsValid = false;
            errors["ConfirmPassword"] = Messages.v_ConfirmPwdMatchWithPwd;
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSubmitPassWord = (e) => {
        var url = "";
        var postData = {};
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        console.log("authToken", authToken);
        e.preventDefault();
        if (this.validateFormPassWord() && authToken) {
            this.setState({ isActive: true });
            postData = this.state.userFields;
            postData["Email"] = this.state.userFields.Email;
            postData["DeviceId"] = "";
            postData["DeviceType"] = "";
            this.callResetPasswordAPI(postData, authToken);
        }
    }

    callResetPasswordAPI(postData, authToken) {
        var URL = RESET_PASSWORD_API;
        PUTData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                console.log('====', responseJson)
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    StoreDataInStorage(Constants.AUTH_EMAIL, "");
                    StoreDataInStorage(Constants.AUTH_TOKEN, "");
                    let errors = {};
                    errors["ConfirmPassword"] = Messages.v_PasswordChanged;
                    this.setState({ errors: errors, isActive: false });
                    this.props.history.push({
                        pathname: RouteConstant.Login
                    });
                } else if (responseJson["StatusCode"] === 400) {
                    let errors = {};
                    errors[""] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors, isActive: false });
                }
                else {
                    let errors = {};
                    errors["ConfirmPassword"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors, isActive: false });
                }
            }).catch(error => {
                let errors = {};
                errors["ConfirmPassword"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    //////////////////////////////////////// page 2 method ////////////////////////////////////////
    //////////////////////////////////////// page 2 method ////////////////////////////////////////
    //////////////////////////////////////// page 2 method ////////////////////////////////////////

    goBack() {
        this.props.history.goBack();
    }

    render() {
        const { isPassword, isConfirmPassword } = this.state;
        return (
            <LoadingOverlay
                active={this.state.isActive}
                text={Constants.LoadingMssg}
                spinner
                styles={{
                    overlay: (base) => ({
                        ...base,
                        position: "fixed"
                    })
                }}
                className="" >
                <div>
                    <div class="hero_area">
                        <MainHeader />
                    </div>
                    {/*<Header setHeaderTitle={ForgotPasswordConst.Title_ForgotPwd} /> */}
                    <div>
                        <section className="section">
                            <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px", marginTop: "20px" }} >
                                {this.state.page == 0 &&
                                    <React.Fragment>
                                        <div className="reg_form_main offset-sm-3 col-sm-6">
                                            <p className="col-sm-12 textcolor" for="Email" style={{ fontWeight: 'bold' }}>
                                                {ForgotPasswordConst.YourEmail} <PutMandatoryfieldSymbol />
                                            </p>
                                            <input
                                                type="email"
                                                placeholder={ForgotPasswordPH.Email}
                                                className="float-none"
                                                name="Email"
                                                value={this.state.userFields.Email}
                                                onChange={this.handleChange}
                                                onBlur={this.handleValidationOnBlurEmail} />
                                            <div className="col-sm-12 errorMsg">
                                                {this.state.errors.Email}
                                            </div>{" "}
                                            <div className="col-sm-12 errorMsg">
                                                {this.state.alreadyEmail}
                                            </div>
                                        </div>
                                        <div className="reg_form_main">
                                            <div className="bottam_sing mt-3">
                                                <label
                                                    style={{ borderRadius: "4px", backgroundColor: Color.THEME_COLOR }}
                                                    onClick={(e) => this.handleSubmitEmail(e)}
                                                    className="btn btn-done"
                                                >{ForgotPasswordConst.btn_Submit}</label>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                                {this.state.page == 1 && <React.Fragment>
                                    <div className="reg_form_main offset-sm-3 col-sm-6">
                                        <p className="col-sm-12 textcolor" for="Email" style={{ fontWeight: 'bold' }}>
                                            {ForgotPasswordConst.ForgotPasswordValidOTP} <PutMandatoryfieldSymbol />
                                        </p>
                                        <input
                                            placeholder={ForgotPasswordPH.Enterotpnumber}
                                            className="float-none"
                                            value={this.state.userFields.otp}
                                            onChange={(e) => this.handleChange(e)}
                                            onBlur={(e) => this.handleValidationOnBlurOTP(e)}
                                            type="text"
                                            pattern="[0-9]*"
                                            inputmode="numeric"
                                            name="otp"
                                            maxLength="6" />
                                        <div className="col-sm-12 errorMsg">
                                            {this.state.errors.otp}
                                        </div>{" "}
                                        <div className="col-sm-12 errorMsg">
                                            {this.state.alreadyEmail}
                                        </div>
                                    </div>

                                    <div className="reg_form_main">
                                        <div className="bottam_sing mt-3">
                                            <label
                                                style={{ borderRadius: "4px", backgroundColor: Color.THEME_COLOR }}
                                                onClick={(e) => this.handleSubmitOTP(e)}
                                                className="btn btn-done"
                                            >{ForgotPasswordConst.btn_Submit}</label>
                                        </div>
                                    </div>
                                </React.Fragment>}
                                {this.state.page == 2 &&
                                    <React.Fragment>
                                        <div className="reg_form_main offset-sm-3 col-sm-6">
                                            <p className="col-sm-12 textcolor" for="Password" style={{ fontWeight: 'bold' }}>
                                                {ForgotPasswordConst.CreateNewPassword} <PutMandatoryfieldSymbol />
                                            </p>
                                            <div className="row" style={{ backgroundColor: "#f0f0f0", borderRadius: "50px", marginBottom: '15px', marginLeft: "0px", marginRight: "0px" }}>
                                                <input
                                                    style={{ width: "90%", margin: 0 }}
                                                    type={isPassword ? "password" : "text"}
                                                    placeholder={ForgotPasswordPH.Password}
                                                    name="Password"
                                                    className="float-none"
                                                    value={this.state.userFields.Password}
                                                    onChange={this.handleChange}
                                                    onBlur={(e) => this.handleValidationOnBlurPassWord(e)} />
                                                <i class={!isPassword ? "fa fa-eye" : "fa fa-eye-slash"} style={{ color: Color.THEME_COLOR, width: '30px', marginTop: "14px" }} onClick={() => this.setState({ isPassword: !isPassword })} />
                                            </div>
                                            <div className="col-sm-12 errorMsg mb-0">
                                                {this.state.errors.Password}
                                            </div>
                                        </div>

                                        <div className="reg_form_main offset-sm-3 col-sm-6">
                                            <p className="col-sm-12 textcolor" for="Password" style={{ fontWeight: 'bold' }}>
                                                {ForgotPasswordConst.CreateNewConfirmPassword} <PutMandatoryfieldSymbol />
                                            </p>
                                            <div className="row" style={{ backgroundColor: "#f0f0f0", borderRadius: "50px", marginBottom: '15px', marginLeft: "0px", marginRight: "0px" }}>
                                                <input
                                                    style={{ width: "90%", margin: 0 }}
                                                    type={isConfirmPassword ? "password" : "text"}
                                                    placeholder={ForgotPasswordPH.ConfirmPassword}
                                                    name="ConfirmPassword"
                                                    className="float-none"
                                                    value={this.state.userFields.ConfirmPassword}
                                                    onChange={this.handleChange}
                                                    onBlur={(e) => this.handleValidationOnBlurPassWord(e)} />
                                                <i class={!isConfirmPassword ? "fa fa-eye" : "fa fa-eye-slash"} style={{ color: Color.THEME_COLOR, width: '30px', marginTop: "14px" }} onClick={() => this.setState({ isConfirmPassword: !isConfirmPassword })} />
                                            </div>
                                            <div className="col-sm-12 errorMsg mb-0">
                                                {this.state.errors.ConfirmPassword}
                                            </div>
                                        </div>

                                        <div className="reg_form_main">
                                            <div className="bottam_sing mt-3">
                                                <label
                                                    style={{ borderRadius: "4px", backgroundColor: Color.THEME_COLOR }}
                                                    onClick={this.handleSubmitPassWord}
                                                    className="btn btn-done"
                                                >{ForgotPasswordConst.btn_Submit}</label>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                        </section>
                    </div>
                    <MainFooter />
                </div>
            </LoadingOverlay>
        );
    }
}

