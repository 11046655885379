import React, { Component } from 'react';
import Constants from '../Config/Constants'
import { RetriveDataFromStorage, RetriveObjectFromStorage, checkPostalCode, removeAllSpace, RemoveDataInStorage } from "../Config/Utils";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import Header from '../Header/Header';
import Color from '../Config/Color';
import RouteConstant from '../Config/RouteConstant';
import { GetDataGooglePlace } from '../../services/GetDataGooglePlace';
import { RadioGroup, Radio } from 'react-radio-group'
import Collapsible from 'react-collapsible';
import { GetData } from "../../services/GetData";
import { GET_COUNTRY_API, USER_UPDATE_POSTALCODE, GOOGLE_PLACE_KEY, KEY_STRING, PLACE_ID } from '../Config/Config.json';
import { PUTData } from "../../services/PUTData";
import LoadingOverlay from 'react-loading-overlay';
import Messages from "../Config/Messages";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { PutMandatoryfieldSymbol } from '../UtillCustomComponent/UtillCustomComponent';

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#f0f0f0', border: "none", borderRadius: "50px", height: "50px", marginBottom: "15px" }),
    placeholder: styles => ({ ...styles, fontSize: "16px", fontWeight: "450 !important", marginLeft: "8px" }),
    option: styles => ({ ...styles, color: '#333' }),
    menuPortal: styles => ({ ...styles, zIndex: "1090" })
};

class Country extends Component {
    constructor(props) {
        super(props);
        this.state = {

            selectedValue: "-1",
            radioselectedindex: "-1",
            modalshow: false,
            imageurl: "",
            countryData: [],
            postalCode: {},
            itemObj: {},
            isActive: false,
            error: {},
            temppostalcode: "",
            selectedAddress: null
        }

        this.handleChangeRadioBtn = this.handleChangeRadioBtn.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.getCountryAPI();
    }

    getCountryAPI() {
        this.setState({ isActive: true });
        console.log("country list1 ",);
        GetData(GET_COUNTRY_API)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    if (responseData) {
                        let postalCode = this.state.postalCode;
                        var objUser = RetriveObjectFromStorage(Constants.Obj_USER);
                        if (objUser) {
                            for (var i = 0; i < responseData.length; i++) {
                                if (responseData[i].CountryCode == objUser.CountryCode) {
                                    postalCode['postcode'] = objUser.PostalCode;
                                    this.setState({ postalCode, selectedValue: i, temppostalcode: objUser.PostalCode, radioselectedindex: i });
                                    break;
                                }
                            }
                        }
                        this.setState({ countryData: responseData });
                        console.log("countryData ", this.state.countryData);
                    }
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong);
                }
                this.setState({ isActive: false });
            });
    }

    handelAddress(addressObj) {
        this.setState({ selectedAddress: addressObj });
        console.log(addressObj);
        let postalCode = this.state.postalCode;
        if (addressObj && addressObj.value && addressObj.value.place_id) {
            this.getaddressGooglePlace("", addressObj.value.place_id, postalCode);
        }
        else {
            postalCode["postcode"] = "";
        }
        this.setState({ postalCode, selectedAddressDetail: null })
    }

    getaddressGooglePlace(authToken, value, refObj) {
        this.setState({ isActive: true })
        var placeId = value
        console.log(placeId)
        geocodeByPlaceId(placeId)
            .then(result => {
                var responseJson = result ? result[0] : null;
                console.log("====", result)
                if (responseJson) {
                    var responseData = responseJson;
                    this.setState({ selectedAddressDetail: responseData ? responseData : null });
                    if (responseData && responseData.address_components) {
                        refObj["postcode"] = "";
                        for (var obj of responseData.address_components) {
                            if (obj.types && checkPostalCode(obj.types)) {
                                refObj["postcode"] = removeAllSpace(obj.short_name);
                                break;
                            }
                        }
                    }
                    else {
                        refObj["postcode"] = "";
                    }
                }
                else {
                    refObj["postcode"] = "";
                }
                this.setState({ refObj, isActive: false });
            }).catch(err => {
                refObj["postcode"] = ""
                this.setState({ refObj, isActive: false });
            });
    }

    handleChangeRadioBtn(itemObj, index) {
        console.log(itemObj);
        var error = {};
        this.setState({
            modalshow: true,
            error: error,
            selectedAddress: null
        });
        this.setState({ radioselectedindex: index });
        this.setState({ imageurl: itemObj.CountryIcon });
        this.setState({ itemObj: itemObj });
        let postalCode = this.state.postalCode;
        var objUser = RetriveObjectFromStorage(Constants.Obj_USER);
        if (objUser) {
            if (itemObj.CountryCode === objUser.CountryCode) {
                postalCode['postcode'] = objUser.PostalCode;
            }
            else {
                postalCode['postcode'] = "";
            }
        }
        this.setState({ postalCode });
        $('#myModal').modal('show');
    }

    handleChange(event) {
        let postalCode = this.state.postalCode;
        let key = event.target.name
        let value = event.target.value
        postalCode[key] = value
        this.setState({ postalCode })
    }

    handleSubmit(e) {
        var postData = {};
        e.preventDefault();
        var error = {}
        if (this.state.postalCode.postcode && this.state.itemObj.CountryCode) {
            var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
            postData["CountryCode"] = this.state.itemObj.CountryCode;
            postData["PostalCode"] = String(this.state.postalCode.postcode).toUpperCase();
            console.log("==> postdata==", postData);
            this.postalcodeAPI(postData, authToken);
        }
        else {
            error["postcode"] = Messages.v_EnterPostalCode;
        }
        this.setState({ error: error });
    }

    postalcodeAPI(postData, authToken) {
        this.setState({ isActive: true });
        var URL = USER_UPDATE_POSTALCODE;
        PUTData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.setState({ selectedValue: this.state.radioselectedindex, temppostalcode: postData["PostalCode"] });
                    $('#myModal').modal('hide')
                    this.setState({ isActive: false });
                    this.props.history.push({
                        pathname: RouteConstant.Dashboard
                    });
                }
                else {
                    alert(responseJson["Message"])
                }
                this.setState({ isActive: false });
            })
    }

    render() {

        const columns = [
            {
                maxWidth: "120px",
                cell: (row, index) =>
                    <RadioGroup className="col-sm-12" name="options" onClick={() => this.handleChangeRadioBtn(row, index)}>
                        <Radio value={row.Country} checked={this.state.selectedValue == index} />
                    </RadioGroup>
            },
            {
                cell: (row, index) => <div style={{ fontSize: "15px" }}>{row.CountryName}{((this.state.selectedValue === index) && this.state.temppostalcode) && <span className='textcolor'>{" (" + this.state.temppostalcode + ")"}</span>}</div>,
                center: true
            },
            {
                maxWidth: "120px",
                cell: row => <img style={{ height: "auto", width: "auto", padding: "5px", borderRadius: "300px" }} src={row.CountryIcon} className="iconDetails" />
            },
            {
                cell: (row, index) => <div style={{ fontSize: "15px", color: Color.THEME_COLOR, cursor: 'pointer' }} onClick={() => this.handleChangeRadioBtn(row, index)}>Change</div>,
                center: true
            }
        ];

        const countryCode = this.state.itemObj ? this.state.itemObj.CountryCode : ""

        return (

            <div>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Constants.LoadingMssg}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className=""
                >
                    <Header setHeaderTitle={Constants.Title_SelectedCountry} />
                    <section className="section">

                        {/* <div className="col-sm-12">
                        <div className="col-sm-2">
                            <i class="fa fa-arrow-left" style={{ fontSize: "20px", color: Color.THEME_COLOR, left: "0px", float: "left" }} onClick={this.goBack} ></i>
                        </div>
                        <center><h5>Select Country</h5></center>
                    </div> */}

                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                            <div className="reg_form_main">
                                <div style={{ backgroundColor: Color.WHITE }}  >
                                    <DataTableExtensions
                                        data={this.state.countryData}
                                        columns={columns}
                                        export={false}
                                        print={false}

                                    >
                                        <DataTable
                                            noHeader={true}
                                            noTableHead={true}
                                        />
                                    </DataTableExtensions>
                                </div>
                            </div>

                            {/* /////////////////////start model///////////////////////////// */}

                            <div class="modal modalcustom" id="myModal">
                                <div class="modal-dialog" style={{ top: "10%" }}>
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <label>Change Postal/ ZIP Code:</label>
                                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                                        </div>
                                        <div class="modal-body">
                                            <div className="reg_form_main">
                                                <center>  <img style={{ height: "auto", width: "auto", padding: "5px", borderRadius: "300px" }} src={this.state.imageurl}></img></center>
                                                <br />
                                                <div className="" style={{ backgroundColor: Color.WHITE }}>
                                                    <p className="textcolor" for="StreetAddress">
                                                        Address
                                                    </p>
                                                    <GooglePlacesAutocomplete
                                                        apiKey={GOOGLE_PLACE_KEY}
                                                        selectProps={{
                                                            value: this.state.selectedAddress,
                                                            menuPortalTarget: document.body,
                                                            onChange: (setAddress) => this.handelAddress(setAddress),
                                                            isClearable: true,
                                                            placeholder: "Address",
                                                            styles: colourStyles
                                                        }}
                                                        autocompletionRequest={{
                                                            componentRestrictions: {
                                                                country: countryCode ? [countryCode] : []
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="">
                                                    <p className="textcolor" for="AptUnit">
                                                        Postal Code <PutMandatoryfieldSymbol />
                                                    </p>
                                                    <input name="postcode"
                                                        className="popupinput"
                                                        placeholder="Postal Code"
                                                        style={{ zIndex: "999", border: "1px solid #aaa" }}
                                                        value={this.state.postalCode.postcode}
                                                        onChange={this.handleChange} />

                                                    <div className="errorMsg">
                                                        {this.state.error.postcode}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <label
                                                class="m-btn"
                                                /*style={{ color: Color.THEME_COLOR }}*/
                                                for="step5"
                                                id="continue-step5"
                                                data-dismiss="modal"
                                            > Cancel</label>
                                            <label
                                                /*style={{ float: "center", borderRadius: "4px", marginBottom: "10px" }}*/
                                                for="step5"
                                                id="continue-step5"
                                                //onClick={this.handlePayment}
                                                onClick={this.handleSubmit}
                                                class="m-btn"
                                            > Change</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.modalshow && false
                                    ?
                                    <div id="myModal" class="modalfade" role="dialog">
                                        <div class="modal-dialog">

                                            <div class="modal-content">
                                                <div class="modal-header" >
                                                    <label>Change Postal/ ZIP Code:</label>
                                                </div>
                                                <div class="modal-body">
                                                    <img src={this.state.imageurl}></img>
                                                </div>
                                                <div style={{ marginLeft: "15px" }}>
                                                    <input />
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                    : null
                            }

                            {/* /////////////////////end model///////////////////////////// */}
                        </div>
                    </section>
                </LoadingOverlay>
            </div>
        );
    }
}

export default Country;