const RouteConstant = {

    Default: '/',
    Login: '/login',
    Register: '/register',
    ForgotPassword: '/forgotpassword',
    EditProfile: '/profile/edit',
    AddAddress: '/address/add',
    NotificationDetail: '/notification/details',
    OrderDetail: '/order/details',
    CurbsideOrder: '/curbside/order/details',
    Country: '/country/postcode',

    Dashboard: '/dashboard',
    PlazaStore: '/plaza/stores',
    PlazaStoreItems: '/plaza/stores/products',
    AddToppings: '/add/toppings',
    AddToCart: '/plaza/stores/products/addtocart',
    MyCart: '/plaza/stores/products/mycart',
    Checkout: '/plaza/stores/products/mycart/checkout',
    Payment: '/brewersnearme/payment',
    TryIt: '/plaza/stores/products/tryit',
    PlazaStoreItemsShopify: '/plaza/shopify/stores/products',
    PlazaStoreItemsDetailShopify: '/plaza/shopifystores/products/detail',
    TermsOfService: '/termsofservice',
    PrivacyPolicy: '/privacypolicy',
    AboutUs: "/aboutus",
    HelpAndSupport: "/helpandsupport",
    Appointment: "/appointment",
    MyAppointment: "/myappointment",
    Legal: "/legal",

    // 5-apr-2023 
    PlazaCartPartner: "/brewersnearme/delivery",
    ContactUs: "/contactus",
    //

    /* Order Web Changes */
    OrderWebNavigation: "/orderweb",
    OrderWebAddtoCart: "/orderweb/store/addtocart",
    OrderWebMyCart: "/orderweb/store/addtocart/mycart",
    OrderWebTryIt: "/orderweb/store/addtocart/tryit",
    OrderWebCheckout: "/orderweb/store/addtocart/mycart/checkout",
    OrderWebAddress: "/orderweb/store/addtocart/mycart/checkout/address",
    OrderWebPayment: "/orderweb/store/payment",
    OrderWebTermsOfService: '/orderweb/store/termsofservice',
    OrderWebPrivacyPolicy: '/orderweb/store/privacypolicy',
    OrderWebAboutUs: "/orderweb/store/aboutus",
    OrderWebHelpAndSupport: "/orderweb/store/helpandsupport",
    OrderWebContactUs: "/orderweb/store/contactus"
}
export default RouteConstant;
