import React, { Component } from 'react';
import './MyCart.css'
import Color from '../Config/Color';
import Header from '../Header/Header';

import RouteConstant from '../Config/RouteConstant';

import Collapsible from 'react-collapsible';
import CartList from './CartList';
import Constants, { currencyInfo } from '../Config/Constants';
import { RetriveObjectFromStorage, StoreObjectInStorage } from '../Config/Utils';
import CartPlazaList from './CartPlazaList';

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
//import ExpandedComponent from '../shared/ExpandedComponent';


class MyCart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countryFees: {},
            cartList: [],
            totalAmount: 0.0,
            plazzaList: [],

            showEmptyCartVw: true,
        }
        this.handleShopNow = this.handleShopNow.bind(this);
        this.handleCheckout = this.handleCheckout.bind(this);
    }

    componentDidMount() {

        var objCountryFees = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES, '');
        console.log("objCountryFees ", objCountryFees);
        this.setState({ countryFees: objCountryFees });

        var objCart = RetriveObjectFromStorage(Constants.Obj_CART, '');

        if (objCart && objCart.length > 0) {
            this.setState({ showEmptyCartVw: false })
            this.setState({ cartList: objCart }, function () {
                this.setTotalCheckoutAmount(this.state.cartList);
            });
        }
        else {
            this.setState({ showEmptyCartVw: true })
        }
    }


    setTotalCheckoutAmount = (list1) => {
        var itemCount = 0
        var plazaTotalAmount = 0.0
        let list = list1
            .map(function (item, index) {
                var store = item.Stores;
                store.map(function (store, index1) {
                    var storeItemList = store.Items;
                    storeItemList.map(function (objitem, index2) {
                        var data = objitem;
                        itemCount = itemCount + 1
                        plazaTotalAmount = plazaTotalAmount + ((data.TotalItemPrice / data.ItemQuantity) * data.ItemQuantity);
                    })
                })
            })
        this.setState({ totalAmount: plazaTotalAmount, showEmptyCartVw: itemCount == 0 })
        this.setState({ plazzaList: list1 })
        list1 = list1.length == 0 ? "" : list1;
        StoreObjectInStorage(Constants.Obj_CART, list1)
    }

    deletedItem(list) {
        this.setTotalCheckoutAmount(list)
    }

    handleShopNow(e) {
        // this.props.history.push({
        //     pathname: RouteConstant.PlazaStoreItems
        // });
        this.props.history.goBack();
    }

    handleCheckout() {
        var objCart = RetriveObjectFromStorage(Constants.Obj_CART, '');
        console.log('plazza list=>', this.state.plazzaList)
        StoreObjectInStorage(Constants.Obj_CART, this.state.plazzaList)
        this.props.history.push({
            pathname: RouteConstant.Checkout
        });
    }

    render() {
        var currencySymbole = ""
        var countryFees = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES)
        if (countryFees) {
            currencySymbole = (countryFees[currencyInfo.CountryCode] ?
                "(" + countryFees[currencyInfo.CountryCode] + ") " : "") + countryFees.Symbol

        }
        return (
            <div>
                <Header setHeaderTitle={Constants.Title_MyCart} />
                <section className="section">
                    {
                        (this.state.showEmptyCartVw && this.state.totalAmount == 0) ?
                            <div className="container" id="vwEmptyCart" style={{ paddingLeft: "2px", paddingRight: "2px", verticalAlign: "middle" }}>
                                <div className="reg_form_main">
                                    <center>
                                        <i class="fas fa-shopping-cart" style={{ fontSize: "50px", color: Color.BLACK }}></i><br /><br />
                                        <label style={{ fontWeight: "bold" }}>Your cart is empty</label>
                                    </center>

                                    <div className="bottam_sing">
                                        <label
                                            style={{ backgroundColor: Color.THEME_COLOR, borderRadius: "50px" }}
                                            onClick={this.handleShopNow}
                                            className="col-sm-5 btn btn-done"
                                        > Shop Now</label>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                                <div className="reg_form_main" style={{ margin: "0px 0px 65px 0px" }}>
                                    <CartPlazaList
                                        data={this.state.cartList}
                                        setPlazaTotal={this.setTotalCheckoutAmount}
                                        deletedItemwith={(list) => { this.deletedItem(list) }}>

                                    </CartPlazaList>
                                </div>
                                <div className="bottam_sing" style={{ paddingBottom: "5px", left: "0px", position: "fixed", bottom: "0px" }}>
                                    <label
                                        style={{ float: "center", borderRadius: "50px", marginBottom: "10px" }}
                                        onClick={this.handleCheckout}
                                        className="btn btn-done"
                                    > Go to Checkout - {currencySymbole}{(Math.round(this.state.totalAmount * 100) / 100).toFixed(2)}</label>
                                </div>
                            </div>
                    }
                </section>
            </div>
        );
    }
}

export default MyCart;