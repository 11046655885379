import React, { Component, PropTypes } from 'react';
import './PlazaStoreItemsShopify.css'
import Constants, { currencyInfo, DEFAULTID, COMPANYCONST } from '../../Config/Constants'
import { MainContext, RetriveDataFromStorage, RetriveObjectFromStorage, StoreObjectInStorage } from "../../Config/Utils";
import Header from '../../Header/Header';
import Color from '../../Config/Color';
import { GET_STOREITEMSHOPIFY_API } from '../../Config/Config.json'
import { GetData } from "../../../services/GetData";
import Messages from '../../Config/Messages';
import ScrollMenu from 'react-horizontal-scrolling-menu';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
// import CategoryList from './CategoryList';
import LoadingOverlay from 'react-loading-overlay';

import { store } from './store.json'
import RouteConstant from '../../Config/RouteConstant';
import defulte from '../../../images/default.png';
import NoPriview from '../../../../src/images/nopreviewp.png';
import NoPriviewBanner from '../../../../src/images/nopreviewb.png';

// One item component
// selected prop will be passed

var currencySymbole = ""
var countryFees = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES)
if (countryFees) {
    currencySymbole = countryFees.Symbol

}

const MenuItem = ({ text, selected }) => {
    return <div className={`menu-item ${selected ? 'active' : ''}`} >
        <div style={{ width: "100px", height: "150px", marginBottom: "5px" }}>
            <img src={text.ItemLogo ? text.ItemLogo : NoPriview} onError={(e) => (e.target.onerror = null, e.target.src = NoPriview)} style={{ width: "80px", height: "100px" }}></img>
            <br />
            <label className="textcolor" style={{ textOverflow: "ellipsis", overflow: "hidden", width: "100px" }}>{text.ItemName}</label>
            <br />
            <label className="textcolor">{currencySymbole} {Number(text.ItemPrice).toFixed(2)}</label>
        </div>

    </div>;
};





export const Menu = (list, selected) =>
    list.map(el => {
        const { ItemID } = el;
        return <MenuItem text={el} key={ItemID} selected={selected} />;
    });

class PlazaStoreItems extends Component {
    constructor(props) {
        super(props);
        this.state = {

            selected: '',
            storeItemList: [],
            storeData: {},
            isActive: false,

        }
    }


    componentDidMount() {
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);

        // var objPlaza = RetriveObjectFromStorage(Constants.Obj_PLAZA, '');
        var objStore = RetriveObjectFromStorage(Constants.Obj_STORE, '');
        // console.log("objPlaza test ", objPlaza );
        // console.log("objPlaza ", objPlaza);
        // console.log("objStore ", objStore.ObjectId);

        if ( objStore) {
            this.setState({ storeData: objStore })
            this.getStoreItemsAPI(authToken, DEFAULTID, objStore.ObjectId);
        }
    }

    getStoreItemsAPI(authToken, plazaID, storeID) {
        this.setState({ isActive: true });
        var API = GET_STOREITEMSHOPIFY_API.replace("#STOREID#", storeID);
        var URL = API.replace("#TENANTID#", plazaID);
        console.log("API ", URL);
        GetData(URL, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    if (responseData && responseData.length > 0) {

                        this.setState({ storeItemList: responseData }, function () {
                            console.log("123 ", this.state.storeItemList)
                        });
                    }
                }
                else {
                    this.setState({ hideEmptyPlazaView: false });
                    // alert(Messages.ERR_SomethingWentWrong);
                }
                this.setState({ isActive: false });
            });
    }

    navigateItemDetail(storeItem) {
        StoreObjectInStorage(Constants.Obj_STORE_ITEMS, storeItem)
        this.props.history.push({
            pathname: RouteConstant.PlazaStoreItemsDetailShopify
        });
    }

    render() {
        const { storeItemList, selected, storeData } = this.state

        var currencySymbole = ""
        var countryFees = RetriveObjectFromStorage(Constants.Obj_COUNTRY_FEES)
        if (countryFees) {
            currencySymbole = (countryFees[currencyInfo.CountryCode] ?
                "(" + countryFees[currencyInfo.CountryCode] + ") " : "") + countryFees.Symbol

        }

        return (
            // <MainContext.Provider value={this.state}>
            <div>
                <LoadingOverlay
                    active={this.state.isActive}
                    text={Constants.LoadingMssg}
                    spinner
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            position: "fixed"
                        })
                    }}
                    className=""
                >
                    <Header setHeaderTitle={this.state.storeData.ApplicationName} />
                    <section className="section">

                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px", zIndex: "-1" }}>
                            <div className="reg_form_main offset-sm-3 col-sm-6" style={{ margiBottom: "10px" }}>
                                <div style={{ position: "relative", width: "inherit" }}>
                                    {/* , height: "150px",  */}
                                    <img src={storeData.AppBannerImage ? storeData.AppBannerImage : NoPriviewBanner} onError={(e) => (e.target.onerror = null, e.target.src = NoPriviewBanner)} style={{ width: "inherit", zIndex: "-1", borderRadius: '10px' }} />
                                    {/* height: "150px", */}
                                    <div className="overlay" style={{ marginBottom: '0px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', backgroundColor: 'rgba(0,0,0,0.6)' }}>
                                        <div style={{ width: "13%", height: "inherit", left: "0%", position: "absolute" }}>
                                            <img src={storeData.AppLogo ? storeData.AppLogo : NoPriview} style={{ width: "35px", height: "35px", margin: "5px", borderRadius: "5px" }} />
                                        </div>
                                        <div style={{ width: "87%", height: "inherit", position: "absolute", right: "0%" }} >
                                            <div className="row" style={{ marginLeft: "0px", width: "100%", height: "70%", marginTop: "6px" }}>
                                                <text style={{ lineHeight: "1.5", fontSize: "12px", width: "inherit", height: "100%", color: Color.WHITE, maxLines: "2px", textOverflow: "ellipsis", overflow: "hidden", }}>
                                                    {storeData.AppAddress}
                                                </text>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row col-md-12" style={{ marginBottom: "10px" }}>
                                {this.state.storeItemList.map(itemObj => {
                                    return <div class="col-6 col-sm-4 col-md-2" style={{ marginBottom: "5px" }} onClick={(e) => this.navigateItemDetail(itemObj)}>
                                        {itemObj.image ? <img src={itemObj.image ? itemObj.image.src : NoPriview} style={{ width: "100px", height: "120px", marginTop: "20px" }}></img> :
                                            <img src={NoPriview} style={{ width: "100px", height: "120px", marginTop: "20px" }}></img>}
                                        <br />
                                        <label className="textcolor" style={{ textOverflow: "ellipsis", overflow: "hidden", maxwidth: "100px", color: 'black' }}>{itemObj.title}</label>
                                        <br />
                                        <label className="textcolor">{currencySymbole} {Number(itemObj.variants[0] ? itemObj.variants[0].price : 0).toFixed(2)}</label>
                                    </div>
                                })}
                            </div>

                            {/* <div className="reg_form_main">
                                <Accordion allowZeroExpanded={true}>
                                    <CategoryList storeData={storeData} data={storeItemList}></CategoryList>
                                </Accordion>
                            </div> */}

                            <div class="modal" id="deleteModal">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <label>{COMPANYCONST.BREWERSNEARME}</label>
                                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                                        </div>

                                        <div class="modal-body">
                                            <label>Are you sure you want to remove all the items from the cart?</label>
                                        </div>
                                        <div class="modal-footer">
                                            <label
                                                style={{ color: Color.THEME_COLOR }}
                                                for="step5"
                                                id="continue-step5"
                                                data-dismiss="modal">No</label>
                                            <label
                                                style={{ float: "center", borderRadius: "50px", marginBottom: "10px" }}
                                                for="step5"
                                                id="continue-step5"
                                                //onClick={this.handlePickupDialog}
                                                className="btn btn-done">Yes</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </LoadingOverlay>
            </div>
            // </MainContext.Provider> 
        );
    }
}

export default PlazaStoreItems;