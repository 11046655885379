import React, { Component } from 'react';
import plazacartLogo from "../../../images/logo.png";
import { COMPANYCONST } from '../../Config/Constants';
import HeaderOrderWeb from '../Header/HeaderOrderWeb';

export default class HelpAndSupportOrderWeb extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {

    }

    render() {
        return (
            <div>
                <div class="hero_area">
                    <HeaderOrderWeb setHeaderTitle={""} />
                </div>
                <section className="section mb-5">
                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }} >
                        <div className="reg_form_main" style={{ float: "unset" }}>
                            <center><img src={plazacartLogo} className="col-sm-4"></img></center>
                            <center><p id="instruction" className="textcolor" style={{ marginBottom: '10px' }}> Contact Us</p> </center>
                        </div>
                        <div class="midsection pl-2 pr-2 pl-sm-0 pr-sm-0 pt-4 pages" style={{ textAlign: 'justify', borderTop: "1px solid #ccc" }}>
                            <div class="Justify_Font_11">
                                <p><strong>E-mail:</strong> <span>{COMPANYCONST.PLAZACARTMAIL}</span></p>
                                {/* <p><strong>Telephone:</strong> <span>{COMPANYCONST.TELEPHONE}</span></p> */}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}