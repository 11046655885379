import React, { useEffect, useRef } from "react"



function Payple({ ClientId, merchantId, Amount, currency, successPayment, phoneNumber, failPayment, billingAddress, UserProfile }) {
    var s = document.createElement('script');
    const appendHead = () => {
        window.paypal.Buttons({
            createOrder: function (data, actions) {
                var billingAdd = {}, phoneNoObj = {}
                if (billingAddress && billingAddress.PostalCode) {
                    billingAdd.address_line_1 = billingAddress.ApartmentNumber
                    billingAdd.address_line_2 = billingAddress.StreetAddress
                    billingAdd.postal_code = billingAddress.PostalCode
                    billingAdd.country_code = billingAddress.CountryCode
                }
                console.log(billingAdd)

                if (phoneNumber) {
                    phoneNoObj.phone = {
                        phone_number: {
                            national_number: phoneNumber,
                        }
                    }
                }

                return actions.order.create({
                    purchase_units: [{
                        amount: {
                            value: Amount
                        },
                        shipping: {
                            name: {
                                full_name: UserProfile ? UserProfile.FirstName + " " + UserProfile.LastName : null
                            }
                        }
                    }],

                    payer: {
                        // birth_date: '2021-01-01',
                        // email_address: 'customer@domain.com',
                        // phone: {
                        //     phone_number: {
                        //         national_number: phoneNumber,
                        //     }
                        // },
                        ...phoneNoObj,
                        name: {
                            given_name: UserProfile ? UserProfile.FirstName : null,
                            surname: UserProfile ? UserProfile.LastName : null,
                        },
                        address: billingAddress ? { ...billingAdd } : undefined
                    },
                });
            },
            onApprove: function (data, actions) {
                return actions.order.capture().then(function (orderData) {
                    // alert(JSON.stringify(orderData));

                    // console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));
                    // var transaction = orderData.purchase_units[0].payments.captures[0];
                    // alert('Transaction ' + transaction.status + ': ' + transaction.id + '\n\nSee console for all available details');
                    successPayment(orderData)
                });
            },
            onError: function (err) {
                console.log("===>", err)
            }
        }).render(paypal.current);
    }

    s.setAttribute('src', "https://www.paypal.com/sdk/js?&client-id=" + ClientId + "&merchant-id=" + merchantId + "&currency=" + currency); s.onload = appendHead;
    document.head.insertBefore(s, document.head.firstElementChild);
    const paypal = useRef()


    // useEffect(() => {
    //     appendHead()
    // })
    return (
        <div>
            <div ref={paypal}></div>
        </div>
    )

}

export default Payple