import React, { Component } from "react";
import { GetData } from "../../services/GetData";
import { PostData } from "../../services/PostData";
import "../../styles/style.css";
import './RegistrationNew.css';
//import 'react-flags-select/css/react-flags-select.css';
import { GET_COUNTRY_API, GET_PLAZA_API, POSTAL_CODE, USER_ADDRESS, USER_REGISTRATION_API, VALIDATE_OTP_API, GOOGLESITEKEY, GOOGLE_CLIENT_ID } from '../Config/Config.json';
import Messages from "../Config/Messages";
import { formatPhoneNumber, removeAllSpace, RetriveObjectFromStorage, StoreDataInStorage, validateEmail, validateOtpNo, validatePassword, validatePhoneNumber, validateUserName, logoutSession} from "../Config/Utils";

//import Select from 'react-select'; //https://www.npmjs.com/package/react-select
import Constants, { DEFAULTID } from '../Config/Constants';
//import FacebookLogin from 'react-facebook-login';
import { GOOGLEPLACEAPIMAP } from '../Config/constvariable';

import email from "../../images/icons/email.png";
import plazablankIcon from "../../images/icons/plazablank.png";
import Color from '../Config/Color.js';


import Checkbox from 'react-simple-checkbox';

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import {
    browserName, fullBrowserVersion, osName,
    osVersion
} from "react-device-detect";
import LoadingOverlay from 'react-loading-overlay';
import NoPriview from '../../../src/images/nopreviewp.png';
import { RegisterConst } from '../Config/constvariable';
import { RegisterPH } from '../Config/Placeholder';
import RouteConstant from "../Config/RouteConstant";
import MainFooter from "../MainFooter/MainFooter";
import MainHeader from "../MainHeader/MainHeader";
import { PutMandatoryfieldSymbol } from "../UtillCustomComponent/UtillCustomComponent";
import ReCAPTCHA from "react-google-recaptcha";
const publicIp = require('public-ip');
import LoginWithGoogle from "../LoginWithGoogleFiles/LoginWithGoogle";
import { ACaptcha, GETWORD } from '../UtillCustomComponent/CaptchaGenerator';

class RegisterNew extends Component {
    constructor(props) {
        super(props);
        this.state = {


            userFields: {},   //registrstion fields
            errors: {},

            selectedCountry: "",
            countryFields: {},  //select country fields
            countryData: [],     //array of country list bind from API -> country fields

            plazaFields: {},  //select plaza fields
            plazaList: [],   //array of plaza list bind from API -> plaza fields  
            hideEmptyPlazaView: false,
            //selectedOption: null,  //select plaza fields

            chkBoxChecked: false,

            otp: {},  //otp fields
            page: 2,
            isGoogleFacebook: false,
            isActive: false,

            selectedAddressDetail: null,
            ipAddress: '',
            browserLanguage: '',
            isPassword: true,
            isConfirmPassword: true,

            /* Google Captcha Code */
            /* googleReCaptch: null,
            iscaptchreload: true, */

            /* Native Captcha Code */
            captchWord: "",
            iscaptchwordMatch: false,

            issignupwithgooogle: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleValidationOnBlur = this.handleValidationOnBlur.bind(this);

        this.handleCountrySubmit = this.handleCountrySubmit.bind(this);
        this.handleSignIn = this.handleSignIn.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleValidationCountryonBlur = this.handleValidationCountryonBlur.bind(this);
        this.onSelectFlag = this.onSelectFlag.bind(this);
        this.handlePlazaContinue = this.handlePlazaContinue.bind(this);

        this.handleRowClick = this.handleRowClick.bind(this);

        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);

        this.handleSubmitPhoneNo = this.handleSubmitPhoneNo.bind(this);

        this.handleValidateOtpSubmit = this.handleValidateOtpSubmit.bind(this);
        this.handleValidateOtpChange = this.handleValidateOtpChange.bind(this);
        this.handleValidationOTPonBlur = this.handleValidationOTPonBlur.bind(this);

    }

    componentDidMount() {
        //this.getCountryAPI();
        // var countryData = this.props.location.countryData
        // alert(JSON.stringify(countryData))
        logoutSession()
        var countryData = RetriveObjectFromStorage(Constants.Obj_COUNTRY, '')
        // alert(JSON.stringify(countryData))

        if (countryData) {
            this.setState({ selectedAddressDetail: countryData.addressDetail ? countryData.addressDetail : null })

            // changes for Brewers 9-may-2023
            // this.getPlazaByPostalCodeAPI(countryData.postalCode, countryData.countryCode)
            //
        }

        /* For Ip Address */
        (async () => {
            var ip = await publicIp.v4();
            this.setState({ ipAddress: ip });
            console.log('ip address', ip);
        })();

        /* For Current Location */
        if ("geolocation" in navigator) {
            console.log("Geolocation Available");
            this.getLocation();

        } else {
            console.log("Geolocation Not Available");
            console.error("Error Code = " + error.code + " - " + error.message);
        }

        /* For Detect browser details */
        const detectBrowserLanguage = require('detect-browser-language')
        this.setState({ browserLanguage: detectBrowserLanguage() });
        console.log('detect browser language', detectBrowserLanguage());

        /* Native Captcha Code */
        this.setState({ captchWord: GETWORD(6) });
    }

    /* For Get Curent Location */
    getLocation = () => {
        navigator.geolocation.getCurrentPosition((position) => {
            console.log('Location', 'lat' + position.coords.latitude + "Lon" + position.coords.longitude);
            this.setState({ latitude: position.coords.latitude });
            this.setState({ longitude: position.coords.longitude });
        });
    };

    /***************************************************************************************** */
    // START --> FUNCTIONS FOR SELECT COUNTRY MODULE
    /***************************************************************************************** */

    getCountryAPI() {
        GetData(GET_COUNTRY_API)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var responseData = responseJson["Data"];
                    console.log("responseData ", responseData);
                    var countryCode = [];
                    for (var i = 0; i < responseData.length; i++) {
                        var obj = responseData[i].CountryCode;
                        countryCode.push(obj);
                    }
                    this.setState({ countryData: countryCode });
                    console.log("countryData ", this.state.countryData);
                }
                else {
                    alert(Messages.ERR_SomethingWentWrong);
                }
            });
    }

    handleCountryChange(event) {
        let countryFields = this.state.countryFields;
        let key = event.target.name
        let value = event.target.value
        countryFields[key] = value
        this.setState({ countryFields })
    }

    onSelectFlag(countryCode) {
        console.log(countryCode)
        let countryFields = this.state.countryFields;
        countryFields["CountryCode"] = countryCode
        this.setState({ countryFields })
        this.setState({ selectedCountry: countryCode })
        var errors = this.state.errors.CountryCode;
        this.setState({ errors: "" });
    }


    handleValidationCountryonBlur(e) {
        let fields = this.state.countryFields;
        let errors = this.state.errors;
        let formIsValid = true;
        let name = e.currentTarget.name;

        if (name === "PostalCode") {
            if (!fields["PostalCode"]) {
                formIsValid = false;
                errors["PostalCode"] = Messages.v_EnterPostalCode
            }
        }

        if (formIsValid) errors[name] = "";
        this.setState({
            fields,
            errors
        });
    }


    validateCountryForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.countryFields;

        if (!fields["CountryCode"]) {
            formIsValid = false;
            errors["CountryCode"] = Messages.v_EnterCountryName;
        }

        if (!fields["PostalCode"]) {
            formIsValid = false;
            errors["PostalCode"] = Messages.v_EnterPostalCode;
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleCountrySubmit(e) {
        var postData = {};
        e.preventDefault();

        if (this.validateCountryForm()) {
            // this.getPlazaByPostalCodeAPI(this.state.countryFields.PostalCode, this.state.countryFields.CountryCode);
            document.getElementById("step2").checked = true;
        }
    }

    handleSignIn(e) {
        this.props.history.push({
            pathname: '/login',
        })
    }


    /***************************************************************************************** */
    // END --> FUNCTIONS FOR SELECT COUNTRY MODULE
    /***************************************************************************************** */



    /***************************************************************************************** */
    // START --> FUNCTIONS FOR SELECT PLAZA MODULE
    /***************************************************************************************** */

    // changes for Brewers 9-may-2023
    // getPlazaByPostalCodeAPI(postalCode, countryCode) {
    //     this.setState({ isActive: true })
    //     var API = GET_PLAZA_API + countryCode + POSTAL_CODE + postalCode;
    //     GetData(API)
    //         .then(result => {
    //             var responseJson = result;
    //             if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
    //                 var responseData = responseJson["Data"];
    //                 console.log("responseData ", responseData.plaza);
    //                 if (responseData.plaza && responseData.plaza.length > 0) {
    //                     //this.setState({screenTitle:"Select Plaza"});                
    //                     this.setState({ plazaList: responseData.plaza });
    //                     this.setState({ hideEmptyPlazaView: true });
    //                 }
    //                 else {
    //                     this.setState({ hideEmptyPlazaView: false });
    //                 }
    //             }
    //             else {
    //                 this.setState({ hideEmptyPlazaView: false });
    //                 alert(Messages.NO_PLAZA);
    //             }
    //             this.setState({ isActive: false })
    //         });
    // }
    //


    handlePlazaContinue() {
        // document.getElementById("step2").checked = true;
        // document.getElementById("headerCart").style.visibility = "hidden";
        // document.getElementById("headername").style.visibility = "hidden";
        this.setState({ page: 2 })
    }

    handleRowClick(data) {

        // document.getElementById("headerCart").style.visibility = "hidden";
        // document.getElementById("headername").style.visibility = "hidden";
        // document.getElementById("step2").checked = true;
        this.setState({ page: 2 })
    }

    /***************************************************************************************** */
    // END --> FUNCTIONS FOR SELECT PLAZA MODULE
    /***************************************************************************************** */

    /***************************************************************************************** */
    // START --> FUNCTIONS FOR EMAIL, GOOGLE, FACEBOOK MODULE
    /***************************************************************************************** */


    /* responseFacebook = (response) => {
         console.log('facebook response ', response);
         try {
             let userFields = this.state.userFields;
             const { email, id } = response
             if (email && id) {
                 userFields['Email'] = email;
                 userFields['Password'] = id;
                 this.setState({ page: 3, isGoogleFacebook: true })
             }
             this.setState({ userFields })
         }
         catch (err) {
             console.log(err)
         }
     }*/


    /*responseGoogle = (response) => {
        console.log('google response ', response);
        try {
            let userFields = this.state.userFields;
            const { profileObj } = response
            if (profileObj) {
                userFields['Email'] = profileObj.email;
                userFields['Password'] = profileObj.googleId;
                userFields['FirstName'] = profileObj.givenName
                userFields['LastName'] = profileObj.familyName
                this.setState({ page: 3, isGoogleFacebook: true })
            }
            this.setState({ userFields })


        }
        catch (err) {
            console.log(err)
        }
    }*/

    reloadCaptch() {

        /* Google Captcha Code */
        /* this.setState({ iscaptchreload: false, googleReCaptch: null });
        setTimeout(() => {
            this.setState({ iscaptchreload: true });
        }, 900) */

        /* Native Captcha Code */
        this.setState({ captchWord: GETWORD(6) });
    }

    /***************************************************************************************** */
    // END --> FUNCTIONS FOR EMAIL, GOOGLE, FACEBOOK MODULE
    /***************************************************************************************** */


    navigatepage() {
        this.setState({ page: 3, issignupwithgooogle: false });
    }



    /***************************************************************************************** */
    // START --> FUNCTIONS FOR REGISTRATION MODULE
    /***************************************************************************************** */

    handleChange(event) {
        let userFields = this.state.userFields;
        let key = event.target.name
        let value = event.target.value
        if (key == "PhoneNumber") {
            userFields[key] = formatPhoneNumber(value)
        }
        else {
            userFields[key] = value
        }

        /* Native Captcha Code */
        if (key === "captchacode") {
            if (value.length === 6) {
                if (value != this.state.captchWord) {
                    let errors = this.state.errors;
                    errors['captchacode'] = Messages.v_EnterValidCaptcha;
                    this.setState({ errors: errors, iscaptchwordMatch: false });
                } else {
                    this.setState({ iscaptchwordMatch: true, errors: {} });
                }
            } else {
                if (value.length === 0 || value.length === 1) {
                    this.setState({ errors: {} });
                }
                this.setState({ iscaptchwordMatch: false });
            }
        }

        this.setState({ userFields })
        // if (key === 'Name' && value[0] === ' ') value = value.trim()
    }

    /* Google Captcha Code */
    /* handleGoogleRecaptcha = vl => {
        this.setState({ googleReCaptch: vl })
    } */

    handleValidationOnBlur(e) {
        let fields = this.state.userFields;
        let errors = this.state.errors;
        let formIsValid = true;
        let name = e.currentTarget.name;

        if (name == "Email") {
            if (!fields["Email"]) {
                formIsValid = false;
                errors["Email"] = Messages.v_EnterEmail;
            }
            else if (typeof fields["Email"] !== "undefined") {
                if (!validateEmail(fields["Email"])) {
                    formIsValid = false;
                    errors["Email"] = Messages.v_EnterValidEmail;
                }
            }
        }

        if (name == "FirstName") {
            if (!fields["FirstName"]) {
                formIsValid = false;
                errors["FirstName"] = Messages.v_EnterName;
            }
            else if (typeof fields["FirstName"] !== "undefined") {
                if (!validateUserName(fields["FirstName"])) {
                    formIsValid = false;
                    errors["FirstName"] = Messages.v_EnterValidName;
                }
            }
        }

        if (name == "LastName") {
            if (!fields["LastName"]) {
                formIsValid = false;
                errors["LastName"] = Messages.v_EnterLastName;
            }
        }

        if (name == "Password") {
            if (!fields["Password"]) {
                formIsValid = false;
                errors["Password"] = Messages.v_EnterPassword
            }
            else if ((typeof fields["Password"] !== "undefined") && !this.state.isGoogleFacebook) {
                if (!validatePassword(fields["Password"])) {
                    formIsValid = false;
                    errors["Password"] = Messages.v_EnterValidPassword;
                }
            }
        }

        if (name == "ConfirmPassword") {
            if (!fields["ConfirmPassword"]) {
                formIsValid = false;
                errors["ConfirmPassword"] = Messages.v_EnterConfirmPassword
            }
            else if ((typeof fields["ConfirmPassword"] !== "undefined") && !this.state.isGoogleFacebook) {
                if (!validatePassword(fields["ConfirmPassword"])) {
                    formIsValid = false;
                    errors["ConfirmPassword"] = Messages.v_EnterValidPassword;
                }
            }
        }

        if (name == "PhoneNumber") {
            if (!fields["PhoneNumber"]) {
                formIsValid = false;
                errors["PhoneNumber"] = Messages.v_EnterPhoneNo
            }
            else if (typeof fields["PhoneNumber"] !== "undefined") {
                if (!validatePhoneNumber(fields["PhoneNumber"])) {
                    formIsValid = false;
                    errors["PhoneNumber"] = Messages.v_EnterMoNoFormate;
                }
            }
        }

        /* Native Captcha Code */
        if (name === "captchacode") {
            if (!fields['captchacode']) {
                formIsValid = false;
                errors["captchacode"] = Messages.v_EnterCaptcha;
            }
            else if (typeof fields["captchacode"] !== "undefined") {
                if (fields["captchacode"] != this.state.captchWord) {
                    formIsValid = false;
                    errors["captchacode"] = Messages.v_EnterValidCaptcha;
                }
            }
        }

        if (formIsValid) errors[name] = "";
        this.setState({
            fields,
            errors
        });
    }


    validateForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;

        /* Google Captcha Code */
        /* const { googleReCaptch } = this.state */

        if (!fields["Email"]) {
            formIsValid = false;
            errors["Email"] = Messages.v_EnterEmail;
        }
        else if (typeof fields["Email"] !== "undefined") {
            if (!validateEmail(fields["Email"])) {
                formIsValid = false;
                errors["Email"] = Messages.v_EnterValidEmail;
            }
        }

        if (!fields["FirstName"]) {
            formIsValid = false;
            errors["FirstName"] = Messages.v_EnterName;
        }
        else if (typeof fields["FirstName"] !== "undefined") {
            if (!validateUserName(fields["FirstName"])) {
                formIsValid = false;
                errors["FirstName"] = Messages.v_EnterValidName;
            }
        }

        if (!fields["LastName"]) {
            formIsValid = false;
            errors["LastName"] = Messages.v_EnterLastName;
        }

        if (!fields["Password"]) {
            formIsValid = false;
            errors["Password"] = Messages.v_EnterPassword
        }
        else if ((typeof fields["Password"] !== "undefined") && !this.state.isGoogleFacebook) {
            if (!validatePassword(fields["Password"])) {
                formIsValid = false;
                errors["Password"] = Messages.v_EnterValidPassword;
            }
        }

        if (!fields["ConfirmPassword"]) {
            formIsValid = false;
            errors["ConfirmPassword"] = Messages.v_EnterConfirmPassword;
        }
        else if ((typeof fields["ConfirmPassword"] !== "undefined") && !this.state.isGoogleFacebook) {
            if (!validatePassword(fields["ConfirmPassword"])) {
                formIsValid = false;
                errors["ConfirmPassword"] = Messages.v_EnterValidPassword;
            }
        }

        if (fields["ConfirmPassword"] != fields["Password"]) {
            formIsValid = false;
            errors["ConfirmPassword"] = Messages.v_ConfirmPwdMatchWithPwd
        }

        if (!fields["PhoneNumber"]) {
            formIsValid = false;
            errors["PhoneNumber"] = Messages.v_EnterPhoneNo
        }
        else if (typeof fields["PhoneNumber"] !== "undefined") {
            if (!validatePhoneNumber(fields["PhoneNumber"])) {
                formIsValid = false;
                errors["PhoneNumber"] = Messages.v_EnterMoNoFormate;
            }
        }

        if (this.state.chkBoxChecked === false) {
            formIsValid = false;
            errors["termspolicy"] = Messages.v_Terms_And_Policy;
        }

        /* Native Captcha Code */
        if (!fields['captchacode']) {
            formIsValid = false;
            errors["captchacode"] = Messages.v_EnterCaptcha;
        }
        else if (typeof fields["captchacode"] !== "undefined") {
            if (fields["captchacode"] != this.state.captchWord) {
                formIsValid = false;
                errors["captchacode"] = Messages.v_EnterValidCaptcha;
            }
        }

        /* Google Captcha Code */
        /* if (!googleReCaptch) {
            formIsValid = false;
        } */

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSubmit(e) {
        var postData = {};
        e.preventDefault();
        if (this.validateForm()) {
            // document.getElementById("step4").checked = true;
            this.setState({ page: 4 });
        }
    }



    handleCheckBoxChange() {
        if (this.state.chkBoxChecked) {
            this.setState({ chkBoxChecked: false })
        } else {
            this.setState({ chkBoxChecked: true })
        }
    }



    /***************************************************************************************** */
    // END --> FUNCTIONS FOR REGISTRATION MODULE
    /***************************************************************************************** */


    /***************************************************************************************** */
    // START --> FUNCTIONS FOR PHONE NUMBER MODULE
    /***************************************************************************************** */

    validatePhoneNoForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;

        if (!fields["PhoneNumber"]) {
            formIsValid = false;
            errors["PhoneNumber"] = Messages.v_EnterPhoneNo
        }
        else if (typeof fields["PhoneNumber"] !== "undefined") {
            if (!validatePhoneNumber(fields["PhoneNumber"])) {
                formIsValid = false;
                errors["PhoneNumber"] = Messages.v_EnterMoNoFormate;
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSubmitPhoneNo(e) {
        var postData = {};
        e.preventDefault();
        if (this.validateForm()) {

            /* Google Captcha Code */
            /*  const { googleReCaptch } = this.state; */

            postData = this.state.userFields;
            var countryData = RetriveObjectFromStorage(Constants.Obj_COUNTRY, '');
            postData["CountryCode"] = countryData.countryCode;
            var postalCode = countryData.postalCode;
            postData["PostalCode"] = postalCode ? postalCode.toUpperCase() : "";
            postData["DeviceId"] = "";/* Constants.DEVICE_ID */
            postData["DeviceType"] = "";/* Constants.DEVICE_TYPE */
            postData["PushToken"] = "";/* Constants.PUSH_TOKEN */
            postData["ipaddress"] = this.state.ipAddress;
            postData["browser"] = browserName;
            postData["browserversion"] = fullBrowserVersion;
            postData["osdetails"] = osName + " " + osVersion;
            if (countryData && countryData.addressDetail && countryData.addressDetail.formatted_address) {
                postData["location"] = countryData.addressDetail.formatted_address;
            } else {
                postData["location"] = (postalCode ? postalCode.toUpperCase() : "") + "," + countryData.countryCode;
            }
            postData["locale"] = this.state.browserLanguage;
            postData["Latitude"] = this.state.latitude;
            postData["Longitude"] = this.state.longitude;
            postData["questionid"] = DEFAULTID;
            postData["answer"] = "";
            postData["userid"] = "";
            postData["googlecaptcharesponse"] = ""; /* googleReCaptch */

            console.log('===>postData', postData);
            console.log("====post", JSON.stringify(postData));
            //alert('data: ' + JSON.stringify(postData));
            this.callResgisterAPI(postData);
        }
    }

    callResgisterAPI(postData) {
        console.log("callResgisterAPI");
        this.setState({ isActive: true })
        var URL = USER_REGISTRATION_API;
        PostData(URL, postData)
            .then(result => {
                var responseJson = result;
                console.log("callResgisterAPI", responseJson);
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    // document.getElementById("step5").checked = true;
                    this.setState({ page: 5 })
                }
                else if (responseJson["StatusCode"] === 400) {
                    let errors = {};
                    errors["Email"] = "Email ID already exists" /* responseJson.Message */;
                    this.setState({ errors: errors, issignupwithgooogle: false });
                }
                else {
                    let errors = {};
                    errors["termspolicy"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors });
                }
                this.setState({ isActive: false });
                this.reloadCaptch()
            }).catch(err => {
                this.setState({ isActive: false });
                let errors = {};
                errors["termspolicy"] = Messages.ERR
                this.reloadCaptch()
            });
    }

    ResponsewithGooglewithSigninApi = (response) => {
        console.log(response);
        try {
            var userFields = this.state.userFields;
            if (response && response.sub) {
                if (response && response.email) {
                    userFields['Email'] = response.email;
                    this.setState({ issignupwithgooogle: true });
                }
                if (response && response.family_name) {
                    userFields['FirstName'] = response.family_name;
                }
                if (response && response.given_name) {
                    userFields['LastName'] = response.given_name;
                }
                this.setState({ page: 3, userFields: userFields, errors: {} });
            } else {
                this.setState({ page: 2, errors: {}, issignupwithgooogle: false });
            }
        } catch (err) {
            console.log(err);
            this.setState({ page: 2, errors: {}, issignupwithgooogle: false });
        }
    }

    /***************************************************************************************** */
    // END --> FUNCTIONS FOR PHONE NUMBER MODULE
    /***************************************************************************************** */




    /***************************************************************************************** */
    // START --> FUNCTIONS FOR VALIDATE OTP MODULE
    /***************************************************************************************** */

    handleValidateOtpChange(event) {
        let otp = this.state.otp;
        let value = event.target.value
        otp["otp"] = value
        this.setState({ otp })
    }

    handleValidationOTPonBlur(e) {
        let fields = this.state.otp;
        let errors = this.state.errors;
        let formIsValid = true;
        let name = e.currentTarget.name;

        if (name === "otp") {
            if (!fields["otp"]) {
                formIsValid = false;
                errors["otp"] = Messages.v_EnterOTP
            }
            else if (typeof fields["otp"] !== "undefined") {
                if (!validateOtpNo(fields["otp"])) {
                    formIsValid = false;
                    errors["otp"] = Messages.v_EnterValidOTP;
                }
            }
        }

        if (formIsValid) errors[name] = "";
        this.setState({
            fields,
            errors
        });
    }


    validateOTPForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.otp;

        if (!fields["otp"]) {
            formIsValid = false;
            errors["otp"] = Messages.v_EnterOTP
        }
        else if (typeof fields["otp"] !== "undefined") {
            if (!validateOtpNo(fields["otp"])) {
                formIsValid = false;
                errors["otp"] = Messages.v_EnterValidOTP;
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleValidateOtpSubmit(e) {
        e.preventDefault();
        if (this.validateOTPForm()) {
            this.setState({ isActive: true });
            var postData = {};
            postData["Email"] = this.state.userFields.Email;
            postData["PhoneNumber"] = this.state.userFields.PhoneNumber;
            postData["DeviceId"] = ""; /* Constants.DEVICE_ID */
            postData["DeviceType"] = "";/* Constants.DEVICE_TYPE */
            postData["Code"] = this.state.otp.otp;
            console.log('===> otpvaidate', postData)
            this.callValidateOtpAPI(postData);
        }
    }

    callValidateOtpAPI(postData) {
        var URL = VALIDATE_OTP_API;
        PostData(URL, postData)
            .then(result => {
                var responseJson = result;
                console.log('responseJson', responseJson)
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var authToken = responseJson["Data"].Token;
                    var selectedAddressDetail = this.state.selectedAddressDetail
                    if (authToken && selectedAddressDetail) {
                        this.handelAddressSubmit(authToken, selectedAddressDetail)
                    }
                    StoreDataInStorage(Constants.AUTH_TOKEN, authToken);
                    let errors = {};
                    this.setState({ errors: errors, isActive: false });
                    this.props.history.push({
                        pathname: RouteConstant.Login,
                    });
                }
                else if (responseJson["StatusCode"] === 400) {
                    let errors = {};
                    errors["otp"] = responseJson.Message;
                    this.setState({ errors: errors, isActive: false });
                }
                else {
                    let errors = {};
                    errors["otp"] = Messages.ERR_SomethingWentWrong;
                    this.setState({ errors: errors, isActive: false });
                }
            }).catch(error => {
                let errors = {};
                errors["otp"] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
            });
    }

    handelAddressSubmit(authToken, addressDetail) {
        var postData = {}
        postData['StreetAddress'] = "";
        postData['ApartmentNumber'] = "";
        postData['PostalCode'] = "";
        if (addressDetail && addressDetail.address_components) {
            for (var obj of addressDetail.address_components) {
                const componentType = obj.types[0];
                switch (componentType) {
                    case GOOGLEPLACEAPIMAP.STREET_NUMBER: {
                        postData['ApartmentNumber'] = obj.long_name;
                        break;
                    }
                    case GOOGLEPLACEAPIMAP.ROUTE: {
                        postData['ApartmentNumber'] = postData['ApartmentNumber'] + ", " + obj.short_name;
                        break;
                    }
                    case GOOGLEPLACEAPIMAP.POSTAL_CODE: {
                        postData['PostalCode'] = removeAllSpace(obj.long_name);
                        break;
                    }
                    case GOOGLEPLACEAPIMAP.POSTAL_CODE_SUFFIX: {
                        postData['PostalCode'] = removeAllSpace(postData['PostalCode'] + "-" + obj.long_name);
                        break;
                    }
                    case GOOGLEPLACEAPIMAP.LOCALITY: {
                        postData['StreetAddress'] = obj.long_name;
                        break;
                    }
                    case GOOGLEPLACEAPIMAP.ADMINISTRATIVE_AREA_LEVEL_1: {
                        postData['StreetAddress'] = postData['StreetAddress'] + ", " + obj.short_name;
                        break;
                    }
                    case GOOGLEPLACEAPIMAP.COUNTRY:
                        postData['StreetAddress'] = postData['StreetAddress'] + ", " + obj.long_name;
                        break;
                }
            }
        }
        console.log("=====", postData)
        this.addAddress(postData, authToken)
    }

    addAddress(postData, authToken) {
        this.setState({ isActive: true });
        var URL = USER_ADDRESS;
        PostData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                console.log("==== address", result)
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {

                }
                else {
                    this.setState({ isActive: false });
                    // alert(responseJson["Message"])
                }
            })
    }


    /***************************************************************************************** */
    // END --> FUNCTIONS FOR VALIDATE OTP MODULE
    /***************************************************************************************** */


    render() {
        /* Google Captcha Code */
        const { plazaList, hideEmptyPlazaView, isPassword, isConfirmPassword, /*googleReCaptch, iscaptchreload,*/ issignupwithgooogle, iscaptchwordMatch, captchWord } = this.state;
        const columns = [
            {
                maxWidth: "80px",
                cell: row => <img style={{ height: "70px", width: "70px", padding: "5px", borderRadius: "10px", cursor: "pointer" }} onClick={() => this.handleRowClick()} src={row.Imageurl ? row.Imageurl : NoPriview} onError={(e) => (e.target.onerror = null, e.target.src = NoPriview)} className="iconDetails" />
            },
            {
                cell: row => <div style={{ marginLeft: "0px", fontSize: "15px", cursor: "pointer" }} onClick={() => this.handleRowClick()}>{row.CompanyName}</div>
            },
        ];

        return (
            <LoadingOverlay
                active={this.state.isActive}
                text={Constants.LoadingMssg}
                spinner
                styles={{
                    overlay: (base) => ({
                        ...base,
                        position: "fixed"
                    })
                }}
                className="">
                <div>
                    {/* <Header /> */}
                    <div class="hero_area">
                        <MainHeader />
                    </div>
                    <section className="section mbottom" /* style={{ height: `${window.screen.height}px` }} */>
                        <div className="registed_form_tab">
                            <input id="step1" className="hiddenCheckBox" type="checkbox" />
                            <input id="step2" className="hiddenCheckBox" type="checkbox" />
                            <input id="step3" className="hiddenCheckBox" type="checkbox" />
                            <input id="step4" className="hiddenCheckBox" type="checkbox" />
                            <input id="step5" className="hiddenCheckBox" type="checkbox" />
                            <input id='step6' className="hiddenCheckBox" type='checkbox' />

                            {/* <div id="part1" className="col-sm-5 center" style={{ marginTop: "10px" }}>
                            <div className="reg_form_main" style={{ borderColor: Color.THEME_COLOR }}>
                                <ReactFlagsSelect
                                    className="menu-flags"
                                    style={{ borderColor: Color.THEME_COLOR }}
                                    name="CountryCode"
                                    defaultCountry="US"
                                    countries={["US", "CA", "IN"]}
                                    selected={this.state.selectedCountry}
                                    //countries={this.state.countryData}
                                    placeholder="Select Country"
                                    onSelect={this.onSelectFlag}
                                />

                                <div className="errorMsg">
                                    {this.state.errors.CountryCode}
                                </div>
                            </div>

                            <div className="reg_form_main">
                                <p className="col-sm-6" style={{ color: Color.THEME_COLOR }} for="PostalCode">
                                    Enter ZIP / Postal Code:
                                                    </p>
                                <input
                                    type="text"
                                    onChange={this.handleCountryChange}
                                    name="PostalCode"
                                    value={this.state.countryFields.PostalCode}
                                    placeholder="Enter ZIP / Postal Code"
                                />
                                <div className="errorMsg">
                                    {this.state.errors.PostalCode}
                                </div>
                            </div>
                            <div className="bottam_sing">
                                <label
                                    style={{ borderRadius: "50px", backgroundColor: Color.THEME_COLOR }}
                                    for="step2"
                                    id="continue-step2"
                                    className="col-sm-10 btn btn-done"
                                    onClick={this.handleCountrySubmit}
                                >SIGN UP</label>
                            </div>
                            <div className="bottam_sing">
                                <label
                                    style={{ borderRadius: "50px", backgroundColor: Color.THEME_COLOR }}
                                    for="step2"
                                    id="continue-step2"
                                    onClick={this.handleSignIn}
                                    className="col-sm-10 btn btn-done">SIGN IN</label>
                            </div>
                        </div> */}

                            {/*  // changes for Brewers 9-may-2023 */}
                            {/* {this.state.page === 1 && <div id="part1" >

                                {!hideEmptyPlazaView
                                    ?
                                    <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }} >
                                        <div className="bottam_sing">
                                            <img src={plazablankIcon}></img>
                                            <p className="col-sm-12" for="FirstName">
                                                {Messages.NO_PLAZA}
                                            </p>
                                            <label
                                                style={{ borderRadius: "4px", backgroundColor: Color.THEME_COLOR, margin: '0px' }}
                                                for="step2"
                                                id="continue-step2"
                                                onClick={this.handlePlazaContinue}
                                                className="btn btn-done"
                                            //hidden={hideBtn}
                                            > {RegisterConst.btn_Continue}</label>
                                        </div>

                                    </div>
                                    :
                                    <div className="reg_form_main">
                                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px", display: this.state.hideEmptyPlazaView }} >
                                            <DataTableExtensions
                                                data={plazaList}
                                                columns={columns}
                                                export={false}
                                                print={false}
                                                filter={true}
                                                filterHidden={false}
                                                filterPlaceholder={"Search for Plaza"}
                                                filterStyle={{ width: "100px" }}
                                            >
                                                <DataTable
                                                    noHeader={true}
                                                    noTableHead={true}
                                                    //pagination
                                                    onRowClicked={this.handleRowClick}
                                                />
                                            </DataTableExtensions>
                                        </div>
                                    </div>
                                }
                            </div>} */}
                            {/*  */}

                            {this.state.page === 2 && <div id="part2">
                                <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }} >
                                    {/* <div className="reg_form_main">
                                        <center><img src={plazacartLogo} className="col-sm-4"></img></center>
                                    </div> */}
                                    <div className="reg_form_main offset-sm-3 col-sm-6">
                                        <div className="bottam_sing">
                                            <center><div
                                                className="col-lg-7 btn btn-done d-flex"
                                                style={{
                                                    height: "44px",
                                                    borderRadius: "4px", backgroundColor: Color.THEME_COLOR,
                                                    maxWidth: '300px',
                                                    padding: 0,
                                                    alignItems: 'center',
                                                    gap: '70px'
                                                }}
                                                onClick={() => this.navigatepage()}>
                                                <img src={email} style={{ height: "30px", width: "30px" }} ></img>
                                                <label
                                                    className="m-0" style={{ fontSize: "14px", letterSpacing: '0.25px' }}
                                                    for="step3"
                                                    id="continue-step3"
                                                > {RegisterConst.Sign_up_with_Email}</label>
                                            </div>
                                            </center>
                                        </div>
                                        <div className="bottam_sing">
                                            <LoginWithGoogle
                                                mode={2}
                                                clientId={GOOGLE_CLIENT_ID}
                                                disabled={false}
                                                onSuccess={this.ResponsewithGooglewithSigninApi} />
                                        </div>
                                        {/* <div className="bottam_sing">
                                            <GoogleLogin
                                                clientId={Constants.GOOGLE_CLIENT_ID}
                                                icon={true}
                                                render={renderProps => (
                                                    <div
                                                        className="col-lg-7 btn btn-done "
                                                        style={{ height: "50px", borderRadius: "4px", backgroundColor: Color.GOOGLE_BTN_COLOR, margin: '0px' }}>
                                                        <img src={gmailLogo} style={{ height: "30px", width: "30px", float: "left", marginRight: "auto" }}></img>
                                                        <label
                                                            //style={{marginLeft:"auto"}}
                                                            onClick={renderProps.onClick}
                                                            disabled={renderProps.disabled}
                                                        > {RegisterConst.btn_JOINWITHGOOGLE}</label>
                                                    </div>
                                                )}
                                                //buttonText="Join with Google"
                                                onSuccess={this.responseGoogle}
                                                onFailure={this.responseGoogle}
                                                cookiePolicy={'single_host_origin'}
                                            />
                                        </div> */}

                                        {/* <div className="bottam_sing">
                                            <FacebookLogin
                                                appId={Constants.FACEBOOK_APP_ID}
                                                // autoLoad={true}
                                                callback={this.responseFacebook}
                                                fields="name,email,picture"
                                                scope="public_profile"
                                                render={renderProps => (
                                                    <div
                                                        className="col-lg-7 btn btn-done "
                                                        style={{ height: "50px", borderRadius: "4px", backgroundColor: Color.FACEBOOK_BTN_COLOR, margin: '0px' }}>
                                                        <img src={facebookLogo} style={{ height: "30px", width: "30px", float: "left" }}></img>
                                                        <label
                                                            onClick={renderProps.onClick}
                                                        > {RegisterConst.btn_JOINWITHFACEBOOK}</label>
                                                    </div>
                                                )} />
                                        </div> */}

                                        <div className="bottam_sing">
                                            <a href="/login" style={{ color: Color.THEME_COLOR, fontWeight: 'bold' }} >{RegisterConst.ALREADYAUSERSIGNIN}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                            {this.state.page === 3 && <div id="part3">
                                <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                                    {/* <div className="reg_form_main">
                                        <center><img src={plazacartLogo} className="col-sm-4"></img></center>
                                    </div> */}

                                    <div className="reg_form_main offset-sm-3 col-sm-6">
                                        <p className="col-sm-12 textcolor" for="FirstName" style={{ fontWeight: 'bold' }}>
                                            {RegisterConst.FirstName}<PutMandatoryfieldSymbol />
                                        </p>
                                        <input
                                            type="text"
                                            placeholder={RegisterPH.FirstName}
                                            name="FirstName"
                                            className="float-none"
                                            value={this.state.userFields.FirstName}
                                            onChange={this.handleChange}
                                            onBlur={this.handleValidationOnBlur}
                                        />
                                        <div className="col-sm-12 errorMsg mb-0">
                                            {this.state.errors.FirstName}
                                        </div>
                                    </div>

                                    <div className="reg_form_main offset-sm-3 col-sm-6">
                                        <p className="col-sm-12 textcolor" for="LastName" style={{ fontWeight: 'bold' }}>
                                            {RegisterConst.LastName} <PutMandatoryfieldSymbol />
                                        </p>
                                        <input
                                            type="text"
                                            placeholder={RegisterPH.LastName}
                                            name="LastName"
                                            className="float-none"
                                            value={this.state.userFields.LastName}
                                            onChange={this.handleChange}
                                            onBlur={this.handleValidationOnBlur}
                                        />
                                        <div className="col-sm-12 errorMsg mb-0">
                                            {this.state.errors.LastName}
                                        </div>
                                    </div>

                                    {!this.state.isGoogleFacebook && <div className="reg_form_main offset-sm-3 col-sm-6">
                                        <p className="col-sm-12 textcolor" style={{ color: Color.THEME_COLOR, fontWeight: 'bold' }} for="Email">
                                            {RegisterConst.Email} <PutMandatoryfieldSymbol />
                                        </p>
                                        <input
                                            type="email"
                                            placeholder={RegisterPH.Email}
                                            name="Email"
                                            disabled={issignupwithgooogle}
                                            className="float-none"
                                            value={this.state.userFields.Email}
                                            onChange={this.handleChange}
                                            onBlur={this.handleValidationOnBlur}
                                        />
                                        <div className="col-sm-12 errorMsg mb-0">
                                            {this.state.errors.Email}
                                        </div>{" "}
                                        <div className="col-sm-12 errorMsg mb-0">
                                            {this.state.alreadyEmail}
                                        </div>
                                    </div>}

                                    {!this.state.isGoogleFacebook && <div className="reg_form_main offset-sm-3 col-sm-6">
                                        <p className="col-sm-12 textcolor" for="Password" style={{ fontWeight: 'bold' }}>
                                            {RegisterConst.Password} <PutMandatoryfieldSymbol />
                                        </p>
                                        <div className="row" style={{ backgroundColor: "#f0f0f0", borderRadius: "50px", marginBottom: '15px', marginLeft: "0px", marginRight: "0px" }}>
                                            <input
                                                style={{ width: "90%", margin: 0 }}
                                                type={isPassword ? "password" : "text"}
                                                placeholder={RegisterPH.Password}
                                                name="Password"
                                                className="float-none"
                                                value={this.state.userFields.Password}
                                                onChange={this.handleChange}
                                                onBlur={this.handleValidationOnBlur} />
                                            <i class={!isPassword ? "fa fa-eye" : "fa fa-eye-slash"} style={{ color: Color.THEME_COLOR, width: '30px', marginTop: "14px" }} onClick={() => this.setState({ isPassword: !isPassword })} />
                                        </div>
                                        <div className="col-sm-12 errorMsg mb-0">
                                            {this.state.errors.Password}
                                        </div>
                                    </div>}

                                    {!this.state.isGoogleFacebook &&
                                        <div className="reg_form_main offset-sm-3 col-sm-6">
                                            <p className="col-sm-12 textcolor" for="Password" style={{ fontWeight: 'bold' }}>
                                                {RegisterConst.ConfirmPassword} <PutMandatoryfieldSymbol />
                                            </p>
                                            <div className="row" style={{ backgroundColor: "#f0f0f0", borderRadius: "50px", marginBottom: '15px', marginLeft: "0px", marginRight: "0px" }}>
                                                <input
                                                    style={{ width: "90%", margin: 0 }}
                                                    type={isConfirmPassword ? "password" : "text"}
                                                    placeholder={RegisterPH.ConfirmPassword}
                                                    name="ConfirmPassword"
                                                    className="float-none"
                                                    value={this.state.userFields.ConfirmPassword}
                                                    onChange={this.handleChange}
                                                    onBlur={this.handleValidationOnBlur} />
                                                <i class={!isConfirmPassword ? "fa fa-eye" : "fa fa-eye-slash"} style={{ color: Color.THEME_COLOR, width: '30px', marginTop: "14px" }} onClick={() => this.setState({ isConfirmPassword: !isConfirmPassword })} />
                                            </div>

                                            <div className="col-sm-12 errorMsg mb-0">
                                                {this.state.errors.ConfirmPassword}
                                            </div>
                                        </div>
                                    }

                                    <div className="reg_form_main offset-sm-3 col-sm-6">
                                        <p className="col-sm-12 textcolor" for="PhoneNumber" style={{ fontWeight: 'bold' }}>
                                            {RegisterConst.screen4Msg1} <PutMandatoryfieldSymbol />
                                        </p>
                                        <input
                                            className="col-sm-12 float-none"
                                            type="text"
                                            pattern="[0-9]*"
                                            placeholder={RegisterPH.PhoneNumber}
                                            name="PhoneNumber"
                                            maxLength="12"
                                            value={this.state.userFields.PhoneNumber}
                                            onChange={this.handleChange}
                                            onBlur={this.handleValidationOnBlur} />
                                        <div className="col-sm-12 errorMsg mb-0">
                                            {this.state.errors.PhoneNumber}
                                        </div>
                                    </div>

                                    <div className="reg_form_main offset-sm-3 col-sm-6">
                                        <div >
                                            <div className="col-sm-12">
                                                <Checkbox
                                                    color={Color.THEME_COLOR}
                                                    size={2}
                                                    tickSize={2}
                                                    checked={this.state.chkBoxChecked}
                                                    delay={0}
                                                    backAnimationDuration={0}
                                                    tickAnimationDuration={0}
                                                    onChange={this.handleCheckBoxChange}></Checkbox>
                                                &nbsp; &nbsp;<span className="textcolor">{RegisterConst.screen3Msg} <a href={RouteConstant.TermsOfService} className="atext" target="_blank">{RegisterConst.screen3Msg1}</a> {RegisterConst.screen3Msg2} <a href={RouteConstant.PrivacyPolicy} target="_blank" className="atext">{RegisterConst.screen3Msg3}</a> {RegisterConst.screen3Msg4}</span>
                                            </div>
                                            <div className="col-sm-12 errorMsg" style={{ marginTop: '7px' }}>
                                                {this.state.errors.termspolicy}
                                            </div>
                                        </div>
                                    </div>

                                    {/* Google Captcha Code */}
                                    {/* <div className="reg_form_main offset-sm-3 col-sm-6 text-center">
                                        {iscaptchreload && <ReCAPTCHA
                                            style={{ display: "inline-block" }}
                                            // theme="dark"
                                            // ref={this._reCaptchaRef}
                                            size="normal"
                                            sitekey={GOOGLESITEKEY}
                                            onChange={this.handleGoogleRecaptcha}
                                        // asyncScriptOnLoad={this.asyncScriptOnLoad}
                                        />}
                                    </div> */}

                                    {/* Native Captcha Code */}
                                    <div className="reg_form_main offset-sm-3 col-sm-6">
                                        <p className="col-sm-12 textcolor" for="PhoneNumber" style={{ fontWeight: 'bold' }}>
                                            {RegisterConst.Captcha} <PutMandatoryfieldSymbol />
                                        </p>
                                        <input
                                            type="text"
                                            placeholder={RegisterPH.Captcha}
                                            name="captchacode"
                                            className="float-none m-0 mb-2"
                                            value={this.state.userFields.captchacode}
                                            maxLength="6"
                                            onChange={this.handleChange}
                                            onBlur={this.handleValidationOnBlur} />
                                        <div className="col-sm-12 errorMsg mb-2">
                                            {this.state.errors.captchacode}
                                        </div>
                                        <div className="text-center">
                                            <ACaptcha
                                                font={'30px Arial'}
                                                align={'center'}
                                                baseline={'middle'}
                                                width={200}
                                                height={50}
                                                bgColor={Color.THEME_COLOR}
                                                color={Color.WHITE}
                                                captchstr={captchWord} />
                                        </div>
                                    </div>

                                    <div className="reg_form_main offset-sm-3 col-sm-6">
                                        <label className="col-sm-12">
                                            {RegisterConst.screen4Msg}
                                        </label>
                                    </div>
                                    <div className="reg_form_main">
                                        <div className="bottam_sing" >
                                            <button
                                                style={{ borderRadius: "4px", backgroundColor: Color.THEME_COLOR, marginBottom: '10px' }}
                                                for="step4"
                                                id="continue-step4"
                                                onClick={this.handleSubmitPhoneNo}
                                                type="button"
                                                className="btn btn-done"
                                                disabled={!iscaptchwordMatch /* !googleReCaptch */}/* Google Captcha Code */
                                            > {RegisterConst.btn_Continue}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                            {/* {this.state.page === 4 && <div id="part4">
                                <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }} >
                                    <div className="reg_form_main">
                                        <center><img src={plazacartLogo} className="col-sm-4"></img></center>
                                    </div>
                                    <div className="reg_form_main offset-sm-3 col-sm-6">
                                        <label className="col-sm-12 textcolor p-0">
                                            {RegisterConst.screen4Msg}
                                        </label>
                                    </div>

                                    <div className="reg_form_main offset-sm-3 col-sm-6">
                                        <p className="col-sm-12 textcolor p-0" for="PhoneNumber" style={{ fontWeight: 'bold' }}>
                                            {RegisterConst.screen4Msg1} <PutMandatoryfieldSymbol />
                                        </p>
                                        <input
                                            className="col-sm-12 float-none"
                                            type="text"
                                            pattern="[0-9]*"
                                            placeholder={RegisterPH.PhoneNumber}
                                            name="PhoneNumber"
                                            maxLength="12"
                                            value={this.state.userFields.PhoneNumber}
                                            onChange={this.handleChange}
                                            onBlur={this.handleValidationOnBlur} />
                                        <div className="col-sm-12 errorMsg mb-0">
                                            {this.state.errors.PhoneNumber}
                                        </div>
                                    </div>
                                    <div className="reg_form_main">
                                        <div className="bottam_sing mt-3">
                                            <label
                                                style={{ borderRadius: "4px", backgroundColor: Color.THEME_COLOR, margin: '0px' }}
                                                for="step5"
                                                id="continue-step5"
                                                onClick={this.handleSubmitPhoneNo}
                                                className="btn btn-done"
                                            > {RegisterConst.btn_Continue}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>} */}

                            {this.state.page == 5 && <div id="part5">
                                <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }} >
                                    <div className="reg_form_main offset-sm-3 col-sm-6">
                                        <p className="col-sm-12 textcolor" for="otp" style={{ fontWeight: 'bold', marginTop: '20px' }}>
                                            {RegisterConst.screen5Msg} <PutMandatoryfieldSymbol />
                                        </p>
                                        <input
                                            type="text"
                                            pattern="[0-9]*"
                                            inputmode="numeric"
                                            placeholder={RegisterPH.Enterotpnumber}
                                            className="float-none"
                                            name="otp"
                                            maxLength="6"
                                            value={this.state.otp.otp}
                                            onChange={this.handleValidateOtpChange}
                                            onBlur={this.handleValidationOTPonBlur}
                                        />
                                        <div className="errorMsg mb-0">
                                            {this.state.errors.otp}
                                        </div>
                                    </div>
                                    <div className="reg_form_main">
                                        <div className="bottam_sing mt-3">
                                            <label
                                                style={{ borderRadius: "4px", backgroundColor: Color.THEME_COLOR, margin: '0px' }}
                                                for="step2"
                                                id="continue-step2"
                                                onClick={this.handleValidateOtpSubmit}
                                                className="btn btn-done"
                                            > {RegisterConst.btn_Submit}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </section>
                    <MainFooter />
                </div>
            </LoadingOverlay>
        );
    }
}
export default RegisterNew;