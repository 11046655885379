import React, { Component } from 'react';
import './Checkout.css';
import Color from '../Config/Color';
import Collapsible from 'react-collapsible';
import ontime1 from "../../images/icons/on-time2.png";
import PickupTimeDay from './PickupTimeDay';
import DeliveryTimeDay from './DeliveryTimeDay';





class PlazaStore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            plazalist: props.data,
            timeDayHeader: "",
            selectedRdoBtn: props.selectedRdoBtn,

        }
    }

    componentDidMount() {
        this.setHeaderForDeliveryPickup();
    }

    setHeaderForDeliveryPickup = () => {
        if (this.state.selectedRdoBtn === "2") {
            this.setState({ timeDayHeader: "Delivery" });
        } else {
            this.setState({ timeDayHeader: "Pickup" });
        }
    }

    setDate = (date, storeId, plazaId) => {
        //console.log(date, storeId, plazaId)
        //console.log("before setDate ", this.state.plazalist);
        var list1 = this.props.data.map(curr => {
            if (curr.PlazaId === plazaId) {
                var storelist = curr.Stores
                var list2 = storelist.map(objStore => {
                    if (objStore.ObjectId === storeId) {
                        return { ...objStore, DeliveryDate: date }
                    }
                    else {
                        return { ...objStore }
                    }
                })
                return { ...curr, Stores: list2 }
            }
            else {
                return { ...curr }
            }
        })

        //console.log("setDate plaza  ", list1);
        this.setState({ plazalist: list1 }, function () {
            //console.log("plazalist   ", this.state.plazalist);
            this.props.updateCartObj(this.state.plazalist)
        })
    }

    setTime = (time, storeId, plazaId) => {
        var list1 = this.props.data.map(curr => {
            if (curr.PlazaId === plazaId) {
                var storelist = curr.Stores
                var list2 = storelist.map(objStore => {
                    if (objStore.ObjectId === storeId) {
                        return { ...objStore, DeliveryTime: time }
                    }
                    else {
                        return { ...objStore }
                    }
                })
                return { ...curr, Stores: list2 }
            }
            else {
                return { ...curr }
            }
        })

        //console.log("setTime plaza  ", list1);
        this.setState({ plazalist: list1 }, function () {
            //console.log("plazalist   ", this.state.plazalist);
            this.props.updateCartObj(this.state.plazalist)
        })
    }

    setTimewithcharge = (timeObj, storeId, plazaId) => {
        var list1 = this.props.data.map(curr => {
            if (curr.PlazaId === plazaId) {
                var storelist = curr.Stores
                var list2 = storelist.map(objStore => {
                    if (objStore.ObjectId === storeId) {
                        return { ...objStore, DeliveryTime: timeObj.DeliveryTime, "TotalDeliveryCharges": parseFloat(timeObj.DeliveryCost) }
                    }
                    else {
                        return { ...objStore }
                    }
                })

                return { ...curr, Stores: list2 }
            }
            else {
                return { ...curr }
            }
        })

        console.log("setTime plaza  delivery=====", list1);
        this.setState({ plazalist: list1 }, function () {
            //console.log("plazalist   ", this.state.plazalist);
            this.props.updateCartObj(this.state.plazalist)
        })
    }


    render() {

        // console.log("1", this.state.plazalist)

        let list = this.props.data
            .map(function (item, index) {
                var storelist = item.Stores.map(function (objStore, index1) {
                    var Deliverytimes = objStore.Deliverytimes
                    var Pickuptimes = objStore.Pickuptimes
                    var storeID = objStore.ObjectId
                    var preselectedTime = objStore.Deliverytimes[0] ? objStore.Deliverytimes[0].DeliveryTime : "";
                    return (
                        <React.Fragment key={objStore.ObjectId}>
                            <div className="">
                                <Collapsible
                                    open={false}
                                    trigger={
                                        <div className="col-sm-12" style={{ marginTop: "10px", backgroundColor: Color.WHITE, paddingBottom: "5px", borderBottom: "1px solid" + Color.GREY, marginBottom: "5px" }} >
                                            <div style={{ width: "100%" }}>
                                                <div className="row">
                                                    <img src={ontime1} style={{ height: "30px", width: "30px" }}></img>
                                                    <label style={{ marginLeft: "5px", color: Color.BLACK }}>{objStore.ApplicationName} - {this.props.selectedRdoBtn === "1" ? "Pickup" : "Delivery"} Time/Day</label>
                                                    <i class="fa fa-caret-down" style={{ marginLeft: "auto", fontSize: "30px", color: Color.THEME_COLOR, float: "right", marginRight: "5px" }} aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    triggerWhenOpen={
                                        <div className="col-sm-12" style={{ marginTop: "10px", backgroundColor: Color.WHITE, paddingBottom: "5px", borderBottom: "1px solid" + Color.GREY, marginBottom: "5px" }} >
                                            <div style={{ width: "100%" }}>
                                                <div className="row">
                                                    <img src={ontime1} style={{ height: "30px", width: "30px" }}></img>
                                                    <label style={{ marginLeft: "5px", color: Color.BLACK }}>{objStore.ApplicationName} - {this.props.selectedRdoBtn === "1" ? "Pickup" : "Delivery"} Time/Day</label>
                                                    <i class="fa fa-caret-up" style={{ marginLeft: "auto", fontSize: "30px", color: Color.THEME_COLOR, float: "right", marginRight: "5px" }} aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </div>
                                    } >

                                    <div className="col-sm-12" style={{ marginTop: "10px" }}>
                                        {
                                            this.props.selectedRdoBtn === "1" ?
                                                <PickupTimeDay
                                                    plazaid={item.PlazaId}
                                                    storeid={objStore.ObjectId}
                                                    setPickupDay={this.setDate}
                                                    setPickupTime={this.setTime}
                                                    pickUpTimesList={Pickuptimes}  >
                                                </PickupTimeDay>
                                                :
                                                <DeliveryTimeDay
                                                    plazaid={item.PlazaId}
                                                    storeid={objStore.ObjectId}
                                                    setPickupDay={this.setDate}
                                                    setPickupTime={this.setTimewithcharge}
                                                    setDeliveryCharge={this.setDeliveryCharge}
                                                    pickUpTimesList={Deliverytimes}  >
                                                </DeliveryTimeDay>
                                        }
                                    </div>
                                </Collapsible>
                            </div>
                        </React.Fragment>
                    )
                }, this)
                return (
                    <React.Fragment key={item.PlazaId}>
                        <p className="textcolor" style={{ marginTop: "5px" }}>
                            {item.CompanyName}
                            {storelist}
                        </p>
                    </React.Fragment>
                )
            }, this);

        return (
            <div>
                {list}
            </div>
        );
    }
}

export default PlazaStore;



