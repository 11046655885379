import React, { Component } from "react";
import Header from "./components/Header/Header";
import Routes from "./routes";

import { MainContext } from './components/Config/Utils';

class App extends Component {
  constructor() {
    super();
    this.state = {
      appName: "React App",
      home: false,
      cart: null,
      setCart: this.setCart,
      // store: null,
      // setStore: this.setStore,
      // cartItems: null,
      // setCartItems: this.setCartItems,
    };
  }

  setCart = (cart) => {
    this.setState({ cart });
  };

  render() {
    return (
       <MainContext.Provider value={this.state}>
      <div>
        <div data-off-canvas-content>
          {/* <Header name={this.state.appName} /> */}
          <Routes name={this.state.appName} />
        </div>
      </div>
     </MainContext.Provider>
     
    );
  }
}
export default App;