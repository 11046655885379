import React from 'react';
import { BrowserRouter, Route, Link, Switch } from 'react-router-dom';
import Welcome from '././components/Welcome/Welcome';

import SelectCountry from './components/SelectCountry/SelectCountry';
import Login from '././components/Login/Login';
import Register from '././components/RegistrationNew/RegistrationNew';
import ForgotPassword from '././components/ForgotPassword/ForgotPassword';

import EditProfile from './components/EditProfile/EditProfile';
import AddAddress from '././components/SideMenu/AddAddress';
import NotificationDetails from './components/SideMenu/NotificationDetails';
import OrderDetail from './components/OrderDetails/OrderDetail';
import CurbsideOrder from './components/CurbsideOrder/CurbsideOrder';
import Country from './components/SideMenu/Country';

import PlazaList from '././components/PlazaList/PlazaList';
import PlazaStore from './components/PlazaStore/PlazaStore';
import PlazaStoreItems from './components/PlazaStoreItems/PlazaStoreItems';
import AddToppings from './components/Toppings/AddToppings';

import AddCart from './components/AddToCart/AddCart';
import MyCart from './components/MyCart/MyCart';
import Checkout from './components/Checkout/Checkout';
import Payment from './components/Payment/Payment';

import RouteConstant from './components/Config/RouteConstant';

import TryIt from './components/TryIt/TryIt'

import PlazaStoreItemsShopify from './components/ShopifyComponent/PlazaStoreItems/PlazaStoreItemsShopify';
import PlazaStoreItemsDetailShopify from './components/ShopifyComponent/PlazaStoreItemsDetail/PlazaStoreItemsDetailShopify';
import ProductSearch from './components/productSearch/productSearch';
import AddCartSingleStore from './components/plazacartSingleStore/AddToCart/AddCartSingleStore';
import ShopifyAddCartSingleStore from './components/plazacartSingleStore/ShopifyComponent/PlazaStoreItemsDetail/ShopifyAddCartSingleStore';
import MyCartSingleStore from './components/plazacartSingleStore/MyCart/MyCartSingleStore';
import CheckoutSingleStore from './components/plazacartSingleStore/Checkout/CheckoutSingleStore';
import OrderDetailSingleStore from './components/plazacartSingleStore/OrderDetails/OrderDetailSingleStore';
import CurbsideOrderSingleStore from './components/plazacartSingleStore/CurbsideOrder/CurbsideOrderSingleStore';
import TermsofService from './components/Pages/TermsofService';
import PrivacyPolicy from './components/Pages/PrivacyPolicy';
import AboutUs from './components/Pages/AboutUs';
import HelpAndSupport from './components/Pages/HelpAndSupport';
import Appointment from './components/appointment/Appointment';
import MyAppointment from './components/appointment/MyAppointment';
import MasterContextProvider from './components/MasterContextProvider/MasterContextProvider';
import PlazaCartPartner from './components/PlazaCartPartner/PlazaCartPartner';
import ContactUs from './components/Pages/ContactUs';

/* ORDER WEB ROUTE CHANGES FOR PUBLIC */
import PlazaOrderWebStoreItems from './components/OrderWeb/PlazaStoreItems/PlazaOrderWebStoreItems';
import AddCartOrderWebSingleStore from './components/OrderWeb/AddToCart/AddCartOrderWebSingleStore';
import MyCartOrderWebSingleStore from './components/OrderWeb/MyCart/MyCartOrderWebSingleStore';
import TryItOrderWeb from './components/OrderWeb/TryIt/TryItOrderWeb';
import CheckoutOrderWebSingleStore from './components/OrderWeb/Checkout/CheckoutOrderWebSingleStore';
import AddAddressOrderWeb from './components/OrderWeb/SideMenu/AddAddressOrderWeb';
import PaymentOrderWeb from './components/OrderWeb/Payment/PaymentOrderWeb';
import AboutUsOrderWeb from './components/OrderWeb/Pages/AboutUsOrderWeb';
import ContactUsOrderWeb from './components/OrderWeb/Pages/ContactUsOrderWeb';
import HelpAndSupportOrderWeb from './components/OrderWeb/Pages/HelpAndSupportOrderWeb';
import PrivacyPolicyOrderWeb from './components/OrderWeb/Pages/PrivacyPolicyOrderWeb';
import TermsofServiceOrderWeb from './components/OrderWeb/Pages/TermsofServiceOrderWeb';

const Routes = () => (
    <BrowserRouter>
        <div>
            <Switch>
                <MasterContextProvider>
                    <Route exact path={RouteConstant.Default} component={SelectCountry} />
                    <Route exact path={RouteConstant.Login} component={Login} />
                    <Route exact path={RouteConstant.Register} component={Register} />
                    <Route exact path={RouteConstant.ForgotPassword} component={ForgotPassword} />

                    <Route exact path={RouteConstant.Dashboard} component={PlazaStore} />
                    {/* // changes for Brewers 9-may-2023 */}
                    {/* <Route exact path={RouteConstant.Dashboard} component={ProductSearch} /> */}
                    {/*  */}
                    {/* <Route exact path={RouteConstant.Dashboard} component={PlazaList} /> */}

                    <Route exact path={RouteConstant.EditProfile} component={EditProfile} />
                    <Route exact path={RouteConstant.AddAddress} component={AddAddress} />
                    <Route exact path={RouteConstant.NotificationDetail} component={NotificationDetails} />

                    {/* <Route exact path={RouteConstant.OrderDetail} component={OrderDetail} /> */}
                    <Route exact path={RouteConstant.OrderDetail} component={OrderDetailSingleStore} />

                    {/* <Route exact path={RouteConstant.CurbsideOrder} component={CurbsideOrder} /> */}
                    <Route exact path={RouteConstant.CurbsideOrder} component={CurbsideOrderSingleStore} />

                    <Route exact path={RouteConstant.Country} component={Country} />

                    <Route exact path={RouteConstant.PlazaStore} component={PlazaStore} />

                    <Route exact path={RouteConstant.PlazaStoreItems} component={PlazaStoreItems} />
                    <Route exact path={RouteConstant.AddToppings} component={AddToppings} />

                    {/* <Route exact path={RouteConstant.AddToCart} component={AddCart} /> */}
                    <Route exact path={RouteConstant.AddToCart} component={AddCartSingleStore} />

                    <Route exact path={RouteConstant.TryIt} component={TryIt} />

                    {/* <Route exact path={RouteConstant.MyCart} component={MyCart} /> */}
                    <Route exact path={RouteConstant.MyCart} component={MyCartSingleStore} />

                    {/* <Route exact path={RouteConstant.Checkout} component={Checkout} /> */}
                    <Route exact path={RouteConstant.Checkout} component={CheckoutSingleStore} />

                    <Route exact path={RouteConstant.Payment} component={Payment} />

                    <Route exact path={RouteConstant.PlazaStoreItemsShopify} component={PlazaStoreItemsShopify} />

                    {/* <Route exact path={RouteConstant.PlazaStoreItemsDetailShopify} component={PlazaStoreItemsDetailShopify} /> */}
                    <Route exact path={RouteConstant.PlazaStoreItemsDetailShopify} component={ShopifyAddCartSingleStore} />

                    <Route exact path={RouteConstant.TermsOfService} component={TermsofService} />
                    <Route exact path={RouteConstant.PrivacyPolicy} component={PrivacyPolicy} />
                    <Route exact path={RouteConstant.AboutUs} component={AboutUs} />
                    <Route exact path={RouteConstant.HelpAndSupport} component={HelpAndSupport} />
                    <Route exact path={RouteConstant.Appointment} component={Appointment} />
                    <Route exact path={RouteConstant.MyAppointment} component={MyAppointment} />
                    <Route exact path={RouteConstant.PlazaCartPartner} component={PlazaCartPartner} />
                    <Route exact path={RouteConstant.ContactUs} component={ContactUs} />

                    {/* ORDER WEB ROUTE CHANGES FOR PUBLIC */}
                    <Route exact path={RouteConstant.OrderWebNavigation + "/:id"} component={PlazaOrderWebStoreItems} />
                    <Route exact path={RouteConstant.OrderWebAddtoCart} component={AddCartOrderWebSingleStore} />
                    <Route exact path={RouteConstant.OrderWebMyCart} component={MyCartOrderWebSingleStore} />
                    <Route exact path={RouteConstant.OrderWebTryIt} component={TryItOrderWeb} />
                    <Route exact path={RouteConstant.OrderWebCheckout} component={CheckoutOrderWebSingleStore} />
                    <Route exact path={RouteConstant.OrderWebAddress} component={AddAddressOrderWeb} />
                    <Route exact path={RouteConstant.OrderWebPayment} component={PaymentOrderWeb} />
                    <Route exact path={RouteConstant.OrderWebAboutUs} component={AboutUsOrderWeb} />
                    <Route exact path={RouteConstant.OrderWebContactUs} component={ContactUsOrderWeb} />
                    <Route exact path={RouteConstant.OrderWebHelpAndSupport} component={HelpAndSupportOrderWeb} />
                    <Route exact path={RouteConstant.OrderWebPrivacyPolicy} component={PrivacyPolicyOrderWeb} />
                    <Route exact path={RouteConstant.OrderWebTermsOfService} component={TermsofServiceOrderWeb} />


                </MasterContextProvider>
            </Switch>
        </div>
    </BrowserRouter>
);

export default Routes;