import React, { Component } from 'react';
import './SideMenu.css';
import Color from '../Config/Color';
import RouteConstant from '../Config/RouteConstant';

import delete1 from "../../images/icons/delete.png";
import read from "../../images/icons/read.png";
import Unread from "../../images/icons/Unread.png";

import { NavLink, withRouter } from 'react-router-dom';
import Moment from 'moment';
import { RetriveDataFromStorage, StoreObjectInStorage, RetriveObjectFromStorage, StoreDataInStorage } from "../Config/Utils";
import Constants from '../Config/Constants';
import { GET_NOTIFICATION_API } from '../Config/Config.json'
import { DeleteData } from '../../services/DeleteData';

class NotificationList extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.handleItemClick = this.handleItemClick.bind(this);
        this.closeNav = this.closeNav.bind(this);
    }

    componentDidMount() {
    }

    handleItemClick(data) {
        console.log("item click")
        console.log(JSON.stringify(data))
        this.closeNav()

        this.props.history.push({
            pathname: RouteConstant.NotificationDetail,
            search: '?id=' + data._id,
        })

        const { onrefresh } = this.props

        if (typeof onrefresh == "function") {
            onrefresh()
        }
    }

    handleDelete(data) {
        console.log("item Delete")
        // console.log(JSON.stringify(data))
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        // this.closeNav()
        // window.closeNav();
        this.deleteAddress(authToken, data._id);
    }

    deleteAddress(authToken, id) {
        var URL = GET_NOTIFICATION_API + "/" + id;
        var postData = {}
        DeleteData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.props.updateNotificationList()
                }
                else {
                    // alert(responseJson["Message"])
                }
            })
    }

    closeNav() {
        window.closeNav();
    }

    render() {
        let list = this.props.data
            .map(function (item, index) {
                return (
                    <React.Fragment key={item.OrderId}>
                        <div className="col-sm-12" style={{ backgroundColor: Color.WHITE, paddingBottom: "5px", borderBottom: "1px solid" + Color.GREY }}  >
                            <div className="row setaTag" style={{ fontSize: "12px", marginLeft: "1px", marginRight: "3px" }} onClick={(value) => this.handleItemClick(item)}>
                                {item.NotificationSubject}<br />
                                {/* {item.NotificationMessage} */}
                                <div dangerouslySetInnerHTML={{ __html: item.NotificationMessage }} ></div>
                            </div>
                            <div className="row" style={{ fontSize: "12px", marginLeft: "2px", marginRight: "5px" }} onClick={(value) => this.handleItemClick(item)}>
                                {Moment(item.CreatedDate).format("DD MMM YYYY h:mm a")}
                            </div>

                            <div className="row" >
                                <span style={{ marginLeft: "auto" }}>
                                    {item.IsRead ? <img src={read} style={{ marginRight: "10px", width: "20px", height: "20px" }}></img> :
                                        <img src={Unread} style={{ marginRight: "10px", width: "20px", height: "20px" }}></img>}
                                    <img class="setaTag" src={delete1} style={{ float: "right", marginRight: "16px", width: "20px", height: "20px" }} onClick={(value) => this.handleDelete(item)}></img>
                                    {/* <i class="fa fa-envelope-open" style={{fontSize: "20px", color:Color.THEME_COLOR,  marginRight:"10px", width:"20px"}}></i> */}
                                    {/* <i class="fa fa-trash"  style={{ fontSize: "20px",color:Color.GREY,float:"right", marginRight:"16px"  }}></i> */}
                                </span>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }, this);

        return (
            <div>
                {list}
            </div>
        );
    }
}

export default withRouter(NotificationList);