import React, { Component } from 'react';
import './SideMenu.css';
import WheelPhoto from "../../images/gear-wheel.png";
import Color from '../Config/Color';
import { withRouter } from 'react-router';

import delete1 from "../../images/icons/delete.png";
import pencil from "../../images/icons/pencil1.png";
import marker1 from "../../images/icons/marker1.png";
import RouteConstant from '../Config/RouteConstant';
import { RetriveDataFromStorage, StoreObjectInStorage, RetriveObjectFromStorage } from "../Config/Utils";
import Constants from '../Config/Constants';
import { USER_ADDRESS } from '../Config/Config.json'
import { DeleteData } from '../../services/DeleteData';

class AddressList extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.closeNav = this.closeNav.bind(this);
        this.handleItemClick = this.handleItemClick.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
    }

    handleItemClick(data) {
        console.log("item click")
        console.log(JSON.stringify(data))
    }

    handleEdit(data) {
        console.log("item Edit")
        console.log(JSON.stringify(data))
        StoreObjectInStorage(Constants.ADDRESS_OBJ, data);
        this.closeNav()
        this.props.history.push({
            pathname: RouteConstant.AddAddress
        });
        const { onrefresh } = this.props

        if (typeof onrefresh == "function") {
            onrefresh()
        }
    }

    handleDelete(data) {
        console.log("item Delete")
        // console.log(JSON.stringify(data))
        var authToken = RetriveDataFromStorage(Constants.AUTH_TOKEN);
        // this.closeNav()
        window.closeNav();
        this.deleteAddress(authToken, data._id);
    }

    deleteAddress(authToken, id) {
        var URL = USER_ADDRESS + "/" + id;
        var postData = {}
        DeleteData(URL, postData, authToken)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    this.props.updateAddressList()

                }
                else {
                    // alert(responseJson["Message"])
                }
            })
    }

    closeNav() {
        window.closeNav();
    }

    render() {
        let list = this.props.data
            .map(function (item, index) {
                return (
                    <React.Fragment key={item.OrderId}>
                        <div className="col-sm-12" style={{ backgroundColor: Color.WHITE, paddingBottom: "5px", borderBottom: "1px solid" + Color.GREY }} >
                            <div className="row" style={{ fontSize: "12px", marginLeft: "10px" }} onClick={(value) => this.handleItemClick(item)}>
                                <img src={marker1} style={{ height: "20px", width: "20px" }}></img>
                                {/* <i class="fa fa-map-marker"  style={{fontSize: "25px", color:Color.RED}} ></i> &nbsp;&nbsp; */}
                                {item.ApartmentNumber}<br />
                                {item.StreetAddress}<br />
                                {item.PostalCode}
                            </div>

                            <div className="row" >
                                <span style={{ marginLeft: "auto" }}>
                                    <img src={pencil} style={{ marginRight: "10px", width: "20px", height: "20px" }} onClick={(value) => this.handleEdit(item)}></img>
                                    <img src={delete1} style={{ float: "right", marginRight: "16px", width: "20px", height: "20px" }} onClick={(value) => this.handleDelete(item)}></img>
                                    {/* <i class="fas fa-pencil-alt" style={{fontSize: "20px", color:Color.THEME_COLOR,  marginRight:"10px", width:"20px"}} onClick={(value) => this.handleDelete(item)}></i> */}
                                    {/* <i class="fa fa-trash"  style={{ fontSize: "20px",color:Color.GREY,float:"right", marginRight:"16px"}} onClick={(value) => this.handleDelete(item)}></i> */}
                                </span>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }, this);

        return (
            <div>
                {list}
            </div>
        );
    }
}

export default withRouter(AddressList);