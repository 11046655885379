import { BackEndRequestURL } from '../components/Config/Config.json'

export function GetData(url, auth) {
  const proxyurl = "https://cors-anywhere.herokuapp.com/";
  let BaseURL =  BackEndRequestURL + url
  //console.log(BaseURL)
  return new Promise((resolve, reject) => {
    fetch(BaseURL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + auth
      }
    })
      .then(response => response.json())
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
        //alert(error)
      })
  })
}
