import React, { Component } from 'react';
import { PostData } from "../../services/PostData";
import "../../styles/style.css";
import { GOOGLE_CLIENT_ID, GOOGLE_SIGNIN_API, LOGIN_API } from '../Config/Config.json';
import Constants, { ContactUSConstFormPH, DEFAULTID } from '../Config/Constants';
import Messages from "../Config/Messages";
import { StoreDataInStorage, getCookie, removeCookie, setCookie, validateEmail, validatePassword, logoutSession } from "../Config/Utils";
import './Login.css';
//import FacebookLogin from 'react-facebook-login';
import {
    browserName, fullBrowserVersion, osName,
    osVersion
} from "react-device-detect";
import LoadingOverlay from 'react-loading-overlay';
import Checkbox from 'react-simple-checkbox';
import Color from '../Config/Color';
import { LoginPH } from "../Config/Placeholder";
import RouteConstant from '../Config/RouteConstant';
import { LoginConst } from "../Config/constvariable";
import LoginWithGoogle from "../LoginWithGoogleFiles/LoginWithGoogle";
import MainFooter from '../MainFooter/MainFooter';
import MainHeader from '../MainHeader/MainHeader';
import { ACaptcha, GETWORD } from '../UtillCustomComponent/CaptchaGenerator';
const publicIp = require('public-ip');

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // cookieCredential: getCookie(Constants.Cookie_LoginCredential),
            userFields: {},
            errors: {},
            checkedRememberMe: false,
            ipAddress: '',
            browserLanguage: '',
            isActive: false,
            isPassword: true,

            /* Google Captcha Code  */
            /* googleReCaptch: null,
            iscaptchreload: true, */

            /* Native Captcha Code */
            captchWord: "",
            iscaptchwordMatch: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleValidationOnBlur = this.handleValidationOnBlur.bind(this);
        this.handleRememberMeCheck = this.handleRememberMeCheck.bind(this);
        this.goBack = this.goBack.bind(this);
        this.handleSignUP = this.handleSignUP.bind(this);
    }

    componentDidMount() {
        logoutSession()
        var cookieobj = getCookie(Constants.Cookie_LoginCredential);
        console.log("cookieobj ", cookieobj)
        if (cookieobj) {
            var userFields = this.state.userFields;
            userFields["Email"] = cookieobj.Email;
            userFields["Password"] = cookieobj.Password;
            this.setState({ userFields });
            this.setState({ checkedRememberMe: true });
        }

        /* For Ip Address */
        (async () => {
            var ip = await publicIp.v4();
            this.setState({ ipAddress: ip });
            console.log('ip address', ip);
        })();

        /* For Current Location */
        if ("geolocation" in navigator) {
            console.log("Geolocation Available");
            this.getLocation();
        } else {
            console.log("Geolocation Not Available");
            console.error("Error Code = " + error.code + " - " + error.message);
        }

        /* For Detect browser details */
        const detectBrowserLanguage = require('detect-browser-language')
        this.setState({ browserLanguage: detectBrowserLanguage() });
        console.log('detect browser language', detectBrowserLanguage());

        /* Native Captcha Code */
        this.setState({ captchWord: GETWORD(6) });
    }

    /* Google Captcha Code */
    /* handleGoogleRecaptcha = vl => {
        this.setState({ googleReCaptch: vl })
    } */

    /* For Get Curent Location */
    getLocation = () => {
        navigator.geolocation.getCurrentPosition((position) => {
            console.log('Location', 'lat' + position.coords.latitude + "Lon" + position.coords.longitude);
            this.setState({ latitude: position.coords.latitude });
            this.setState({ longitude: position.coords.longitude });
        });
    };

    handleChange(event) {
        let userFields = this.state.userFields;
        let key = event.target.name;
        let value = event.target.value;
        userFields[key] = value;

        /* Native Captcha Code */
        if (key === "captchacode") {
            if (value.length === 6) {
                if (value != this.state.captchWord) {
                    let errors = this.state.errors;
                    errors['captchacode'] = Messages.v_EnterValidCaptcha;
                    this.setState({ errors: errors, iscaptchwordMatch: false });
                } else {
                    this.setState({ iscaptchwordMatch: true, errors: {} });
                }
            } else {
                if (value.length === 0 || value.length === 1) {
                    this.setState({ errors: {} });
                }
                this.setState({ iscaptchwordMatch: false });
            }
        }

        this.setState({ userFields });
    }

    handleRememberMeCheck() {
        if (this.state.checkedRememberMe) {
            this.setState({ checkedRememberMe: false })
        } else {
            this.setState({ checkedRememberMe: true })
        }
    }

    reloadCaptch() {

        /* Google Captcha Code */
        /* this.setState({ iscaptchreload: false, googleReCaptch: null })
        setTimeout(() => {
            this.setState({ iscaptchreload: true })
        }, 900) */

        /* Native Captcha Code */
        this.setState({ captchWord: GETWORD(6) });
    }

    handleValidationOnBlur(e) {
        let fields = this.state.userFields;
        let errors = this.state.errors;
        let formIsValid = true;
        let name = e.currentTarget.name;

       /*  if (name === "Email") {
            if (!fields["Email"]) {
                formIsValid = false;
                errors["Email"] = Messages.v_EnterEmail;
            }
            else if (typeof fields["Email"] !== "undefined") {
                if (!validateEmail(fields["Email"])) {
                    formIsValid = false;
                    errors["Email"] = Messages.v_EnterValidEmail;
                }
            }
        }
        else if (name === "Password") {
            if (!fields["Password"]) {
                formIsValid = false;
                errors["Password"] = Messages.v_EnterPassword
            }
            else if (typeof fields["Password"] !== "undefined") {
                if (!validatePassword(fields["Password"])) {
                    formIsValid = false;
                    errors["Password"] = Messages.v_EnterValidPassword;
                }
            }
        }
        else */ if (name === "captchacode") {
            if (!fields['captchacode']) {
                formIsValid = false;
                errors["captchacode"] = Messages.v_EnterCaptcha;
            }
            else if (typeof fields["captchacode"] !== "undefined") {
                if (fields["captchacode"] != this.state.captchWord) {
                    formIsValid = false;
                    errors["captchacode"] = Messages.v_EnterValidCaptcha;
                }
            }
        }

        if (formIsValid) errors[name] = "";
        this.setState({
            fields,
            errors
        });
    }

    validateForm() {
        let errors = {};
        let formIsValid = true;
        let fields = this.state.userFields;

        /* Goole Captcha Code */
        /* const { googleReCaptch } = this.state */

        if (!fields["Email"]) {
            formIsValid = false;
            errors["Email"] = Messages.v_EnterEmail;
        }
        else if (typeof fields["Email"] !== "undefined") {
            if (!validateEmail(fields["Email"])) {
                formIsValid = false;
                errors["Email"] = Messages.v_EnterValidEmail;
            }
        }

        if (!fields["Password"]) {
            formIsValid = false;
            errors["Password"] = Messages.v_EnterPassword
        }
        else if (typeof fields["Password"] !== "undefined") {
            if (!validatePassword(fields["Password"])) {
                formIsValid = false;
                errors["Password"] = Messages.v_EnterValidPassword;
            }
        }

        /* Native Captcha Code */
        if (!fields['captchacode']) {
            formIsValid = false;
            errors["captchacode"] = Messages.v_EnterCaptcha;
        }
        else if (typeof fields["captchacode"] !== "undefined") {
            if (fields["captchacode"] != this.state.captchWord) {
                formIsValid = false;
                errors["captchacode"] = Messages.v_EnterValidCaptcha;
            }
        }

        /* Google Captcha Code */
        /* if (!googleReCaptch) {
            formIsValid = false;
        } */

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSubmit(e) {
        var url = "";
        var postData = {};
        e.preventDefault();
        if (this.validateForm()) {

            /* Googl Captcha Code */
            /* const { googleReCaptch } = this.state; */

            this.setState({ isActive: true });

            postData = this.state.userFields;
            postData["DeviceId"] = "";/* Constants.DEVICE_ID */
            postData["DeviceType"] = "";/* Constants.DEVICE_TYPE */
            postData["PushToken"] = "";/* Constants.PUSH_TOKEN */
            postData["ipaddress"] = this.state.ipAddress;
            postData["browser"] = browserName;
            postData["browserversion"] = fullBrowserVersion;
            postData["osdetails"] = osName + " " + osVersion;
            postData["location"] = "";
            postData["locale"] = this.state.browserLanguage;
            postData["Latitude"] = this.state.latitude;
            postData["Longitude"] = this.state.longitude;
            postData["questionid"] = DEFAULTID;
            postData["answer"] = "";
            postData["userid"] = "";

            /* Google Captcha code */
            postData["googlecaptcharesponse"] = ""; /* googleReCaptch */
            this.callLoginAPI(postData);
        }
    }

    callLoginAPI(postData) {
        this.setState({ isActive: true });
        var URL = LOGIN_API;
        PostData(URL, postData)
            .then(result => {
                var responseJson = result;
                if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                    var authToken = responseJson["Data"].Token;
                    StoreDataInStorage(Constants.AUTH_TOKEN, authToken);
                    this.setState({ isActive: false });
                    if (this.state.checkedRememberMe) {
                        StoreDataInStorage(Constants.FLAG_REMEMBER_ME, true);
                        var userfields = this.state.userFields;
                        setCookie(Constants.Cookie_LoginCredential, userfields);
                    } else {
                        StoreDataInStorage(Constants.FLAG_REMEMBER_ME, false);
                        removeCookie(Constants.Cookie_LoginCredential)
                    }
                    this.props.history.push({
                        pathname: RouteConstant.Dashboard,
                    })
                    window.location.reload(true);
                }
                else {
                    let errors = {};
                    errors['ErrorEmail'] = responseJson["Message"];
                    this.setState({ errors: errors, isActive: false });
                }
                this.reloadCaptch()
            }).catch(error => {
                let errors = {};
                errors['ErrorEmail'] = Messages.ERR_SomethingWentWrong;
                this.setState({ errors: errors, isActive: false });
                this.reloadCaptch()
            });
    }

    ResponsewithGooglewithSigninApi = (response) => {
        this.setState({ loaderClass: true, isActive: true, error: {} });

        /* Google Captcha Code */
        const { /* googleReCaptch, */ iscaptchwordMatch } = this.state;

        var postData = {};
        console.log(response);

        try {
            if (iscaptchwordMatch /* googleReCaptch */) {

                var data = response;
                let key = Constants.GOOGLEEMAIL;
                let value = data.email;
                postData[key] = value;

                /* Google Captcha code */
                postData["googlecaptcharesponse"] = ""; /* googleReCaptch */

                var URL = GOOGLE_SIGNIN_API;
                PostData(URL, postData)
                    .then(result => {
                        var responseJson = result;
                        console.log("responseJson", responseJson);
                        if (responseJson["StatusCode"] === 200 && responseJson["Status"] === "Success") {
                            var authToken = responseJson["Data"].Token;
                            StoreDataInStorage(Constants.AUTH_TOKEN, authToken);
                            this.setState({ isActive: false });
                            if (this.state.checkedRememberMe) {
                                StoreDataInStorage(Constants.FLAG_REMEMBER_ME, true);
                                var userfields = this.state.userFields;
                                setCookie(Constants.Cookie_LoginCredential, userfields);
                            } else {
                                StoreDataInStorage(Constants.FLAG_REMEMBER_ME, false);
                                removeCookie(Constants.Cookie_LoginCredential)
                            }
                            this.props.history.push({
                                pathname: RouteConstant.Dashboard,
                            })
                            window.location.reload(true);
                        }
                        else {
                            let errors = {};
                            errors['ErrorEmail'] = responseJson["Message"];
                            this.setState({ errors: errors, isActive: false });
                        }
                        this.reloadCaptch()
                    }).catch(error => {
                        let errors = {};
                        errors['ErrorEmail'] = Messages.ERR_SomethingWentWrong;
                        this.setState({ errors: errors, isActive: false });
                        this.reloadCaptch()
                    });
            } else {
                let errors = {};
                let fields = this.state.userFields;
                if (!fields['captchacode']) {
                    errors["captchacode"] = Messages.v_EnterCaptcha;
                }
                else if (typeof fields["captchacode"] !== "undefined") {
                    if (fields["captchacode"] != this.state.captchWord) {
                        errors["captchacode"] = Messages.v_EnterValidCaptcha;
                    }
                }
                this.setState({ errors: errors, isActive: false });
                this.reloadCaptch()
            }
        } catch (err) {
            let errors = {};
            errors['ErrorEmail'] = Messages.ERR_SomethingWentWrong;
            this.setState({ errors: errors, isActive: false });
            this.reloadCaptch()
        }
    }

    /* responseFacebook = (response) => {
        console.log('facebook response ', response);
        try {
            const { email, id } = response
            if (email && id) {
                var postData = {};
                postData["Email"] = email
                postData["Password"] = id
                postData["DeviceId"] = Constants.DEVICE_ID;
                postData["DeviceType"] = Constants.DEVICE_TYPE;
                postData["PushToken"] = Constants.PUSH_TOKEN;
                this.callLoginAPI(postData);
            }
        }
        catch (err) {
            console.log(err)
        }
    } */

    /*  responseGoogle = (response) => {
            console.log('google response ', response);
            try {
                const { profileObj } = response
                var postData = {};
                if (profileObj) {
                    postData["Email"] = profileObj.email;
                    postData["Password"] = profileObj.googleId
                    postData["DeviceId"] = Constants.DEVICE_ID;
                    postData["DeviceType"] = Constants.DEVICE_TYPE;
                    postData["PushToken"] = Constants.PUSH_TOKEN;
                    this.callLoginAPI(postData);
                }
    
            }
            catch (err) {
                console.log(err)
            }
        } */

    goBack() {
        this.props.history.goBack();
    }

    handleSignUP(e) {
        this.props.history.goBack();
    }

    render() {
        /* Google Captcha code */
        const { isPassword, /* googleReCaptch, iscaptchreload, */ captchWord, iscaptchwordMatch } = this.state;
        return (
            <LoadingOverlay
                active={this.state.isActive}
                text={Constants.LoadingMssg}
                spinner
                styles={{
                    overlay: (base) => ({
                        ...base,
                        position: "fixed"
                    })
                }}
                className="" >
                <div>
                    <div class="hero_area">
                        <MainHeader />
                    </div>
                    {/* <Header /> */}
                    <section className="section">
                        {/* <div className="col-sm-12">
                        <div className="col-sm-2">
                            <i class="fa fa-arrow-left" style={{ fontSize: "20px", color: Color.THEME_COLOR, left: "0px", float: "left" }} onClick={this.goBack} ></i>
                        </div>
                    </div> */}

                        <div className="container" style={{ paddingLeft: "2px", paddingRight: "2px" }} >
                            <div className="reg_form_main">
                                {/* <center><img src={plazacartLogo} className="col-sm-4"></img></center> */}
                                <center><p id="instruction" className="textcolor" style={{ marginBottom: '10px' }}>{LoginConst.screenTitle}</p> </center>
                            </div>

                            <div className="reg_form_main offset-sm-3 col-sm-6">
                                <input
                                    type="email"
                                    placeholder={LoginPH.email}
                                    className="float-none"
                                    name="Email"
                                    value={this.state.userFields.Email}
                                    onChange={this.handleChange} />
                                <div className="col-sm-12 errorMsg mb-0">
                                    {this.state.errors.Email}
                                </div>{" "}
                                <div className="col-sm-12 errorMsg mb-0">
                                    {this.state.alreadyEmail}
                                </div>
                            </div>

                            <div className="reg_form_main offset-sm-3 col-sm-6">
                                <div className="row" style={{ backgroundColor: "#f0f0f0", borderRadius: "50px", marginBottom: '10px', marginLeft: "0px", marginRight: "0px" }}>
                                    <input
                                        style={{ width: "90%", margin: 0 }}
                                        type={isPassword ? "password" : "text"}
                                        placeholder={LoginPH.Password}
                                        className="float-none"
                                        name="Password"
                                        value={this.state.userFields.Password}
                                        onChange={this.handleChange}
                                    //onBlur={this.handleValidationOnBlur}
                                    //onFocus={this.validatePass} 
                                    />
                                    <i class={!isPassword ? "fa fa-eye" : "fa fa-eye-slash"} style={{ color: Color.THEME_COLOR, width: '30px', marginTop: "14px" }} onClick={() => this.setState({ isPassword: !isPassword })} />
                                </div>
                                <div className="col-sm-12 errorMsg mb-0">
                                    {this.state.errors.Password}
                                </div>
                            </div>

                            {/* Google Captcha Code  */}
                            {/* <div className="reg_form_main offset-sm-3 col-sm-6 text-center">
                                {iscaptchreload && <ReCAPTCHA
                                    style={{ display: "inline-block" }}
                                    // theme="dark"
                                    // ref={this._reCaptchaRef}
                                    size="normal"
                                    sitekey={GOOGLESITEKEY}
                                    onChange={this.handleGoogleRecaptcha}
                                // asyncScriptOnLoad={this.asyncScriptOnLoad}
                                />}
                            </div> */}

                            {/* Native Captcha Code */}
                            <div className="reg_form_main offset-sm-3 col-sm-6">
                                <input
                                    type="text"
                                    placeholder={LoginPH.Captcha}
                                    name="captchacode"
                                    className="float-none m-0 mb-2"
                                    value={this.state.userFields.captchacode}
                                    maxLength="6"
                                    onChange={this.handleChange}
                                    onBlur={this.handleValidationOnBlur} />
                                <div className="col-sm-12 errorMsg mb-2">
                                    {this.state.errors.captchacode}
                                </div>
                                <div className="text-center">
                                    <ACaptcha
                                        font={'30px Arial'}
                                        align={'center'}
                                        baseline={'middle'}
                                        width={200}
                                        height={50}
                                        bgColor={Color.THEME_COLOR}
                                        color={Color.WHITE}
                                        captchstr={captchWord} />
                                </div>
                            </div>

                            <div className="reg_form_main offset-sm-3 col-sm-6">
                                <div >
                                    <div className="col-sm-12">
                                        <Checkbox
                                            color={Color.THEME_COLOR}
                                            size={2}
                                            tickSize={2}
                                            checked={this.state.checkedRememberMe}
                                            delay={0}
                                            backAnimationDuration={0}
                                            tickAnimationDuration={0}
                                            onChange={this.handleRememberMeCheck}></Checkbox>
                                        &nbsp; &nbsp;<text className="textcolor" style={{ fontWeight: 'bold' }}>{LoginConst.Rememberme}</text>
                                    </div>
                                </div>
                            </div>

                            <div className="reg_form_main offset-sm-3 col-sm-6">
                                <center><a href={RouteConstant.ForgotPassword} className="col-sm-12 textcolor" style={{ fontWeight: 'bold' }}>{LoginConst.Forgotpassword}</a></center>
                                <div className="col-sm-12 errorMsg mb-0">
                                    <center>{this.state.errors.ErrorEmail}</center>
                                </div>
                            </div>

                            <div className="reg_form_main offset-sm-3 col-sm-6 mbottom">
                                <div className="bottam_sing">
                                    <button
                                        style={{ borderRadius: "4px", margin: '0px', width: "100%", maxWidth: '300px' }}
                                        for="step2"
                                        id="continue-step2"
                                        onClick={this.handleSubmit}
                                        type="button"
                                        className="col-lg-7 btn btn-done"
                                        disabled={!iscaptchwordMatch /* !googleReCaptch */}/* Google Captch Code */
                                    > {LoginConst.btn_SIGNIN}</button>
                                </div>

                                <div className="bottam_sing" style={{ marginTop: '12px' }}>
                                    <LoginWithGoogle
                                        mode={1}
                                        clientId={GOOGLE_CLIENT_ID}
                                        disabled={!iscaptchwordMatch /* !googleReCaptch */} /* Google Captch Code */
                                        onSuccess={this.ResponsewithGooglewithSigninApi} />
                                </div>

                                <div className="bottam_sing" style={{ marginTop: '12px' }}>
                                    <label
                                        style={{ borderRadius: "4px", margin: '0px', maxWidth: '300px' }}
                                        for="step2"
                                        id="continue-step2"
                                        onClick={this.handleSignUP}
                                        className="col-lg-7 btn btn-done"
                                    > {LoginConst.btn_SIGNUP}</label>
                                </div>

                                {/* <div className="bottam_sing">
                                    <GoogleLogin
                                        clientId={Constants.GOOGLE_CLIENT_ID}
                                        icon={true}
                                        render={renderProps => (
                                            <div
                                                className="col-lg-7 btn btn-done"
                                                style={{ height: "50px", borderRadius: "4px", backgroundColor: Color.GOOGLE_BTN_COLOR, margin: '0px' }}>
                                                <img src={gmailLogo} style={{ height: "30px", width: "30px", float: "left", marginRight: "auto" }}></img>
                                                <label
                                                    //style={{marginLeft:"auto"}}
                                                    onClick={renderProps.onClick}
                                                    disabled={renderProps.disabled}
                                                > {LoginConst.btn_JOINWITHGOOGLE}</label>
                                            </div>
                                        )}
                                        buttonText="Join with Google"
                                        onSuccess={this.responseGoogle}
                                        onFailure={this.responseGoogle}
                                        cookiePolicy={'single_host_origin'}
                                    />
                                </div> */}

                                {/* <div className="bottam_sing">
                                    <FacebookLogin
                                        appId={Constants.FACEBOOK_APP_ID}
                                        callback={this.responseFacebook}
                                        fields="name,email,picture"
                                        render={renderProps => (
                                            <div
                                                className="col-lg-7 btn btn-done"
                                                style={{ height: "50px", borderRadius: "4px", backgroundColor: Color.FACEBOOK_BTN_COLOR, margin: '0px' }}>
                                                <img src={facebookLogo} style={{ height: "30px", width: "30px", float: "left", marginRight: "auto" }}></img>
                                                <label
                                                    onClick={renderProps.onClick}
                                                > {LoginConst.btn_JOINWITHFACEBOOK}</label>
                                            </div>
                                        )} />
                                </div> */}
                            </div>
                        </div>
                    </section>
                    <MainFooter />
                </div>
            </LoadingOverlay>
        );
    }
}

